import { GetLastAccessRequestAction, GetLastAccessResponseAction, GetLastAccessErrorAction } from '.'
import { protectedApiClient } from 'helpers/api'
import { handleApiErrorWithNotification } from 'helpers/errorHandling'
import { ServerLastAccess } from '../models'

export default (id: number, cb?: (isSuccess: boolean) => void) => {
	return (
		dispatch: (arg: GetLastAccessRequestAction | GetLastAccessResponseAction | GetLastAccessErrorAction) => void
	) => {
		const request = new GetLastAccessRequestAction(id)
		dispatch(request)

		protectedApiClient
			.get<ServerLastAccess>(`/asyncjob/access-log`)
			.then((response) => {
				dispatch(new GetLastAccessResponseAction(request, response.data))
				cb && cb(true)
			})
			.catch((error) => {
				dispatch(new GetLastAccessErrorAction(request, error))
				handleApiErrorWithNotification(error)
				cb && cb(false)
			})
	}
}
