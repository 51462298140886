import {AsyncActionMode, NameItemStr} from 'common/models'
import {AsyncJobParams, AsyncJobModel, JobGrid, ServerLastAccess} from '../models'
import {ServerGrid, ServerModel} from "../../../server/models";

export enum ActionType {
	CREATE_ASYNCJOB = 'CREATE_ASYNCJOB',
	GET_ASYNCJOBS = 'GET_ASYNCJOBS',
	GET_ASYNCJOB = 'GET_ASYNCJOB',
	UPDATE_ASYNCJOB = 'UPDATE_ASYNCJOB',
	DELETE_ASYNCJOB = 'DELETE_ASYNCJOB',
	GET_JOB_NAMES = 'GET_JOB_NAMES',
	GET_JOB_STATES = 'GET_JOB_STATES',
	START_JOB = 'START_JOB',
	FINISH_JOB = 'FINISH_JOB',
	RESOLVE_JOB = 'RESOLVE_JOB',
	RESTART_JOB = 'RESTART_JOB',
	CANCEL_JOB = 'CANCEL_JOB',
	LOOKUP_SERVER_JOB = 'LOOKUP_SERVER_JOB',
	FIND_SERVERS = 'FIND_SERVERS',
	GET_SERVER_JOB = 'GET_SERVER_JOB',
	CLEAR_SERVER_JOB = 'CLEAR_SERVER_JOB',
	GET_ASYNCJOB_ACCESS_LOG = 'GET_ASYNCJOB_ACCESS_LOG',
}


export type AsyncJobActions =
	| CreateAsyncJobRequestAction
	| CreateAsyncJobResponseAction
	| CreateAsyncJobErrorAction
	| GetAsyncJobsRequestAction
	| GetAsyncJobsResponseAction
	| GetAsyncJobsErrorAction
	| GetAsyncJobRequestAction
	| GetAsyncJobResponseAction
	| GetAsyncJobErrorAction

	| GetJobNamesRequestAction
	| GetJobNamesResponseAction
	| GetJobNamesErrorAction

	| GetJobStatesRequestAction
	| GetJobStatesResponseAction
	| GetJobStatesErrorAction
	| UpdateAsyncJobRequestAction
	| UpdateAsyncJobResponseAction
	| UpdateAsyncJobErrorAction
	| DeleteAsyncJobRequestAction
	| DeleteAsyncJobResponseAction
	| DeleteAsyncJobErrorAction
	| StartJobRequestAction
	| StartJobResponseAction
	| StartJobErrorAction
	| FinishJobRequestAction
	| FinishJobResponseAction
	| FinishJobErrorAction
	| ResolveJobRequestAction
	| ResolveJobResponseAction
	| ResolveJobErrorAction
	| CancelJobRequestAction
	| CancelJobResponseAction
	| CancelJobErrorAction
	| RestartJobRequestAction
	| RestartJobResponseAction
	| RestartJobErrorAction
	| LookupServerJobRequestAction
	| LookupServerJobResponseAction
	| LookupServerJobErrorAction
	| FindServersByHostnameRequestAction
	| FindServersByHostnameResponseAction
	| FindServersByHostnameErrorAction
	| GetServerJobRequestAction
	| GetServerJobResponseAction
	| GetServerJobErrorAction
	| ClearServerJobRequestAction
	| GetLastAccessRequestAction
	| GetLastAccessResponseAction
	| GetLastAccessErrorAction


export class StartJobRequestAction {
	readonly type = ActionType.START_JOB
	readonly mode = AsyncActionMode.REQUEST
	constructor(public id: number) {}
}
export class StartJobResponseAction {
	readonly type = ActionType.START_JOB
	readonly mode = AsyncActionMode.RESPONSE
	constructor(public request: StartJobRequestAction, public data: AsyncJobModel) {}
}
export class StartJobErrorAction {
	readonly type = ActionType.START_JOB
	readonly mode = AsyncActionMode.ERROR
	constructor(public request: StartJobRequestAction, public error: string) {}
}
export class FinishJobRequestAction {
	readonly type = ActionType.FINISH_JOB
	readonly mode = AsyncActionMode.REQUEST
	constructor(public id: number) {}
}
export class FinishJobResponseAction {
	readonly type = ActionType.FINISH_JOB
	readonly mode = AsyncActionMode.RESPONSE
	constructor(public request: FinishJobRequestAction, public data: AsyncJobModel) {}
}
export class FinishJobErrorAction {
	readonly type = ActionType.FINISH_JOB
	readonly mode = AsyncActionMode.ERROR
	constructor(public request: FinishJobRequestAction, public error: string) {}
}

export class ResolveJobRequestAction {
	readonly type = ActionType.RESOLVE_JOB
	readonly mode = AsyncActionMode.REQUEST
	constructor(public id: number) {}
}
export class ResolveJobResponseAction {
	readonly type = ActionType.RESOLVE_JOB
	readonly mode = AsyncActionMode.RESPONSE
	constructor(public request: ResolveJobRequestAction, public data: AsyncJobModel) {}
}
export class ResolveJobErrorAction {
	readonly type = ActionType.RESOLVE_JOB
	readonly mode = AsyncActionMode.ERROR
	constructor(public request: ResolveJobRequestAction, public error: string) {}
}

export class CancelJobRequestAction {
	readonly type = ActionType.CANCEL_JOB
	readonly mode = AsyncActionMode.REQUEST
	constructor(public id: number) {}
}
export class CancelJobResponseAction {
	readonly type = ActionType.CANCEL_JOB
	readonly mode = AsyncActionMode.RESPONSE
	constructor(public request: CancelJobRequestAction, public data: AsyncJobModel) {}
}
export class CancelJobErrorAction {
	readonly type = ActionType.CANCEL_JOB
	readonly mode = AsyncActionMode.ERROR
	constructor(public request: CancelJobRequestAction, public error: string) {}
}

export class RestartJobRequestAction {
	readonly type = ActionType.RESTART_JOB
	readonly mode = AsyncActionMode.REQUEST
	constructor(public id: number) {}
}
export class RestartJobResponseAction {
	readonly type = ActionType.RESTART_JOB
	readonly mode = AsyncActionMode.RESPONSE
	constructor(public request: RestartJobRequestAction, public data: AsyncJobModel) {}
}
export class RestartJobErrorAction {
	readonly type = ActionType.RESTART_JOB
	readonly mode = AsyncActionMode.ERROR
	constructor(public request: RestartJobRequestAction, public error: string) {}
}

export class CreateAsyncJobRequestAction {
	readonly type = ActionType.CREATE_ASYNCJOB
	readonly mode = AsyncActionMode.REQUEST
	constructor(public payload: AsyncJobParams) {}
}
export class CreateAsyncJobResponseAction {
	readonly type = ActionType.CREATE_ASYNCJOB
	readonly mode = AsyncActionMode.RESPONSE
	constructor(public request: CreateAsyncJobRequestAction, public data: AsyncJobModel) {}
}
export class CreateAsyncJobErrorAction {
	readonly type = ActionType.CREATE_ASYNCJOB
	readonly mode = AsyncActionMode.ERROR
	constructor(public request: CreateAsyncJobRequestAction, public error: string) {}
}

export class GetAsyncJobRequestAction {
	readonly type = ActionType.GET_ASYNCJOB
	readonly mode = AsyncActionMode.REQUEST
	constructor(public payload: number) {
		''
	}
}
export class GetAsyncJobResponseAction {
	readonly type = ActionType.GET_ASYNCJOB
	readonly mode = AsyncActionMode.RESPONSE
	constructor(public request: GetAsyncJobRequestAction, public data: AsyncJobModel) {}
}
export class GetAsyncJobErrorAction {
	readonly type = ActionType.GET_ASYNCJOB
	readonly mode = AsyncActionMode.ERROR
	constructor(public request: GetAsyncJobRequestAction, public error: string) {}
}

export class GetAsyncJobsRequestAction {
	readonly type = ActionType.GET_ASYNCJOBS
	readonly mode = AsyncActionMode.REQUEST
	constructor() {
		''
	}
}
export class GetAsyncJobsResponseAction {
	readonly type = ActionType.GET_ASYNCJOBS
	readonly mode = AsyncActionMode.RESPONSE
	constructor(public request: GetAsyncJobsRequestAction, public data: JobGrid) {}
}
export class GetAsyncJobsErrorAction {
	readonly type = ActionType.GET_ASYNCJOBS
	readonly mode = AsyncActionMode.ERROR
	constructor(public request: GetAsyncJobsRequestAction, public error: string) {}
}
export class GetJobNamesRequestAction {
	readonly type = ActionType.GET_JOB_NAMES
	readonly mode = AsyncActionMode.REQUEST
	constructor() {
		''
	}
}
export class GetJobNamesResponseAction {
	readonly type = ActionType.GET_JOB_NAMES
	readonly mode = AsyncActionMode.RESPONSE
	constructor(public request: GetJobNamesRequestAction, public data: NameItemStr[]) {}
}
export class GetJobNamesErrorAction {
	readonly type = ActionType.GET_JOB_NAMES
	readonly mode = AsyncActionMode.ERROR
	constructor(public request: GetJobNamesRequestAction, public error: string) {}
}

export class GetJobStatesRequestAction {
	readonly type = ActionType.GET_JOB_STATES
	readonly mode = AsyncActionMode.REQUEST
	constructor() {
		''
	}
}
export class GetJobStatesResponseAction {
	readonly type = ActionType.GET_JOB_STATES
	readonly mode = AsyncActionMode.RESPONSE
	constructor(public request: GetJobStatesRequestAction, public data: NameItemStr[]) {}
}
export class GetJobStatesErrorAction {
	readonly type = ActionType.GET_JOB_STATES
	readonly mode = AsyncActionMode.ERROR
	constructor(public request: GetJobStatesRequestAction, public error: string) {}
}

export class UpdateAsyncJobRequestAction {
	readonly type = ActionType.UPDATE_ASYNCJOB
	readonly mode = AsyncActionMode.REQUEST
	constructor(public id: AsyncJobParams) {}
}
export class UpdateAsyncJobResponseAction {
	readonly type = ActionType.UPDATE_ASYNCJOB
	readonly mode = AsyncActionMode.RESPONSE
	constructor(public request: UpdateAsyncJobRequestAction, public data: AsyncJobModel) {}
}
export class UpdateAsyncJobErrorAction {
	readonly type = ActionType.UPDATE_ASYNCJOB
	readonly mode = AsyncActionMode.ERROR
	constructor(public request: UpdateAsyncJobRequestAction, public error: string) {}
}

export class DeleteAsyncJobRequestAction {
	readonly type = ActionType.DELETE_ASYNCJOB
	readonly mode = AsyncActionMode.REQUEST
	constructor(public id: number) {
		''
	}
}
export class DeleteAsyncJobResponseAction {
	readonly type = ActionType.DELETE_ASYNCJOB
	readonly mode = AsyncActionMode.RESPONSE
	constructor(public request: DeleteAsyncJobRequestAction, public data: AsyncJobModel) {}
}
export class DeleteAsyncJobErrorAction {
	readonly type = ActionType.DELETE_ASYNCJOB
	readonly mode = AsyncActionMode.ERROR
	constructor(public request: DeleteAsyncJobRequestAction, public error: string) {}
}

export class LookupServerJobRequestAction {
	readonly type = ActionType.LOOKUP_SERVER_JOB
	readonly mode = AsyncActionMode.REQUEST
	constructor(public field: string) {
		''
	}
}
export class LookupServerJobResponseAction {
	readonly type = ActionType.LOOKUP_SERVER_JOB
	readonly mode = AsyncActionMode.RESPONSE
	constructor(
		public request: LookupServerJobRequestAction,
		public field: string,
		public data: string[],
	) {}
}
export class LookupServerJobErrorAction {
	readonly type = ActionType.LOOKUP_SERVER_JOB
	readonly mode = AsyncActionMode.ERROR
	constructor(public request: LookupServerJobRequestAction, public error: string) {}
}


export class FindServersByHostnameRequestAction {
	readonly type = ActionType.FIND_SERVERS
	readonly mode = AsyncActionMode.REQUEST
	constructor() {
		''
	}
}
export class FindServersByHostnameResponseAction {
	readonly type = ActionType.FIND_SERVERS
	readonly mode = AsyncActionMode.RESPONSE
	constructor(public request: FindServersByHostnameRequestAction, public data: ServerGrid) {}
}
export class FindServersByHostnameErrorAction {
	readonly type = ActionType.FIND_SERVERS
	readonly mode = AsyncActionMode.ERROR
	constructor(public request: FindServersByHostnameRequestAction, public error: string) {}
}

export class GetServerJobRequestAction {
	readonly type = ActionType.GET_SERVER_JOB
	readonly mode = AsyncActionMode.REQUEST
	constructor(public payload: number) {
		''
	}
}
export class GetServerJobResponseAction {
	readonly type = ActionType.GET_SERVER_JOB
	readonly mode = AsyncActionMode.RESPONSE
	constructor(public request: GetServerJobRequestAction, public data: ServerModel) {}
}
export class GetServerJobErrorAction {
	readonly type = ActionType.GET_SERVER_JOB
	readonly mode = AsyncActionMode.ERROR
	constructor(public request: GetServerJobRequestAction, public error: string) {}
}

export class ClearServerJobRequestAction {
	readonly type = ActionType.CLEAR_SERVER_JOB
	readonly mode = AsyncActionMode.REQUEST
	constructor() {}
}

export class GetLastAccessRequestAction {
	readonly type = ActionType.GET_ASYNCJOB_ACCESS_LOG
	readonly mode = AsyncActionMode.REQUEST
	constructor(public payload: number) {
		''
	}
}
export class GetLastAccessResponseAction {
	readonly type = ActionType.GET_ASYNCJOB_ACCESS_LOG
	readonly mode = AsyncActionMode.RESPONSE
	constructor(public request: GetLastAccessRequestAction, public data: ServerLastAccess) {}
}	
export class GetLastAccessErrorAction {
	readonly type = ActionType.GET_ASYNCJOB_ACCESS_LOG
	readonly mode = AsyncActionMode.ERROR
	constructor(public request: GetLastAccessRequestAction, public error: string) {}
}


