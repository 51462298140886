import { GetMailDomainRequestAction, GetMailDomainResponseAction, GetMailDomainErrorAction } from '.'
import { protectedApiClient } from 'helpers/api'
import { handleApiErrorWithNotification } from 'helpers/errorHandling'
import { MailDomainModel } from '../models'

export default (id: number, cb?: (isSuccess: boolean) => void) => {
	return (
		dispatch: (arg: GetMailDomainRequestAction | GetMailDomainResponseAction | GetMailDomainErrorAction) => void
	) => {
		const request = new GetMailDomainRequestAction(id)
		dispatch(request)

		protectedApiClient
			.get<MailDomainModel>(`/mail/domain/${id}?expand=service,customer,mail_service_domain`)
			.then((response) => {
				dispatch(new GetMailDomainResponseAction(request, response.data))
				cb && cb(true)
			})
			.catch((error) => {
				dispatch(new GetMailDomainErrorAction(request, error))
				handleApiErrorWithNotification(error)
				cb && cb(false)
			})
	}
}
