import React, { ReactNode, useEffect, useRef, useState } from 'react'
import { AutoComplete, Button, Card, Col, Input, message, Modal, Row, Space, Switch, Table, Tag } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { AppState } from 'common/models'
import { useTranslation } from 'react-i18next'
import {
    BorderOutlined,
    CheckSquareFilled,
    DeleteTwoTone,
    EditTwoTone, ExclamationCircleOutlined,
    InfoCircleTwoTone, RobotOutlined,
    UserAddOutlined,
    UserOutlined
} from '@ant-design/icons'
import { AiOutlineUserAdd } from 'react-icons/ai'
import CreateUserPage from "./CreateUserPage"
import { RouteComponentProps, useHistory } from 'react-router'
import { useLoggedUser } from "../../helpers/loginUserHelper"
import { CheckOutlined, CloseOutlined } from "@ant-design/icons/lib/icons"
import { LoadingIndicator } from "../../components"
import Draggable, { DraggableData, DraggableEvent } from "react-draggable"
import usePageSize from "../../common/usePageSize"
import { UserDetails } from "./models"
import { PAGING } from "../../common/enums"
import TotalNum from "../../components/TotalNum/TotalNum"
import queryString, { ParsedQuery } from "query-string"
import { Link, useLocation } from "react-router-dom"
import getAllUsers from "./actions/getAllUsers"
import { _isNotEmptyObject, removeDiac, stopPropagation } from "../../common/fce"
import './UsersPage.scss'
import deleteUser from "./actions/deleteUser"
import { sort_searchable, sort_str } from "../../common/sorting"
import {
    SUPER_ROLE
} from "../../common/LoggedUser"
import HistoryModal from "../../components/History/HistoryModal"
import UserUpdateForm from "./UserUpdateForm"
import tableCustomers from "../login/actions/tableCustomers"


const { confirm } = Modal

const shortString = (name: string, n = 15) => {
    if (name.length > n) {
        return name.substr(0, n) + '..'
    }
    return name
}

const UsersPage = (props: RouteComponentProps) => {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const history = useHistory()
    const { search } = useLocation()

    const { customers } = useSelector((state: AppState) => state.auth.tables)
    const { users, user, isLoading } = useSelector((state: AppState) => state.user)
    const { users_toggle } = useSelector((state: AppState) => state.sidenav)

    const [usersFull, setUsersFull] = useState<UserDetails[]>([])
    const [dataSource, setDataSource] = useState<UserDetails[]>([])

    // filters
    const [parsed, setParsed] = useState<ParsedQuery<string>>(queryString.parse(search))
    const [username, setUsername] = useState<string>('')
    const [email, setEmail] = useState<string>('')
    const [title, setTitle] = useState<string>('')
    const [ident, setIdent] = useState<string>('')
    const [showDeleted, setShowDeleted] = useState(false)
    const [showSystem, setShowSystem] = useState(false)
    const [modalEditVisible, setModalEditVisible] = useState<boolean>(false)
    const [userId, setUserId] = useState<number | undefined>()
    const [modalCreateVisible, setModalCreateVisible] = useState<boolean>(false)
    const [isHistoryModalVisible, setHistoryModalVisible] = useState(false)
    const [historyTitle, setHistoryTitle] = useState('')
    const [historyModelId, setHistoryModelId] = useState<number | undefined>()
    const [forceUpdateHistoryId, setForceUpdateHistoryId] = useState<number>(1)
    const [pageSize, setPageSize] = useState<string>()
    const [openDelete, setOpenDelete] = useState(false)
    const [confirmDelete, setConfirmDelete] = useState(false)
    const [createUserFormTS, setCreateUserFormTS] = useState<number>(0)


    // get settings and current user
    const loggedUser = useLoggedUser()
    if (!loggedUser || !loggedUser.isLoaded()) {
        // waiting..
        return (
            <div className="fullwidth-loading" >
                <LoadingIndicator />
            </div>
        )
    }

    // ------------- initialized -------------
    const appSetting = loggedUser.getAppSettings()
    const SEARCH_MIN = appSetting.min_search_length


    // logger
    const logger = (msg, obj: any = null) => { if (appSetting && appSetting.debug) { obj ? console.log('UsersPage: ' + msg + ' > ' + JSON.stringify(obj)) : console.log('UsersPage: ' + msg) } }
    usePageSize(appSetting, loggedUser.user.id, pageSize)

    // history drag modal
    const draggleRef = useRef<HTMLDivElement>(null)
    const [bounds, setBounds] = useState({ left: 0, top: 0, bottom: 0, right: 0 })
    const onStart = (_event: DraggableEvent, uiData: DraggableData) => {
        const { clientWidth, clientHeight } = window.document.documentElement
        const targetRect = draggleRef.current?.getBoundingClientRect()
        if (!targetRect) {
            return
        }
        setBounds({
            left: -targetRect.left + uiData.x,
            right: clientWidth - (targetRect.right - uiData.x),
            top: -targetRect.top + uiData.y,
            bottom: clientHeight - (targetRect.bottom - uiData.y),
        })
    }

    // permissions
    // required: ROLE_USER_MANAGER | ROLE_CUSTOMER_MANAGER
    const [isViewer, setViewer] = useState(false)
    const [isCreator, setCreator] = useState(false)
    const [isEditor, setEditor] = useState(false)
    const [isDeleter, setDeleter] = useState(false)

    useEffect(() => {
        // when user logged => set permissions
        logger('loggedUser: ' + loggedUser.user.username)
        const access_users = loggedUser.hasAccess('page_users')
        setViewer(access_users)
        setCreator(loggedUser.hasAccess('page_users_create_button'))
        setEditor(loggedUser.hasAccess('page_users_edit_button'))
        setDeleter(loggedUser.hasAccess('page_users_delete_button'))
        if (!access_users) {
            // go to dashboard
            message.error(t('general.error403'))
            history.replace('/')
            return
        }

        logger('getAllUsers called')
        dispatch(getAllUsers(() => { }, 'customer,roles,groups'))
        // apply QS filters
        handleQueryStringFilters()
    }, [])


    useEffect(() => {
        // trigger from Side menu clicked
        logger('users_toggle---')
        if (!isLoading && isViewer) {
            // reload
            dispatch(getAllUsers(() => { }, 'customer,roles,groups'))
            // refreshGrid()
        }
    }, [users_toggle])

    useEffect(() => {
        // update datasource when data was changed
        if (isViewer) {
            setUsersFull(users.map(u => {
                return { ...u, searchableName: removeDiac(u.title), ident: u.customer ? u.customer.name : u.username }
            }))
        }
    }, [users, customers])

    useEffect(() => {
        // update datasource when data was changed
        refreshGrid()
    }, [usersFull, showDeleted, showSystem, username, email, title, ident])

    const filtered = (showDeleted: boolean, username: string, email: string, title: string, custName) => {
        // search for pattern
        if (!usersFull) {
            return []
        }
        let qs: string[] = []
        let data = usersFull
        if (!showDeleted) {
            data = data.filter(u => u.deleted_at === 0)
        }
        if (!showSystem) {
            data = data.filter(u => u.is_system === 0)
        }
        if (username && username.length > SEARCH_MIN) {
            data = data.filter((u) => u.username.toLowerCase().includes(username.toLowerCase()))
            qs.push('username=' + username)
        }
        if (email && email.length > SEARCH_MIN) {
            data = data.filter((u) => u.email.toLowerCase().includes(email.toLowerCase()))
            qs.push('email=' + email)
        }
        if (title && title.length > SEARCH_MIN) {
            data = data.filter((u) => u.searchableName?.includes(removeDiac(title)))
            qs.push('title=' + title)
        }
        if (ident && ident.length > SEARCH_MIN) {
            data = data.filter((u) => u.ident && removeDiac(u.ident).includes(removeDiac(custName)))
            qs.push('customer_id=' + ident)
        }

        history.replace('/users?' + qs.join("&"))
        logger(`filtered users: ${usersFull.length}, dataSource: ${data.length}`)
        return data.sort(sort_searchable)
    }

    const refreshGrid = () => {
        logger('refreshGrid')
        setDataSource(filtered(showDeleted, username, email, title, ident))
    }

    const handleQueryStringFilters = () => {
        // /users?email=com&title=and&username=andre&customer_id=1
        if (parsed && _isNotEmptyObject(parsed)) {
            const qs_email: string | string[] | null = parsed['email']
            if (qs_email && typeof qs_email === 'string') {
                setEmail(qs_email)
            }
            const qs_title: string | string[] | null = parsed['title']
            if (qs_title && typeof qs_title === 'string') {
                setTitle(qs_title)
            }
            const qs_username: string | string[] | null = parsed['username']
            if (qs_username && typeof qs_username === 'string') {
                setUsername(qs_username)
            }
            const qs_customer_id: string | string[] | null = parsed['customer_id']
            if (qs_customer_id && typeof qs_customer_id === 'string') {
                const cid = parseInt(qs_customer_id)
                let c_name = getCustomerName(cid)
                c_name && setIdent(c_name)
            }
        }
    }

    const getCustomerName = (cid: number | undefined): string => {
        if (!cid) {
            return ''
        }
        if (loggedUser.user.is_zcom) {
            const cust = customers?.find(c => c.id === cid)
            return (cust && cust.company!.name) ? cust.company!.name : `cust_${cid}`
        }
        else {
            return loggedUser.company.name
        }
    }

    const handleBtnCreate = () => {
        setCreateUserFormTS(Date.now())
        setModalCreateVisible(true)
    }

    const handleClose = () => {
        setModalEditVisible(false)
    }

    const showConfirmDelete = (b: UserDetails, title: string) => {
        if (!b) {
            return
        }
        confirm({
            icon: <ExclamationCircleOutlined style={{ color: 'red' }} />,
            title: title,
            content: <p>{b.title}</p>,
            okText: t('general.yes'),
            cancelText: t('general.cancel'),
            okButtonProps: { loading: confirmDelete },
            className: 'confirm-alert',
            onOk() {
                setConfirmDelete(true)
                dispatch(deleteUser(b.id, suc => {
                    setConfirmDelete(false)
                    setOpenDelete(false)
                    if (suc) {
                        message.success(t('general.success'))
                    }
                }))
            },
            onCancel() {
                setOpenDelete(false)
            },
        }
        )
    }

    const renderRole = (person: UserDetails) => {
        //logger(JSON.stringify(person))
        if (!person.roles) {
            return ''
        }
        if (person.is_zcom) {
            if (person.roles.includes('zcom-admin')) {
                return (<Tag color="green">admin</Tag>)
            }
            if (person.roles.includes(SUPER_ROLE)) {
                return (<Tag color="red">root</Tag>)
            }

            if (person.roles.includes('billing-admin')) {
                return (<Tag color="green">billing</Tag>)
            }
            return (<Tag color="green">zcom</Tag>)
        }
        else {
            return (<Tag color="orange">customer</Tag>)
        }
    }

    const FilterByUsernameInput = (
        <AutoComplete
            placeholder={t('usersPage.username')}
            style={{ width: '100px' }}
            value={username}
            allowClear={true}
            onClick={stopPropagation}
            onKeyDown={(e) => { if (e.key === 'Enter') { e.stopPropagation() } }}
            onChange={(currValue) => {
                setUsername(currValue)
            }}
        />
    )
    const FilterByEmailInput = (
        <AutoComplete
            placeholder="E-mail"
            style={{ width: '100px' }}
            value={email}
            allowClear={true}
            onClick={stopPropagation}
            onKeyDown={(e) => { if (e.key === 'Enter') { e.stopPropagation() } }}
            onChange={(currValue) => {
                setEmail(currValue)
            }}
        />
    )

    const FilterByTitle = (
        <AutoComplete
            placeholder={t('usersPage.name')}
            style={{ width: '150px' }}
            value={title}
            allowClear={true}
            onClick={stopPropagation}
            onKeyDown={(e) => { if (e.key === 'Enter') { e.stopPropagation() } }}
            onChange={(v) => {
                setTitle(v)
            }}
        />
    )

    const FilterByCustomer = (
        <AutoComplete
            placeholder={t('usersPage.ident')}
            style={{ width: '150px' }}
            value={ident}
            allowClear={true}
            onClick={stopPropagation}
            onKeyDown={(e) => { if (e.key === 'Enter') { e.stopPropagation() } }}
            onChange={(v) => {
                setIdent(v)
            }}
        />
    )

    const columns = [
        {
            title: FilterByTitle,
            dataIndex: 'title',
            key: 'title',
            sorter: (a, b) => sort_searchable(a, b),
            render: (text: string, record: UserDetails) => (
                <Link style={{ textDecoration: 'underline' }} to={`/users/${record.id}`}>
                    {text}
                </Link>
            )
        },
        {
            title: FilterByUsernameInput,
            dataIndex: 'username',
            key: 'username',
            render: (text: string) => shortString(text, 15),
            sorter: (a, b) => sort_str(a.username, b.username),
        },
        {
            title: FilterByEmailInput,
            dataIndex: 'email',
            key: 'email',
            render: (text: string) => shortString(text, 22),
            sorter: (a, b) => sort_str(a.email, b.email),
        },
        {
            title: "sys",
            dataIndex: 'is_system',
            key: 'is_system',
            width: 50,
            render: (n) => n == 1 ? <CheckSquareFilled /> : <BorderOutlined />
        },
        {
            title: "unix",
            dataIndex: 'is_unix',
            key: 'is_unix',
            width: 50,
            render: (n) => n == 1 ? <CheckSquareFilled /> : <BorderOutlined />
        },
        {
            title: FilterByCustomer,
            dataIndex: 'ident',
            key: 'ident',
            sorter: (a, b) => sort_str(a.ident, b.ident),
        },
        {
            title: 'Action',
            key: 'action',
            dataIndex: 'action',
            width: '100px',
            className: 'center',
            render: (text: string, record: UserDetails) => (
                <Space size={1}>
                    {isEditor &&
                        <Button type="text"
                            size="small"
                            className='actionButton'
                            title={t('general.change')}
                            onClick={() => {
                                setUserId(record.id)
                                setModalEditVisible(true)
                            }}
                            icon={<EditTwoTone twoToneColor="green" />}
                        />
                    }
                    {!isEditor &&
                        <Button type="text"
                            size="small"
                            className='actionButton'
                            title={t('general.change')}
                            disabled={true}
                            icon={<EditTwoTone twoToneColor="lightgray" />}
                        />
                    }
                    {isDeleter && (
                        (record && record.deleted_at === 0) ?
                            (
                                <Button type='text' danger size='small'
                                    className='actionButton'
                                    onClick={() => showConfirmDelete(record, t('usersPage.confirm_delete'))}
                                    icon={<DeleteTwoTone twoToneColor='red' />} />
                            )
                            :
                            (
                                <Button type="text" danger size="small"
                                    disabled={true}
                                    title={t('general.delete')}
                                    className='actionButton'
                                    icon={<DeleteTwoTone twoToneColor="red" />}
                                />
                            )
                    )
                    }
                    {!isDeleter &&
                        <Button type="text"
                            size="small"
                            className='actionButton'
                            title={t('general.delete')}
                            disabled={true}
                            icon={<DeleteTwoTone twoToneColor="lightgray" />}
                        />
                    }
                    <Button title={t('general.btnHistory')} size='small'
                        onClick={() => {
                            setHistoryModelId(record.id)
                            setHistoryTitle(record.title)
                            setHistoryModalVisible(true)
                            setForceUpdateHistoryId(forceUpdateHistoryId + 1)
                        }}
                        className='actionButton'
                        icon={<InfoCircleTwoTone />}
                    />
                </Space>
            ),
        },
    ]

    return (
        <Card className='UsersPage'
            title={
                <Row>
                    <Col span={3}>
                        <UserOutlined /> &nbsp; {t('usersPage.title')}
                    </Col>
                    <Col span={9}>

                    </Col>
                    <Col span={6}>
                        <Space style={{ float: 'right', marginRight: '20px' }}>
                            <div style={{ float: "left", marginRight: "10px" }}>{t('usersPage.show_system')}</div>
                            <Switch
                                checkedChildren={<CheckOutlined />}
                                unCheckedChildren={<CloseOutlined />}
                                checked={showSystem}
                                disabled={!isViewer}
                                onChange={() => setShowSystem(!showSystem)}
                            />
                        </Space>
                    </Col>
                    <Col span={6}>
                        <Space style={{ float: 'right', marginRight: '20px' }}>
                            <div style={{ float: "left", marginRight: "10px" }}>{t('usersPage.show_deleted')}</div>
                            <Switch
                                checkedChildren={<CheckOutlined />}
                                unCheckedChildren={<CloseOutlined />}
                                checked={showDeleted}
                                disabled={!isViewer}
                                onChange={() => setShowDeleted(!showDeleted)}
                            />
                        </Space>
                    </Col>
                </Row>
            }
            extra={
                <Button type='primary'
                    onClick={handleBtnCreate}
                    disabled={!isCreator}>
                    <UserAddOutlined />{t('usersPage.create_user')}
                </Button>
            }
        >

            <Table<UserDetails>
                dataSource={dataSource}
                columns={columns}
                rowKey={(record) => `${record.id}`}
                showHeader={true}
                bordered={true}
                className="UsersTable"
                rowClassName={(record) => {
                    if (record.deleted_at && record.deleted_at > 0)
                        return 'deleted'
                    else
                        if (record.customer_id === 1)
                            return record.is_system === 1 ? 'zcom_sys' : 'zcom'

                        else
                            return ''
                }}
                loading={isLoading}
                pagination={{
                    defaultPageSize: appSetting.grid_page_size,
                    pageSizeOptions: PAGING,
                    showSizeChanger: true
                }}
                onChange={(ev) => { setPageSize(`${ev.pageSize}`) }}
                footer={() => TotalNum(Number(dataSource?.length), 'Users', dataSource)}
            />

            <Modal title={
                user && user.is_system === 1 ? (<><RobotOutlined /> &nbsp;<span>{t('createUserPage.daemon')}</span></>)
                    : (<><UserOutlined /> &nbsp;<span>{t('createUserPage.account')}</span></>)
            }
                style={{ top: 20 }}
                width={600}
                visible={modalEditVisible}
                onOk={() => setModalEditVisible(false)}
                onCancel={handleClose}
                footer={null}
                maskClosable={false}
                getContainer={false}
                confirmLoading={true}
            >
                <UserUpdateForm userId={userId} setModalVisible={setModalEditVisible} />
            </Modal>

            <Modal title={<><AiOutlineUserAdd /> &nbsp;{t('createUserPage.title')}</>}
                style={{ top: 20 }}
                width={600}
                visible={modalCreateVisible}
                onCancel={() => setModalCreateVisible(false)}
                maskClosable={false}
                footer={null}
                confirmLoading={true}
            >
                <CreateUserPage alwaysHook={createUserFormTS} setModalVisible={(visible) => setModalCreateVisible(visible)} />
            </Modal>

            <HistoryModal service='user'
                model='User'
                modelId={historyModelId}
                title={historyTitle}
                isModalVisible={isHistoryModalVisible}
                setModalVisible={() => setHistoryModalVisible(false)}
                forceUpdateId={forceUpdateHistoryId}
                modalRender={(modal) => (
                    <Draggable bounds={bounds} onStart={(ev, data) => onStart(ev, data)}>
                        <div ref={draggleRef}>{modal}</div>
                    </Draggable>
                )}
            />


        </Card>
    )
}

export default UsersPage
