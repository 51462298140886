import { Card, Radio, Tag } from "antd"
import {
    CloudUploadOutlined,
    CodeSandboxOutlined,
    ConsoleSqlOutlined,
    GlobalOutlined,
    MailOutlined, RadarChartOutlined, RocketOutlined
} from "@ant-design/icons"
import React, { ReactNode } from "react"
import { ServerModel, ServerService } from "./models"
import { Link } from "react-router-dom"
import { UseTranslationResponse } from "react-i18next"
import * as i18next from "i18next"
import Button from "antd-button-color"
import { DomainService } from "pages/dns/service/models"


export const SERVER_STATES = [{ id: 1, name: 'Active' }, { id: 2, name: 'Offline' }, { id: 3, name: 'Maintenance' }]

export const renderState = (server: ServerModel, t: i18next.TFunction) => {
    if (!server) {
        return ''
    }
    if (server.status_id === 1) {
        return (<Tag className='success'>{t('serversPage.active')}</Tag>)
    }
    if (server.status_id === 2) {
        return (<Tag className='alert'>{t('serversPage.offline')}</Tag>)
    }
    if (server.status_id === 3) {
        return (<Tag className='maintenance'>{t('serversPage.maintenance')}</Tag>)
    }
    return ''
}

export const generateServices = (service: ServerService) => {
    if (!service || !service.name) {
        return ''
    }
    const name = service.name.toLowerCase()
    if (service.id) {
        switch (name) {
            case 'dns':
                return (
                    <Link to={`/dns/services/${service.id}`}>
                        <Tag className='microTag' title='DNS Service' color='volcano' key='dns'><GlobalOutlined /></Tag>
                    </Link>)
            case 'mail':
                return (
                    <Link to={`/mail/services/${service.id}`}>
                        <Tag className='microTag' title='Mail Service' color='gold' key='mail'><MailOutlined /></Tag>
                    </Link>)
            case 'rdb':
                return (
                    <Link to={`/rdb/services/${service.id}`}>
                        <Tag className='microTag' title='SQL Service' color='green' key='rdb'><ConsoleSqlOutlined /></Tag>
                    </Link>)
            case 'web':
                return (
                    <Link to={`/web/services/${service.id}`}>
                        <Tag className='microTag' title='WEB Service' color='geekblue' key='web'><RocketOutlined /></Tag>
                    </Link>)
            case 'backup':
                return (<Tag className='microTag' title='Backup Service' color='red' key='bacula_server'><CloudUploadOutlined /></Tag>)
            case 'hypervisor':
                return (<Tag className='microTag' title='Hypervisor' color='cyan' key='hypervisor'><CodeSandboxOutlined /></Tag>)
        }
    }
    else {
        // missing service_id
        switch (name) {
            case 'dns':
                return (<Tag className='microTag' title={`DNS Service - ${service.status}`} color='gray' key='dns'><GlobalOutlined /></Tag>)
            case 'mail':
                return (<Tag className='microTag' title={`Mail Service - ${service.status}`} color='gray' key='mail'><MailOutlined /></Tag>)
            case 'rdb':
                return (<Tag className='microTag' title={`SQL Service - ${service.status}`} color='gray' key='rdb'><ConsoleSqlOutlined /></Tag>)
            case 'web':
                return (<Tag className='microTag' title={`WEB Service - ${service.status}`} color='gray' key='web'><RocketOutlined /></Tag>)
            case 'backup':
                return (<Tag className='microTag' title={`Backup Service - ${service.status}`} color='gray' key='bacula_server'><CloudUploadOutlined /></Tag>)
            case 'hypervisor':
                return (<Tag className='microTag' title={`Hypervisor - ${service.status}`} color='gray' key='hypervisor'><CodeSandboxOutlined /></Tag>)
        }
    }
}

export const generateServiceProduct = (product: DomainService) => {
    if (!product || !product.type_name) {
        return '?'
    }
    switch (product.type_name.toLowerCase()) {
        case 'dns':
            return (
                <Link to={`/dns/services/${product.service_id}`} key={`ldns${product.service_id}`}>
                    <Tag className='microTag' title='DNS Service' color='volcano' key={`dns${product.service_id}`}><GlobalOutlined /></Tag>
                </Link>)
        case 'mail':
            return (
                <Link to={`/mail/domain/${product.id}`} key={`lrdb${product.id}`}>
                    <Tag className='microTag' title={`Domain - ${product.name}`} color='gold' key={`mail${product.id}`}><MailOutlined /></Tag>
                </Link>)
        case 'rdb':
            return (
                <Link to={`/rdb/database/${product.id}`} key={`lrdb${product.id}`}>
                    <Tag className='microTag' title={`Database - ${product.name}`} color='green' key={`rdb${product.id}`}><ConsoleSqlOutlined /></Tag>
                </Link>)
        case 'web':
            return (
                <Link to={`/web/vhost/${product.id}`} key={`lweb${product.id}`}>
                    <Tag className='microTag' title={`Vhost - ${product.name}`} color='geekblue' key={`web${product.id}`}><RocketOutlined /></Tag>
                </Link>)
        case 'backup':
            return (<Tag className='microTag' title='Backup Service' color='red' key={`backup${product.service_id}`}><CloudUploadOutlined /></Tag>)
        case 'proxmox':
            return (<Tag className='microTag' title='Hypervisor' color='cyan' key={`hypervisor${product.service_id}`}><CodeSandboxOutlined /></Tag>)
    }
}

export interface ServiceTypeUI {
    id: number
    name: string
    icon: string | ReactNode
    color: string
    url: string
}

export const SERVICE_BOXES: ServiceTypeUI[] = [
    { id: 1, name: 'dns', icon: <GlobalOutlined />, color: '#da886f', url: '' },
    { id: 2, name: 'mail', icon: <MailOutlined />, color: '#dabc7c', url: '' },
    { id: 3, name: 'rdb', icon: <ConsoleSqlOutlined />, color: '#7cc55b', url: '' },
    { id: 4, name: 'web', icon: <RadarChartOutlined />, color: '#6376d7', url: '' },
    { id: 5, name: 'proxmox', icon: <CodeSandboxOutlined />, color: '#5bd2d7', url: '' },
    { id: 6, name: 'bacula_server', icon: <CloudUploadOutlined />, color: '#f14e5c', url: '' }
]

export const getServiceUiType = (name: string) => {
    if (!name) {
        return undefined
    }
    return SERVICE_BOXES.find(box => box.name === name.toLowerCase())
}

export const renderService = (name: string) => {
    if (!name) {
        return ''
    }
    const box = getServiceUiType(name)
    if (box) {
        let ser_name = box.name.replace('rdb', 'sql')
        return (<Tag icon={box.icon} className='bigger' color={box.color} key={box.color}>{ser_name} service</Tag>)
    }
    return ''
}

export const renderServiceBox = (s: ServerService, t: i18next.TFunction) => {
    if (!s) {
        return ''
    }
    const box = getServiceUiType(s.name)
    const url = `/${s.name.toLowerCase()}/services/${s.id}`
    if (box) {
        return (
            <Card title={renderService(s.name)} key={box.id.toString()} style={
                {
                    textAlign: 'center',
                    width: '20%',
                    minWidth: '240px',
                    float: 'left',
                    margin: '25px',
                    padding: '10px',
                    borderRadius: '8px',
                    border: `2px solid ${box.color}`,
                    backgroundColor: '#ededed'
                }}>
                <Link to={url}>{t('serversPage.detail')}</Link>
            </Card>
        )
    }
    return ''
}

export const renderStateSwitch = (server: ServerModel, t: i18next.TFunction) => {
    if (!server) {
        return ''
    }
    return (
        <>
            {
                server.status_id === 1 && (
                    <div>
                        <span>{t('serversPage.state')}</span> &nbsp;
                        <Button className='success' size='small' style={{ margin: 0 }}>{t('serversPage.active')}</Button>
                        <Button className='gray' size='small' style={{ margin: 0 }}>{t('serversPage.maintenance')}</Button>
                        <Button className='gray' size='small' style={{ margin: 0 }}>{t('serversPage.offline')}</Button>
                    </div>
                )
            }
            {
                server.status_id === 3 && (
                    <div>
                        <span>{t('serversPage.state')}</span> &nbsp;
                        <Button className='gray' size='small' style={{ margin: 0 }}>{t('serversPage.active')}</Button>
                        <Button className='maintenance' size='small' style={{ margin: 0 }}>{t('serversPage.maintenance')}</Button>
                        <Button className='gray' size='small' style={{ margin: 0 }}>{t('serversPage.offline')}</Button>
                    </div>
                )
            }
            {
                server.status_id === 2 && (
                    <div>
                        <span>{t('serversPage.state')}</span> &nbsp;
                        <Button value="1" className='gray' size='small' style={{ margin: 0 }}>{t('serversPage.active')}</Button>
                        <Button value="2" className='gray' size='small' style={{ margin: 0 }}>{t('serversPage.maintenance')}</Button>
                        <Button value="3" className='alert' size='small' style={{ margin: 0 }}>{t('serversPage.offline')}</Button>
                    </div>
                )
            }
        </>
    )
}

export interface ItemState {
    key: string
    valid_from: string
    status_id: number
    is_notification: 1 | 0
    description: string
}

