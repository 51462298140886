import {
    GetAppSettingRequestAction,
    GetAppSettingResponseAction,
    GetAppSettingErrorAction,
} from '.'
import { protectedApiClient } from 'helpers/api'
import { handleApiErrorWithNotification } from 'helpers/errorHandling'
import {SettingsDetails} from "../models";


export default (id: number, cb?: (isSuccess: boolean) => void) => {
    return (
        dispatch: (
            arg: GetAppSettingRequestAction | GetAppSettingResponseAction | GetAppSettingErrorAction,
        ) => void,
    ) => {
        const request = new GetAppSettingRequestAction()
        dispatch(request)

        protectedApiClient
            .get<SettingsDetails>(`/setting/${id}`)
            .then((response) => {
                dispatch(new GetAppSettingResponseAction(request, response.data))
                cb && cb(true)
            })
            .catch((error) => {
                dispatch(new GetAppSettingErrorAction(request, error))
                handleApiErrorWithNotification(error)
                cb && cb(false)
            })
    }
}
