import {
    GetServersWebRequestAction,
    GetServersWebResponseAction,
    GetServersWebErrorAction,
} from '.'
import { protectedApiClient } from 'helpers/api'
import { handleApiErrorWithNotification } from 'helpers/errorHandling'
import {ServerModel} from "../../server/models";



export default (prefix: string, cb?: (isSuccess: boolean) => void) => {
    return (
        dispatch: (
            arg:
                | GetServersWebRequestAction
                | GetServersWebResponseAction
                | GetServersWebErrorAction,
        ) => void,
    ) => {
        const request = new GetServersWebRequestAction()
        dispatch(request)

        let url = `/server/server`
        if (prefix) {
            url = `/server/server?hostname=${prefix}`
        }
        protectedApiClient
            .get<ServerModel[]>(url)
            .then((response) => {
                dispatch(new GetServersWebResponseAction(request, response.data))
                cb && cb(true)
            })
            .catch((error) => {
                dispatch(new GetServersWebErrorAction(request, error.toString()))
                handleApiErrorWithNotification(error)
                cb && cb(false)
            })
    }
}
