import {UploadCertificateRequestAction, UploadCertificateResponseAction, UploadCertificateErrorAction} from '.'
import {protectedApiClient} from 'helpers/api'
import {handleApiErrorWithNotification} from 'helpers/errorHandling'
import { CertificateModel, UploadCertificateParams} from '../models'

export default (params: UploadCertificateParams, cb?: (isSuccess: boolean) => void) => {
    return (
        dispatch: (
            arg: UploadCertificateRequestAction | UploadCertificateResponseAction | UploadCertificateErrorAction,
        ) => void,
    ) => {
        const request = new UploadCertificateRequestAction(params)
        dispatch(request)
        protectedApiClient
            .post<CertificateModel>(`/customer/${params.customer_id}/certificate-upload`, params)
            .then((response) => {
                dispatch(new UploadCertificateResponseAction(request, response.data))
                cb && cb(true)
            })
            .catch((error) => {
                dispatch(new UploadCertificateErrorAction(request, error))
                handleApiErrorWithNotification(error)
                cb && cb(false)
            })
    }
}
