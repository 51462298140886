import { GetDomainStatusRequestAction, GetDomainStatusResponseAction, GetDomainStatusErrorAction } from './index'
import { protectedApiClient } from 'helpers/api'
import { handleApiError } from 'helpers/errorHandling'
import { DomainStatus } from 'pages/dns/service/models'

export default (domain: string, cb?: (isSuccess: boolean) => void) => {
	return (
		dispatch: (arg: GetDomainStatusRequestAction | GetDomainStatusResponseAction | GetDomainStatusErrorAction) => void
	) => {
		const request = new GetDomainStatusRequestAction()
		dispatch(request)

		protectedApiClient
			.get<DomainStatus>(`/domain/dns-status?name=${domain}`)
			.then((response) => {
				dispatch(new GetDomainStatusResponseAction(request, response.data))
				cb && cb(true)
			})
			.catch((error) => {
				let msg = error.toString()
				dispatch(new GetDomainStatusErrorAction(request, msg))
				handleApiError(error)
				cb && cb(false)
			})
	}
}
