import { ActionType, ToggleMenuActions } from './actions'
import { AsyncActionMode } from 'common/models'
import { SideNavState } from './models'

const SIDENAV_INITIAL_STATE: SideNavState = {
	billing_invoices: false,
	billing_companies: false,
	billing_customers: false,
	billing_pricelists: false,
	billing_bills: false,
	rights_roles: false,
	rights_resources: false,
	rights_usergroups: false,
	rights_users: false,
	users_toggle: false,
	inventory_items: false,
	inventory_servers: false,
	inventory_locations: false,
	inventory_dim_types: false,
	datacenter_datacenters: false,
	datacenter_racks: false,
	datacenter_blocks: false,
	ip_subnets: false,
	ssl_certs: false,
	dns_domains: false,
	rdb_services: false,
	web_services: false,
	mail_services: false,
	log_servers: false,
	a_jobs: false,
}

export default (state = SIDENAV_INITIAL_STATE, action: ToggleMenuActions): SideNavState => {
	switch (action.type) {
		case ActionType.TOGGLE_MENU:
			if (action.mode === AsyncActionMode.RESPONSE) {
				switch (action.path) {
					case '/billing/invoice':
						return {
							...state,
							billing_invoices: !state.billing_invoices,
						}
						break
					case '/billing/companies':
						return {
							...state,
							billing_companies: !state.billing_companies,
						}
						break
					case '/billing/customer':
						return {
							...state,
							billing_customers: !state.billing_customers,
						}
						break
					case '/billing/pricelist':
						return {
							...state,
							billing_pricelists: !state.billing_pricelists,
						}
						break
					case '/billing/bill':
						return {
							...state,
							billing_bills: !state.billing_bills,
						}
						break
					case '/rights/role':
						return {
							...state,
							rights_roles: !state.rights_roles,
						}
						break
					case '/rights/resource-group':
						return {
							...state,
							rights_resources: !state.rights_resources,
						}
						break
					case '/rights/user-group':
						return {
							...state,
							rights_usergroups: !state.rights_usergroups,
						}
						break
					case '/rights/user':
						return {
							...state,
							rights_users: !state.rights_users,
						}
						break
					case '/users':
						return {
							...state,
							users_toggle: !state.users_toggle,
						}
						break
					case '/inventory/physical-servers':
						return {
							...state,
							inventory_servers: !state.inventory_servers,
						}
						break
					case '/inventory/dim/locations':
						return {
							...state,
							inventory_locations: !state.inventory_locations,
						}
						break
					case '/inventory/dim/types':
						return {
							...state,
							inventory_dim_types: !state.inventory_dim_types,
						}
						break
					case '/inventory':
						return {
							...state,
							inventory_items: !state.inventory_items,
						}
						break
					case '/datacenter/rack':
						return {
							...state,
							datacenter_racks: !state.datacenter_racks,
						}
						break
					case '/datacenter/block':
						return {
							...state,
							datacenter_blocks: !state.datacenter_blocks,
						}
						break
					case '/datacenter':
						return {
							...state,
							datacenter_datacenters: !state.datacenter_datacenters,
						}
						break
					case '/ip/subnets':
						return {
							...state,
							ip_subnets: !state.ip_subnets,
						}
						break
					case '/dns/domains':
						return {
							...state,
							dns_domains: !state.dns_domains,
						}
						break
					case '/dns/services':
						return {
							...state,
							dns_domains: !state.dns_domains,
						}
						break
					case '/servers':
						return {
							...state,
							log_servers: !state.log_servers,
						}
						break
					case '/jobs':
						return {
							...state,
							a_jobs: !state.a_jobs,
						}
						break
					case '/certificates':
						return {
							...state,
							ssl_certs: !state.ssl_certs,
						}
						break
					case '/rdb/databases':
						return {
							...state,
							rdb_services: !state.rdb_services,
						}
						break
					case '/web/vhosts':
						return {
							...state,
							web_services: !state.web_services,
						}
						break
					case '/web/templates':
						return {
							...state,
							web_services: !state.web_services,
						}
						break
					case '/mail/domains':
						return {
							...state,
							mail_services: !state.mail_services,
						}
						break
				}
			}
			break
	}
	return state
}
