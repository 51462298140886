import { AsyncActionMode, NameItem } from 'common/models'
import {
	CreateVhostParams,
	VhostModel,
	VhostGrid,
	WebServiceModel,
	WebTemplateModel,
	CreateWebTemplateParams,
	UpdateWebTemplateVersionsParams,
	CreateWebServiceParams,
	WebDaemonVersionResp,
	WebServerVersionResp,
	PhpVersionResp,
	AliasModel,
	AliasParams,
	VhostParams,
	ApplyChangesParams,
} from '../models'
import { ServerModel } from '../../server/models'
import { CertificateModel } from 'pages/certificate/models'
import { DomainModel, DomainStatus, ZisExistsResp, CreateDomainParam } from 'pages/dns/service/models'
import { CreateDatabaseDomainParams, CreateDatabaseParams, DatabaseModel } from 'pages/database/models'

export enum ActionType {
	CREATE_VHOST = 'CREATE_VHOST',
	GET_VHOSTS = 'GET_VHOSTS',
	GET_SERVICE_VHOSTS = 'GET_SERVICE_VHOSTS',
	GET_VHOST = 'GET_VHOST',
	GET_VHOST_CERTIFICATE = 'GET_VHOST_CERTIFICATE',
	GET_CERTIFICATES_VHOST = 'GET_CERTIFICATES_VHOST',
	APPLY_CHANGES_VHOST = 'APPLY_CHANGES_VHOST',
	UPDATE_VHOST = 'UPDATE_VHOST',
	REMOVE_CERT_FROM_VHOST = 'REMOVE_CERT_FROM_VHOST',
	DELETE_VHOST = 'DELETE_VHOST',
	DOMAINS_VHOST = 'DOMAINS_VHOST',
	DOMAIN_EXISTS = 'DOMAIN_EXISTS',
	GET_DOMAIN_STATUS = 'GET_DOMAIN_STATUS',
	GET_DNS_SERVICES = 'GET_DNS_SERVICES',
	GET_RDB_SERVICES = 'GET_RDB_SERVICES',
	GET_MAIL_SERVICES = 'GET_MAIL_SERVICES',
	GET_WEB_SERVICES = 'GET_WEB_SERVICES',

	CREATE_WEB_TEMPLATE = 'CREATE_WEB_TEMPLATE',
	GET_WEB_TEMPLATES = 'GET_WEB_TEMPLATES',
	GET_WEB_TEMPLATE = 'GET_WEB_TEMPLATE',
	UPDATE_WEB_TEMPLATE = 'UPDATE_WEB_TEMPLATE',
	DELETE_WEB_TEMPLATE = 'DELETE_WEB_TEMPLATE',
	UPDATE_WEB_TEMPLATE_VERSIONS = 'UPDATE_WEB_TEMPLATE_VERSIONS',

	GET_WEB_DAEMON = 'GET_WEB_DAEMON',
	GET_WEB_SERVER_VERSION = 'GET_WEB_SERVER_VERSION',
	GET_PHP_VERSION = 'GET_PHP_VERSION',

	GET_WEB_SERVICE = 'GET_WEB_SERVICE',
	GET_WEBSERVICE_BY_NAME = 'GET_WEBSERVICE_BY_NAME',
	DELETE_WEB_SERVICE = 'DELETE_WEB_SERVICE',
	CREATE_WEB_SERVICE = 'CREATE_WEB_SERVICE',
	GET_SERVERS_WEB = 'GET_SERVERS_WEB',
	GET_SERVICE_TEMPLATES = 'GET_SERVICE_TEMPLATES',
	CREATE_DOMAIN_VHOST = 'CREATE_DOMAIN_VHOST',
	GET_DOMAIN_VHOST = 'GET_DOMAIN_VHOST',
	CREATE_DATABASE_VHOST = 'CREATE_DATABASE_VHOST',

	GET_PHP_VERSIONS = 'GET_PHP_VERSIONS',
	GET_WEB_SERVER_VERSIONS = 'GET_WEB_SERVER_VERSIONS',
	GET_VHOST_VERSION = 'GET_VHOST_VERSION',
	GET_VHOST_APLICATIONS = 'GET_VHOST_APLICATIONS',
	GET_VHOST_APLICATIONS_BY_SERVER = 'GET_VHOST_APLICATIONS_BY_SERVER',
	ACTIVATE_VHOST = 'ACTIVATE_VHOST',
	UPDATE_CUSTOM_VHOST_CONFIG = 'UPDATE_CUSTOM_VHOST_CONFIG',
	APPLY_VHOST_CHANGES = 'APPLY_VHOST_CHANGES',
	GET_VHOST_ALIASES = 'GET_VHOST_ALIASES',
	CREATE_ALIAS = 'CREATE_ALIAS',
	DELETE_ALIAS = 'DELETE_ALIAS',
	LOOKUP_WEB_SERVICE = 'LOOKUP_WEB_SERVICE',
	LOOKUP_VHOST = 'LOOKUP_VHOST',
	PREVIEW_CONFIG = 'PREVIEW_CONFIG',
}

export type VhostActions =
	| CreateVhostRequestAction
	| CreateVhostResponseAction
	| CreateVhostErrorAction
	| GetVhostsRequestAction
	| GetVhostsResponseAction
	| GetVhostsErrorAction
	| GetServiceVhostsRequestAction
	| GetServiceVhostsResponseAction
	| GetServiceVhostsErrorAction
	| GetVhostRequestAction
	| GetVhostResponseAction
	| GetVhostErrorAction
	| ApplyChangesVhostRequestAction
	| ApplyChangesVhostResponseAction
	| ApplyChangesVhostErrorAction
	| UpdateVhostRequestAction
	| UpdateVhostResponseAction
	| UpdateVhostErrorAction
	| RemoveCertFromVhostRequestAction
	| RemoveCertFromVhostResponseAction
	| RemoveCertFromVhostErrorAction
	| DeleteVhostRequestAction
	| DeleteVhostResponseAction
	| DeleteVhostErrorAction
	| GetWebServiceRequestAction
	| GetWebServiceResponseAction
	| GetWebServiceErrorAction
	| GetWebServiceByNameRequestAction
	| GetWebServiceByNameResponseAction
	| GetWebServiceByNameErrorAction
	| CreateWebServiceRequestAction
	| CreateWebServiceResponseAction
	| CreateWebServiceErrorAction
	| DeleteWebServiceRequestAction
	| DeleteWebServiceResponseAction
	| DeleteWebServiceErrorAction
	| CreateWebTemplateRequestAction
	| CreateWebTemplateResponseAction
	| CreateWebTemplateErrorAction
	| GetWebTemplatesRequestAction
	| GetWebTemplatesResponseAction
	| GetWebTemplatesErrorAction
	| GetWebTemplateRequestAction
	| GetWebTemplateResponseAction
	| GetWebTemplateErrorAction
	| GetCertificatesVhostRequestAction
	| GetCertificatesVhostResponseAction
	| GetCertificatesVhostErrorAction
	| UpdateWebTemplateRequestAction
	| UpdateWebTemplateResponseAction
	| UpdateWebTemplateErrorAction
	| DeleteWebTemplateRequestAction
	| DeleteWebTemplateResponseAction
	| DeleteWebTemplateErrorAction
	| GetWebServerVersionsRequestAction
	| GetWebServerVersionsResponseAction
	| GetWebServerVersionsErrorAction
	| GetPhpVersionsRequestAction
	| GetPhpVersionsResponseAction
	| GetPhpVersionsErrorAction
	| UpdateWebTemplateVersionsRequestAction
	| UpdateWebTemplateVersionsResponseAction
	| UpdateWebTemplateVersionsErrorAction
	| GetServersWebRequestAction
	| GetServersWebResponseAction
	| GetServersWebErrorAction
	| GetServiceTemplatesRequestAction
	| GetServiceTemplatesResponseAction
	| GetServiceTemplatesErrorAction
	| GetWebDaemonRequestAction
	| GetWebDaemonResponseAction
	| GetWebDaemonErrorAction
	| GetWebServerVersionRequestAction
	| GetWebServerVersionResponseAction
	| GetWebServerVersionErrorAction
	| GetPhpVersionRequestAction
	| GetPhpVersionResponseAction
	| GetPhpVersionErrorAction
	| GetVhostAliasesRequestAction
	| GetVhostAliasesResponseAction
	| GetVhostAliasesErrorAction
	| CreateAliasRequestAction
	| CreateAliasResponseAction
	| CreateAliasErrorAction
	| DeleteAliasRequestAction
	| DeleteAliasResponseAction
	| DeleteAliasErrorAction
	| GetCertificateRequestAction
	| GetCertificateResponseAction
	| GetCertificateErrorAction
	| LookupWebServiceRequestAction
	| LookupWebServiceResponseAction
	| LookupWebServiceErrorAction
	| LookupVhostRequestAction
	| LookupVhostResponseAction
	| LookupVhostErrorAction
	| GetDomainsVhostRequestAction
	| GetDomainsVhostResponseAction
	| GetDomainsVhostErrorAction
	| GetDomainVhostRequestAction
	| GetDomainVhostResponseAction
	| GetDomainVhostErrorAction
	| DomainExistsRequestAction
	| DomainExistsResponseAction
	| DomainExistsErrorAction
	| GetDomainStatusRequestAction
	| GetDomainStatusResponseAction
	| GetDomainStatusErrorAction
	| GetDnsServicesRequestAction
	| GetDnsServicesResponseAction
	| GetDnsServicesErrorAction
	| GetRdbServicesRequestAction
	| GetRdbServicesResponseAction
	| GetRdbServicesErrorAction
	| GetMailServicesRequestAction
	| GetMailServicesResponseAction
	| GetMailServicesErrorAction
	| CreateDomainVhostRequestAction
	| CreateDomainVhostResponseAction
	| CreateDomainVhostErrorAction
	| CreateDatabaseVhostRequestAction
	| CreateDatabaseVhostResponseAction
	| CreateDatabaseVhostErrorAction
	| PreviewConfigRequestAction
	| PreviewConfigResponseAction
	| PreviewConfigErrorAction
	| GetWebServicesRequestAction
	| GetWebServicesResponseAction
	| GetWebServicesErrorAction

export class CreateVhostRequestAction {
	readonly type = ActionType.CREATE_VHOST
	readonly mode = AsyncActionMode.REQUEST
	constructor(public payload: CreateVhostParams) {}
}
export class CreateVhostResponseAction {
	readonly type = ActionType.CREATE_VHOST
	readonly mode = AsyncActionMode.RESPONSE
	constructor(public request: CreateVhostRequestAction, public data: VhostModel) {}
}
export class CreateVhostErrorAction {
	readonly type = ActionType.CREATE_VHOST
	readonly mode = AsyncActionMode.ERROR
	constructor(public request: CreateVhostRequestAction, public error: string) {}
}

export class GetVhostRequestAction {
	readonly type = ActionType.GET_VHOST
	readonly mode = AsyncActionMode.REQUEST
	constructor(public payload: number) {
		''
	}
}
export class GetVhostResponseAction {
	readonly type = ActionType.GET_VHOST
	readonly mode = AsyncActionMode.RESPONSE
	constructor(public request: GetVhostRequestAction, public data: VhostModel) {}
}
export class GetVhostErrorAction {
	readonly type = ActionType.GET_VHOST
	readonly mode = AsyncActionMode.ERROR
	constructor(public request: GetVhostRequestAction, public error: string) {}
}

export class GetServiceVhostsRequestAction {
	readonly type = ActionType.GET_SERVICE_VHOSTS
	readonly mode = AsyncActionMode.REQUEST
	constructor(public id: number) {
		''
	}
}
export class GetServiceVhostsResponseAction {
	readonly type = ActionType.GET_SERVICE_VHOSTS
	readonly mode = AsyncActionMode.RESPONSE
	constructor(public request: GetServiceVhostsRequestAction, public data: VhostModel[]) {}
}
export class GetServiceVhostsErrorAction {
	readonly type = ActionType.GET_SERVICE_VHOSTS
	readonly mode = AsyncActionMode.ERROR
	constructor(public request: GetServiceVhostsRequestAction, public error: string) {}
}

export class GetVhostsRequestAction {
	readonly type = ActionType.GET_VHOSTS
	readonly mode = AsyncActionMode.REQUEST
	constructor() {
		''
	}
}
export class GetVhostsResponseAction {
	readonly type = ActionType.GET_VHOSTS
	readonly mode = AsyncActionMode.RESPONSE
	constructor(public request: GetVhostsRequestAction, public data: VhostGrid) {}
}
export class GetVhostsErrorAction {
	readonly type = ActionType.GET_VHOSTS
	readonly mode = AsyncActionMode.ERROR
	constructor(public request: GetVhostsRequestAction, public error: string) {}
}

export class UpdateVhostRequestAction {
	readonly type = ActionType.UPDATE_VHOST
	readonly mode = AsyncActionMode.REQUEST
	constructor(public data: VhostParams) {}
}
export class UpdateVhostResponseAction {
	readonly type = ActionType.UPDATE_VHOST
	readonly mode = AsyncActionMode.RESPONSE
	constructor(public request: UpdateVhostRequestAction, public data: VhostModel) {}
}
export class UpdateVhostErrorAction {
	readonly type = ActionType.UPDATE_VHOST
	readonly mode = AsyncActionMode.ERROR
	constructor(public request: UpdateVhostRequestAction, public error: string) {}
}

export class RemoveCertFromVhostRequestAction {
	readonly type = ActionType.REMOVE_CERT_FROM_VHOST
	readonly mode = AsyncActionMode.REQUEST
	constructor(public id: number) {
		''
	}
}
export class RemoveCertFromVhostResponseAction {
	readonly type = ActionType.REMOVE_CERT_FROM_VHOST
	readonly mode = AsyncActionMode.RESPONSE
	constructor(public request: RemoveCertFromVhostRequestAction, public data: VhostModel) {}
}
export class RemoveCertFromVhostErrorAction {
	readonly type = ActionType.REMOVE_CERT_FROM_VHOST
	readonly mode = AsyncActionMode.ERROR
	constructor(public request: RemoveCertFromVhostRequestAction, public error: string) {}
}
export class DeleteVhostRequestAction {
	readonly type = ActionType.DELETE_VHOST
	readonly mode = AsyncActionMode.REQUEST
	constructor(public id: number) {
		''
	}
}
export class DeleteVhostResponseAction {
	readonly type = ActionType.DELETE_VHOST
	readonly mode = AsyncActionMode.RESPONSE
	constructor(public request: DeleteVhostRequestAction, public data: VhostModel) {}
}
export class DeleteVhostErrorAction {
	readonly type = ActionType.DELETE_VHOST
	readonly mode = AsyncActionMode.ERROR
	constructor(public request: DeleteVhostRequestAction, public error: string) {}
}

export class CreateWebTemplateRequestAction {
	readonly type = ActionType.CREATE_WEB_TEMPLATE
	readonly mode = AsyncActionMode.REQUEST
	constructor(public payload: CreateWebTemplateParams) {}
}
export class CreateWebTemplateResponseAction {
	readonly type = ActionType.CREATE_WEB_TEMPLATE
	readonly mode = AsyncActionMode.RESPONSE
	constructor(public request: CreateWebTemplateRequestAction, public data: WebTemplateModel) {}
}
export class CreateWebTemplateErrorAction {
	readonly type = ActionType.CREATE_WEB_TEMPLATE
	readonly mode = AsyncActionMode.ERROR
	constructor(public request: CreateWebTemplateRequestAction, public error: string) {}
}

export class GetWebTemplateRequestAction {
	readonly type = ActionType.GET_WEB_TEMPLATE
	readonly mode = AsyncActionMode.REQUEST
	constructor(public payload: number) {
		''
	}
}
export class GetWebTemplateResponseAction {
	readonly type = ActionType.GET_WEB_TEMPLATE
	readonly mode = AsyncActionMode.RESPONSE
	constructor(public request: GetWebTemplateRequestAction, public data: WebTemplateModel) {}
}
export class GetWebTemplateErrorAction {
	readonly type = ActionType.GET_WEB_TEMPLATE
	readonly mode = AsyncActionMode.ERROR
	constructor(public request: GetWebTemplateRequestAction, public error: string) {}
}

export class GetWebTemplatesRequestAction {
	readonly type = ActionType.GET_WEB_TEMPLATES
	readonly mode = AsyncActionMode.REQUEST
	constructor() {
		''
	}
}
export class GetWebTemplatesResponseAction {
	readonly type = ActionType.GET_WEB_TEMPLATES
	readonly mode = AsyncActionMode.RESPONSE
	constructor(public request: GetWebTemplatesRequestAction, public data: WebTemplateModel[]) {}
}
export class GetWebTemplatesErrorAction {
	readonly type = ActionType.GET_WEB_TEMPLATES
	readonly mode = AsyncActionMode.ERROR
	constructor(public request: GetWebTemplatesRequestAction, public error: string) {}
}

export class UpdateWebTemplateRequestAction {
	readonly type = ActionType.UPDATE_WEB_TEMPLATE
	readonly mode = AsyncActionMode.REQUEST
	constructor(public data: WebTemplateModel) {}
}
export class UpdateWebTemplateResponseAction {
	readonly type = ActionType.UPDATE_WEB_TEMPLATE
	readonly mode = AsyncActionMode.RESPONSE
	constructor(public request: UpdateWebTemplateRequestAction, public data: WebTemplateModel) {}
}
export class UpdateWebTemplateErrorAction {
	readonly type = ActionType.UPDATE_WEB_TEMPLATE
	readonly mode = AsyncActionMode.ERROR
	constructor(public request: UpdateWebTemplateRequestAction, public error: string) {}
}

export class UpdateWebTemplateVersionsRequestAction {
	readonly type = ActionType.UPDATE_WEB_TEMPLATE_VERSIONS
	readonly mode = AsyncActionMode.REQUEST
	constructor(public data: UpdateWebTemplateVersionsParams) {}
}
export class UpdateWebTemplateVersionsResponseAction {
	readonly type = ActionType.UPDATE_WEB_TEMPLATE_VERSIONS
	readonly mode = AsyncActionMode.RESPONSE
	constructor(public request: UpdateWebTemplateVersionsRequestAction, public data: WebTemplateModel) {}
}
export class UpdateWebTemplateVersionsErrorAction {
	readonly type = ActionType.UPDATE_WEB_TEMPLATE_VERSIONS
	readonly mode = AsyncActionMode.ERROR
	constructor(public request: UpdateWebTemplateVersionsRequestAction, public error: string) {}
}

export class DeleteWebTemplateRequestAction {
	readonly type = ActionType.DELETE_WEB_TEMPLATE
	readonly mode = AsyncActionMode.REQUEST
	constructor(public id: number) {
		''
	}
}
export class DeleteWebTemplateResponseAction {
	readonly type = ActionType.DELETE_WEB_TEMPLATE
	readonly mode = AsyncActionMode.RESPONSE
	constructor(public request: DeleteWebTemplateRequestAction, public data: WebTemplateModel) {}
}
export class DeleteWebTemplateErrorAction {
	readonly type = ActionType.DELETE_WEB_TEMPLATE
	readonly mode = AsyncActionMode.ERROR
	constructor(public request: DeleteWebTemplateRequestAction, public error: string) {}
}

export class GetWebServiceRequestAction {
	readonly type = ActionType.GET_WEB_SERVICE
	readonly mode = AsyncActionMode.REQUEST
	constructor(public id: number) {
		''
	}
}
export class GetWebServiceResponseAction {
	readonly type = ActionType.GET_WEB_SERVICE
	readonly mode = AsyncActionMode.RESPONSE
	constructor(public request: GetWebServiceRequestAction, public data: WebServiceModel) {}
}
export class GetWebServiceErrorAction {
	readonly type = ActionType.GET_WEB_SERVICE
	readonly mode = AsyncActionMode.ERROR
	constructor(public request: GetWebServiceRequestAction, public error: string) {}
}

export class DeleteWebServiceRequestAction {
	readonly type = ActionType.DELETE_WEB_SERVICE
	readonly mode = AsyncActionMode.REQUEST
	constructor(public id: number) {
		''
	}
}
export class DeleteWebServiceResponseAction {
	readonly type = ActionType.DELETE_WEB_SERVICE
	readonly mode = AsyncActionMode.RESPONSE
	constructor(public request: DeleteWebServiceRequestAction, public data: WebServiceModel) {}
}
export class DeleteWebServiceErrorAction {
	readonly type = ActionType.DELETE_WEB_SERVICE
	readonly mode = AsyncActionMode.ERROR
	constructor(public request: DeleteWebServiceRequestAction, public error: string) {}
}

export class CreateWebServiceRequestAction {
	readonly type = ActionType.CREATE_WEB_SERVICE
	readonly mode = AsyncActionMode.REQUEST
	constructor(public data: CreateWebServiceParams) {
		''
	}
}
export class CreateWebServiceResponseAction {
	readonly type = ActionType.CREATE_WEB_SERVICE
	readonly mode = AsyncActionMode.RESPONSE
	constructor(public request: CreateWebServiceRequestAction, public data: WebServiceModel) {}
}
export class CreateWebServiceErrorAction {
	readonly type = ActionType.CREATE_WEB_SERVICE
	readonly mode = AsyncActionMode.ERROR
	constructor(public request: CreateWebServiceRequestAction, public error: string) {}
}
export class GetPhpVersionsRequestAction {
	readonly type = ActionType.GET_PHP_VERSIONS
	readonly mode = AsyncActionMode.REQUEST
	constructor() {
		''
	}
}
export class GetPhpVersionsResponseAction {
	readonly type = ActionType.GET_PHP_VERSIONS
	readonly mode = AsyncActionMode.RESPONSE
	constructor(public request: GetPhpVersionsRequestAction, public data: NameItem[]) {}
}
export class GetPhpVersionsErrorAction {
	readonly type = ActionType.GET_PHP_VERSIONS
	readonly mode = AsyncActionMode.ERROR
	constructor(public request: GetPhpVersionsRequestAction, public error: string) {}
}

export class GetWebServerVersionsRequestAction {
	readonly type = ActionType.GET_WEB_SERVER_VERSIONS
	readonly mode = AsyncActionMode.REQUEST
	constructor() {}
}
export class GetWebServerVersionsResponseAction {
	readonly type = ActionType.GET_WEB_SERVER_VERSIONS
	readonly mode = AsyncActionMode.RESPONSE
	constructor(public request: GetWebServerVersionsRequestAction, public data: NameItem[]) {}
}
export class GetWebServerVersionsErrorAction {
	readonly type = ActionType.GET_WEB_SERVER_VERSIONS
	readonly mode = AsyncActionMode.ERROR
	constructor(public request: GetWebServerVersionsRequestAction, public error: string) {}
}

export class GetServersWebRequestAction {
	readonly type = ActionType.GET_SERVERS_WEB
	readonly mode = AsyncActionMode.REQUEST
	constructor() {}
}
export class GetServersWebResponseAction {
	readonly type = ActionType.GET_SERVERS_WEB
	readonly mode = AsyncActionMode.RESPONSE
	constructor(public request: GetServersWebRequestAction, public data: ServerModel[]) {}
}
export class GetServersWebErrorAction {
	readonly type = ActionType.GET_SERVERS_WEB
	readonly mode = AsyncActionMode.ERROR
	constructor(public request: GetServersWebRequestAction, public error: string) {}
}

export class GetServiceTemplatesRequestAction {
	readonly type = ActionType.GET_SERVICE_TEMPLATES
	readonly mode = AsyncActionMode.REQUEST
	constructor(public id: number) {
		''
	}
}

export class GetServiceTemplatesResponseAction {
	readonly type = ActionType.GET_SERVICE_TEMPLATES
	readonly mode = AsyncActionMode.RESPONSE
	constructor(public request: GetServiceTemplatesRequestAction, public data: NameItem[]) {}
}

export class GetServiceTemplatesErrorAction {
	readonly type = ActionType.GET_SERVICE_TEMPLATES
	readonly mode = AsyncActionMode.ERROR
	constructor(public request: GetServiceTemplatesRequestAction, public error: string) {}
}

export class GetWebDaemonRequestAction {
	readonly type = ActionType.GET_WEB_DAEMON
	readonly mode = AsyncActionMode.REQUEST
	constructor(public id: number) {
		''
	}
}

export class GetWebDaemonResponseAction {
	readonly type = ActionType.GET_WEB_DAEMON
	readonly mode = AsyncActionMode.RESPONSE
	constructor(public request: GetWebDaemonRequestAction, public data: WebDaemonVersionResp) {}
}

export class GetWebDaemonErrorAction {
	readonly type = ActionType.GET_WEB_DAEMON
	readonly mode = AsyncActionMode.ERROR
	constructor(public request: GetWebDaemonRequestAction, public error: string) {}
}

export class GetWebServerVersionRequestAction {
	readonly type = ActionType.GET_WEB_SERVER_VERSION
	readonly mode = AsyncActionMode.REQUEST
	constructor(public id: number) {
		''
	}
}

export class GetWebServerVersionResponseAction {
	readonly type = ActionType.GET_WEB_SERVER_VERSION
	readonly mode = AsyncActionMode.RESPONSE
	constructor(public request: GetWebServerVersionRequestAction, public data: WebServerVersionResp) {}
}

export class GetWebServerVersionErrorAction {
	readonly type = ActionType.GET_WEB_SERVER_VERSION
	readonly mode = AsyncActionMode.ERROR
	constructor(public request: GetWebServerVersionRequestAction, public error: string) {}
}

export class GetPhpVersionRequestAction {
	readonly type = ActionType.GET_PHP_VERSION
	readonly mode = AsyncActionMode.REQUEST
	constructor() {}
}

export class GetPhpVersionResponseAction {
	readonly type = ActionType.GET_PHP_VERSION
	readonly mode = AsyncActionMode.RESPONSE
	constructor(public request: GetPhpVersionRequestAction, public data: PhpVersionResp) {}
}

export class GetPhpVersionErrorAction {
	readonly type = ActionType.GET_PHP_VERSION
	readonly mode = AsyncActionMode.ERROR
	constructor(public request: GetPhpVersionRequestAction, public error: string) {}
}

export class GetVhostAliasesRequestAction {
	readonly type = ActionType.GET_VHOST_ALIASES
	readonly mode = AsyncActionMode.REQUEST
	constructor(public id: number) {
		''
	}
}

export class GetVhostAliasesResponseAction {
	readonly type = ActionType.GET_VHOST_ALIASES
	readonly mode = AsyncActionMode.RESPONSE
	constructor(public request: GetVhostAliasesRequestAction, public data: AliasModel[]) {}
}

export class GetVhostAliasesErrorAction {
	readonly type = ActionType.GET_VHOST_ALIASES
	readonly mode = AsyncActionMode.ERROR
	constructor(public request: GetVhostAliasesRequestAction, public error: string) {}
}

export class CreateAliasRequestAction {
	readonly type = ActionType.CREATE_ALIAS
	readonly mode = AsyncActionMode.REQUEST
	constructor(public payload: AliasModel) {}
}

export class CreateAliasResponseAction {
	readonly type = ActionType.CREATE_ALIAS
	readonly mode = AsyncActionMode.RESPONSE
	constructor(public request: CreateAliasRequestAction, public data: AliasModel) {}
}

export class CreateAliasErrorAction {
	readonly type = ActionType.CREATE_ALIAS
	readonly mode = AsyncActionMode.ERROR
	constructor(public request: CreateAliasRequestAction, public error: string) {}
}

export class DeleteAliasRequestAction {
	readonly type = ActionType.DELETE_ALIAS
	readonly mode = AsyncActionMode.REQUEST
	constructor(public params: AliasParams) {}
}

export class DeleteAliasResponseAction {
	readonly type = ActionType.DELETE_ALIAS
	readonly mode = AsyncActionMode.RESPONSE
	constructor(public request: DeleteAliasRequestAction, public data: AliasModel) {}
}

export class DeleteAliasErrorAction {
	readonly type = ActionType.DELETE_ALIAS
	readonly mode = AsyncActionMode.ERROR
	constructor(public request: DeleteAliasRequestAction, public error: string) {}
}

// Request Action
export class GetCertificateRequestAction {
	public readonly type = ActionType.GET_VHOST_CERTIFICATE
	public readonly mode = AsyncActionMode.REQUEST

	constructor(public id: number) {}
}

// Response Action
export class GetCertificateResponseAction {
	public readonly type = ActionType.GET_VHOST_CERTIFICATE
	public readonly mode = AsyncActionMode.RESPONSE
	constructor(public request: GetCertificateRequestAction, public data: CertificateModel) {}
}

// Error Action
export class GetCertificateErrorAction {
	public readonly type = ActionType.GET_VHOST_CERTIFICATE
	public readonly mode = AsyncActionMode.ERROR

	constructor(public request: GetCertificateRequestAction, public error: string) {}
}

// Request Action
export class LookupWebServiceRequestAction {
	public readonly type = ActionType.LOOKUP_WEB_SERVICE
	public readonly mode = AsyncActionMode.REQUEST

	constructor(public field: string) {}
}

// Response Action
export class LookupWebServiceResponseAction {
	public readonly type = ActionType.LOOKUP_WEB_SERVICE
	public readonly mode = AsyncActionMode.RESPONSE

	constructor(public request: LookupWebServiceRequestAction, public field: string, public data: string[]) {}
}

// Error Action
export class LookupWebServiceErrorAction {
	public readonly type = ActionType.LOOKUP_WEB_SERVICE
	public readonly mode = AsyncActionMode.ERROR

	constructor(public request: LookupWebServiceRequestAction, public error: string) {}
}

export class LookupVhostRequestAction {
	public readonly type = ActionType.LOOKUP_VHOST
	public readonly mode = AsyncActionMode.REQUEST

	constructor(public field: string) {}
}

// Response Action
export class LookupVhostResponseAction {
	public readonly type = ActionType.LOOKUP_VHOST
	public readonly mode = AsyncActionMode.RESPONSE

	constructor(public request: LookupVhostRequestAction, public field: string, public data: string[]) {}
}

// Error Action
export class LookupVhostErrorAction {
	public readonly type = ActionType.LOOKUP_VHOST
	public readonly mode = AsyncActionMode.ERROR

	constructor(public request: LookupVhostRequestAction, public error: string) {}
}

export class GetWebServiceByNameRequestAction {
	public readonly type = ActionType.GET_WEBSERVICE_BY_NAME
	public readonly mode = AsyncActionMode.REQUEST

	constructor(public name: string) {}
}

export class GetWebServiceByNameResponseAction {
	public readonly type = ActionType.GET_WEBSERVICE_BY_NAME
	public readonly mode = AsyncActionMode.RESPONSE

	constructor(public request: GetWebServiceByNameRequestAction, public data: WebServiceModel[]) {}
}

export class GetWebServiceByNameErrorAction {
	public readonly type = ActionType.GET_WEBSERVICE_BY_NAME
	public readonly mode = AsyncActionMode.ERROR

	constructor(public request: GetWebServiceByNameRequestAction, public error: string) {}
}

export class GetDomainsVhostRequestAction {
	public readonly type = ActionType.DOMAINS_VHOST
	public readonly mode = AsyncActionMode.REQUEST

	constructor() {}
}

export class GetDomainsVhostResponseAction {
	public readonly type = ActionType.DOMAINS_VHOST
	public readonly mode = AsyncActionMode.RESPONSE

	constructor(public request: GetDomainsVhostRequestAction, public data: DomainModel[]) {}
}

export class GetDomainsVhostErrorAction {
	public readonly type = ActionType.DOMAINS_VHOST
	public readonly mode = AsyncActionMode.ERROR

	constructor(public request: GetDomainsVhostRequestAction, public error: string) {}
}

export class GetDomainStatusRequestAction {
	public readonly type = ActionType.GET_DOMAIN_STATUS
	public readonly mode = AsyncActionMode.REQUEST

	constructor() {}
}

export class GetDomainStatusResponseAction {
	public readonly type = ActionType.GET_DOMAIN_STATUS
	public readonly mode = AsyncActionMode.RESPONSE

	constructor(public request: GetDomainStatusRequestAction, public data: DomainStatus) {}
}

export class GetDomainStatusErrorAction {
	public readonly type = ActionType.GET_DOMAIN_STATUS
	public readonly mode = AsyncActionMode.ERROR

	constructor(public request: GetDomainStatusRequestAction, public error: string) {}
}

export class DomainExistsRequestAction {
	public readonly type = ActionType.DOMAIN_EXISTS
	public readonly mode = AsyncActionMode.REQUEST

	constructor() {}
}

export class DomainExistsResponseAction {
	public readonly type = ActionType.DOMAIN_EXISTS
	public readonly mode = AsyncActionMode.RESPONSE

	constructor(public request: DomainExistsRequestAction, public data: ZisExistsResp) {}
}

export class DomainExistsErrorAction {
	public readonly type = ActionType.DOMAIN_EXISTS
	public readonly mode = AsyncActionMode.ERROR

	constructor(public request: DomainExistsRequestAction, public error: string) {}
}

export class GetDnsServicesRequestAction {
	readonly type = ActionType.GET_DNS_SERVICES
	readonly mode = AsyncActionMode.REQUEST
	constructor() {}
}

export class GetDnsServicesResponseAction {
	readonly type = ActionType.GET_DNS_SERVICES
	readonly mode = AsyncActionMode.RESPONSE
	constructor(public request: GetDnsServicesRequestAction, public data: NameItem[]) {}
}

export class GetDnsServicesErrorAction {
	readonly type = ActionType.GET_DNS_SERVICES
	readonly mode = AsyncActionMode.ERROR
	constructor(public request: GetDnsServicesRequestAction, public error: any) {}
}
export class GetRdbServicesRequestAction {
	readonly type = ActionType.GET_RDB_SERVICES
	readonly mode = AsyncActionMode.REQUEST
	constructor() {}
}

export class GetRdbServicesResponseAction {
	readonly type = ActionType.GET_RDB_SERVICES
	readonly mode = AsyncActionMode.RESPONSE
	constructor(public request: GetRdbServicesRequestAction, public data: NameItem[]) {}
}

export class GetRdbServicesErrorAction {
	readonly type = ActionType.GET_RDB_SERVICES
	readonly mode = AsyncActionMode.ERROR
	constructor(public request: GetRdbServicesRequestAction, public error: any) {}
}

export class GetMailServicesRequestAction {
	readonly type = ActionType.GET_MAIL_SERVICES
	readonly mode = AsyncActionMode.REQUEST
	constructor() {}
}

export class GetMailServicesResponseAction {
	readonly type = ActionType.GET_MAIL_SERVICES
	readonly mode = AsyncActionMode.RESPONSE
	constructor(public request: GetMailServicesRequestAction, public data: NameItem[]) {}
}

export class GetMailServicesErrorAction {
	readonly type = ActionType.GET_MAIL_SERVICES
	readonly mode = AsyncActionMode.ERROR
	constructor(public request: GetMailServicesRequestAction, public error: any) {}
}

export class CreateDomainVhostRequestAction {
	public readonly type = ActionType.CREATE_DOMAIN_VHOST
	public readonly mode = AsyncActionMode.REQUEST

	constructor(public params: CreateDomainParam) {}
}

export class CreateDomainVhostResponseAction {
	public readonly type = ActionType.CREATE_DOMAIN_VHOST
	public readonly mode = AsyncActionMode.RESPONSE

	constructor(public request: CreateDomainVhostRequestAction, public data: DomainModel) {}
}

export class CreateDomainVhostErrorAction {
	public readonly type = ActionType.CREATE_DOMAIN_VHOST
	public readonly mode = AsyncActionMode.ERROR

	constructor(public request: CreateDomainVhostRequestAction, public error: string) {}
}

export class CreateDatabaseVhostRequestAction {
	public readonly type = ActionType.CREATE_DATABASE_VHOST
	public readonly mode = AsyncActionMode.REQUEST

	constructor(public params: CreateDatabaseDomainParams) {}
}

export class CreateDatabaseVhostResponseAction {
	public readonly type = ActionType.CREATE_DATABASE_VHOST
	public readonly mode = AsyncActionMode.RESPONSE

	constructor(public request: CreateDatabaseVhostRequestAction, public data: DatabaseModel) {}
}

export class CreateDatabaseVhostErrorAction {
	public readonly type = ActionType.CREATE_DATABASE_VHOST
	public readonly mode = AsyncActionMode.ERROR

	constructor(public request: CreateDatabaseVhostRequestAction, public error: string) {}
}

export class GetDomainVhostRequestAction {
	readonly type = ActionType.GET_DOMAIN_VHOST
	readonly mode = AsyncActionMode.REQUEST
	constructor(public id: number) {}
}

export class GetDomainVhostResponseAction {
	readonly type = ActionType.GET_DOMAIN_VHOST
	readonly mode = AsyncActionMode.RESPONSE
	constructor(public request: GetDomainVhostRequestAction, public data: DomainModel) {}
}

export class GetDomainVhostErrorAction {
	readonly type = ActionType.GET_DOMAIN_VHOST
	readonly mode = AsyncActionMode.ERROR
	constructor(public request: GetDomainVhostRequestAction, public error: any) {}
}

export class ApplyChangesVhostRequestAction {
	readonly type = ActionType.APPLY_CHANGES_VHOST
	readonly mode = AsyncActionMode.REQUEST
	constructor(public id: number) {}
}

export class ApplyChangesVhostResponseAction {
	readonly type = ActionType.APPLY_CHANGES_VHOST
	readonly mode = AsyncActionMode.RESPONSE
	constructor(public request: ApplyChangesVhostRequestAction, public data: VhostModel) {}
}

export class ApplyChangesVhostErrorAction {
	readonly type = ActionType.APPLY_CHANGES_VHOST
	readonly mode = AsyncActionMode.ERROR
	constructor(public request: ApplyChangesVhostRequestAction, public error: string) {}
}

export class GetCertificatesVhostRequestAction {
	readonly mode = AsyncActionMode.REQUEST
	readonly type = ActionType.GET_CERTIFICATES_VHOST
	constructor() {}
}

export class GetCertificatesVhostResponseAction {
	readonly mode = AsyncActionMode.RESPONSE
	readonly type = ActionType.GET_CERTIFICATES_VHOST
	constructor(public request: GetCertificatesVhostRequestAction, public data: NameItem[]) {}
}

export class GetCertificatesVhostErrorAction {
	readonly mode = AsyncActionMode.ERROR
	readonly type = ActionType.GET_CERTIFICATES_VHOST
	constructor(public request: GetCertificatesVhostRequestAction, public error: any) {}
}

export class PreviewConfigRequestAction {
	public readonly type = ActionType.PREVIEW_CONFIG
	public readonly mode = AsyncActionMode.REQUEST

	constructor(public id: number) {}
}

export class PreviewConfigResponseAction {
	public readonly type = ActionType.PREVIEW_CONFIG
	public readonly mode = AsyncActionMode.RESPONSE

	constructor(public request: PreviewConfigRequestAction, public data: string) {}
}

export class PreviewConfigErrorAction {
	public readonly type = ActionType.PREVIEW_CONFIG
	public readonly mode = AsyncActionMode.ERROR

	constructor(public request: PreviewConfigRequestAction, public error: string) {}
}

export class GetWebServicesRequestAction {
	readonly type = ActionType.GET_WEB_SERVICES
	readonly mode = AsyncActionMode.REQUEST
	constructor() {}
}

export class GetWebServicesResponseAction {
	readonly type = ActionType.GET_WEB_SERVICES
	readonly mode = AsyncActionMode.RESPONSE
	constructor(public request: GetWebServicesRequestAction, public data: NameItem[]) {}
}

export class GetWebServicesErrorAction {
	readonly type = ActionType.GET_WEB_SERVICES
	readonly mode = AsyncActionMode.ERROR
	constructor(public request: GetWebServicesRequestAction, public error: any) {}
}
