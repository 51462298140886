import { CreateDatabaseVhostRequestAction, CreateDatabaseVhostResponseAction, CreateDatabaseVhostErrorAction } from '.'
import { protectedApiClient } from 'helpers/api'
import { handleApiErrorWithNotification } from 'helpers/errorHandling'
import { CreateDatabaseDomainParams, DatabaseModel } from '../../database/models'

export default (params: CreateDatabaseDomainParams, cb?: (isSuccess: boolean, id?: number) => void) => {
	return (
		dispatch: (
			arg: CreateDatabaseVhostRequestAction | CreateDatabaseVhostResponseAction | CreateDatabaseVhostErrorAction
		) => void
	) => {
		const request = new CreateDatabaseVhostRequestAction(params)
		dispatch(request)
		protectedApiClient
			.post<DatabaseModel>(`/domain/${params.domain_id}/database?expand=service,customer`, params)
			.then((response) => {
				dispatch(new CreateDatabaseVhostResponseAction(request, response.data))
				cb && cb(true, response.data.id)
			})
			.catch((error) => {
				let msg = ''
				if (error) {
					if (error.message) {
						msg = error.message
					} else {
						msg = error.toString()
					}
				}
				dispatch(new CreateDatabaseVhostErrorAction(request, msg))
				handleApiErrorWithNotification(error)
				cb && cb(false)
			})
	}
}
