import { useTranslation } from "react-i18next"
import { useHistory } from "react-router"
import { useDispatch, useSelector } from "react-redux"
import { AppState } from "../../common/models"
import React, { useEffect, useRef, useState } from "react"
import { useLoggedUser } from "../../helpers/loginUserHelper"
import { LoadingIndicator } from "../../components"
import ErrorPage403 from "../../components/Errors/ErrorPage403"
import useLogger from "../../common/useLogger"
import Draggable, { DraggableData, DraggableEvent } from "react-draggable"
import { removeDiac, stopPropagation } from "../../common/fce"
import { VhostModel } from "./models"
import { ColumnsType } from "antd/lib/table"
import { AutoComplete, Card, message, Modal, Space, Spin, Table, Tag } from "antd"
import Button from "antd-button-color"
import { InfoCircleTwoTone, RadarChartOutlined, } from "@ant-design/icons"
import { GlobalOutlined, PlusCircleOutlined } from "@ant-design/icons/lib/icons"
import usePageSize from "../../common/usePageSize"
import Pager from "../../components/pagination/pager"
import { Link } from "react-router-dom"
import "./WebServices.scss"
import HistoryModal from "../../components/History/HistoryModal"
import getVhosts from "./actions/getVhosts"
import CreateWebServiceForm from "./CreateWebService"
import CreateVhostForm from "./CreateVhostForm"
import lookupWebService from "./actions/lookupWebService"
import lookupVhost from "./actions/lookupVhost"

const { confirm } = Modal


const VhostsPage = () => {
	const CONTROL_NAME = 'page_web_vhosts'
	const { t } = useTranslation()
	const history = useHistory()
	const dispatch = useDispatch()

	const { customers, customerNames } = useSelector((state: AppState) => state.auth.tables)
	const { isLoadingService, vhosts, vhost_pager, lookup_vhost, lookup_host } = useSelector((state: AppState) => state.webservice)
	const { web_services } = useSelector((state: AppState) => state.sidenav)

	const [dataSource, setDataSource] = useState<VhostModel[]>([])
	const [sourceFull, setSourceFull] = useState<VhostModel[]>([])

	const [searchName, setSearchName] = useState<string>('')
	const [searchNameLookup, setSearchNameLookup] = useState<string>('')
	const [searchHost, setSearchHost] = useState<string>('')
	const [searchHostLookup, setSearchHostLookup] = useState<string>('')
	const [searchCustomerId, setSearchCustomerId] = useState<number | undefined>(undefined)
	const [selectedCustomerId, setSelectedCustomerId] = useState<number | undefined>(undefined)
	const [searchCustomer, setSearchCustomer] = useState<string>('')
	const [selectedServiceId, setSelectedServiceId] = useState<number | undefined>(undefined)

	const [customerOptions, setCustomerOptions] = useState<{ label: string, value: number }[]>([])
	const [nameOptions, setNameOptions] = useState<{ label: string, value: string }[]>([])
	const [hostOptions, setHostOptions] = useState<{ label: string, value: string }[]>([])

	const [pageNumber, setPageNumber] = useState<number>(1)
	const [qsFilter, setQsFilter] = useState<string>('')
	const [loaded, setLoaded] = useState(0)         // when records are loaded
	const [ready, setReady] = useState<number>(0)   // when customers are loaded

	const [isWebServiceModalVisible, setWebServiceModalVisible] = useState<boolean>(false)
	const [isVhostModalVisible, setVhostModalVisible] = useState<boolean>(false)

	const [isHistoryModalVisible, setHistoryModalVisible] = useState(false)
	const [historyTitle, setHistoryTitle] = useState('')
	const [historyModelId, setHistoryModelId] = useState<number | undefined>()

	const [isWebServiceViewer, setWebServiceViewer] = useState(false)
	const [isWebServiceCreator, setWebServiceCreator] = useState(false)
	const [isWebServiceEditor, setWebServiceEditor] = useState(false)
	const [isVhostCreator, setVhostCreator] = useState(false)


	// get settings and current user
	const loggedUser = useLoggedUser()
	if (!loggedUser || !loggedUser.isLoaded()) {
		// waiting..
		return (
			<div className="fullwidth-loading" >
				<LoadingIndicator />
			</div>
		)
	}

	// required authorization
	if (!loggedUser.hasAccess(CONTROL_NAME)) {
		return <ErrorPage403 />
	}

	// settings
	const appSetting = loggedUser.getAppSettings()
	const SEARCH_MIN = appSetting.min_search_length

	// usage: logger(msg, obj=null)
	const logger = useLogger(appSetting, 'VhostPage')
	const [pageSize, setPageSize] = useState<number>(appSetting.grid_page_size)
	usePageSize(appSetting, loggedUser.user.id, pageSize)

	// history drag modal
	const [disabled, setDisabled] = useState(true)
	const draggleRef = useRef<HTMLDivElement>(null)
	const [bounds, setBounds] = useState({ left: 0, top: 0, bottom: 0, right: 0 })
	const onStart = (_event: DraggableEvent, uiData: DraggableData) => {
		const { clientWidth, clientHeight } = window.document.documentElement
		const targetRect = draggleRef.current?.getBoundingClientRect()
		if (!targetRect) {
			return
		}
		setBounds({
			left: -targetRect.left + uiData.x,
			right: clientWidth - (targetRect.right - uiData.x),
			top: -targetRect.top + uiData.y,
			bottom: clientHeight - (targetRect.bottom - uiData.y),
		})
	}

	useEffect(() => {
		// trigger ONCE
		const access = loggedUser.hasAccess(CONTROL_NAME)
		setWebServiceViewer(access)
		setWebServiceCreator(loggedUser.hasAccess('page_web_service_create_button'))
		setVhostCreator(loggedUser.hasAccess('page_web_vhost_create_button'))
		setWebServiceEditor(loggedUser.hasAccess('page_web_service_edit_button'))
	}, [])

	useEffect(() => {
		// trigger from Side menu clicked
		logger('web_services---')
		if (!isLoadingService && isWebServiceViewer) {
			// reload
			fetchRecords(1, pageSize)
		}
	}, [web_services])

	useEffect(() => {
		// populate nameOptions
		logger('lookup_vhost changed: ' + JSON.stringify(lookup_vhost))
		setNameOptions(lookup_vhost.map(s => ({ label: s, value: s })))
	}, [lookup_vhost])

	useEffect(() => {
		// populate hostOptions
		logger('lookup_host changed: ' + JSON.stringify(lookup_host))
		setHostOptions(lookup_host.map(s => ({ label: s, value: s })))
	}, [lookup_host])


	useEffect(() => {
		if (customers) {
			setReady(ready + 1)
			setCustomerOptions(
				customers.map(item => ({
					value: item.id,
					label: item.company?.name!
				}))
			)
		}
	}, [customers])

	useEffect(() => {
		// when filter is changed
		// show the first page
		logger(`qsFilter changed: page: ${pageNumber}, pageSize: ${pageSize}, qs=${qsFilter}`)
		let pn = pageNumber
		if (ready) {    // do not change page for F5
			pn = 1
		}
		fetchRecords(pn, pageSize)
	}, [qsFilter])

	useEffect(() => {
		if (vhosts.items) {
			setSourceFull(vhosts.items.map(s => {
				return {
					...s,
					customer_name: s.customer?.name,
					searchableName: removeDiac(s.name),
				}
			}))
		}
	}, [vhosts.items])

	useEffect(() => {
		// update QsFilter will trigger fetchRecords
		const qs: string = prepareQsFilter()
		logger(`qsFilter:${qs}`)
		if (qs.trim() != qsFilter.trim()) {
			setQsFilter(qs)
		}
	}, [searchName, searchHost, searchCustomerId, selectedCustomerId])

	useEffect(() => {
		// Filters
		if (filterIsValid()) {
			refreshGrid()
		}
	}, [sourceFull])

	const refreshGrid = () => {
		setDataSource(sourceFull)
	}

	const fetchRecords = (pn: number, ps: number) => {
		setPageNumber(pn)
		setPageSize(ps)
		if (!isLoadingService && filterIsValid()) {
			// purchase_from is required
			logger(`fetchRecords: page: ${pn}, pageSize: ${ps}, qs=${qsFilter}`)
			let f = ''
			if (qsFilter) {
				f = '&' + qsFilter
			}
			dispatch(getVhosts(ps, pn - 1, f, suc => { }))
			setLoaded(1)
		}
	}

	const filterIsValid = (): boolean => {
		// no rules
		return true
	}


	const getQSFilter = (): string[] => {
		let qs: string[] = []
		return qs
	}

	const prepareQsFilter = (): string => {
		// load filtered data from server
		let qs: string[]
		qs = getQSFilter()
		if (searchName && appSetting.checkMinSearch(searchName)) {
			qs.push('name=' + encodeURIComponent(searchName))
		}
		if (searchHost && appSetting.checkMinSearch(searchHost)) {
			qs.push('service[host]=' + encodeURIComponent(searchHost))
		}
		if (searchCustomerId && searchCustomerId > 0) {
			qs.push('customer_id=' + searchCustomerId)
		}
		logger('prepareQsFilter: ' + qs.join("&"))
		return (qs.length === 0) ? '' : qs.join("&")
	}

	const handleCreate = () => {
		setWebServiceModalVisible(true)
	}

	const onClearCustomer = () => {
		setSearchCustomer('')
		setSearchCustomerId(undefined)
	}

	const onSelectCustomer = (v: string) => {
		const cid = parseInt(v)
		if (cid > 0) {
			logger('onSelectCustomer.. ' + v)
			setSearchCustomerId(cid)
			const name = customerNames!.get(cid)
			name && setSearchCustomer(name)
		}
		else {
			setSearchCustomerId(undefined)
			v && setSearchCustomer(v)
		}
	}

	const onChangeCustomerLookup = (data: string) => {
		if (!data) {
			if (searchCustomer.length === 1) {
				setSearchCustomer('')
			}
			return
		}
		if (data != searchCustomer) {
			setSearchCustomer(data)
		}
	}

	const fetchNameLookup = (searchText: string) => {
		// call lookup for name
		searchText = searchText.trim()
		if (appSetting.checkMinSearch(searchText.trim())) {
			let qs: string[]
			qs = getQSFilter()
			if (appSetting.checkMinSearch(searchText)) {
				qs.push('field=name')
				qs.push('value=' + encodeURIComponent(searchText.trim()))
				dispatch(lookupVhost('name', qs.join("&")))
			}
		}
	}

	const onClearName = () => {
		setSearchNameLookup('')
		setSearchName('')
		setNameOptions([])
	}

	const onSelectName = (data: string) => {
		setSearchName(data)
		setPageNumber(1)
	}

	const onChangeNameLookup = (data: string) => {
		if (!data) {
			if (searchNameLookup.length === 1) {
				setSearchNameLookup('')
				fetchNameLookup('')
			}
			return
		}
		if (data != searchNameLookup) {
			setSearchNameLookup(data)
			fetchNameLookup(data)
		}
	}

	const fetchHostLookup = (searchText: string) => {
		// call lookup for db host
		searchText = searchText.trim()
		if (appSetting.checkMinSearch(searchText.trim())) {
			let qs: string[]
			qs = getQSFilter()
			if (appSetting.checkMinSearch(searchText)) {
				qs.push('field=host')
				qs.push('value=' + encodeURIComponent(searchText.trim()))
				dispatch(lookupWebService('host', qs.join("&")))
			}
		}
	}

	const onClearHost = () => {
		setSearchHostLookup('')
		setSearchHost('')
		setHostOptions([])
	}

	const onSelectHost = (data: string) => {
		setSearchHost(data)
		setPageNumber(1)
	}

	const onChangeHostLookup = (data: string) => {
		if (!data) {
			if (searchHostLookup.length === 1) {
				setSearchHostLookup('')
				fetchHostLookup('')
			}
			return
		}
		if (data != searchHostLookup) {
			setSearchHostLookup(data)
			fetchHostLookup(data)
		}
	}

	const FilterByName = (
		<AutoComplete
			showSearch
			placeholder={t('webPage.vhosts.vhost')}
			style={{ width: '200px' }}
			value={searchNameLookup}
			options={nameOptions}
			dropdownMatchSelectWidth={200}
			onInputKeyDown={(e) => {
				if (e.key === 'Enter') {
					onSelectName(e.currentTarget.value)
				}
			}}
			onSelect={onSelectName}
			onChange={onChangeNameLookup}
			onClear={onClearName}
			onClick={stopPropagation}
			//notFoundContent={isLoadingLookup && <Spin />}
			optionFilterProp='label'
			filterOption={false}
			allowClear={true}
		/>
	)

	const FilterByHost = (
		<AutoComplete
			showSearch
			placeholder='WEB Server'
			style={{ width: '200px' }}
			value={searchHostLookup}
			options={hostOptions}
			dropdownMatchSelectWidth={200}
			onInputKeyDown={(e) => {
				if (e.key === 'Enter') {
					onSelectHost(e.currentTarget.value)
				}
			}}
			onSelect={onSelectHost}
			onChange={onChangeHostLookup}
			onClear={onClearHost}
			onClick={stopPropagation}
			//notFoundContent={isLoadingLookup && <Spin />}
			optionFilterProp='label'
			filterOption={false}
			allowClear={true}
		/>
	)

	const FilterByCustomer = (
		<AutoComplete
			showSearch
			placeholder={t('certificatesPage.customer')}
			style={{ width: '150px' }}
			value={searchCustomer}
			options={customerOptions}
			dropdownMatchSelectWidth={200}
			onInputKeyDown={(e) => {
				if (e.key === 'Enter') {
					onSelectCustomer(e.currentTarget.value)
				}
			}}
			onSelect={onSelectCustomer}
			onChange={onChangeCustomerLookup}
			onClear={onClearCustomer}
			onClick={stopPropagation}
			optionFilterProp='label'
			filterOption={(input, opt) => removeDiac(opt?.label + '').includes(removeDiac(input))}
			allowClear={true}
		/>
	)

	const columns: ColumnsType<VhostModel> = [
		{
			title: FilterByName,
			dataIndex: 'name',
			key: 'name',
			width: '40%',
			align: 'left',
			fixed: 'left',
			render: (name: string, rec: VhostModel) => <Link to={`/web/vhost/${rec.id}`}>{rec.name}</Link>
		},
		{
			title: 'Active',
			dataIndex: 'is_active',
			key: 'is_active',
			width: 50,
			align: 'center',
			render: (name: string, rec: VhostModel) => rec.is_active ? <Tag color="green">Active</Tag> : <Tag color="red">Inactive</Tag>
		},
		{
			title: FilterByHost,
			dataIndex: 'service_id',
			key: 'service_id',
			width: '30%',
			render: (name: string, rec: VhostModel) => (
				isWebServiceViewer ?
					(<Tag className='webServer'>
						{
							isWebServiceEditor && <Link className='linkWeb' to={`/web/services/${rec.service_id}`}>{rec.service?.name}</Link>
						}
						{
							!isWebServiceEditor && <span>{rec.service?.name}</span>
						}

					</Tag>) : <Tag className='webServer'>{rec.service?.name}</Tag>)
		},
		{
			title: FilterByCustomer,
			dataIndex: 'customer_name',
			key: 'customer_name',
			width: '20%',
		},
		{
			title: (<div className='center'>Action</div>),
			key: 'action',
			dataIndex: 'action',
			width: '100px',
			align: 'right',
			render: (text: string, record: VhostModel) => (
				<Space size={1} onClick={(e) => e.stopPropagation()}>
					<Button title={t('general.btnHistory')} size='small'
						onClick={() => {
							setHistoryModelId(record.id)
							setHistoryTitle(record.name)
							setHistoryModalVisible(true)
						}}
						icon={<InfoCircleTwoTone />}
						className='actionButton'
					/>
				</Space>
			),
		}
	]


	return (
		<>
			<Card title={<><RadarChartOutlined /> &nbsp;{t('webPage.vhosts.title')}</>}
				loading={isLoadingService}
				className='WebServicePage'
				extra={
					<Space>
						<Button type='primary'
							disabled={!isVhostCreator}
							onClick={() => setVhostModalVisible(true)}>
							<PlusCircleOutlined /> {t('webPage.vhosts.new_vhost')}
						</Button>
						<Button type='primary' onClick={handleCreate} disabled={!isWebServiceCreator}>
							<PlusCircleOutlined /> {t('webPage.vhosts.new_server')}
						</Button>
					</Space>

				}
			>
				<Table<VhostModel>
					columns={columns}
					dataSource={dataSource}
					scroll={{ x: 'max-content' }}
					rowKey='id'
					bordered={true}
					className='VhostsTable'
					loading={isLoadingService}
					showHeader={true}
					size='small'
					pagination={false}
					footer={() => Pager({
						filename: 'fn',
						total: vhost_pager.totalCount,
						current: vhost_pager.page,
						pageSize: vhost_pager.pageSize,
						data: dataSource,
						fetchRecords: fetchRecords
					})}
					onChange={(ev) => {
						ev.pageSize && setPageSize(ev.pageSize)
					}}
				/>

			</Card>

			<Modal title={
				<div style={{ width: '100%', cursor: 'move' }}
					onMouseOver={() => { if (disabled) { setDisabled(false) } }}
					onMouseOut={() => { setDisabled(true) }}
					onFocus={() => { }}
					onBlur={() => { }}
				>
					<GlobalOutlined /> &nbsp; {t('webPage.vhosts.new_server')}
				</div>
			}
				destroyOnClose
				style={{ top: 20 }}
				width={600}
				visible={isWebServiceModalVisible}
				onCancel={() => setWebServiceModalVisible(false)}
				modalRender={(modal) => (
					<Draggable disabled={disabled} bounds={bounds} onStart={(ev, data) => onStart(ev, data)}>
						<div ref={draggleRef}>{modal}</div>
					</Draggable>
				)}
				footer={null}
				confirmLoading={true}
			>
				<CreateWebServiceForm onClose={(id) => {
					setWebServiceModalVisible(false)
					history.push(`/web/services/${id}`)
				}} />
			</Modal>

			<Modal title={
				<div style={{ width: '100%', cursor: 'move' }}
					onMouseOver={() => { if (disabled) { setDisabled(false) } }}
					onMouseOut={() => { setDisabled(true) }}
					onFocus={() => { }}
					onBlur={() => { }}
				>
					<GlobalOutlined /> &nbsp; {t('webPage.vhosts.new_vhost')}
				</div>
			}
				destroyOnClose
				style={{ top: 20 }}
				width={600}
				visible={isVhostModalVisible}
				onCancel={() => setVhostModalVisible(false)}
				modalRender={(modal) => (
					<Draggable disabled={disabled} bounds={bounds} onStart={(ev, data) => onStart(ev, data)}>
						<div ref={draggleRef}>{modal}</div>
					</Draggable>
				)}
				footer={null}
				confirmLoading={true}
			>
				<CreateVhostForm onClose={() => { setVhostModalVisible(false) }} />
			</Modal>

			<HistoryModal service='web' model='ServiceVhost'
				modelId={historyModelId}
				title={historyTitle}
				isModalVisible={isHistoryModalVisible}
				setModalVisible={() => setHistoryModalVisible(false)}
				modalRender={(modal) => (
					<Draggable bounds={bounds} onStart={(ev, data) => onStart(ev, data)}>
						<div ref={draggleRef}>{modal}</div>
					</Draggable>
				)}
			/>
		</>
	)
}

export default VhostsPage
