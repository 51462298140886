import {DeleteAliasRequestAction, DeleteAliasResponseAction, DeleteAliasErrorAction} from './index'
import {protectedApiClient} from 'helpers/api'
import {handleApiErrorWithNotification} from 'helpers/errorHandling'
import { AliasParams } from '../models'

export default (params: AliasParams, cb?: (isSuccess: boolean) => void) => {
	return (dispatch: (arg: DeleteAliasRequestAction | DeleteAliasResponseAction | DeleteAliasErrorAction) => void) => {
		const request = new DeleteAliasRequestAction(params)
		dispatch(request)

		protectedApiClient
			.delete(`/web/vhost/${params.vhost_id}/alias?name=${params.name}`)
			.then((response) => {
				dispatch(new DeleteAliasResponseAction(request, response.data))
				cb && cb(true)
			})
			.catch((error) => {
				dispatch(new DeleteAliasErrorAction(request, error.toString()))
				handleApiErrorWithNotification(error)
				cb && cb(false)
			})
	}
}
