import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { useForm } from "antd/lib/form/Form"
import { useLoggedUser } from "../../../helpers/loginUserHelper"
import { Button, Col, Form, Input, message, Row, Select, Spin } from "antd"
import React, { useEffect, useState } from "react"
import { tailLayout } from "../../../helpers/layoutHelpers"
import { Store } from "antd/lib/form/interface"
import { EditPtrParams, PtrRecord } from "./models"
import { AppState } from "../../../common/models"
import addPtr from "./actions/addPtr"
import { _isValidDomain } from "../../../common/fce"
import removePtr from "./actions/removePtr"


interface Props {
    hint?: string
    onClose: () => void
}


const EditPtrForm = (props: Props) => {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const [form1] = useForm()

    const { ip, isSaving } = useSelector((state: AppState) => state.ipSubnet)

    // get settings and logged user from store
    const loggedUser = useLoggedUser()
    if (!loggedUser || !loggedUser.isLoaded()) {
        return (<Spin />)
    }

    useEffect(() => {
        // once

    }, [])

    const submitForm = (values: Store): void => {
        if (ip) {
            const params: EditPtrParams = {
                id: ip.id,
                content: values.ptrdname,
            }
            dispatch(addPtr(params, suc => {
                if (suc) {
                    message.success(t('general.success'))
                    props.onClose()
                }
            }))
        }
    }

    const removeRecord = (): void => {
        if (ip) {
            dispatch(removePtr(ip.id, suc => {
                if (suc) {
                    message.success(t('general.success'))
                    props.onClose()
                }
            }))
        }
    }

    const isPtrSet = (ptr?: PtrRecord) => {
        if (!ptr) {
            return false
        }
        if (ptr.ptrdname && ptr.ptrdname.length > 2 && !ptr.ptrdname.startsWith('unn-')) {
            return true
        }
        return false
    }

    if (!ip) {
        return (<Spin />)
    }

    return (
        <Row justify='space-between' className='InvoiceTopForm' style={{ marginTop: '0' }}>
            <Col span={24} >
                <Form name='edit-ptr'
                    labelCol={{ span: 6 }}
                    wrapperCol={{ span: 18 }}
                    layout="horizontal"
                    form={form1}
                    initialValues={{ name: ip.ptr?.name, ptrdname: isPtrSet(ip.ptr) ? ip.ptr?.ptrdname : props.hint }}
                    onFinish={submitForm}
                >

                    <Form.Item name='name' label='PTR'>
                        <Input disabled />
                    </Form.Item>

                    <Form.Item name='ptrdname'
                        label={t('ipSubnetPage.ptrdname')}
                        rules={[
                            { required: true, message: t('errors.field_required') },
                            {
                                validator: (_, value) => {
                                    if (_isValidDomain(value)) {
                                        return Promise.resolve()
                                    } else {
                                        return Promise.reject('Invalid domain.')
                                    }
                                }, message: t('errors.field_error')
                            }
                        ]}>
                        <Input placeholder='server.domain.name' />
                    </Form.Item>

                    <Form.Item {...tailLayout}>
                        <Row>
                            <Col span={12}>
                                {
                                    ip && isPtrSet(ip.ptr) && (
                                        <Button type='primary' 
                                            loading={isSaving}
                                            danger={true}
                                            onClick={() => removeRecord()}
                                            className='create-button'>
                                            {t('general.remove')}
                                        </Button>
                                    )
                                }
                            </Col>
                            <Col span={12}>
                                <Button
                                    loading={isSaving}
                                    type='primary'
                                    htmlType='submit'
                                    className='create-button'>
                                    {t('general.set')}
                                </Button>
                            </Col>
                        </Row>

                    </Form.Item>
                </Form>
            </Col>
            {
                ip && !ip.ptr && (
                    <Col span={24} className='center' >
                        <span className='text-alert'>Could not load PTR</span>
                    </Col>
                )
            }
        </Row>
    )
}

export default EditPtrForm
