import React, { useEffect, useState } from 'react'
import {
    Button,
    Form,
    Input,
    Switch,
    Select,
    message,
} from 'antd'
import { useTranslation } from 'react-i18next'
import { useForm } from 'antd/lib/form/Form'
import { formItemLayout, tailLayout } from 'helpers/layoutHelpers'
import {
    CHALLENGE_ENUM, GenCertificateParams,
} from 'pages/certificate/models'
import { CheckOutlined, CloseOutlined, UploadOutlined } from '@ant-design/icons'
import { _isValidDomain, ValidateParams } from "../../common/fce"
import { useDispatch, useSelector } from "react-redux"
import { AppState } from "../../common/models"
import { Store } from "antd/lib/form/interface"
import tableCustomers from "../login/actions/tableCustomers"
import { useLoggedUser } from "../../helpers/loginUserHelper"
import { LoadingIndicator } from "../../components"
import { sort_label } from "../../common/sorting"
import genCertificate from "./actions/genCertificate"


interface Props {
    closeModal: () => void
}

export const GenCertificateForm = ({ closeModal }: Props) => {
    const [form] = useForm()
    const { t } = useTranslation()
    const dispatch = useDispatch()

    const { customers } = useSelector((state: AppState) => state.auth.tables)
    const { isSaving } = useSelector((state: AppState) => state.certificate)

    const [customerOptions, setCustomerOptions] = useState<{ label: string, value: number }[]>([])
    const [selectedCustomerId, setSelectedCustomerId] = useState<number | undefined>()
    const [domainField, setDomainField] = useState<ValidateParams>({ value: '', validateStatus: 'success', errorMsg: '' })
    const [isFormValid, setFormValid] = useState<boolean>(false)
    const [addWww, setAddWww] = useState<boolean>(false)
    const [wildcard, setWildcard] = useState<boolean>(false)

    // get settings and logged user from store
    const loggedUser = useLoggedUser()
    if (!loggedUser || !loggedUser.isLoaded()) {
        return (
            <div className="fullwidth-loading">
                <LoadingIndicator />
            </div>
        )
    }
    const appSetting = loggedUser.getAppSettings()

    useEffect(() => {
        if (customers.length === 0) {
            dispatch(tableCustomers())
        }
    }, [])

    useEffect(() => {
        setCustomerOptions(customers.map(c => ({ label: c.company!.name, value: c.id })).sort(sort_label))
    }, [customers])


    const checkDomain = (dom: string) => {
        let dom2 = dom.replace('*.', '')
        if (!dom2) {
            setDomainField({
                value: dom,
                validateStatus: 'success',
                errorMsg: ''
            })
            setFormValid(false)
            return true
        }
        const result = _isValidDomain(dom2)
        if (result) {
            setDomainField({
                value: dom,
                validateStatus: 'success',
                errorMsg: ''
            })
            setFormValid(true)
            return true
        }
        else {
            setDomainField({
                value: dom,
                validateStatus: 'error',
                errorMsg: 'Invalid domain'
            })
            setFormValid(false)
            return false
        }
    }

    const handleSubmit = (values: Store): void => {

        const params: GenCertificateParams = {
            customer_id: !loggedUser.user.is_zcom ? loggedUser.customer.id : values.customer_id,
            domain: values.name.toLowerCase(),
            wildcard: wildcard ? 1 : 0,
            www: addWww ? 1 : 0
        }
        dispatch(genCertificate(params, suc => {
            if (suc) {
                message.success(t('general.success'))
                closeModal()
            }
        }))
    }

    return (
        <Form {...formItemLayout} form={form}
            onFinish={handleSubmit}
            className='NewCertForm'
            initialValues={{
                customer_id: loggedUser.customer.id,
                auto_prolong: true,
                prolong_before_days: 20,
                challenge: CHALLENGE_ENUM.CHALLENGE_DNS
            }}>

            <Form.Item name='customer_id' label={t('domainPage.customer')}
                rules={[{ required: true, message: t('errors.field_required') }]}
            >
                <Select optionFilterProp='label'
                    style={{ width: '100%' }}
                    options={customerOptions}
                    disabled={!loggedUser.user.is_zcom}
                    onChange={(value: number) => {
                        setSelectedCustomerId(value)
                    }}
                />
            </Form.Item>

            <Form.Item
                name='name' label={t('certificatesPage.domain')}
                rules={[
                    { required: true, message: t('errors.field_required') },
                    () => ({
                        validator(_, value) {
                            if (_isValidDomain(value)) {
                                return Promise.resolve()
                            }
                            return Promise.reject(new Error(t('domainPage.invalid_domain')))
                        },
                    })
                ]}
                hasFeedback>
                <Input onBlur={(e) => checkDomain(e.target.value)}
                    onChange={(e) => checkDomain(e.target.value)}
                    placeholder='domain'
                    data-gramm="false"
                    data-gramm_editor="false"
                    data-enable-grammarly="false" />
            </Form.Item>

            <Form.Item name='wildcard' label='wildcard'>
                <Switch
                    checkedChildren={<CheckOutlined />}
                    unCheckedChildren={<CloseOutlined />}
                    checked={wildcard}
                    onChange={() => { setWildcard(!wildcard) }}
                />
            </Form.Item>

            <Form.Item name='addWww' label='www'>
                <Switch
                    checkedChildren={<CheckOutlined />}
                    unCheckedChildren={<CloseOutlined />}
                    checked={addWww}
                    onChange={() => { setAddWww(!addWww) }}
                />
            </Form.Item>

            <Form.Item {...tailLayout}>
                <Button type='primary' htmlType='submit' disabled={!isFormValid} loading={isSaving}>
                    {t('certificatesPage.generate_ssl')}
                </Button>
            </Form.Item>
        </Form>
    )
}

export default GenCertificateForm


