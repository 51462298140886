import { GetDaemonVersionRequestAction, GetDaemonVersionResponseAction, GetDaemonVersionErrorAction } from '.'
import { protectedApiClient } from 'helpers/api'
import { handleApiError, handleApiErrorWithNotification } from 'helpers/errorHandling'
import { DaemonVersionResp } from '../models'

export default (id: number, cb?: (isSuccess: boolean) => void) => {
	return (
		dispatch: (
			arg: GetDaemonVersionRequestAction | GetDaemonVersionResponseAction | GetDaemonVersionErrorAction
		) => void
	) => {
		const request = new GetDaemonVersionRequestAction()
		dispatch(request)

		protectedApiClient
			.get<DaemonVersionResp>(`/mail/service/${id}/deamon-version`)
			.then((response) => {
				dispatch(new GetDaemonVersionResponseAction(request, response.data))
				cb && cb(true)
			})
			.catch((error) => {
				let msg = error.toString()
				if (parseInt(error.response.status) === 502) {
					msg = 'ERROR HTTP 502'
				}
				dispatch(new GetDaemonVersionErrorAction(request, msg))
				handleApiErrorWithNotification(error)
				cb && cb(false)
			})
	}
}
