import { ActionType, InvoiceActions } from './actions'
import { AsyncActionMode } from 'common/models'
import { InvoiceDetails, InvoiceState } from './models'
import { removeDiac } from '../../../common/fce'
import { CustomerDetails } from '../customer/models'

const INVOICE_INITIAL_STATE: InvoiceState = {
	isLoading: false,
	isSaving: false,
	invoices: [],
	invoice: {
		id: 0,
		state: 1,
		customer_id: 0,
		customer_name: '',
		searchableName: '',
		number: '',
		generate_customer_services: 0,
		period_from: '',
		period_to: '',
		date_of_issue: '',
		date_of_maturity: '',
		date_of_taxing: '',
		date_of_payment: '',
		currency: 'CZK',
		invoiceItems: [],
		invoiceDocuments: [],
		invoiceAttachments: [],
		sent_at: 0,
		paid: false,
		invoice_detail: 2,
		reminded_at: 0,
		total_without_vat: 0,
		total_with_vat: 0,
		total_vat: 0,
	},
	versions: [],
	document: undefined,
	documentos: [],
	attachments: [],
}

export default (state = INVOICE_INITIAL_STATE, action: InvoiceActions): InvoiceState => {
	function processInvoices(data: InvoiceDetails[], customers: CustomerDetails[]): InvoiceDetails[] {
		// prepare data for search
		if (data && data.length > 0) {
			return data.map((inv) => {
				inv.searchableName = inv.customer_name ? removeDiac(inv.customer_name) : '?!?'
				return inv
			})
		}
		return []
	}

	switch (action.type) {
		case ActionType.GET_INVOICE:
			if (action.mode === AsyncActionMode.REQUEST) {
				return { ...state, invoice: undefined, isLoading: true }
			}
			if (action.mode === AsyncActionMode.RESPONSE) {
				return {
					...state,
					isLoading: false,
					invoice: { ...action.data },
					invoices: state.invoices.map((inv) => (inv.id === action.data.id ? action.data : inv)),
					versions: !action.data.invoiceDocuments
						? []
						: action.data.invoiceDocuments.map((d) => {
								return { id: d.id, version: d.version, name: action.data + '_' + d.version }
						  }),
					attachments: !action.data.invoiceAttachments ? [] : action.data.invoiceAttachments,
				}
			}
			if (action.mode === AsyncActionMode.ERROR) {
				return {
					...state,
					isLoading: false,
					error: action.error,
				}
			}
			break

		case ActionType.GET_INVOICE_COMPANY:
			if (action.mode === AsyncActionMode.REQUEST) {
				return { ...state, company: undefined, isLoading: true }
			}
			if (action.mode === AsyncActionMode.RESPONSE) {
				return {
					...state,
					isLoading: false,
					company: { ...action.data },
				}
			}
			if (action.mode === AsyncActionMode.ERROR) {
				return {
					...state,
					isLoading: false,
					error: action.error,
				}
			}
			break

		case ActionType.GET_INVOICE_ITEM:
			if (action.mode === AsyncActionMode.REQUEST) {
				return { ...state }
			}
			if (action.mode === AsyncActionMode.RESPONSE) {
				return {
					...state,
					invoiceItem: action.data,
				}
			}
			if (action.mode === AsyncActionMode.ERROR) {
				return {
					...state,
					error: action.error,
				}
			}
			break

		case ActionType.CREATE_INVOICE:
			if (action.mode === AsyncActionMode.REQUEST) {
				return { ...state, isSaving: true }
			}
			if (action.mode === AsyncActionMode.RESPONSE) {
				return {
					...state,
					invoices: state.invoices?.concat(action.data),
					invoice: action.data,
					versions: [],
					attachments: [],
					isSaving: false,
				}
			}
			if (action.mode === AsyncActionMode.ERROR) {
				return {
					...state,
					isSaving: false,
					error: action.error,
				}
			}
			break
		case ActionType.CREATE_INVOICE_MAIL:
			if (action.mode === AsyncActionMode.REQUEST) {
				return { ...state, isSaving: true }
			}
			if (action.mode === AsyncActionMode.RESPONSE) {
				return {
					...state,
					mail: action.data,
					isSaving: false,
				}
			}
			if (action.mode === AsyncActionMode.ERROR) {
				return { ...state, isSaving: false, error: action.error }
			}
			break
		case ActionType.SEND_INVOICE_MAIL:
			if (action.mode === AsyncActionMode.REQUEST) {
				return { ...state, isSaving: true }
			}
			if (action.mode === AsyncActionMode.RESPONSE) {
				return {
					...state,
					mail: undefined,
					invoices: state.invoices
						? state.invoices.map((invoice) => (invoice.id === action.data.id ? { ...action.data } : invoice))
						: [{ ...action.data }],
					invoice: action.data,
					isSaving: false,
				}
			}
			if (action.mode === AsyncActionMode.ERROR) {
				return { ...state, isSaving: false, error: action.error }
			}
			break
		case ActionType.GENERATE_INVOICE:
			if (action.mode === AsyncActionMode.REQUEST) {
				return { ...state, isSaving: true }
			}
			if (action.mode === AsyncActionMode.RESPONSE) {
				return {
					...state,
					isSaving: false,
					invoices: state.invoices
						? state.invoices.map((invoice) => (invoice.id === action.data.id ? { ...action.data } : invoice))
						: [{ ...action.data }],
					invoice: action.data,
					versions: [],
					attachments: [],
				}
			}
			if (action.mode === AsyncActionMode.ERROR) {
				return { ...state }
			}
			break
		case ActionType.CREATE_INVOICE_ITEM:
			if (action.mode === AsyncActionMode.REQUEST) {
				return { ...state, isSaving: true }
			}
			if (action.mode === AsyncActionMode.RESPONSE) {
				if (!state.invoice) {
					// never happen
					return { ...state }
				}
				return {
					...state,
					invoiceItem: undefined,
					invoice: action.data,
					invoices: state.invoices.map((invoice) => (invoice.id === action.data.id ? action.data : invoice)),
					isSaving: false,
				}
			}
			if (action.mode === AsyncActionMode.ERROR) {
				return {
					...state,
					isSaving: false,
					error: action.error,
				}
			}
			break

		case ActionType.UPDATE_INVOICE:
			if (action.mode === AsyncActionMode.REQUEST) {
				return { ...state, isSaving: true }
			}
			if (action.mode === AsyncActionMode.RESPONSE) {
				return {
					...state,
					invoices: state.invoices
						? state.invoices.map((invoice) => (invoice.id === action.data.id ? { ...action.data } : invoice))
						: [{ ...action.data }],
					invoice: action.data,
					isSaving: false,
				}
			}
			if (action.mode === AsyncActionMode.ERROR) {
				return {
					...state,
					isSaving: false,
					error: action.error,
				}
			}
			break

		case ActionType.FINALIZE_INVOICE:
			if (action.mode === AsyncActionMode.REQUEST) {
				return { ...state, isSaving: true }
			}
			if (action.mode === AsyncActionMode.RESPONSE) {
				return {
					...state,
					invoices: state.invoices
						? state.invoices.map((invoice) => (invoice.id === action.data.id ? { ...action.data } : invoice))
						: [{ ...action.data }],
					invoice: action.data,
					isSaving: false,
				}
			}
			if (action.mode === AsyncActionMode.ERROR) {
				return {
					...state,
					isSaving: false,
					error: action.error,
				}
			}
			break

		case ActionType.REPAIR_INVOICE:
			if (action.mode === AsyncActionMode.REQUEST) {
				return { ...state, isSaving: true }
			}
			if (action.mode === AsyncActionMode.RESPONSE) {
				return {
					...state,
					invoices: state.invoices
						? state.invoices.map((invoice) => (invoice.id === action.data.id ? { ...action.data } : invoice))
						: [{ ...action.data }],
					invoice: action.data,
					isSaving: false,
				}
			}
			if (action.mode === AsyncActionMode.ERROR) {
				return {
					...state,
					isSaving: false,
					error: action.error,
				}
			}
			break

		case ActionType.UPDATE_INVOICE_ITEM:
			if (action.mode === AsyncActionMode.REQUEST) {
				return { ...state, isSaving: true }
			}
			if (action.mode === AsyncActionMode.RESPONSE) {
				// returns Invoice !!!
				return {
					...state,
					invoiceItem: undefined,
					invoice: undefined,
					// action.data,
					// invoices: state.invoices.map((invoice) =>
					//   invoice.id === action.data.id ? action.data : invoice,
					// ),
					isSaving: false,
				}
			}
			if (action.mode === AsyncActionMode.ERROR) {
				return {
					...state,
					isSaving: false,
					error: action.error,
				}
			}
			break

		case ActionType.GET_INVOICES:
			if (action.mode === AsyncActionMode.REQUEST) {
				return { ...state, isLoading: true }
			}
			if (action.mode === AsyncActionMode.RESPONSE) {
				return {
					...state,
					isLoading: false,
					invoices: processInvoices(action.data, action.customers),
					invoice: undefined,
					versions: [],
					attachments: [],
				}
			}
			if (action.mode === AsyncActionMode.ERROR) {
				return {
					...state,
					isLoading: false,
					error: action.error,
				}
			}
			break
		case ActionType.DELETE_INVOICE:
			if (action.mode === AsyncActionMode.REQUEST) {
				return { ...state, isSaving: true }
			}
			if (action.mode === AsyncActionMode.RESPONSE) {
				return {
					...state,
					invoices: state.invoices.filter((invoice) => invoice.id !== action.request.id),
					versions: [],
					attachments: [],
					isSaving: false,
				}
			}
			if (action.mode === AsyncActionMode.ERROR) {
				return {
					...state,
					isSaving: false,
					error: action.error,
				}
			}
			break

		case ActionType.DELETE_INVOICE_ITEM:
			if (action.mode === AsyncActionMode.REQUEST) {
				return { ...state, isSaving: true }
			}
			if (action.mode === AsyncActionMode.RESPONSE) {
				return {
					...state,
					invoiceItem: undefined,
					invoice: undefined,
					isSaving: false,
				}
			}
			if (action.mode === AsyncActionMode.ERROR) {
				return {
					...state,
					isSaving: false,
					error: action.error,
				}
			}
			break

		case ActionType.UPDATE_DATA_OF_PAYMENT:
			if (action.mode === AsyncActionMode.REQUEST) {
				return { ...state, isSaving: true }
			}
			if (action.mode === AsyncActionMode.RESPONSE) {
				let oldItem = state.invoices.find((inv) => inv.id === action.data.id)
				return {
					...state,
					invoices:
						state.invoices &&
						state.invoices.map((inv) =>
							inv.id === action.data.id
								? {
										...action.data,
										searchableName: oldItem ? oldItem.searchableName : removeDiac(action.data.customer_name),
								  }
								: inv
						),
					invoice: state.invoice && state.invoice.id === action.data.id ? action.data : undefined,
					isSaving: false,
				}
			}
			if (action.mode === AsyncActionMode.ERROR) {
				return {
					...state,
					isSaving: false,
					error: action.error,
				}
			}
			break

		case ActionType.UPDATE_NOTE:
			if (action.mode === AsyncActionMode.REQUEST) {
				return { ...state, isSaving: true }
			}
			if (action.mode === AsyncActionMode.RESPONSE) {
				return {
					...state,
					invoices:
						state.invoices && state.invoices.map((inv) => (inv.id === action.data.id ? { ...action.data } : inv)),
					invoice: action.data,
					isSaving: false,
				}
			}
			if (action.mode === AsyncActionMode.ERROR) {
				return {
					...state,
					isSaving: false,
					error: action.error,
				}
			}
			break

		case ActionType.SET_INVOICE_ORDER:
			if (action.mode === AsyncActionMode.REQUEST) {
				return { ...state, isSaving: true }
			}
			if (action.mode === AsyncActionMode.RESPONSE) {
				return {
					...state,
					invoices:
						state.invoices && state.invoices.map((inv) => (inv.id === action.data.id ? { ...action.data } : inv)),
					invoice: action.data,
					isSaving: false,
				}
			}
			if (action.mode === AsyncActionMode.ERROR) {
				return {
					...state,
					isSaving: false,
					error: action.error,
				}
			}
			break

		case ActionType.CREDIT_NOTE:
			if (action.mode === AsyncActionMode.REQUEST) {
				return { ...state, isLoading: false }
			}
			if (action.mode === AsyncActionMode.RESPONSE) {
				return {
					...state,
					invoices:
						state.invoices && state.invoices.map((inv) => (inv.id === action.data.id ? { ...action.data } : inv)),
					// invoice: action.data,  Do not switch invoice - need to be confirmed by user!!
					isLoading: false,
				}
			}
			if (action.mode === AsyncActionMode.ERROR) {
				return {
					...state,
					isLoading: false,
					error: action.error,
				}
			}
			break

		case ActionType.GET_INVOICEATTACHMENT:
			if (action.mode === AsyncActionMode.REQUEST) {
				return { ...state, isLoading: true }
			}
			if (action.mode === AsyncActionMode.RESPONSE) {
				return {
					...state,
					attachments:
						state.attachments && state.attachments.length ? state.attachments.concat(action.data) : [action.data],
					isLoading: false,
				}
			}
			if (action.mode === AsyncActionMode.ERROR) {
				return {
					...state,
					isLoading: false,
					error: action.error,
				}
			}
			break

		case ActionType.CREATE_INVOICEATTACHMENT:
			if (action.mode === AsyncActionMode.REQUEST) {
				return { ...state, isSaving: true }
			}
			if (action.mode === AsyncActionMode.RESPONSE) {
				return {
					...state,
					attachments: state.attachments.concat(action.data),
					isSaving: false,
				}
			}
			if (action.mode === AsyncActionMode.ERROR) {
				return {
					...state,
					isSaving: false,
					error: action.error,
				}
			}
			break

		case ActionType.GET_INVOICEATTACHMENTS:
			if (action.mode === AsyncActionMode.REQUEST) {
				return { ...state, isLoading: true }
			}
			if (action.mode === AsyncActionMode.RESPONSE) {
				return {
					...state,
					isLoading: false,
					attachments: action.data,
				}
			}
			if (action.mode === AsyncActionMode.ERROR) {
				return {
					...state,
					isLoading: false,
					error: action.error,
				}
			}
			break
		case ActionType.DELETE_INVOICEATTACHMENT:
			if (action.mode === AsyncActionMode.REQUEST) {
				return { ...state, isSaving: true }
			}
			if (action.mode === AsyncActionMode.RESPONSE) {
				return {
					...state,
					attachments: state.attachments!.filter((invoiceattachment) => invoiceattachment.id !== action.request.id),
					isSaving: false,
				}
			}
			if (action.mode === AsyncActionMode.ERROR) {
				return {
					...state,
					isSaving: false,
					error: action.error,
				}
			}
			break

		case ActionType.GET_INVOICEDOCUMENT:
			if (action.mode === AsyncActionMode.REQUEST) {
				return { ...state, isLoading: true }
			}
			if (action.mode === AsyncActionMode.RESPONSE) {
				return {
					...state,
					isLoading: false,
					document: action.data,
				}
			}
			if (action.mode === AsyncActionMode.ERROR) {
				return {
					...state,
					isLoading: false,
					error: action.error,
				}
			}
			break

		case ActionType.CREATE_INVOICEDOCUMENT:
			if (action.mode === AsyncActionMode.REQUEST) {
				return { ...state, isSaving: true }
			}
			if (action.mode === AsyncActionMode.RESPONSE) {
				return {
					...state,
					document: action.data,
					isSaving: false,
				}
			}
			if (action.mode === AsyncActionMode.ERROR) {
				return {
					...state,
					isSaving: false,
					error: action.error,
				}
			}
			break
		case ActionType.GET_INVOICEDOCUMENT_BY_INVOICE:
			if (action.mode === AsyncActionMode.REQUEST) {
				return { ...state, isLoading: true }
			}
			if (action.mode === AsyncActionMode.RESPONSE) {
				return {
					...state,
					isLoading: false,
					document: action.data,
				}
			}
			if (action.mode === AsyncActionMode.ERROR) {
				return {
					...state,
					isLoading: false,
					document: undefined,
					error: action.error,
				}
			}
			break
		case ActionType.DELETE_INVOICEDOCUMENT:
			if (action.mode === AsyncActionMode.REQUEST) {
				return { ...state, isSaving: true }
			}
			if (action.mode === AsyncActionMode.RESPONSE) {
				return {
					...state,
					document: action.data,
					isSaving: false,
				}
			}
			if (action.mode === AsyncActionMode.ERROR) {
				return {
					...state,
					isSaving: false,
					error: action.error,
				}
			}
			break
	}
	return state
}
