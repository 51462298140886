import { ActionType, CertificateActions } from './actions'
import { AsyncActionMode, PAGE1 } from 'common/models'
import { CertificateModel, CertificateState } from './models'

const CERTIFICATE_INITIAL_STATE: CertificateState = {
	isLoading: false,
	isSaving: false,
	error: undefined,
	certificates: { items: [], pagination: PAGE1 },
	pager: PAGE1,
	lookup_name: [],
	isLoadingLookup: false,
}

export default (state = CERTIFICATE_INITIAL_STATE, action: CertificateActions): CertificateState => {
	switch (action.type) {
		case ActionType.REMOVE_CERTIFICATE_FROM_VHOST:
			if (action.mode === AsyncActionMode.REQUEST) {
				return { ...state, isSaving: true }
			}
			if (action.mode === AsyncActionMode.RESPONSE) {
				return {
					...state,
					isSaving: false,
				}
			}
			if (action.mode === AsyncActionMode.ERROR) {
				return {
					...state,
					isSaving: false,
					error: action.error,
				}
			}
			break
		case ActionType.ADD_CERTIFICATE_TO_VHOST:
			if (action.mode === AsyncActionMode.REQUEST) {
				return { ...state, isSaving: true }
			}
			if (action.mode === AsyncActionMode.RESPONSE) {
				return {
					...state,
					isSaving: false,
				}
			}
			if (action.mode === AsyncActionMode.ERROR) {
				return {
					...state,
					isSaving: false,
					error: action.error,
				}
			}
			break
		case ActionType.GET_CERTIFICATES_BY_VHOST:
			if (action.mode === AsyncActionMode.REQUEST) {
				return { ...state, isLoading: true }
			}
			if (action.mode === AsyncActionMode.RESPONSE) {
				return {
					...state,
					isLoading: false,
					vhosts: [{ [action.request.payload]: action.data }],
				}
			}
			if (action.mode === AsyncActionMode.ERROR) {
				return {
					...state,
					isLoading: false,
					error: action.error,
				}
			}
			break
		case ActionType.GET_CERTIFICATE:
		case ActionType.GET_CERTIFICATE_SECURE:
			if (action.mode === AsyncActionMode.REQUEST) {
				return { ...state, certificate: undefined, isLoading: true }
			}
			if (action.mode === AsyncActionMode.RESPONSE) {
				return {
					...state,
					certificate: action.data,
					isLoading: false,
				}
			}
			if (action.mode === AsyncActionMode.ERROR) {
				return {
					...state,
					isLoading: false,
					error: action.error,
				}
			}
			break
		case ActionType.CREATE_CERTIFICATE:
		case ActionType.UPLOAD_CERTIFICATE:
			if (action.mode === AsyncActionMode.REQUEST) {
				return { ...state, certificate: undefined, isSaving: true }
			}
			if (action.mode === AsyncActionMode.RESPONSE) {
				return {
					...state,
					certificate: action.data,
					certificates: {
						...state.certificates,
						items: state.certificates.items.concat(action.data),
					},
					isSaving: false,
				}
			}
			if (action.mode === AsyncActionMode.ERROR) {
				return {
					...state,
					error: action.error,
					isSaving: false,
				}
			}
			break
		case ActionType.GEN_CERTIFICATE:
			// creates scheduled job only
			if (action.mode === AsyncActionMode.REQUEST) {
				return { ...state, certificate: undefined, isSaving: true }
			}
			if (action.mode === AsyncActionMode.RESPONSE) {
				return {
					...state,
					isSaving: false,
				}
			}
			if (action.mode === AsyncActionMode.ERROR) {
				return {
					...state,
					error: action.error,
					isSaving: false,
				}
			}
			break
		case ActionType.PROLONG_CERTIFICATE:
			// creates scheduled job only
			if (action.mode === AsyncActionMode.REQUEST) {
				return { ...state, certificate: undefined, isSaving: true }
			}
			if (action.mode === AsyncActionMode.RESPONSE) {
				return {
					...state,
					isSaving: false,
				}
			}
			if (action.mode === AsyncActionMode.ERROR) {
				return {
					...state,
					error: action.error,
					isSaving: false,
				}
			}
			break
		case ActionType.GET_CERTIFICATES:
			if (action.mode === AsyncActionMode.REQUEST) {
				return { ...state, isLoading: true }
			}
			if (action.mode === AsyncActionMode.RESPONSE) {
				return {
					...state,
					isLoading: false,
					certificate: undefined,
					certificates: action.data,
					pager: { ...action.data.pagination, page: action.data.pagination.page + 1 },
					isLoadingLookup: false,
				}
			}
			if (action.mode === AsyncActionMode.ERROR) {
				return {
					...state,
					isLoading: false,
					error: action.error,
				}
			}
			break
		case ActionType.DELETE_CERTIFICATE:
			if (action.mode === AsyncActionMode.REQUEST) {
				return { ...state, isSaving: true }
			}
			if (action.mode === AsyncActionMode.RESPONSE) {
				return {
					...state,
					certificate: undefined,
					certificates: {
						...state.certificates,
						items: state.certificates.items!.filter((c) => c.id !== action.request.id),
					},
					isSaving: false,
				}
			}
			if (action.mode === AsyncActionMode.ERROR) {
				return {
					...state,
					isSaving: false,
					error: action.error,
				}
			}
			break

		case ActionType.UPDATE_CERTIFICATE:
			if (action.mode === AsyncActionMode.REQUEST) {
				return { ...state, isSaving: true }
			}
			if (action.mode === AsyncActionMode.RESPONSE) {
				return {
					...state,
					certificate: action.data,
					certificates: {
						...state.certificates,
						items: state.certificates.items!.map((c) => (c.id === action.data.id ? { ...action.data } : c)),
					},
					isSaving: false,
				}
			}
			if (action.mode === AsyncActionMode.ERROR) {
				return {
					...state,
					isSaving: false,
					error: action.error,
				}
			}
			break
		case ActionType.LOOKUP_CERTIFICATE:
			if (action.mode === AsyncActionMode.REQUEST) {
				return { ...state, lookup_name: [], isLoadingLookup: true }
			}
			if (action.mode === AsyncActionMode.RESPONSE) {
				if (action.field === 'subjects') {
					return {
						...state,
						lookup_name: action.data,
						isLoadingLookup: false,
					}
				}
				console.error('Wrong action field.')
			}
			if (action.mode === AsyncActionMode.ERROR) {
				return {
					...state,
					error: action.error,
					isLoadingLookup: false,
				}
			}
			break
	}
	return state
}
