import { AutoComplete, Button, Card, Col, Divider, message, Modal, Row, Space, Spin, Table } from "antd"
import { AppState } from "common/models"
import ErrorPage403 from "components/Errors/ErrorPage403"
import { useLoggedUser } from "helpers/loginUserHelper"
import { useEffect, useRef, useState } from "react"
import Draggable, { DraggableData } from "react-draggable"
import { DraggableEvent } from "react-draggable"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { useHistory, useParams } from "react-router"
import useLogger from "common/useLogger"
import { LoadingIndicator } from "components"
import getMailService from "./actions/getMailService"
import React from "react"
import { MailDomainModel, MailServiceModel } from "./models"
import getMailDomainsByService from "./actions/getMailDomainsByService"
import { ConsoleSqlOutlined, DeleteTwoTone, MailOutlined, SyncOutlined } from "@ant-design/icons"
import { PlusCircleOutlined } from "@ant-design/icons"
import { Link } from "react-router-dom"
import { getServiceUiType, renderService } from "pages/server/common"
import getDaemonVersion from "./actions/getDaemonVersion"
import getMailVersion from "./actions/getMailVersion"
import CreateMailDomainForm from "./CreateMailDomainForm"
import Highlighter from "react-highlight-words"
import HistoryLog from "components/History/HistoryLog"
import { removeDiac, stopPropagation } from "common/fce"
import { confirmDeleteBox } from "common/utils"
import deleteMailService from "./actions/deleteMailService"
import './Mails.scss'
import TextArea from "antd/lib/input/TextArea"
import updateMailService from "./actions/updateMailService"

export interface ParamTypes {
  service_id: string
}

const renderServiceBox = (name: string) => {
  if (!name) {
    return ''
  }
  const box = getServiceUiType(name)
  if (box) {
    return (
      <Card title={renderService(name)} key={box.id.toString()} style={
        {
          textAlign: 'center',
          width: '220px',
          margin: '10% 25%',
          padding: '10px',
          borderRadius: '8px',
          border: `2px solid ${box.color}`,
          backgroundColor: '#ededed'
        }}>
      </Card>
    )
  }
  return ''
}



const MailServiceDetailPage = () => {
  const CONTROL_NAME = 'page_mail_service'
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const history = useHistory()
  const { service_id } = useParams<ParamTypes>()

  const { isLoadingService, mail_service, mail_service_domains, mail_version, daemon_version, error } = useSelector((state: AppState) => state.mailservice)

  const [searchName, setSearchName] = useState<string>('')
  const [mailDomainSource, setMailDomainSource] = useState<MailDomainModel[]>([])
  const [isMailDomainModalVisible, setMailDomainModalVisible] = useState<boolean>(false)
  const [forceUpdateId, setForceUpdateId] = useState<number>(1)
  const [historyModelId, setHistoryModelId] = useState<number | undefined>()
  const [openDelete, setOpenDelete] = useState(false)
  const [confirmDelete, setConfirmDelete] = useState(false)
  const [updateStatus, setUpdateStatus] = useState(false)
  const [comment, setComment] = useState('')


  const [isViewer, setViewer] = useState(false)
  const [isMailAdmin, setMailAdmin] = useState(false)
  const [isMailDomainCreator, setMailDomainCreator] = useState(false)
  const [isMailDomainEditor, setMailDomainEditor] = useState(false)
  const [isMailDomainDeleter, setMailDomainDeleter] = useState(false)

  // history drag modal
  const [disabled, setDisabled] = useState(true)
  const draggleRef = useRef<HTMLDivElement>(null)
  const [bounds, setBounds] = useState({ left: 0, top: 0, bottom: 0, right: 0 })
  const onStart = (_event: DraggableEvent, uiData: DraggableData) => {
    const { clientWidth, clientHeight } = window.document.documentElement
    const targetRect = draggleRef.current?.getBoundingClientRect()
    if (!targetRect) {
      return
    }
    setBounds({
      left: -targetRect.left + uiData.x,
      right: clientWidth - (targetRect.right - uiData.x),
      top: -targetRect.top + uiData.y,
      bottom: clientHeight - (targetRect.bottom - uiData.y),
    })
  }


  // get settings and current user
  const loggedUser = useLoggedUser()
  if (!loggedUser || !loggedUser.isLoaded()) {
    // waiting..
    return (
      <div className="fullwidth-loading" >
        <LoadingIndicator />
      </div>
    )
  }

  // required authorization
  if (!loggedUser.hasAccess(CONTROL_NAME)) {
    return <ErrorPage403 />
  }

  // settings
  const appSetting = loggedUser.getAppSettings()

  const logger = useLogger(appSetting, 'MailServiceDetailPage')

  useEffect(() => {
    logger('service_id: ' + service_id)
    const access = loggedUser.hasAccess('page_mail_service')
    setViewer(access)

    if (!access) {
      // failover 403
      message.error('No permissions!')
      history.replace('/mail/domains')
      return
    }

    setMailAdmin(loggedUser.hasRole('mail-admin'))
    setMailDomainCreator(loggedUser.hasAccess('page_mail_domains_create_button'))
    setMailDomainEditor(loggedUser.hasAccess('page_mail_domains_edit_button'))
    setMailDomainDeleter(loggedUser.hasAccess('page_mail_domains_delete_button'))

    if (parseInt(service_id) > 0) {
      // loadOptions()
      const sid = parseInt(service_id)
      dispatch(getMailService(sid))
    }
    else {
      // failover 404
      history.replace('/mail/domains')
    }
  }, [])

  useEffect(() => {
    if (mail_service && mail_service.id === parseInt(service_id)) {
      checkServer()
      setHistoryModelId(mail_service.id)
      dispatch(getMailDomainsByService(mail_service.id))
      loadHistory()
      setComment(mail_service.comment || '')
    }
  }, [mail_service && mail_service.id])

  useEffect(() => {
    renderDatabases()
  }, [mail_service_domains, searchName])

  const renderDatabases = () => {
    if (searchName && appSetting.checkMinSearch(searchName)) {
      setMailDomainSource(mail_service_domains.filter(d => removeDiac(d.name).includes(removeDiac(searchName))))
    }
    else {
      setMailDomainSource(mail_service_domains)
    }
  }

  const checkServer = () => {
    dispatch(getDaemonVersion(parseInt(service_id)))
    dispatch(getMailVersion(parseInt(service_id)))
    loadHistory()
  }

  const isDaemonReady = (): boolean => {
    return !!daemon_version && parseInt(daemon_version) > 0
  }

  const loadHistory = () => {
    setForceUpdateId(forceUpdateId + 1)	// update history
  }

  const handleDeleteService = (rec: MailServiceModel) => {
    if (rec) {
      setConfirmDelete(true)
      dispatch(deleteMailService(rec.id, suc => {
        setConfirmDelete(false)
        setOpenDelete(false)
        if (suc) {
          message.success(t('general.success'))
          history.replace('/mail/domains')
        }
      }))
    }
    else {
      logger(JSON.stringify(rec))
      message.error(t('general.error'))
    }
  }

  const FilterByName = (
    <AutoComplete
      placeholder={t('mailDomainsPage.domain')}
      style={{ width: '150px' }}
      value={searchName}
      allowClear={true}
      onClick={stopPropagation}
      onKeyDown={(e) => { if (e.key === 'Enter') { e.stopPropagation() } }}
      onChange={(v) => {
        setSearchName(v)
      }}
    />
  )

  if (!mail_service || !mail_service.id) {
    return (<Spin />)
  }

  return (
    <>
      <Card
        title={<><ConsoleSqlOutlined /> &nbsp; {t('mailDomainsPage.mail_service')} &nbsp; {mail_service.host}</>}
        className='MailServiceDetailPage'
        loading={isLoadingService}
        extra={
          <>
            <Button type='primary'
              disabled={!mail_service || !isDaemonReady() || !isMailDomainCreator}
              onClick={() => setMailDomainModalVisible(true)}>
              <PlusCircleOutlined /> {t('mailDomainsPage.new_domain')}
            </Button>
          </>
        }
      >


        <Row>
          <Col xs={24} sm={24} md={24} lg={8} xl={8} className='pad4 center'>
            &nbsp;{renderServiceBox('mail')}
            <Row>
              <Col span={12} className='right bold pad4'>{t('mailDomainsPage.daemon')}:</Col>
              <Col span={12} className='left pad4'>
                {(daemon_version && parseInt(daemon_version) > 0) && (
                  <Space>
                    <span className='text-success'>OK</span>
                    <span className='small'>version</span>
                    <b>{daemon_version}</b>
                  </Space>
                )}
                {(daemon_version === daemon_version) && (
                  <Space>
                    <span className='text-alert'>{error}</span>
                  </Space>
                )}
                {(daemon_version === undefined) && (
                  <Space>
                    <SyncOutlined spin color='blue' />
                  </Space>
                )}
              </Col>
              <Col span={12} className='right bold pad4'>{t('mailDomainsPage.version')}:</Col>
              <Col span={12} className='left pad4'>
                {(mail_version) && (
                  <Space>
                    <span className='text-success'>OK</span>
                    <b>{mail_version}</b>
                  </Space>
                )}
                {(mail_version === null) && (
                  <Space>
                    <span className='text-alert'>{error}</span>
                  </Space>
                )}
                {(mail_version === undefined) && (
                  <Space>
                    <SyncOutlined spin color='blue' />
                  </Space>
                )}
              </Col>
              <Col span={12} className='right bold pad4'></Col>
              <Col span={12} className='left pad4'>
                <Button size='small' onClick={() => checkServer()}>Check state</Button>
              </Col>
            </Row>
          </Col>
          <Col xs={24} sm={24} md={24} lg={16} xl={16} className='pad4'>
            <Row>
              <Col span={6} className='right bold pad4' style={{ marginTop: '8px' }}><b>{t('mailDomainsPage.host')}</b>:</Col>
              <Col span={18} className='pad4'><h2>{mail_service.host}</h2></Col>
            </Row>
            <Row>
              <Col span={6} className='right bold pad4'>id:</Col>
              <Col span={18} className='pad4'>{mail_service.id}</Col>
            </Row>

            <Row>
              <Col span={6} className='right bold pad4'>{t('mailDomainsPage.server')}:</Col>
              <Col span={18} className='pad4'>
                {
                  (loggedUser.user.is_zcom && isMailAdmin) ?
                    <Link to={`/servers/edit/${mail_service?.server_id}`}>{mail_service?.host}</Link>
                    :
                    mail_service?.host
                }
              </Col>
            </Row>

            <Row>
              <Col span={6} className='right bold pad4'>{t('mailDomainsPage.customer')}:</Col>
              <Col span={18} className='pad4'>{mail_service.customer?.name}</Col>
            </Row>

            <Row>
              <Col span={6} className='right bold pad4'>{t('general.created')}:</Col>
              <Col span={18} className='pad4'>{appSetting.renderDate(mail_service.created_at)}</Col>
            </Row>

          </Col>
        </Row>

        <Divider />

        <Row>
          <Col span={8} style={{ padding: '15px' }}>
            <Table<MailDomainModel>
              columns={[
                {
                  title: FilterByName,
                  dataIndex: 'name',
                  key: 'name',
                  width: 200,
                  align: 'left',
                  render: (text: string, rec: MailDomainModel) => (
                    <Link to={`/mail/domain/${rec.id}`}>
                      <Highlighter
                        highlightStyle={{
                          backgroundColor: '#ffc069',
                          color: '#2d9259',
                          padding: 0,
                        }}
                        searchWords={[searchName]}
                        autoEscape
                        textToHighlight={text.toString()}
                      />
                    </Link>
                  ),
                }]}
              dataSource={mailDomainSource}
              scroll={{ x: 'max-content' }}
              rowKey='id'
              bordered={true}
              className='MailServiceDomainsTable'
              style={{ width: '100%' }}
              loading={isLoadingService}
              showHeader={true}
              size='small'
              pagination={false}
            />
          </Col>
          <Col span={16} style={{ padding: '15px' }}>

            <Row>
              <Col span={24} className='bold pad4'>{t('mailDomainsPage.comment')}:</Col>
              <Col span={24} className='pad4'>
                <TextArea rows={7} value={comment}
                  data-gramm="false"
                  data-1p-ignore
                  onChange={(e) => { setComment(e.target.value); setUpdateStatus(true) }} />
                <Button type='primary' size="small" style={{ marginTop: '4px', float: 'right' }}
                  disabled={!updateStatus}
                  onClick={() => {
                    dispatch(updateMailService({ id: mail_service.id, comment: comment }, suc => {
                      if (suc) {
                        message.success(t('general.success'))
                        setUpdateStatus(false)
                      }
                    }))
                  }}>{t('general.save')}</Button>
              </Col>
            </Row>

          </Col>
        </Row>

        <Row>
          <Col span={24} className='pad4'>
            <HistoryLog service='mail'
              model='Service'
              modelId={historyModelId}
              forceUpdateId={forceUpdateId}
              isModal={false}
              showTitle={true} />
          </Col>
        </Row>

        <Row>
          <Col span={24} className='pad8'>
            <Divider />
            <Button danger size='small'
              disabled={!isMailAdmin}
              onClick={() => {
                if (mail_service) {
                  if (!isDaemonReady()) {
                    message.error(t('mailDomainsPage.daemon_not_ready'))
                    return
                  }
                  confirmDeleteBox(
                    t('mailDomainsPage.confirm_delete_service'),
                    mail_service.host,
                    confirmDelete,
                    () => handleDeleteService(mail_service),
                    () => { setOpenDelete(false) },
                    t
                  )
                }
              }}
              style={{ float: 'right' }}
              icon={<DeleteTwoTone twoToneColor='red' />}>{t('mailDomainsPage.btn_delete_service')}</Button>

          </Col>
        </Row>

      </Card>


      <Modal title={
        <div style={{ width: '100%', cursor: 'move' }}
          onMouseOver={() => { if (disabled) { setDisabled(false) } }}
          onMouseOut={() => { setDisabled(true) }}
          onFocus={() => { }}
          onBlur={() => { }}
        >
          <MailOutlined /> &nbsp; {t('mailDomainsPage.new_domain')}
        </div>
      }
        destroyOnClose
        style={{ top: 20 }}
        width={600}
        visible={isMailDomainModalVisible}
        onCancel={() => setMailDomainModalVisible(false)}
        modalRender={(modal) => (
          <Draggable disabled={disabled} bounds={bounds} onStart={(ev, data) => onStart(ev, data)}>
            <div ref={draggleRef}>{modal}</div>
          </Draggable>
        )}
        footer={null}
        confirmLoading={true}
      >
        <CreateMailDomainForm onClose={() => {
          setMailDomainModalVisible(false)
        }}
        />
      </Modal>


    </>
  )

}

export default MailServiceDetailPage
