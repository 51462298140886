import React, { useEffect, useState } from 'react'
import { Tag } from 'antd'
import { Link, useParams, withRouter } from 'react-router-dom'
import store from 'redux/store'
import './BreadcrumbZis.scss'
import { useTranslation } from "react-i18next"


const BreadcrumbZis = withRouter(props => {
  const { t } = useTranslation()
  const { authenticated, buffer } = store.getState().auth
  const [list, setList] = useState<string[]>([])

  useEffect(() => {
    setList(buffer)
  }, [buffer])

  const toName = (url: string) => {
    let name = url.toLowerCase()
    if (name === '/') {
      return t('dashboardPage.title')
    }
    if (name.endsWith('/')) {
      name = name.substring(0, name.length - 1)
    }
    if (name === '/billing/companies') {
      return t('companiesPage.title')
    }
    if (name === '/billing/invoice') {
      return t('billing.invoice.title')
    }
    if (name === '/users') {
      return t('usersPage.title')
    }
    if (name.substring(0, 7) === '/users/') {
      return t('usersPage.title_detail') + ':' + name.substring(7)
    }
    if (name === '/rights/user') {
      return t('rights.users.title')
    }
    if (name === '/rights/role') {
      return t('rights.roles.title')
    }
    if (name.toLowerCase().includes('/rights/role/')) {
      const arr = name.split('/')
      return t('rights.roles.title') + ':' + arr[arr.length - 1]
    }
    if (name === '/rights/user-group') {
      return t('rights.userGroups.title')
    }
    if (name === '/rights/resource-group') {
      return t('rights.resources.title')
    }
    if (name.toLowerCase().includes('/rights/resource-group/')) {
      const arr = name.split('/')
      return t('rights.resources.title') + ':' + arr[arr.length - 1]
    }

    if (name === '/billing/customer') {
      return t('customerPage.title')
    }
    if (name === '/billing/pricelist') {
      return t('billing.pricelist.title')
    }
    if (name.substring(0, 27) === '/billing/pricelist-products') {
      return t('billing.pricelist.title') + name.substring(27)
    }
    if (name.substring(0, 26) === '/billing/customer-services') {
      return t('billing.customer-services.title') + name.substring(26)
    }
    if (name.substring(0, 21) === '/billing/invoice/edit') {
      return t('billing.invoice.title') + name.substring(21)
    }
    if (name.substring(0, 18) === '/billing/bill/edit') {
      return t('billing.bill.title') + name.substring(18)
    }
    if (name.substring(0, 20) === '/billing/bill/create') {
      return t('billing.bill.title') + '/new'
    }
    if (name === '/billing/bill') {
      return t('billing.bill.title')
    }
    if (name === '/billing/mail') {
      return t('billing.mail.title')
    }
    if (name === '/billing/dim/cost-allocation-category') {
      return t('costAllocationPage.title')
    }
    if (name === '/billing/dim/countries') {
      return t('countries.title')
    }
    if (name === '/billing/dim/products') {
      return t('billing.products.title')
    }
    if (name === '/billing/dim/servicetypes') {
      return t('billing.servicetypes.title')
    }
    if (name === '/billing/dim/mail-templates') {
      return t('billing.mail_template.title')
    }
    if (name === '/billing/dim/taxes') {
      return t('billing.tax.title')
    }
    if (name === '/inventory/dim/types') {
      return t('inventory.menu.inventory_type_title')
    }
    if (name === '/inventory/dim/locations') {
      return t('inventory.menu.location_title')
    }
    if (name === '/inventory/physical-servers') {
      return t('physicalServersPage.title')
    }
    if (name === '/inventory') {
      return t('inventory.menu.inventory')
    }
    if (name === '/logviewer') {
      return t('logViewer.title')
    }
    if (name === '/settings') {
      return t('settingsPage.title')
    }
    if (name === '/ip/subnets') {
      return t('ipSubnetPage.title')
    }
    if (name.substring(0, 11) === '/ip/subnet/') {
      return t('ipSubnetPage.subnet') + name.substring(10)
    }
    if (name.substring(0, 14) === '/servers/edit/') {
      return t('serversPage.server') + name.substring(13)
    }
    if (name === '/servers') {
      return t('serversPage.title')
    }
    if (name.substring(0, 12) === '/dns/domains') {
      if (name.endsWith('/records')) {
        return name.substring(13).replace('/records', '')
      }
      else {
        return t('domainPage.title')
      }
    }
    if (name.substring(0, 11) === '/dns/zones/') {
      let domain = 'zone:' + name.substring(10)
      return domain.replace('/records', '')
    }
    if (name === '/dns/services') {
      return t('dnsPage.title')
    }
    if (name.substring(0, 14) === '/dns/services/') {
      return 'DNS' + name.substring(13)
    }
    if (name === '/certificates') {
      return t('certificatesPage.title')
    }
    if (name === '/jobs') {
      return 'Jobs'
    }
    if (name === '/rdb/databases') {
      return t('databasesPage.title')
    }
    if (name.substring(0, 14) === '/rdb/services/') {
      return 'SqlServer' + name.substring(13)
    }
    if (name.substring(0, 14) === '/rdb/database/') {
      return 'DB' + name.substring(13)
    }
    if (name === '/web/vhosts') {
      return 'Vhosts'
    }
    if (name.substring(0, 11) === '/web/vhost/') {
      return 'Vhost' + name.substring(10)
    }
    if (name === '/web/templates') {
      return 'Templates'
    }
    if (name.substring(0, 15) === '/web/templates/') {
      return 'Template' + name.substring(14)
    }
    if (name.substring(0, 14) === '/web/services/') {
      return 'WebServer' + name.substring(13)
    }
    if (name.substring(0, 13) === '/mail/domains') {
      return 'Maily'
    }
    if (name.substring(0, 13) === '/mail/domain/') {
      return 'Mail' + name.substring(12)
    }
    if (name.substring(0, 15) === '/mail/services/') {
      return 'MailServer' + name.substring(14)
    }
    if (name === '/vpsadmin/hypervisors') {
      return t('vpsadmin.hypervisors.title')
    }
    if (name.substring(0, 21) === '/vpsadmin/hypervisor/') {
      let arr = name.split('/')
      return t('vpsadmin.hypervisor') + ' ' + arr[arr.length - 1]
    }
    if (name === '/vpsadmin/vps') {
      return t('vpsadmin.vps.title')
    }
    if (name.substring(0, 14) === '/vpsadmin/vps/') {
      let arr = name.split('/')
      return t('vpsadmin.vps.title') + ' ' + arr[arr.length - 1]
    }
    if (name.substring(0, 17) === '/vpsadmin/setting') {
      return t('vpsadmin.settings')
    }
    return url
  }

  if (!authenticated) {
    return <div style={{ display: 'block' }}>&nbsp;</div>
  }

  return (
    <div style={{ padding: '5px' }}>
      {list.filter(s => s != '/2fa').map(item => {
        return <Tag key={item} style={{ borderRadius: '5px', border: '0' }}>
          <Link to={item} className='link'>{toName(item)}</Link>
        </Tag>
      })}
    </div>
  )
})

export default BreadcrumbZis
