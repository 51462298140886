import {useForm} from "antd/lib/form/Form"
import {useTranslation} from "react-i18next"
import React, {useEffect, useState} from "react"
import {useDispatch, useSelector} from "react-redux"
import {Button, Form, Input, message, Select, Space, Spin, Switch, Tag} from "antd"
import {formItemLayout, tailLayout} from "../../../helpers/layoutHelpers"
import {CheckOutlined, CloseOutlined} from "@ant-design/icons"
import {SettingsDetails} from "../models"
import {AppState} from "../../../common/models"
import {useLoggedUser} from "../../../helpers/loginUserHelper"
import {_toInt} from "../../../common/fce";
import updateAppSetting from "../actions/updateAppSetting";


interface Props {
    onClose: () => void
}


const SettingForm = (props: Props) => {
    const [form] = useForm()
    const {t} = useTranslation()
    const dispatch = useDispatch();

    const {setting, isLoading, isSaving} = useSelector((state: AppState) => state.appSettingPage)

    const [updateStatus, setUpdateStatus] = useState<boolean>(false)
    const [frozen, setFrozen] = useState(true)
    const [hidden, setHidden] = useState(true)
    const [scopeOptions, setScopeOptions] = useState<{ label: string, value: string }[]>([])


    // get settings and logged user from store
    const loggedUser = useLoggedUser()
    if (!loggedUser || !loggedUser.isLoaded()) {
        return (<Spin />)
    }


    useEffect(() => {
        // when user logged => set permissions
        if (loggedUser) {
            let items = [{label: 'Application', value: 'app'}]
            setScopeOptions(items)
        }
    }, [])

    useEffect(() => {
        if (setting) {
            setFrozen(setting.frozen === 1)
            setHidden(setting.hidden === 1)
            console.log('setting: '+JSON.stringify(setting))
        }
    }, [setting])


    const handleSubmit = (values: any) => {
        if (setting) {
            const params = {...values,
                id: setting.id,
                frozen: _toInt(frozen),
                hidden: _toInt(hidden),
                object_id: 0}
            dispatch(
                updateAppSetting(params, (suc: boolean) => {
                    if (suc) {
                        message.success(t('general.success'))
                        props.onClose()
                    }
                }),
            )
        }
    }

    if (!setting) {
        return (
            <Spin/>
        )
    }

    return (
        <Form {...formItemLayout}
              onFinish={handleSubmit}
              form={form}
              initialValues={{...setting, frozen: setting.frozen === 1, hidden: setting.hidden === 1}}
              onChange={() => setUpdateStatus(true)}
        >

            <Form.Item name='name'
                       label={t('settingsPage.name')}
                       rules={[{required: true, message: t('errors.field_required')}]}
                       >
                <Space>
                    <Form.Item noStyle>
                        <h2>{setting.name}</h2>
                    </Form.Item>
                </Space>
            </Form.Item>

            <Form.Item name='group' label={t('settingsPage.group')}
                       rules={[{required: true, message: t('errors.field_required')}]}
                       >
                <Input />
            </Form.Item>

            <Form.Item name='value' label={t('settingsPage.value')}
                       rules={[{required: true, message: t('errors.field_required')}]}
                       >
                <Input />
            </Form.Item>

            <Form.Item name='scope' label={t('settingsPage.scope')}>
                <Select
                    style={{width: '120px'}}
                    disabled={true}
                    options={scopeOptions}
                />
            </Form.Item>

            <Form.Item label={t('settingsPage.frozen')}
                       valuePropName='frozen'>
                <Form.Item name="frozen" noStyle>
                    <Switch
                        checkedChildren={<CheckOutlined/>}
                        unCheckedChildren={<CloseOutlined/>}
                        checked={frozen}
                        onChange={() => {setFrozen(!frozen); setUpdateStatus(true)}}
                    />
                </Form.Item>
            </Form.Item>

            <Form.Item label={t('settingsPage.hidden')}
                       valuePropName='hidden'>
                <Form.Item name="hidden" noStyle>
                    <Switch
                        checkedChildren={<CheckOutlined/>}
                        unCheckedChildren={<CloseOutlined/>}
                        checked={hidden}
                        onChange={() => {setHidden(!hidden); setUpdateStatus(true)}}
                    />
                </Form.Item>
            </Form.Item>

            <Form.Item {...tailLayout}>
                <Button type='primary'
                        htmlType='submit'
                        className='login-form-button'
                        disabled={!updateStatus}>
                    {t('general.save')}
                </Button>
            </Form.Item>
        </Form>
    )
}

export default SettingForm
