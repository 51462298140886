import { useTranslation } from "react-i18next"
import { useHistory } from "react-router"
import { useDispatch, useSelector } from "react-redux"
import { Link, useParams } from "react-router-dom"
import { AppState } from "../../common/models"
import React, { useEffect, useRef, useState } from "react"
import { useLoggedUser } from "../../helpers/loginUserHelper"
import { LoadingIndicator } from "../../components"
import ErrorPage403 from "../../components/Errors/ErrorPage403"
import useLogger from "../../common/useLogger"
import Draggable, { DraggableData, DraggableEvent } from "react-draggable"
import { Alert, AutoComplete, Button, Card, Col, Divider, Form, Input, message, Modal, Row, Select, Space, Spin, Switch, Table, Tag } from "antd"
import { CheckOutlined, CloseOutlined, DeleteTwoTone, ExclamationCircleOutlined, RadarChartOutlined } from "@ant-design/icons"
import getWebService from "./actions/getWebService"
import deleteVhost from "./actions/deleteVhost"
import { AliasModel, VhostModel, VhostParams } from "./models"
import getVhost from "./actions/getVhost"
import Pager from "../../components/pagination/pager"
import { removeDiac, stopPropagation } from "../../common/fce"
import getWebDaemon from "./actions/getWebDaemon"
import getWebTemplate from "./actions/getWebTemplate"
import getWebTemplates from "./actions/getWebTemplates"
import getServiceTemplates from "./actions/getServiceTemplates"
import getAliases from "./actions/getAliases"
import { ColumnsType } from "antd/es/table"
import PopConfirmZis from "components/popconfirm/PopConfirmZis"
import './WebServices.scss'
import getCertificate from "./actions/getCertificate"
import NewAliasForm from "./NewAliasForm"
import deleteAlias from "./actions/deleteAlias"
import getDomainVhost from "./actions/getDomainVhost"
import { formItemLayout } from "helpers/layoutHelpers"
import { useForm } from "antd/es/form/Form"
import { Store } from "antd/es/form/interface"
import updateVhost from "./actions/updateVhost"
import applyChanges from "./actions/applyChanges"
import GenCertificateForm from "pages/certificate/GenCertificateForm"
import getCertificatesVhost from "./actions/getCertificatesVhost"
import removeCertFromVhost from "./actions/removeCertFromVhost"
import PreviewConfigForm from "./PreviewConfigForm"
import TextArea from "antd/lib/input/TextArea"
const { confirm } = Modal

interface ParamTypes {
    id: string
}

interface Props {
    serviceId: number | undefined
    onClose: () => void
}

const VhostDetailPage = (props: Props) => {
    const CONTROL_NAME = 'page_web_vhost_detail'
    const [form] = useForm()
    const { t } = useTranslation()
    const history = useHistory()
    const dispatch = useDispatch()
    const { id } = useParams<ParamTypes>()

    const { isLoadingVhost, service, vhost, vhost_aliases, certificate, certificates, error,
        web_daemon, service_templates, template, fqdn_domain, isApplyingChangesVhost } = useSelector((state: AppState) => state.webservice)

    const [connected, setConnected] = useState(false)
    const [aliasName, setAliasName] = useState<string>('')

    const [certificateOptions, setCertificateOptions] = useState<{ label: string, value: number, key: number }[]>([])
    const [serviceTemplateOptions, setServiceTemplateOptions] = useState<{ label: string, value: number, key: number }[]>([])
    const [aliasSource, setAliasSource] = useState<AliasModel[]>([])
    const [updateStatus, setUpdateStatus] = useState<boolean>(false)
    const [isActive, setActive] = useState<boolean>(false)
    const [isAfw, setAfw] = useState<boolean>(false)
    const [isForceRedirectWWW, setForceRedirectWWW] = useState<boolean>(false)
    const [isForceRedirectHTTPS, setForceRedirectHTTPS] = useState<boolean>(false)
    const [isHttp, setHttp] = useState<boolean>(false)
    const [isHttps, setHttps] = useState<boolean>(false)
    const [removeCert, setRemoveCert] = useState<boolean>(false)

    const [isAliasModalVisible, setAliasModalVisible] = useState(false)
    const [isPreviewModalVisible, setPreviewModalVisible] = useState(false)
    const [isCertModalVisible, setCertModalVisible] = useState(false)
    const [openDelete, setOpenDelete] = useState(false)
    const [confirmDelete, setConfirmDelete] = useState(false)

    const [isVhostViewer, setVhostViewer] = useState(false)
    const [isVhostCreator, setVhostCreator] = useState(false)
    const [isVhostEditor, setVhostEditor] = useState(false)
    const [isVhostDeleter, setVhostDeleter] = useState(false)
    const [isWebServiceEditor, setWebServiceEditor] = useState(false)

    // get settings and current user
    const loggedUser = useLoggedUser()
    if (!loggedUser || !loggedUser.isLoaded()) {
        // waiting..
        return (
            <div className="fullwidth-loading" >
                <LoadingIndicator />
            </div>
        )
    }

    // required authorization
    if (!loggedUser.hasAccess(CONTROL_NAME)) {
        return <ErrorPage403 />
    }

    // settings
    const appSetting = loggedUser.getAppSettings()

    const logger = useLogger(appSetting, 'VhostDetailPage')

    // history drag modal
    const [disabled, setDisabled] = useState(true)
    const draggleRef = useRef<HTMLDivElement>(null)
    const [bounds, setBounds] = useState({ left: 0, top: 0, bottom: 0, right: 0 })
    const onStart = (_event: DraggableEvent, uiData: DraggableData) => {
        const { clientWidth, clientHeight } = window.document.documentElement
        const targetRect = draggleRef.current?.getBoundingClientRect()
        if (!targetRect) {
            return
        }
        setBounds({
            left: -targetRect.left + uiData.x,
            right: clientWidth - (targetRect.right - uiData.x),
            top: -targetRect.top + uiData.y,
            bottom: clientHeight - (targetRect.bottom - uiData.y),
        })
    }

    useEffect(() => {
        logger('id: ' + id)
        const access = loggedUser.hasAccess(CONTROL_NAME)
        setVhostViewer(access)

        if (!access) {
            // failover 403
            message.error('No permissions!')
            history.replace('/web/vhosts')
            return
        }
        setVhostEditor(loggedUser.hasAccess('page_web_vhost_edit_button'))
        setVhostDeleter(loggedUser.hasAccess('page_web_vhost_delete_button'))
        setWebServiceEditor(loggedUser.hasAccess('page_web_service_edit_button'))
        if (parseInt(id) > 0) {
            const did = parseInt(id)
            dispatch(getVhost(did, suc => {
                !suc && history.replace('/web/vhosts')
            }))
        }
        else {
            // failover 404
            history.replace('/web/vhosts')
        }
    }, [])

    useEffect(() => {
        if (vhost && vhost.id) {
            dispatch(getAliases(vhost.id))
            dispatch(getWebService(vhost.service_id))
            dispatch(getWebDaemon(vhost.service_id))
            dispatch(getWebTemplate(vhost.template_id))
            vhost.certificate_id && dispatch(getCertificate(vhost.certificate_id))
            setAfw(vhost.is_afw === 1)
            setActive(vhost.is_active === 1)
            setForceRedirectHTTPS(vhost.is_force_https_redirect === 1)
            setForceRedirectWWW(vhost.is_force_www_redirect === 1)
            setHttp(vhost.protocol === 0 || vhost.protocol === 2)
            setHttps(vhost.protocol === 1 || vhost.protocol === 2)
            vhost.domain?.id && dispatch(getDomainVhost(vhost.domain.id))
            dispatch(getCertificatesVhost())
            setRemoveCert(false)
            form.setFieldsValue({
                certificate_id: vhost.certificate_id,
                template_id: vhost.template_id
            })
        }
    }, [vhost])


    useEffect(() => {
        if (!connected && template && template.id) {
            setServiceTemplateOptions([{ label: template.name, value: template.id, key: template.id }])
        }
    }, [template])


    useEffect(() => {
        if (certificates) {
            setCertificateOptions(certificates.map(t => ({ label: t.name, value: t.id, key: t.id })))
        }
    }, [certificates, certificates.length])

    useEffect(() => {
        if (web_daemon && parseInt(web_daemon) > 0) {
            setConnected(true)
            vhost && dispatch(getServiceTemplates(vhost.service_id))
        }
        else {
            setConnected(false)
        }
    }, [web_daemon])

    useEffect(() => {
        if (connected && service_templates) {
            setServiceTemplateOptions(
                service_templates.map(t => ({ label: t.name, value: t.id, key: t.id }))
            )
        }
    }, [service_templates])

    useEffect(() => {
        renderAliases()
    }, [aliasName])

    useEffect(() => {
        renderAliases()
    }, [vhost_aliases])

    const renderAliases = () => {
        if (aliasName && appSetting.checkMinSearch(aliasName)) {
            setAliasSource(vhost_aliases.filter(h => removeDiac(h.name).includes(removeDiac(aliasName))))
        }
        else {
            setAliasSource(vhost_aliases)
        }
    }

    const showConfirmDelete = (d: VhostModel, title: string) => {
        if (!d) {
            return
        }
        // if (!isDeleter) {
        //     message.error(t('general.error403'))
        //     return
        // }
        confirm({
            icon: <ExclamationCircleOutlined style={{ color: 'red' }} />,
            title: title,
            content: <p>{d.name}</p>,
            okText: t('general.yes'),
            cancelText: t('general.cancel'),
            okButtonProps: { loading: confirmDelete },
            className: 'confirm-alert',
            onOk() {
                setConfirmDelete(true)
                dispatch(deleteVhost(d.id!, suc => {
                    setConfirmDelete(false)
                    setOpenDelete(false)
                    if (suc) {
                        message.success(t('general.success'))
                        history.replace('/web/vhosts')
                    }
                }))
            },
            onCancel() {
                setOpenDelete(false)
            },
        }
        )
    }

    const handleDeleteAlias = (item: AliasModel) => {
        if (!vhost || !vhost.id) {
            return
        }
        dispatch(deleteAlias({ vhost_id: vhost.id, name: item.name }, suc => {
            if (suc) {
                message.success(t('general.success'))
            }
        }))
    }

    const getProtocol = (isHttp: boolean, isHttps: boolean) => {
        if (isHttp && isHttps) {
            return 2
        }
        if (isHttps) {
            return 1
        }
        return 0
    }

    const onFormSubmit = (values: Store) => {
        console.log(values)
        if (!vhost || !vhost.id) {
            console.warn('vhost not found')
            return
        }
        const params: VhostParams = {
            id: vhost.id,
            template_id: values.template_id,
            custom_config: values.custom_config,
            is_active: isActive ? 1 : 0,
            is_force_https_redirect: isForceRedirectHTTPS ? 1 : 0,
            is_force_www_redirect: isForceRedirectWWW ? 1 : 0,
            protocol: getProtocol(isHttp, isHttps),
            is_afw: isAfw ? 1 : 0,
            redirect_target: values.redirect_target,
            certificate_id: values.certificate_id
        }

        dispatch(updateVhost(params, suc => {
            if (suc) {
                message.success(t('general.success'))
                if (removeCert && vhost && vhost.id) {
                    dispatch(removeCertFromVhost(vhost.id, suc => {
                        setRemoveCert(false)
                        vhost.id && dispatch(getVhost(vhost.id))
                    }))
                }
            }
        }))
    }

    const FilterByAlias = (
        <AutoComplete
            placeholder='Alias'
            style={{ width: '100px' }}
            value={aliasName}
            allowClear={true}
            onClick={stopPropagation}
            onKeyDown={(e) => { if (e.key === 'Enter') { e.stopPropagation() } }}
            onChange={(v) => {
                setAliasName(v)
            }}
        />
    )

    const columns: ColumnsType<AliasModel> = [
        {
            title: FilterByAlias,
            dataIndex: 'name',
            key: 'name',
            width: 120
        },
        {
            title: (<div className='center'>Action</div>),
            key: 'action',
            dataIndex: 'action',
            width: 60,
            align: 'center',
            render: (text: string, rec: AliasModel) => (
                <PopConfirmZis title={t('webPage.vhosts.btn_delete_alias') + '?'} onConfirm={() => { handleDeleteAlias(rec) }}>
                    <Button type='text' danger size='small'
                        className='actionButton'
                        title='Delete'
                        icon={<DeleteTwoTone twoToneColor={isVhostDeleter ? "red" : "#ccc"} />}
                    />
                </PopConfirmZis>
            ),
        }
    ]

    if (!appSetting || Object.keys(appSetting).length === 0) {
        return (<Spin />)
    }

    if (!vhost || !vhost.id || !service) {
        return (<Spin />)
    }

    return (
        <>
            <Card
                title={<><RadarChartOutlined /> &nbsp; {t('webPage.vhosts.title')} &nbsp; {vhost.name}</>}
                className='VhostDetailPage'
                loading={isLoadingVhost}
            >

                <Row style={{ marginTop: '35px' }}>
                    <Col span={6} className='right bold pad4'>{t('webPage.vhosts.fqdn')}:</Col>
                    <Col span={6} className='pad4'><b>{vhost.name}</b>&nbsp;&nbsp;
                        <a href={`https://${vhost.name}`} className="link" target='_blank'>🔗web</a></Col>
                    <Col span={12}>
                        <div style={{ textAlign: 'center' }}>
                            {
                                vhost.is_config_applied === 0 &&
                                <Alert type="error" style={{ padding: '2px', width: '50%' }} message={t('webPage.vhosts.changes')} />
                            }
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col span={6} className='right bold pad4' style={{ fontSize: '0.8em' }}>id:</Col>
                    <Col span={6} className='pad4' style={{ fontSize: '0.8em' }}>{vhost.id}</Col>
                    <Col span={12}>&nbsp;</Col>
                </Row>
                <Row>
                    <Col span={6} className='right bold pad4'>{t('webPage.vhosts.web_service')}:</Col>
                    <Col span={6} className='pad4'>
                        {
                            isWebServiceEditor && <Link to={`/web/services/${service?.id}`}>{service?.host}</Link>
                        }
                        {
                            !isWebServiceEditor && <span>{service?.host}</span>
                        }
                    </Col>
                    <Col span={12}>&nbsp;</Col>
                </Row>

                <Row>
                    <Col span={6} className='right bold pad4'>{t('webPage.vhosts.customer')}:</Col>
                    <Col span={6} className='pad4'>{vhost.customer?.name}</Col>
                    <Col span={12}>&nbsp;</Col>
                </Row>

                <Row>
                    <Col span={6} className='right bold pad4'>{t('general.created')}:</Col>
                    <Col span={6} className='pad4'>{appSetting.renderDate(vhost.created_at)}</Col>
                    <Col span={12}>&nbsp;</Col>
                </Row>


                {!connected && (
                    <Row>
                        <Col span={24} className='pad4'>
                            <Alert className="center" message="The web server is not ready. Please check the web service status." type="error" />
                        </Col>
                    </Row>
                )}

                <Divider />

                <Form
                    onFinish={onFormSubmit}
                    form={form}
                    onChange={() => setUpdateStatus(true)}
                    initialValues={{
                        ...vhost,
                        custom_config: vhost.custom_config,
                    }}
                    {...formItemLayout}
                >
                    <Row>
                        <Col span={6} className='right bold pad4'>{t('webPage.vhosts.template')}:</Col>
                        <Col span={12} className='pad4'>
                            {
                                connected && (
                                    <Form.Item name="template_id">
                                        <Select
                                            showSearch
                                            optionFilterProp='label'
                                            options={serviceTemplateOptions}
                                            placeholder={t('webPage.vhosts.template')}
                                            style={{ width: '100%' }}
                                            onChange={(value) => {
                                                form.setFieldsValue({ template_id: value })
                                                setUpdateStatus(true)
                                            }}
                                        >
                                        </Select>
                                    </Form.Item>
                                )
                            }
                            {
                                !connected && (
                                    <Select
                                        disabled={true}
                                        optionFilterProp='label'
                                        options={serviceTemplateOptions}
                                        placeholder={t('webPage.vhosts.template')}
                                        value={vhost.template_id}
                                        style={{ width: '100%' }}
                                    >
                                    </Select>
                                )
                            }


                        </Col>
                        <Col span={6}>&nbsp;</Col>
                    </Row>

                    <Divider />

                    <Row>
                        <Col span={6} className='right bold pad8'>{t('webPage.vhosts.aliases')}:</Col>
                        <Col span={12} className='pad4'>
                            <Table<AliasModel>
                                columns={columns}
                                dataSource={aliasSource}
                                scroll={{ x: 'max-content' }}
                                rowKey='id'
                                bordered={true}
                                className='AliasesTable'
                                showHeader={true}
                                size='small'
                                pagination={false}
                            />
                        </Col>
                        <Col span={6}>&nbsp;
                            <Button type='primary' size="small"
                                disabled={!isVhostEditor}
                                onClick={() => { setAliasModalVisible(true) }}>
                                {t('general.add') + ' Alias'}
                            </Button>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={6} className='right bold pad4'>{t('webPage.vhosts.alias_redirect')}:</Col>
                        <Col span={6} className='pad4'>
                            <Form.Item name="redirect_target">
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={12}>&nbsp;</Col>
                    </Row>

                    <Divider />

                    {
                        vhost.domain && (
                            <>
                                <Row>
                                    <Col span={6} className='right bold pad4'>{t('webPage.vhosts.domain')}:</Col>
                                    <Col span={6} className='pad4'>
                                        <Link to={`/dns/domains?name=${vhost.domain?.name}&pagination[pageSize]=10&pagination[page]=1`}>{vhost.domain?.name}</Link>

                                    </Col>
                                    <Col span={12}>&nbsp;</Col>
                                </Row>
                                <Row>
                                    <Col span={6} className='right bold pad4'>{t('webPage.vhosts.database')}:</Col>
                                    <Col span={6} className='pad4'>
                                        {
                                            fqdn_domain && fqdn_domain.services && fqdn_domain.services.length > 0 && (
                                                <div>
                                                    {
                                                        fqdn_domain.services.filter(s => s.type_name.toLowerCase() === "rdb").map(s => (
                                                            <Tag color="success" key={s.id}>
                                                                <Link to={`/rdb/database/${s.id}`} key={s.id}>{s.name}</Link>
                                                            </Tag>
                                                        ))
                                                    }
                                                </div>
                                            )
                                        }
                                    </Col>
                                    <Col span={12}>&nbsp;</Col>
                                </Row>
                                <Divider />
                            </>
                        )
                    }


                    <Row>
                        <Col span={6} className='right bold pad8'>Protocol HTTP:</Col>
                        <Col span={6} className='pad4'>
                            <Switch
                                checkedChildren={<CheckOutlined />}
                                unCheckedChildren={<CloseOutlined />}
                                checked={isHttp}
                                defaultChecked={isHttp}
                                size="small"
                                onChange={() => {
                                    if (isHttp && !isHttps) {
                                        // no posiible
                                    }
                                    else {
                                        setHttp(!isHttp)
                                        setUpdateStatus(true)
                                    }
                                }}
                            />
                        </Col>
                        <Col span={12}>&nbsp;</Col>
                    </Row>

                    <Row>
                        <Col span={6} className='right bold pad8'>Protocol HTTPS:</Col>
                        <Col span={18} className='pad4 left'>
                            <Switch
                                checkedChildren={<CheckOutlined />}
                                unCheckedChildren={<CloseOutlined />}
                                checked={isHttps}
                                size="small"
                                onChange={() => {
                                    if (!isHttp && isHttps) {
                                        // no posiible
                                    }
                                    else {
                                        setHttps(!isHttps)
                                        setUpdateStatus(true)
                                    }
                                }}
                            />
                        </Col>
                    </Row>
                    {
                        certificate && (
                            <Row>
                                <Col span={6} className='pad4 bold right'>{t('webPage.vhosts.certificate')}:</Col>
                                <Col span={18} className='pad4 left'>

                                    <Form.Item name="certificate_id">
                                        <Select
                                            showSearch
                                            optionFilterProp='label'
                                            options={certificateOptions}
                                            placeholder='No certificate'
                                            value={vhost.certificate_id}
                                            style={{ width: '200px', float: 'left' }}
                                            onClear={() => {
                                                form.setFieldsValue({ certificate_id: undefined })
                                                setUpdateStatus(true)
                                            }}
                                            onChange={(value) => {
                                                form.setFieldsValue({ certificate_id: value })
                                                setUpdateStatus(true)
                                                value && dispatch(getCertificate(value))
                                            }}
                                        >
                                        </Select>
                                    </Form.Item>


                                    <Row style={{ border: '1px solid #eee', width: '60%', padding: '10px', backgroundColor: '#fafafa' }}>
                                        <Col span={4} className='right'>{t('webPage.vhosts.domain')}:&nbsp;</Col>
                                        <Col span={20}><b>{certificate.name}</b></Col>
                                        <Col span={4} className='right'>{t('webPage.vhosts.authority')}:&nbsp;</Col>
                                        <Col span={20}>{certificate.authority}</Col>
                                        <Col span={4} className='right'>{t('webPage.vhosts.valid')}:&nbsp;</Col>
                                        <Col span={20}>{appSetting.renderDate(certificate.valid_from)} - {appSetting.renderDate(certificate.valid_to)}</Col>
                                        <Col span={4} className='right'>{t('webPage.vhosts.subjects')}:&nbsp;</Col>
                                        <Col span={20}>
                                            {
                                                certificate.subjects &&
                                                certificate.subjects.split(',').map(s => (
                                                    <Tag color="success" key={s}>{s}</Tag>
                                                ))
                                            }
                                        </Col>
                                        <Col span={8} className='right'>Remove Ceertificate&nbsp;</Col>
                                        <Col span={16}>
                                            <Switch
                                                checkedChildren={<CheckOutlined />}
                                                unCheckedChildren={<CloseOutlined />}
                                                checked={removeCert}
                                                defaultChecked={removeCert}
                                                size="small"
                                                disabled={!isVhostEditor || (certificate && isHttps)}
                                                onChange={() => {
                                                    setRemoveCert(!removeCert)
                                                    setUpdateStatus(true)
                                                }}
                                            />
                                        </Col>
                                    </Row>

                                </Col>
                            </Row>

                        )
                    }
                    {
                        !certificate && (
                            <Row>
                                <Col span={6} className='pad4 bold right'>{t('webPage.vhosts.certificate')}:</Col>
                                <Col span={18} className='pad4 left'>

                                    <Form.Item name="certificate_id">
                                        <Select
                                            showSearch
                                            optionFilterProp='label'
                                            options={certificateOptions}
                                            disabled={!isHttps}
                                            placeholder='No certificate'
                                            value={vhost.certificate_id}
                                            style={{ width: '200px', float: 'left' }}
                                            onChange={(value) => {
                                                form.setFieldsValue({ certificate_id: value })
                                                setUpdateStatus(true)
                                            }}
                                        >
                                        </Select>
                                    </Form.Item>

                                    <Button type='primary' size="small"
                                        style={{ marginLeft: '25px' }}
                                        disabled={!isVhostEditor}
                                        onClick={() => {
                                            setUpdateStatus(true)
                                            setCertModalVisible(true)
                                        }}>
                                        {t('webPage.vhosts.generate_certificate')}
                                    </Button>

                                </Col>
                            </Row>
                        )
                    }

                    <Row style={{ marginTop: '25px' }}>
                        <Col span={12}>
                            <Row>
                                <Col span={12} className='right bold pad8'>ForceRedirectHttps:</Col>
                                <Col span={12} className='pad4'>
                                    <Switch
                                        checkedChildren={<CheckOutlined />}
                                        unCheckedChildren={<CloseOutlined />}
                                        disabled={!isHttps}
                                        checked={isForceRedirectHTTPS}
                                        size="small"
                                        onChange={() => {
                                            setForceRedirectHTTPS(!isForceRedirectHTTPS)
                                            setUpdateStatus(true)
                                        }}
                                    />
                                </Col>
                            </Row>

                            <Row>
                                <Col span={12} className='right bold pad8'>ForceRedirectWWW:</Col>
                                <Col span={12} className='pad4'>
                                    <Switch
                                        checkedChildren={<CheckOutlined />}
                                        unCheckedChildren={<CloseOutlined />}
                                        checked={isForceRedirectWWW}
                                        size="small"
                                        onChange={() => {
                                            setForceRedirectWWW(!isForceRedirectWWW)
                                            setUpdateStatus(true)
                                        }}
                                    />
                                </Col>
                            </Row>

                            <Row>
                                <Col span={12} className='right bold pad8'>AFW:</Col>
                                <Col span={12} className='pad4'>
                                    <Switch
                                        checkedChildren={<CheckOutlined />}
                                        unCheckedChildren={<CloseOutlined />}
                                        checked={isAfw}
                                        disabled={!service.has_afw}
                                        size="small"
                                        onChange={() => {
                                            setAfw(!isAfw)
                                            setUpdateStatus(true)
                                        }}
                                    />
                                </Col>
                            </Row>

                            <Row>
                                <Col span={12} className='right bold pad8'>Active:</Col>
                                <Col span={12} className='pad4'>
                                    <Switch
                                        checkedChildren={<CheckOutlined />}
                                        unCheckedChildren={<CloseOutlined />}
                                        checked={isActive}
                                        disabled={!isHttps && !isHttp}
                                        size="small"
                                        onChange={() => {
                                            setActive(!isActive)
                                            setUpdateStatus(true)
                                        }}
                                    />
                                </Col>
                            </Row>
                        </Col>
                        <Col span={12}>
                            <b>{t('webPage.vhosts.manual_config')}:</b>
                            <Form.Item name='custom_config' labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
                                <TextArea rows={6}
                                    style={{ fontFamily: 'monospace', overflow: 'scroll', minWidth: '240px', maxWidth: '90%', resize: 'both' }}
                                    data-gramm="false"
                                    data-gramm_editor="false"
                                    data-enable-grammarly="false" />
                            </Form.Item>
                        </Col>
                    </Row>


                    <Divider />

                    <Row style={{ marginBottom: '15px' }}>
                        <Col span={6} className='pad4'>
                            <Button danger
                                disabled={!isVhostDeleter || vhost.is_active === 1}
                                onClick={() => {
                                    showConfirmDelete(vhost, t('webPage.vhosts.confirm_delete_vhost'))
                                }}
                                icon={<DeleteTwoTone twoToneColor='red' />}>{t('webPage.vhosts.btn_delete_vhost')}</Button>
                        </Col>
                        <Col span={6} className='pad4'>
                            <Button type='primary' htmlType="submit"
                                disabled={!updateStatus || !isVhostEditor}>
                                {t('general.save')}
                            </Button>
                        </Col>
                        <Col span={6} style={{ textAlign: 'center' }}>

                            <Button type='primary' className={vhost.is_config_applied === 1 ? '' : 'success'}
                                disabled={vhost.is_config_applied === 1}
                                loading={isApplyingChangesVhost}
                                onClick={() => {
                                    dispatch(vhost.id && applyChanges(vhost.id, suc => {
                                        if (suc) {
                                            message.success(t('webPage.vhosts.apply_changes_success'))
                                        }
                                        else {
                                            message.error(t('webPage.vhosts.apply_changes_error'))
                                        }
                                    }))
                                }}>
                                {t('webPage.vhosts.apply_changes')}
                            </Button>
                            <div style={{ textAlign: 'center' }}>
                                {
                                    vhost.is_config_applied === 0 &&
                                    <span style={{ color: 'red', fontSize: '0.8em' }}>{t('webPage.vhosts.changes')}</span>
                                }
                            </div>
                        </Col>
                        <Col span={6}>&nbsp;
                            <Button className="warning" onClick={() => { setPreviewModalVisible(true) }}>
                                {t('webPage.vhosts.config_view')}
                            </Button>
                        </Col>
                    </Row>

                </Form>

                <Row>
                    <Col span={24} className='pad4 center'>&nbsp;
                        {error && <Tag color="red">{error}</Tag>}
                    </Col>
                </Row>
            </Card>

            <Modal title={
                <div style={{ width: '100%', cursor: 'move' }}
                    onMouseOver={() => { if (disabled) { setDisabled(false) } }}
                    onMouseOut={() => { setDisabled(true) }}
                    onFocus={() => { }}
                    onBlur={() => { }}
                >
                    <RadarChartOutlined /> &nbsp; {t('webPage.vhosts.new_alias')}
                </div>
            }
                destroyOnClose
                style={{ top: 20 }}
                width={400}
                visible={isAliasModalVisible}
                onCancel={() => setAliasModalVisible(false)}
                modalRender={(modal) => (
                    <Draggable disabled={disabled} bounds={bounds} onStart={(ev, data) => onStart(ev, data)}>
                        <div ref={draggleRef}>{modal}</div>
                    </Draggable>
                )}
                footer={null}
                confirmLoading={true}
            >
                <NewAliasForm onClose={() => { setAliasModalVisible(false) }} />
            </Modal>


            <Modal title={
                <div style={{ width: '100%', cursor: 'move' }}
                    onMouseOver={() => { if (disabled) { setDisabled(false) } }}
                    onMouseOut={() => { setDisabled(true) }}
                    onFocus={() => { }}
                    onBlur={() => { }}
                >
                    <RadarChartOutlined /> &nbsp; {t('webPage.vhosts.config_view')}
                </div>
            }
                destroyOnClose
                style={{ top: 20 }}
                width='80%'
                visible={isPreviewModalVisible}
                onCancel={() => setPreviewModalVisible(false)}
                modalRender={(modal) => (
                    <Draggable disabled={disabled} bounds={bounds} onStart={(ev, data) => onStart(ev, data)}>
                        <div ref={draggleRef}>{modal}</div>
                    </Draggable>
                )}
                footer={null}
                confirmLoading={true}
            >
                <PreviewConfigForm onClose={() => { setPreviewModalVisible(false) }} />
            </Modal>



            <Modal title={
                <div style={{ width: '100%', cursor: 'move' }}
                    onMouseOver={() => { if (disabled) { setDisabled(false) } }}
                    onMouseOut={() => { setDisabled(true) }}
                    onFocus={() => { }}
                    onBlur={() => { }}
                >
                    <RadarChartOutlined /> &nbsp; {t('certificatesPage.generate_ssl')}
                </div>
            }
                destroyOnClose
                style={{ top: 20 }}
                width={400}
                visible={isCertModalVisible}
                onCancel={() => setCertModalVisible(false)}
                modalRender={(modal) => (
                    <Draggable disabled={disabled} bounds={bounds} onStart={(ev, data) => onStart(ev, data)}>
                        <div ref={draggleRef}>{modal}</div>
                    </Draggable>
                )}
                footer={null}
                confirmLoading={true}
            >
                <GenCertificateForm closeModal={() => { setCertModalVisible(false) }} />
            </Modal>
        </>
    )
}

export default VhostDetailPage
