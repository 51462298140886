import React, { useEffect, useState, useCallback } from 'react'
import { Layout, Spin, Alert, Typography, Divider, Col, Row, Button, Input, Space, message } from 'antd'
import { CaretRightOutlined, SearchOutlined, UnorderedListOutlined } from '@ant-design/icons'
import './helpWindow.scss'
import { ContentItem, SectionContent } from 'pages/login/models'
import { TocItem } from 'pages/login/models'
import { SectionItem } from 'pages/login/models'
import { useDispatch, useSelector } from 'react-redux'
import { AppState } from 'common/models'
import getDocsToc from 'pages/login/actions/getDocsToc'
import getDocsSections from 'pages/login/actions/getDocsSections'
import getDocsSectionContent from 'pages/login/actions/getDocsSectionContent'
import getDocsSearch from 'pages/login/actions/getDocsSearch'


const SEARCH_SECTION = 999

const { Content } = Layout
const { Title } = Typography

const HelpWindow: React.FC = () => {
  const dispatch = useDispatch()

  const { docs_toc, docs_sections, docs_section_content } = useSelector((state: AppState) => state.auth)

  const [sections, setSections] = useState<SectionItem[]>([])
  const [sectionContents, setSectionContents] = useState<Record<string, SectionContent>>({})
  const [tocItems, setTocItems] = useState<TocItem[]>([])
  const [loading, setLoading] = useState<boolean>(false)
  const [error, setError] = useState<string | null>(null)
  const [mainSection, setMainSection] = useState<number>(0) // Default to section 8 for IP addresses
  const [mainSectionTitle, setMainSectionTitle] = useState<string>("")
  const [expandedSections, setExpandedSections] = useState<string[]>([]) // Track expanded sections
  const [searchQuery, setSearchQuery] = useState<string>("")

  useEffect(() => {
    setLoading(true)
    if (!docs_toc) {
      dispatch(getDocsToc())
    }

    const url = location.pathname.toLowerCase()
    console.log(`Navigating to url: ${url}`)
    if (url.includes('/users')) {
      setMainSection(5)
      return
    }
    if (url.includes('/rights/')) {
      setMainSection(3)
      return
    }
    if (url.includes('/billing/')) {
      setMainSection(6)
      return
    }
    if (url.includes('/inventory/')) {
      setMainSection(7)
      return
    }
    if (url.includes('/ip/')) {
      setMainSection(8)
      return
    }
    if (url.includes('/dns/domains/')) {
      setMainSection(10)
      return
    }
    if (url.includes('/dns/')) {
      setMainSection(9)
      return
    }
    if (url.includes('/servers')) {
      setMainSection(11)
      return
    }
    if (url.includes('/certificates')) {
      setMainSection(12)
      return
    }
    if (url.includes('/rdb/')) {
      setMainSection(13)
      return
    }
    if (url.includes('/web/')) {
      setMainSection(14)
      return
    }
    if (url.includes('/mail/')) {
      setMainSection(15)
      return
    }
    setMainSection(0)
  }, [])

  useEffect(() => {
    if (docs_toc) {
      setTocItems(docs_toc)
      mainSection === 0 && setMainSectionTitle("Obsah")
      setSections([])
      setSectionContents({})
      setLoading(false)
    }
  }, [docs_toc])

  useEffect(() => {
    if (mainSection) {
      setLoading(true)
      if (mainSection != SEARCH_SECTION) {
        dispatch(getDocsSections(mainSection.toString()))
      }
      if (tocItems.length > 0) {
        const tocItem = tocItems.find(item => item.subsection === mainSection)
        if (tocItem) {
          navigateToSection(tocItem.section, tocItem.subsection, tocItem.header)
        }
      }
    }
  }, [mainSection, tocItems])

  useEffect(() => {
    if (docs_sections) {
      setSections(docs_sections)
    }
    setLoading(false)
  }, [docs_sections])

  useEffect(() => {
    if (docs_section_content) {
      setSectionContents(docs_section_content)
    }
    setLoading(false)
  }, [docs_section_content])

  // Navigate to a specific main section
  const navigateToSection = (section: number, subsection: number, header: string) => {
    console.log(`Navigating to section: ${section}.${subsection}.${header}`)
    if (section === undefined || subsection === undefined) {
      console.error(`Invalid section/subsection: ${section}.${subsection}`)
      return
    }

    setMainSection(subsection)
    setMainSectionTitle(header || 'Unknown Section')
    // Clear expanded sections when navigating
    setExpandedSections([])
  }

  // Toggle section expansion
  const toggleSection = (sectionKey: string) => {
    if (!sectionKey) return // Don't toggle if key is invalid

    const isExpanded = expandedSections.includes(sectionKey)
    if (!isExpanded) {
      dispatch(getDocsSectionContent(sectionKey))
    }

    setExpandedSections(prev => {
      if (prev.includes(sectionKey)) {
        return prev.filter(key => key !== sectionKey)
      } else {
        return [...prev, sectionKey]
      }
    })
  }


  // Handle loading state
  if (loading) {
    return (
      <div className="loading-container">
        <Spin size="large" tip="Loading help content..." />
      </div>
    )
  }

  // Handle error state
  if (error) {
    return (
      <div className="error-container">
        <Alert
          message="Error"
          description={error}
          type="error"
          showIcon
        />
      </div>
    )
  }

  return (
    <Layout className="help-window-unified">
      <Content className="unified-content">
        <div className="help-header" style={{ marginBottom: '10px' }}>

          <Button
            type="primary" size='small'
            style={{ backgroundColor: '#888888', fontSize: '0.9em', border: 0 }}
            icon={<UnorderedListOutlined />}
            onClick={() => {
              setMainSection(0)
              setMainSectionTitle("Obsah")
              dispatch(getDocsToc())
            }}
          >
            TOC
          </Button>

          <Input
            placeholder="Search"
            value={searchQuery}
            style={{ width: '320px', marginLeft: '40px' }}
            allowClear
            onChange={(e) => setSearchQuery(e.target.value)}
            onPressEnter={() => {
              if (searchQuery.length > 4) {
                setLoading(true)
                setMainSection(SEARCH_SECTION)
                dispatch(getDocsSearch(searchQuery))
              }
            }}
          />
          <Button
            type="primary" size='small'
            style={{ backgroundColor: '#888888', fontSize: '1.0em', border: 0 }}
            onClick={() => {
              if (searchQuery.length > 4) {
                setLoading(true)
                setMainSection(SEARCH_SECTION)
                dispatch(getDocsSearch(searchQuery))
              }
              else {
                message.warning('Please enter a search query (minimum 5 characters)')
              }
            }}
            icon={<SearchOutlined />}
          >
            Search
          </Button>

        </div>

        {mainSection === SEARCH_SECTION && (
          <span style={{ fontSize: '0.9em', fontWeight: 'normal', marginTop: '10px' }}>
            Search results for: <span style={{ fontWeight: 'bold', backgroundColor: 'gold', fontSize: '1.2em', padding: '2px', borderRadius: '5px' }}>{searchQuery}</span>
          </span>
        )
        }
        {mainSection != SEARCH_SECTION && (
          <span style={{ fontSize: '1.2em', fontWeight: 'bold', marginTop: '10px' }}>
            {mainSection > 0 ? `${mainSection}. ${mainSectionTitle}` : mainSectionTitle}
          </span>
        )
        }

        <Divider style={{ margin: '10px 0 0 0' }} />

        {/* Display TOC if mainSection is 0 */}
        {mainSection === 0 && tocItems && tocItems.length > 0 && (
          <div className="main-toc-list">
            <ul className="content-list">
              {tocItems.map(item => (
                <li
                  key={`${item.section}.${item.subsection}`}
                  className="main-toc-item"
                  onClick={() => navigateToSection(item.section, item.subsection, item.header)}
                >
                  {item.subsection}. {item.header}
                </li>
              ))}
            </ul>
          </div>
        )}

        {/* Combined TOC and Content for regular sections */}
        {mainSection > 0 && sections && sections.length > 0 && (
          <div className="unified-toc-content">
            {sections.map((section) => {
              if (!section || section.section === undefined || section.subsection === undefined) {
                return null
              }

              const sectionKey = `${section.section}.${section.subsection}`
              const sectionContent = sectionContents[sectionKey]
              const isExpanded = expandedSections.includes(sectionKey)

              return (
                <div key={sectionKey} className="unified-section">
                  <div
                    className={`unified-section-header ${isExpanded ? 'expanded' : ''}`}
                    onClick={() => toggleSection(sectionKey)}
                    id={`section-${sectionKey}`}
                  >
                    <CaretRightOutlined className="expand-icon" />
                    <span>{section.section}.{section.subsection}. {section.header}</span>
                  </div>

                  {isExpanded && (
                    <>
                      {!sectionContent && <Spin size="small" />}
                      {sectionContent && <ContentSection section={sectionContent} />}
                    </>
                  )}
                </div>
              )
            })}
          </div>
        )}
      </Content>
    </Layout>
  )
}

interface ContentSectionProps {
  section: SectionContent
}

const ContentSection: React.FC<ContentSectionProps> = ({ section }) => {

  if (!section?.content?.length) {
    return <div>No content available</div>
  }

  return (
    <div className="unified-content-section">
      <ul className="content-list">
        {section.content.map(item => {
          const level = item.classname || `level-0`
          return (
            <li key={item.order} className={level}>
              {item.desc}
            </li>
          )
        })}
      </ul>
    </div>
  )
}

export default HelpWindow
