import { GetDomainsMailRequestAction, GetDomainsMailResponseAction, GetDomainsMailErrorAction } from '.'
import { protectedApiClient } from 'helpers/api'
import { handleApiErrorWithNotification } from 'helpers/errorHandling'
import { DomainModel } from 'pages/dns/service/models'

export default (prefix: string, cb?: (isSuccess: boolean) => void) => {
	return (
		dispatch: (arg: GetDomainsMailRequestAction | GetDomainsMailResponseAction | GetDomainsMailErrorAction) => void
	) => {
		const request = new GetDomainsMailRequestAction()
		dispatch(request)

		let url = `/domain`
		if (prefix) {
			url = `/domain?name=${prefix}`
		}
		protectedApiClient
			.get<DomainModel[]>(url)
			.then((response) => {
				dispatch(new GetDomainsMailResponseAction(request, response.data))
				cb && cb(true)
			})
			.catch((error) => {
				dispatch(new GetDomainsMailErrorAction(request, error))
				handleApiErrorWithNotification(error)
				cb && cb(false)
			})
	}
}
