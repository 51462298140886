import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {useForm} from "antd/lib/form/Form";
import React, {useEffect, useState} from "react";
import {Button, Col, Form, Input, message, Row, Select, Spin} from "antd";
import {Store} from "antd/lib/form/interface";
import TextArea from "antd/lib/input/TextArea";
import {AppState} from "../../common/models";
import {useLoggedUser} from "../../helpers/loginUserHelper";
import addIpToServer from "./actions/addIpToServer";
import {ServerAddRemoveIpParams} from "./models";
import IpSelector from "../ip/subnet/IpSelector";
import {tailLayout} from "../../helpers/layoutHelpers";
import listIp from "./actions/listIp";


interface Props {
    onClose: () => void
}

const AddIpForm = (props: Props) => {
    const {t} = useTranslation()
    const dispatch = useDispatch()
    const [form2] = useForm()

    const {server, ip_addresses, isLoadingIps, isSaving} = useSelector( ( state: AppState ) => state.logic_server)

    const [selectedIpId, setSelectedIpId] = useState<number | undefined>()

    // get settings and logged user from store
    const loggedUser = useLoggedUser()
    if (!loggedUser || !loggedUser.isLoaded()) {
        return (<Spin/>)
    }
    const appSetting = loggedUser.getAppSettings()

    const fetchIpLookup = (searchText: string) => {
        // call lookup for Ip_name
        if (appSetting.checkMinSearch(searchText)) {
            dispatch(listIp(searchText))
        }
    }

    const submitIP = (values: Store): void => {
        if (!server) {
            message.error('Server unknown.')
            return
        }
        if (!selectedIpId) {
            message.error('Select IP address.')
            return
        }
        const params: ServerAddRemoveIpParams = {
            server_id: server.id,
            ip_id: selectedIpId,
            comment: values.comment
        }
        dispatch(addIpToServer(params, suc => {
            suc && message.success(t('general.success'))
            props.onClose()
        }))
    }

    return (
        <>
            <Row justify='space-between' style={{marginTop: '25px'}}>
                <Col span={24} >

                    <Form name='add-ip'
                          labelCol={{ span: 6 }}
                          wrapperCol={{ span: 18 }}
                          layout="horizontal"
                          form={form2}
                          onFinish={submitIP}
                    >

                        <Form.Item name='ip_id' label={t('serversPage.ip')}>
                            <IpSelector
                                dataSource={ip_addresses}
                                isLoadingIps={isLoadingIps}
                                handleSelectedIp={(ip_id) => setSelectedIpId(ip_id)}
                                fetchData={fetchIpLookup}
                            />
                        </Form.Item>

                        <Form.Item name='comment' label={t('serversPage.comment')}>
                            <TextArea data-gramm="false"
                                      data-gramm_editor="false"
                                      data-enable-grammarly="false" />
                        </Form.Item>


                        <Form.Item {...tailLayout}>
                            <Button type='primary' htmlType='submit'
                                    disabled={!selectedIpId || !server}
                                    loading={isSaving}
                                    className='create-button'>
                                {t('serversPage.add_ip')}
                            </Button>
                        </Form.Item>
                    </Form>

                </Col>
            </Row>
        </>
    )

}

export default AddIpForm
