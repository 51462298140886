import {Form, message, Spin} from "antd";
import {useForm} from "antd/lib/form/Form";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {useLoggedUser} from "../../helpers/loginUserHelper";
import React, {useEffect, useState} from "react";
import tableCustomers from "../login/actions/tableCustomers";
import {formItemLayout, tailLayout} from "../../helpers/layoutHelpers";
import {AppState} from "../../common/models";
import {Store} from "antd/lib/form/interface";
import ServerSelector from "../server/ServerSelector";
import Button from "antd-button-color";
import TextArea from "antd/lib/input/TextArea";
import {useHistory} from "react-router";
import getServersWeb from "./actions/getServersWeb";
import {CreateWebServiceParams} from "./models";
import createWebService from "./actions/createWebService";



interface Props {
    onClose: (id?: number) => void
}

const {Item} = Form


const CreateWebServiceForm = (props: Props) => {
    const [form] = useForm()
    const {t} = useTranslation()
    const dispatch = useDispatch()
    const history = useHistory()

    const {customers} = useSelector((state: AppState) => state.auth.tables)
    const {servers, isLoadingServers, isSavingService} = useSelector((state: AppState) => state.webservice)

    const [updateStatus, setUpdateStatus] = useState<boolean>(false)
    const [selectedServerId, setSelectedServerId] = useState<number | undefined>()


    // get settings and logged user from store
    const loggedUser = useLoggedUser()
    if (!loggedUser || !loggedUser.isLoaded()) {
        return (<Spin/>)
    }
    const appSetting = loggedUser.getAppSettings()

    useEffect(() => {
        // once
        if(!customers || customers.length === 0) {
            dispatch(tableCustomers())
        }
    }, [])

    const loadServerLookup = (pattern: string) => {
        if (appSetting.checkMinSearch(pattern)) {
            dispatch(getServersWeb(pattern))
        }
    }

    const onFormSubmit = (values: Store) => {
        if (selectedServerId) {
            const params: CreateWebServiceParams = {
                server_id: selectedServerId,
                comment: values.comment
            }
            dispatch(createWebService(params, (suc, id) => {
                if (suc) {
                    message.success(t('general.success'))
                    props.onClose(id)
                }
            }))
        }
    }


    return (
        <Form
            onFinish={onFormSubmit}
            form={form}
            onChange={() => setUpdateStatus(true)}
            initialValues={{customer_id:1, is_active: true}}
            {...formItemLayout}
        >

            <Form.Item name='server_id' label={t('webPage.server')}>
                <ServerSelector
                    dataSource={servers}
                    isLoading={isLoadingServers}
                    handleSelectedServer={(sid) => {
                        setSelectedServerId(sid); form.setFieldsValue([{server_id: sid}])
                        setUpdateStatus(true)
                    }}
                    fetchData={loadServerLookup}
                />
            </Form.Item>

            <Item label={t('webPage.comment')} name='comment'>
                <TextArea rows={3}
                          data-gramm="false"
                          data-gramm_editor="false"
                          data-enable-grammarly="false"  />
            </Item>

            <Item {...tailLayout}>
                <Button type='primary'
                        loading={isSavingService}
                        disabled={!updateStatus}
                        htmlType='submit'>
                    {t('general.create')}
                </Button>
            </Item>
        </Form>
    )
}

export default CreateWebServiceForm
