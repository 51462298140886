import { IAppSetting, SettingsDetails } from "../pages/settings/models";
import moment from "moment";
import {
    _renderDate,
    _renderDateTime,
    _renderPercent,
    _renderPrice,
    SQL_DATE_FORMAT,
    _renderFullDateTime,
    _renderPhone
} from "./fce";

// this is default setting of the app
// used when API is not accessible (never)
const defaultSetting: IAppSetting = {
    'date_format': 'YYYY-MM-DD',
    'datetime_format': 'YYYY-MM-DD H:m',
    'fulldatetime_format': 'YYYY-MM-DD H:m:s',
    'debug': false,
    'price_rounding': 2,
    'grid_page_size': 10,
    'logviewer_limit': 1000,
    'remember_page_size': 0,
    'auto_logout_minutes': 30,
    'show_clock': 1,
    'min_search_length': 2,
    'auth_2fa_google': false,
    'auth_2fa_sms': false,
    'auth_2fa_required': true,
    'auth_2fa_expiration': 0,
    'dns_service_id': 'ns1.zcom.cz',
    'mail_service_id': '',
    'rdb_service_id': 'zisdev-website.zcom.cz',
    'web_service_id': 'zisdev-website.zcom.cz',
    'backup_service_id': '',
    'proxmox_service_id': ''
}

class AppSetting implements IAppSetting {
    public debug: boolean
    public date_format: string
    public date_picker_format: string
    public datetime_format: string
    public fulldatetime_format: string
    public price_rounding: number
    public grid_page_size: number
    public logviewer_limit: number
    public remember_page_size: number
    public auto_logout_minutes: number
    public show_clock: number
    public min_search_length: number
    public auth_2fa_google: boolean
    public auth_2fa_sms: boolean
    public auth_2fa_required: boolean
    public auth_2fa_expiration: number
    public dns_service_id: string
    public mail_service_id: string
    public rdb_service_id: string
    public web_service_id: string
    public backup_service_id: string
    public proxmox_service_id: string


    settings: SettingsDetails[]
    page_permissions: {}

    constructor(settings: SettingsDetails[], appPermissions) {
        if (!settings || settings.length === 0) {
            throw Error('AppSetting: settings are empty.')
        }

        this.settings = settings
        this.page_permissions = appPermissions

        // load values from 'settings'
        // if value is not set => use default
        this.debug = parseInt(this._getSettingValue('debug')) > 0
        this.date_format = this._getSettingValue('date_format')
        this.date_picker_format = 'd.M.yyyy'   // 'D.M.YYYY' react-date-picker is not using Moment formats!!
        this.datetime_format = this._getSettingValue('datetime_format')
        this.fulldatetime_format = this._getSettingValue('fulldatetime_format')
        this.price_rounding = parseInt(this._getSettingValue('price_rounding'))
        this.grid_page_size = parseInt(this._getSettingValue('grid_page_size'))
        this.logviewer_limit = parseInt(this._getSettingValue('logviewer_limit'))

        this.remember_page_size = parseInt(this._getSettingValue('remember_page_size'))
        this.auto_logout_minutes = parseInt(this._getSettingValue('auto_logout_minutes'))
        this.show_clock = parseInt(this._getSettingValue('show_clock'))
        this.min_search_length = parseInt(this._getSettingValue('min_search_length'))

        this.auth_2fa_google = parseInt(this._getSettingValue('2fa_authenticator_google')) > 0
        this.auth_2fa_sms = parseInt(this._getSettingValue('2fa_authenticator_sms')) > 0
        this.auth_2fa_required = parseInt(this._getSettingValue('2fa_required')) > 0
        this.auth_2fa_expiration = parseInt(this._getSettingValue('2fa_expiration'))

        this.dns_service_id = this._getSettingValue('dns_service_id')
        this.mail_service_id = this._getSettingValue('mail_service_id')
        this.rdb_service_id = this._getSettingValue('rdb_service_id')
        this.web_service_id= this._getSettingValue('web_service_id')
        this.backup_service_id = this._getSettingValue('backup_service_id')
        this.proxmox_service_id = this._getSettingValue('proxmox_service_id')
    }

    private _getSettingValue(name: string): string {
        // choose a correct value for a setting
        let item = this.settings.find((s) => s.name === name)
        if (!item) {
            // default
            return defaultSetting[name]
        }
        return item.value
    }

    public getSettingItem(name: string): SettingsDetails {
        const item = this.settings?.find((item) => item.name === name)
        if (item === undefined) {
            throw new TypeError(`useSettings: Settings: ${name} not found.`);
        }
        return item
    }

    public getPagePermissions(pageName: string): string[] {
        if (pageName in this.page_permissions) {
            return this.page_permissions[pageName]
        }
        return []
    }

    public log(pageName: string, msg: string, obj:any=null): void {
        if (this.debug) {
            if (obj) {
                console.log(pageName + ': ' + msg + ' > ' + JSON.stringify(obj))
            }
            else {
                console.log(pageName + ': ' + msg)
            }
        }
    }

    public checkMinSearch(val: string | undefined): boolean {
        if (!val) {
            return false
        }
        return val.length > this.min_search_length
    }

    public renderPercent(n: number | undefined | null): string {
        if (!n)
            return _renderPercent(0)
        return _renderPercent(n)
    }

    public renderPrice(n: number | undefined | null): string {
        // n * 100
        if (!n)
            return '0'
        return _renderPrice(n, this.price_rounding)
    }

    public parsePrice(n: number | string): number {
        // n * 100
        if (typeof n === 'number') {
            return Number((n * 100).toFixed(0))
        }
        let s = n.replace(',', '.')
        s = s.replace(/\s/g, '')
        n = parseFloat(s)
        return Number((n * 100).toFixed(0))
    }

    public readNumberFromForm(value: number | string | undefined): number {
        if (!value)
            return 0

        if (typeof value === 'number') {
            return value
        }
        // string
        let s = value.replace(',', '.')
        return parseFloat(s)
    }

    public parseCountDecPlaces(n: string, places: number): number {
        let s = n.replace(',', '.')
        if (!places || places === 0) {
            let x = parseInt(s)
            return Number((x * 1000).toFixed(0))
        }
        const coef: number = Math.pow(10, places)
        let v2: number = parseInt((parseFloat(s) * coef).toFixed(0))
        let sa: number = v2 / coef
        return Number((sa * 1000).toFixed(0))
    }
    public parseCount(n: number | string): number {
        // n * 1000
        if (typeof n === 'number') {
            return Number((n * 1000).toFixed(0))
        }
        let s = n.replace(',', '.')
        n = parseFloat(s)
        return Number((n * 1000).toFixed(0))
    }

    public renderCount(n: number | undefined | null): string {
        // n/1000
        if (!n)
            return '0'
        if (n === 1000)
            return '1'

        let x = Number((n / 1000).toFixed(0))
        if (x * 1000 === n)
            return x.toString()

        x = Number((n / 1000).toFixed(3))
        return x.toString().replace('.', ',')
    }

    public renderDate(dt: Date | undefined | null | string | number): string {
        if (!dt)
            return ''

        if (typeof dt === 'number') {
            return moment.unix(dt).format(this.date_format)
        }
        return _renderDate(dt, this.date_format)
    }

    public renderDateSQL(dt: Date | undefined | null | string): string {
        if (!dt)
            return ''
        return moment(dt).format(SQL_DATE_FORMAT)
    }

    public renderDateTime(dt: Date | undefined | null | string | number): string {
        if (!dt)
            return ''
        if (typeof dt === 'number') {
            return moment.unix(dt).format(this.datetime_format)
        }
        return _renderDateTime(dt, this.datetime_format)
    }

    public renderFullDateTime(dt: Date | undefined | null | string | number): string {
        if (!dt)
            return ''
        if (typeof dt === 'number') {
            return moment.unix(dt).format(this.fulldatetime_format)
        }
        return _renderFullDateTime(dt, this.fulldatetime_format)
    }


    public renderPhone(phone: string | undefined): string {
        return _renderPhone(phone)
    }

    public renderMonoDateTime(dt: Date | undefined | null | string | number): string {
        // returns mono scope format
        if (!dt)
            return ''

        const format = 'YYYY-MM-DD HH:mm:ss'
        if (typeof dt === 'number') {
            return moment.unix(dt).format(format)
        }
        return moment(dt).format(format)
    }

    public renderMonoDateTimeMS(dt: Date | undefined | null | string | number): string {
        // returns mono scope format
        if (!dt)
            return ''

        const format = 'YYYY-MM-DD HH:mm:ss.SSS'
        if (typeof dt === 'number') {
            return moment.unix(dt).format(format)
        }
        return moment(dt).format(format)
    }

    public renderHistoryDateTime(dt: Date | undefined | null | string | number): string {
        let s = this.renderMonoDateTime(dt)
        s = s.replace(/ /g, '\u00a0')
        return s
    }

    public renderLoggerDateTime(dt: Date | undefined | null | string | number): string {
        let s = this.renderMonoDateTime(dt)
        s = s.replace('-', '')
        s = s.replace('-', '')
        s = s.replace(/ /g, '\u00a0')
        return s
    }

    public renderLoggerDateTimeMS(dt: Date | undefined | null | string | number): string {
        let s = this.renderMonoDateTimeMS(dt)
        s = s.replace('-', '')
        s = s.replace('-', '')
        s = s.replace(/ /g, '\u00a0')
        return s
    }
}

export default AppSetting