import {AsyncActionMode, NameItem} from 'common/models'
import {
	DbServiceModel,
	DbServiceError,
	UpdateDbServiceParams,
	CreateDbServiceParams,
	DatabaseModel,
	CreateDatabaseParams,
	UpdateDatabaseParams,
	DbUserModel,
	CreateDbUserParams,
	UpdateDbUserParams,
	DbServiceGrid,
	DatabaseGrid, DbVersionResp, DaemonVersionResp, PasswordResp, UpdateDbPasswordParams
} from '../models'
import {ServerModel} from "../../server/models";

export enum ActionType {
	CREATE_DB_SERVICE = 'CREATE_DB_SERVICE',
	GET_DB_SERVICES = 'GET_DB_SERVICES',
	GET_DB_SERVICE = 'GET_DB_SERVICE',
	GET_DB_SERVICE_DEFAULT = 'GET_DB_SERVICE_DEFAULT',
	UPDATE_DB_SERVICE = 'UPDATE_DB_SERVICE',
	DELETE_DB_SERVICE = 'DELETE_DB_SERVICE',

	CREATE_DATABASE = 'CREATE_DATABASE',
	GET_DATABASES = 'GET_DATABASES',
	GET_DATABASE = 'GET_DATABASE',
	UPDATE_DATABASE = 'UPDATE_DATABASE',
	DELETE_DATABASE = 'DELETE_DATABASE',

	CREATE_DB_USER = 'CREATE_DB_USER',
	GET_DB_USERS = 'GET_DB_USERS',
	GET_DB_USER = 'GET_DB_USER',
	UPDATE_DB_USER = 'UPDATE_DB_USER',
	DELETE_DB_USER = 'DELETE_DB_USER',

	GET_DRIVERS = 'GET_DRIVERS',
	LOOKUP_DATABASE = 'LOOKUP_DATABASE',
	LOOKUP_RDB_SERVICE = 'LOOKUP_RDB_SERVICE',
	GET_DAEMON = 'GET_DAEMON',
	GET_DB_VERSION = 'GET_DB_VERSION',
	GET_SERVERS_RDB = 'GET_SERVERS_RDB',
	GET_DB_PASSWORD = 'GET_DB_PASSWORD',
	UPDATE_DB_PASSWORD = 'UPDATE_DB_PASSWORD',
	GET_SERVICE_DATABASES = 'GET_SERVICE_DATABASES'
}

export type DbServiceActions =
	| CreateDbServiceRequestAction
	| CreateDbServiceResponseAction
	| CreateDbServiceErrorAction
	| GetDbServicesRequestAction
	| GetDbServicesResponseAction
	| GetDbServicesErrorAction
	| GetDbServiceRequestAction
	| GetDbServiceResponseAction
	| GetDbServiceErrorAction

	| GetRdbServiceByNameRequestAction
	| GetRdbServiceByNameResponseAction
	| GetRdbServiceByNameErrorAction

	| UpdateDbServiceRequestAction
	| UpdateDbServiceResponseAction
	| UpdateDbServiceErrorAction
	| DeleteDbServiceRequestAction
	| DeleteDbServiceResponseAction
	| DeleteDbServiceErrorAction

	| CreateDatabaseRequestAction
	| CreateDatabaseResponseAction
	| CreateDatabaseErrorAction
	| GetDatabasesRequestAction
	| GetDatabasesResponseAction
	| GetDatabasesErrorAction
	| GetServiceDatabasesRequestAction
	| GetServiceDatabasesResponseAction
	| GetServiceDatabasesErrorAction
	| GetDatabaseRequestAction
	| GetDatabaseResponseAction
	| GetDatabaseErrorAction
	| UpdateDatabaseRequestAction
	| UpdateDatabaseResponseAction
	| UpdateDatabaseErrorAction
	| DeleteDatabaseRequestAction
	| DeleteDatabaseResponseAction
	| DeleteDatabaseErrorAction

	| CreateDbUserRequestAction
	| CreateDbUserResponseAction
	| CreateDbUserErrorAction
	| GetDbUsersRequestAction
	| GetDbUsersResponseAction
	| GetDbUsersErrorAction
	| GetDbUserRequestAction
	| GetDbUserResponseAction
	| GetDbUserErrorAction
	| UpdateDbUserRequestAction
	| UpdateDbUserResponseAction
	| UpdateDbUserErrorAction
	| DeleteDbUserRequestAction
	| DeleteDbUserResponseAction
	| DeleteDbUserErrorAction

	| GetDbDriversRequestAction
	| GetDbDriversResponseAction
	| GetDbDriversErrorAction
	| LookupDatabaseRequestAction
	| LookupDatabaseResponseAction
	| LookupDatabaseErrorAction
	| LookupRdbServiceRequestAction
	| LookupRdbServiceResponseAction
	| LookupRdbServiceErrorAction
	| GetDbVersionRequestAction
	| GetDbVersionResponseAction
	| GetDbVersionErrorAction
	| GetDaemonRequestAction
	| GetDaemonResponseAction
	| GetDaemonErrorAction
	| GetServersRdbRequestAction
	| GetServersRdbResponseAction
	| GetServersRdbErrorAction
	| GetDbPasswordRequestAction
	| GetDbPasswordResponseAction
	| GetDbPasswordErrorAction
	| UpdateDbPasswordRequestAction
	| UpdateDbPasswordResponseAction
	| UpdateDbPasswordErrorAction


export class CreateDbServiceRequestAction {
	readonly type = ActionType.CREATE_DB_SERVICE
	readonly mode = AsyncActionMode.REQUEST
	constructor(public payload: CreateDbServiceParams) {}
}
export class CreateDbServiceResponseAction {
	readonly type = ActionType.CREATE_DB_SERVICE
	readonly mode = AsyncActionMode.RESPONSE
	constructor(public request: CreateDbServiceRequestAction, public data: DbServiceModel) {}
}
export class CreateDbServiceErrorAction {
	readonly type = ActionType.CREATE_DB_SERVICE
	readonly mode = AsyncActionMode.ERROR
	constructor(public request: CreateDbServiceRequestAction, public error: string) {}
}

export class GetDbServiceRequestAction {
	readonly type = ActionType.GET_DB_SERVICE
	readonly mode = AsyncActionMode.REQUEST
	constructor(public payload: number) {
		''
	}
}
export class GetDbServiceResponseAction {
	readonly type = ActionType.GET_DB_SERVICE
	readonly mode = AsyncActionMode.RESPONSE
	constructor(public request: GetDbServiceRequestAction, public data: DbServiceModel) {}
}
export class GetDbServiceErrorAction {
	readonly type = ActionType.GET_DB_SERVICE
	readonly mode = AsyncActionMode.ERROR
	constructor(public request: GetDbServiceRequestAction, public error: string) {}
}

export class GetRdbServiceByNameRequestAction {
	readonly type = ActionType.GET_DB_SERVICE_DEFAULT
	readonly mode = AsyncActionMode.REQUEST
	constructor(public name: string) {
		''
	}
}
export class GetRdbServiceByNameResponseAction {
	readonly type = ActionType.GET_DB_SERVICE_DEFAULT
	readonly mode = AsyncActionMode.RESPONSE
	constructor(public request: GetRdbServiceByNameRequestAction, public data: DbServiceModel[]) {}
}
export class GetRdbServiceByNameErrorAction {
	readonly type = ActionType.GET_DB_SERVICE_DEFAULT
	readonly mode = AsyncActionMode.ERROR
	constructor(public request: GetRdbServiceByNameRequestAction, public error: string) {}
}

export class GetDbServicesRequestAction {
	readonly type = ActionType.GET_DB_SERVICES
	readonly mode = AsyncActionMode.REQUEST
	constructor() {
		''
	}
}
export class GetDbServicesResponseAction {
	readonly type = ActionType.GET_DB_SERVICES
	readonly mode = AsyncActionMode.RESPONSE
	constructor(public request: GetDbServicesRequestAction, public data: DbServiceGrid) {}
}
export class GetDbServicesErrorAction {
	readonly type = ActionType.GET_DB_SERVICES
	readonly mode = AsyncActionMode.ERROR
	constructor(public request: GetDbServicesRequestAction, public error: string) {}
}

export class UpdateDbServiceRequestAction {
	readonly type = ActionType.UPDATE_DB_SERVICE
	readonly mode = AsyncActionMode.REQUEST
	constructor(public id: UpdateDbServiceParams) {}
}
export class UpdateDbServiceResponseAction {
	readonly type = ActionType.UPDATE_DB_SERVICE
	readonly mode = AsyncActionMode.RESPONSE
	constructor(public request: UpdateDbServiceRequestAction, public data: DbServiceModel) {}
}
export class UpdateDbServiceErrorAction {
	readonly type = ActionType.UPDATE_DB_SERVICE
	readonly mode = AsyncActionMode.ERROR
	constructor(public request: UpdateDbServiceRequestAction, public error: string) {}
}

export class DeleteDbServiceRequestAction {
	readonly type = ActionType.DELETE_DB_SERVICE
	readonly mode = AsyncActionMode.REQUEST
	constructor(public id: number) {
		''
	}
}
export class DeleteDbServiceResponseAction {
	readonly type = ActionType.DELETE_DB_SERVICE
	readonly mode = AsyncActionMode.RESPONSE
	constructor(public request: DeleteDbServiceRequestAction, public data: DbServiceModel) {}
}
export class DeleteDbServiceErrorAction {
	readonly type = ActionType.DELETE_DB_SERVICE
	readonly mode = AsyncActionMode.ERROR
	constructor(public request: DeleteDbServiceRequestAction, public error: string) {}
}

export class CreateDatabaseRequestAction {
	readonly type = ActionType.CREATE_DATABASE
	readonly mode = AsyncActionMode.REQUEST
	constructor(public payload: CreateDatabaseParams) {}
}
export class CreateDatabaseResponseAction {
	readonly type = ActionType.CREATE_DATABASE
	readonly mode = AsyncActionMode.RESPONSE
	constructor(public request: CreateDatabaseRequestAction, public data: DatabaseModel) {}
}
export class CreateDatabaseErrorAction {
	readonly type = ActionType.CREATE_DATABASE
	readonly mode = AsyncActionMode.ERROR
	constructor(public request: CreateDatabaseRequestAction, public error: string) {}
}

export class GetDatabaseRequestAction {
	readonly type = ActionType.GET_DATABASE
	readonly mode = AsyncActionMode.REQUEST
	constructor(public payload: number) {
		''
	}
}
export class GetDatabaseResponseAction {
	readonly type = ActionType.GET_DATABASE
	readonly mode = AsyncActionMode.RESPONSE
	constructor(public request: GetDatabaseRequestAction, public data: DatabaseModel) {}
}
export class GetDatabaseErrorAction {
	readonly type = ActionType.GET_DATABASE
	readonly mode = AsyncActionMode.ERROR
	constructor(public request: GetDatabaseRequestAction, public error: string) {}
}

export class GetDatabasesRequestAction {
	readonly type = ActionType.GET_DATABASES
	readonly mode = AsyncActionMode.REQUEST
	constructor() {
		''
	}
}
export class GetDatabasesResponseAction {
	readonly type = ActionType.GET_DATABASES
	readonly mode = AsyncActionMode.RESPONSE
	constructor(public request: GetDatabasesRequestAction, public data: DatabaseGrid) {}
}
export class GetDatabasesErrorAction {
	readonly type = ActionType.GET_DATABASES
	readonly mode = AsyncActionMode.ERROR
	constructor(public request: GetDatabasesRequestAction, public error: string) {}
}

export class GetDbDriversRequestAction {
	readonly type = ActionType.GET_DRIVERS
	readonly mode = AsyncActionMode.REQUEST
	constructor() {
		''
	}
}
export class GetDbDriversResponseAction {
	readonly type = ActionType.GET_DRIVERS
	readonly mode = AsyncActionMode.RESPONSE
	constructor(public request: GetDbDriversRequestAction, public data: NameItem[]) {}
}
export class GetDbDriversErrorAction {
	readonly type = ActionType.GET_DRIVERS
	readonly mode = AsyncActionMode.ERROR
	constructor(public request: GetDbDriversRequestAction, public error: string) {}
}


export class UpdateDatabaseRequestAction {
	readonly type = ActionType.UPDATE_DATABASE
	readonly mode = AsyncActionMode.REQUEST
	constructor(public id: UpdateDatabaseParams) {}
}
export class UpdateDatabaseResponseAction {
	readonly type = ActionType.UPDATE_DATABASE
	readonly mode = AsyncActionMode.RESPONSE
	constructor(public request: UpdateDatabaseRequestAction, public data: DatabaseModel) {}
}
export class UpdateDatabaseErrorAction {
	readonly type = ActionType.UPDATE_DATABASE
	readonly mode = AsyncActionMode.ERROR
	constructor(public request: UpdateDatabaseRequestAction, public error: string) {}
}

export class DeleteDatabaseRequestAction {
	readonly type = ActionType.DELETE_DATABASE
	readonly mode = AsyncActionMode.REQUEST
	constructor(public id: number) {
		''
	}
}
export class DeleteDatabaseResponseAction {
	readonly type = ActionType.DELETE_DATABASE
	readonly mode = AsyncActionMode.RESPONSE
	constructor(public request: DeleteDatabaseRequestAction, public data: DatabaseModel) {}
}
export class DeleteDatabaseErrorAction {
	readonly type = ActionType.DELETE_DATABASE
	readonly mode = AsyncActionMode.ERROR
	constructor(public request: DeleteDatabaseRequestAction, public error: string) {}
}


export class CreateDbUserRequestAction {
	readonly type = ActionType.CREATE_DB_USER
	readonly mode = AsyncActionMode.REQUEST
	constructor(public payload: CreateDbUserParams) {}
}
export class CreateDbUserResponseAction {
	readonly type = ActionType.CREATE_DB_USER
	readonly mode = AsyncActionMode.RESPONSE
	constructor(public request: CreateDbUserRequestAction, public data: DbUserModel) {}
}
export class CreateDbUserErrorAction {
	readonly type = ActionType.CREATE_DB_USER
	readonly mode = AsyncActionMode.ERROR
	constructor(public request: CreateDbUserRequestAction, public error: string) {}
}

export class GetDbUserRequestAction {
	readonly type = ActionType.GET_DB_USER
	readonly mode = AsyncActionMode.REQUEST
	constructor(public payload: number) {
		''
	}
}
export class GetDbUserResponseAction {
	readonly type = ActionType.GET_DB_USER
	readonly mode = AsyncActionMode.RESPONSE
	constructor(public request: GetDbUserRequestAction, public data: DbUserModel) {}
}
export class GetDbUserErrorAction {
	readonly type = ActionType.GET_DB_USER
	readonly mode = AsyncActionMode.ERROR
	constructor(public request: GetDbUserRequestAction, public error: string) {}
}

export class GetDbUsersRequestAction {
	readonly type = ActionType.GET_DB_USERS
	readonly mode = AsyncActionMode.REQUEST
	constructor() {
		''
	}
}
export class GetDbUsersResponseAction {
	readonly type = ActionType.GET_DB_USERS
	readonly mode = AsyncActionMode.RESPONSE
	constructor(public request: GetDbUsersRequestAction, public data: DbUserModel[]) {}
}
export class GetDbUsersErrorAction {
	readonly type = ActionType.GET_DB_USERS
	readonly mode = AsyncActionMode.ERROR
	constructor(public request: GetDbUsersRequestAction, public error: string) {}
}

export class UpdateDbUserRequestAction {
	readonly type = ActionType.UPDATE_DB_USER
	readonly mode = AsyncActionMode.REQUEST
	constructor(public params: UpdateDbUserParams) {}
}
export class UpdateDbUserResponseAction {
	readonly type = ActionType.UPDATE_DB_USER
	readonly mode = AsyncActionMode.RESPONSE
	constructor(public request: UpdateDbUserRequestAction, public data: DbUserModel) {}
}
export class UpdateDbUserErrorAction {
	readonly type = ActionType.UPDATE_DB_USER
	readonly mode = AsyncActionMode.ERROR
	constructor(public request: UpdateDbUserRequestAction, public error: string) {}
}

export class UpdateDbPasswordRequestAction {
	readonly type = ActionType.UPDATE_DB_PASSWORD
	readonly mode = AsyncActionMode.REQUEST
	constructor(public params: UpdateDbPasswordParams) {}
}
export class UpdateDbPasswordResponseAction {
	readonly type = ActionType.UPDATE_DB_PASSWORD
	readonly mode = AsyncActionMode.RESPONSE
	constructor(public request: UpdateDbPasswordRequestAction, public data: PasswordResp) {}
}
export class UpdateDbPasswordErrorAction {
	readonly type = ActionType.UPDATE_DB_PASSWORD
	readonly mode = AsyncActionMode.ERROR
	constructor(public request: UpdateDbPasswordRequestAction, public error: string) {}
}

export class DeleteDbUserRequestAction {
	readonly type = ActionType.DELETE_DB_USER
	readonly mode = AsyncActionMode.REQUEST
	constructor(public id: number) {
		''
	}
}
export class DeleteDbUserResponseAction {
	readonly type = ActionType.DELETE_DB_USER
	readonly mode = AsyncActionMode.RESPONSE
	constructor(public request: DeleteDbUserRequestAction, public data: DbUserModel) {}
}
export class DeleteDbUserErrorAction {
	readonly type = ActionType.DELETE_DB_USER
	readonly mode = AsyncActionMode.ERROR
	constructor(public request: DeleteDbUserRequestAction, public error: string) {}
}

export class LookupDatabaseRequestAction {
	readonly type = ActionType.LOOKUP_DATABASE
	readonly mode = AsyncActionMode.REQUEST
	constructor(public field: string) {
		''
	}
}
export class LookupDatabaseResponseAction {
	readonly type = ActionType.LOOKUP_DATABASE
	readonly mode = AsyncActionMode.RESPONSE
	constructor(
		public request: LookupDatabaseRequestAction,
		public field: string,
		public data: string[],
	) {}
}
export class LookupDatabaseErrorAction {
	readonly type = ActionType.LOOKUP_DATABASE
	readonly mode = AsyncActionMode.ERROR
	constructor(public request: LookupDatabaseRequestAction, public error: string) {}
}


export class LookupRdbServiceRequestAction {
	readonly type = ActionType.LOOKUP_RDB_SERVICE
	readonly mode = AsyncActionMode.REQUEST
	constructor(public field: string) {
		''
	}
}
export class LookupRdbServiceResponseAction {
	readonly type = ActionType.LOOKUP_RDB_SERVICE
	readonly mode = AsyncActionMode.RESPONSE
	constructor(
		public request: LookupRdbServiceRequestAction,
		public field: string,
		public data: string[],
	) {}
}
export class LookupRdbServiceErrorAction {
	readonly type = ActionType.LOOKUP_RDB_SERVICE
	readonly mode = AsyncActionMode.ERROR
	constructor(public request: LookupRdbServiceRequestAction, public error: string) {}
}



export class GetDaemonRequestAction {
	readonly type = ActionType.GET_DAEMON
	readonly mode = AsyncActionMode.REQUEST
	constructor(public id: number) {
		''
	}
}
export class GetDaemonResponseAction {
	readonly type = ActionType.GET_DAEMON
	readonly mode = AsyncActionMode.RESPONSE
	constructor(public request: GetDaemonRequestAction, public data: DaemonVersionResp) {}
}
export class GetDaemonErrorAction {
	readonly type = ActionType.GET_DAEMON
	readonly mode = AsyncActionMode.ERROR
	constructor(public request: GetDaemonRequestAction, public error: string) {}
}
export class GetDbVersionRequestAction {
	readonly type = ActionType.GET_DB_VERSION
	readonly mode = AsyncActionMode.REQUEST
	constructor(public id: number) {
		''
	}
}
export class GetDbVersionResponseAction {
	readonly type = ActionType.GET_DB_VERSION
	readonly mode = AsyncActionMode.RESPONSE
	constructor(public request: GetDbVersionRequestAction, public data: DbVersionResp) {}
}
export class GetDbVersionErrorAction {
	readonly type = ActionType.GET_DB_VERSION
	readonly mode = AsyncActionMode.ERROR
	constructor(public request: GetDbVersionRequestAction, public error: string) {}
}

export class GetServersRdbRequestAction {
	readonly type = ActionType.GET_SERVERS_RDB
	readonly mode = AsyncActionMode.REQUEST
	constructor() {
		''
	}
}
export class GetServersRdbResponseAction {
	readonly type = ActionType.GET_SERVERS_RDB
	readonly mode = AsyncActionMode.RESPONSE
	constructor(public request: GetServersRdbRequestAction, public data: ServerModel[]) {}
}
export class GetServersRdbErrorAction {
	readonly type = ActionType.GET_SERVERS_RDB
	readonly mode = AsyncActionMode.ERROR
	constructor(public request: GetServersRdbRequestAction, public error: string) {}
}

export class GetDbPasswordRequestAction {
	readonly type = ActionType.GET_DB_PASSWORD
	readonly mode = AsyncActionMode.REQUEST
	constructor() {
		''
	}
}
export class GetDbPasswordResponseAction {
	readonly type = ActionType.GET_DB_PASSWORD
	readonly mode = AsyncActionMode.RESPONSE
	constructor(public request: GetDbPasswordRequestAction, public data: PasswordResp) {}
}
export class GetDbPasswordErrorAction {
	readonly type = ActionType.GET_DB_PASSWORD
	readonly mode = AsyncActionMode.ERROR
	constructor(public request: GetDbPasswordRequestAction, public error: string) {}
}

export class GetServiceDatabasesRequestAction {
	readonly type = ActionType.GET_SERVICE_DATABASES
	readonly mode = AsyncActionMode.REQUEST
	constructor() {
		''
	}
}
export class GetServiceDatabasesResponseAction {
	readonly type = ActionType.GET_SERVICE_DATABASES
	readonly mode = AsyncActionMode.RESPONSE
	constructor(public request: GetServiceDatabasesRequestAction, public data: DatabaseModel[]) {}
}
export class GetServiceDatabasesErrorAction {
	readonly type = ActionType.GET_SERVICE_DATABASES
	readonly mode = AsyncActionMode.ERROR
	constructor(public request: GetServiceDatabasesRequestAction, public error: string) {}
}
