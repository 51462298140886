import { AppState } from 'common/models'
import { combineReducers } from 'redux'
import authReducer from 'pages/login/reducer'
import themeReducer from 'components/ThemeSwitcher/reducer'
import fontReducer from 'components/FontSwitcher/reducer'
import userReducer from 'pages/user/reducer'
import appSettingReducer from 'pages/settings/reducer'
import companyReducer from 'pages/company/reducer'
import sidenavReducer from 'components/SideNav/reducer'
import customerReducer from 'pages/billing/customer/reducer'
import ipSubnetReducer from 'pages/ip/subnet/reducer'
import logReducer from 'pages/logviewer/reducer'
import roleReducer from 'pages/rights/roles/reducer'
import resourceReducer from 'pages/rights/resources/reducer'
import usergroupReducer from 'pages/rights/usergroups/reducer'
import userRightsReducer from 'pages/rights/users/reducer'
import countriesReducer from 'components/SelectCountries/reducer'
import taxReducer from 'pages/billing/tax/reducer'
import productReducer from 'pages/billing/product/reducer'
import pricelistReducer from 'pages/billing/pricelist/reducer'
import productPriceReducer from 'pages/billing/productprice/reducer'
import productPriceRangeReducer from 'pages/billing/productpricerange/reducer'
import customerServiceReducer from 'pages/billing/customerservice/reducer'
import invoiceReducer from 'pages/billing/invoice/reducer'
import invoiceDocumentReducer from 'pages/billing/invoicedocument/reducer'
import invoiceCostAllocationReducer from 'pages/billing/invoicecostallocation/reducer'
import costAllocationReducer from 'pages/billing/costallocation/reducer'
import billReducer from 'pages/billing/bill/reducer'
import currenciesReducer from 'components/SelectCurrencies/reducer'
import billattachmentReducer from 'components/BillVat/BillAttachment/reducer'
import inventoryReducer from 'pages/inventory/inventory/reducer'
import serverReducer from 'pages/server/reducer'
import inventoryLocationReducer from 'pages/inventory/location/reducer'
import inventoryTypeReducer from 'pages/inventory/type/reducer'
import inventoryPhysicalServerReducer from 'pages/inventory/physicalserver/reducer'
import datacenterReducer from 'pages/datacenter/datacenter/reducer'
import rackReducer from 'pages/datacenter/rack/reducer'
import blockReducer from 'pages/datacenter/block/reducer'
import historyReducer from 'components/History/reducer'
import dnsServiceReducer from 'pages/dns/service/reducer'
import rdbServiceReducer from 'pages/database/reducer'
import mailDomainsReducer from 'pages/mail/reducer'
import asyncJobReducer from 'pages/asyncjob/job/reducer'
import certificatesResducer from 'pages/certificate/reducer'
import vpsAdminReducer from 'pages/vpsadmin/reducer'
import webserviceReducer from 'pages/webservice/reducer'
import serviceTypeReducer from 'pages/billing/servicetype/reducer'
import mailReducer from 'pages/billing/mail/reducer'
import mailTemplateReducer from 'pages/billing/mailtemplate/reducer'
import countriesPageReducer from 'pages/billing/countries/reducer'

const appReducer = combineReducers<AppState>({
	auth: authReducer,
	theme: themeReducer,
	font: fontReducer,
	user: userReducer,
	appSettingPage: appSettingReducer,
	company: companyReducer,
	customer: customerReducer,
	sidenav: sidenavReducer,
	ipSubnet: ipSubnetReducer,
	logs: logReducer,
	rolesPage: roleReducer,
	resourcePage: resourceReducer,
	userRightsPage: userRightsReducer,
	groupsPage: usergroupReducer,
	countries: countriesReducer,
	tax: taxReducer,
	product: productReducer,
	pricelist: pricelistReducer,
	productprice: productPriceReducer,
	productpricerange: productPriceRangeReducer,
	customerservice: customerServiceReducer,
	invoice: invoiceReducer,
	invoicedocument: invoiceDocumentReducer,
	invoiceCostAllocation: invoiceCostAllocationReducer,
	costAllocationCategory: costAllocationReducer,
	bill: billReducer,
	currencies: currenciesReducer,
	billattachment: billattachmentReducer,
	inventory: inventoryReducer,
	logic_server: serverReducer,
	inventorylocation: inventoryLocationReducer,
	inventorytype: inventoryTypeReducer,
	inventoryphysicalserver: inventoryPhysicalServerReducer,
	datacenter: datacenterReducer,
	rack: rackReducer,
	block: blockReducer,
	historylog: historyReducer,
	dnsservice: dnsServiceReducer,
	rdbservice: rdbServiceReducer,
	mailservice: mailDomainsReducer,
	asyncjob: asyncJobReducer,
	certificate: certificatesResducer,
	vpsadmin: vpsAdminReducer,
	webservice: webserviceReducer,
	servicetype: serviceTypeReducer,
	mail: mailReducer,
	mailtemplate: mailTemplateReducer,
	countriesPage: countriesPageReducer,
})

const rootReducer = (state, action) => {
	if (action.type === 'LOGOUT_USER') {
		if (action.mode === 'RESPONSE') {
			return appReducer(undefined, action)
		}
	}

	return appReducer(state, action)
}

export default rootReducer
