import { UpdateMailServiceRequestAction, UpdateMailServiceResponseAction, UpdateMailServiceErrorAction } from '.'
import { protectedApiClient } from 'helpers/api'
import { handleApiErrorWithNotification } from 'helpers/errorHandling'
import { MailServiceModel, UpdateMailServiceParams } from '../models'

export default (params: UpdateMailServiceParams, cb?: (isSuccess: boolean) => void) => {
	return (
		dispatch: (
			arg: UpdateMailServiceRequestAction | UpdateMailServiceResponseAction | UpdateMailServiceErrorAction
		) => void
	) => {
		const request = new UpdateMailServiceRequestAction(params)
		dispatch(request)

		protectedApiClient
			.put<MailServiceModel>(`/mail/service/${params.id}`, params)
			.then((response) => {
				dispatch(new UpdateMailServiceResponseAction(request, response.data))
				cb && cb(true)
			})
			.catch((error) => {
				dispatch(new UpdateMailServiceErrorAction(request, error))
				handleApiErrorWithNotification(error)
				cb && cb(false)
			})
	}
}
