import React, {useEffect, useRef, useState} from 'react'
import {Link, useLocation} from 'react-router-dom'
import {Card, Table, Space, Modal, Input, message, Tooltip, Row, Col, Select, Spin, AutoComplete} from 'antd'
import {PlusCircleOutlined, CloseOutlined, CheckOutlined, ClockCircleOutlined} from '@ant-design/icons/lib/icons'
import {useTranslation} from 'react-i18next'
import {AppState} from 'common/models'
import {useDispatch, useSelector} from 'react-redux'
import getInvoices from './actions/getInvoices'
import {InvoiceDetails} from './models'
import getTaxes from '../tax/actions/getTaxes'
import moment, {Moment} from 'moment'
import Button from 'antd-button-color'
import queryString, {ParsedQuery} from "query-string"
import './InvoicePage.scss'
import {
    MessageTwoTone,
    EditTwoTone,
    FilePdfTwoTone,
    InfoCircleTwoTone,
    PaperClipOutlined
} from '@ant-design/icons'
import {ColumnsType} from 'antd/lib/table'
import {protectedApiClient} from 'helpers/api'
import TotalNum from "../../../components/TotalNum/TotalNum";
import SortComponent from "../../../components/SortComponent/SortComponent";
import {PAGING} from "../../../common/enums";
import {useLoggedUser} from "../../../helpers/loginUserHelper";
import ReactDatePicker from "react-datepicker";
import getCustomers from "../customer/actions/getCustomers";
import getCompanies from "../../company/actions/getCompanies";
import {LoadingIndicator} from "../../../components";
import {_isNotEmptyObject, removeDiac, slugify, SQL_DATE_FORMAT, stopPropagation} from "../../../common/fce";
import {useHistory} from "react-router";
import getInvoiceDocumentByInvoice from "./actions/getInvoiceDocumentByInvoice";
import Draggable, {DraggableData, DraggableEvent} from "react-draggable";
import usePageSize from "../../../common/usePageSize";
import DateRange from "../../../components/RangeFilter/DateRange";
import UserStorage from "../../../common/userStorage";
import ErrorPage403 from "../../../components/Errors/ErrorPage403";
import {sort_number, sort_str} from "../../../common/sorting";
import i18n from "i18next";
import updateDateOfPayment from "./actions/updateDateOfPayment";
import PdfPreview from "../../../components/ModalDialogs/PdfPreview";
import HistoryModal from "../../../components/History/HistoryModal";
import tableCompanies from "../../login/actions/tableCompanies";

const { confirm } = Modal

const getInvoiceFileName = (customer_name: string): string => {
    let cName = customer_name.replace(/\./g, '-')
    cName = slugify(cName)
    return cName
}

export const getDownloadName = (inv: InvoiceDetails, version: number = 1): string => {
    // returns a name of file of downloaded invoice
    if (!inv) {
        return 'missing_invoice.pdf'
    }
    if (!inv.customer_name) {
        return 'error_in_customer_name.pdf'
    }
    const cName = getInvoiceFileName(inv.customer_name)
    if (inv.total_with_vat > 0) {
        const dt = inv.period_to ? inv.period_to : inv.date_of_taxing
        return `${dt}-${inv.number}-${version}-${slugify(cName)}.pdf`
    }
    else {
        const dt = inv.date_of_issue
        return `${dt}-${inv.number}-${version}-${slugify(cName)}.pdf`
    }
}


const InvoicePage = (props) => {
    const CONTROL_NAME = 'page_billing_invoice'
    const {t} = useTranslation()
    const dispatch = useDispatch()
    const history = useHistory()
    const {search} = useLocation()
    let initRange: [Moment, Moment] = UserStorage.GetInitRange()

    const {invoices, document: invoiceDocument, isLoading} = useSelector((state: AppState) => state.invoice)
    const {taxes} = useSelector((state: AppState) => state.tax)
    const {customers} = useSelector((state: AppState) => state.customer)
    const {companies} = useSelector((state: AppState) => state.auth.tables)
    const {billing_invoices} = useSelector((state: AppState) => state.sidenav)

    // filters
    const [parsed, setParsed] = useState<ParsedQuery<string>>(queryString.parse(search))
    const [filterId, setFilterId] = useState(-1)
    const [searchCustomerName, setSearchCustomerName] = useState('')
    const [searchNumber, setSearchNumber] = useState('')
    const [searchNumber2, setSearchNumber2] = useState('')
    const [searchRange, setSearchRange] = useState<[Moment, Moment] | undefined>(initRange)

    const [isHistoryModalVisible, setHistoryModalVisible] = useState(false)
    const [historyTitle, setHistoryTitle] = useState('')
    const [historyModelId, setHistoryModelId] = useState<number | undefined>()
    const [dataSource, setDataSource] = useState<InvoiceDetails[]>([])
    const [filterStamp, setFilterStamp] = useState('')
    const [pageSize, setPageSize] = useState<string>()
    const REMINDER_DATE = moment().subtract(30, 'days')
    const [previewLoading, setPreviewLoading] = useState<boolean>(false)
    const [preview, setPreview] = useState<string>('')
    const [previewVisible, setPreviewVisible] = useState<boolean>(false)

    const [isViewer, setViewer] = useState(false)
    const [isCreator, setCreator] = useState(false)
    const [isEditor, setEditor] = useState(false)
    const [isDeleter, setDeleter] = useState(false)

    const F_PREPARED_INVOICES = 1
    const F_FINALIZED_INVOICES = 2
    const F_UNPAID_INVOICES = 3
    const F_UNPAID_INVOICES_AFTER_MATURITY = 4
    const F_UNPAID_INVOICES_14DAYS_AFTER_MATURITY = 5
    const F_PAID_INVOICES = 6
    const F_CREDIT_NOTES = 7


    // get settings and logged user from store
    const loggedUser = useLoggedUser()
    if (!loggedUser || !loggedUser.isLoaded()) {
        return (
            <div className="fullwidth-loading" >
                <LoadingIndicator/>
            </div>
        )
    }

    // required authorization
    if (!loggedUser.hasAccess(CONTROL_NAME)) {
        return <ErrorPage403 />
    }

    // settings
    const appSetting = loggedUser.getAppSettings()
    const SEARCH_MIN = appSetting.min_search_length

    // logger
    const logger = (msg, obj:any=null) => { if (appSetting && appSetting.debug) {obj ? console.log('InvoicePage: ' + msg + ' > ' + JSON.stringify(obj)) : console.log('InvoicePage: ' + msg)} }
    usePageSize(appSetting, loggedUser.user.id, pageSize)

    // history drag modal
    const draggleRef = useRef<HTMLDivElement>(null)
    const [bounds, setBounds] = useState({ left: 0, top: 0, bottom: 0, right: 0 })
    const onStart = (_event: DraggableEvent, uiData: DraggableData) => {
        const { clientWidth, clientHeight } = window.document.documentElement;
        const targetRect = draggleRef.current?.getBoundingClientRect();
        if (!targetRect) {
            return;
        }
        setBounds({
            left: -targetRect.left + uiData.x,
            right: clientWidth - (targetRect.right - uiData.x),
            top: -targetRect.top + uiData.y,
            bottom: clientHeight - (targetRect.bottom - uiData.y),
        });
    }

    useEffect(() => {
        const viewer = loggedUser.hasAccess('page_billing_invoice')
        setViewer(viewer)
        setCreator(loggedUser.hasAccess('page_billing_invoice_create_button'))
        setEditor(loggedUser.hasAccess('page_billing_invoice_edit_button'))
        setDeleter(loggedUser.hasAccess('page_billing_invoice_delete_button'))
        if (!viewer) {
            // go to dashboard
            history.replace('/')
        }
        // apply QS filters
        // and set filters
        initRange = UserStorage.GetInitRange()
        if(!customers || (customers && customers.length === 0)) {
            dispatch(getCustomers( 'company,last_invoice', suc => {
                handleQueryStringFilters()
            }))
        }
        else {
            handleQueryStringFilters()
        }
    }, [])

    useEffect(() => {
        // update datasource when data was changed
        if (customers && customers.length) {
            logger('customers===='+customers.length)
            handleQueryStringFilters()
        }
    }, [customers])

    useEffect(() => {
        // load invoices from API
        // trigger ONCE
        if(!taxes || (taxes && taxes.length === 0)) {
            dispatch(getTaxes())
            return
        }
        if(!companies || (companies && companies.length === 0)) {
            logger('companies: '+ companies.length)
            dispatch(tableCompanies())
            return
        }
    }, [taxes, companies])

    useEffect(() => {
        // trigger from Side menu clicked
        if (!isLoading && customers.length) {
            logger('invoices: trigger from Side menu clicked')
            dispatch(getInvoices(customers, getQS(), suc => {
                // clear cache
                setFilterStamp('')
            }))
        }
    }, [billing_invoices])

    useEffect(() => {
        // update datasource when data was changed
        if (!isLoading) {
            refreshGrid()
        }
    }, [searchCustomerName, filterId, searchNumber])

    useEffect(() => {
        // reload invoices when filter has been changed
        if (!isLoading && customers.length) {
            dispatch(getInvoices(customers, getQS(), suc => {}))
        }
    }, [searchRange, customers])

    useEffect(() => {
        // update datasource when data was changed
        if (!isLoading) {
            refreshGridForce()
        }
    }, [invoices])

    const getStamp = (from: string, to: string, searchNum2: string) => {
        // cache stamp
        return `${filterId},${from},${to},${searchCustomerName},${searchNum2}`
    }

    const handleQueryStringFilters = () => {
        // /billing/invoice?filter_id=6&number=122&from=2022-06-01&to=2022-08-10
        logger('handleQueryStringFilters ')
        let passedRange = 0
        if (parsed && _isNotEmptyObject(parsed)) {
            const qs_name: string | string[] | null = parsed['name']
            if (qs_name && typeof qs_name === 'string') {
                setSearchCustomerName(qs_name)
            }
            const qs_filter: string | string[] | null = parsed['filter_id']
            if (qs_filter && typeof qs_filter === 'string') {
                setFilterId(parseInt(qs_filter))
            }
            const qs_number: string | string[] | null = parsed['number']
            if (qs_number && typeof qs_number === 'string') {
                setSearchNumber(qs_number.toLowerCase())  // textbox
                setSearchNumber2(qs_number.toLowerCase()) // filter
            }
            const qs_customer: string | string[] | null = parsed['customer_id']
            if (qs_customer && typeof qs_customer === 'string') {
                logger('handleQueryStringFilters: qs_customer='+qs_customer)
                const cid = parseInt(qs_customer)
                if (cid > 0) {
                    logger('handleQueryStringFilters: customers='+customers?.length)
                    const cust = customers?.find((c) => c.id === cid)
                    if (cust && cust.company!.name) {
                        setSearchCustomerName(cust.company!.name.toLowerCase())
                    }
                }
            }
            // invoice period
            const qs_from: string | string[] | null = parsed['from']
            if (qs_from && typeof qs_from === 'string' && qs_from.length > 8) {
                const qs_to: string | string[] | null = parsed['to']
                if (qs_to && typeof qs_to === 'string' && qs_to.length > 8) {
                    setSearchRange([moment(qs_from), moment(qs_to)])
                    passedRange = 1
                }
            }

        }
        if (passedRange === 0) {     // init searchRange
            setSearchRange(initRange)
        }
    }

    const qFilter = (fid: number | undefined): (i:InvoiceDetails) => boolean => {
        if (!invoices) {
            return (item) => true
        }

        if(fid === F_PREPARED_INVOICES) {
            return (item) => item.state === 1 && !isCreditNote(item)
        } else if(fid === F_FINALIZED_INVOICES) {
            return (item) => item.state != 1 && !isCreditNote(item)
        } else if(fid === F_UNPAID_INVOICES) {
            return (item) => item.paid === false && item.state != 1 && !isCreditNote(item)
        } else if(fid === F_UNPAID_INVOICES_AFTER_MATURITY) {
            const now = moment()
            return (item) => item.paid === false && item.state != 1 && (now > moment(item.date_of_maturity)) && !isCreditNote(item)
        } else if(fid === F_UNPAID_INVOICES_14DAYS_AFTER_MATURITY) {
            return (item) => item.paid === false && item.state != 1 && (REMINDER_DATE > moment(item.date_of_maturity)) && !isCreditNote(item)
        } else if(fid === F_PAID_INVOICES) {
            return (item) => item.paid === true && item.state != 1 && !isCreditNote(item)
        }
        else if (fid === F_CREDIT_NOTES) {
            return (item) => isCreditNote(item)
        }

        return (item) => true
    }

    const isCreditNote = (item: InvoiceDetails): boolean => {
        if (item && item.reverse_invoice_id) {
            return item.reverse_invoice_id > 0
        }
        return false
    }

    const handleInvoiceFilter = (value) => {
        if(!customers || (customers && customers.length === 0)) {
            dispatch(getCustomers( 'company,last_invoice',suc => {
                dispatch(getInvoices(customers, getQS(), suc => {
                    logger('InvoiceFilter onChange:'+value)
                    value ? setFilterId(Number(value)) : setFilterId(-1)
                }))
            }))
            return
        }

        // reload invoices when filter has been changed
        dispatch(getInvoices(customers, getQS(), suc => {
            logger('InvoiceFilter onChange:'+value)
            value ? setFilterId(Number(value)) : setFilterId(-1)
        }))
    }

    const handleDateChange = (dt_from: string, dt_to: string) => {
        // use SQL_DATE_FORMAT
        if (dt_from && dt_to) {
            const dt1: Moment = moment(dt_from)
            const dt2: Moment = moment(dt_to)
            setSearchRange([dt1, dt2])
        }
    }

    const getQS = () => {
        let qs: string[] = []
        const from = searchRange ? searchRange[0] : initRange[0]
        const to = searchRange ? searchRange[1] : initRange[1]
        qs.push('taxing_from='+from.format(SQL_DATE_FORMAT))
        qs.push('taxing_to='+to.format(SQL_DATE_FORMAT))
        return qs.join("&")
    }

    const filtered = (fid: number | undefined, custName: string, num: string
    ): InvoiceDetails[] => {
        // search for pattern
        if (!invoices || invoices.length === 0) {
            return []
        }

        logger('filter..')
        let qs: string[] = []
        const from = searchRange ? searchRange[0] : initRange[0]
        const to = searchRange ? searchRange[1] : initRange[1]
        setFilterStamp(getStamp(from.format(SQL_DATE_FORMAT), to.format(SQL_DATE_FORMAT), num))
        let data = invoices.filter(item => moment(item.date_of_issue).isBetween(from, to, undefined,'[]'))
        qs.push('from='+from.format(SQL_DATE_FORMAT))
        qs.push('to='+to.format(SQL_DATE_FORMAT))
        if (!data) {
            console.error('Error filtered1: qs:'+ qs.join("&"))
            return []
        }

        const us = new UserStorage()
        us.setFilterDateFrom(from.format(SQL_DATE_FORMAT))
        us.setFilterDateTo(to.format(SQL_DATE_FORMAT))

        if (fid && fid > 0) {
            data = data.filter(qFilter(fid))
            qs.push('filter_id='+fid)
        }
        if (!data) {
            console.error('Error filtered1: qs:'+ qs.join("&"))
            return []
        }

        if (custName && custName.length > SEARCH_MIN) {
            // only custName
            const qCustName = (invoice) => invoice.searchableName && invoice.searchableName.includes(removeDiac(custName))
            data = data.filter((i) => {if (!i || !i.searchableName) {return false} else {return qCustName(i)}})
            qs.push('name='+removeDiac(custName))
        }
        if (!data) {
            console.error('Error filtered2: qs:'+ qs.join("&"))
            return []
        }
        if (num && num.length > SEARCH_MIN) {
            // only number
            const qNum = (inv) => inv.number.toString().includes(num.toLowerCase())
            data = data.filter((inv) => {if (!inv || !inv.number) {return false} else {return qNum(inv)}})
            qs.push('number='+num.toLowerCase())
        }
        if (!data) {
            console.error('Error filtered3: qs:'+ qs.join("&"))
            return []
        }
        history.replace('/billing/invoice?' + qs.join("&"))
        logger('replace: /billing/invoice?'+ qs.join("&"))
        setParsed(queryString.parse(search))
        return data.sort(sort_number)
    }

    const refreshGrid = () => {
        const from = searchRange ? searchRange[0] : initRange[0]
        const to = searchRange ? searchRange[1] : initRange[1]
        const stamp: string = getStamp(from.format(SQL_DATE_FORMAT), to.format(SQL_DATE_FORMAT), searchNumber2)
        logger(`refreshGrid pattern: ${stamp}`)

        if (stamp === filterStamp && dataSource.length > 0) {
            logger('refreshGrid skipped')
            return
        }
        setDataSource(filtered(filterId, searchCustomerName, searchNumber2))
    }

    const refreshGridForce = () => {
        const from = searchRange ? searchRange[0] : initRange[0]
        const to = searchRange ? searchRange[1] : initRange[1]
        const stamp: string = getStamp(from.format(SQL_DATE_FORMAT), to.format(SQL_DATE_FORMAT), searchNumber2)
        logger(`refreshGridForce pattern: ${stamp}`)
        setDataSource(filtered(filterId, searchCustomerName, searchNumber2))
    }

    const payInvoice = (invoiceId: number, dt: Date) => {
        let payload = {id: invoiceId, date_of_payment: appSetting.renderDateSQL(dt)}
        dispatch(
            updateDateOfPayment(
                payload,
                (suc) => {
                    if (suc) {
                        message.success(t('billing.invoice.was_paid'))
                    }
                },
            ))
    }

    const FilterByCustomer = invoices && (
        <AutoComplete
            placeholder={t('billing.invoice.table.search_customer')}
            style={{width: 160}}
            allowClear={true}
            value={searchCustomerName}
            onClick={stopPropagation}
            onKeyDown={(e) => {if (e.key === 'Enter') {e.stopPropagation()}}}
            onChange={(v) => {
                setSearchCustomerName(v)
            }}
        />
    )

    const FilterByNumber = invoices && (
        <AutoComplete
            placeholder={t('billing.invoice.table.number')}
            style={{width: '100px'}}
            value={searchNumber}
            allowClear={true}
            onClick={stopPropagation}
            onKeyDown={(e) => {if (e.key === 'Enter') {e.stopPropagation()}}}
            onChange={(v) => {
                setSearchNumber(v)
                if (v && v.length > SEARCH_MIN) {
                    setSearchNumber2(v)
                }
                else {
                    setSearchNumber2('')
                }
            }}
        />
    )

    const payButton = () => {
        return (<Button type='link' size='small'>{t('billing.invoice.btn_set')}</Button>)
    }

    const datePayment = (inv) => {
        if (!isEditor) {
            return ''
        }
        return (
          <ReactDatePicker selected={new Date()}
                           locale={i18n.language}
                           shouldCloseOnSelect={true}
                           dateFormat={appSetting.date_format.toLowerCase()}
                           isClearable={false}
                           onChange={(date) => payInvoice(inv.id, date)}
                           customInput={payButton()}
          />
        )
    }

    const downloadInvoice = (record: InvoiceDetails) => {
        if (record) {
            dispatch(getInvoiceDocumentByInvoice(record.id, (suc, docs) => {
                if (suc) {
                    message.success({content: 'Downloading...', duration: 0, key: record.id}).then()
                    const doc = docs && docs.length ? docs[docs.length-1] : undefined
                    doc && protectedApiClient.get<string>(`/billing/invoice-document/${doc.id}/content`,{onDownloadProgress: () => {
                        }})
                        .then(response => {
                            const downloadLink = document.createElement("a")
                            downloadLink.href = `data:application/pdf;base64,${response.data}`
                            downloadLink.download = getDownloadName(record, doc.version)
                            downloadLink.click()
                            message.destroy(record.id)
                        })
                        .catch(error => {
                            console.log(error)
                            message.error('Download Failed')
                        })
                }

            }))
        }
    }

    const hidePreview = () => {setPreviewVisible(false)}

    const pdfPreview = (id: number) => {
        // download and show preview PDF
        if (id > 0) {
            setPreviewVisible(true)
            setPreviewLoading(true)
            protectedApiClient.get<string>(`/billing/invoice/${id}/preview`)
                .then(response => {
                    setPreviewLoading(false)
                    setPreview(response.data)
                })
                .catch(error => {
                    message.error(t('billing.invoice.preview_fail'))
                    setPreviewLoading(false)
                })
        }
    }

    const columns: ColumnsType<InvoiceDetails> = [
        {
            title: t('billing.invoice.table.state'),
            dataIndex: 'state',
            key: 'state',
            align: 'center',
            width: 40,
            fixed: 'left',
            showSorterTooltip: false,
            render: (text,record) => {
                if (text === 1) {
                    return (
                        <Tooltip title="Prepared">
                            <CloseOutlined style={{backgroundColor: 'yellow'}}  />
                        </Tooltip>
                    )
                }
                else {
                    return (
                        <Tooltip title="Finalized">
                            <CheckOutlined style={{backgroundColor: 'lightgreen'}}  />
                        </Tooltip>
                    )
                }
            }
        },
        {
            title: t('billing.invoice.table.paid'),
            dataIndex: 'paid',
            key: 'paid',
            align: 'center',
            width: 40,
            fixed: 'left',
            showSorterTooltip: false,
            render: (text, record) => {

                if (isCreditNote(record)) {
                    return (
                        <Tooltip title={'Credit Note'}>
                            <CheckOutlined style={{backgroundColor: 'yellow'}}  />
                        </Tooltip>)
                }

                if ((record.state !== 1) ) {
                    if (record.date_of_payment) {
                        return (
                            <Tooltip title={'Paid at ' + record.date_of_payment}>
                                <CheckOutlined style={{backgroundColor: 'lightgreen'}}  />
                            </Tooltip>)
                    }
                    else {
                        // not paid
                        const now = moment()
                        if (now < moment(record.date_of_maturity)) {
                            return (
                                <Tooltip title="Not paid before maturity">
                                    <ClockCircleOutlined />
                                </Tooltip>
                            )
                        }
                        else {
                            if (REMINDER_DATE > moment(record.date_of_maturity)) {
                                return (
                                    <Tooltip title="Not paid after maturity">
                                        <CloseOutlined style={{backgroundColor: 'red'}} />
                                    </Tooltip>
                                )
                            }
                            else {
                                return (
                                    <Tooltip title="Not paid after maturity">
                                        <CloseOutlined style={{backgroundColor: 'orange'}} />
                                    </Tooltip>
                                )
                            }
                        }
                    }
                }
            }
        },
        {
            title: <div style={{marginRight: "1rem"}}>
                        {FilterByCustomer}
                    </div>,
            dataIndex: 'customer_name',
            key: 'customer_name',
            showSorterTooltip: false,
            width: 200,
            fixed: 'left',
            render : (text, record) => (<span title={record.period_from + ' - ' + record.period_to}><Link to={`/billing/invoice/edit/${record.id}`}>{text}</Link></span>),
            sorter: (a, b) => {
                        return SortComponent(!a.customer_name ? '' : a.customer_name.toLowerCase(), !b.customer_name ? '' : b.customer_name.toLowerCase())
                    }
        },
        {
            title: <div style={{marginRight: "1rem"}}>
                {FilterByNumber}
            </div>,
            dataIndex: 'number',
            key: 'number',
            showSorterTooltip: false,
            width: 130,
            fixed: 'left',
            sorter: (a, b) => sort_str(a.number, b.number),
            render: (text, record) => {
                return (
                    <Link to={`/billing/invoice/edit/${record.id}`}>{text}</Link>
                )
            }
        },
        {
            title: t('billing.invoice.table.total_with_vat'),
            dataIndex: 'total_with_vat',
            className: 'right',
            key: 'total_with_vat',
            showSorterTooltip: false,
            width: 90,
            render : (price: number) => appSetting?.renderPrice(price),
            sorter: (a, b) => a.total_with_vat - b.total_with_vat
        },
        {
            title: 'Note',
            dataIndex: 'internal_note',
            align: 'center',
            key: 'internal_note',
            showSorterTooltip: false,
            width: 50,
            render: text => (text &&
                <Tooltip title={text}>
                    <MessageTwoTone />
                </Tooltip>
            )
        },
        {
            title: t('billing.invoice.table.date_of_issue'),
            dataIndex: 'date_of_issue',
            key: 'date_of_issue',
            align: 'center',
            showSorterTooltip: false,
            width: 90,
            render: (dt: number) => appSetting?.renderDate(dt),
            sorter: (a, b) => sort_str(a.date_of_issue, b.date_of_issue)
        },
        {
            title: t('billing.invoice.table.date_of_taxing'),
            dataIndex: 'date_of_taxing',
            align: 'center',
            key: 'date_of_taxing',
            showSorterTooltip: false,
            width: 90,
            render: (dt) => appSetting?.renderDate(dt),
            sorter: (a, b) => sort_str(a.date_of_taxing, b.date_of_taxing)
        },
        {
            title: t('billing.invoice.table.date_of_sent'),
            dataIndex: 'sent_at',
            align: 'center',
            key: 'sent_at',
            showSorterTooltip: false,
            width: 90,
            render: (dt) => appSetting?.renderDate(dt),
            sorter: (a, b) => (!a.sent_at ? 0 : a.sent_at) - (!b.sent_at ? 0 : b.sent_at)
        },
        {
            title: t('billing.invoice.table.date_of_maturity'),
            dataIndex: 'date_of_maturity',
            align: 'center',
            key: 'date_of_maturity',
            showSorterTooltip: false,
            width: 90,
            render: (dt) => appSetting?.renderDate(dt),
            sorter: (a, b) => sort_str(a.date_of_maturity, b.date_of_maturity)
        },
        {
            title: t('billing.invoice.table.date_of_pay'),
            dataIndex: 'date_of_payment',
            key: 'date_of_payment',
            align: 'center',
            showSorterTooltip: false,
            width: 100,
            render: (dt: number, rec) => rec.state === 1 ? '' : (dt ? appSetting?.renderDate(dt) : datePayment(rec)),
            sorter: (a, b) => sort_str(a.date_of_payment, b.date_of_payment)
        },
        {
            title: t('billing.invoice.table.reminded_at'),
            dataIndex: 'reminded_at',
            key: 'reminded_at',
            align: 'center',
            showSorterTooltip: false,
            width: 100,
            render: (dt: number) => appSetting?.renderDate(dt),
            sorter: (a, b) => sort_str(a.reminded_at, b.reminded_at)
        },
        {
            title: t('billing.invoice.table.action'),
            key: 'action',
            dataIndex: 'action',
            align: 'center',
            width: 120,
            showSorterTooltip: false,
            fixed: 'right',
            render: (_, record: InvoiceDetails) => (
                <Space size={1}>
                    {
                        record.state > 0 && (
                            <Button title={t('billing.invoice.table.preview')}
                                icon={<FilePdfTwoTone />}
                                size='small'
                                type='text'
                                className='actionButton'
                                onClick={() => pdfPreview(record.id)}
                            />
                        )

                    }

                    {
                        record.state === 2 && (
                            <Button title={t('billing.invoice.table.download')}
                                    icon={<PaperClipOutlined style={{color: 'green'}} />}
                                    size='small'
                                    type='text'
                                    className='actionButton'
                                    onClick={() => downloadInvoice(record)}
                                    disabled={record.state != 2}
                            />
                        )

                    }
                    {
                        record.state != 2 && (
                            <Button title={t('billing.invoice.table.no_download')}
                                    icon={<PaperClipOutlined style={{color: 'lightgray'}} />}
                                    size='small'
                                    type='text'
                                    className='actionButton'
                            />
                        )
                    }

                    {
                        isEditor && (
                            <Link to={`/billing/invoice/edit/${record?.id}`}>
                                <Button title={t('billing.invoice.table.edit')}
                                        type='text' size='small'
                                        className='actionButton'
                                        icon={<EditTwoTone twoToneColor='green' />} />
                            </Link>
                        )
                    }

                    <Button title={t('general.btnHistory')} size='small'
                            onClick={() => {
                                setHistoryModelId(record.id)
                                record.customer_name && setHistoryTitle(record.customer_name)
                                setHistoryModalVisible(true)
                            }}
                            icon={<InfoCircleTwoTone />}
                            className='actionButton'
                    />
                </Space>
            ),
        },
    ]

    const InvoiceFilter = (
            <Select
                placeholder={t('billing.invoice.table.search_invoices')}
                //options={options}
                optionLabelProp="label"
                style={{ width: 250 }}
                allowClear
                value={filterId > 0 ? filterId : undefined}
                onChange={handleInvoiceFilter}
                filterOption={(inputValue, option) =>
                    !option!.value ? false : option!.value.toString().toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                }
            >
                <Select.Option value={F_PREPARED_INVOICES} label={t('billing.invoice.prepared_invoices')}>
                    <CloseOutlined style={{backgroundColor: 'yellow'}} /> {t('billing.invoice.prepared_invoices')}
                </Select.Option>
                <Select.Option value={F_FINALIZED_INVOICES} label={t('billing.invoice.finalized_invoices')}>
                    <CheckOutlined style={{backgroundColor: 'lightgreen'}} /> {t('billing.invoice.finalized_invoices')}
                </Select.Option>
                <Select.Option value={F_UNPAID_INVOICES} label={t('billing.invoice.unpaid_invoices')}>
                    <CloseOutlined style={{backgroundColor: 'white'}} /> {t('billing.invoice.unpaid_invoices')}
                </Select.Option>
                <Select.Option value={F_UNPAID_INVOICES_AFTER_MATURITY} label={t('billing.invoice.unpaid_invoices_after_maturity')}>
                    <CloseOutlined style={{backgroundColor: 'orange'}} /> {t('billing.invoice.unpaid_invoices_after_maturity')}
                </Select.Option>
                <Select.Option value={F_UNPAID_INVOICES_14DAYS_AFTER_MATURITY} label={t('billing.invoice.unpaid_invoices_14days_after_maturity')}>
                    <CloseOutlined style={{backgroundColor: 'red'}} /> {t('billing.invoice.unpaid_invoices_14days_after_maturity')}
                </Select.Option>
                <Select.Option value={F_PAID_INVOICES} label={t('billing.invoice.paid_invoices')}>
                    <CheckOutlined style={{backgroundColor: 'lightgreen'}} /> {t('billing.invoice.paid_invoices')}
                </Select.Option>
                <Select.Option value={F_CREDIT_NOTES} label={t('billing.invoice.credit_notes')}>
                    <CheckOutlined style={{backgroundColor: 'yellow'}} /> {t('billing.invoice.credit_notes')}
                </Select.Option>
            </Select>
    )


    if (appSetting) {
        if (!customers || (customers && customers.length===0)) {
            return <Spin/>
        }
    }

    if (appSetting) {
        return (
            <>
                <Card className='InvoicePage'
                    title={
                        <Row>
                            <Col span={3}>
                                <PaperClipOutlined/> &nbsp; {t('billing.invoice.title')}
                            </Col>
                            <Col span={9}>
                                {t('billing.invoice.filter')}: &nbsp; {InvoiceFilter}
                            </Col>
                            <Col span={2} className='right' style={{padding: '2px'}}>{t('billing.invoice.period_filter')}:&nbsp;</Col>
                            <Col span={10}>
                                <DateRange format={appSetting.date_picker_format} // not Moment formats!!
                                           initStart={initRange[0].format(SQL_DATE_FORMAT)}
                                           initEnd={initRange[1].format(SQL_DATE_FORMAT)}
                                           onChange={handleDateChange}
                                />
                            </Col>
                        </Row>
                    }
                    extra={
                    <>
                        {
                            isCreator && (
                                <Link to='/billing/invoice/create'>
                                    <Button type='primary'>
                                        <PlusCircleOutlined/> {t('billing.invoice.create_invoice')}{' '}
                                    </Button>
                                </Link>
                            )
                        }
                        {
                            !isCreator && (
                                <Button type='primary' disabled={true}>
                                    <PlusCircleOutlined/> {t('billing.invoice.create_invoice')}{' '}
                                </Button>
                            )
                        }
                    </>
                    }
                    loading={false}
                >
                    <Table<InvoiceDetails>
                        dataSource={dataSource}
                        rowKey="id"
                        columns={columns}
                        scroll={{ x: 680 }}
                        bordered={true}
                        loading={isLoading}
                        className='invoiceDetailTable'
                        rowClassName={(record) => {
                            if (record?.state === 2)
                                if (record?.paid === true)
                                    return 'paid'
                                else
                                    return 'prepared'
                            else
                                return ''
                        }}
                        pagination={{
                            defaultPageSize: appSetting.grid_page_size,
                            pageSizeOptions: PAGING,
                            showSizeChanger: true
                        }}
                        onChange={(ev) => {
                            setPageSize(`${ev.pageSize}`)
                        }}
                        footer={() => dataSource && TotalNum(Number(dataSource.length), 'Invoice', dataSource)}
                    />
                </Card>

                <PdfPreview
                    title={t('billing.invoice.pdf_preview')}
                    loading={previewLoading}
                    visible={previewVisible}
                    hide={hidePreview}
                    preview={preview}
                />

                <HistoryModal service='billing' model='Invoice'
                              modelId={historyModelId}
                              title={historyTitle}
                              isModalVisible={isHistoryModalVisible}
                              setModalVisible={() => setHistoryModalVisible(false)}
                              modalRender={(modal) => (
                                  <Draggable bounds={bounds} onStart={(ev, data) => onStart(ev, data)}>
                                      <div ref={draggleRef}>{modal}</div>
                                  </Draggable>
                              )}
                />
            </>
        )
    }
    else {
        return (
            <Spin/>
        )
    }
}

export default InvoicePage
