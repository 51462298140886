import { ActionType, VhostActions } from './actions'
import { AsyncActionMode, PAGE1, Pagination } from 'common/models'
import { WebServiceState } from './models'

const VHOST_INITIAL_STATE: WebServiceState = {
	isSavingService: false,
	isSavingVhost: false,
	isApplyingChangesVhost: false,
	isSavingTemplate: false,
	isLoadingService: false,
	isLoadingVhost: false,
	isLoadingTemplate: false,
	vhost: undefined,
	vhost_aliases: [],
	certificate: undefined,
	certificates: [],
	domains: [],
	isLoadingDomains: false,
	fqdn: '',
	fqdn_domain: undefined,
	fqdn_database: undefined,
	fqdn_zis_exists: false,
	fqdn_domain_status: undefined,
	fqdn_dns_services: [],
	fqdn_rdb_services: [],
	fqdn_mail_services: [],
	fqdn_web_services: [],
	vhosts: { items: [], pagination: PAGE1 },
	vhost_pager: PAGE1,
	service: undefined,
	service_templates: [],
	service_vhosts: [],
	services: { items: [], pagination: PAGE1 },
	pager: PAGE1,
	template: undefined,
	templates: [],
	servers: [],
	isLoadingServers: false,
	isLoadingLookup: false,
	lookup_vhost: [],
	lookup_host: [],
	web_daemon: undefined,
	web_server_version: undefined,
	php_version: undefined,
	web_server_versions: [],
	php_versions: [],
	error: undefined,
}

export default (state = VHOST_INITIAL_STATE, action: VhostActions): WebServiceState => {
	switch (action.type) {
		case ActionType.GET_VHOST:
			if (action.mode === AsyncActionMode.REQUEST) {
				return { ...state, isLoadingVhost: true }
			}
			if (action.mode === AsyncActionMode.RESPONSE) {
				return {
					...state,
					vhost: action.data,
					isLoadingVhost: false,
				}
			}
			if (action.mode === AsyncActionMode.ERROR) {
				return {
					...state,
					isLoadingVhost: false,
					error: action.error,
				}
			}
			break

		case ActionType.APPLY_CHANGES_VHOST:
			if (action.mode === AsyncActionMode.REQUEST) {
				return { ...state, isApplyingChangesVhost: true }
			}
			if (action.mode === AsyncActionMode.RESPONSE) {
				return {
					...state,
					vhost: action.data,
					isApplyingChangesVhost: false,
				}
			}
			if (action.mode === AsyncActionMode.ERROR) {
				return {
					...state,
					isApplyingChangesVhost: false,
					error: action.error,
				}
			}
			break
		case ActionType.CREATE_VHOST:
			if (action.mode === AsyncActionMode.REQUEST) {
				return { ...state, isSavingVhost: true }
			}
			if (action.mode === AsyncActionMode.RESPONSE) {
				// add to first pos.
				let new_list = [...state.vhosts.items] // force new obj
				new_list.unshift(action.data)
				return {
					...state,
					vhost: action.data,
					vhosts: {
						...state.vhosts,
						items: new_list,
					},
					isSavingVhost: false,
				}
			}
			if (action.mode === AsyncActionMode.ERROR) {
				return {
					...state,
					error: action.error,
					isSavingVhost: false,
				}
			}
			break
		case ActionType.GET_VHOSTS:
			if (action.mode === AsyncActionMode.REQUEST) {
				return {
					...state,
					web_daemon: undefined,
					web_server_version: undefined,
					php_version: undefined,
					vhost: undefined,
					certificate: undefined,
					isLoadingVhost: true,
				}
			}
			if (action.mode === AsyncActionMode.RESPONSE) {
				return {
					...state,
					isLoadingVhost: false,
					vhosts: action.data,
					vhost_pager: { ...action.data.pagination, page: action.data.pagination.page + 1 },
				}
			}
			if (action.mode === AsyncActionMode.ERROR) {
				return {
					...state,
					isLoadingVhost: false,
					error: action.error,
				}
			}
			break
		case ActionType.GET_SERVICE_VHOSTS:
			if (action.mode === AsyncActionMode.REQUEST) {
				return { ...state, isLoadingVhost: true }
			}
			if (action.mode === AsyncActionMode.RESPONSE) {
				return {
					...state,
					isLoadingVhost: false,
					vhost: undefined,
					service_vhosts: action.data,
				}
			}
			if (action.mode === AsyncActionMode.ERROR) {
				return {
					...state,
					isLoadingVhost: false,
					error: action.error,
				}
			}
			break
		case ActionType.DELETE_VHOST:
			if (action.mode === AsyncActionMode.REQUEST) {
				return { ...state, isSavingVhost: true }
			}
			if (action.mode === AsyncActionMode.RESPONSE) {
				return {
					...state,
					vhost: undefined,
					vhosts: {
						...state.vhosts,
						items: state.vhosts.items!.filter((c) => c.id !== action.request.id),
					},
					isSavingVhost: false,
				}
			}
			if (action.mode === AsyncActionMode.ERROR) {
				return {
					...state,
					isSavingVhost: false,
					error: action.error,
				}
			}
			break
		case ActionType.UPDATE_VHOST:
			if (action.mode === AsyncActionMode.REQUEST) {
				return { ...state, isSavingVhost: true }
			}
			if (action.mode === AsyncActionMode.RESPONSE) {
				return {
					...state,
					vhost: action.data,
					vhosts: {
						...state.vhosts,
						items: state.vhosts.items!.map((c) => (c.id === action.data.id ? { ...action.data } : c)),
					},
					isSavingVhost: false,
				}
			}
			if (action.mode === AsyncActionMode.ERROR) {
				return {
					...state,
					isSavingVhost: false,
					error: action.error,
				}
			}
			break

		case ActionType.REMOVE_CERT_FROM_VHOST:
				if (action.mode === AsyncActionMode.REQUEST) {
					return { ...state, isSavingVhost: true }
				}
				if (action.mode === AsyncActionMode.RESPONSE) {
					return {
						...state,
						isSavingVhost: false,
					}
				}
				if (action.mode === AsyncActionMode.ERROR) {
					return {
						...state,
						isSavingVhost: false,
						error: action.error,
					}
				}
				break

		case ActionType.GET_VHOST_CERTIFICATE:
			if (action.mode === AsyncActionMode.REQUEST) {
				return { ...state, certificate: undefined }
			}
			if (action.mode === AsyncActionMode.RESPONSE) {
				return {
					...state,
					certificate: action.data,
				}
			}
			if (action.mode === AsyncActionMode.ERROR) {
				return {
					...state,
					error: action.error,
				}
			}
			break
		case ActionType.PREVIEW_CONFIG:
			if (action.mode === AsyncActionMode.REQUEST) {
				return { ...state, vhost_config: '' }
			}
			if (action.mode === AsyncActionMode.RESPONSE) {
				return {
					...state,
					vhost_config: action.data,
				}
			}
			if (action.mode === AsyncActionMode.ERROR) {
				return {
					...state,
					error: action.error,
				}
			}
			break
		case ActionType.GET_CERTIFICATES_VHOST:
			if (action.mode === AsyncActionMode.REQUEST) {
				return { ...state, certificate: undefined, isLoadingVhost: true }
			}
			if (action.mode === AsyncActionMode.RESPONSE) {
				return {
					...state,
					certificates: action.data,
					isLoadingVhost: false,
				}
			}
			if (action.mode === AsyncActionMode.ERROR) {
				return {
					...state,
					isLoadingVhost: false,
					error: action.error,
				}
			}
			break
		case ActionType.GET_VHOST_ALIASES:
			if (action.mode === AsyncActionMode.REQUEST) {
				return { ...state, isLoadingVhost: true }
			}
			if (action.mode === AsyncActionMode.RESPONSE) {
				return {
					...state,
					isLoadingVhost: false,
					vhost_aliases: action.data,
				}
			}
			if (action.mode === AsyncActionMode.ERROR) {
				return {
					...state,
					isLoadingVhost: false,
					error: action.error,
				}
			}
			break
		case ActionType.CREATE_ALIAS:
			if (action.mode === AsyncActionMode.REQUEST) {
				return { ...state, isSavingVhost: true }
			}
			if (action.mode === AsyncActionMode.RESPONSE) {
				// add to first pos.
				let new_list = [...state.vhost_aliases] // force new obj
				new_list.unshift(action.data)
				return {
					...state,
					vhost_aliases: new_list,
					isSavingVhost: false,
				}
			}
			if (action.mode === AsyncActionMode.ERROR) {
				return {
					...state,
					error: action.error,
					isSavingVhost: false,
				}
			}
			break
		case ActionType.DELETE_ALIAS:
			if (action.mode === AsyncActionMode.REQUEST) {
				return { ...state, isSavingVhost: true }
			}
			if (action.mode === AsyncActionMode.RESPONSE) {
				return {
					...state,
					vhost_aliases: state.vhost_aliases.filter((c) => c.name !== action.request.params.name),
					isSavingVhost: false,
				}
			}
			if (action.mode === AsyncActionMode.ERROR) {
				return {
					...state,
					isSavingVhost: false,
					error: action.error,
				}
			}
			break

		case ActionType.GET_WEB_SERVICE:
			if (action.mode === AsyncActionMode.REQUEST) {
				return {
					...state,
					service: undefined,
					web_daemon: undefined,
					web_server_version: undefined,
					php_version: undefined,
					service_templates: [],
					service_vhosts: [],
					isLoadingService: true,
				}
			}
			if (action.mode === AsyncActionMode.RESPONSE) {
				return {
					...state,
					service: action.data,
					isLoadingService: false,
				}
			}
			if (action.mode === AsyncActionMode.ERROR) {
				return {
					...state,
					isLoadingService: false,
					error: action.error,
				}
			}
			break
		case ActionType.GET_WEBSERVICE_BY_NAME:
			if (action.mode === AsyncActionMode.REQUEST) {
				return { ...state, service: undefined, isLoadingService: true }
			}
			if (action.mode === AsyncActionMode.RESPONSE) {
				return {
					...state,
					service: action.data && action.data.length ? action.data[0] : undefined,
					isLoadingService: false,
				}
			}
			if (action.mode === AsyncActionMode.ERROR) {
				return {
					...state,
					isLoadingService: false,
					error: action.error,
				}
			}
			break
		case ActionType.GET_WEB_DAEMON:
			if (action.mode === AsyncActionMode.REQUEST) {
				return { ...state, web_daemon: undefined, error: undefined }
			}
			if (action.mode === AsyncActionMode.RESPONSE) {
				return {
					...state,
					web_daemon: action.data.deamon_version ? `${action.data.deamon_version}` : null,
				}
			}
			if (action.mode === AsyncActionMode.ERROR) {
				return {
					...state,
					web_daemon: null,
					error: action.error,
				}
			}
			break
		case ActionType.GET_WEB_SERVER_VERSION:
			if (action.mode === AsyncActionMode.REQUEST) {
				return { ...state, web_server_version: undefined, error: undefined }
			}
			if (action.mode === AsyncActionMode.RESPONSE) {
				return {
					...state,
					web_server_version: action.data.apache_version ? `${action.data.apache_version}` : null,
				}
			}
			if (action.mode === AsyncActionMode.ERROR) {
				return {
					...state,
					web_server_version: null,
					error: action.error,
				}
			}
			break
		case ActionType.GET_PHP_VERSION:
			if (action.mode === AsyncActionMode.REQUEST) {
				return { ...state, php_version: undefined, error: undefined }
			}
			if (action.mode === AsyncActionMode.RESPONSE) {
				return {
					...state,
					php_version: action.data.php_version ? `${action.data.php_version}` : null,
				}
			}
			if (action.mode === AsyncActionMode.ERROR) {
				return {
					...state,
					php_version: null,
					error: action.error,
				}
			}
			break
		case ActionType.GET_SERVICE_TEMPLATES:
			if (action.mode === AsyncActionMode.REQUEST) {
				return { ...state, isLoadingTemplate: true }
			}
			if (action.mode === AsyncActionMode.RESPONSE) {
				return { ...state, service_templates: action.data, isLoadingTemplate: false }
			}
			if (action.mode === AsyncActionMode.ERROR) {
				return {
					...state,
					isLoadingTemplate: false,
					error: action.error,
				}
			}
			break

		case ActionType.CREATE_WEB_SERVICE:
			if (action.mode === AsyncActionMode.REQUEST) {
				return { ...state, isSavingService: true }
			}
			if (action.mode === AsyncActionMode.RESPONSE) {
				// add to first pos.
				let new_list = [...state.services.items] // force new obj
				new_list.unshift(action.data)
				return {
					...state,
					service: undefined,
					services: {
						...state.services,
						items: new_list,
					},
					isSavingService: false,
				}
			}
			if (action.mode === AsyncActionMode.ERROR) {
				return {
					...state,
					error: action.error,
					isSavingService: false,
				}
			}
			break

		case ActionType.CREATE_DATABASE_VHOST:
			if (action.mode === AsyncActionMode.REQUEST) {
				return { ...state }
			}
			if (action.mode === AsyncActionMode.RESPONSE) {
				return {
					...state,
					fqdn_database: action.data,
				}
			}
			if (action.mode === AsyncActionMode.ERROR) {
				return {
					...state,
					error: action.error,
				}
			}
			break
		case ActionType.GET_WEB_TEMPLATE:
			if (action.mode === AsyncActionMode.REQUEST) {
				return { ...state, isLoadingTemplate: true }
			}
			if (action.mode === AsyncActionMode.RESPONSE) {
				return {
					...state,
					template: action.data,
					isLoadingTemplate: false,
				}
			}
			if (action.mode === AsyncActionMode.ERROR) {
				return {
					...state,
					isLoadingTemplate: false,
					error: action.error,
				}
			}
			break
		case ActionType.CREATE_WEB_TEMPLATE:
			if (action.mode === AsyncActionMode.REQUEST) {
				return { ...state, isSavingTemplate: true }
			}
			if (action.mode === AsyncActionMode.RESPONSE) {
				// add to first pos.
				let new_list = [...state.templates] // force new obj
				new_list.unshift(action.data)
				return {
					...state,
					template: undefined,
					templates: new_list,
					isSavingTemplate: false,
				}
			}
			if (action.mode === AsyncActionMode.ERROR) {
				return {
					...state,
					error: action.error,
					isSavingTemplate: false,
				}
			}
			break
		case ActionType.GET_WEB_TEMPLATES:
			if (action.mode === AsyncActionMode.REQUEST) {
				return { ...state, isLoadingTemplate: true }
			}
			if (action.mode === AsyncActionMode.RESPONSE) {
				return {
					...state,
					isLoadingTemplate: false,
					template: undefined,
					templates: action.data,
				}
			}
			if (action.mode === AsyncActionMode.ERROR) {
				return {
					...state,
					isLoadingTemplate: false,
					error: action.error,
				}
			}
			break
		case ActionType.DELETE_WEB_TEMPLATE:
			if (action.mode === AsyncActionMode.REQUEST) {
				return { ...state, isSavingTemplate: true }
			}
			if (action.mode === AsyncActionMode.RESPONSE) {
				return {
					...state,
					template: undefined,
					templates: state.templates.filter((c) => c.id !== action.request.id),
					isSavingTemplate: false,
				}
			}
			if (action.mode === AsyncActionMode.ERROR) {
				return {
					...state,
					isSavingTemplate: false,
					error: action.error,
				}
			}
			break
		case ActionType.UPDATE_WEB_TEMPLATE:
		case ActionType.UPDATE_WEB_TEMPLATE_VERSIONS:
			if (action.mode === AsyncActionMode.REQUEST) {
				return { ...state, isSavingTemplate: true }
			}
			if (action.mode === AsyncActionMode.RESPONSE) {
				return {
					...state,
					template: action.data,
					templates: state.templates.map((c) => (c.id === action.data.id ? { ...action.data } : c)),
					isSavingTemplate: false,
				}
			}
			if (action.mode === AsyncActionMode.ERROR) {
				return {
					...state,
					isSavingTemplate: false,
					error: action.error,
				}
			}
			break

		case ActionType.GET_PHP_VERSIONS:
			if (action.mode === AsyncActionMode.REQUEST) {
				return { ...state, php_versions: [] }
			}
			if (action.mode === AsyncActionMode.RESPONSE) {
				return { ...state, php_versions: action.data }
			}
			if (action.mode === AsyncActionMode.ERROR) {
				return { ...state, error: action.error }
			}
			break
		case ActionType.GET_WEB_SERVER_VERSIONS:
			if (action.mode === AsyncActionMode.REQUEST) {
				return { ...state, web_server_versions: [] }
			}
			if (action.mode === AsyncActionMode.RESPONSE) {
				return { ...state, web_server_versions: action.data }
			}
			if (action.mode === AsyncActionMode.ERROR) {
				return { ...state, error: action.error }
			}
			break
		case ActionType.GET_SERVERS_WEB:
			if (action.mode === AsyncActionMode.REQUEST) {
				return { ...state, servers: [], isLoadingServers: true }
			}
			if (action.mode === AsyncActionMode.RESPONSE) {
				return { ...state, servers: action.data, isLoadingServers: false }
			}
			if (action.mode === AsyncActionMode.ERROR) {
				return { ...state, error: action.error, isLoadingServers: false }
			}
			break
		case ActionType.LOOKUP_VHOST:
			if (action.mode === AsyncActionMode.REQUEST) {
				return { ...state, lookup_vhost: [], isLoadingLookup: true }
			}
			if (action.mode === AsyncActionMode.RESPONSE) {
				if (action.field === 'name') {
					return {
						...state,
						lookup_vhost: action.data,
						isLoadingLookup: false,
					}
				}
				console.error('Wrong action field.')
			}
			if (action.mode === AsyncActionMode.ERROR) {
				return {
					...state,
					error: action.error,
					isLoadingLookup: false,
				}
			}
			break
		case ActionType.LOOKUP_WEB_SERVICE:
			if (action.mode === AsyncActionMode.REQUEST) {
				return { ...state, lookup_host: [], isLoadingLookup: true }
			}
			if (action.mode === AsyncActionMode.RESPONSE) {
				if (action.field === 'host') {
					return {
						...state,
						lookup_host: action.data,
						isLoadingLookup: false,
					}
				}
				console.error('Wrong action field.')
			}
			if (action.mode === AsyncActionMode.ERROR) {
				return {
					...state,
					error: action.error,
					isLoadingLookup: false,
				}
			}
			break
		case ActionType.DOMAINS_VHOST:
			if (action.mode === AsyncActionMode.REQUEST) {
				return { ...state, domains: [], isLoadingDomains: true }
			}
			if (action.mode === AsyncActionMode.RESPONSE) {
				return {
					...state,
					domains: action.data,
					isLoadingDomains: false,
				}
			}
			if (action.mode === AsyncActionMode.ERROR) {
				return {
					...state,
					error: action.error,
					isLoadingDomains: false,
				}
			}
			break
		case ActionType.DOMAIN_EXISTS:
			if (action.mode === AsyncActionMode.REQUEST) {
				return { ...state, fqdn_zis_exists: false }
			}
			if (action.mode === AsyncActionMode.RESPONSE) {
				return {
					...state,
					fqdn_zis_exists: action.data.zis_exist === 1 ? true : false,
				}
			}
			if (action.mode === AsyncActionMode.ERROR) {
				return {
					...state,
					error: action.error,
				}
			}
			break
		case ActionType.GET_DOMAIN_STATUS:
			if (action.mode === AsyncActionMode.REQUEST) {
				return { ...state, fqdn_domain_status: undefined }
			}
			if (action.mode === AsyncActionMode.RESPONSE) {
				return {
					...state,
					fqdn_domain_status: action.data,
				}
			}
			if (action.mode === AsyncActionMode.ERROR) {
				return {
					...state,
					error: action.error,
				}
			}
			break
		case ActionType.CREATE_DOMAIN_VHOST:
			if (action.mode === AsyncActionMode.REQUEST) {
				return { ...state, fqdn_domain: undefined }
			}
			if (action.mode === AsyncActionMode.RESPONSE) {
				return {
					...state,
					fqdn_domain: action.data,
				}
			}
			if (action.mode === AsyncActionMode.ERROR) {
				return {
					...state,
					error: action.error,
				}
			}
			break
		case ActionType.GET_DOMAIN_VHOST:
			if (action.mode === AsyncActionMode.REQUEST) {
				return { ...state, fqdn_domain: undefined }
			}
			if (action.mode === AsyncActionMode.RESPONSE) {
				return {
					...state,
					fqdn_domain: action.data,
				}
			}
			if (action.mode === AsyncActionMode.ERROR) {
				return {
					...state,
					error: action.error,
				}
			}
			break
		case ActionType.GET_DNS_SERVICES:
			if (action.mode === AsyncActionMode.REQUEST) {
				return { ...state, fqdn_dns_services: [] }
			}
			if (action.mode === AsyncActionMode.RESPONSE) {
				return {
					...state,
					fqdn_dns_services: action.data,
				}
			}
			if (action.mode === AsyncActionMode.ERROR) {
				return {
					...state,
					error: action.error,
				}
			}
			break
		case ActionType.GET_RDB_SERVICES:
			if (action.mode === AsyncActionMode.REQUEST) {
				return { ...state, fqdn_rdb_services: [] }
			}
			if (action.mode === AsyncActionMode.RESPONSE) {
				return {
					...state,
					fqdn_rdb_services: action.data,
				}
			}
			if (action.mode === AsyncActionMode.ERROR) {
				return {
					...state,
					error: action.error,
				}
			}
			break
		case ActionType.GET_MAIL_SERVICES:
			if (action.mode === AsyncActionMode.REQUEST) {
				return { ...state, fqdn_mail_services: [] }
			}
			if (action.mode === AsyncActionMode.RESPONSE) {
				return {
					...state,
					fqdn_mail_services: action.data,
				}
			}
			if (action.mode === AsyncActionMode.ERROR) {
				return {
					...state,
					error: action.error,
				}
			}
			break
		case ActionType.GET_WEB_SERVICES:
			if (action.mode === AsyncActionMode.REQUEST) {
				return { ...state, fqdn_web_services: [] }
			}
			if (action.mode === AsyncActionMode.RESPONSE) {
				return {
					...state,
					fqdn_web_services: action.data,
				}
			}
			if (action.mode === AsyncActionMode.ERROR) {
				return {
					...state,
					error: action.error,
				}
			}
			break
	}
	return state
}
