import React, {useEffect, useState} from 'react'
import {Card, Form, Input, Button} from 'antd'
import {formItemLayout} from 'helpers/layoutHelpers'
import {Store} from 'antd/lib/form/interface'
import {LockOutlined} from '@ant-design/icons'
import {useTranslation} from 'react-i18next'
import {RouteComponentProps, useHistory} from 'react-router'
import queryString, {ParsedQuery} from 'query-string'
import {passwordReset} from './actions/passwordReset'
import {useDispatch} from "react-redux";
import setPassRequest from "../../login/actions/setPassRequest";

const PasswordResetPage = (props: RouteComponentProps) => {
    const {t} = useTranslation()
    const history = useHistory()
    const dispatch = useDispatch()

    const [resetToken, setResetToken] = useState<string | string[] | null | undefined>()

    useEffect(() => {
         // here it is redirected to /login 
         // reason reset-password
        const url = props.location.search
        const params: ParsedQuery = queryString.parse(url)
        if (params && params.token && typeof params.token === 'string') {  
            history.push('/login')
            dispatch(setPassRequest(params.token))
        }
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        if (resetToken && resetToken.length > 5) {
            history.replace('/login?flow=reset-password')
        }
        // eslint-disable-next-line
    }, [resetToken])

    return (

        <Card
            title={`${t('resetPasswordPage.reset_password_for')}`}
            style={{width: 500}}

            className='PasswordResetPage'>
            <Form
                name='reset_password'
                className='reset-password-form'
                {...formItemLayout}
            >
                <Form.Item name='token' noStyle>
                    <Input type='hidden'/>
                </Form.Item>

                <Form.Item
                    name='password'
                    rules={[
                        {required: true, message: t('changePasswordPage.err_new_password')},
                        {min: 5, message: t('changePasswordPage.err_password_length', {count: 5})},
                        // { pattern: mediumRegex, message: t('changePasswordPage.err_password_mediumRegex') }
                    ]}
                    label={t('changePasswordPage.new_password')}
                >
                    <Input type='password' prefix={<LockOutlined className='site-form-item-icon'/>}/>
                </Form.Item>

                <Form.Item
                    name='password_repeat'
                    label={t('changePasswordPage.repeat_new_password')}
                    rules={[
                        {
                            required: true,
                            message: t('changePasswordPage.err_repeat_password'),
                        },
                        ({getFieldValue}) => ({
                            validator(rule, value) {
                                if (!value || getFieldValue('password') === value) {
                                    return Promise.resolve()
                                }
                                return Promise.reject(t('changePasswordPage.err_repeat_not_match'))
                            },
                        }),
                    ]}
                >
                    <Input type='password' prefix={<LockOutlined className='site-form-item-icon'/>}/>
                </Form.Item>

                <Form.Item>
                    <Button type='primary' htmlType='submit' className='login-form-button'>
                        {t('changePasswordPage.change')}
                    </Button>
                </Form.Item>
            </Form>
        </Card>
    )
}

export default PasswordResetPage
