import { useTranslation } from "react-i18next"
import { useHistory } from "react-router"
import { useDispatch, useSelector } from "react-redux"
import { AppState } from "../../common/models"
import { useEffect, useRef, useState } from "react"
import { useLoggedUser } from "../../helpers/loginUserHelper"
import { LoadingIndicator } from "../../components"
import ErrorPage403 from "../../components/Errors/ErrorPage403"
import useLogger from "../../common/useLogger"
import Draggable, { DraggableData, DraggableEvent } from "react-draggable"
import { removeDiac, stopPropagation } from "../../common/fce"
import { MailDomainModel } from "./models"
import { ColumnsType } from "antd/lib/table"
import { AutoComplete, Card, message, Modal, Space, Spin, Table, Tag } from "antd"
import Button from "antd-button-color"
import {
  ConsoleSqlOutlined,
  InfoCircleTwoTone,
} from "@ant-design/icons"
import { GlobalOutlined, MailOutlined, PlusCircleOutlined } from "@ant-design/icons/lib/icons"
import usePageSize from "../../common/usePageSize"
import Pager from "../../components/pagination/pager"
import getMailDomains from "./actions/getMailDomains"
import { Link } from "react-router-dom"
import "./Mails.scss"
import HistoryModal from "../../components/History/HistoryModal"
import CreateMailDomainForm from "./CreateMailDomainForm"
import CreateMailServiceForm from "./CreateMailServiceForm"
import lookupMailService from "./actions/lookupMailService"
import lookupMailDomain from "./actions/lookupMailDomain"
import React from "react"
import checkMX from "./actions/checkMX"

const { confirm } = Modal



const MailDomainsPage = () => {
  const CONTROL_NAME = 'page_mail_domains'
  const { t } = useTranslation()
  const history = useHistory()
  const dispatch = useDispatch()

  const { customers, customerNames } = useSelector((state: AppState) => state.auth.tables)
  const { isLoadingService, mail_service, mail_domains, mail_domains_pager,
    lookup_domain, lookup_host, isLoadingLookup } = useSelector((state: AppState) => state.mailservice)
  const { mail_services } = useSelector((state: AppState) => state.sidenav)

  const [dataSource, setDataSource] = useState<MailDomainModel[]>([])
  const [sourceFull, setSourceFull] = useState<MailDomainModel[]>([])

  const [searchName, setSearchName] = useState<string>('')
  const [searchNameLookup, setSearchNameLookup] = useState<string>('')
  const [searchHost, setSearchHost] = useState<string>('')
  const [searchHostLookup, setSearchHostLookup] = useState<string>('')
  const [searchCustomerId, setSearchCustomerId] = useState<number | undefined>(undefined)
  const [selectedCustomerId, setSelectedCustomerId] = useState<number | undefined>(undefined)
  const [searchCustomer, setSearchCustomer] = useState<string>('')
  const [selectedServiceId, setSelectedServiceId] = useState<number | undefined>(undefined)

  const [customerOptions, setCustomerOptions] = useState<{ label: string, value: number }[]>([])
  const [nameOptions, setNameOptions] = useState<{ label: string, value: string }[]>([])
  const [hostOptions, setHostOptions] = useState<{ label: string, value: string }[]>([])

  const [pageNumber, setPageNumber] = useState<number>(1)
  const [qsFilter, setQsFilter] = useState<string>('')
  const [loaded, setLoaded] = useState(0)         // when records are loaded
  const [ready, setReady] = useState<number>(0)   // when customers are loaded

  const [isMailServiceModalVisible, setMailServiceModalVisible] = useState<boolean>(false)
  const [isMailDomainModalVisible, setMailDomainModalVisible] = useState<boolean>(false)

  const [isHistoryModalVisible, setHistoryModalVisible] = useState(false)
  const [historyTitle, setHistoryTitle] = useState('')
  const [historyModelId, setHistoryModelId] = useState<number | undefined>()

  const [isMailDomainViewer, setMailDomainViewer] = useState(false)
  const [isMailServiceCreator, setMailServiceCreator] = useState(false)
  const [isMailServiceEditor, setMailServiceEditor] = useState(false)
  const [isMailServiceDeleter, setMailServiceDeleter] = useState(false)
  const [isMailDomainCreator, setMailDomainCreator] = useState(false)

  // get settings and current user
  const loggedUser = useLoggedUser()
  if (!loggedUser || !loggedUser.isLoaded()) {
    // waiting..
    return (
      <div className="fullwidth-loading" >
        <LoadingIndicator />
      </div>
    )
  }

  // required authorization
  if (!loggedUser.hasAccess(CONTROL_NAME)) {
    return <ErrorPage403 />
  }

  // settings
  const appSetting = loggedUser.getAppSettings()
  const SEARCH_MIN = appSetting.min_search_length

  // usage: logger(msg, obj=null)
  const logger = useLogger(appSetting, 'MailDomainsPage')
  const [pageSize, setPageSize] = useState<number>(appSetting.grid_page_size)
  usePageSize(appSetting, loggedUser.user.id, pageSize)

  // history drag modal
  const [disabled, setDisabled] = useState(true)
  const draggleRef = useRef<HTMLDivElement>(null)
  const [bounds, setBounds] = useState({ left: 0, top: 0, bottom: 0, right: 0 })
  const onStart = (_event: DraggableEvent, uiData: DraggableData) => {
    const { clientWidth, clientHeight } = window.document.documentElement
    const targetRect = draggleRef.current?.getBoundingClientRect()
    if (!targetRect) {
      return
    }
    setBounds({
      left: -targetRect.left + uiData.x,
      right: clientWidth - (targetRect.right - uiData.x),
      top: -targetRect.top + uiData.y,
      bottom: clientHeight - (targetRect.bottom - uiData.y),
    })
  }

  useEffect(() => {
    // trigger ONCE
    const access = loggedUser.hasAccess('page_mail_domains')
    setMailDomainViewer(access)
    setMailServiceCreator(loggedUser.hasAccess('page_mail_service_create_button'))
    setMailServiceEditor(loggedUser.hasAccess('page_mail_service_edit_button'))
    setMailServiceDeleter(loggedUser.hasAccess('page_mail_service_delete_button'))
    setMailDomainCreator(loggedUser.hasAccess('page_mail_domains_create_button'))
  }, [])

  useEffect(() => {
    // trigger from Side menu clicked
    logger('mail_services---')
    if (!isLoadingService && isMailDomainViewer) {
      // reload
      fetchRecords(1, pageSize)
    }
  }, [mail_services])


  useEffect(() => {
    // populate nameOptions
    logger('lookup_domain changed: ' + JSON.stringify(lookup_domain))
    setNameOptions(lookup_domain.map(s => ({ label: s, value: s })))
  }, [lookup_domain])

  useEffect(() => {
    // populate hostOptions
    logger('lookup_host changed: ' + JSON.stringify(lookup_host))
    setHostOptions(lookup_host.map(s => ({ label: s, value: s })))
  }, [lookup_host])


  useEffect(() => {
    if (customers) {
      setReady(ready + 1)
      setCustomerOptions(
        customers.map(item => ({
          value: item.id,
          label: item.company?.name!
        }))
      )
    }
  }, [customers])

  useEffect(() => {
    // when filter is changed
    // show the first page
    logger(`qsFilter changed: page: ${pageNumber}, pageSize: ${pageSize}, qs=${qsFilter}`)
    let pn = pageNumber
    if (ready) {    // do not change page for F5
      pn = 1
    }
    fetchRecords(pn, pageSize)
  }, [qsFilter])

  useEffect(() => {
    if (mail_domains.items) {
      logger('mail_domains.items changed: ')
      setSourceFull(mail_domains.items)
    }
  }, [mail_domains, mail_domains.items])

  useEffect(() => {
    // update QsFilter will trigger fetchRecords
    const qs: string = prepareQsFilter()
    logger(`qsFilter:${qs}`)
    if (qs.trim() != qsFilter.trim()) {
      setQsFilter(qs)
    }
  }, [searchName, searchHost, searchCustomerId, selectedCustomerId])

  useEffect(() => {
    // Filters
    logger('sourceFull changed: ' + JSON.stringify(sourceFull))
    if (filterIsValid()) {
      refreshGrid()
    }
  }, [sourceFull])

  const refreshGrid = () => {
    setDataSource(sourceFull)
  }

  const fetchRecords = (pn: number, ps: number) => {
    setPageNumber(pn)
    setPageSize(ps)
    if (!isLoadingService && filterIsValid()) {
      // purchase_from is required
      logger(`fetchRecords: page: ${pn}, pageSize: ${ps}, qs=${qsFilter}`)
      let f = ''
      if (qsFilter) {
        f = '&' + qsFilter
      }
      dispatch(getMailDomains(ps, pn - 1, f, suc => { }))
      setLoaded(1)
    }
  }

  const filterIsValid = (): boolean => {
    // no rules
    return true
  }


  const getQSFilter = (): string[] => {
    let qs: string[] = []
    return qs
  }

  const prepareQsFilter = (): string => {
    // load filtered data from server
    let qs: string[]
    qs = getQSFilter()
    if (searchName && appSetting.checkMinSearch(searchName)) {
      qs.push('name=' + encodeURIComponent(searchName))
    }
    if (searchHost && appSetting.checkMinSearch(searchHost)) {
      qs.push('service[host]=' + encodeURIComponent(searchHost))
    }
    if (searchCustomerId && searchCustomerId > 0) {
      qs.push('customer_id=' + searchCustomerId)
    }
    logger('prepareQsFilter: ' + qs.join("&"))
    return (qs.length === 0) ? '' : qs.join("&")
  }

  const handleCreate = () => {
    setMailServiceModalVisible(true)
  }

  const onClearCustomer = () => {
    setSearchCustomer('')
    setSearchCustomerId(undefined)
  }

  const onSelectCustomer = (v: string) => {
    const cid = parseInt(v)
    if (cid > 0) {
      logger('onSelectCustomer.. ' + v)
      setSearchCustomerId(cid)
      const name = customerNames!.get(cid)
      name && setSearchCustomer(name)
    }
    else {
      setSearchCustomerId(undefined)
      v && setSearchCustomer(v)
    }
  }

  const onChangeCustomerLookup = (data: string) => {
    if (!data) {
      if (searchCustomer.length === 1) {
        setSearchCustomer('')
      }
      return
    }
    if (data != searchCustomer) {
      setSearchCustomer(data)
    }
  }

  const fetchNameLookup = (searchText: string) => {
    // call lookup for name
    searchText = searchText.trim()
    if (appSetting.checkMinSearch(searchText.trim())) {
      let qs: string[]
      qs = getQSFilter()
      if (appSetting.checkMinSearch(searchText)) {
        qs.push('field=name')
        qs.push('value=' + encodeURIComponent(searchText.trim()))
        logger('lookupMailDomain: ' + qs.join("&"))
        dispatch(lookupMailDomain('name', qs.join("&")))
      }
    }
  }

  const onClearName = () => {
    setSearchNameLookup('')
    setSearchName('')
    setNameOptions([])
  }

  const onSelectName = (data: string) => {
    setSearchName(data)
    setPageNumber(1)
  }

  const onChangeNameLookup = (data: string) => {
    if (!data) {
      if (searchNameLookup.length === 1) {
        setSearchNameLookup('')
        fetchNameLookup('')
      }
      return
    }
    if (data != searchNameLookup) {
      setSearchNameLookup(data)
      fetchNameLookup(data)
    }
  }

  const fetchHostLookup = (searchText: string) => {
    // call lookup for mail host
    searchText = searchText.trim()
    if (appSetting.checkMinSearch(searchText.trim())) {
      let qs: string[]
      qs = getQSFilter()
      if (appSetting.checkMinSearch(searchText)) {
        qs.push('field=host')
        qs.push('value=' + encodeURIComponent(searchText.trim()))
        logger('fetchHostLookup: ' + qs.join("&"))
        dispatch(lookupMailService('host', qs.join("&")))
      }
    }
  }

  const onClearHost = () => {
    setSearchHostLookup('')
    setSearchHost('')
    setHostOptions([])
  }

  const onSelectHost = (data: string) => {
    setSearchHost(data)
    setPageNumber(1)
  }

  const onChangeHostLookup = (data: string) => {
    if (!data) {
      if (searchHostLookup.length === 1) {
        setSearchHostLookup('')
        fetchHostLookup('')
      }
      return
    }
    if (data != searchHostLookup) {
      setSearchHostLookup(data)
      fetchHostLookup(data)
    }
  }

  const FilterByName = (
    <AutoComplete
      showSearch
      placeholder={t('mailDomainsPage.domain')}
      style={{ width: '200px' }}
      value={searchNameLookup}
      options={nameOptions}
      dropdownMatchSelectWidth={200}
      onInputKeyDown={(e) => {
        if (e.key === 'Enter') {
          onSelectName(e.currentTarget.value)
        }
      }}
      onSelect={onSelectName}
      onChange={onChangeNameLookup}
      onClear={onClearName}
      onClick={stopPropagation}
      notFoundContent={isLoadingLookup && <Spin />}
      optionFilterProp='label'
      filterOption={false}
      allowClear={true}
    />
  )

  const FilterByHost = (
    <AutoComplete
      showSearch
      placeholder='DB Server'
      style={{ width: '200px' }}
      value={searchHostLookup}
      options={hostOptions}
      dropdownMatchSelectWidth={200}
      onInputKeyDown={(e) => {
        if (e.key === 'Enter') {
          onSelectHost(e.currentTarget.value)
        }
      }}
      onSelect={onSelectHost}
      onChange={onChangeHostLookup}
      onClear={onClearHost}
      onClick={stopPropagation}
      notFoundContent={isLoadingLookup && <Spin />}
      optionFilterProp='label'
      filterOption={false}
      allowClear={true}
    />
  )

  const FilterByCustomer = (
    <AutoComplete
      showSearch
      placeholder={t('certificatesPage.customer')}
      style={{ width: '150px' }}
      value={searchCustomer}
      options={customerOptions}
      dropdownMatchSelectWidth={200}
      onInputKeyDown={(e) => {
        if (e.key === 'Enter') {
          onSelectCustomer(e.currentTarget.value)
        }
      }}
      onSelect={onSelectCustomer}
      onChange={onChangeCustomerLookup}
      onClear={onClearCustomer}
      onClick={stopPropagation}
      optionFilterProp='label'
      filterOption={(input, opt) => removeDiac(opt?.label + '').includes(removeDiac(input))}
      allowClear={true}
    />
  )

  const checkerMX = () => {
    console.log('checkMX')
    dataSource.forEach(rec => {
      console.log('checkMX ' + rec.name)
      dispatch(checkMX(rec.id))
    })
  }

  const columns: ColumnsType<MailDomainModel> = [
    {
      title: FilterByName,
      dataIndex: 'name',
      key: 'name',
      width: '40%',
      align: 'left',
      fixed: 'left',
      render: (name: string, rec: MailDomainModel) => <Link to={`/mail/domain/${rec.id}`}>{rec.name}</Link>
    },
    {
      title: FilterByHost,
      dataIndex: 'service_id',
      key: 'service_id',
      width: '30%',
      render: (name: string, rec: MailDomainModel) => (
        <Tag className='mailServer'>
          {
            isMailServiceEditor && <Link className='linkMail' to={`/mail/services/${rec.service_id}`}>{rec.service?.name}</Link>
          }
          {
            !isMailServiceEditor && <span>{rec.service?.name}</span>
          }
        </Tag>)
    },
    {
      title: FilterByCustomer,
      dataIndex: 'customer_name',
      key: 'customer_name',
      width: '20%',
    },
    {
      title: (<Button type="ghost" onClick={() => {
        checkerMX()
      }}>Check</Button>),
      dataIndex: 'mx_check',
      key: 'mx_check',
      align: 'center',
      width: '20%',
      render: (text: string, record: MailDomainModel) => (
        <Tag title={record.mx} color={record.mx_check === 1 ? 'green' : (record.mx_check === 0 ? 'red' : '')}>{record.mx_check === 1 ? 'OK' : (record.mx_check === 0 ? 'Problem' : '?')}</Tag>
      )
    },
    {
      title: (<div className='center'>Action</div>),
      key: 'action',
      dataIndex: 'action',
      width: '80px',
      align: 'center',
      render: (text: string, record: MailDomainModel) => (
        <Space size={1} onClick={(e) => e.stopPropagation()}>
          <Button title={t('general.btnHistory')} size='small'
            onClick={() => {
              setHistoryModelId(record.id)
              setHistoryTitle(record.name)
              setHistoryModalVisible(true)
            }}
            icon={<InfoCircleTwoTone />}
            className='actionButton'
          />
        </Space>
      ),
    }
  ]


  return (
    <>
      <Card title={<><MailOutlined /> &nbsp;{t('mailDomainsPage.title')}</>}
        loading={isLoadingService}
        className='MailDomainsPage'
        extra={

          <Space>
            <Button type='primary'
              disabled={!isMailDomainCreator}
              onClick={() => setMailDomainModalVisible(true)}>
              <PlusCircleOutlined /> {t('mailDomainsPage.new_domain')}
            </Button>
            <Button type='primary' onClick={handleCreate} disabled={!isMailServiceCreator}>
              <PlusCircleOutlined /> {t('mailDomainsPage.new_mail_server')}
            </Button>
          </Space>

        }
      >
        <Table<MailDomainModel>
          columns={columns}
          dataSource={dataSource}
          scroll={{ x: 'max-content' }}
          rowKey='id'
          bordered={true}
          className='MailDomainsTable'
          loading={isLoadingService}
          showHeader={true}
          size='small'
          pagination={false}
          footer={() => Pager({
            filename: 'fn',
            total: mail_domains_pager.totalCount,
            current: mail_domains_pager.page,
            pageSize: mail_domains_pager.pageSize,
            data: dataSource,
            fetchRecords: fetchRecords
          })}
          onChange={(ev) => {
            ev.pageSize && setPageSize(ev.pageSize)
          }}
        />

      </Card>

      <Modal title={
        <div style={{ width: '100%', cursor: 'move' }}
          onMouseOver={() => { if (disabled) { setDisabled(false) } }}
          onMouseOut={() => { setDisabled(true) }}
          onFocus={() => { }}
          onBlur={() => { }}
        >
          <MailOutlined /> &nbsp; {t('mailDomainsPage.new_mail_server')}
        </div>
      }
        destroyOnClose
        style={{ top: 20 }}
        width={600}
        visible={isMailServiceModalVisible}
        onCancel={() => setMailServiceModalVisible(false)}
        modalRender={(modal) => (
          <Draggable disabled={disabled} bounds={bounds} onStart={(ev, data) => onStart(ev, data)}>
            <div ref={draggleRef}>{modal}</div>
          </Draggable>
        )}
        footer={null}
        confirmLoading={true}
      >
        <CreateMailServiceForm serviceId={selectedServiceId} onClose={() => setMailServiceModalVisible(false)} />
      </Modal>

      <Modal title={
        <div style={{ width: '100%', cursor: 'move' }}
          onMouseOver={() => { if (disabled) { setDisabled(false) } }}
          onMouseOut={() => { setDisabled(true) }}
          onFocus={() => { }}
          onBlur={() => { }}
        >
          <MailOutlined /> &nbsp; {t('mailDomainsPage.new_domain')}
        </div>
      }
        destroyOnClose
        style={{ top: 20 }}
        width={600}
        visible={isMailDomainModalVisible}
        onCancel={() => setMailDomainModalVisible(false)}
        modalRender={(modal) => (
          <Draggable disabled={disabled} bounds={bounds} onStart={(ev, data) => onStart(ev, data)}>
            <div ref={draggleRef}>{modal}</div>
          </Draggable>
        )}
        footer={null}
        confirmLoading={true}
      >
        <CreateMailDomainForm onClose={() => {
          setMailDomainModalVisible(false)
        }}
        />
      </Modal>

      <HistoryModal service='mail' model='ServiceDomain'
        modelId={historyModelId}
        title={historyTitle}
        isModalVisible={isHistoryModalVisible}
        setModalVisible={() => setHistoryModalVisible(false)}
        modalRender={(modal) => (
          <Draggable bounds={bounds} onStart={(ev, data) => onStart(ev, data)}>
            <div ref={draggleRef}>{modal}</div>
          </Draggable>
        )}
      />
    </>
  )
}

export default MailDomainsPage
