import React, { useEffect, useState } from 'react'
import { Col, Form, Input, InputNumber, message, Radio, Row, Select, Space, Typography, } from 'antd'
import { useTranslation } from 'react-i18next'
import { AppState } from 'common/models'
import { useDispatch, useSelector } from 'react-redux'
import { formItemLayout, labelTopLayout } from 'helpers/layoutHelpers'
import Button from 'antd-button-color'
import DnsRecordArchivedDropdown from './DnsRecordArchivedDropdown'
import { useForm } from 'antd/es/form/Form'
import { AddDnsRecordParams, DNSType, RemoveDnsRecordParams } from "./models"
import addDnsRecord from "./actions/addDnsRecord"
import {
    _addLastComma,
    _isValidDomain,
    _isValidIP4,
    _isValidIP6,
    _remLastComma,
    onKeyPressOnlyNum
} from "../../../common/fce"
import getZone from "./actions/getZone"


interface Props {
    zone: string
    digest?: string
    name?: string
    type?: string
    content?: string
    ttl?: number
    is_new?: Boolean
    closeModal: () => void
}

const { Item } = Form


interface RTOption {
    label: string
    value: string
    description: string
    rule: string
    disabled: boolean
}

export const dnsRecordTypes: RTOption[] = [
    {
        label: 'A',
        value: 'A',
        description: 'Host address',
        rule: 'IPv4',
        disabled: false
    },
    {
        label: 'AAAA',
        value: 'AAAA',
        description: 'IPv6 host address',
        rule: 'IPv6',
        disabled: false
    },
    {
        label: 'CNAME',
        value: 'CNAME',
        description: 'Canonical name for an alias',
        rule: 'Domain name',
        disabled: false
    },
    {
        label: 'MX',
        value: 'MX',
        description: 'Mail',
        rule: 'Mail server',
        disabled: false
    },
    {
        label: 'NS',
        value: 'NS',
        description: 'Name Server',
        rule: 'Name server address',
        disabled: false
    },
    { label: 'PTR', value: 'PTR', description: 'Pointer', rule: 'Pointer', disabled: false },
    {
        label: 'SOA',
        value: 'SOA',
        description: 'SOA record',
        rule: 'SOA',
        disabled: false
    },
    {
        label: 'SRV',
        value: 'SRV',
        description: 'location of service',
        rule: 'Location',
        disabled: false
    },
    {
        label: 'TXT',
        value: 'TXT',
        description: 'Descriptive text',
        rule: 'Text value',
        disabled: false
    },
]

export const isValidRecord = (type: DNSType, value: string) => {
    if (!value || !value.trim()) {
        return true
    }
    value = value.trim()
    if (type === DNSType.NS) {
        return _isValidDomain(value) || _isValidIP4(value) || _isValidIP6(value)
    }
    if (type === DNSType.A) {
        return _isValidIP4(value)
    }
    if (type === DNSType.AAAA) {
        return _isValidIP6(value)
    }
    if (type === DNSType.MX) {
        return _isValidDomain(value) || _isValidIP4(value) || _isValidIP6(value)
    }
    if (type === DNSType.CNAME) {
        return _isValidDomain(value)
    }
    return true
}


const ZoneEditRecordForm = (props: Props) => {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const [form] = useForm()

    const { dnsZone, dnsService } = useSelector((state: AppState) => state.dnsservice)

    const [selectedType, setSelectedType] = useState<RTOption>()
    const [dnsRecordOptions, setDnsRecordOptions] = useState<RTOption[]>(dnsRecordTypes)

    useEffect(() => {
        if (!dnsZone || dnsZone.zone != _addLastComma(props.zone)) {
            message.error('Wrong zone: ' + props.zone)
            //props.closeModal()
            //return
        }

        if (!props.is_new && props.type) {
            // EDIT
            setSelectedType(dnsRecordTypes.find(t => t.value === props.type?.toUpperCase()))
        }
        else {
            // CREATE
            setDnsRecordOptions(dnsRecordTypes.filter(t => t.value !== 'SOA'))
        }
    }, [])

    const handleSubmit = (values) => {
        if (!dnsZone) {
            message.error('Missing zone.')
            return
        }
        if (!dnsService) {
            message.error('Missing DNS service.')
            return
        }
        let params: AddDnsRecordParams = {
            id: dnsService.id,
            digest: values.digest,
            zone: _remLastComma(dnsZone.zone),
            name: values.name,
            type: values.type,
            content: values.content,
            ttl: values.ttl
        }
        if (selectedType && selectedType.value === 'MX') {
            params = { ...params, content: `${values.priority} ${values.content}` }
        }
        dispatch(addDnsRecord(params, suc => {
            if (suc) {
                dispatch(getZone(dnsService.id, _remLastComma(dnsZone.zone)))
                message.success(t('general.success'))
                props.closeModal()
            }
        }))
    }

    return (
        <Form
            {...formItemLayout}
            form={form}
            onFinish={handleSubmit}
            initialValues={{
                domain: dnsZone?.zone,
                digest: props.digest,
                type: props.type,
                name: props.name,
                content: props.content,
                ttl: props.ttl ? props.ttl : 1800
            }}
        >
            <Item name='service_id' style={{ display: 'none' }}>
                <Input type='hidden' />
            </Item>
            <Item name='domain' style={{ display: 'none' }} >
                <Input type='hidden' />
            </Item>

            <Item name='digest' style={{ display: 'none' }} >
                <Input type='hidden' />
            </Item>

            <Row gutter={8}>
                <Col span={24}>
                    <Item name='type' label={t('dnsPage.dnsRecordForm.input_type')} rules={[{ required: true }]}>
                        <Select optionFilterProp='label'
                            style={{ width: '100px' }}
                            disabled={!props.is_new}
                            options={dnsRecordOptions}
                            onChange={(value) => {
                                setSelectedType(dnsRecordTypes.find(t => t.value === value.toUpperCase()))
                            }}
                        />
                    </Item>
                </Col>

                <Col span={24}>
                    <Item name='name'
                        rules={[{ required: true }]}
                        label={t('dnsPage.dnsRecordForm.input_name')}>
                        <Input data-gramm="false" disabled={!props.is_new} />
                    </Item>
                </Col>

                {
                    selectedType && selectedType.value === 'MX' && (
                        <Col span={24}>
                            <Item name='priority'
                                rules={[{ required: true }]}
                                label={t('dnsPage.dnsRecordForm.priority')}>
                                <Input type='number' min={1} data-gramm="false" />
                            </Item>
                        </Col>
                    )
                }

                <Col span={24}>
                    <Item name='content'
                        rules={[
                            {
                                required: true,
                                message: `Invalid content: ${selectedType?.rule}`,
                                validator: async (rule, value) => {
                                    const t = form.getFieldValue('type')
                                    const isValid = isValidRecord(t, value)
                                    if (!isValid) {
                                        throw new Error('Something wrong!')
                                    }
                                }
                            },
                        ]}
                        label={t('dnsPage.dnsRecordForm.input_content')}
                    >
                        <Input disabled={selectedType && selectedType.value === 'SOA'}
                            placeholder={selectedType?.rule} data-gramm="false" />
                    </Item>
                </Col>

                <Col span={24}>
                    <Item name='ttl'
                        rules={[{ required: true }]}
                        label={t('dnsPage.dnsRecordForm.input_ttl')}
                    >
                        <InputNumber
                            min={1}
                            step={1}
                            onKeyPress={onKeyPressOnlyNum}
                            style={{ width: '100%' }}
                            placeholder='3600'
                        />
                    </Item>
                </Col>

                <Col span={12}>

                    <DnsRecordArchivedDropdown
                        onSelect={(record) => form.setFieldsValue(record)}
                    />

                </Col>
                <Col span={12}>
                    <Item>
                        <Button type='primary' htmlType='submit'>
                            {props.is_new ? t('dnsPage.dnsRecordForm.button_create') : t('dnsPage.dnsRecordForm.button_update')}
                        </Button>
                    </Item>
                </Col>

            </Row>
        </Form>
    )
}

export default ZoneEditRecordForm
