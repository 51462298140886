import { GetVhostRequestAction, GetVhostResponseAction, GetVhostErrorAction } from './index'
import { protectedApiClient } from 'helpers/api'
import { handleApiErrorWithNotification } from 'helpers/errorHandling'
import { VhostModel } from '../models'

export default (id: number, cb?: (isSuccess: boolean) => void) => {
	return (dispatch: (arg: GetVhostRequestAction | GetVhostResponseAction | GetVhostErrorAction) => void) => {
		const request = new GetVhostRequestAction(id)
		dispatch(request)

		protectedApiClient
			.get<VhostModel>(`/web/vhost/${id}?expand=service,customer,domain,is_config_applied`)
			.then((response) => {
				dispatch(new GetVhostResponseAction(request, response.data))
				cb && cb(true)
			})
			.catch((error) => {
				dispatch(new GetVhostErrorAction(request, error.toString()))
				handleApiErrorWithNotification(error)
				cb && cb(false)
			})
	}
}
