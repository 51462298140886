import React, { useEffect, useState } from 'react'
import {
    Button,
    Form,
    Input,
    InputNumber,
    Switch,
    Select,
    Collapse,
    Space,
    Row,
    Col,
    message,
    Upload,
    UploadProps, Spin, Progress
} from 'antd'
import { useTranslation } from 'react-i18next'
import { useForm } from 'antd/lib/form/Form'
import { formItemLayout, tailLayout } from 'helpers/layoutHelpers'
import {
    CertificateModel,
    CreateCertificateParams,
    CHALLENGE_ENUM, UploadCertificateParams,
} from 'pages/certificate/models'
import { CheckOutlined, CloseOutlined, UploadOutlined } from '@ant-design/icons'
import { _isValidDomain, ValidateParams } from "../../common/fce"
import { useDispatch, useSelector } from "react-redux"
import { AppState } from "../../common/models"
import { Store } from "antd/lib/form/interface"
import tableCustomers from "../login/actions/tableCustomers"
import { useLoggedUser } from "../../helpers/loginUserHelper"
import { LoadingIndicator } from "../../components"
import { sort_label } from "../../common/sorting"
import createCertificate from "./actions/createCertificate"
import uploadCertificate from "./actions/uploadCertificate"


const { Item } = Form
const { TextArea } = Input
const { Panel } = Collapse

interface Props {
    closeModal: () => void
}

export const NewCertificateForm = ({ closeModal }: Props) => {
    const [form] = useForm()
    const { t } = useTranslation()
    const dispatch = useDispatch()

    const { customers } = useSelector((state: AppState) => state.auth.tables)
    const { isSaving } = useSelector((state: AppState) => state.certificate)

    const [customerOptions, setCustomerOptions] = useState<{ label: string, value: number }[]>([])
    const [selectedCustomerId, setSelectedCustomerId] = useState<number | undefined>()
    const [domainField, setDomainField] = useState<ValidateParams>({ value: '', validateStatus: 'success', errorMsg: '' })
    const [isFormValid, setFormValid] = useState<boolean>(false)
    const [autoProlong, setAutoProlong] = useState<boolean>(false)
    const [uploadZip, setUploadZip] = useState<boolean>(false)
    const [progress, setProgress] = useState(0)
    const [content, setContent] = useState<string>('')


    // get settings and logged user from store
    const loggedUser = useLoggedUser()
    if (!loggedUser || !loggedUser.isLoaded()) {
        return (
            <div className="fullwidth-loading">
                <LoadingIndicator />
            </div>
        )
    }
    const appSetting = loggedUser.getAppSettings()

    useEffect(() => {
        if (customers.length === 0) {
            dispatch(tableCustomers())
        }
        setAutoProlong(true)
    }, [])

    useEffect(() => {
        setCustomerOptions(customers.map(c => ({ label: c.company!.name, value: c.id })).sort(sort_label))
    }, [customers])


    const checkDomain = (dom: string) => {
        let dom2 = dom.replace('*.', '')
        if (!dom2) {
            setDomainField({
                value: dom,
                validateStatus: 'success',
                errorMsg: ''
            })
            setFormValid(false)
            return true
        }
        const result = _isValidDomain(dom2)
        if (result) {
            setDomainField({
                value: dom,
                validateStatus: 'success',
                errorMsg: ''
            })
            setFormValid(true)
            return true
        }
        else {
            setDomainField({
                value: dom,
                validateStatus: 'error',
                errorMsg: 'Invalid domain'
            })
            setFormValid(false)
            return false
        }
    }

    const handleSubmit = (values: Store): void => {
        if (uploadZip) {
            const params: UploadCertificateParams = {
                customer_id: values.customer_id,
                name: values.name,
                challenge: values.challenge,
                content: content,
                auto_prolong: autoProlong ? 1 : 0,
                prolong_before_days: autoProlong ? values.prolong_before_days : 0,
                comment: values.comment
            }
            dispatch(uploadCertificate(params, suc => {
                if (suc) {
                    message.success(t('general.success'))
                    closeModal()
                }
            }))
            return
        }

        const params: CreateCertificateParams = {
            customer_id: values.customer_id,
            name: values.name,
            challenge: values.challenge,
            crt: values.crt,
            key: values.key,
            ca_crt: values.ca_crt,
            auto_prolong: autoProlong ? 1 : 0,
            prolong_before_days: autoProlong ? values.prolong_before_days : 0,
            comment: values.comment
        }
        dispatch(createCertificate(params, suc => {
            if (suc) {
                message.success(t('general.success'))
                closeModal()
            }
        }))
    }

    const beforeUpload = (file) => {
        const isZip = file.type === "application/zip" || file.name.endsWith(".zip")
        if (!isZip) {
            message.error("You can only upload ZIP files!")
        }
        return isZip || Upload.LIST_IGNORE // Block upload if not ZIP
    }

    const handleChange = (info) => {
        if (info.file.status === "uploading") {
            setProgress(Math.round((info.event?.percent || 0))) // Update progress
        }

        if (info.file.status === "done" || info.file.status === "error") {
            const file = info.file.originFileObj
            if (file) {
                const reader = new FileReader()
                reader.onload = (e) => {
                    const binaryData: string | ArrayBuffer | null | undefined = e.target?.result
                    if (binaryData && binaryData instanceof ArrayBuffer) {
                        const base64 = btoa(
                            new Uint8Array(binaryData).reduce(
                                (data, byte) => data + String.fromCharCode(byte),
                                ""
                            )
                        )
                        setContent(base64)
                        setProgress(100)
                    }
                }
                reader.onerror = () => {
                    message.error("Failed to read the file!")
                    setProgress(0)
                }
                reader.readAsArrayBuffer(file) // Read the file as binary
            }
        }
    }

    return (
        <Form {...formItemLayout} form={form}
            onFinish={handleSubmit}
            className='NewCertForm'
            initialValues={{
                customer_id: loggedUser.user.customer_id,
                auto_prolong: true,
                prolong_before_days: 20,
                challenge: CHALLENGE_ENUM.CHALLENGE_DNS
            }}>

            <Form.Item name='customer_id' label={t('domainPage.customer')}
                rules={[{ required: true, message: t('errors.field_required') }]}
            >
                <Select optionFilterProp='label'
                    style={{ width: '100%' }}
                    options={customerOptions}
                    disabled={!loggedUser.user.is_zcom}
                    onChange={(value: number) => {
                        setSelectedCustomerId(value)
                    }}
                />
            </Form.Item>

            <Form.Item
                name='name' label={t('certificatesPage.name')}
                rules={[
                    { required: true, message: t('errors.field_required') },
                    () => ({
                        validator(_, value) {
                            if (_isValidDomain(value)) {
                                return Promise.resolve()
                            }
                            return Promise.reject(new Error(t('domainPage.invalid_domain')))
                        },
                    })
                ]}
                hasFeedback>
                <Input onBlur={(e) => checkDomain(e.target.value)}
                    onChange={(e) => checkDomain(e.target.value)}
                    placeholder='domain'
                    data-gramm="false"
                    data-gramm_editor="false"
                    data-enable-grammarly="false" />
            </Form.Item>

            <Form.Item
                name='challenge'
                rules={[{ required: true, message: t('certificatesPage.error.challenge') }]}
                label={t('certificatesPage.challenge')}>
                <Select options={Object.values(CHALLENGE_ENUM).map((val) => ({ label: val, value: val }))} />
            </Form.Item>

            <Row style={{ marginBottom: '15px' }}>
                <Col span={8} style={{ textAlign: 'right' }}>&nbsp;</Col>
                <Col span={16} style={{ textAlign: 'center' }}>{t('certificatesPage.copy_or_upload')}&nbsp;&nbsp;</Col>
            </Row>

            <Row style={{ marginBottom: '15px' }}>
                <Col span={8} style={{ textAlign: 'right' }}>&nbsp;&nbsp;</Col>
                <Col span={16} style={{ textAlign: 'center' }}>
                    {t('certificatesPage.upload_zip')}:&nbsp;
                    <Switch size='small'
                        checkedChildren={<CheckOutlined />}
                        unCheckedChildren={<CloseOutlined />}
                        checked={uploadZip}
                        onChange={() => { setUploadZip(!uploadZip) }}
                    />
                </Col>
            </Row>

            {
                !uploadZip && (
                    <Row>
                        <Col span={24}>
                            <Form.Item name='key' label={t('certificatesPage.key')}
                                labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}>
                                <TextArea data-gramm="false" data-gramm_editor="false" data-enable-grammarly="false" />
                            </Form.Item>
                            <Form.Item name='crt' label={t('certificatesPage.crt')}
                                labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}>
                                <TextArea data-gramm="false" data-gramm_editor="false" data-enable-grammarly="false" />
                            </Form.Item>
                            <Form.Item name='ca_crt' label={t('certificatesPage.ca_crt')}
                                labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}>
                                <TextArea data-gramm="false" data-gramm_editor="false" data-enable-grammarly="false" />
                            </Form.Item>
                        </Col>
                    </Row>
                )
            }
            {
                uploadZip && (
                    <Row>
                        <Col span={8} style={{ textAlign: 'right' }}>&nbsp;</Col>
                        <Col span={16} style={{ textAlign: 'center', height: 98 }}>
                            <Upload
                                accept=".zip"
                                beforeUpload={beforeUpload}
                                onChange={handleChange}
                                showUploadList={false} // Hide the file list if not needed
                            >
                                <Button icon={<UploadOutlined />}>Upload ZIP File</Button>
                            </Upload>
                            {progress > 0 && (
                                <Progress percent={progress} status={progress === 100 ? "success" : "active"} />
                            )}
                        </Col>
                    </Row>
                )
            }

            <Row>
                <Col span={8} className='right pad6'>{t('certificatesPage.auto_prolong')}:&nbsp;</Col>
                <Col span={8}>
                    <Item name='auto_prolong' valuePropName='checked'>
                        <Switch
                            checkedChildren={<CheckOutlined />}
                            unCheckedChildren={<CloseOutlined />}
                            checked={autoProlong}
                            onChange={() => { setAutoProlong(!autoProlong) }}
                        />
                    </Item>
                </Col>
                <Col span={8}>
                    {
                        autoProlong && (
                            <Item name='prolong_before_days' label={t('certificatesPage.prolong_before_days')}>
                                <InputNumber style={{ width: 60 }} />
                            </Item>
                        )
                    }
                </Col>
            </Row>

            <Form.Item name='comment' label={t('certificatesPage.comment')}>
                <TextArea cols={5} style={{ minHeight: '100px' }} />
            </Form.Item>

            <Form.Item {...tailLayout}>
                <Button type='primary' htmlType='submit' loading={isSaving}>
                    {t('general.save')}
                </Button>
            </Form.Item>
        </Form>
    )
}

export default NewCertificateForm


