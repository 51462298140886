import {ActionType, ServerActions} from './actions'
import {AsyncActionMode, NameItem, PAGE1} from 'common/models'
import {ServerModel, ServersPageState, StatusPlan} from './models'

const SERVER_INITIAL_STATE: ServersPageState = {
	isLoading: false,
	isSaving: false,
	isLoadingServers: false,
	isLoadingPhysicalServers: false,
	isLoadingIps: false,
	isSavingPlan: false,
	isLoadingPlan: false,
	error: undefined,
	servers: {items: [], pagination: PAGE1},
	pager: PAGE1,
	ip_addresses: [],
	ip: undefined,
	physical_server: undefined,
	physical_servers: [],
	hypervisor: undefined,
	vps_servers: [],
	status_plans: [],
	service_types: [],
	server_types: [],
	locations: [],
	managements: [],
	notifications: [],
	backup_servers: [],
	monitorings: [],
	lookup_hostname: [],
	isLoadingLookup: false
}

export default (state = SERVER_INITIAL_STATE, action: ServerActions): ServersPageState => {
	switch (action.type) {
		case ActionType.ADD_IP_TO_SERVER:
			if (action.mode === AsyncActionMode.REQUEST) {
				return {...state, isLoadingIps: true}
			}
			if (action.mode === AsyncActionMode.RESPONSE) {
				return {
					...state,
					isLoadingIps: false,
					server: action.data,
					servers: {
						...state.servers,
						items: state.servers.items.map(
							(s) => s.id === action.data.id ? action.data : s,
						)},
				}
			}
			if (action.mode === AsyncActionMode.ERROR) {
				return {
					...state,
					isLoadingIps: false,
					error: action.error,
				}
			}
			break
		case ActionType.REMOVE_IP_FROM_SERVER:
			if (action.mode === AsyncActionMode.REQUEST) {
				return {...state, isLoadingIps: true}
			}
			if (action.mode === AsyncActionMode.RESPONSE) {
				return {
					...state,
					isLoadingIps: false,
					server: action.data,
					servers: {
						...state.servers,
						items: state.servers.items.map(
							(s) => s.id === action.request.payload.server_id ? {
								...s,
								ips: s.ips?.filter((a) => a.id !== action.request.payload.ip_id),
							} : s,
						)},
				}
			}
			if (action.mode === AsyncActionMode.ERROR) {
				return {
					...state,
					isLoadingIps: false,
					error: action.error,
				}
			}
			break
		case ActionType.GET_SERVER:
			if (action.mode === AsyncActionMode.REQUEST) {
				return {...state, isLoading: true}
			}
			if (action.mode === AsyncActionMode.RESPONSE) {
				return {
					...state,
					isLoading: false,
					server: action.data,
				}
			}
			if (action.mode === AsyncActionMode.ERROR) {
				return {
					...state,
					isLoading: false,
					error: action.error,
				}
			}
			break
		case ActionType.GET_HYPERVISOR:
			if (action.mode === AsyncActionMode.REQUEST) {
				return {...state, isLoadingPhysicalServers: true}
			}
			if (action.mode === AsyncActionMode.RESPONSE) {
				return {
					...state,
					isLoadingPhysicalServers: false,
					hypervisor: action.data,
				}
			}
			if (action.mode === AsyncActionMode.ERROR) {
				return {
					...state,
					isLoadingPhysicalServers: false,
					error: action.error,
				}
			}
			break
		case ActionType.GET_PHYS_SERVER:
			if (action.mode === AsyncActionMode.REQUEST) {
				return {...state, physical_server: undefined, isLoadingPhysicalServers: true}
			}
			if (action.mode === AsyncActionMode.RESPONSE) {
				return {
					...state,
					isLoadingPhysicalServers: false,
					physical_server: action.data,
				}
			}
			if (action.mode === AsyncActionMode.ERROR) {
				return {
					...state,
					isLoadingPhysicalServers: false,
					error: action.error,
				}
			}
			break
		case ActionType.CREATE_SERVER:
			if (action.mode === AsyncActionMode.REQUEST) {
				return {...state, isSaving: true}
			}
			if (action.mode === AsyncActionMode.RESPONSE) {
				// add to first pos.
				let new_list = [...state.servers.items]  // new obj
				new_list.unshift(action.data)
				return {
					...state,
					servers: {
						...state.servers,
						items: new_list
					},
					isSaving: false,
				}
			}
			if (action.mode === AsyncActionMode.ERROR) {
				return {
					...state,
					isSaving: false,
					error: action.error,
				}
			}
			break
		case ActionType.GET_SERVERS:
			if (action.mode === AsyncActionMode.REQUEST) {
				return {...state, isLoadingServers: true}
			}
			if (action.mode === AsyncActionMode.RESPONSE) {
				return {
					...state,
					isLoadingServers: false,
					servers: action.data,
					pager: {...action.data.pagination, page: action.data.pagination.page+1},
				}
			}
			if (action.mode === AsyncActionMode.ERROR) {
				return {
					...state,
					isLoadingServers: false,
					error: action.error,
				}
			}
			break
		case ActionType.GET_IP_LIST:
			if (action.mode === AsyncActionMode.REQUEST) {
				return {...state,
					ip_addresses: [],
					isLoadingIps: true}
			}
			if (action.mode === AsyncActionMode.RESPONSE) {
				return {
					...state,
					isLoadingIps: false,
					ip_addresses: action.data,
				}
			}
			if (action.mode === AsyncActionMode.ERROR) {
				return {
					...state,
					isLoadingIps: false,
					error: action.error,
				}
			}
			break
		case ActionType.GET_HYPERVISORS:
			if (action.mode === AsyncActionMode.REQUEST) {
				return {...state,
					vps_servers: [],
					isLoadingPhysicalServers: true}
			}
			if (action.mode === AsyncActionMode.RESPONSE) {
				return {
					...state,
					isLoadingPhysicalServers: false,
					vps_servers: action.data,
				}
			}
			if (action.mode === AsyncActionMode.ERROR) {
				return {
					...state,
					isLoadingPhysicalServers: false,
					error: action.error,
				}
			}
			break

		case ActionType.LIST_PHYSICAL_SERVERS:
			if (action.mode === AsyncActionMode.REQUEST) {
				return {...state, isLoadingPhysicalServers: true}
			}
			if (action.mode === AsyncActionMode.RESPONSE) {
				return {
					...state,
					isLoadingPhysicalServers: false,
					physical_servers: action.data,
				}
			}
			if (action.mode === AsyncActionMode.ERROR) {
				return {
					...state,
					isLoadingPhysicalServers: false,
					error: action.error,
				}
			}
			break
		case ActionType.DELETE_SERVER:
			if (action.mode === AsyncActionMode.REQUEST) {
				return {...state, isSaving: true}
			}
			if (action.mode === AsyncActionMode.RESPONSE) {
				return {
					...state,
					servers: {
						...state.servers,
						items: state.servers.items.filter(
							(s) => s.id !== action.request.id,
						)},
					isSaving: false,
				}
			}
			if (action.mode === AsyncActionMode.ERROR) {
				return {
					...state,
					isSaving: false,
					error: action.error,
				}
			}
			break

		case ActionType.UPDATE_SERVER:
			if (action.mode === AsyncActionMode.REQUEST) {
				return {...state, isSaving: true}
			}
			if (action.mode === AsyncActionMode.RESPONSE) {
				return {
					...state,
					server: action.data,
					servers: {
						...state.servers,
						items: state.servers.items.map(
							(s) => s.id === action.data.id ? action.data : s,
						)},
					isSaving: false,
				}
			}
			if (action.mode === AsyncActionMode.ERROR) {
				return {
					...state,
					isSaving: false,
					error: action.error,
				}
			}
			break

		case ActionType.GET_SERVER_TYPE:
			if (action.mode === AsyncActionMode.REQUEST) {
				return {...state, isLoading: true}
			}
			if (action.mode === AsyncActionMode.RESPONSE) {
				return {
					...state,
					isLoading: false,
					server_types: action.data,
				}
			}
			if (action.mode === AsyncActionMode.ERROR) {
				return {
					...state,
					isLoading: false,
					error: action.error,
				}
			}
			break
		case ActionType.GET_SERVER_SERVICE_TYPE:
			if (action.mode === AsyncActionMode.REQUEST) {
				return {...state, isLoading: true}
			}
			if (action.mode === AsyncActionMode.RESPONSE) {
				return {
					...state,
					isLoading: false,
					service_types: action.data,
				}
			}
			if (action.mode === AsyncActionMode.ERROR) {
				return {
					...state,
					isLoading: false,
					error: action.error,
				}
			}
			break
		case ActionType.GET_BACKUP_SERVERS:
			if (action.mode === AsyncActionMode.REQUEST) {
				return {...state, isLoading: true}
			}
			if (action.mode === AsyncActionMode.RESPONSE) {
				return {
					...state,
					isLoading: false,
					backup_servers: action.data,
				}
			}
			if (action.mode === AsyncActionMode.ERROR) {
				return {
					...state,
					isLoading: false,
					error: action.error,
				}
			}
			break
		case ActionType.GET_SERVER_MONITORING_TYPE:
			if (action.mode === AsyncActionMode.REQUEST) {
				return {...state, isLoading: true}
			}
			if (action.mode === AsyncActionMode.RESPONSE) {
				return {
					...state,
					isLoading: false,
					monitorings: action.data,
				}
			}
			if (action.mode === AsyncActionMode.ERROR) {
				return {
					...state,
					isLoading: false,
					error: action.error,
				}
			}
			break
		case ActionType.GET_MANAGEMENT_TYPE:
			if (action.mode === AsyncActionMode.REQUEST) {
				return {...state, isLoading: true}
			}
			if (action.mode === AsyncActionMode.RESPONSE) {
				return {
					...state,
					isLoading: false,
					managements: action.data,
				}
			}
			if (action.mode === AsyncActionMode.ERROR) {
				return {
					...state,
					isLoading: false,
					error: action.error,
				}
			}
			break
		case ActionType.GET_NOTIFICATIONS:
			if (action.mode === AsyncActionMode.REQUEST) {
				return {...state, isLoading: true}
			}
			if (action.mode === AsyncActionMode.RESPONSE) {
				return {
					...state,
					isLoading: false,
					notifications: action.data,
				}
			}
			if (action.mode === AsyncActionMode.ERROR) {
				return {
					...state,
					isLoading: false,
					error: action.error,
				}
			}
			break
		case ActionType.LOOKUP_SERVER:
			if (action.mode === AsyncActionMode.REQUEST) {
				return { ...state,
					isLoadingLookup: true,
				}
			}
			if (action.mode === AsyncActionMode.RESPONSE) {
				if (action.field === 'hostname') {
					return {
						...state,
						lookup_hostname: action.data,
						isLoadingLookup: false
					}
				}
				console.error('Wrong action field.')
			}
			if (action.mode === AsyncActionMode.ERROR) {
				return {
					...state,
					error: action.error,
					isLoadingLookup: false
				}
			}
			break
		case ActionType.GET_IP_SERVER:
			if (action.mode === AsyncActionMode.REQUEST) {
				return { ...state,
					isLoadingIps: true,
				}
			}
			if (action.mode === AsyncActionMode.RESPONSE) {
				return {
					...state,
					ip: action.data,
					isLoadingIps: false
				}
			}
			if (action.mode === AsyncActionMode.ERROR) {
				return {
					...state,
					error: action.error,
					isLoadingIps: false
				}
			}
			break
		case ActionType.UPDATE_IP_COMMENT:
			if (action.mode === AsyncActionMode.REQUEST) {
				return {...state, isSaving: true}
			}
			if (action.mode === AsyncActionMode.RESPONSE) {
				const newIps = (state.server?.ips) ? state.server.ips.map((ip) => ip.id === action.data.id ? action.data : ip) : []
				return {
					...state,
					ip: action.data,
					server: state.server ? {...state.server, ips: newIps} : undefined,
					isSaving: false,
				}
			}
			if (action.mode === AsyncActionMode.ERROR) {
				return {
					...state,
					isSaving: false,
					error: action.error,
				}
			}
			break
		case ActionType.GET_STATUS_PLAN:
			if (action.mode === AsyncActionMode.REQUEST) {
				return { ...state,
					isLoadingPlan: true,
				}
			}
			if (action.mode === AsyncActionMode.RESPONSE) {
				return {
					...state,
					status_plans: action.data,
					isLoadingPlan: false
				}
			}
			if (action.mode === AsyncActionMode.ERROR) {
				return {
					...state,
					error: action.error,
					isLoadingPlan: false
				}
			}
			break
		case ActionType.UPDATE_STATUS_PLAN:
			if (action.mode === AsyncActionMode.REQUEST) {
				return {...state, isSavingPlan: true}
			}
			if (action.mode === AsyncActionMode.RESPONSE) {
				return {
					...state,
					status_plans: state.status_plans.map(
							(s) => s.id === action.data.id ? action.data : s,
						),
					isSavingPlan: false,
				}
			}
			if (action.mode === AsyncActionMode.ERROR) {
				return {
					...state,
					isSavingPlan: false,
					error: action.error,
				}
			}
			break
		case ActionType.CREATE_STATUS_PLAN:
			if (action.mode === AsyncActionMode.REQUEST) {
				return {...state, isSavingPlan: true}
			}
			if (action.mode === AsyncActionMode.RESPONSE) {
				return {
					...state,
					status_plans: state.status_plans.concat(action.data),
					isSavingPlan: false,
				}
			}
			if (action.mode === AsyncActionMode.ERROR) {
				return {
					...state,
					isSavingPlan: false,
					error: action.error,
				}
			}
			break
		case ActionType.DELETE_STATUS_PLAN:
			if (action.mode === AsyncActionMode.REQUEST) {
				return {...state, isSavingPlan: true}
			}
			if (action.mode === AsyncActionMode.RESPONSE) {
				return {
					...state,
					status_plans: state.status_plans.filter((s) => s.id !== action.request.id),
					isSavingPlan: false,
				}
			}
			if (action.mode === AsyncActionMode.ERROR) {
				return {
					...state,
					isSavingPlan: false,
					error: action.error,
				}
			}
			break
	}
	return state
}
