import { GetDocsSearchRequestAction, GetDocsSearchResponseAction, GetDocsSearchErrorAction } from '.'
import { protectedApiClient } from 'helpers/api'
import { handleApiErrorWithNotification } from 'helpers/errorHandling'
import { SectionItem } from '../models'

export default (searchQuery: string, cb?: (isSuccess: boolean) => void) => {
	return (
		dispatch: (args: GetDocsSearchRequestAction | GetDocsSearchResponseAction | GetDocsSearchErrorAction) => void
	) => {
		const request = new GetDocsSearchRequestAction()
		dispatch(request)

		protectedApiClient
			.get<SectionItem[]>(`/zdoc/search?lang=cs&query=${encodeURIComponent(searchQuery)}`)
			.then((response) => {
				dispatch(new GetDocsSearchResponseAction(request, response.data))
				cb && cb(true)
			})
			.catch((error) => {
				dispatch(new GetDocsSearchErrorAction(request, error))
				handleApiErrorWithNotification(error)
				cb && cb(false)
			})
	}
}
