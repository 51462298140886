import {GetCertificateRequestAction, GetCertificateResponseAction, GetCertificateErrorAction} from './index'
import {protectedApiClient} from 'helpers/api'
import {handleApiErrorWithNotification} from 'helpers/errorHandling'
import { CertificateModel } from 'pages/certificate/models'

export default (id: number, cb?: (isSuccess: boolean) => void) => {
	return (dispatch: (arg: GetCertificateRequestAction | GetCertificateResponseAction | GetCertificateErrorAction) => void) => {
		const request = new GetCertificateRequestAction(id)
		dispatch(request)

		protectedApiClient
			.get<CertificateModel>(`/certificate/certificate/${id}`)
			.then((response) => {
				dispatch(new GetCertificateResponseAction(request, response.data))
				cb && cb(true)
			})
			.catch((error) => {
				dispatch(new GetCertificateErrorAction(request, error.toString()))
				handleApiErrorWithNotification(error)
				cb && cb(false)
			})
	}
}
