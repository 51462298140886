import { DeleteMailboxRequestAction, DeleteMailboxResponseAction, DeleteMailboxErrorAction } from '.'
import { protectedApiClient } from 'helpers/api'
import { handleApiErrorWithNotification } from 'helpers/errorHandling'

export default (domainId: number, mailbox: string, cb?: (isSuccess: boolean) => void) => {
	return (
		dispatch: (arg: DeleteMailboxRequestAction | DeleteMailboxResponseAction | DeleteMailboxErrorAction) => void
	) => {
		const request = new DeleteMailboxRequestAction()
		dispatch(request)

		protectedApiClient
			.delete(`/mail/domain/${domainId}/mailbox?mailbox=${mailbox}`)
			.then((response) => {
				dispatch(new DeleteMailboxResponseAction(request))
				cb && cb(true)
			})
			.catch((error) => {
				dispatch(new DeleteMailboxErrorAction(request, error))
				handleApiErrorWithNotification(error)
				cb && cb(false)
			})
	}
}
