import { useTranslation } from "react-i18next"
import { AutoComplete, Form, Spin } from "antd"
import { useEffect, useState } from "react"
import { DomainModel } from "./models"
import { useLoggedUser } from "helpers/loginUserHelper"
import { _isValidDomain, stopPropagation } from "common/fce"
import { NameItem } from "common/models"
import { InputStatus } from "antd/lib/_util/statusUtils"



interface Props {
  dataSource: NameItem[]
  isLoading: boolean
  handleSelectedDomain: (domain: string, domain_id?: number) => void
  fetchData?: (searchText: string) => void
  handleStatus?: (status: InputStatus) => void
}


const DomainSelector = (props: Props) => {
  const { t } = useTranslation()

  const { dataSource, isLoading, fetchData, handleSelectedDomain } = props
  const [searchDomainLookup, setSearchDomainLookup] = useState<string | undefined>()
  const [searchDomain, setSearchDomain] = useState<string | undefined>()
  const [domainOptions, setDomainOptions] = useState<{ label: JSX.Element, key: string, value: string }[]>([])
  const [status, setStatus] = useState<InputStatus>("")

  // get settings and logged user from store
  const loggedUser = useLoggedUser()
  if (!loggedUser || !loggedUser.isLoaded()) {
    return (<Spin />)
  }
  const appSetting = loggedUser.getAppSettings()

  useEffect(() => {
    if (dataSource && dataSource.length) {
      // render DomainOptions
      const data = dataSource.map((d) => {
        return {
          value: d.name,
          key: `${d.id}`,
          label: <span>{d.name}</span>
        }
      })
      setDomainOptions(data)
    }
    else {
      setDomainOptions([])
    }
  }, [dataSource])

  useEffect(() => {
    props.handleStatus && props.handleStatus(status)
  }, [status])

  const onClearDomain = () => {
    setSearchDomainLookup(undefined)
    setSearchDomain(undefined)
    setDomainOptions([])
    handleSelectedDomain("", undefined)
    setStatus("")
  }

  const onSelectDomain = (data: string) => {
    console.log('onSelectDomain: ' + data)
    const item = domainOptions.find(s => s.value == data)
    console.log('onSelectDomain: domainOptions=' + JSON.stringify(domainOptions))
    if (item) {
      // raise event IP Selected
      setSearchDomain(item.value)
      setSearchDomainLookup(item.value)
      handleSelectedDomain(item.value, parseInt(item.key))
      setStatus("")
    }
    else {
      handleSelectedDomain(data, undefined)
    }
  }

  const onChangeDomainLookup = (searchText: string) => {
    console.log('onChangeDomainLookup: ' + searchText)
    _isValidDomain(searchText) ? setStatus("") : setStatus("error")
    if (!searchText) {
      if (searchDomainLookup && searchDomainLookup.length === 1) {
        setSearchDomainLookup(undefined)
      }
      return
    }

    if (appSetting.checkMinSearch(searchText)) {
      if (searchText != searchDomainLookup) {
        console.log('fetchData: ' + searchText)
        fetchData && fetchData(searchText)
      }
    }
    console.log('onChangeDomainLookup2: ' + searchText)
    setSearchDomainLookup(searchText)
    setSearchDomain(searchText)
    _isValidDomain(searchText) ? setStatus("") : setStatus("error")
  }

  return (
    <AutoComplete
      showSearch
      value={searchDomain}
      options={domainOptions}
      style={{ width: '100%' }}
      status={status}
      dropdownMatchSelectWidth={200}
      onInputKeyDown={(e) => {
        if (e.key === 'Enter') {
          onSelectDomain(e.currentTarget.value)
        }
      }}
      onBlur={
        () => {
          if (searchDomainLookup && _isValidDomain(searchDomainLookup)) {
            onSelectDomain(searchDomainLookup)
          }
        }}
      onSelect={onSelectDomain}
      onChange={onChangeDomainLookup}
      onClear={onClearDomain}
      onClick={stopPropagation}
      notFoundContent={isLoading && <Spin />}
      filterOption={false}
      optionFilterProp='label'
      placeholder={t('dnsPage.domain')}
      allowClear={true}
    />
  )
}

export default DomainSelector
