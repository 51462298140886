import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {AppState} from "../../common/models";
import {SettingsDetails} from "./models";
import './SettingsPage.scss';
import {useTranslation} from "react-i18next";
import {Card, Col, Input, Row, Space, Spin, Table, Tag} from "antd";
import Button from "antd-button-color";
import {PAGING} from "../../common/enums";
import TotalNum from "../../components/TotalNum/TotalNum";
import {
    BorderOutlined,
    CheckSquareFilled,
    EditTwoTone,
    LockTwoTone,
    SettingOutlined,
    UnlockTwoTone
} from "@ant-design/icons";
import {ShopOutlined} from "@ant-design/icons/lib/icons";
import Modal from "antd/lib/modal/Modal";
import {useLoggedUser} from "../../helpers/loginUserHelper";
import SettingForm from "./components/SettingForm";
import {LoadingIndicator} from "../../components";
import {stopPropagation} from "../../common/fce";
import {renderScope} from "../user/SettingItem";
import usePageSize from "../../common/usePageSize";
import {useHistory} from "react-router";
import ErrorPage403 from "../../components/Errors/ErrorPage403";
import useLogger from "../../common/useLogger";
import getAppSettings from "./actions/getAppSettings";
import {sort_name} from "../../common/sorting";
import getAppSetting from "./actions/getAppSetting";



enum Scope {
    APP = 'app',
    USER = 'user',
    CUSTOMER = 'customer'
}

// sorting
const sort_group = (a, b) => (a.group.toLowerCase() < b.group.toLowerCase()) ? -1 : 1


const SettingsPage = props => {
    const CONTROL_NAME = 'page_app_settings'
    const dispatch = useDispatch();
    const {t} = useTranslation()
    const history = useHistory()

    const {settings, isLoading, isSaving} = useSelector((state: AppState) => state.appSettingPage)

    const [dataSource, setDataSource] = useState<SettingsDetails[]>()
    const [searchName, setSearchName] = useState('')
    const [isModalVisible, setModalVisible] = useState(false)

    const [isAppSettingsViewer, setAppSettingsViewer] = useState(false)
    const [isAppSettingsEditor, setAppSettingsEditor] = useState(false)

    // get settings and logged user from store
    const loggedUser = useLoggedUser()
    if (!loggedUser || !loggedUser.isLoaded()) {
        return (
            <div className="fullwidth-loading" >
                <LoadingIndicator/>
            </div>
        )
    }

    // required authorization
    if (!loggedUser.hasAccess(CONTROL_NAME)) {
        return <ErrorPage403 />
    }

    const appSetting = loggedUser.getAppSettings()

    // usage: logger(msg, obj=null)
    const logger = useLogger(appSetting, 'SettingsPage')
    const [pageSize, setPageSize] = useState<number>(appSetting.grid_page_size)
    usePageSize(appSetting, loggedUser.user.id, pageSize)


    useEffect(() => {
        const access = loggedUser.hasAccess('page_app_settings')
        setAppSettingsViewer(access)
        setAppSettingsEditor(loggedUser.hasAccess('page_app_settings_edit_button'))
        if (access) {
            dispatch(getAppSettings())
        }
    }, [])

    useEffect(() => {
        // update datasource when data was changed
        if (!isModalVisible) {
            refreshGrid()
        }
    }, [settings, isModalVisible, searchName])

    const filtered = (name: string) => {
        if (!settings) {
            return []
        }

        let data = settings.filter((s) => s.name.toLowerCase().includes(name.toLowerCase()))
        logger(`filtered settings: ${settings?.length}, dataSource: ${data?.length}`)
        return data
    }

    const refreshGrid = () => {
        const newData = filtered(searchName).sort(sort_group)
        setDataSource(newData)
    }

    const FilterByNameInput = settings && (
        <Input placeholder={t('settingsPage.name')} value={searchName} onClick={stopPropagation}
               onChange={e => {setSearchName(e.target.value)}}
        />
    )

    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
            className: 'center',
            width: '30px',
        },
        {
            title: t('settingsPage.group'),
            dataIndex: 'group',
            key: 'group',
            className: 'left',
            width: 100,
        },
        {
            title: FilterByNameInput,
            dataIndex: 'name',
            key: 'name',
            width: '20%',
            sorter: (a, b) => sort_name(a, b),
            render: (text) => (<b>{text}</b>)
        },
        {
            title: t('settingsPage.value'),
            dataIndex: 'value',
            key: 'value',
            className: 'left',
            width: '30%'
        },
        {
            title: 'F',
            dataIndex: 'frozen',
            key: 'frozen',
            className: 'center',
            width: 25,
            render: (n) => n == 1 ? <CheckSquareFilled /> : <BorderOutlined />
        },
        {
            title: 'H',
            dataIndex: 'hidden',
            key: 'hidden',
            className: 'center',
            width: 25,
            render: (n) => n == 1 ? <CheckSquareFilled /> : <BorderOutlined />
        },
        {
            title: t('settingsPage.help'),
            dataIndex: 'name',
            key: 'name',
            className: 'left',
            width: '40%',
            render: (text) => (t(`settingHelp.${text}`))
        },
        {
            title: 'Action',
            key: 'action',
            width: '60px',
            dataIndex: 'action',
            className: 'center',
            render: (_, rec: SettingsDetails) => (
                <Space size='small'>
                    < Button
                        type='text'
                        size='small'
                        disabled={!isAppSettingsEditor}
                        onClick={() => {
                            dispatch(getAppSetting(rec.id!))
                            setModalVisible(true)}}
                        icon={<EditTwoTone twoToneColor={isAppSettingsEditor ? 'green' : '#ccc'} />}
                    />
                </Space>
            ),
        },
    ]

    return (
        <>
            <Card className='SettingsPage'
                  title={
                      <Row>
                          <Col span={6}>
                              <SettingOutlined />&nbsp;{t('settingsPage.title')}
                          </Col>
                          <Col span={18}>
                              <Space style={{float: 'right', marginRight: '20px'}}>
                                  <div style={{float: "left", marginRight: "10px"}}>&nbsp;
                                      {renderScope('app')}
                                  </div>
                              </Space>
                          </Col>
                      </Row>
                  }
            >
                <Table<SettingsDetails>
                    rowClassName={() => 'highlight'}
                    bordered={true}
                    columns={columns}
                    loading={isLoading}
                    dataSource={dataSource}
                    className='settingsTable'
                    rowKey='id'
                    pagination={{
                        defaultPageSize: appSetting.grid_page_size,
                        pageSizeOptions: PAGING,
                        showSizeChanger: true
                    }}
                    onChange={(ev) => {
                        ev.pageSize && setPageSize(ev.pageSize)
                    }}
                    footer={() => TotalNum(Number(dataSource?.length), 'Tax', dataSource)}
                />

            </Card>

            <Modal
                destroyOnClose={true}
                style={{top: 20}}
                title={
                    <>
                        <ShopOutlined/>{' '}{t('settingsPage.title')}
                    </>
                }
                visible={isModalVisible}
                onCancel={() => setModalVisible(false)}
                footer={null}>
                <SettingForm onClose={() => setModalVisible(false)} />
            </Modal>
        </>
    )
}

export default SettingsPage
