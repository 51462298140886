import { GetDomainVhostRequestAction, GetDomainVhostResponseAction, GetDomainVhostErrorAction } from '.'
import { protectedApiClient } from 'helpers/api'
import { handleApiErrorWithNotification } from 'helpers/errorHandling'
import { DomainModel } from 'pages/dns/service/models'

export default (id: number, cb?: (isSuccess: boolean) => void) => {
	return (
		dispatch: (arg: GetDomainVhostRequestAction | GetDomainVhostResponseAction | GetDomainVhostErrorAction) => void
	) => {
		const request = new GetDomainVhostRequestAction(id)
		dispatch(request)

		protectedApiClient
			.get<DomainModel>(`/domain/${id}?expand=services`)
			.then((response) => {
				dispatch(new GetDomainVhostResponseAction(request, response.data))
				cb && cb(true)
			})
			.catch((error) => {
				dispatch(new GetDomainVhostErrorAction(request, error.toString()))
				handleApiErrorWithNotification(error)
				cb && cb(false)
			})
	}
}
