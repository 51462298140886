import {RestartJobRequestAction, RestartJobResponseAction, RestartJobErrorAction} from '.'
import {protectedApiClient} from 'helpers/api'
import {handleApiErrorWithNotification} from 'helpers/errorHandling'
import {AsyncJobModel} from '../models'

export default (id: number, cb?: (isSuccess: boolean) => void) => {
    return (dispatch: (arg: RestartJobRequestAction | RestartJobResponseAction | RestartJobErrorAction) => void) => {
        const request = new RestartJobRequestAction(id)
        dispatch(request)

        protectedApiClient
            .put<AsyncJobModel>(`/asyncjob/job/${id}/restart`)
            .then((response) => {
                dispatch(new RestartJobResponseAction(request, response.data))
                cb && cb(true)
            })
            .catch((error) => {
                dispatch(new RestartJobErrorAction(request, error))
                handleApiErrorWithNotification(error)
                cb && cb(false)
            })
    }
}