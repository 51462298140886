import { UpdateMailDomainRequestAction, UpdateMailDomainResponseAction, UpdateMailDomainErrorAction } from '.'
import { protectedApiClient } from 'helpers/api'
import { handleApiErrorWithNotification } from 'helpers/errorHandling'
import { MailDomainModel, UpdateMailDomainParams } from '../models'

export default (params: UpdateMailDomainParams, cb?: (isSuccess: boolean) => void) => {
	return (
		dispatch: (
			arg: UpdateMailDomainRequestAction | UpdateMailDomainResponseAction | UpdateMailDomainErrorAction
		) => void
	) => {
		const request = new UpdateMailDomainRequestAction(params)
		dispatch(request)

		protectedApiClient
			.put<MailDomainModel>(`/mail/domain/${params.id}`, params)
			.then((response) => {
				dispatch(new UpdateMailDomainResponseAction(request, response.data))
				cb && cb(true)
			})
			.catch((error) => {
				dispatch(new UpdateMailDomainErrorAction(request, error))
				handleApiErrorWithNotification(error)
				cb && cb(false)
			})
	}
}
