
import {LookupServerErrorAction, LookupServerRequestAction, LookupServerResponseAction} from "./index";
import {protectedApiClient} from "../../../helpers/api";
import {handleApiErrorWithNotification} from "../../../helpers/errorHandling";


export default (field: string, qs: string, cb?: (isSuccess: boolean) => void) => {
    return (
        dispatch: (
            arg: LookupServerRequestAction | LookupServerResponseAction | LookupServerErrorAction,
        ) => void,
    ) => {
        const request = new LookupServerRequestAction(field)
        dispatch(request)
        let url = `/server/server/lookup?`+qs
        protectedApiClient
            .get<string[]>(url)
            .then((response) => {
                dispatch(new LookupServerResponseAction(request, field, response.data))
                cb && cb(true)
            })
            .catch((error) => {
                dispatch(new LookupServerErrorAction(request, error))
                handleApiErrorWithNotification(error)
                cb && cb(false)
            })
    }
}
