import { GetDocsSectionsRequestAction, GetDocsSectionsResponseAction, GetDocsSectionsErrorAction } from '.'
import { protectedApiClient } from 'helpers/api'
import { handleApiErrorWithNotification } from 'helpers/errorHandling'
import { SectionItem } from '../models'

export default (section: string, cb?: (isSuccess: boolean) => void) => {
	return (
		dispatch: (args: GetDocsSectionsRequestAction | GetDocsSectionsResponseAction | GetDocsSectionsErrorAction) => void
	) => {
		const request = new GetDocsSectionsRequestAction(section)
		dispatch(request)

		protectedApiClient
			.get<SectionItem[]>(`/zdoc/${section}/section`)
			.then((response) => {
				dispatch(new GetDocsSectionsResponseAction(request, response.data))
				cb && cb(true)
			})
			.catch((error) => {
				dispatch(new GetDocsSectionsErrorAction(request, error))
				handleApiErrorWithNotification(error)
				cb && cb(false)
			})
	}
}
