import React, { ChangeEvent, ReactNode, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import {
    Button,
    Card,
    Col, Dropdown,
    Input,
    Layout, Menu, MenuProps,
    message,
    Modal,
    Row,
    Space,
    Spin, Switch, Table,
    Tag, Tooltip
} from 'antd'
import { AppState } from 'common/models'
import {
    _parsePhone,
    ValidateParams
} from "../../common/fce"
import { useLoggedUser } from "../../helpers/loginUserHelper"
import { formItemLayout, tailLayout } from "../../helpers/layoutHelpers"
import Item from "antd/lib/form/FormItem"
import {
    CaretDownOutlined,
    MessageTwoTone, RobotOutlined,
    SyncOutlined,
    UserOutlined
} from "@ant-design/icons"
import Form, { useForm } from "antd/lib/form/Form"
import './UsersPage.scss'
import { UserAuthenticationModel, UserDetails, UserVerificationModel } from "./models"
import { CustomerDetails } from "../billing/customer/models"
import loginAsUser from "../login/actions/loginAsUser"
import getSelf from "../login/actions/getSelf"
import getCustomerSelf from "../login/actions/getCustomerSelf"
import getSettingsSelf from "../login/actions/getSettingsSelf"
import LoggedUser, {
    ROLE_ACCESS_MANAGER,
    ROLE_APP_ADMIN,
    ROLE_BILLING_EDITOR, ROLE_CUSTOMER_OWNER,
    ROLE_USER_MANAGER, ROLE_USER_ZCOM,
} from "../../common/LoggedUser"
import { useHistory, useParams } from "react-router"
import createUserMail from "./actions/createUserMail"
import ReactQuill from "react-quill"
import { LoadingIndicator } from "../../components"
import RoleTag from "../../components/RoleTag/RoleTag"
import HistoryLog from "../../components/History/HistoryLog"
import getHistoryLogs from "../../components/History/actions/getHistoryLogs"
import getUser from "./actions/getUser"
import Draggable, { DraggableData, DraggableEvent } from "react-draggable"
import createUserAuthentication from "./actions/createUserAuthentication"
import updateUserAuthentication from "./actions/updateUserAuthentication"
import deleteUserAuthentication from "./actions/deleteUserAuthentication"
import deleteUserAuthLog from "./actions/deleteUserAuthLog"
import { AiOutlineUserAdd } from "react-icons/ai"
import ProfileForm from "./ProfileForm"
import VerifyEmail from "./VerifyEmail"
import Panel2FA from "./Panel2FA"
import PanelProfile from "./PanelProfile"
import PanelSettings from "./PanelSettings"
import VerifySMS from "./VerifySMS"
import resetAll from "./actions/resetAll"
import PasswordChangeForm from "./PasswordChangeForm"
import UserUpdateForm from "./UserUpdateForm"
import PanelWhitelistIP from "./PanelWhitelistIP"
import sendWelcomeEmail from "./actions/sendWelcomeEmail"
import getUserSettings from "./actions/getUserSettings"
import { passwordRequest } from "./actions/passwordRequest"
import PanelLanguage from "./PanelLanguage"
import { Link } from 'react-router-dom'
import getUserCompany from './actions/getUserCompany'
import PanelSshKeys from './PanelSshKeys'


interface ParamTypes {
    id: string
}


export const isIdentVerified = (field: 'phone' | 'email', value: string, verifications: UserVerificationModel[]): boolean => {
    if (!value) {
        return false
    }
    let ident = ''
    if (field === 'phone') {
        ident = _parsePhone(value)
    }
    if (field === 'email') {
        ident = value.toLowerCase()
    }
    if (verifications && verifications.length) {
        const ver = verifications.filter(v => v.field === field).find(v => v.value === ident)
        if (ver) {
            return ver.is_verified === 1
        }
    }
    return false
}


const UserDetailPage = () => {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const history = useHistory()

    const { id } = useParams<ParamTypes>()

    const { auth } = useSelector((state: AppState) => state)
    const { customers } = useSelector((state: AppState) => state.auth.tables)
    const { self } = useSelector((state: AppState) => state.auth)
    const { usermail, user, company } = useSelector((state: AppState) => state.user)

    const [iid, setIid] = useState<number>(0)
    const [mailDlg, setMailDlg] = useState<boolean>(false)
    const [mailLoading, setMailLoading] = useState<boolean>(false)
    const [sender, setSender] = useState<string>('')
    const [selectedCustomer, setSelectedCustomer] = useState<CustomerDetails>()
    const [emailAddr, setEmailAddr] = useState<ValidateParams>({ value: '', validateStatus: '', errorMsg: '' })
    const [historyTitle, setHistoryTitle] = useState('')
    const [updateStatusSMS, setUpdateStatusSMS] = useState<boolean>(false)
    const [modalActionTitle, setModalActionTitle] = useState<string>('')
    const [panelKey, setPanelKey] = useState<string>('profile')

    const [isModalVerifyEmailVisible, setModalVerifyEmailVisible] = useState<boolean>(false)
    const [isModalVerifySmsVisible, setModalVerifySmsVisible] = useState<boolean>(false)

    const [verifyPhone, setVerifyPhone] = useState<string | undefined>()
    const [verifyEmail, setVerifyEmail] = useState<string | undefined>()
    const [profileModalVisible, setProfileModalVisible] = useState<boolean>(false)
    const [modalEditVisible, setModalEditVisible] = useState<boolean>(false)
    const [changePasswordModalVisible, setChangePasswordModalVisible] = useState<boolean>(false)

    const [forceRenderProfile, setForceRenderProfile] = useState<number>(0)
    const [forceUpdateHistoryId, setForceUpdateHistoryId] = useState<number>(1)
    const [changePasswordFormTS, setChangePasswordFormTS] = useState<number>(0)

    const [isViewer, setViewer] = useState(false)
    const [isEditor, setEditor] = useState(false)
    const [isUserManager, setUserManager] = useState(false)
    const [canEdit, setCanEdit] = useState(false)

    // get settings and logged user from store
    let loggedUser = useLoggedUser()
    if (!loggedUser || !loggedUser.isLoaded()) {
        return (
            <div className="fullwidth-loading" >
                <LoadingIndicator />
            </div>
        )
    }
    const appSetting = loggedUser.getAppSettings()
    // logger
    const logger = (msg, obj: any = null) => { if (appSetting && appSetting.debug) { obj ? console.log('UserDetail: ' + msg + ' > ' + JSON.stringify(obj)) : console.log('UserDetail: ' + msg) } }

    // history drag modal
    const [disabled, setDisabled] = useState(true)
    const draggleRef = useRef<HTMLDivElement>(null)
    const [bounds, setBounds] = useState({ left: 0, top: 0, bottom: 0, right: 0 })
    const onStart = (_event: DraggableEvent, uiData: DraggableData) => {
        const { clientWidth, clientHeight } = window.document.documentElement
        const targetRect = draggleRef.current?.getBoundingClientRect()
        if (!targetRect) {
            return
        }
        setBounds({
            left: -targetRect.left + uiData.x,
            right: clientWidth - (targetRect.right - uiData.x),
            top: -targetRect.top + uiData.y,
            bottom: clientHeight - (targetRect.bottom - uiData.y),
        })
    }

    useEffect(() => {
        // trigger ONCE
        logger('------------ trigger ONCE ')

    }, [])

    useEffect(() => {
        // fetch user data
        logger('load useruser: ' + id)
        if (!id) {
            return
        }
        let id2 = 0
        if (id == 'me') {
            setViewer(true)
            setEditor(true)
            id2 = self!.id
        }
        else {
            if (parseInt(id) === 0) {
                // error
                message.error('Chybny userId')
                history.push('/users')
                return
            }
            if (self && self.id === parseInt(id)) {
                // me
                setViewer(true)
                setEditor(true)
                id2 = self!.id
            } else {
                // other
                setViewer(loggedUser!.hasRole(ROLE_USER_MANAGER))
                setEditor(loggedUser!.hasRole(ROLE_USER_MANAGER))
                id2 = parseInt(id)
            }
        }
        const um = loggedUser!.hasRole(ROLE_USER_ZCOM) || loggedUser!.hasRole(ROLE_USER_MANAGER) || loggedUser!.hasRole(ROLE_ACCESS_MANAGER)
        setUserManager(um)

        // load user data
        setIid(id2)
        dispatch(getUser(id2))
        dispatch(getUserSettings(id2))
    }, [id])

    useEffect(() => {
        if (user) {
            setCanEdit(isEditor)
            fetchHistory('user', 'User', user.id)
            setSelectedCustomer(customers.find(c => c.id === user.customer_id))
        }
    }, [user])

    useEffect(() => {
        if (selectedCustomer) {
            dispatch(getUserCompany(selectedCustomer.company_id))
        }
    }, [selectedCustomer])

    useEffect(() => {
        logger(panelKey)
    }, [panelKey])


    const handleResetAll = () => {
        if (user && user.id > 0) {
            dispatch(resetAll(user.id, (suc) => {
                if (suc) {
                    dispatch(getUser(user.id))
                    message.success(t('general.success'))
                }
                else {
                    message.error(t('general.error'))
                }
            }))
        }
    }

    const handlePasswordRequest = () => {
        if (user) {
            passwordRequest({ email: user.email }, (suc, data) => {
                if (suc) {
                    message.success(t('general.success'))
                }
            })
        }
    }

    const handleSendMail = (user: UserDetails, template_name: string) => {

        setMailDlg(true)
        setMailLoading(true)
        setSender(user.email)
        if (template_name === 'mail_welcome_password') {
            setModalActionTitle(t('usersPage.setPassMail'))
        }
        if (template_name === 'mail_password_reset') {
            setModalActionTitle(t('usersPage.setPassLink'))
        }
        dispatch(createUserMail({ user_id: user.id, customer_id: user.customer_id, template_name: template_name }, isSuccess => {
            isSuccess && setMailLoading(false)
            !isSuccess && message.error(t('billing.invoice.create_mail_error'))
        }))
    }

    const fetchHistory = (service: string, model: string, mid: number) => {
        setHistoryTitle(`${service} / ${model} / ${mid}`)
        dispatch(getHistoryLogs(service, model, mid))
    }

    const loginAs = (user: UserDetails) => {
        // log-in as different user
        dispatch(loginAsUser({ user_id: user.id }, (suc) => {
            suc && dispatch(getSelf('validCustomers,roles,resourceGroups,groups,userAuthentications,authentications', (suc) => {
                suc && dispatch(getCustomerSelf('company', (suc) => {
                    suc && dispatch(getSettingsSelf((suc) => {
                        loggedUser = new LoggedUser(auth)
                        history.replace('/')
                    }))
                    !suc && message.error(t('general.error'))
                }
                ))
                !suc && message.error(t('general.error'))
            }
            ))
            !suc && message.error(t('general.error'))
        }
        ))
    }

    const validateInvoicingEmail = (emailStr: string): ValidateParams => {
        const emails = emailStr.trim().split(',')
        const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        let result = true
        for (const item of emails) {
            result = pattern.test(item.toLowerCase())
            if (!result) break
        }
        if (!result) {
            return {
                validateStatus: 'error',
                errorMsg: 'Should be comma-separated emails'
            }
        }
        else {
            return {
                validateStatus: 'success',
                errorMsg: ''
            }
        }
    }

    const handleInvoiceChange = (e: ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value
        setEmailAddr({ ...validateInvoicingEmail(value), value })
    }

    const isMe = () => {
        if (user && self) {
            return user.id === self.id
        }
        return false
    }

    const handleSendWelcomeMail = (v) => {
        if ((emailAddr.validateStatus === 'success' || !emailAddr.validateStatus) && usermail) {
            const data = { user_id: user!.id }
            dispatch(sendWelcomeEmail(data, (suc) => {
                if (suc) {
                    setMailDlg(false)
                    message.success(t('general.success'))
                }
                else {
                    message.error(t('general.error'))
                }
            }))
        }
    }

    const deleteAuth = (selUserAuth: UserAuthenticationModel) => {
        if (selUserAuth && selUserAuth.id) {
            dispatch(deleteUserAuthentication(selUserAuth.id, suc => {
                suc && message.success(t('general.success'))
                !suc && message.error(t('general.error'))
            }))
        }
    }

    const saveSmsAuth = (selUserAuth: UserAuthenticationModel) => {
        // Save 2FA auth
        if (selUserAuth && user) {
            const params = {
                id: selUserAuth.id,
                user_id: user.id,
                is_active: selUserAuth.is_active,
                ident: _parsePhone(selUserAuth.ident),
                expiration: selUserAuth.expiration
            }
            logger('saveSmsAuth: ', params)
            if (params.id) {
                // edit
                dispatch(updateUserAuthentication(params, suc => {
                    suc && message.success(t('general.success'))
                    suc && setUpdateStatusSMS(false)
                    !suc && message.error(t('general.error'))
                }))
            }
            else {
                // create
                const params2 = {
                    id: user.id,
                    user_id: user.id,
                    authentication_id: selUserAuth.authentication_id,
                    is_active: selUserAuth.is_active,
                    ident: _parsePhone(selUserAuth.ident),
                    expiration: selUserAuth.expiration
                }
                dispatch(createUserAuthentication(params2, suc => {
                    if (suc) {
                        message.success(t('general.success'))
                        setUpdateStatusSMS(false)
                    }
                    !suc && message.error(t('general.error'))
                }))
            }
        }
    }

    const saveGAuth = (selUserAuth: UserAuthenticationModel) => {
        // Save 2FA auth
        if (selUserAuth) {
            if (selUserAuth.id) {
                const params = {
                    id: selUserAuth.id,
                    is_active: selUserAuth.is_active,
                    expiration: selUserAuth.expiration
                }
                dispatch(updateUserAuthentication(params, suc => {
                    suc && message.success(t('general.success'))
                    !suc && message.error(t('general.error'))
                }))
            }
            else {
                const params = {
                    user_id: selUserAuth.user_id,
                    authentication_id: selUserAuth.authentication_id,
                    is_active: selUserAuth.is_active,
                    ident: '',
                    expiration: selUserAuth.expiration
                }
                dispatch(createUserAuthentication(params, suc => {
                    if (suc) {
                        message.success(t('general.success'))
                    }
                    !suc && message.error(t('general.error'))
                }))
            }
        }
    }

    const deleteLog = (log_id: number) => {
        if (log_id) {
            dispatch(deleteUserAuthLog(log_id, suc => {
                suc && message.success(t('general.success'))
            }))
        }
    }

    const onMenuClick: MenuProps['onClick'] = e => {
        logger('onMenuClick:' + e.key)
        if (!user) {
            return
        }
        switch (e.key) {
            case 'change_pass':
                setChangePasswordFormTS(Date.now())
                setChangePasswordModalVisible(true)
                break
            case 'send_set_pass_link':
                handlePasswordRequest()
                break
            case 'reset_all':
                handleResetAll()
                break
            case 'login_as':
                loginAs(user)
                break
            case 'send_welcome_mail':
                handleSendMail(user, 'mail_welcome_password')
                break
            case 'block':
                // blockAccess()
                break
        }
    }


    const actionMenu = () => {
        if (!user || !loggedUser) {
            return (
                <Menu onClick={onMenuClick}>error</Menu>
            )
        }

        if (isMe()) {
            // it's me!
            return (
                <Menu onClick={onMenuClick}>
                    <Menu.Item key="change_pass" disabled={user?.is_zcom}><Tooltip title={t('usersPage.changePass_desc')}>{t('usersPage.changePass')}</Tooltip></Menu.Item>
                    <Menu.Item key="send_set_pass_link" disabled={user?.is_zcom}><Tooltip title={t('usersPage.setPassLink_desc')}>{t('usersPage.setPassLink')}</Tooltip></Menu.Item>
                </Menu>
            )
        }

        if (!canEdit) {
            return (
                <Menu onClick={onMenuClick}>Read Only</Menu>
            )
        }

        // for admins
        return (
            <Menu onClick={onMenuClick}>
                <Menu.Item key="change_pass" disabled={!isMe() || user?.is_zcom}><Tooltip title={t('usersPage.changePass_desc')}>{t('usersPage.changePass')}</Tooltip></Menu.Item>
                <Menu.Item key="send_set_pass_link" disabled={user?.is_zcom}><Tooltip title={t('usersPage.setPassLink_desc')}>{t('usersPage.setPassLink')}</Tooltip></Menu.Item>
                <Menu.Item key="send_welcome_mail" disabled={user?.is_zcom}><Tooltip title={t('usersPage.setPassMail_desc')}>{t('usersPage.setPassMail')}</Tooltip></Menu.Item>
                <Menu.Item key="reset_all" disabled={!canEdit || user?.is_zcom}><Tooltip title={t('usersPage.resetAll_desc')}>{t('usersPage.resetAll')}</Tooltip></Menu.Item>
                {
                    loggedUser.hasRole(ROLE_APP_ADMIN) && (
                        <Menu.Item key="login_as" disabled={!loggedUser.hasRole(ROLE_USER_MANAGER)}><Tooltip title={t('usersPage.login_as_desc')}>{t('usersPage.login_as') + ' @' + user.username}</Tooltip></Menu.Item>
                    )
                }
            </Menu>
        )
    }

    type MenuItem = Required<MenuProps>['items'][number]
    function getItem(
        label: React.ReactNode,
        key: React.Key,
        icon?: React.ReactNode,
        children?: MenuItem[],
        type?: 'group',
    ): MenuItem {
        return { key, icon, children, label, type } as MenuItem
    }

    const createLeftSideMenu = () => {
        const items: MenuProps['items'] = [getItem(isMe() ? t('usersPage.menu_my_profile') : t('usersPage.menu_profile'), 'profile')]
        if (isMe() || isViewer) {
            items.push(getItem(isMe() ? t('usersPage.menu_my_notifications') : t('usersPage.menu_notifications'), 'notifications'))
            items.push(getItem(isMe() ? t('usersPage.menu_my_rights') : t('usersPage.menu_rights'), 'rights'))
            items.push(getItem(isMe() ? t('usersPage.menu_my_security') : t('usersPage.menu_security'), 'security'))
            items.push(getItem(isMe() ? t('usersPage.menu_my_gui_settings') : t('usersPage.menu_gui_settings'), 'gui_settings'))
            items.push(getItem(isMe() ? t('usersPage.menu_my_logs') : t('usersPage.menu_logs'), 'logs'))
        }

        if (isMe() && loggedUser!.hasRole(ROLE_CUSTOMER_OWNER)) {
            // customer settings
            items.push(getItem(t('usersPage.menu_customer_settings'), 'customer_settings'))
        }

        return items
    }

    const items: MenuProps['items'] = createLeftSideMenu()

    const onClickAccountMenu: MenuProps['onClick'] = e => {
        setPanelKey(e.key)
    }

    if (!user || !user.id || iid != user.id) {
        return (
            <Layout className='EditUserPage'>
                <Row gutter={[8, 8]} style={{ padding: '16px' }}>
                    <Col span={24} style={{ textAlign: 'left' }}>
                        <div style={{ whiteSpace: 'nowrap' }}><UserOutlined /> &nbsp;<span>{t('usersPage.title_detail')}</span></div>
                    </Col>
                </Row>
                <Row gutter={[32, 8]} style={{ height: '450px' }}>
                    <Col span={24}>
                        <div className="page-loading" >
                            <LoadingIndicator />
                        </div>
                    </Col>
                </Row>
            </Layout>
        )
    }

    const renderButtonEditRoles = (text: string, self?: UserDetails, user?: UserDetails) => {
        if (self && user) {
            if (isUserManager) {
                // user is loaded
                // open ProfileForm
                return (
                    <Link to={`/rights/user?user_id=${user.id}`}>{text}</Link>
                )
            }
        }
        return (<span className='text-disabled'>{text}</span>)
    }

    return (
        <>
            <Layout className='EditUserPage'>
                <Row gutter={[8, 8]} style={{ padding: '16px' }}>
                    <Col span={16} style={{ textAlign: 'left' }}>
                        {
                            user.is_system === 1 && (
                                <div style={{ whiteSpace: 'nowrap' }}><RobotOutlined /> &nbsp;<span>{t('usersPage.daemon')}</span>
                                    &nbsp;&nbsp;&nbsp;<span style={{ fontSize: '1.6em', fontWeight: 'bold' }}>{user.title}</span></div>
                            )
                        }
                        {
                            user.is_system === 0 && (
                                <div style={{ whiteSpace: 'nowrap' }}><UserOutlined /> &nbsp;<span>{t('usersPage.title_detail')}</span>
                                    &nbsp;&nbsp;&nbsp;<span style={{ fontSize: '1.6em', fontWeight: 'bold' }}>{user.title}</span></div>
                            )
                        }

                    </Col>

                    <Col span={8} style={{ textAlign: 'right' }}>
                        <Dropdown overlay={actionMenu()} placement='bottomRight' >
                            <Button style={{ marginLeft: '25px' }} type='primary'>
                                {t('general.actions')} <CaretDownOutlined />
                            </Button>
                        </Dropdown>
                    </Col>
                </Row>

                <Row gutter={[32, 8]}>
                    <Col xs={24} sm={24} md={24} lg={8} xl={8} xxl={8}>

                        <Menu
                            onClick={onClickAccountMenu}
                            selectedKeys={[panelKey]}
                            mode="inline"
                            className="AccountMenu"
                            items={items}
                        />

                    </Col>


                    <Col xs={24} sm={24} md={24} lg={16} xl={16} xxl={16}>
                        {
                            panelKey === 'profile' && (
                                <PanelProfile
                                    openModalVerifySms={(ident: string, b: boolean) => {
                                        setVerifyPhone(ident)
                                        setModalVerifySmsVisible(b)
                                    }}
                                    openModalVerifyEmail={(ident: string, b: boolean) => {
                                        setVerifyEmail(ident)
                                        setModalVerifyEmailVisible(b)
                                    }}
                                    editProfile={(uid) => {
                                        if (user.id === self!.id) {
                                            setProfileModalVisible(true)
                                        }
                                        else {
                                            setModalEditVisible(true)
                                        }
                                    }} />
                            )
                        }

                        {
                            panelKey === 'notifications' && (
                                <PanelSettings category='notifications' scope='user' loggedUser={loggedUser} />
                            )
                        }

                        {
                            panelKey === 'rights' && (
                                <>
                                    <Card title={t('usersPage.groups')}
                                        extra={renderButtonEditRoles(t('general.edit'), self, user)}
                                        className='fblock'>
                                        <Row gutter={[0, 0]}>
                                            <Col span={8} className='right'><span>{t('usersPage.groups')}</span></Col>
                                            <Col span={16}>
                                                {
                                                    user.groups?.map((g, idx) => (
                                                        <Tag key={g.id} color="gray">
                                                            {g.title}
                                                        </Tag>))
                                                }
                                            </Col>
                                        </Row>
                                    </Card>
                                    <Card title={t('usersPage.roles')}
                                        extra={renderButtonEditRoles(t('general.edit'), self, user)}
                                        className='fblock'>
                                        <Row gutter={[0, 0]}>
                                            <Col span={8} className='right'>
                                                <span>{t('usersPage.roles')}:</span><br />
                                            </Col>
                                            <Col span={16}>
                                                <div style={{ width: '210px', backgroundColor: '#f8f8f8', maxHeight: '300px', overflowY: 'auto' }}>
                                                    {
                                                        user.roles?.sort().map(role => <RoleTag key={role} role={role} />)
                                                    }
                                                </div>
                                            </Col>
                                        </Row>
                                    </Card>
                                </>
                            )
                        }

                        {
                            panelKey === 'security' && user.is_system === 1 && (<PanelWhitelistIP />)
                        }
                        {
                            panelKey === 'security' && user.is_system === 0 && (
                                <>
                                    <PanelSettings category='security' scope='user' loggedUser={loggedUser} />
                                    <Panel2FA saveSmsAuth={saveSmsAuth}
                                        saveGAuth={saveGAuth}
                                        deleteLog={deleteLog}
                                        deleteAuth={deleteAuth}
                                    />
                                    <PanelSshKeys />
                                </>
                            )
                        }

                        {
                            panelKey === 'gui_settings' && (
                                <>
                                    <PanelLanguage />
                                    <PanelSettings category='gui' scope='user' loggedUser={loggedUser} />

                                    {
                                        self!.is_zcom && (
                                            <PanelSettings category='zcom' scope='user' loggedUser={loggedUser} />
                                        )
                                    }

                                </>
                            )
                        }

                        {
                            panelKey === 'logs' && (
                                <HistoryLog isModal={false}
                                    showTitle={true}
                                    service='user'
                                    model='User'
                                    modelId={user.id}
                                    forceUpdateId={forceUpdateHistoryId} />
                            )
                        }

                        {
                            panelKey === 'customer_settings' && (
                                <>
                                    <div>
                                        <h1>{company!.name}</h1>
                                        <h2>{company!.street}</h2>
                                        <h2>{company!.city}</h2>
                                    </div>
                                    <PanelSettings category='customer' scope='customer' loggedUser={loggedUser} />
                                </>

                            )
                        }

                    </Col>
                </Row>

            </Layout>

            <Modal
                visible={mailDlg}
                footer={null}
                onCancel={() => setMailDlg(false)}
                title={modalActionTitle}
                maskClosable={false}
                width={900}
            >
                {
                    mailLoading &&
                    <Spin />
                }
                {
                    !mailLoading && usermail &&
                    <Form
                        {...formItemLayout}
                        initialValues={{
                            body: usermail.body,
                            to: sender,
                            subject: usermail.subject
                        }}
                        onFinish={handleSendWelcomeMail}
                    >
                        <Item name='to' label={t('billing.invoice.modal.to')} validateStatus={emailAddr.validateStatus} help={emailAddr.errorMsg}>
                            <Input onChange={handleInvoiceChange} />
                        </Item>
                        <Item name='subject' label={t('billing.invoice.modal.subject')}>
                            <Input />
                        </Item>
                        <Item name='body' label={t('billing.invoice.modal.body')}>
                            <ReactQuill theme="snow" className={'reactquillHight'} />
                        </Item>
                        <Item {...tailLayout}>
                            <Space>
                                <Button htmlType='submit' type='primary'>
                                    {t('billing.invoice.modal.btn_send')}
                                </Button>
                                <Button type='default' onClick={() => setMailDlg(false)}>
                                    {t('billing.invoice.modal.btn_close')}
                                </Button>
                            </Space>
                        </Item>
                    </Form>
                }
            </Modal>

            <Modal title={
                <div style={{ width: '100%', cursor: 'move' }}
                    onMouseOver={() => { if (disabled) { setDisabled(false) } }}
                    onMouseOut={() => { setDisabled(true) }}
                    onFocus={() => { }}
                    onBlur={() => { }}
                >
                    <><MessageTwoTone /> &nbsp; Ověření emailové adresy</>
                </div>
            }
                destroyOnClose
                className='modalComment'
                style={{ top: 20 }}
                visible={isModalVerifyEmailVisible}
                onOk={() => setModalVerifyEmailVisible(false)}
                onCancel={() => setModalVerifyEmailVisible(false)}
                modalRender={(modal) => (
                    <Draggable disabled={disabled} bounds={bounds} onStart={(ev, data) => onStart(ev, data)}>
                        <div ref={draggleRef}>{modal}</div>
                    </Draggable>
                )}
                getContainer={false}
                footer={null}
                confirmLoading={true}
            >
                <VerifyEmail ident={verifyEmail} setModalVisible={setModalVerifyEmailVisible} />
            </Modal>

            <Modal title={
                <div style={{ width: '100%', cursor: 'move' }}
                    onMouseOver={() => { if (disabled) { setDisabled(false) } }}
                    onMouseOut={() => { setDisabled(true) }}
                    onFocus={() => { }}
                    onBlur={() => { }}
                >
                    <><MessageTwoTone /> &nbsp; {t('Force2FA.verify_phone')}</>
                </div>
            }
                destroyOnClose
                className='modalComment'
                style={{ top: 20 }}
                visible={isModalVerifySmsVisible}
                onOk={() => setModalVerifySmsVisible(false)}
                onCancel={() => setModalVerifySmsVisible(false)}
                modalRender={(modal) => (
                    <Draggable disabled={disabled} bounds={bounds} onStart={(ev, data) => onStart(ev, data)}>
                        <div ref={draggleRef}>{modal}</div>
                    </Draggable>
                )}
                getContainer={false}
                footer={null}
                confirmLoading={true}
            >
                <VerifySMS ident={verifyPhone} setModalVisible={setModalVerifySmsVisible} />
            </Modal>

            <Modal title={
                <>
                    <AiOutlineUserAdd /> &nbsp;
                    {self && t('updateUserPage.title') + ' @' + self.username}
                </>
            }
                style={{ top: 20 }}
                width={600}
                visible={profileModalVisible}
                onCancel={() => setProfileModalVisible(false)}
                footer={null}
                confirmLoading={true}
            >
                <ProfileForm forceRender={forceRenderProfile} setModalVisible={(b) => setProfileModalVisible(b)} />
            </Modal>

            <Modal title={<><UserOutlined /> &nbsp;{t('createUserPage.account')}</>}
                style={{ top: 20 }}
                width={600}
                visible={modalEditVisible}
                onOk={() => setModalEditVisible(false)}
                onCancel={() => setModalEditVisible(false)}
                footer={null}
                maskClosable={false}
                getContainer={false}
                confirmLoading={true}
            >
                <UserUpdateForm userId={user.id} setModalVisible={setModalEditVisible} />
            </Modal>

            <Modal title={
                <>
                    <SyncOutlined /> &nbsp;{t('changePasswordPage.change_password')}
                </>
            }
                style={{ top: 20 }}
                width={600}
                visible={changePasswordModalVisible}
                onCancel={() => setChangePasswordModalVisible(false)}
                footer={null}
                confirmLoading={true}
            >
                <PasswordChangeForm alwaysHook={changePasswordFormTS} setModalVisible={(visible) => setChangePasswordModalVisible(visible)} />
            </Modal>
        </>
    )
}

export default UserDetailPage
