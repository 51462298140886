import { GetMailAliasesRequestAction, GetMailAliasesResponseAction, GetMailAliasesErrorAction } from '.'
import { protectedApiClient } from 'helpers/api'
import { handleApiErrorWithNotification } from 'helpers/errorHandling'
import { MailAliasModel, MailboxModel } from '../models'

export default (domainId: number, cb?: (isSuccess: boolean) => void) => {
	return (
		dispatch: (arg: GetMailAliasesRequestAction | GetMailAliasesResponseAction | GetMailAliasesErrorAction) => void
	) => {
		const request = new GetMailAliasesRequestAction(domainId)
		dispatch(request)

		let url = `/mail/domain/${domainId}/alias`
		protectedApiClient
			.get<MailAliasModel[]>(url)
			.then((response) => {
				dispatch(new GetMailAliasesResponseAction(request, response.data))
				cb && cb(true)
			})
			.catch((error) => {
				dispatch(new GetMailAliasesErrorAction(request, error))
				handleApiErrorWithNotification(error)
				cb && cb(false)
			})
	}
}
