import {
  AddIpToServerRequestAction,
  AddIpToServerResponseAction,
  AddIpToServerErrorAction,
} from '.'
import { protectedApiClient } from 'helpers/api'
import { handleApiErrorWithNotification } from 'helpers/errorHandling'
import {ServerAddRemoveIpParams, AddRemoveIpResponse, ServerModel} from '../models'

export default (
  params: ServerAddRemoveIpParams,
  cb?: (isSuccess: boolean) => void,
) => {
  return (
    dispatch: (
      arg:
        | AddIpToServerRequestAction
        | AddIpToServerResponseAction
        | AddIpToServerErrorAction,
    ) => void,
  ) => {
    const request = new AddIpToServerRequestAction(params)
    dispatch(request)
    protectedApiClient
      .put<ServerModel>(`/server/server/${params.server_id}/add-ip?expand=location,ips,type,services,management_type,monitoring_type,primary_ip,backup_type,status`, params)
      .then((response) => {
        dispatch(new AddIpToServerResponseAction(request, response.data))
        cb && cb(true)
      })
      .catch((error) => {
        dispatch(new AddIpToServerErrorAction(request, error))
        handleApiErrorWithNotification(error)
        cb && cb(false)
      })
  }
}
