import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {useForm} from "antd/lib/form/Form";
import React, {useEffect, useState} from "react";
import {Button, Col, Form, Input, message, Row, Select, Spin} from "antd";
import {Store} from "antd/lib/form/interface";
import TextArea from "antd/lib/input/TextArea";
import {AppState} from "../../common/models";
import {useLoggedUser} from "../../helpers/loginUserHelper";
import addIpToServer from "./actions/addIpToServer";
import {ServerAddRemoveIpParams} from "./models";
import {tailLayout} from "../../helpers/layoutHelpers";
import {UpdateIpParams} from "../ip/subnet/models";
import updateComment from "./actions/updateComment";


interface Props {
    onClose: () => void
}

const EditIpForm = (props: Props) => {
    const {t} = useTranslation()
    const dispatch = useDispatch()
    const [form2] = useForm()

    const {server, ip, isLoadingIps, isSaving} = useSelector( ( state: AppState ) => state.logic_server)

    const [selectedIpId, setSelectedIpId] = useState<number | undefined>()

    // get settings and logged user from store
    const loggedUser = useLoggedUser()
    if (!loggedUser || !loggedUser.isLoaded()) {
        return (<Spin/>)
    }
    const appSetting = loggedUser.getAppSettings()

    const submitForm = (values: Store): void => {
        if (!server) {
            message.error('Server unknown.')
            return
        }
        if (!ip) {
            message.error('Select IP address.')
            return
        }
        const params: UpdateIpParams = {
            id: ip.id,
            note: values.note
        }
        dispatch(updateComment(params, suc => {
            suc && message.success(t('general.success'))
            props.onClose()
        }))
    }

    return (
        <>
            <Row justify='space-between' style={{marginTop: '25px'}}>
                <Col span={24} >

                    <Form name='edit-ip'
                          labelCol={{ span: 6 }}
                          wrapperCol={{ span: 18 }}
                          layout="horizontal"
                          form={form2}
                          initialValues={ip}
                          onFinish={submitForm}
                    >

                        <Form.Item name='address' label={t('serversPage.ip')}>
                            <Input disabled />
                        </Form.Item>

                        <Form.Item name='note' label={t('serversPage.comment')}>
                            <TextArea data-gramm="false"
                                      data-gramm_editor="false"
                                      data-enable-grammarly="false" />
                        </Form.Item>


                        <Form.Item {...tailLayout}>
                            <Button type='primary' htmlType='submit'
                                    disabled={!ip}
                                    loading={isSaving}
                                    className='create-button'>
                                {t('general.save')}
                            </Button>
                        </Form.Item>
                    </Form>

                </Col>
            </Row>
        </>
    )

}

export default EditIpForm
