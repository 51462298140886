import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import {
    Button,
    Card,
    Col,
    Divider, Empty,
    Form,
    message,
    Modal, Popover,
    Row,
    Select, Space,
    Spin,
    Table, Tooltip
} from "antd"
import { CloudServerOutlined } from "@ant-design/icons/lib/icons"
import React, { useEffect, useRef, useState } from "react"
import { AppState } from "../../common/models"
import getServer from "./actions/getServer"
import { Link, useParams } from "react-router-dom"
import { useHistory } from "react-router"
import { useLoggedUser } from "../../helpers/loginUserHelper"
import { LoadingIndicator } from "../../components"
import ErrorPage403 from "../../components/Errors/ErrorPage403"
import useLogger from "../../common/useLogger"
import { ServerAddRemoveIpParams, ServerModel, StatusPlan, UpdateServerParams } from "./models"
import { useForm } from "antd/lib/form/Form"
import { Store } from "antd/lib/form/interface"
import updateServer from "./actions/updateServer"
import tableCustomers from "../login/actions/tableCustomers"
import getServerTypes from "./actions/getServerTypes"
import getServerServiceTypes from "./actions/getServerServiceTypes"
import getServerMonitoringTypes from "./actions/getServerMonitoringTypes"
import { ItemState, renderServiceBox, renderStateSwitch, SERVER_STATES } from "./common"
import TextArea from "antd/es/input/TextArea"
import getNotifications from "./actions/getNotifications"
import getManagementTypes from "./actions/getManagementTypes"
import getPhysServer from "./actions/getPhysServer"
import {
    getJsonParam,
    removeDiac,
    shortStr,
    SQL_DATETIME2_FORMAT,
    SQL_DATETIME_FORMAT,
    stopPropagation
} from "../../common/fce"
import { IpAddressModel } from "../ip/subnet/models"
import {
    CrownFilled,
    DeleteTwoTone,
    EditTwoTone,
    ExclamationCircleOutlined,
} from "@ant-design/icons"
import AddIpForm from "./AddIpForm"
import Draggable, { DraggableData, DraggableEvent } from "react-draggable"
import removeIpFromServer from "./actions/removeIpFromServer"
import EditIpForm from "./EditIpForm"
import getIP from "./actions/getIP"
import deleteServer from "./actions/deleteServer"
import { sort_label, sort_str } from "../../common/sorting"
import listPhysicalServers from "./actions/listPhysicalServers"
import getHypervisors from "./actions/getHypervisors"
import getHypervisor from "./actions/getHypervisor"
import getBackupServers from "./actions/getBackupServers"
import HistoryLog from "../../components/History/HistoryLog"
import ServerPlanForm from "./ServerPlanForm"
import "./ServersPage.scss"
import getStatusPlan from "./actions/getStatusPlan"
import moment from "moment"

const { confirm } = Modal

interface ParamTypes {
    server_id: string
}

const dataDaemons = [
    {
        key: '1',
        name: 'serveradmin-webnode',
        current: 4,
        max: 8,
        state: 'OK',
    },
    {
        key: '2',
        name: 'serveradmin-sqlnode',
        current: 3,
        max: 6,
        state: 'OK',
    },
    {
        key: '3',
        name: 'serveradmin-baculanode',
        current: 1,
        max: 1,
        state: 'OK',
    }
]

const columnsDaemons = [
    {
        title: 'Daemon',
        dataIndex: 'name',
        key: 'name',
    },
    {
        title: 'Aktuální verze',
        dataIndex: 'current',
        key: 'current',
    },
    {
        title: 'Dostupná verze',
        dataIndex: 'max',
        key: 'max',
    },
    {
        title: 'Stav',
        dataIndex: 'state',
        key: 'state',
    }
]


const EditServerPage = () => {
    const CONTROL_NAME = 'page_servers'
    const PHYSICAL_SERVER = 1
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const history = useHistory()
    const [form1] = useForm()

    const { server_id } = useParams<ParamTypes>()

    const { customers } = useSelector((state: AppState) => state.auth.tables)
    const { server, isSaving, isLoading, service_types, server_types, hypervisor,
        locations, managements, notifications, backup_servers, vps_servers, status_plans,
        monitorings, physical_servers, physical_server, isLoadingIps } = useSelector((state: AppState) => state.logic_server)

    const [serverTypeId, setServerTypeId] = useState<number>(0)
    const [customerOptions, setCustomerOptions] = useState<{ label: string, value: number, key: number }[]>([])
    const [serverTypeOptions, setServerTypeOptions] = useState<{ label: string, value: number, key: number }[]>([])
    const [physicalServerOptions, setPhysicalServerOptions] = useState<{ label: string, value: number, key: number }[]>([])
    const [vpsOptions, setVpsOptions] = useState<{ label: string, value: number, key: number }[]>([])
    const [statusOptions, setStatusOptions] = useState<{ label: string, value: number, key: number }[]>([])
    const [locationOptions, setLocationOptions] = useState<{ label: string, value: number, key: number }[]>([])
    const [managementOptions, setManagementOptions] = useState<{ label: string, value: number, key: number }[]>([])
    const [notificationZcomOptions, setNotificationZcomOptions] = useState<{ label: string, value: number, key: number }[]>([])
    const [notificationCustomerOptions, setNotificationCustomerOptions] = useState<{ label: string, value: number, key: number }[]>([])
    const [backupOptions, setBackupOptions] = useState<{ label: string, value: number, key: number }[]>([])
    const [monitoringOptions, setMonitoringOptions] = useState<{ label: string, value: number, key: number }[]>([])
    const [updateStatus, setUpdateStatus] = useState<boolean>(false)
    const [selectedPhysicalServerId, setSelectedPhysicalServerId] = useState<number | undefined>()
    const [selectedVpsId, setSelectedVpsId] = useState<number | undefined>()
    const [selectedBackup1Id, setSelectedBackup1Id] = useState<number | undefined>()
    const [selectedBackup2Id, setSelectedBackup2Id] = useState<number | undefined>()

    const [isModalNewIpVisible, setModalNewIpVisible] = useState(false)
    const [isModalEditIpVisible, setModalEditIpVisible] = useState(false)
    const [isModalPlanVisible, setModalPlanVisible] = useState(false)
    const [openDelete, setOpenDelete] = useState(false)
    const [confirmDelete, setConfirmDelete] = useState(false)
    const [historyModelId, setHistoryModelId] = useState<number | undefined>()
    const [forceUpdateId, setForceUpdateId] = useState<number>(1)
    const [planSource, setPlanSource] = useState<StatusPlan[]>([])

    const [isViewer, setViewer] = useState(false)
    const [isCreator, setCreator] = useState(false)
    const [isEditor, setEditor] = useState(false)
    const [isDeleter, setDeleter] = useState(false)
    const [isIpManager, setIpManager] = useState(false)
    const [isServiceManager, setServiceManager] = useState(false)
    const [isCustomerNotificator, setCustomerNotificator] = useState(false)

    // get settings and current user
    const loggedUser = useLoggedUser()
    if (!loggedUser || !loggedUser.isLoaded()) {
        // waiting..
        return (
            <div className="fullwidth-loading" >
                <LoadingIndicator />
            </div>
        )
    }

    // history drag modal
    const [disabled, setDisabled] = useState(true)
    const draggleRef = useRef<HTMLDivElement>(null)
    const [bounds, setBounds] = useState({ left: 0, top: 0, bottom: 0, right: 0 })
    const onStart = (_event: DraggableEvent, uiData: DraggableData) => {
        const { clientWidth, clientHeight } = window.document.documentElement
        const targetRect = draggleRef.current?.getBoundingClientRect()
        if (!targetRect) {
            return
        }
        setBounds({
            left: -targetRect.left + uiData.x,
            right: clientWidth - (targetRect.right - uiData.x),
            top: -targetRect.top + uiData.y,
            bottom: clientHeight - (targetRect.bottom - uiData.y),
        })
    }

    // required authorization
    if (!loggedUser.hasAccess(CONTROL_NAME)) {
        return <ErrorPage403 />
    }

    // settings
    const appSetting = loggedUser.getAppSettings()
    const SEARCH_MIN = appSetting.min_search_length

    const logger = useLogger(appSetting, 'EditServerPage')

    useEffect(() => {
        // trigger ONCE
        logger('server_id: ' + server_id)
        const access = loggedUser.hasAccess('page_servers')
        setViewer(access)
        setCreator(loggedUser.hasAccess('page_servers_create_button'))
        setEditor(loggedUser.hasAccess('page_servers_edit_button'))
        setDeleter(loggedUser.hasAccess('page_servers_delete_button'))
        setIpManager(loggedUser.hasAccess('page_servers_ip_manager'))
        setServiceManager(loggedUser.hasAccess('page_servers_service_manager'))
        setCustomerNotificator(loggedUser.hasAccess('page_servers_customer_notificator'))
        if (!access) {
            // failover 403
            message.error('No permissions!')
            history.replace('/servers')
            return
        }

        if (parseInt(server_id) > 0) {
            loadOptions()
            const sid = parseInt(server_id)
            dispatch(getServer(sid))
            dispatch(getStatusPlan(sid))
        }
        else {
            // failover 404
            history.replace('/servers')
        }
        forceUpdateId === 1 && setForceUpdateId(forceUpdateId + 1)	// update history
    }, [])

    useEffect(() => {
        if (server) {
            setServerTypeId(server.type_id)
            setHistoryModelId(server.id)
            if (server.type_id === PHYSICAL_SERVER) {
                if (server.location_id && server.location_id > 0) {
                    dispatch(getPhysServer(server.location_id))
                }
            }
            forceUpdateId === 1 && setForceUpdateId(forceUpdateId + 1)	// update history
        }
    }, [server])

    useEffect(() => {
        if (status_plans) {
            setPlanSource(status_plans.map(s => { return { ...s, key: `${s.id}` } }).sort((b, a) => sort_str(a.valid_from, b.valid_from)))
        }
    }, [status_plans])

    useEffect(() => {
        if (physical_servers) {
            setPhysicalServerOptions(
                physical_servers.map(item => ({
                    value: item.id,
                    key: item.id,
                    label: item.name
                }))
            )
        }
    }, [physical_servers])

    useEffect(() => {
        if (vps_servers) {
            setVpsOptions(
                vps_servers.map(item => ({
                    value: item.id,
                    key: item.id,
                    label: item.hostname
                }))
            )
        }
    }, [vps_servers])


    useEffect(() => {
        if (customers) {
            setCustomerOptions(
                customers.map(item => ({
                    value: item.id,
                    key: item.id,
                    label: item.company?.name!
                }))
            )
        }
    }, [customers])

    useEffect(() => {
        if (server_types) {
            setServerTypeOptions(
                server_types.map(item => ({
                    value: item.id,
                    key: item.id,
                    label: item.name
                }))
            )
        }
    }, [server_types])

    useEffect(() => {
        if (notifications) {
            setNotificationCustomerOptions(
                notifications.map(item => ({
                    value: item.id,
                    key: item.id,
                    label: item.name
                }))
            )
            setNotificationZcomOptions(
                notifications.map(item => ({
                    value: item.id,
                    key: item.id,
                    label: item.name
                }))
            )
        }
    }, [notifications])

    useEffect(() => {
        if (monitorings) {
            setMonitoringOptions(
                monitorings.map(item => ({
                    value: item.id,
                    key: item.id,
                    label: item.name
                }))
            )
        }
    }, [monitorings])

    useEffect(() => {
        if (managements) {
            setManagementOptions(
                managements.map(item => ({
                    value: item.id,
                    key: item.id,
                    label: item.name
                }))
            )
        }
    }, [managements])

    useEffect(() => {
        if (backup_servers) {
            setBackupOptions(
                backup_servers.map(item => ({
                    value: item.id,
                    key: item.id,
                    label: item.name
                }))
            )
        }
    }, [backup_servers])

    useEffect(() => {
        if (locations) {
            setLocationOptions(
                locations.map(item => ({
                    value: item.id,
                    key: item.id,
                    label: item.name
                }))
            )
        }
    }, [locations])

    const loadOptions = () => {
        // load Options for refresh F5
        if (!customers || customers.length === 0) {
            dispatch(tableCustomers())
        }
        if (!physical_servers || physical_servers.length === 0) {
            dispatch(listPhysicalServers())
        }
        if (!vps_servers || vps_servers.length === 0) {
            dispatch(getHypervisors())
        }
        if (!server_types || server_types.length === 0) {
            dispatch(getServerTypes())
        }
        if (!service_types || service_types.length === 0) {
            dispatch(getServerServiceTypes())
        }
        if (!backup_servers || backup_servers.length === 0) {
            dispatch(getBackupServers())
        }
        if (!monitorings || monitorings.length === 0) {
            dispatch(getServerMonitoringTypes())
        }
        if (!managements || managements.length === 0) {
            dispatch(getManagementTypes())
        }
        if (!notifications || notifications.length === 0) {
            dispatch(getNotifications())
        }
        setStatusOptions(
            SERVER_STATES.map(item => ({
                value: item.id,
                key: item.id,
                label: item.name
            }))
        )

    }

    const reloadServer = () => {
        if (server && server.id) {
            dispatch(getServer(server.id))
        }
    }

    const loadIP = (id: number) => {
        if (id) {
            dispatch(getIP(id))
        }
    }

    const setPrimaryIP = (ipId: number) => {
        // call lookup for Ip_name
        if (!server) {
            message.error('Server unknown.')
            return
        }
        if (!ipId) {
            message.error('Set IP address.')
            return
        }
        const params: UpdateServerParams = {
            id: server.id,
            primary_ip_id: ipId
        }
        dispatch(updateServer(params, suc => {
            if (suc) {
                message.success(t('general.success'))
                setForceUpdateId(forceUpdateId + 1)	// update history
                // reloadServer()
            }
        }))
    }

    const showConfirmDelete = (s: ServerModel, title: string) => {
        if (!s) {
            return
        }
        // if (!isDeleter) {
        //     message.error(t('general.error403'))
        //     return
        // }
        confirm({
            icon: <ExclamationCircleOutlined style={{ color: 'red' }} />,
            title: title,
            content: <p>{s.hostname}</p>,
            okText: t('general.yes'),
            cancelText: t('general.cancel'),
            okButtonProps: { loading: confirmDelete },
            className: 'confirm-alert',
            onOk() {
                setConfirmDelete(true)
                dispatch(deleteServer(s.id, suc => {
                    setConfirmDelete(false)
                    setOpenDelete(false)
                    if (suc) {
                        message.success(t('general.success'))
                        history.replace('/servers')
                    }
                }))
            },
            onCancel() {
                setOpenDelete(false)
            },
        }
        )
    }

    const removeIP = (ipId: number) => {
        if (!server) {
            message.error('Server unknown.')
            return
        }
        if (!ipId) {
            message.error('Set IP address.')
            return
        }
        const params: ServerAddRemoveIpParams = {
            server_id: server.id,
            ip_id: ipId
        }
        dispatch(removeIpFromServer(params, suc => {
            if (suc) {
                message.success(t('general.success'))
                setForceUpdateId(forceUpdateId + 1)	// update history
            }
        }))
    }

    const showConfirmServerType = (typeId: number) => {
        confirm({
            title: t('serversPage.change_hardware'),
            icon: <ExclamationCircleOutlined />,
            content: t('serversPage.change_hardware_desc'),
            onOk() {
                // change Hardware
                setUpdateStatus(true)
                setServerTypeId(typeId)
                setSelectedVpsId(undefined)
                setSelectedPhysicalServerId(undefined)
                form1.setFieldsValue({ location_id: undefined })
            },
            onCancel() {
                if (server) {
                    setServerTypeId(server.type_id)
                    form1.setFieldsValue({ type_id: server.type_id })
                }
            },
        })
    }

    const changeServerType = (v) => {
        showConfirmServerType(v)
    }

    const handleSubmit = (values: Store): void => {
        if (!server) {
            message.error('Missing server!')
            return
        }
        logger('Form: ' + JSON.stringify(values))
        const params: UpdateServerParams = { ...values, id: server.id }
        dispatch(updateServer(params, suc => {
            if (suc) {
                message.success(t('general.success'))
                reloadServer()
                setForceUpdateId(forceUpdateId + 1)	// update history
            }
        }))
    }

    const renderVps = () => {
        if (!server) {
            return ''
        }
        return (
            <>
                <Form.Item name='location_id' label={t('serversPage.location')}>
                    <Select
                        showSearch
                        placeholder={t('serversPage.location')}
                        options={vpsOptions}
                        value={selectedVpsId}
                        style={{ width: '100%', marginRight: "1rem" }}
                        dropdownMatchSelectWidth={200}
                        optionFilterProp="children"
                        filterOption={(input, opt) => removeDiac(opt?.label + '').includes(removeDiac(input))}
                        filterSort={sort_label}
                        allowClear
                        disabled={!isEditor}
                        onClick={stopPropagation}
                        onSelect={(v) => {
                            setSelectedVpsId(v)
                            setUpdateStatus(true)
                            dispatch(getHypervisor(v))
                        }}
                        onClear={() => setSelectedVpsId(undefined)}
                    />
                </Form.Item>

                {hypervisor && (
                    <Row>
                        <Col span={9} className='labelCol'>{t('serversPage.name')}:</Col>
                        <Col span={15} className='valueCol'><Link to={`/server/server?id=${hypervisor.id}`}>{shortStr(hypervisor?.hostname, 32)}</Link></Col>
                        <Col span={9} className='labelCol'>{t('serversPage.ip')}:</Col>
                        <Col span={15} className='valueCol'>{hypervisor.primary_ip?.name}</Col>
                        <Col span={9} className='labelCol'>{t('serversPage.location')}:</Col>
                        <Col span={15} className='valueCol'>{hypervisor.location}</Col>
                        <Col span={9} className='labelCol'>{t('serversPage.status')}:</Col>
                        <Col span={15} className='valueCol'>{hypervisor.status?.name}</Col>
                    </Row>
                )}
            </>
        )
    }

    const renderPhysicalServer = () => {
        if (!server) {
            return ''
        }

        return (
            <>
                <Form.Item name='location_id' label={t('serversPage.location')}>
                    <Select
                        showSearch
                        placeholder={t('serversPage.location')}
                        options={physicalServerOptions}
                        value={selectedPhysicalServerId}
                        style={{ width: '100%', marginRight: "1rem" }}
                        dropdownMatchSelectWidth={200}
                        optionFilterProp="children"
                        filterOption={(input, opt) => removeDiac(opt?.label + '').includes(removeDiac(input))}
                        filterSort={sort_label}
                        allowClear
                        disabled={!isEditor}
                        onClick={stopPropagation}
                        onSelect={(v) => {
                            setSelectedPhysicalServerId(v)
                            setUpdateStatus(true)
                            dispatch(getPhysServer(v))
                        }}
                        onClear={() => setSelectedPhysicalServerId(undefined)}
                    />
                </Form.Item>

                {physical_server && selectedPhysicalServerId === physical_server.id && (
                    <Row>
                        <Col span={9} className='labelCol'>{t('physicalServersPage.name')}:</Col>
                        <Col span={15} style={{ backgroundColor: '#f2f2f2' }} className='valueCol'><Link to={`/inventory/physical-servers?id=${physical_server.id}`}>{shortStr(physical_server?.conf, 32)}</Link></Col>
                        <Col span={9} className='labelCol'>{t('physicalServersPage.u_size')}:</Col>
                        <Col span={15} style={{ backgroundColor: '#f2f2f2' }} className='valueCol'>{physical_server.inventory && getJsonParam(physical_server.inventory.parameters, 'u_size')}</Col>
                        <Col span={9} className='labelCol'>{t('physicalServersPage.rack')}:</Col>
                        <Col span={15} style={{ backgroundColor: '#f2f2f2' }} className='valueCol'>{physical_server?.rack?.name}</Col>
                        <Col span={9} className='labelCol'>{t('physicalServersPage.ipmi')}:</Col>
                        <Col span={15} style={{ backgroundColor: '#f2f2f2' }} className='valueCol'>{physical_server?.text_ipmi}</Col>
                    </Row>
                )}
            </>
        )
    }

    const statusColumns = [
        {
            title: t('serversPage.time'),
            dataIndex: 'valid_from',
            width: '140px',
            render: (dt, rec) => (moment(dt, SQL_DATETIME_FORMAT).format(SQL_DATETIME2_FORMAT)),
        },
        {
            title: t('serversPage.state'),
            dataIndex: 'status_id',
            width: '80px',
            render: (sid, rec) => t(`serversPage.${SERVER_STATES.find(s => s.id === rec.status_id)?.name.toLowerCase()}`),
            SERVER_STATES
        },
        {
            title: 'Notif.',
            dataIndex: 'is_notification',
            width: 50,
        },
        {
            title: t('serversPage.note'),
            dataIndex: 'description',
            width: '30%',
            render: (desc, rec) => shortStr(desc, 12)
        }
    ]

    if (!server) {
        return <Spin />
    }

    return (
        <>
            <Card
                title={
                    <Row>
                        <Col span={6} style={{ padding: '10px' }}><CloudServerOutlined /> &nbsp; {t('serversPage.server')}</Col>
                        <Col span={18} style={{ padding: '10px' }}><span className='title3' >{server?.hostname}</span></Col>
                        <Col span={6} style={{ padding: '10px' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; {t('serversPage.ip')}</Col>
                        <Col span={18} style={{ padding: '10px' }}><span className='title2' >{server.primary_ip?.name}</span></Col>
                    </Row>
                }
                extra={
                    <Row style={{ width: 400 }}>
                        <Col span={24} style={{ padding: '1px' }}>
                            {renderStateSwitch(server, t)}
                        </Col>
                        <Col span={24} style={{ padding: '1px' }}>
                            <Table
                                bordered
                                style={{ height: 80 }}
                                scroll={{ y: 70 }}
                                locale={{ emptyText: 'No plan' }}
                                dataSource={planSource}
                                columns={statusColumns}
                                className='StatusTable'
                                rowKey='id'
                                onRow={(record, rowIndex) => {
                                    return {
                                        onClick: event => {
                                            (isEditor || isCreator) ? setModalPlanVisible(true) : setModalPlanVisible(false)
                                        }, // click row
                                    }
                                }}
                                onHeaderRow={(record, index) => {
                                    return {
                                        onClick: () => {
                                            (isEditor || isCreator) ? setModalPlanVisible(true) : setModalPlanVisible(false)
                                        }
                                    }
                                }}
                                pagination={false}
                            />
                        </Col>
                    </Row>
                }
                loading={isLoading}
                className='EditServerPage'
            >

                <Row style={{ marginTop: '35px', marginBottom: '35px' }}>
                    <Col span={24} style={{ textAlign: 'center', padding: '8px' }}>
                        <div><span className='title3'>{t('serversPage.services')}</span></div>
                        <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
                            {
                                server.services.length > 0 && server.services.map(s => renderServiceBox(s, t))
                            }
                            {
                                server.services.length === 0 && <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                            }
                        </div>

                    </Col>
                </Row>

                <Divider />
                <div style={{ textAlign: 'center', padding: '8px' }}><span className='title3'>{t('serversPage.settings')}</span></div>
                <Row style={{ marginTop: '35px', marginBottom: '35px' }}>
                    <Col span={10}>

                        <Form name='edit-server'
                            labelCol={{ span: 9 }}
                            wrapperCol={{ span: 15 }}
                            layout="horizontal"
                            form={form1}
                            initialValues={server}
                            onFinish={handleSubmit}
                            onChange={() => setUpdateStatus(true)}
                        >
                            <Form.Item name='status_id' label={t('serversPage.state')}>
                                <Select size='middle'
                                    options={statusOptions}
                                    onChange={() => setUpdateStatus(true)}
                                    disabled={!isEditor}
                                />
                            </Form.Item>

                            <Form.Item name='owner_id' label={t('serversPage.customer_id')}>
                                <Select size='middle'
                                    options={customerOptions}
                                    onChange={() => setUpdateStatus(true)}
                                    showSearch
                                    optionFilterProp='label'
                                    disabled={!isEditor}
                                />
                            </Form.Item>

                            <Form.Item name='management_type_id' label={t('serversPage.management_type')}>
                                <Select size='middle'
                                    options={managementOptions}
                                    onChange={() => setUpdateStatus(true)}
                                    showSearch
                                    optionFilterProp='label'
                                    disabled={!isEditor}
                                />
                            </Form.Item>

                            <Divider style={{ marginTop: '35px' }} orientation='left'>Hardware</Divider>
                            <Form.Item name='type_id' label={t('serversPage.server_type')}>
                                <Select size='middle'
                                    options={serverTypeOptions}
                                    showSearch
                                    optionFilterProp='label'
                                    onChange={v => changeServerType(v)}
                                    disabled={!isEditor}
                                />
                            </Form.Item>

                            {
                                // Phys.Server
                                serverTypeId === PHYSICAL_SERVER && renderPhysicalServer()
                            }

                            {
                                // VPS
                                serverTypeId > 1 && serverTypeId < 5 && renderVps()
                            }

                            <Divider style={{ marginTop: '35px' }} orientation='left'>{t('serversPage.notification')}</Divider>
                            <Form.Item name='notification_zcom_id' label={t('serversPage.notification_zcom')}>
                                <Select size='middle'
                                    options={notificationZcomOptions}
                                    onChange={() => setUpdateStatus(true)}
                                    showSearch
                                    optionFilterProp='label'
                                    disabled={!isEditor}
                                />
                            </Form.Item>

                            <Form.Item name='notification_customer_id' label={t('serversPage.notification_customer')}>
                                <Select size='middle'
                                    options={notificationCustomerOptions}
                                    onChange={() => setUpdateStatus(true)}
                                    showSearch
                                    optionFilterProp='label'
                                    disabled={!isEditor}
                                />
                            </Form.Item>

                            <Form.Item name='monitoring_type_id' label={t('serversPage.monitoring_type')}>
                                <Select size='middle'
                                    options={monitoringOptions}
                                    onChange={() => setUpdateStatus(true)}
                                    showSearch
                                    optionFilterProp='label'
                                    disabled={!isEditor}
                                />
                            </Form.Item>

                            <Divider style={{ marginTop: '35px' }} orientation='left'>Backup</Divider>
                            <Form.Item name='primary_backup' label={t('serversPage.primary_backup')}>
                                <Select
                                    showSearch
                                    placeholder={t('serversPage.backup_service')}
                                    options={backupOptions}
                                    value={selectedBackup1Id}
                                    style={{ width: '100%', marginRight: "1rem" }}
                                    dropdownMatchSelectWidth={200}
                                    optionFilterProp="children"
                                    filterOption={(input, opt) => removeDiac(opt?.label + '').includes(removeDiac(input))}
                                    filterSort={sort_label}
                                    allowClear
                                    onClick={stopPropagation}
                                    onSelect={(v) => setSelectedBackup1Id(v)}
                                    onClear={() => setSelectedBackup1Id(undefined)}
                                    disabled={!isEditor}
                                />
                            </Form.Item>
                            <Form.Item name='secondary_backup' label={t('serversPage.secondary_backup')}>
                                <Select
                                    showSearch
                                    placeholder={t('serversPage.backup_service')}
                                    options={backupOptions.filter(item => item.value != selectedBackup1Id)}
                                    value={selectedBackup2Id}
                                    style={{ width: '100%', marginRight: "1rem" }}
                                    dropdownMatchSelectWidth={200}
                                    optionFilterProp="children"
                                    filterOption={(input, opt) => removeDiac(opt?.label + '').includes(removeDiac(input))}
                                    filterSort={sort_label}
                                    allowClear
                                    onClick={stopPropagation}
                                    onSelect={(v) => setSelectedBackup2Id(v)}
                                    onClear={() => setSelectedBackup2Id(undefined)}
                                    disabled={!isEditor || !selectedBackup1Id}
                                />
                            </Form.Item>

                            <Divider style={{ marginTop: '35px' }} orientation='left'>{t('serversPage.comment')}</Divider>
                            <Form.Item name='comment' label={t('serversPage.comment')}>
                                <TextArea data-gramm="false"
                                    disabled={!isEditor}
                                    data-gramm_editor="false"
                                    data-enable-grammarly="false" />
                            </Form.Item>

                            <Form.Item
                                labelCol={{ span: 8 }}
                                wrapperCol={{ span: 16 }}>
                                <Button type='primary' size='middle'
                                    htmlType='submit'
                                    disabled={!isEditor || !updateStatus}
                                    style={{ float: 'right' }}>
                                    {t('general.save_changes')}
                                </Button>
                            </Form.Item>
                        </Form>

                    </Col>
                    <Col span={2}></Col>
                    <Col span={10}>

                        <CrownFilled style={{ color: "gold" }} />&nbsp;<span>{t('serversPage.primary_ip_id')}:</span>&nbsp;&nbsp;&nbsp;
                        <span style={{ fontSize: '1.2em', fontWeight: 'bold', border: 0 }}>{server.primary_ip?.name}</span>

                        <Table<IpAddressModel>
                            columns={[
                                {
                                    title: 'IP',
                                    dataIndex: 'address',
                                    key: 'address',
                                    width: 120,
                                    render: (text: string, rec: IpAddressModel) => {
                                        if (rec.id === server?.primary_ip_id) {
                                            return <span className='text-success' title='Primary IP'>{text}</span>
                                        }
                                        else {
                                            return text
                                        }
                                    }
                                },
                                {
                                    title: 'Comment',
                                    dataIndex: 'note',
                                    key: 'note',
                                },
                                {
                                    title: 'Action',
                                    dataIndex: 'Action',
                                    key: 'Action',
                                    width: 100,
                                    align: 'center',
                                    render: (text: string, rec: IpAddressModel) => (
                                        <Space size='small'>
                                            <Button type='text' size='small'
                                                className='actionButton'
                                                disabled={!isEditor}
                                                onClick={(e) => {
                                                    loadIP(rec.id)
                                                    setModalEditIpVisible(true)
                                                }}
                                                icon={<EditTwoTone twoToneColor={(isEditor) ? "green" : "#ccc"} />} />

                                            <Button type='text' size='small'
                                                className='actionButton'
                                                title={t('serversPage.set_primary_ip')}
                                                disabled={!isEditor || rec.id === server?.primary_ip_id}
                                                onClick={(e) => { setPrimaryIP(rec.id) }}
                                                icon={<CrownFilled style={{ color: (isEditor && rec.id != server?.primary_ip_id) ? "gold" : "#ccc" }} />}
                                            />

                                            <Button type='text' danger size='small'
                                                className='actionButton'
                                                disabled={!isEditor}
                                                onClick={(e) => { removeIP(rec.id) }}
                                                icon={<DeleteTwoTone twoToneColor={(isEditor) ? "red" : "#ccc"} />} />

                                        </Space>
                                    )
                                }
                            ]}
                            dataSource={server.ips}
                            scroll={{ x: 'max-content' }}
                            rowKey='id'
                            bordered={true}
                            className='IpsTable'
                            loading={isLoadingIps}
                            showHeader={true}
                            pagination={false}
                        />

                        <Button type='primary' size='small'
                            disabled={!isEditor && !isIpManager}
                            onClick={() => {
                                setModalNewIpVisible(true)
                            }}
                            style={{ float: 'right', margin: '4px' }}>
                            {t('serversPage.add_ip')}
                        </Button>

                        <Divider style={{ marginTop: '35px' }} orientation='left'>Daemons</Divider>
                        <Table dataSource={dataDaemons} columns={columnsDaemons} pagination={false} />

                        <Divider style={{ marginTop: '35px' }} orientation='left'>Graphs</Divider>
                        <Link to=''>odkaz na grafy zátěže</Link>
                    </Col>
                    <Col span={2}></Col>
                </Row>

                <Row gutter={[8, 8]} style={{ marginTop: '32px' }}>
                    <Col span={24}>
                        <HistoryLog service='server'
                            model='Server'
                            modelId={historyModelId}
                            forceUpdateId={forceUpdateId}
                            isModal={false}
                            showTitle={true} />
                    </Col>
                </Row>

                <Row>
                    <Col span={24} className='pad8'>
                        <Divider />
                        <Button danger size='small'
                            disabled={!isDeleter}
                            style={{ float: 'right' }}
                            onClick={() => {
                                showConfirmDelete(server, t('serversPage.confirm_delete_server'))
                            }}
                            icon={<DeleteTwoTone twoToneColor='red' />}>{t('serversPage.btn_delete_server')}</Button>

                    </Col>
                </Row>
            </Card>

            <Modal title={
                <div style={{ width: '100%', cursor: 'move' }}
                    onMouseOver={() => { if (disabled) { setDisabled(false) } }}
                    onMouseOut={() => { setDisabled(true) }}
                    onFocus={() => { }}
                    onBlur={() => { }}
                >
                    <><CloudServerOutlined /> &nbsp; {t('serversPage.add_ip')}</>
                </div>
            }
                style={{ top: 20 }}
                width={480}
                visible={isModalEditIpVisible}
                onCancel={() => setModalEditIpVisible(false)}
                maskClosable={false}
                modalRender={(modal) => (
                    <Draggable disabled={disabled} bounds={bounds} onStart={(ev, data) => onStart(ev, data)}>
                        <div ref={draggleRef}>{modal}</div>
                    </Draggable>
                )}
                destroyOnClose
                footer={null}
            >
                <EditIpForm onClose={() => { setModalEditIpVisible(false) }} />
            </Modal>

            <Modal title={
                <div style={{ width: '100%', cursor: 'move' }}
                    onMouseOver={() => { if (disabled) { setDisabled(false) } }}
                    onMouseOut={() => { setDisabled(true) }}
                    onFocus={() => { }}
                    onBlur={() => { }}
                >
                    <><CloudServerOutlined /> &nbsp; {t('serversPage.add_ip')}</>
                </div>
            }
                style={{ top: 20 }}
                width={480}
                visible={isModalNewIpVisible}
                onCancel={() => setModalNewIpVisible(false)}
                maskClosable={false}
                modalRender={(modal) => (
                    <Draggable disabled={disabled} bounds={bounds} onStart={(ev, data) => onStart(ev, data)}>
                        <div ref={draggleRef}>{modal}</div>
                    </Draggable>
                )}
                destroyOnClose
                footer={null}
            >
                <AddIpForm onClose={() => { setModalNewIpVisible(false) }} />
            </Modal>


            <Modal title={
                <div style={{ width: '100%', cursor: 'move' }}
                    onMouseOver={() => { if (disabled) { setDisabled(false) } }}
                    onMouseOut={() => { setDisabled(true) }}
                    onFocus={() => { }}
                    onBlur={() => { }}
                >
                    <><CloudServerOutlined /> &nbsp; {t('serversPage.plan')}</>
                </div>
            }
                style={{ top: 20 }}
                width={780}
                visible={isModalPlanVisible}
                onCancel={() => setModalPlanVisible(false)}
                maskClosable={false}
                modalRender={(modal) => (
                    <Draggable disabled={disabled} bounds={bounds} onStart={(ev, data) => onStart(ev, data)}>
                        <div ref={draggleRef}>{modal}</div>
                    </Draggable>
                )}
                destroyOnClose
                footer={null}
            >
                <ServerPlanForm onClose={() => { setModalPlanVisible(false) }} />
            </Modal>

        </>
    )
}

export default EditServerPage
