import {
    CreateStatusPlanRequestAction,
    CreateStatusPlanResponseAction,
    CreateStatusPlanErrorAction,
} from '.'
import { protectedApiClient } from 'helpers/api'
import { handleApiErrorWithNotification } from 'helpers/errorHandling'
import { CreateStatusPlanParams, StatusPlan } from '../models'

export default (
    params: CreateStatusPlanParams,
    cb?: (isSuccess: boolean) => void,
) => {
    return (
        dispatch: (
            arg:
                | CreateStatusPlanRequestAction
                | CreateStatusPlanResponseAction
                | CreateStatusPlanErrorAction,
        ) => void,
    ) => {
        const request = new CreateStatusPlanRequestAction(params)
        dispatch(request)
        protectedApiClient
            .post<StatusPlan>(`/server/server/${params.server_id}/status-scheduler`, params)
            .then((response) => {
                dispatch(new CreateStatusPlanResponseAction(request, response.data))
                cb && cb(true)
            })
            .catch((error) => {
                dispatch(new CreateStatusPlanErrorAction(request, error))
                handleApiErrorWithNotification(error)
                cb && cb(false)
            })
    }
}
