import {
	UpdateMailboxPasswordRequestAction,
	UpdateMailboxPasswordResponseAction,
	UpdateMailboxPasswordErrorAction,
} from '.'
import { protectedApiClient } from 'helpers/api'
import { handleApiErrorWithNotification } from 'helpers/errorHandling'
import { MailboxModel, UpdateMailboxPasswordParams } from '../models'

export default (params: UpdateMailboxPasswordParams, cb?: (isSuccess: boolean) => void) => {
	return (
		dispatch: (
			arg: UpdateMailboxPasswordRequestAction | UpdateMailboxPasswordResponseAction | UpdateMailboxPasswordErrorAction
		) => void
	) => {
		const request = new UpdateMailboxPasswordRequestAction(params)
		dispatch(request)

		protectedApiClient
			.put<MailboxModel>(`/mail/domain/${params.domain_id}/password?mailbox=${params.mailbox}`, params)
			.then((response) => {
				dispatch(new UpdateMailboxPasswordResponseAction(request, response.data))
				cb && cb(true)
			})
			.catch((error) => {
				dispatch(new UpdateMailboxPasswordErrorAction(request, error))
				handleApiErrorWithNotification(error)
				cb && cb(false)
			})
	}
}
