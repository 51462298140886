import {CreateAliasRequestAction, CreateAliasResponseAction, CreateAliasErrorAction} from './index'
import {protectedApiClient} from 'helpers/api'
import {handleApiErrorWithNotification} from 'helpers/errorHandling'
import {AliasParams, VhostModel} from '../models'

export default (params: AliasParams, cb?: (isSuccess: boolean) => void) => {
	return (dispatch: (arg: CreateAliasRequestAction | CreateAliasResponseAction | CreateAliasErrorAction) => void) => {
		const request = new CreateAliasRequestAction(params)
		dispatch(request)
		protectedApiClient
			.post<VhostModel>(`/web/vhost/${params.vhost_id}/alias`, params)
			.then((response) => {
				dispatch(new CreateAliasResponseAction(request, response.data))
				cb && cb(true)
			})
			.catch((error) => {
				dispatch(new CreateAliasErrorAction(request, error.toString()))
				handleApiErrorWithNotification(error)
				cb && cb(false)
			})
	}
}
