import React, { useState, useEffect, useRef } from 'react'
import {
	Card,
	Modal,
	Table,
	Space,
	AutoComplete,
	message,
	Popconfirm,
	Tag,
	Row,
	Col,
	Tooltip,
	Spin,
	Select,
	Input
} from 'antd'
import {
	CheckCircleOutlined,
	CheckCircleTwoTone, DeleteTwoTone, EditTwoTone,
	PlayCircleOutlined,
	PlayCircleTwoTone,
	SafetyCertificateOutlined,
	StopTwoTone
} from '@ant-design/icons/lib/icons'
import './CertificatesPage.scss'
import { useTranslation } from 'react-i18next'
import Button from "antd-button-color"
import { Link } from 'react-router-dom'
import { CertificateModel, CHALLENGE_ENUM } from './models'
import { useDispatch, useSelector } from 'react-redux'
import CertificateEditForm from 'pages/certificate/CertificateEditForm'
import deleteCertificate from './actions/deleteCertificate'
import getCertificates from './actions/getCertificates'
import { AppState } from "../../common/models"
import { useLoggedUser } from "../../helpers/loginUserHelper"
import { LoadingIndicator } from "../../components"
import ErrorPage403 from "../../components/Errors/ErrorPage403"
import { onKeyPressOnlyNum, removeDiac, shortStr, stopPropagation } from "../../common/fce"
import usePageSize from "../../common/usePageSize"
import { useHistory } from "react-router"
import { useLocation } from "react-router-dom"
import { ExclamationCircleOutlined, InfoCircleTwoTone, PlusCircleOutlined, SyncOutlined } from "@ant-design/icons"
import { ColumnsType } from "antd/es/table"
import useLogger from "../../common/useLogger"
import Draggable, { DraggableData, DraggableEvent } from "react-draggable"
import Pager from "../../components/pagination/pager"
import getCertificate from "./actions/getCertificate"
import AppSettings from "../../common/AppSettings"
import NewCertificateForm from "./NewCertificateForm"
import lookupCertificate from "./actions/lookupCertificate"
import { sort_label, sort_str } from "../../common/sorting"
import GenCertificateForm from "./GenCertificateForm"
import HistoryModal from "../../components/History/HistoryModal"
import { protectedApiClient } from "../../helpers/api"
import prolongCertificate from './actions/prolongCertificate'


const { confirm } = Modal

const renderChallenge = (rec: CertificateModel) => {
	if (!rec) {
		return ''
	}
	if (rec.challenge.toLowerCase() === 'dns-01') {
		return <Tag color="gold">dns-01</Tag>
	}
	if (rec.challenge.toLowerCase() === 'web-01') {
		return <Tag color="#6376d7">web-01</Tag>
	}
	return <Tag>{rec.challenge}</Tag>
}

const renderSubjects = (rec: CertificateModel) => {
	if (!rec) {
		return ''
	}
	const subjects = rec.subjects?.split(',')
	return (
		<>
			{
				subjects?.filter(s => s && s.length > 1).sort(sort_str).map(item => <div><Tag key={item}>{item}</Tag></div>)
			}
		</>
	)
}

const renderValidTo = (rec: CertificateModel, appSetting: AppSettings) => {
	if (!rec) {
		return ''
	}
	const now = Math.round(Date.now() / 1000)
	if (rec.valid_to) {
		if (rec.valid_to && rec.valid_to <= now) {
			// Expired
			if (rec.auto_prolong) {
				return <Tag color="#FF0000">{appSetting.renderDate(rec.valid_to)}</Tag>
			}
			// dead
			return <Tag color="black" style={{ color: 'white' }}>{appSetting.renderDate(rec.valid_to)}</Tag>
		}
		if (rec.days_to_expire && (rec.days_to_expire <= 14)) {
			// Expired in 14 days
			return <Tag color="#E76823">{appSetting.renderDate(rec.valid_to)}</Tag>
		}
		if (rec.days_to_expire && (rec.days_to_expire <= 60)) {
			// Expired in 2 month
			return <Tag color="#FFDD00" style={{ color: 'black' }}>{appSetting.renderDate(rec.valid_to)}</Tag>
		}
	}
	// valid Certificate
	return appSetting.renderDate(rec.valid_to)
}

const CertificatesPage = () => {
	const CONTROL_NAME = 'page_certificates'
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const history = useHistory()
	const { search } = useLocation()

	const { customers, customerNames } = useSelector((state: AppState) => state.auth.tables)
	const { certificates, pager, isLoading, isLoadingLookup, lookup_name } = useSelector((state: AppState) => state.certificate)
	const { ssl_certs } = useSelector((state: AppState) => state.sidenav)

	const [dataSource, setDataSource] = useState<CertificateModel[]>([])
	const [certificatesFull, setCertificatesFull] = useState<CertificateModel[]>([])
	const [pageNumber, setPageNumber] = useState<number>(1)
	const [qsFilter, setQsFilter] = useState<string>('')

	// options
	const [customerOptions, setCustomerOptions] = useState<{ label: string, value: number }[]>([])
	const [nameOptions, setNameOptions] = useState<{ label: string, value: string }[]>([])
	const [challengeOptions, setChallengeOptions] = useState<{ label: string, value: string }[]>([])

	const [searchName, setSearchName] = useState<string>('')
	const [searchNameLookup, setSearchNameLookup] = useState<string>('')
	const [searchComment, setSearchComment] = useState<string>('')
	const [searchCustomerId, setSearchCustomerId] = useState<number | undefined>(undefined)
	const [selectedCustomerId, setSelectedCustomerId] = useState<number | undefined>(undefined)
	const [searchDaysToExp, setSearchDaysToExp] = useState<number | undefined>(undefined)
	const [searchCustomer, setSearchCustomer] = useState<string>('')
	const [selectedChallenge, setSelectedChallenge] = useState<string | undefined>(undefined)

	const [loaded, setLoaded] = useState(0)         // when records are loaded
	const [ready, setReady] = useState<number>(0)   // when customers are loaded

	const [isHistoryModalVisible, setHistoryModalVisible] = useState(false)
	const [historyTitle, setHistoryTitle] = useState('')
	const [historyModelId, setHistoryModelId] = useState<number | undefined>()
	const [isModalEditCertificate, setModalEditCertificate] = useState(false)
	const [isModalNewCertificate, setModalNewCertificate] = useState(false)
	const [isModalGenCertificate, setModalGenCertificate] = useState(false)
	const [openDelete, setOpenDelete] = useState(false)
	const [confirmDelete, setConfirmDelete] = useState(false)

	const [isCertViewer, setCertViewer] = useState(false)
	const [isCertCreator, setCertCreator] = useState(false)
	const [isCertEditor, setCertEditor] = useState(false)
	const [isCertDeleter, setCertDeleter] = useState(false)
	const [isCertExporter, setCertExporter] = useState(false)

	// get settings and logged user from store
	const loggedUser = useLoggedUser()
	if (!loggedUser || !loggedUser.isLoaded()) {
		return (
			<div className="fullwidth-loading" >
				<LoadingIndicator />
			</div>
		)
	}

	// required authorization
	if (!loggedUser.hasAccess(CONTROL_NAME)) {
		return <ErrorPage403 />
	}

	// settings
	const appSetting = loggedUser.getAppSettings()

	// usage: logger(msg, obj=null)
	const logger = useLogger(appSetting, 'CertificatesPage')
	const [pageSize, setPageSize] = useState<number>(appSetting.grid_page_size)
	usePageSize(appSetting, loggedUser.user.id, pageSize)

	// history drag modal
	const [disabled, setDisabled] = useState(true)
	const draggleRef = useRef<HTMLDivElement>(null)
	const [bounds, setBounds] = useState({ left: 0, top: 0, bottom: 0, right: 0 })
	const onStart = (_event: DraggableEvent, uiData: DraggableData) => {
		const { clientWidth, clientHeight } = window.document.documentElement
		const targetRect = draggleRef.current?.getBoundingClientRect()
		if (!targetRect) {
			return
		}
		setBounds({
			left: -targetRect.left + uiData.x,
			right: clientWidth - (targetRect.right - uiData.x),
			top: -targetRect.top + uiData.y,
			bottom: clientHeight - (targetRect.bottom - uiData.y),
		})
	}

	useEffect(() => {
		const access = loggedUser.hasAccess('page_certificates')
		setCertViewer(access)
		setCertCreator(loggedUser.hasAccess('page_certificates_create_button'))
		setCertEditor(loggedUser.hasAccess('page_certificates_edit_button'))
		setCertDeleter(loggedUser.hasAccess('page_certificates_delete_button'))
		setCertExporter(loggedUser.hasAccess('page_certificates_exporter'))
		if (!access) {
			// failover 403
			message.error('No permissions!')
			history.replace('/')
			return
		}

		setChallengeOptions([
			{ label: CHALLENGE_ENUM.CHALLENGE_DNS, value: CHALLENGE_ENUM.CHALLENGE_DNS },
			{ label: CHALLENGE_ENUM.CHALLENGE_HTTP, value: CHALLENGE_ENUM.CHALLENGE_HTTP }
		])

		//dispatch(getServers())
	}, [dispatch])

	useEffect(() => {
		// trigger from Side menu clicked
		if (!isLoading && loaded > 0) {
			fetchRecords(1, pageSize)
		}
	}, [ssl_certs])

	useEffect(() => {
		// populate nameOptions
		logger('lookup_name changed: ' + JSON.stringify(lookup_name))
		setNameOptions(lookup_name.map(s => ({ label: s, value: s })))
	}, [lookup_name])

	useEffect(() => {
		// update QsFilter will trigger fetchRecords
		const qs: string = prepareQsFilter()
		logger(`qsFilter:${qs}`)
		if (qs.trim() != qsFilter.trim()) {
			setQsFilter(qs)
		}
	}, [searchName, searchComment, searchCustomerId, selectedChallenge, selectedCustomerId])

	useEffect(() => {
		// when filter is changed
		// show the first page
		logger(`qsFilter changed: page: ${pageNumber}, pageSize: ${pageSize}, qs=${qsFilter}`)
		let pn = pageNumber
		if (ready) {    // do not change page for F5
			pn = 1
		}
		fetchRecords(pn, pageSize)
	}, [qsFilter])

	useEffect(() => {
		logger('full - certificates..')
		if (certificates.items) {
			logger('certificates: ' + certificates.items.length)
			setCertificatesFull(certificates.items.map((s) => {
				let name = customers.find(c => c.id === s.customer_id)?.company!.name
				return {
					...s,
					customer_name: name
				}
			}))
		}
	}, [certificates.items])

	useEffect(() => {
		if (customers) {
			setReady(ready + 1)
			setCustomerOptions(
				customers.map(item => ({
					value: item.id,
					label: item.company?.name!
				}))
			)
		}
	}, [customers])

	useEffect(() => {
		// Filters
		if (filterIsValid()) {
			refreshGrid()
		}
	}, [certificatesFull])

	const fetchRecords = (pn: number, ps: number) => {
		setPageNumber(pn)
		setPageSize(ps)
		if (!isLoading && filterIsValid()) {
			// purchase_from is required
			logger(`fetchRecords: page: ${pn}, pageSize: ${ps}, qs=${qsFilter}`)
			let f = ''
			if (qsFilter) {
				f = '&' + qsFilter
			}
			dispatch(getCertificates(ps, pn - 1, f, suc => { }))
			setLoaded(1)
		}
	}

	const refreshGrid = () => {
		logger('refreshGrid')
		setDataSource(certificatesFull)
	}

	const filterIsValid = (): boolean => {
		// no rules
		return true
	}

	const getQSFilter = (): string[] => {
		let qs: string[] = []
		return qs
	}

	const prepareQsFilter = (): string => {
		// load filtered data from server
		let qs: string[]
		qs = getQSFilter()
		if (searchComment && appSetting.checkMinSearch(searchComment)) {
			qs.push('comment=' + encodeURIComponent(searchComment))
		}
		if (searchName && appSetting.checkMinSearch(searchName)) {
			qs.push('subjects=' + encodeURIComponent(searchName))
		}
		if (searchDaysToExp && searchDaysToExp > 0) {
			qs.push('days_to_expire=' + searchDaysToExp)
		}
		if (selectedChallenge && appSetting.checkMinSearch(selectedChallenge)) {
			qs.push('challenge=' + selectedChallenge)
		}
		if (searchCustomerId && searchCustomerId > 0) {
			qs.push('customer_id=' + searchCustomerId)
		}
		logger('prepareQsFilter: ' + qs.join("&"))
		return (qs.length === 0) ? '' : qs.join("&")
	}

	const fetchNameLookup = (searchText: string) => {
		// call lookup for name
		searchText = searchText.trim()
		if (appSetting.checkMinSearch(searchText.trim())) {
			let qs: string[]
			qs = getQSFilter()
			if (appSetting.checkMinSearch(searchText)) {
				qs.push('field=subjects')
				qs.push('value=' + encodeURIComponent(searchText.trim()))
				logger('lookupSubjects: ' + qs.join("&"))
				dispatch(lookupCertificate('subjects', qs.join("&")))
			}
		}
	}

	const onClearName = () => {
		setSearchNameLookup('')
		setSearchName('')
		setNameOptions([])
	}

	const onSelectName = (data: string) => {
		setSearchName(data)
		setPageNumber(1)
	}

	const onChangeNameLookup = (data: string) => {
		if (!data) {
			if (searchNameLookup.length === 1) {
				setSearchNameLookup('')
				fetchNameLookup('')
			}
			return
		}
		if (data != searchNameLookup) {
			setSearchNameLookup(data)
			fetchNameLookup(data)
		}
	}

	const onClearCustomer = () => {
		setSearchCustomer('')
		setSearchCustomerId(undefined)
	}

	const onSelectCustomer = (v: string) => {
		const cid = parseInt(v)
		if (cid > 0) {
			logger('onSelectCustomer.. ' + v)
			setSearchCustomerId(cid)
			const name = customerNames!.get(cid)
			name && setSearchCustomer(name)
		}
		else {
			setSearchCustomerId(undefined)
			v && setSearchCustomer(v)
		}
	}

	const onChangeCustomerLookup = (data: string) => {
		if (!data) {
			if (searchCustomer.length === 1) {
				setSearchCustomer('')
			}
			return
		}
		if (data != searchCustomer) {
			setSearchCustomer(data)
		}
	}

	const showConfirmDelete = (c: CertificateModel, title: string) => {
		if (!c) {
			return
		}

		confirm({
			icon: <ExclamationCircleOutlined style={{ color: 'red' }} />,
			title: title,
			content: <p>{c.name}</p>,
			okText: t('general.yes'),
			cancelText: t('general.cancel'),
			okButtonProps: { loading: confirmDelete },
			className: 'confirm-alert',
			onOk() {
				setConfirmDelete(true)
				dispatch(deleteCertificate(c.id, suc => {
					setConfirmDelete(false)
					setOpenDelete(false)
					if (suc) {
						message.success(t('general.success'))
					}
				}))
			},
			onCancel() {
				setOpenDelete(false)
			},
		}
		)
	}

	const showConfirmRenew = (c: CertificateModel, title: string) => {
		if (!c) {
			return
		}

		confirm({
			icon: <ExclamationCircleOutlined style={{ color: 'red' }} />,
			title: title,
			content: <p>{c.name}</p>,
			okText: t('general.yes'),
			cancelText: t('general.cancel'),
			okButtonProps: { loading: confirmDelete },
			className: 'confirm-alert',
			onOk() {
				setConfirmDelete(true)
				// dispatch(deleteCertificate(c.id,suc => {
				// 	setConfirmDelete(false)
				// 	setOpenDelete(false)
				// 	if (suc) {
				// 		message.success(t('general.success'))
				// 	}
				// }))
			},
			onCancel() {
				setOpenDelete(false)
			},
		}
		)
	}

	const FilterByComment = (
		<AutoComplete
			placeholder={t('certificatesPage.comment')}
			style={{ width: '200px' }}
			value={searchComment}
			allowClear={true}
			onClick={stopPropagation}
			onKeyDown={(e) => { if (e.key === 'Enter') { e.stopPropagation() } }}
			onChange={(v) => {
				setSearchComment(v)
			}}
		/>
	)

	const handleDownload = (rec: CertificateModel, typ: string) => {
		if (rec) {
			protectedApiClient.get<string>(`/certificate/certificate/${rec.id}/${typ}`, {
				onDownloadProgress: progressEvent => {
				}
			})
				.then(response => {
					const downloadLink = document.createElement("a")
					downloadLink.href = `data:application/zip;base64,${response.data}`
					downloadLink.download = `${rec.name}.zip`
					downloadLink.click()
				})
				.catch(error => {
					console.log(error)
					message.error(error.message)
				})
		}
	}

	const expandedRowRender = (rec: CertificateModel) => {
		return (
			<>
				<Row className='jobDetail' key={rec.id}>
					<Col span='4' className='right bold pad4'>
						{t('certificatesPage.authority')}:&nbsp;
					</Col>
					<Col span='20' className='pad4'>
						{rec?.authority}
					</Col>
					<Col span='4' className='right bold pad4'>
						{t('certificatesPage.checksum')}:&nbsp;
					</Col>
					<Col span='20' className='pad4'>
						<Row>
							<Col span={14} className='pad4'>{rec?.checksum}</Col>
							<Col span={10} className='right'>
								Download:&nbsp;
								{
									isCertExporter && (
										<>
											<span onClick={() => handleDownload(rec, 'crt')} className='linkUnder'>crt</span> &nbsp;|&nbsp;
											<span onClick={() => handleDownload(rec, 'key')} className='linkUnder'>key</span>&nbsp;|&nbsp;
											<span onClick={() => handleDownload(rec, 'ca-crt')} className='linkUnder'>ca-crt</span>&nbsp;|&nbsp;
											<span onClick={() => handleDownload(rec, 'zip')} className='linkUnder'>pem</span>
										</>
									)
								}
								{
									!isCertExporter && (
										<>
											<span className='text-disabled'>crt</span> &nbsp;|&nbsp;<span className='text-disabled'>key</span>&nbsp;|&nbsp;<span className='text-disabled'>ca_crt</span>&nbsp;|&nbsp;<span className='text-disabled'>pem</span>
										</>
									)
								}
							</Col>
						</Row>
					</Col>
					<Col span='4' className='right bold pad4'>
						{t('certificatesPage.challenge')}:&nbsp;
					</Col>
					<Col span='20' className='pad4'>
						{rec.challenge}
					</Col>
					<Col span='4' className='right bold pad4'>
						{t('general.updated')}:&nbsp;
					</Col>
					<Col span='20' className='pad4'>
						{appSetting.renderDateTime(rec.updated_at)}
					</Col>
					<Col span='4' className='right bold pad4'>
						{t('certificatesPage.days_to_expire')}:&nbsp;
					</Col>
					<Col span='20' className='pad4'>
						{rec.days_to_expire}
					</Col>

					<Col span='4' className='right bold pad4'>
						{t('certificatesPage.usage')}:&nbsp;
					</Col>
					<Col span='20' className='pad4'>
						{rec.usages?.map(u => u.name).join(', ')}
					</Col>

					<Col span='4' className='right bold pad4'>
						{t('certificatesPage.comment')}:&nbsp;
					</Col>
					<Col span='20' className='pad4'>
						{rec.comment}
					</Col>
				</Row>
			</>
		)
	}

	const FilterByChallenge = (
		<Select
			showSearch
			placeholder={t('certificatesPage.challenge')}
			options={challengeOptions}
			value={selectedChallenge}
			style={{ width: 100, marginRight: "1rem" }}
			dropdownMatchSelectWidth={140}
			optionFilterProp="children"
			filterOption={(input, opt) => removeDiac(opt?.label + '').includes(removeDiac(input))}
			filterSort={sort_label}
			allowClear
			onClick={stopPropagation}
			onChange={(v) => { setSelectedChallenge(v) }}
			onSelect={(v) => setSelectedChallenge(v)}
			onClear={() => setSelectedChallenge(undefined)}
		/>
	)

	const FilterByDays = (
		<Input type='text'
			placeholder={t('certificatesPage.days_to_expire_short')}
			style={{ width: 50 }}
			onKeyPress={(e) => {
				if (e.key === 'Enter') {
					e.currentTarget.value ? setSelectedCustomerId(parseInt(e.currentTarget.value)) : setSelectedCustomerId(undefined)
				}
				else {
					onKeyPressOnlyNum(e)
				}
			}}
			value={searchDaysToExp}
			onChange={(e) => { e.target.value ? setSearchDaysToExp(parseInt(e.target.value)) : setSearchDaysToExp(undefined) }}
		/>
	)

	const FilterByName = (
		<AutoComplete
			showSearch
			placeholder={t('certificatesPage.name')}
			style={{ width: '200px' }}
			value={searchNameLookup}
			options={nameOptions}
			dropdownMatchSelectWidth={200}
			onInputKeyDown={(e) => {
				if (e.key === 'Enter') {
					onSelectName(e.currentTarget.value)
				}
			}}
			onSelect={onSelectName}
			onChange={onChangeNameLookup}
			onClear={onClearName}
			onClick={stopPropagation}
			notFoundContent={isLoadingLookup && <Spin />}
			optionFilterProp='label'
			filterOption={false}
			allowClear={true}
		/>
	)

	const FilterByCustomer = (
		<AutoComplete
			showSearch
			placeholder={t('certificatesPage.customer')}
			style={{ width: '150px' }}
			value={searchCustomer}
			options={customerOptions}
			dropdownMatchSelectWidth={200}
			onInputKeyDown={(e) => {
				if (e.key === 'Enter') {
					onSelectCustomer(e.currentTarget.value)
				}
			}}
			onSelect={onSelectCustomer}
			onChange={onChangeCustomerLookup}
			onClear={onClearCustomer}
			onClick={stopPropagation}
			optionFilterProp='label'
			filterOption={(input, opt) => removeDiac(opt?.label + '').includes(removeDiac(input))}
			allowClear={true}
		/>
	)

	const columns: ColumnsType<CertificateModel> = [
		{
			title: FilterByName,
			dataIndex: 'subjects',
			key: 'subjects',
			width: '20%',
			className: 'left',
			ellipsis: true,
			fixed: true,
			render: (val: number, rec: CertificateModel) => renderSubjects(rec)
		},
		{
			title: t('certificatesPage.valid_to'),
			dataIndex: 'valid_to',
			key: 'valid_to',
			align: 'center',
			width: 80,
			render: (val: number, rec: CertificateModel) => renderValidTo(rec, appSetting)
		},
		{
			title: t('certificatesPage.auto_prolong'),
			dataIndex: 'auto_prolong',
			key: 'auto_prolong',
			align: 'left',
			width: 80,
			render: (val: number, rec: CertificateModel) => (
				<span>&nbsp;&nbsp;&nbsp;
					{rec.auto_prolong ? <CheckCircleOutlined style={{ color: 'green' }} /> : <StopTwoTone twoToneColor='red' />}&nbsp;
					{!!rec.auto_prolong && (<span style={{ color: '#ccc' }}>&nbsp;{rec.prolong_before_days}d</span>)}
				</span>
			)
		},
		{
			title: FilterByComment,
			dataIndex: 'comment',
			key: 'comment',
			width: '35%',
			ellipsis: true,
			render: (val: number, rec: CertificateModel) => <Tooltip title={rec.comment}><span>{shortStr(rec.comment, 50)}</span></Tooltip>
		},
		{
			title: FilterByCustomer,
			dataIndex: 'customer_name',
			key: 'customer_name',
			width: 120
		},
		{
			title: t('general.actions'),
			key: 'action',
			width: 100,
			align: 'center',
			fixed: 'right',
			dataIndex: 'action',
			render: (text, rec: CertificateModel) => (
				<Space>

					<Popconfirm
						title={t('certificatesPage.prolong_certificate_confirm')}
						onConfirm={() => { dispatch(prolongCertificate(rec.id)) }}
						okText={t('general.yes')}
						cancelText={t('general.no')}
					>
						<Button type='text' size='small'
							disabled={!isCertEditor}
							title={t('certificatesPage.prolong_certificate')}
							className='actionButton'
							icon={<PlayCircleOutlined style={{ color: isCertEditor ? "green" : "#ccc" }} />}
						/>
					</Popconfirm>

					<Button type='text' size='small'
						disabled={!isCertEditor}
						onClick={() => {
							// setSelectedCertId(rec)
							dispatch(getCertificate(rec.id))
							setModalEditCertificate(true)
						}}
						className='actionButton'
						icon={<EditTwoTone twoToneColor={isCertEditor ? "green" : "#ccc"} />}
					/>

					<Button type='text' danger size='small'
						disabled={!isCertDeleter}
						className='actionButton'
						onClick={() => showConfirmDelete(rec, t('certificatesPage.confirm_delete'))}
						icon={<DeleteTwoTone twoToneColor={(isCertDeleter) ? "red" : "#ccc"} />}
					/>

					<Button title={t('general.btnHistory')} size='small'
						onClick={() => {
							setHistoryModelId(rec.id)
							setHistoryTitle(rec.name)
							setHistoryModalVisible(true)
						}}
						icon={<InfoCircleTwoTone />}
						className='actionButton'
					/>

				</Space>
			),
		},
	]

	if (!appSetting || Object.keys(appSetting).length === 0) {
		return (<Spin />)
	}

	return (
		<>
			<Card
				title={
					<>
						<SafetyCertificateOutlined /> &nbsp; {t('certificatesPage.title')}
					</>
				}
				className='certificatesPage'
				extra={
					<>
						<Button type='success'
							disabled={!isCertCreator}
							onClick={() => {
								isCertCreator && setModalGenCertificate(true)
							}}>
							<PlusCircleOutlined /> {t('certificatesPage.generate_ssl')}{' '}
						</Button>
						<Button type='primary'
							disabled={!isCertCreator}
							onClick={() => {
								isCertCreator && setModalNewCertificate(true)
							}}>
							<PlusCircleOutlined /> {t('certificatesPage.create_title')}{' '}
						</Button>
					</>
				}
				loading={false}>

				<Table<CertificateModel>
					columns={columns}
					dataSource={dataSource}
					scroll={{ x: 'max-content' }}
					rowKey='id'
					bordered={true}
					className='CertificatesTable'
					expandable={{
						expandedRowRender: expandedRowRender,
					}}
					loading={isLoading}
					showHeader={true}
					pagination={false}
					footer={() => Pager({
						filename: 'fn',
						total: pager.totalCount,
						current: pager.page,
						pageSize: pager.pageSize,
						data: dataSource,
						fetchRecords: fetchRecords
					})}
					onChange={(ev) => {
						ev.pageSize && setPageSize(ev.pageSize)
					}}
				/>
			</Card>

			<Modal
				destroyOnClose
				style={{ top: 20 }}
				width={600}
				title={
					<>
						<SafetyCertificateOutlined /> &nbsp; {t('certificatesPage.create_title')}
					</>
				}
				visible={isModalNewCertificate}
				onCancel={() => setModalNewCertificate(false)}
				footer={null}
				confirmLoading={true}>
				<NewCertificateForm closeModal={() => setModalNewCertificate(false)} />
			</Modal>

			<Modal
				destroyOnClose
				style={{ top: 20 }}
				width={600}
				title={
					<>
						<SafetyCertificateOutlined /> &nbsp; {t('certificatesPage.create_title')}
					</>
				}
				visible={isModalGenCertificate}
				onCancel={() => setModalGenCertificate(false)}
				footer={null}
				confirmLoading={true}>
				<GenCertificateForm closeModal={() => setModalGenCertificate(false)} />
			</Modal>

			<Modal
				destroyOnClose
				style={{ top: 20 }}
				width={600}
				title={
					<>
						<SafetyCertificateOutlined /> &nbsp; {t('certificatesPage.form_title')}
					</>
				}
				visible={isModalEditCertificate}
				onCancel={() => setModalEditCertificate(false)}
				footer={null}
				confirmLoading={true}>
				<CertificateEditForm closeModal={() => setModalEditCertificate(false)} />
			</Modal>

			<HistoryModal service='certificate' model='Certificate'
				modelId={historyModelId}
				title={historyTitle}
				isModalVisible={isHistoryModalVisible}
				setModalVisible={() => setHistoryModalVisible(false)}
				modalRender={(modal) => (
					<Draggable bounds={bounds} onStart={(ev, data) => onStart(ev, data)}>
						<div ref={draggleRef}>{modal}</div>
					</Draggable>
				)}
			/>

		</>
	)
}

export default CertificatesPage
