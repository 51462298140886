import {
    GetServersDnsRequestAction,
    GetServersDnsResponseAction,
    GetServersDnsErrorAction,
} from '.'
import { protectedApiClient } from 'helpers/api'
import { handleApiErrorWithNotification } from 'helpers/errorHandling'
import {ServerModel} from "../../../server/models";


export default (prefix: string, cb?: (isSuccess: boolean) => void) => {
    return (
        dispatch: (
            arg:
                | GetServersDnsRequestAction
                | GetServersDnsResponseAction
                | GetServersDnsErrorAction,
        ) => void,
    ) => {
        const request = new GetServersDnsRequestAction()
        dispatch(request)

        let url = `/server/server`
        if (prefix) {
            url = `/server/server?hostname=${prefix}`
        }
        protectedApiClient
            .get<ServerModel[]>(url)
            .then((response) => {
                dispatch(new GetServersDnsResponseAction(request, response.data))
                cb && cb(true)
            })
            .catch((error) => {
                dispatch(new GetServersDnsErrorAction(request, error))
                handleApiErrorWithNotification(error)
                cb && cb(false)
            })
    }
}
