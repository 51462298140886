import { GetServersIpRequestAction, GetServersIpResponseAction, GetServersIpErrorAction } from '.'
import { protectedApiClient } from 'helpers/api'
import { handleApiErrorWithNotification } from 'helpers/errorHandling'
import { ServerModel } from '../../../server/models'

export default (prefix: string, cb?: (isSuccess: boolean) => void) => {
	return (
		dispatch: (arg: GetServersIpRequestAction | GetServersIpResponseAction | GetServersIpErrorAction) => void
	) => {
		const request = new GetServersIpRequestAction()
		dispatch(request)

		let url = `/server/server`
		if (prefix) {
			url = `/server/server?hostname=${prefix}`
		}
		protectedApiClient
			.get<ServerModel[]>(url)
			.then((response) => {
				dispatch(new GetServersIpResponseAction(request, response.data))
				cb && cb(true)
			})
			.catch((error) => {
				dispatch(new GetServersIpErrorAction(request, error))
				handleApiErrorWithNotification(error)
				cb && cb(false)
			})
	}
}
