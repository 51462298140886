import { protectedApiClient } from 'helpers/api'
import { handleApiErrorWithNotification } from 'helpers/errorHandling'
import { IpAddressGrid } from '../models'
import { ListIpAddressErrorAction, ListIpAddressRequestAction, ListIpAddressResponseAction } from './index'

export default (
	pageSize: number,
	pageNumber: number,
	subnetId: number,
	filter: string,
	cb?: (isSuccess: boolean) => void
) => {
	return (
		dispatch: (arg: ListIpAddressRequestAction | ListIpAddressResponseAction | ListIpAddressErrorAction) => void
	) => {
		const request = new ListIpAddressRequestAction(pageSize, pageNumber)
		dispatch(request)

		let url = `/ip/subnet/${subnetId}/ip?expand=ipv6,usages&pagination[pageSize]=${pageSize}&pagination[page]=${pageNumber}`
		if (filter) {
			url = `${url}&${filter}`
		}
		protectedApiClient
			.get<IpAddressGrid>(url)
			.then((response) => {
				dispatch(new ListIpAddressResponseAction(request, response.data))
				cb && cb(true)
			})
			.catch((error) => {
				dispatch(new ListIpAddressErrorAction(request, error))
				handleApiErrorWithNotification(error)
				cb && cb(false)
			})
	}
}
