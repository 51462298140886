import { AsyncActionMode, PAGE1 } from 'common/models'
import { CheckMXResp, MailDomainsPageState } from './models'
import { MailServiceActions } from './actions'
import { ActionType } from './actions'

const MAIL_INITIAL_STATE: MailDomainsPageState = {
	isSavingService: false,
	isSavingDomain: false,
	isSavingMailbox: false,
	isSavingMailAlias: false,
	isLoadingService: false,
	isLoadingDomain: false,
	isLoadingMailbox: false,
	isLoadingMailAliases: false,
	isLoadingLookup: false,
	lookup_domain: [],
	lookup_host: [],
	mail_boxes: [],
	mail_boxes_pager: PAGE1,
	mail_aliases: [],
	daemon_version: '',
	mail_version: '',

	mail_service: undefined,
	mail_services: [],
	mail_service_domains: [],

	mail_domains: { items: [], pagination: PAGE1 },
	mail_domains_pager: PAGE1,
	servers: [],
	domains: [],
	isLoadingServers: false,
	isLoadingDomains: false,
	error: '',
}

export default (state = MAIL_INITIAL_STATE, action: MailServiceActions): MailDomainsPageState => {
	switch (action.type) {
		case ActionType.CREATE_MAIL_SERVICE:
			if (action.mode === AsyncActionMode.REQUEST) {
				return { ...state, daemon_version: undefined, mail_version: undefined, isLoadingService: true }
			}
			if (action.mode === AsyncActionMode.RESPONSE) {
				return {
					...state,
					mail_service: action.data,
					isLoadingService: false,
				}
			}
			if (action.mode === AsyncActionMode.ERROR) {
				return {
					...state,
					isLoadingService: false,
					error: action.error,
				}
			}
			break
		case ActionType.GET_MAIL_SERVICE:
			if (action.mode === AsyncActionMode.REQUEST) {
				return { ...state, daemon_version: undefined, mail_version: undefined, isLoadingService: true }
			}
			if (action.mode === AsyncActionMode.RESPONSE) {
				return {
					...state,
					mail_service: action.data,
					isLoadingService: false,
				}
			}
			if (action.mode === AsyncActionMode.ERROR) {
				return {
					...state,
					isLoadingService: false,
					error: action.error,
				}
			}
			break
		case ActionType.GET_MAIL_SERVICES:
			if (action.mode === AsyncActionMode.REQUEST) {
				return { ...state, mail_services: [], isLoadingService: true }
			}
			if (action.mode === AsyncActionMode.RESPONSE) {
				return {
					...state,
					mail_services: action.data,
					isLoadingService: false,
				}
			}
			if (action.mode === AsyncActionMode.ERROR) {
				return {
					...state,
					isLoadingService: false,
					error: action.error,
				}
			}
			break
		case ActionType.GET_MAIL_SERVICE_DEFAULT:
			if (action.mode === AsyncActionMode.REQUEST) {
				return { ...state, daemon_version: undefined, mail_version: undefined, isLoadingService: true }
			}
			if (action.mode === AsyncActionMode.RESPONSE) {
				return {
					...state,
					mail_service: action.data && action.data.length ? action.data[0] : undefined,
					isLoadingService: false,
				}
			}
			if (action.mode === AsyncActionMode.ERROR) {
				return {
					...state,
					isLoadingService: false,
					error: action.error,
				}
			}
			break
		case ActionType.UPDATE_MAIL_SERVICE:
			if (action.mode === AsyncActionMode.REQUEST) {
				return { ...state, isSavingService: true }
			}
			if (action.mode === AsyncActionMode.RESPONSE) {
				return {
					...state,
					mail_service: action.data,
					isSavingService: false,
				}
			}
			if (action.mode === AsyncActionMode.ERROR) {
				return {
					...state,
					isSavingService: false,
					error: action.error,
				}
			}
			break
		case ActionType.DELETE_MAIL_SERVICE:
			if (action.mode === AsyncActionMode.REQUEST) {
				return { ...state, isSavingService: true }
			}
			if (action.mode === AsyncActionMode.RESPONSE) {
				return {
					...state,
					mail_service: undefined,
					isSavingService: false,
				}
			}
			if (action.mode === AsyncActionMode.ERROR) {
				return {
					...state,
					isSavingService: false,
					error: action.error,
				}
			}
			break

		case ActionType.GET_MAIL_DOMAINS:
			if (action.mode === AsyncActionMode.REQUEST) {
				return {
					...state,
					mail_domain: undefined,
					daemon_version: undefined,
					mail_version: undefined,
					isLoadingService: true,
				}
			}
			if (action.mode === AsyncActionMode.RESPONSE) {
				return {
					...state,
					isLoadingService: false,
					mail_domains: action.data,
					mail_domains_pager: { ...action.data.pagination, page: action.data.pagination.page + 1 },
				}
			}
			if (action.mode === AsyncActionMode.ERROR) {
				return {
					...state,
					isLoadingService: false,
					error: action.error,
				}
			}
			break
		case ActionType.GET_MAIL_DOMAIN:
			if (action.mode === AsyncActionMode.REQUEST) {
				return { ...state, mail_domain: undefined, isLoadingDomain: true }
			}
			if (action.mode === AsyncActionMode.RESPONSE) {
				return {
					...state,
					isLoadingDomain: false,
					mail_domain: action.data,
				}
			}
			if (action.mode === AsyncActionMode.ERROR) {
				return {
					...state,
					isLoadingDomain: false,
					error: action.error,
				}
			}
			break
		case ActionType.CREATE_MAIL_DOMAIN:
			if (action.mode === AsyncActionMode.REQUEST) {
				return { ...state, mail_box: undefined, isSavingDomain: true }
			}
			if (action.mode === AsyncActionMode.RESPONSE) {
				let new_list = [...state.mail_domains.items] // force new obj
				new_list.unshift(action.data)
				return {
					...state,
					mail_domain: action.data,
					mail_domains: {
						...state.mail_domains,
						items: new_list,
					},
					isSavingDomain: false,
				}
			}
			if (action.mode === AsyncActionMode.ERROR) {
				return {
					...state,
					isSavingDomain: false,
					error: action.error,
				}
			}
			break
		case ActionType.GET_MAIL_DOMAINS_BY_SERVICE:
			if (action.mode === AsyncActionMode.REQUEST) {
				return { ...state, mail_service_domains: [], isLoadingService: true }
			}
			if (action.mode === AsyncActionMode.RESPONSE) {
				return {
					...state,
					isLoadingService: false,
					mail_service_domains: action.data,
				}
			}
			if (action.mode === AsyncActionMode.ERROR) {
				return {
					...state,
					isLoadingService: false,
					error: action.error,
				}
			}
			break
		case ActionType.DELETE_MAIL_DOMAIN:
			if (action.mode === AsyncActionMode.REQUEST) {
				return { ...state, isSavingDomain: true }
			}
			if (action.mode === AsyncActionMode.RESPONSE) {
				return {
					...state,
					mail_domain: undefined,
					mail_domains: {
						...state.mail_domains,
						items: state.mail_domains.items!.filter((c) => c.id !== action.request.id),
					},
					isSavingDomain: false,
				}
			}
			if (action.mode === AsyncActionMode.ERROR) {
				return {
					...state,
					isSavingDomain: false,
					error: action.error,
				}
			}
			break
		case ActionType.CHECK_MX:
			if (action.mode === AsyncActionMode.REQUEST) {
				return { ...state }
			}
			if (action.mode === AsyncActionMode.RESPONSE) {
				const resp: CheckMXResp = action.data
				return {
					...state,
					mail_domains: {
						...state.mail_domains,
						items: state.mail_domains.items.map((d) =>
							d.id === action.request.domainId ? { ...d, mx_check: action.data.is_mx_correct, mx: action.data.mx } : d
						),
					},
				}
			}
			if (action.mode === AsyncActionMode.ERROR) {
				return {
					...state,
				}
			}
			break

		case ActionType.GET_MAILBOXES:
			if (action.mode === AsyncActionMode.REQUEST) {
				return {
					...state,
					mail_box: undefined,
					mail_boxes: [],
					isLoadingMailbox: true,
				}
			}
			if (action.mode === AsyncActionMode.RESPONSE) {
				return {
					...state,
					isLoadingMailbox: false,
					mail_boxes: action.data,
				}
			}
			if (action.mode === AsyncActionMode.ERROR) {
				return {
					...state,
					isLoadingMailbox: false,
					error: action.error,
				}
			}
			break
		case ActionType.GET_MAILBOX:
			if (action.mode === AsyncActionMode.REQUEST) {
				return {
					...state,
					mail_box: undefined,
					isLoadingMailbox: true,
				}
			}
			if (action.mode === AsyncActionMode.RESPONSE) {
				let data = undefined
				if (action.data && Array.isArray(action.data) && action.data.length > 0) {
					data = action.data[0]
				}
				return {
					...state,
					isLoadingMailbox: false,
					mail_box: data,
				}
			}
			if (action.mode === AsyncActionMode.ERROR) {
				return {
					...state,
					isLoadingMailbox: false,
					error: action.error,
				}
			}
			break
		case ActionType.CREATE_MAILBOX:
			if (action.mode === AsyncActionMode.REQUEST) {
				return { ...state, mail_box: undefined, isSavingMailbox: true }
			}
			if (action.mode === AsyncActionMode.RESPONSE) {
				let new_list = [...state.mail_boxes] // force new obj
				new_list.unshift(action.data)
				return {
					...state,
					mail_box: action.data,
					mail_boxes: new_list,
					isSavingMailbox: false,
				}
			}
			if (action.mode === AsyncActionMode.ERROR) {
				return {
					...state,
					isSavingMailbox: false,
					error: action.error,
				}
			}
			break
		case ActionType.UPDATE_MAILBOX:
			if (action.mode === AsyncActionMode.REQUEST) {
				return { ...state, isSavingMailbox: true }
			}
			if (action.mode === AsyncActionMode.RESPONSE) {
				return {
					...state,
					mail_box: action.data,
					mail_boxes: state.mail_boxes.map((c) => (c.email === action.data.email ? { ...action.data } : c)),
					isSavingMailbox: false,
				}
			}
			if (action.mode === AsyncActionMode.ERROR) {
				return {
					...state,
					isSavingMailbox: false,
					error: action.error,
				}
			}
			break
		case ActionType.DELETE_MAILBOX:
			if (action.mode === AsyncActionMode.REQUEST) {
				return { ...state, isSavingMailbox: true }
			}
			if (action.mode === AsyncActionMode.RESPONSE) {
				return {
					...state,
					mail_box: undefined,
					// mail_boxes: state.mail_boxes!.filter((c) => c.id !== action.request.id),
					isSavingMailbox: false,
				}
			}
			if (action.mode === AsyncActionMode.ERROR) {
				return {
					...state,
					isSavingMailbox: false,
					error: action.error,
				}
			}
			break

		case ActionType.GET_MAIL_ALIASES:
			if (action.mode === AsyncActionMode.REQUEST) {
				return {
					...state,
					mail_aliases: [],
					isLoadingMailAliases: true,
				}
			}
			if (action.mode === AsyncActionMode.RESPONSE) {
				return {
					...state,
					isLoadingMailAliases: false,
					mail_aliases: action.data,
				}
			}
			if (action.mode === AsyncActionMode.ERROR) {
				return {
					...state,
					isLoadingMailAliases: false,
					error: action.error,
				}
			}
			break
		case ActionType.CREATE_MAIL_ALIAS:
			if (action.mode === AsyncActionMode.REQUEST) {
				return { ...state, mail_box: undefined, isSavingMailAlias: true }
			}
			if (action.mode === AsyncActionMode.RESPONSE) {
				let new_list = [...action.data, ...state.mail_aliases] // force new obj
				return {
					...state,
					mail_aliases: new_list,
					isSavingMailAlias: false,
				}
			}
			if (action.mode === AsyncActionMode.ERROR) {
				return {
					...state,
					isSavingMailAlias: false,
					error: action.error,
				}
			}
			break
		case ActionType.DELETE_MAIL_ALIAS:
			if (action.mode === AsyncActionMode.REQUEST) {
				return { ...state, isSavingMailAlias: true }
			}
			if (action.mode === AsyncActionMode.RESPONSE) {
				return {
					...state,
					// mail_aliases: state.mail_aliases!.filter((c) => c.id !== action.request.id),
					isSavingMailAlias: false,
				}
			}
			if (action.mode === AsyncActionMode.ERROR) {
				return {
					...state,
					isSavingMailAlias: false,
					error: action.error,
				}
			}
			break
		case ActionType.GET_DAEMON_VERSION:
			if (action.mode === AsyncActionMode.REQUEST) {
				return { ...state, daemon_version: undefined, error: undefined }
			}
			if (action.mode === AsyncActionMode.RESPONSE) {
				return {
					...state,
					daemon_version: action.data.deamon_version ? `${action.data.deamon_version}` : null,
				}
			}
			if (action.mode === AsyncActionMode.ERROR) {
				return {
					...state,
					daemon_version: null,
					error: action.error,
				}
			}
			break
		case ActionType.GET_MAIL_VERSION:
			if (action.mode === AsyncActionMode.REQUEST) {
				return { ...state, mail_version: undefined, error: undefined }
			}
			if (action.mode === AsyncActionMode.RESPONSE) {
				return {
					...state,
					mail_version: action.data.mail_version ? `${action.data.mail_version}` : null,
				}
			}
			if (action.mode === AsyncActionMode.ERROR) {
				return {
					...state,
					mail_version: null,
					error: action.error,
				}
			}
			break
		case ActionType.LOOKUP_MAIL_DOMAIN:
			if (action.mode === AsyncActionMode.REQUEST) {
				return { ...state, lookup_domain: [], isLoadingLookup: true }
			}
			if (action.mode === AsyncActionMode.RESPONSE) {
				if (action.field === 'name') {
					return {
						...state,
						lookup_domain: action.data,
						isLoadingLookup: false,
					}
				}
				console.error('Wrong action field.')
			}
			if (action.mode === AsyncActionMode.ERROR) {
				return {
					...state,
					error: action.error,
					isLoadingLookup: false,
				}
			}
			break
		case ActionType.LOOKUP_MAIL_SERVICE:
			if (action.mode === AsyncActionMode.REQUEST) {
				return { ...state, lookup_host: [], isLoadingLookup: true }
			}
			if (action.mode === AsyncActionMode.RESPONSE) {
				if (action.field === 'host') {
					return {
						...state,
						lookup_host: action.data,
						isLoadingLookup: false,
					}
				}
				console.error('Wrong action field.')
			}
			if (action.mode === AsyncActionMode.ERROR) {
				return {
					...state,
					error: action.error,
					isLoadingLookup: false,
				}
			}
			break
		case ActionType.GET_SERVERS_MAIL:
			if (action.mode === AsyncActionMode.REQUEST) {
				return { ...state, servers: [], isLoadingServers: true }
			}
			if (action.mode === AsyncActionMode.RESPONSE) {
				return {
					...state,
					servers: action.data,
					isLoadingServers: false,
				}
			}
			if (action.mode === AsyncActionMode.ERROR) {
				return {
					...state,
					isLoadingServers: false,
					error: action.error,
				}
			}
			break
		case ActionType.GET_DOMAINS_MAIL:
			if (action.mode === AsyncActionMode.REQUEST) {
				return { ...state, servers: [], isLoadingDomains: true }
			}
			if (action.mode === AsyncActionMode.RESPONSE) {
				return {
					...state,
					domains: action.data,
					isLoadingDomains: false,
				}
			}
			if (action.mode === AsyncActionMode.ERROR) {
				return {
					...state,
					isLoadingDomains: false,
					error: action.error,
				}
			}
			break
	}
	return state
}
