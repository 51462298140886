import React, { ReactNode, useEffect, useState } from 'react'
import {
    Button,
    Col,
    Divider,
    Form,
    Input,
    InputNumber,
    message,
    Modal,
    Row,
    Select,
    Spin,
    TreeSelect
} from 'antd'
import { useForm } from 'antd/lib/form/Form'
import TextArea from 'antd/lib/input/TextArea'
import { formItemLayout } from 'helpers/layoutHelpers'
import { InventoryModel } from 'pages/inventory/inventory/models'
import { useTranslation } from 'react-i18next'
import { InventoryTypeModel } from 'pages/inventory/type/models'
import moment from 'moment'
import { useDispatch, useSelector } from "react-redux"
import { AppState } from "../../../common/models"
import getInventoryPageLocations from "./actions/getInventoryPageLocations"
import getInventoryPageTypes from "./actions/getInventoryPageTypes"
import updateInventory from "./actions/updateInventory"
import {
    _formatPrice,
    _formatPriceStr,
    _parsePrice, decEn,
    getJsonParam,
    SQL_DATE_FORMAT, ZCOM_COMPANY_ID
} from "../../../common/fce"
import { useLoggedUser } from "../../../helpers/loginUserHelper"
import useLogger from "../../../common/useLogger"
import { sort_label, sort_name } from "../../../common/sorting"
import getIPMI from "./actions/getIPMI"
import { DeleteTwoTone, ExclamationCircleOutlined } from "@ant-design/icons"
import getInventoryStatuses from "./actions/getInventoryStatuses"
import "react-datepicker/dist/react-datepicker.css"
import './inventory.scss'
import UpdateInventoryStatus from "./actions/updateInventoryStatus"
import DeleteInventory from "./actions/deleteInventory"
import getInventoryBills from "./actions/getInventoryBills"
import { BillModel } from "../../billing/bill/models"
import { _validateInventoryForm } from "../common"
import DateInput from "../../../components/RangeFilter/DateInput"
import tableCompanies from "../../login/actions/tableCompanies"
import IpSelector from 'pages/ip/subnet/IpSelector'
import { IpModel } from 'pages/ip/subnet/models'

interface Props {
    setModalVisible: (values: boolean) => void
    objId?: number
}

const { Item } = Form
const { confirm } = Modal

const InventoryActive = 1
const InventorySold = 2

const InventoryEditForm = ({ setModalVisible, objId }: Props) => {
    const { t } = useTranslation()
    const [form] = useForm()
    const dispatch = useDispatch()
    const inputRef = React.useRef<any>(null)
    const dateRef = React.useRef<any>(null)

    const {
        inventory,
        isInventoryLoading,
        isSaving,
        types,
        bills,
        statuses,
        locations,
        ip_addresses,
        isLoadingIps,
    } = useSelector((state: AppState) => state.inventory)
    const { companies } = useSelector((state: AppState) => state.auth.tables)
    const { racks, isLoading: isLoadingRack } = useSelector((state: AppState) => state.rack)

    const [typeId, setTypeId] = useState<number>(0)
    const [statusId, setStatusId] = useState<number>(0)
    const [locationId, setLocationId] = useState<number>(0)
    const [ownerId, setOwnerId] = useState<number>(ZCOM_COMPANY_ID)
    const [billId, setBillId] = useState<number>(0)
    const [vendorId, setVendorId] = useState<number>(0)

    const [keys, setKeys] = useState<string[]>([])
    const [inventoryType, setInventoryType] = useState<InventoryTypeModel | undefined>(undefined)
    const [initData, setInitData] = useState({})

    const [ipmiData, setIpmiData] = useState<IpModel[]>([])
    const [selectedIpId, setSelectedIpId] = useState<number | undefined>()
    const [locationOptions, setLocationOptions] = useState<{ label: string, value: number }[]>([])
    const [companyOptions, setCompanyOptions] = useState<{ label: string, value: number }[]>([])
    const [statusOptions, setStatusOptions] = useState<{ label: string, value: number }[]>([])
    const [ownerOptions, setOwnerOptions] = useState<{ label: string, value: number }[]>([])
    const [billOptions, setBillOptions] = useState<{ label: string | ReactNode, value: number }[]>([])
    const [typeOptions, setTypeOptions] = useState<{ label: string, value: number }[]>([])
    const [createDate, setCreateDate] = useState<Date | undefined>()
    const [openStatus, setOpenStatus] = useState(false)
    const [confirmStatus, setConfirmStatus] = useState(false)
    const [openDelete, setOpenDelete] = useState(false)
    const [confirmDelete, setConfirmDelete] = useState(false)
    const [validStatus, setValidStatus] = useState(false)
    const [isOwnerZcom, setOwnerZcom] = useState<boolean>(false)

    const [isViewer, setViewer] = useState(false)
    const [isCreator, setCreator] = useState(false)
    const [isEditor, setEditor] = useState(false)
    const [isDeleter, setDeleter] = useState(false)

    // get settings and logged user from store
    const loggedUser = useLoggedUser()
    if (!loggedUser || !loggedUser.isLoaded()) {
        return (<Spin />)
    }
    const appSetting = loggedUser.getAppSettings()
    appSetting.debug = true
    const logger = useLogger(appSetting, 'InventoryEditForm')

    useEffect(() => {
        if (loggedUser) {
            setViewer(loggedUser.hasAccess('page_inventory'))
            setCreator(loggedUser.hasAccess('page_inventory_create_button'))
            setEditor(loggedUser.hasAccess('page_inventory_edit_button'))
            setDeleter(loggedUser.hasAccess('page_inventory_delete_button'))
        }
        if (!companies || companies.length === 0) {
            dispatch(tableCompanies())
            return
        }
        if (!locations || locations.length === 0) {
            dispatch(getInventoryPageLocations())
            return
        }
        if (!statuses || statuses.length === 0) {
            dispatch(getInventoryStatuses())
            return
        }
        if (!types || types.length === 0) {
            dispatch(getInventoryPageTypes())
            return
        }
        setStatusOptions(statuses.sort(sort_name).map(t => ({ label: t.name, value: t.id })))
        setLocationOptions(locations.sort(sort_name).map(t => ({ label: t.name, value: t.id })))
        setCompanyOptions(companies.sort(sort_name).map(t => ({ label: t.name, value: t.id })))
        setOwnerOptions(companies.sort(sort_name).map(t => ({ label: t.name, value: t.id })))
        setTypeOptions(types.map(t => ({ label: t.name, value: t.id })).sort(sort_label))

    }, [companies, statuses, locations, types])

    useEffect(() => {
        if (inventory && inventory.id && objId === inventory.id) {
            logger('inventory: ' + JSON.stringify(inventory))
            if (inventory.vendor_id) {
                setVendorId(inventory.vendor_id)
            }
            const it = types.find(t => t.id === inventory.type_id)
            setInventoryType(it)

            if (inventory.purchase_date) {
                const dt = moment(inventory.purchase_date, SQL_DATE_FORMAT).toDate()
                setCreateDate(dt)
            }

            setTypeId(inventory.type_id)
            if (it) {
                inventory.typeKeys = it.keys
                if (it.keys && it.keys.length) {
                    logger('keys: ' + JSON.stringify(it.keys))
                    setKeys(it.keys.filter(k => k.trim().length > 0))
                }
                else {
                    setKeys([])
                }
            }
            else {
                // never
                console.warn('inventory: unknown type???')
                inventory.typeKeys = []
            }
        }
    }, [inventory, types])

    useEffect(() => {
        if (inventoryType && inventory && objId === inventory.id) {
            let data = {
                ...inventory,
                ipmi_ip: inventory.ipmi_id ? inventory.ipmi_id : undefined,
                price: inventory.price ? _formatPrice(inventory.price / 100 + 0.001) : undefined,
                purchase_date: inventory.purchase_date ? appSetting.renderDate(inventory.purchase_date) : ''
            }
            if (inventory.parameters && inventoryType.keys && inventoryType.keys.length) {
                for (let k of inventoryType.keys) {
                    logger('useEffect: initData3 ' + k + ' = ' + getJsonParam(inventory.parameters, k))
                    data[k] = getJsonParam(inventory.parameters, k)
                }
            }
            else {
                console.warn('warn: inventory.parameters')
            }
            logger('useEffect: initData5 ' + JSON.stringify(data))
            setInitData(data)
            if (inventory.ipmi_id) {
                setSelectedIpId(inventory.ipmi_id)
                form.setFieldsValue({ 'ipmi_ip': inventory.ipmi_id })
                logger('=====ipmi_idipmi_idipmi_idipmi_idipmi_id: initData5 ')
            }
        }
    }, [inventoryType, inventory])

    useEffect(() => {
        if (inventoryType && inventory && initData) {
            logger('useEffect: initData4' + JSON.stringify(initData))
        }
        validateForm()
    }, [initData])

    useEffect(() => {
        if (locationId > 0) {
            form.setFieldsValue({ 'location_id': locationId })
            logger('useEffect: locationId' + locationId)
        }
    }, [locationId])

    useEffect(() => {
        if (vendorId) {
            dispatch(getInventoryBills(vendorId))
        }
        // setValidStatus(true)
    }, [vendorId])

    useEffect(() => {
        if (bills) {
            setBillOptions(bills.sort(sort_name).map(b => ({ label: renderBill(b), value: b.id })))
        }
    }, [bills])

    useEffect(() => {
        if (ownerId) {
            setOwnerZcom(ownerId === ZCOM_COMPANY_ID)
        }
        else {
            setOwnerZcom(false)
        }
        // setValidStatus(true)
    }, [ownerId])

    useEffect(() => {
        if (ip_addresses) {
            setIpmiData(ip_addresses.filter(ip => ip.is_ipmi === 1))
        }
    }, [ip_addresses])

    const fetchIpLookup = (searchText: string) => {
        // call lookup for Ip_name
        if (appSetting.checkMinSearch(searchText)) {
            dispatch(getIPMI(searchText))
        }
    }

    const renderBill = (item: BillModel) => {
        return (
            <div className='name'>
                <span>{item.number} ({t('inventoryPage.day_at')}:{item.date_of_maturity})</span>
            </div>
        )
    }

    const selectType = (tid: number | undefined) => {
        if (!tid) {
            return
        }
        setTypeId(tid)
        setValidStatus(true)
    }

    const canChangeType = () => {
        if (inventory && inventory.parent) {
            return inventory.parent.parent_id === null
        }
        return true
    }

    const showConfirmStatus = (sid: number, title: string) => {
        if (!inventory) {
            return
        }
        confirm({
            icon: <ExclamationCircleOutlined />,
            title: title,
            content: <p>{inventory && inventory.name}</p>,
            okText: t('general.yes'),
            cancelText: t('general.cancel'),
            okButtonProps: { loading: confirmStatus },
            className: 'confirm-warning',
            onOk() {
                setConfirmStatus(true)
                const params: InventoryModel = { ...inventory, status_id: sid }
                dispatch(UpdateInventoryStatus(params, suc => {
                    setConfirmStatus(false)
                    setOpenStatus(false)
                    suc && message.success(t('general.success'))
                }))
            },
            onCancel() {
                setOpenStatus(false)
                form.setFieldsValue({ 'status_id': inventory.status_id })
            },
        })
    }

    const selectStatus = (sid: number | undefined) => {
        if (!sid) {
            return
        }
        if (sid === 3) {
            // Likvidace
            showConfirmStatus(sid, t('inventoryPage.confirm_remove_from_evidence_dispose'))
            return
        }
        if (sid === 4) {
            // Vyrazeno
            showConfirmStatus(sid, t('inventoryPage.confirm_remove_from_evidence'))
            return
        }
        setStatusId(sid)
        setValidStatus(true)
    }

    const showConfirmDelete = (title: string) => {
        if (!inventory) {
            return
        }
        if (!isDeleter) {
            message.error(t('general.error403'))
            return
        }
        confirm({
            icon: <ExclamationCircleOutlined style={{ color: 'red' }} />,
            title: title,
            content: <p>{inventory && inventory.name}</p>,
            okText: t('general.yes'),
            cancelText: t('general.cancel'),
            okButtonProps: { loading: confirmDelete },
            className: 'confirm-alert',
            onOk() {
                setConfirmDelete(true)
                dispatch(DeleteInventory(inventory.id, suc => {
                    setConfirmDelete(false)
                    setOpenDelete(false)
                    suc && message.success(t('general.success'))
                    suc && setModalVisible(false)
                }))
            },
            onCancel() {
                setOpenDelete(false)
            },
        })
    }

    const validateForm = () => {
        return _validateInventoryForm(form, isOwnerZcom, t)
    }

    const handleFormatter = e => {
        let value = appSetting.renderPrice(_parsePrice(e.target.value))
        form.setFieldsValue({ price: decEn(value) })
    }

    const renderParamField = (k) => {
        if (k === 'ipmi_ip') {
            return (
                <Item name={k} key={k} label={t(`inventoryPage.${k}`)}>
                    <IpSelector
                        dataSource={ipmiData}
                        isLoadingIps={isLoadingIps}
                        handleSelectedIp={(ip_id) => { setSelectedIpId(ip_id); setValidStatus(true) }}
                        fetchData={fetchIpLookup}
                        shadowUsed={true}
                        initValue={(inventory && inventory.ipmi) ? inventory.ipmi.address : undefined}
                    />
                </Item>
            )
        }
        if (k === 'u_size') {
            return (
                <Item name={k} key={k} label={t(`inventoryPage.${k}`)}>
                    <InputNumber onChange={() => setValidStatus(true)} />
                </Item>
            )
        }
        if (k === 'slots') {
            return (
                <Item name={k} key={k} label={t(`inventoryPage.${k}`)}>
                    <InputNumber onChange={() => setValidStatus(true)} />
                </Item>
            )
        }
        if (k === 'power') {
            return (
                <Item name={k} key={k} label={t(`inventoryPage.${k}`)}>
                    <InputNumber onChange={() => setValidStatus(true)} addonAfter="W" />
                </Item>
            )
        }
        return (
            <Item name={k} key={k} label={t(`inventoryPage.${k}`)}>
                <Input onChange={() => setValidStatus(true)} />
            </Item>
        )
    }

    const isInventoryUsed = () => {
        return inventory && inventory.is_used != undefined && inventory.is_used > 0
    }
    const isInventoryActive = () => {
        return inventory && inventory.status_id === InventoryActive
    }

    const getFormParams = () => {
        const params = {}
        const power = form.getFieldValue('power')
        if (power && (power > 0)) {
            params['power'] = power
        }
        const u_size = form.getFieldValue('u_size')
        if (u_size && parseInt(u_size) > 0) {
            params['u_size'] = parseInt(u_size)
        }
        const slots = form.getFieldValue('slots')
        if (slots && parseInt(slots) > 0) {
            params['slots'] = parseInt(slots)
        }
        if (selectedIpId && selectedIpId > 0) {
            params['ipmi_ip'] = selectedIpId
        }
        return params
    }

    const onFinish = (values) => {
        if (!isEditor) {
            message.error(t('general.error403'))
            return
        }
        logger('onFinish: ' + JSON.stringify(values))
        const purchase_date = form.getFieldValue('purchase_date')
        const price = values['price']
        const params = {
            ...values,
            id: inventory && inventory.id,
            ipmi_ip: undefined,
            power: undefined,
            u_size: undefined,
            purchase_date: createDate ? appSetting.renderDateSQL(createDate) : null,
            price: price ? _parsePrice(form.getFieldValue('price')) : undefined,
            parameters: JSON.stringify(getFormParams())
        }

        logger('onFinish params: ' + JSON.stringify(params))
        dispatch(updateInventory(
            params,
            suc => {
                if (suc) {
                    message.success(t('general.success'))
                    setModalVisible(false)
                }
            }
        ))
    }

    if (!inventory || isInventoryLoading || !objId || !inventoryType) {
        return (<Spin />)
    }
    if (!initData || Object.keys(initData).length === 0) {
        return (<Spin />)
    }
    if (inventory.id != objId) {
        return (<Spin />)
    }

    return (
        <div style={{ width: '100%' }} className='inventoryEditForm'>
            <Form
                {...formItemLayout}
                onFinish={onFinish}
                form={form}
                initialValues={
                    {
                        ...initData,
                    }
                }
            >
                <Row>
                    <Col span={11}>
                        <Item name='inv_no' label={t('inventoryPage.inv_no')}>
                            <Input disabled />
                        </Item>
                    </Col>
                    <Col span={2}></Col>
                    <Col span={11}>
                        <Item name='status_id' label={t('inventoryPage.status')}>
                            <Select
                                showSearch
                                optionFilterProp='label'
                                onChange={selectStatus}
                                options={statusOptions}
                            />
                        </Item>
                    </Col>
                </Row>

                <Row>
                    <Col span={24}>
                        <Item name='name' labelCol={{ span: 3 }} wrapperCol={{ span: 21 }}
                            label={t('inventoryPage.name')}
                            rules={[{ required: true, message: t('errors.field_required') }]}>
                            <TextArea size="large" autoFocus style={{ marginLeft: '20px' }}
                                onChange={() => setValidStatus(true)}
                                data-gramm="false"
                                data-gramm_editor="false"
                                data-enable-grammarly="false" />
                        </Item>
                    </Col>
                </Row>
                <Row>
                    <Col span={11}>
                        <Item name='type_id' label={t('inventoryPage.type')}
                            rules={[{ required: true, message: t('errors.field_required') }]}
                            validateTrigger={["onBlur", "onFocus", "onInput"]}
                        >
                            <Select
                                showSearch
                                optionFilterProp='label'
                                onChange={selectType}
                                options={typeOptions}
                                disabled={!canChangeType()}
                            />
                        </Item>

                        <Item name='location_id' label={t('inventoryPage.location')}
                            rules={[{ required: true, message: t('errors.field_required') }]}>
                            <Select
                                showSearch={true}
                                filterOption={true}
                                optionFilterProp='label'
                                options={locationOptions}
                                onChange={() => setValidStatus(true)}
                            />
                        </Item>

                        <Item name='vendor_id' label={t('inventoryPage.vendor')}>
                            <Select
                                showSearch
                                allowClear={true}
                                filterOption={true}
                                optionFilterProp='label'
                                options={companyOptions}
                                dropdownMatchSelectWidth={200}
                                onChange={() => setValidStatus(true)}
                            />
                        </Item>

                        <Item name='bill_id' label={t('inventoryPage.bill')}>
                            <Select
                                showSearch
                                allowClear={true}
                                filterOption={true}
                                optionFilterProp='label'
                                options={billOptions}
                                onChange={(v) => { setBillId(v); setValidStatus(true) }}
                            />
                        </Item>

                        <Item name='purchase_date'
                            label={t('inventoryPage.purchase_date')}
                            rules={[{ required: false }]}>
                            <DateInput htmlId='__purchase_date'
                                initDateStr={appSetting.renderDateSQL(createDate)}
                                disabled={false}
                                required={false}
                                format={appSetting.date_picker_format} // not Moment formats!!
                                onChange={(date) => {
                                    if (date) {
                                        form.setFieldsValue({ purchase_date: date })
                                        setCreateDate(new Date(date))
                                    }
                                    else {
                                        form.setFieldsValue({ purchase_date: undefined })
                                    }
                                    setValidStatus(true)
                                }}
                            />
                        </Item>
                        <Item name='price' label={t('inventoryPage.price')}>
                            <InputNumber ref={inputRef}
                                precision={2}
                                decimalSeparator=','
                                addonAfter='CZK'
                                style={{ width: '160px' }}
                                className="price"
                                onKeyPress={(e) => { if (e.key === 'Enter') { handleFormatter(e) } }}
                                onBlur={handleFormatter} />
                        </Item>

                        <Item name='warranty' label={t('inventoryPage.warranty')}>
                            <InputNumber addonAfter='mes' onChange={() => setValidStatus(true)} />
                        </Item>
                    </Col>
                    <Col span={2}></Col>
                    <Col span={11}>

                        <Item name='serial' label={t('inventoryPage.serial')}>
                            <Input onChange={() => setValidStatus(true)} />
                        </Item>

                        <Item name='owner_id' label={t('inventoryPage.owner')}>
                            <Select
                                showSearch
                                filterOption={true}
                                optionFilterProp='label'
                                options={ownerOptions}
                                onChange={(v) => { setOwnerId(v); setValidStatus(true) }}
                            />
                        </Item>
                        <Item name='comment' label={t('inventoryPage.comment')}>
                            <TextArea data-gramm="false"
                                data-gramm_editor="false"
                                data-enable-grammarly="false"
                                onChange={() => setValidStatus(true)} />
                        </Item>
                        <Divider />
                        {
                            inventory.typeKeys &&
                            inventory.typeKeys.filter(k => k.trim().length > 0 && k != 'rack_id').map(k => renderParamField(k))
                        }
                        <Divider />
                    </Col>
                </Row>
                <Row>
                    <Col span={24} style={{ textAlign: 'center' }}>
                        <Item labelCol={{ span: 4 }} wrapperCol={{ span: 8 }} style={{ margin: '1px 40%' }}>
                            <Button loading={isSaving} type='primary' disabled={!validStatus} htmlType='submit'>
                                {t('inventoryPage.update')}
                            </Button>
                        </Item>
                    </Col>
                </Row>

                <Row style={{ borderTop: '1px solid #dcdcdc', marginTop: '5px', paddingTop: '5px' }}>
                    <Col span={5}>
                        <Button danger size='small'
                            disabled={!isDeleter || isInventoryUsed() || !isInventoryActive()}
                            onClick={() => showConfirmDelete(t('inventoryPage.confirm_delete_item'))}
                            icon={<DeleteTwoTone twoToneColor='red' />}>{t('general.delete')}</Button>
                    </Col>
                    <Col span={19} className='left' style={{ color: '#ccc' }}>
                        {
                            isInventoryUsed() && (
                                <span>{t('inventoryPage.used')}: {inventory.used}</span>
                            )
                        }
                    </Col>
                </Row>
            </Form>

        </div>

    )
}

export default InventoryEditForm
