import { GetVhostsRequestAction, GetVhostsResponseAction, GetVhostsErrorAction } from './index'
import { protectedApiClient } from 'helpers/api'
import { handleApiErrorWithNotification } from 'helpers/errorHandling'
import { TemplateGrid, VhostGrid, VhostModel, WebTemplateModel } from '../models'

export default (pageSize: number, pageNumber: number, filter: string, cb?: (isSuccess: boolean) => void) => {
	return (dispatch: (arg: GetVhostsRequestAction | GetVhostsResponseAction | GetVhostsErrorAction) => void) => {
		const request = new GetVhostsRequestAction()
		dispatch(request)

		let url = `/web/vhost?expand=service,customer${filter}&pagination[pageSize]=${pageSize}&pagination[page]=${pageNumber}`
		protectedApiClient
			.get<VhostGrid>(url)
			.then((response) => {
				dispatch(new GetVhostsResponseAction(request, response.data))
				cb && cb(true)
			})
			.catch((error) => {
				dispatch(new GetVhostsErrorAction(request, error.toString()))
				handleApiErrorWithNotification(error)
				cb && cb(false)
			})
	}
}
