import { useHistory } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { useTranslation } from "react-i18next"
import { useForm } from "antd/es/form/Form"
import { Button, Col, Form, Input, InputNumber, message, Result, Row, Spin, Tag } from "antd"
import { formItemLayout, tailLayout } from "helpers/layoutHelpers"
import createMailbox from "./actions/createMailbox"
import { CreateMailboxParams } from "./models"
import { AppState } from "common/models"
import { useState } from "react"
import { Store } from "antd/es/form/interface"
import { EyeInvisibleOutlined, SyncOutlined } from "@ant-design/icons"
import { EyeTwoTone } from "@ant-design/icons"


interface Props {
  onClose: () => void
}

const { Item } = Form


const CreateMailboxForm = (props: Props) => {
  const [form] = useForm()
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const { mail_service, mail_box, mail_domain, isSavingMailbox, error } = useSelector((state: AppState) => state.mailservice)

  const [updateStatus, setUpdateStatus] = useState(false)

  const PAGE_FORM = 0
  const PAGE_LOADING = 1
  const PAGE_SUCCESS = 2
  const PAGE_ERROR = 3
  const [pageState, setPageState] = useState<number>(PAGE_FORM)

  const onFormSubmit = (values: Store) => {
    if (!mail_domain) {
      message.error('Mail domain is not found')
      return
    }
    if (!values.mailbox) {
      message.error('Email is required')
      return
    }

    const params: CreateMailboxParams = {
      mail_domain_id: mail_domain.id,
      mailbox: values.mailbox,
      quota: values.quota ? values.quota : undefined
    }
    setPageState(PAGE_LOADING)
    dispatch(createMailbox(params, (suc) => {
      if (suc) {
        message.success(t('general.success'))
        setPageState(PAGE_SUCCESS)
      }
      else {
        setPageState(PAGE_ERROR)
      }
    }))
  }

  if (!mail_domain || !mail_domain.id) {
    return <Spin />
  }

  if (pageState === PAGE_FORM) {
    return (
      <Form
        onFinish={onFormSubmit}
        form={form}
        onChange={() => setUpdateStatus(true)}
        initialValues={{ quota: 0 }}
        {...formItemLayout}
      >

        <Form.Item name='mailbox' label='Email' rules={[{ required: true }]}>
          <Input data-gramm="false" data-1p-ignore suffix={`@${mail_domain.name}`} autoFocus />
        </Form.Item>

        <Form.Item name='quota' label={t('mailDomainsPage.quota')}>
          <InputNumber min={0} max={1000000000} step={1} addonAfter='MB' />
        </Form.Item>

        <Item {...tailLayout}>
          <Button type='primary'
            loading={isSavingMailbox}
            disabled={!updateStatus}
            htmlType='submit'>
            {t('general.create')}
          </Button>
        </Item>
      </Form>
    )
  }

  if (pageState === PAGE_LOADING) {
    return (
      <Row>
        <Col span={24} className='center'>Communication with server <b>{mail_service?.host}</b></Col>
        <Col span={24} className='center'>&nbsp;</Col>
        <Col span={24} className='center'>
          <Tag icon={<SyncOutlined spin />} color="processing">
            processing
          </Tag>
        </Col>
      </Row>
    )
  }

  if (pageState === PAGE_SUCCESS) {
    return (
      <Result
        status="success"
        style={{ padding: 0 }}
        title={
          <p style={{ fontSize: '16px' }}>
            {t('mailDomainsPage.mailbox_created')} <br />
          </p>
        }
        subTitle={
          <Row className="desc">
            <Col span={12} className="right pad4">Mailbox: </Col>
            <Col span={12} className='left pad4'><span style={{ fontFamily: 'monospace' }}>{mail_box?.email}</span></Col>
            <Col span={12} className="right pad4">Password:</Col>
            <Col span={12} className='left pad4'><span style={{ fontFamily: 'monospace' }}><Tag>{mail_box?.password}</Tag></span></Col>

          </Row>
        }
        extra={[
          <Button key='close' onClick={() => { props.onClose() }}>{t('general.close')}</Button>
        ]}
      />
    )
  }


  return (
    <Row>
      <Col span={24}>
        <h1>Error</h1>
      </Col>
      <Col span={12} className="text-right">Mailbox: </Col>
      <Col span={12}>{mail_box?.mailbox}</Col>
      <Col span={12} className="text-right">Error:</Col>
      <Col span={12}>{error}</Col>
      <Col span={24} className="text-right">
        <Button type="primary" onClick={() => props.onClose()}>Close</Button>
      </Col>
    </Row>
  )

}

export default CreateMailboxForm
