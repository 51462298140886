import {AsyncActionMode} from "../../../../common/models";
import {CreateRoleParam, EndPointModel, EndpointParams, RoleModel, RoleParams} from "../models";


export enum ActionType {
    GET_ROLE = 'GET_ROLE',
    GET_ROLES = 'GET_ROLES',
    CREATE_ROLE = 'CREATE_ROLE',
    UPDATE_ROLE = 'UPDATE_ROLE',
    DELETE_ROLE = 'DELETE_ROLE',
    GET_ENDPOINTS = 'GET_ENDPOINTS',
    GET_ENDPOINT = 'GET_ENDPOINT',
    GET_ALL_ENDPOINTS = 'GET_ALL_ENDPOINTS',
    UPDATE_ENDPOINT = 'UPDATE_ENDPOINT'
}

export type RoleActions =
    | GetRoleRequestAction
    | GetRoleResponseAction
    | GetRoleErrorAction
    | GetRolesRequestAction
    | GetRolesResponseAction
    | GetRolesErrorAction
    | CreateRoleRequestAction
    | CreateRoleResponseAction
    | CreateRoleErrorAction
    | UpdateRoleRequestAction
    | UpdateRoleResponseAction
    | UpdateRoleErrorAction
    | DeleteRoleRequestAction
    | DeleteRoleResponseAction
    | DeleteRoleErrorAction
    | GetEndpointRequestAction
    | GetEndpointResponseAction
    | GetEndpointErrorAction
    | GetEndpointsRequestAction
    | GetEndpointsResponseAction
    | GetEndpointsErrorAction
    | GetAllEndpointsRequestAction
    | GetAllEndpointsResponseAction
    | GetAllEndpointsErrorAction
    | UpdateEndpointRequestAction
    | UpdateEndpointResponseAction
    | UpdateEndpointErrorAction


export class GetRoleRequestAction {
    readonly type = ActionType.GET_ROLE
    readonly mode = AsyncActionMode.REQUEST
    constructor(casbin_key: string) {
        ''
    }
}
export class GetRoleResponseAction {
    readonly type = ActionType.GET_ROLE
    readonly mode = AsyncActionMode.RESPONSE
    constructor(
        public request: GetRoleRequestAction,
        public data: RoleModel,
    ) {}
}
export class GetRoleErrorAction {
    readonly type = ActionType.GET_ROLE
    readonly mode = AsyncActionMode.ERROR
    constructor(
        public request: GetRoleRequestAction,
        public error: string,
    ) {}
}

export class GetRolesRequestAction {
    readonly type = ActionType.GET_ROLES
    readonly mode = AsyncActionMode.REQUEST
    constructor() {
        ''
    }
}
export class GetRolesResponseAction {
    readonly type = ActionType.GET_ROLES
    readonly mode = AsyncActionMode.RESPONSE
    constructor(
        public request: GetRolesRequestAction,
        public data: RoleModel[],
    ) {}
}
export class GetRolesErrorAction {
    readonly type = ActionType.GET_ROLES
    readonly mode = AsyncActionMode.ERROR
    constructor(
        public request: GetRolesRequestAction,
        public error: string,
    ) {}
}


export class CreateRoleRequestAction {
    readonly type = ActionType.CREATE_ROLE
    readonly mode = AsyncActionMode.REQUEST
    constructor(public payload: CreateRoleParam) {
    }
}

export class CreateRoleResponseAction {
    readonly type = ActionType.CREATE_ROLE
    readonly mode = AsyncActionMode.RESPONSE
    constructor(
        public request: CreateRoleRequestAction,
        public data: RoleModel,
    ) {
    }
}

export class CreateRoleErrorAction {
    readonly type = ActionType.CREATE_ROLE
    readonly mode = AsyncActionMode.ERROR

    constructor(
        public request: CreateRoleRequestAction,
        public error: string,
    ) {
    }
}

export class UpdateRoleRequestAction {
    readonly type = ActionType.UPDATE_ROLE
    readonly mode = AsyncActionMode.REQUEST
    constructor(public item: RoleParams) {
    }
}

export class UpdateRoleResponseAction {
    readonly type = ActionType.UPDATE_ROLE
    readonly mode = AsyncActionMode.RESPONSE
    constructor(
        public request: UpdateRoleRequestAction,
        public data: RoleModel,
    ) {
    }
}

export class UpdateRoleErrorAction {
    readonly type = ActionType.UPDATE_ROLE
    readonly mode = AsyncActionMode.ERROR
    constructor(
        public request: UpdateRoleRequestAction,
        public error: string,
    ) {
    }
}

export class DeleteRoleRequestAction {
    readonly type = ActionType.DELETE_ROLE
    readonly mode = AsyncActionMode.REQUEST
    constructor(public casbin_key: string) {
        ''
    }
}

export class DeleteRoleResponseAction {
    readonly type = ActionType.DELETE_ROLE
    readonly mode = AsyncActionMode.RESPONSE
    constructor(
        public request: DeleteRoleRequestAction,
        public data: RoleModel,
    ) {
    }
}

export class DeleteRoleErrorAction {
    readonly type = ActionType.DELETE_ROLE
    readonly mode = AsyncActionMode.ERROR
    constructor(
        public request: DeleteRoleRequestAction,
        public error: string,
    ) {
    }
}


export class GetEndpointRequestAction {
    readonly type = ActionType.GET_ENDPOINT
    readonly mode = AsyncActionMode.REQUEST
    constructor(id: number) {
        ''
    }
}
export class GetEndpointResponseAction {
    readonly type = ActionType.GET_ENDPOINT
    readonly mode = AsyncActionMode.RESPONSE
    constructor(
        public request: GetEndpointRequestAction,
        public data: EndPointModel,
    ) {}
}
export class GetEndpointErrorAction {
    readonly type = ActionType.GET_ENDPOINT
    readonly mode = AsyncActionMode.ERROR
    constructor(
        public request: GetEndpointRequestAction,
        public error: string,
    ) {}
}

export class GetEndpointsRequestAction {
    readonly type = ActionType.GET_ENDPOINTS
    readonly mode = AsyncActionMode.REQUEST
    constructor(casbin_key: string) {
        ''
    }
}
export class GetEndpointsResponseAction {
    readonly type = ActionType.GET_ENDPOINTS
    readonly mode = AsyncActionMode.RESPONSE
    constructor(
        public request: GetEndpointsRequestAction,
        public data: EndPointModel[],
    ) {}
}
export class GetEndpointsErrorAction {
    readonly type = ActionType.GET_ENDPOINTS
    readonly mode = AsyncActionMode.ERROR
    constructor(
        public request: GetEndpointsRequestAction,
        public error: string,
    ) {}
}


export class GetAllEndpointsRequestAction {
    readonly type = ActionType.GET_ALL_ENDPOINTS
    readonly mode = AsyncActionMode.REQUEST
    constructor() {
        ''
    }
}
export class GetAllEndpointsResponseAction {
    readonly type = ActionType.GET_ALL_ENDPOINTS
    readonly mode = AsyncActionMode.RESPONSE
    constructor(
        public request: GetAllEndpointsRequestAction,
        public data: EndPointModel[],
    ) {}
}
export class GetAllEndpointsErrorAction {
    readonly type = ActionType.GET_ALL_ENDPOINTS
    readonly mode = AsyncActionMode.ERROR
    constructor(
        public request: GetAllEndpointsRequestAction,
        public error: string,
    ) {}
}

export class UpdateEndpointRequestAction {
    readonly type = ActionType.UPDATE_ENDPOINT
    readonly mode = AsyncActionMode.REQUEST
    constructor(public params: EndpointParams) {
    }
}

export class UpdateEndpointResponseAction {
    readonly type = ActionType.UPDATE_ENDPOINT
    readonly mode = AsyncActionMode.RESPONSE
    constructor(
        public request: UpdateEndpointRequestAction,
        public data: RoleModel,
    ) {
    }
}

export class UpdateEndpointErrorAction {
    readonly type = ActionType.UPDATE_ENDPOINT
    readonly mode = AsyncActionMode.ERROR
    constructor(
        public request: UpdateEndpointRequestAction,
        public error: string,
    ) {
    }
}
