import {ProlongCertificateRequestAction, ProlongCertificateResponseAction, ProlongCertificateErrorAction} from '.'
import {protectedApiClient} from 'helpers/api'
import {handleApiErrorWithNotification} from 'helpers/errorHandling'
import {CertificateModel} from '../models'

export default (id: number, cb?: (isSuccess: boolean) => void) => {
	return (
		dispatch: (
			arg: ProlongCertificateRequestAction | ProlongCertificateResponseAction | ProlongCertificateErrorAction,
		) => void,
	) => {
		const request = new ProlongCertificateRequestAction(id)
		dispatch(request)

		protectedApiClient
			.put<CertificateModel>(`/certificate/certificate/${id}/prolong`)
			.then((response) => {
				dispatch(new ProlongCertificateResponseAction(request, response.data))
				cb && cb(true)
			})
			.catch((error) => {
				dispatch(new ProlongCertificateErrorAction(request, error))
				handleApiErrorWithNotification(error)
				cb && cb(false)
			})
	}
}
