import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { useForm } from "antd/lib/form/Form"
import { Button, Col, Divider, Form, Input, message, Row, Select, Spin, Switch } from "antd"
import React, { useEffect, useState } from "react"
import { Store } from "antd/lib/form/interface"
import { CreateServerParams, ServerAddRemoveIpParams } from "../../server/models"
import createServer from "../../server/actions/createServer"
import { sort_label } from "../../../common/sorting"
import tableCustomers from "../../login/actions/tableCustomers"
import { AppState } from "../../../common/models"
import { useLoggedUser } from "../../../helpers/loginUserHelper"
import { tailLayout } from "../../../helpers/layoutHelpers"
import { _isValidDomain, _isValidIP4, stopPropagation } from "../../../common/fce"
import { CheckOutlined, CloseOutlined } from "@ant-design/icons"
import getServerTypes from "pages/server/actions/getServerTypes"
import { IpAddressModel } from "./models"
import ServerSelector from "pages/server/ServerSelector"
import getServersIp from "./actions/getServersIp"
import addIpToServer from "pages/server/actions/addIpToServer"

interface Props {
  selectedIp?: IpAddressModel
  onClose: () => void
}


const NewServerIpForm = (props: Props) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [form1] = useForm()
  const [form2] = useForm()

  const { customers } = useSelector((state: AppState) => state.auth.tables)
  const { subnet, servers, isLoadingServers } = useSelector((state: AppState) => state.ipSubnet)

  const { server_types } = useSelector((state: AppState) => state.logic_server)

  const [customerOptions, setCustomerOptions] = useState<{ label: string, value: number, key: number }[]>([])
  const [serverTypeOptions, setServerTypeOptions] = useState<{ label: string, value: number, key: number }[]>([])
  const [selectedIpId, setSelectedIpId] = useState<number | undefined>()
  const [addDnsRec, setAddDnsRec] = useState<boolean>(false)
  const [addReverse, setAddReverse] = useState<boolean>(false)

  const [updateStatus, setUpdateStatus] = useState<boolean>(false)
  const [selectedServerId, setSelectedServerId] = useState<number | undefined>()


  // get settings and logged user from store
  const loggedUser = useLoggedUser()
  if (!loggedUser || !loggedUser.isLoaded()) {
    return (<Spin />)
  }
  const appSetting = loggedUser.getAppSettings()

  useEffect(() => {
    // once
    dispatch(getServerTypes())
    if (!customers || customers.length === 0) {
      dispatch(tableCustomers())
    }
  }, [])

  useEffect(() => {
    if (customers && customers.length) {
      // render CustomerOptions
      const data = customers.map((c) => {
        return {
          value: c.id,
          key: c.id,
          label: c.company!.name
        }
      })
      setCustomerOptions(data.sort(sort_label))
    }
  }, [customers])

  useEffect(() => {
    if (server_types) {
      setServerTypeOptions(
        server_types.map(item => ({
          value: item.id,
          key: item.id,
          label: item.name
        }))
      )
    }
  }, [server_types])

  const loadServerLookup = (pattern: string) => {
    if (appSetting.checkMinSearch(pattern)) {
      dispatch(getServersIp(pattern))
    }
  }

  const submitServer = (values: Store): void => {
    // create a new server
    if (!props.selectedIp) {
      message.error(t('errors.field_required'))
      return
    }

    const params: CreateServerParams = {
      customer_id: loggedUser.user.is_zcom ? values.customer_id : loggedUser.customer.id,
      hostname: values.hostname,
      primary_ip_id: props.selectedIp.id,
      type_id: values.type_id,
      add_reverse: values.add_reverse ? 1 : 0,
      add_dns_rec: values.add_dns_rec ? 1 : 0,
    }
    dispatch(createServer(params, suc => {
      if (suc) {
        message.success(t('general.success'))
        props.onClose()
      }
    }))
  }

  const submitAddIpToServer = (values: Store): void => {
    console.log(values)

    if (!props.selectedIp || !selectedServerId) {
      message.error(t('errors.field_required'))
      return
    }

    const params: ServerAddRemoveIpParams = {
      server_id: selectedServerId,
      ip_id: props.selectedIp.id,
    }
    dispatch(addIpToServer(params, suc => {
      if (suc) {
        message.success(t('general.success'))
        props.onClose()
      }
    }))
  }

  if (!props.selectedIp) {
    return <Spin />
  }

  return (
    <Row justify='space-between' style={{ marginTop: '0' }}>
      <Col span={24} >
        <Form name='create-server'
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 18 }}
          layout="horizontal"
          form={form1}
          initialValues={{
            customer_id: loggedUser.customer.id,
            type_id: 1,
            primary_ip: props.selectedIp?.address
          }}
          onFinish={submitServer}
        >
          <Form.Item name='customer_id' label={t('serversPage.customer_id')}
            rules={[{ required: true, message: t('errors.field_required') }]}>
            <Select optionFilterProp='label'
              style={{ width: '100%' }}
              options={customerOptions}
              disabled={!loggedUser.user.is_zcom}
              showSearch
            />
          </Form.Item>

          <Form.Item name='hostname'
            label={t('serversPage.hostname')}
            rules={[
              { required: true, message: t('errors.field_required') },
              {
                validator(_, value) {
                  if (_isValidDomain(value) || _isValidIP4(value)) {
                    return Promise.resolve()
                  }
                  return Promise.reject(new Error(t('domainPage.invalid_domain')))
                }
              }
            ]}>
            <Input
              placeholder='server.com'
              autoFocus // This will add focus to the input field when the form is rendered
            />
          </Form.Item>

          <Form.Item name='primary_ip' label={t('serversPage.primary_ip_id')}>
            <Input disabled={true} />
          </Form.Item>

          <Form.Item name='type_id' label={t('serversPage.server_type')}
            rules={[{ required: true, message: t('errors.field_required') }]}>
            <Select size='middle'
              options={serverTypeOptions}
              showSearch
              optionFilterProp='label'
            />
          </Form.Item>

          <Form.Item {...{
            labelCol: { span: 21 },
            wrapperCol: { span: 3 },
          }}
            name='add_dns_rec'
            label={t('serversPage.add_dns_rec')}
            style={{ fontSize: '0.8em' }}
            valuePropName='checked'
          >
            <Switch size='small'
              checkedChildren={<CheckOutlined />}
              unCheckedChildren={<CloseOutlined />}
              checked={addDnsRec}
              onChange={() => {
                setAddDnsRec(!addDnsRec)
              }}
            />
          </Form.Item>

          <Form.Item {...{
            labelCol: { span: 21 },
            wrapperCol: { span: 3 },
          }}
            name='add_reverse'
            label={t('serversPage.add_reverse')}
            valuePropName='checked'
          >
            <Switch size='small'
              checkedChildren={<CheckOutlined />}
              unCheckedChildren={<CloseOutlined />}
              checked={addReverse}
              onChange={() => {
                setAddReverse(!addReverse)
              }}
            />
          </Form.Item>

          <Form.Item {...tailLayout}>
            <Button type='primary' htmlType='submit' className='create-button'>
              {t('serversPage.create_server')}
            </Button>
          </Form.Item>
        </Form>
        <Divider style={{ marginTop: '35px' }} orientation='center'>nebo</Divider>

        <div style={{ textAlign: 'center' }}>
          <h3>{t('serversPage.add_ip_to_exist_server')}</h3>
        </div>


        <Form name='add-ip-to-server'
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 18 }}
          layout="horizontal"
          form={form2}
          initialValues={{
            primary_ip: props.selectedIp?.address,
            server_id: selectedServerId
          }}
          onFinish={submitAddIpToServer}
        >
          <Form.Item name='primary_ip' label='IP'>
            <Input disabled={true} />
          </Form.Item>

          <Form.Item name='server_id' label={t('serversPage.server')}>
            <ServerSelector
              dataSource={servers}
              isLoading={isLoadingServers}
              handleSelectedServer={(sid) => {
                setSelectedServerId(sid); form2.setFieldsValue([{ server_id: sid }])
                setUpdateStatus(true)
              }}
              fetchData={loadServerLookup}
            />
          </Form.Item>

          <Form.Item {...tailLayout}>
            <Button type='primary' htmlType='submit' className='create-button'>
              {t('serversPage.add_ip_to_server')}
            </Button>
          </Form.Item>
        </Form>
      </Col>
    </Row>
  )
}

export default NewServerIpForm
