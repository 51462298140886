import {RemoveCertFromVhostRequestAction, RemoveCertFromVhostResponseAction, RemoveCertFromVhostErrorAction} from './index'
import {protectedApiClient} from 'helpers/api'
import {handleApiErrorWithNotification} from 'helpers/errorHandling'


export default (id: number, cb?: (isSuccess: boolean) => void) => {
	return (dispatch: (arg: RemoveCertFromVhostRequestAction | RemoveCertFromVhostResponseAction | RemoveCertFromVhostErrorAction) => void) => {
		const request = new RemoveCertFromVhostRequestAction(id)
		dispatch(request)

		protectedApiClient
			.delete(`/web/vhost/${id}/certificate`)
			.then((response) => {
				dispatch(new RemoveCertFromVhostResponseAction(request, response.data))
				cb && cb(true)
			})
			.catch((error) => {
				dispatch(new RemoveCertFromVhostErrorAction(request, error.toString()))
				handleApiErrorWithNotification(error)
				cb && cb(false)
			})
	}
}
