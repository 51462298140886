import {GetServiceVhostsRequestAction, GetServiceVhostsResponseAction, GetServiceVhostsErrorAction} from './index'
import {protectedApiClient} from 'helpers/api'
import {handleApiErrorWithNotification} from 'helpers/errorHandling'
import {VhostModel} from '../models'

export default (service_id: number, cb?: (isSuccess: boolean) => void) => {
    return (dispatch: (arg: GetServiceVhostsRequestAction | GetServiceVhostsResponseAction | GetServiceVhostsErrorAction) => void) => {
        const request = new GetServiceVhostsRequestAction(service_id)
        dispatch(request)

        let url = `/web/service/${service_id}/vhost`
        protectedApiClient
            .get<VhostModel[]>(url)
            .then((response) => {
                dispatch(new GetServiceVhostsResponseAction(request, response.data))
                cb && cb(true)
            })
            .catch((error) => {
                dispatch(new GetServiceVhostsErrorAction(request, error.toString()))
                handleApiErrorWithNotification(error)
                cb && cb(false)
            })
    }
}
