import { CustomerDetails } from '../pages/billing/customer/models'
import moment, { Moment } from 'moment'
import { SQL_DATE_FORMAT } from './fce'
import { CustomerServiceDetails } from '../pages/billing/customerservice/models'

class Customer {
	data: CustomerDetails

	constructor(data: CustomerDetails) {
		this.data = data
	}

	public getData(): CustomerDetails {
		return this.data
	}

	public getStartDate = (date: Moment): Moment => {
		let start_year = moment().startOf('year')
		if (date.year() != start_year.year()) {
			// in the beginning of year (in January)
			// pick the last year
			start_year = start_year.add(-1, 'year')
		}

		let billing_period = this.data.billing_period
		if (!billing_period) {
			billing_period = 'month'
		}
		let start_date = moment().startOf('month')
		switch (billing_period) {
			case 'month':
				start_date = date.startOf('month')
				break
			case 'quarter_year':
				if ([0, 1, 2].includes(date.month())) {
					start_date = start_year.startOf('month')
				}
				if ([3, 4, 5].includes(date.month())) {
					start_date = start_year.add(3, 'month').startOf('month')
				}
				if ([6, 7, 8].includes(date.month())) {
					start_date = start_year.add(6, 'month').startOf('month')
				}
				if ([9, 10, 11, 12].includes(date.month())) {
					start_date = start_year.add(9, 'month').startOf('month')
				}
				break
			case 'half_year':
				if ([0, 1, 2, 3, 4, 5].includes(date.month())) {
					start_date = start_year.startOf('month')
				} else {
					start_date = start_year.add(6, 'month').startOf('month')
				}
				break
			case 'year':
				start_date = start_year.startOf('month')
				break
			case 'iregular':
				// should be never used
				start_date = start_year.startOf('month')
				break
		}
		return start_date
	}

	public getCurrentBillingPeriod = (): [Moment, Moment] => {
		// returns the current billing period by customer
		let billing_period = this.data.billing_period
		if (!billing_period) {
			billing_period = 'month'
		}
		const today = moment()
		let start_date = moment().startOf('month')
		let period_from: string
		if (this.data.last_invoice) {
			period_from = this.data.last_invoice.period_from
			start_date = moment(period_from).startOf('month')
			if (this.data.last_invoice.sent) {
				// if sent => use the next period
				switch (billing_period) {
					case 'month':
						start_date.add(1, 'month').startOf('month')
						break
					case 'quarter_year':
						start_date.add(3, 'month').startOf('month')
						break
					case 'half_year':
						start_date.add(6, 'month').startOf('month')
						break
					case 'year':
						start_date.add(12, 'month').startOf('month')
						break
					case 'iregular':
						start_date.add(12, 'month').startOf('month')
						break
				}
			}
		} else {
			// the first invoice
			start_date = this.getStartDate(today)
		}
		const end_date = start_date.clone()
		switch (billing_period) {
			case 'month':
				end_date.endOf('month')
				break
			case 'quarter_year':
				end_date.add(-1, 'day')
				end_date.add(3, 'month').endOf('month')
				break
			case 'half_year':
				end_date.add(-1, 'day')
				end_date.add(6, 'month').endOf('month')
				break
			case 'year':
				end_date.add(-1, 'day')
				end_date.add(12, 'month').endOf('month')
				break
			case 'iregular':
				end_date.add(-1, 'day')
				end_date.add(12, 'month').endOf('month')
				break
		}
		// console.log('billing_period: '+billing_period)
		// console.log('getCurrentBillingPeriod: '+start_date.format(SQL_DATE_FORMAT))
		return [start_date, end_date]
	}

	public getUnInvoicedBillingPeriod = (customerservices: CustomerServiceDetails[]): [string, string] => {
		const invoicedBy = this.getInvoiced(customerservices)
		const from = invoicedBy.add(1, 'days').clone()
		const start_date = this.getStartDate(from)
		const end_date = start_date.clone()
		switch (this.data.billing_period) {
			case 'month':
				end_date.add(1, 'months').add(-1, 'days')
				break
			case 'quarter_year':
				end_date.add(3, 'months').add(-1, 'days')
				break
			case 'half_year':
				end_date.add(6, 'months').add(-1, 'days')
				break
			case 'year':
				end_date.add(1, 'years').add(-1, 'days')
				break
			case 'iregular':
				end_date.add(1, 'years').add(-1, 'days')
				break
			default:
				end_date.add(1, 'months').add(-1, 'days')
				break
		}
		return [start_date.format(SQL_DATE_FORMAT), end_date.format(SQL_DATE_FORMAT)]
	}

	public getInvoiced(customerservices: CustomerServiceDetails[]): Moment {
		// returns minimum of invoiced_by
		// when item has not been invoiced use (date_from-1)
		if (customerservices.length === 0) {
			return moment().startOf('month').add(-1, 'days')
		}
		let dt: Moment | undefined = undefined
		let minDt = moment().add(-356, 'days')
		for (const cs of customerservices) {
			if (cs.invoiced_by) {
				dt = moment(cs.invoiced_by)
				if (dt > minDt) {
					minDt = moment(dt.format(SQL_DATE_FORMAT))
				}
			}
		}
		if (dt === undefined) {
			for (const cs of customerservices) {
				dt = moment(cs.date_from).startOf('month')
				if (dt > minDt) {
					minDt = moment(dt.format(SQL_DATE_FORMAT))
				}
			}
			return minDt.add(-1, 'days')
		}
		return minDt
	}
}

export default Customer
