import { protectedApiClient } from 'helpers/api'
import { handleApiErrorWithNotification } from 'helpers/errorHandling'
import { GetAsyncLogsRequestAction, GetAsyncLogsResponseAction, GetAsyncLogsErrorAction } from '.'
import { AsyncLogRecord, LogRecord } from '../../../pages/logviewer/models'

export default (task_id: number, cb?: (isSuccess: boolean) => void) => {
	return (
		dispatch: (arg: GetAsyncLogsRequestAction | GetAsyncLogsResponseAction | GetAsyncLogsErrorAction) => void
	) => {
		const request = new GetAsyncLogsRequestAction(task_id)
		dispatch(request)
		let url = '/asyncjob/log?app_name=zisasyncjobd&task_id=' + task_id
		protectedApiClient
			.get<AsyncLogRecord[]>(url)
			.then((response) => {
				dispatch(new GetAsyncLogsResponseAction(request, response.data))
				cb && cb(true)
			})
			.catch((error) => {
				dispatch(new GetAsyncLogsErrorAction(request, error))
				handleApiErrorWithNotification(error)
				cb && cb(false)
			})
	}
}
