import { SetPasswordRequestAction, SetPasswordResponseAction, SetPasswordErrorAction } from '.'
import { apiClient, protectedApiClient } from 'helpers/api'
import { handleApiError, handleApiErrorWithNotification } from 'helpers/errorHandling'
import { Auth2faResponseData, SetPasswordParams } from '../models'


export default (params: SetPasswordParams, cb?: (suc: boolean) => void) => {
	return (dispatch: (arg: SetPasswordRequestAction | SetPasswordResponseAction | SetPasswordErrorAction) => void) => {
		const request = new SetPasswordRequestAction(params)
		dispatch(request)
		apiClient
			.post<Auth2faResponseData>('/user/request', params)
			.then((response) => {
				dispatch(new SetPasswordResponseAction(request, response.data))
				cb && cb(true)
			})
			.catch((error) => {
				if (error.response.status === 400) {
					const message: string = error.response.data.message
					dispatch(new SetPasswordErrorAction(request, message))
					cb && cb(false)
					return
				}
				dispatch(new SetPasswordErrorAction(request, error))
				handleApiErrorWithNotification(error)
				cb && cb(false)
			})
	}
}
