import React, { useState, useEffect, useRef, ReactNode } from 'react'
import {
    Card,
    Button,
    Modal,
    Space,
    Table,
    Tag,
    Tooltip,
    Spin,
    AutoComplete,
    Radio, Select, message
} from 'antd'
import { ApartmentOutlined, CloudServerOutlined, PlusCircleOutlined } from '@ant-design/icons/lib/icons'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import getServers from './actions/getServers'
import { ServerModel, ServerService } from './models'
import { AppState, NameItem } from 'common/models'

import {
    InfoCircleTwoTone,
    MessageTwoTone
} from "@ant-design/icons"
import { useLoggedUser } from "../../helpers/loginUserHelper"
import { LoadingIndicator } from "../../components"
import ErrorPage403 from "../../components/Errors/ErrorPage403"
import useLogger from "../../common/useLogger"
import usePageSize from "../../common/usePageSize"
import Draggable, { DraggableData, DraggableEvent } from "react-draggable"
import getHistoryLogs from "../../components/History/actions/getHistoryLogs"
import { ColumnsType } from "antd/lib/table"
import { removeDiac, SQL_DATE_FORMAT, stopPropagation } from "../../common/fce"
import { sort_label, sort_str } from "../../common/sorting"
import './ServersPage.scss'
import { Link } from "react-router-dom"
import getServerTypes from "./actions/getServerTypes"
import getServerServiceTypes from "./actions/getServerServiceTypes"
import getServerMonitoringTypes from "./actions/getServerMonitoringTypes"
import tableCustomers from "../login/actions/tableCustomers"
import Pager from "../../components/pagination/pager"
import NewServerForm from "./NewServerForm"
import HistoryModal from "../../components/History/HistoryModal"
import { useHistory } from "react-router"
import { generateServices, renderState, SERVER_STATES } from "./common"
import getManagementTypes from "./actions/getManagementTypes"
import getNotifications from "./actions/getNotifications"
import getBackupServers from "./actions/getBackupServers"
import lookupServer from "./actions/lookupServer"
import IpSelector from "../ip/subnet/IpSelector"
import listIp from "./actions/listIp"



const ServersPage = () => {
    const CONTROL_NAME = 'page_servers'
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const history = useHistory()

    const { customers } = useSelector((state: AppState) => state.auth.tables)
    const { servers, pager, physical_servers, isLoading, isLoadingServers, service_types, server_types,
        ip_addresses, isLoadingIps, managements, notifications, backup_servers, lookup_hostname, isLoadingLookup,
        monitorings } = useSelector((state: AppState) => state.logic_server)
    const { log_servers } = useSelector((state: AppState) => state.sidenav)

    const [stateOptions, setStateOptions] = useState<{ label: string, value: number, key: number }[]>([])
    const [customerOptions, setCustomerOptions] = useState<{ label: string, value: number, key: number }[]>([])
    const [hostnameOptions, setHostnameOptions] = useState<{ label: string | ReactNode, value: string }[]>([])
    const [serviceTypeOptions, setServiceTypeOptions] = useState<{ label: string, value: string, key: string }[]>([])

    const [searchHostname, setSearchHostname] = useState<string | undefined>()
    const [searchHostnameLookup, setSearchHostnameLookup] = useState<string | undefined>()
    const [selectedCustomerId, setSelectedCustomerId] = useState<number | undefined>()
    const [searchStateId, setSearchStateId] = useState<number | undefined>()
    const [searchServiceTypeId, setSearchServiceTypeId] = useState<string | undefined>()
    const [selectedIpId, setSelectedIpId] = useState<number | undefined>()
    const [loaded, setLoaded] = useState(0)         // when records are loaded
    const [ready, setReady] = useState<number>(0)   // when customers are loaded

    const [dataSource, setDataSource] = useState<ServerModel[]>([])
    const [serversFull, setServersFull] = useState<ServerModel[]>([])
    const [pageNumber, setPageNumber] = useState<number>(1)
    const [qsFilter, setQsFilter] = useState<string>('')

    // page
    const [isHistoryModalVisible, setHistoryModalVisible] = useState(false)
    const [historyTitle, setHistoryTitle] = useState('')
    const [historyModelId, setHistoryModelId] = useState<number | undefined>()
    const [isModalNewVisible, setModalNewVisible] = useState(false)

    const [isViewer, setViewer] = useState(false)
    const [isCreator, setCreator] = useState(false)
    const [isEditor, setEditor] = useState(false)
    const [isDeleter, setDeleter] = useState(false)
    const [isIpManager, setIpManager] = useState(false)
    const [isServiceManager, setServiceManager] = useState(false)
    const [isCustomerNotificator, setCustomerNotificator] = useState(false)

    // get settings and current user
    const loggedUser = useLoggedUser()
    if (!loggedUser || !loggedUser.isLoaded()) {
        // waiting..
        return (
            <div className="fullwidth-loading" >
                <LoadingIndicator />
            </div>
        )
    }

    // required authorization
    if (!loggedUser.hasAccess(CONTROL_NAME)) {
        return <ErrorPage403 />
    }

    // settings
    const appSetting = loggedUser.getAppSettings()

    // usage: logger(msg, obj=null)
    const logger = useLogger(appSetting, 'ServersPage')
    const [pageSize, setPageSize] = useState<number>(appSetting.grid_page_size)
    usePageSize(appSetting, loggedUser.user.id, pageSize)

    // history drag modal
    const [disabled, setDisabled] = useState(true)
    const draggleRef = useRef<HTMLDivElement>(null)
    const [bounds, setBounds] = useState({ left: 0, top: 0, bottom: 0, right: 0 })
    const onStart = (_event: DraggableEvent, uiData: DraggableData) => {
        const { clientWidth, clientHeight } = window.document.documentElement
        const targetRect = draggleRef.current?.getBoundingClientRect()
        if (!targetRect) {
            return
        }
        setBounds({
            left: -targetRect.left + uiData.x,
            right: clientWidth - (targetRect.right - uiData.x),
            top: -targetRect.top + uiData.y,
            bottom: clientHeight - (targetRect.bottom - uiData.y),
        })
    }

    useEffect(() => {
        // trigger ONCE
        const access = loggedUser.hasAccess('page_servers')
        setViewer(access)
        setCreator(loggedUser.hasAccess('page_servers_create_button'))
        setEditor(loggedUser.hasAccess('page_servers_edit_button'))
        setDeleter(loggedUser.hasAccess('page_servers_delete_button'))
        setIpManager(loggedUser.hasAccess('page_servers_ip_manager'))
        setServiceManager(loggedUser.hasAccess('page_servers_service_manager'))
        setCustomerNotificator(loggedUser.hasAccess('page_servers_customer_notificator'))

        if (!access) {
            // failover 403
            message.error('No permissions!')
            history.replace('/')
            return
        }

        loadOptions()
        setStateOptions(SERVER_STATES.map(item => ({
            value: item.id,
            key: item.id,
            label: item.name
        })))

        if (!isLoadingServers) {
            //fetchRecords(1, pageSize)
        }

    }, [])

    useEffect(() => {
        // SideNav menu pressed
        logger('isLoadingServers:' + isLoadingServers)
        logger('loaded:' + loaded)
        if (!isLoadingServers && loaded > 0) {
            fetchRecords(1, pageSize)
        }
    }, [log_servers])

    useEffect(() => {
        // populate hostnameOptions
        setHostnameOptions(lookup_hostname.map(s => ({ label: s, value: s })))
    }, [lookup_hostname])

    useEffect(() => {
        // update QsFilter will trigger fetchRecords
        const qs: string = prepareQsFilter()
        logger(`qsFilter:${qs}`)
        if (qs.trim() != qsFilter.trim()) {
            setQsFilter(qs)
        }
    }, [searchHostname, selectedIpId, searchStateId, selectedCustomerId, searchServiceTypeId])

    useEffect(() => {
        // when filter is changed
        // show the first page
        logger(`qsFilter changed: page: ${pageNumber}, pageSize: ${pageSize}, qs=${qsFilter}`)
        let pn = pageNumber
        if (ready) {    // do not change page for F5
            pn = 1
        }
        fetchRecords(pn, pageSize)
    }, [qsFilter])


    useEffect(() => {
        logger('full - servers..')
        if (servers.items) {
            logger('servers: ' + servers.items.length)
            setServersFull(servers.items.map((s) => {
                let ips = s.ips?.map((a) => a.address).join('|')
                return {
                    ...s,
                    primary_ip_name: s.primary_ip?.name,
                    searchableName: removeDiac(s.customer?.name) + ',' + ips + ',' + s.hostname + ',' + s.primary_ip?.name,
                    customer_name: s.customer?.name
                }
            }))
        }
    }, [servers.items])

    useEffect(() => {
        if (customers) {
            setReady(ready + 1)
            setCustomerOptions(
                customers.map(item => ({
                    value: item.id,
                    key: item.id,
                    label: item.company?.name!
                }))
            )
        }
    }, [customers])

    useEffect(() => {
        if (service_types) {
            setReady(ready + 1)
            setServiceTypeOptions(
                service_types.map(item => ({
                    value: item.name.toLowerCase(),
                    key: item.name.toLowerCase(),
                    label: item.name
                }))
            )
        }
    }, [service_types])

    useEffect(() => {
        // Filters
        if (filterIsValid()) {
            refreshGrid()
        }
    }, [serversFull])

    const fetchRecords = (pn: number, ps: number) => {
        setPageNumber(pn)
        setPageSize(ps)
        if (!isLoading && filterIsValid()) {
            // purchase_from is required
            logger(`fetchRecords: page: ${pn}, pageSize: ${ps}, qs=${qsFilter}`)
            let f = ''
            if (qsFilter) {
                f = '&' + qsFilter
            }
            dispatch(getServers(ps, pn - 1, f, suc => { }))
            setLoaded(1)
        }
    }

    const refreshGrid = () => {
        logger('refreshGrid')
        setDataSource(serversFull)
    }

    const filterIsValid = (): boolean => {
        // no rules
        return true
    }

    const getQSFilter = (): string[] => {
        let qs: string[] = []
        return qs
    }

    const prepareQsFilter = (): string => {
        // load filtered data from server
        let qs: string[]
        qs = getQSFilter()
        if (searchHostname && appSetting.checkMinSearch(searchHostname)) {
            qs.push('hostname=' + encodeURIComponent(searchHostname))
        }
        if (searchStateId && searchStateId > 0) {
            qs.push('status_id=' + searchStateId)
        }
        if (selectedIpId && selectedIpId > 0) {
            qs.push('primary_ip_id=' + selectedIpId)
        }
        if (selectedCustomerId && selectedCustomerId > 0) {
            qs.push('owner_id=' + selectedCustomerId)
        }
        if (searchServiceTypeId && appSetting.checkMinSearch(searchServiceTypeId)) {
            qs.push('service_type=' + searchServiceTypeId.toLowerCase())
        }
        logger('prepareQsFilter: ' + qs.join("&"))
        return (qs.length === 0) ? '' : qs.join("&")
    }

    const fetchHistory = (service: string, model: string, mid: number) => {
        setHistoryTitle(`${service} / ${model} / ${mid}`)
        dispatch(getHistoryLogs(service, model, mid))
    }

    const loadOptions = () => {
        // load Options for refresh F5
        if (!customers || customers.length === 0) {
            dispatch(tableCustomers())
        }
        if (!physical_servers || physical_servers.length === 0) {
            // dispatch(getPhysicalServers())
        }
        if (!server_types || server_types.length === 0) {
            dispatch(getServerTypes())
        }
        if (!service_types || service_types.length === 0) {
            dispatch(getServerServiceTypes())
        }
        if (!backup_servers || backup_servers.length === 0) {
            dispatch(getBackupServers())
        }
        if (!monitorings || monitorings.length === 0) {
            dispatch(getServerMonitoringTypes())
        }
        if (!managements || managements.length === 0) {
            dispatch(getManagementTypes())
        }
        if (!notifications || notifications.length === 0) {
            dispatch(getNotifications())
        }
    }

    const onClearHostname = () => {
        setSearchHostnameLookup('')
        setSearchHostname('')
        setHostnameOptions([])
    }

    const onChangeHostnameLookup = (data: string) => {
        if (!data) {
            if (searchHostnameLookup && searchHostnameLookup.length === 1) {
                setSearchHostnameLookup('')
                fetchHostnameLookup('')
            }
            return
        }
        if (data != searchHostnameLookup) {
            setSearchHostnameLookup(data)
            fetchHostnameLookup(data)
        }
    }

    const fetchHostnameLookup = (searchText: string) => {
        // call lookup serial
        if (appSetting.checkMinSearch(searchText)) {
            let qs: string[]
            qs = getQSFilter()
            qs.push('field=hostname')
            qs.push('value=' + encodeURIComponent(searchText.trim()))
            logger('lookupServer: ' + qs.join("&"))
            dispatch(lookupServer('hostname', qs.join("&")))
        }
    }

    const onSelectHostname = (data: string) => {
        setSearchHostname(data)
    }

    const handleFilterChange = (name: string, value: any) => {
        if (name === 'status') {
            // load data from server - automatic
            if (value && value > 0) {
                setSearchStateId(value)
            } else {
                setSearchStateId(undefined)
            }
        }
    }

    const fetchIpLookup = (searchText: string) => {
        // call lookup for Ip_name
        if (appSetting.checkMinSearch(searchText)) {
            dispatch(listIp(searchText))
        }
    }

    const FilterByState = (
        <Select
            showSearch
            placeholder={t('serversPage.state')}
            options={stateOptions}
            value={searchStateId}
            style={{ width: 100, marginRight: "1rem" }}
            dropdownMatchSelectWidth={140}
            optionFilterProp="children"
            filterOption={(input, opt) => removeDiac(opt?.label + '').includes(removeDiac(input))}
            filterSort={sort_label}
            allowClear
            onClick={stopPropagation}
            onChange={(v) => { handleFilterChange('status', v) }}
            onSelect={(v) => setSearchStateId(v)}
            onClear={() => setSearchStateId(undefined)}
        />
    )

    const FilterByServiceType = (
        <Select
            showSearch
            placeholder={t('serversPage.services')}
            options={serviceTypeOptions}
            value={searchServiceTypeId}
            style={{ width: 120, marginRight: "1rem" }}
            dropdownMatchSelectWidth={140}
            optionFilterProp="children"
            filterOption={(input, opt) => removeDiac(opt?.label + '').includes(removeDiac(input))}
            filterSort={sort_label}
            allowClear
            onClick={stopPropagation}
            onChange={(v) => { handleFilterChange('service_type', v) }}
            onSelect={(v) => setSearchServiceTypeId(v)}
            onClear={() => setSearchServiceTypeId(undefined)}
        />
    )

    const FilterByHostname = (
        <AutoComplete
            showSearch
            placeholder={t('serversPage.hostname')}
            value={searchHostnameLookup}
            options={hostnameOptions}
            style={{ width: 160 }}
            dropdownMatchSelectWidth={200}
            onInputKeyDown={(e) => {
                if (e.key === 'Enter') {
                    onSelectHostname(e.currentTarget.value)
                }
            }}
            onSelect={onSelectHostname}
            onSearch={onChangeHostnameLookup}
            onChange={onChangeHostnameLookup}
            onClear={onClearHostname}
            onClick={stopPropagation}
            notFoundContent={isLoadingLookup && <Spin />}
            filterOption={false}
            optionFilterProp='label'
            allowClear={true}
        />
    )

    const FilterByCustomer = (
        <Select
            showSearch
            placeholder={t('serversPage.customer_id')}
            options={customerOptions}
            value={selectedCustomerId}
            style={{ width: 120, marginRight: "1rem" }}
            dropdownMatchSelectWidth={200}
            optionFilterProp="children"
            filterOption={(input, opt) => removeDiac(opt?.label + '').includes(removeDiac(input))}
            filterSort={sort_label}
            allowClear
            onClick={stopPropagation}
            onSelect={(v) => setSelectedCustomerId(v)}
            onClear={() => setSelectedCustomerId(undefined)}
        />
    )

    const FilterByIp = (
        <IpSelector
            dataSource={ip_addresses}
            isLoadingIps={isLoadingIps}
            handleSelectedIp={(ip_id) => { setSelectedIpId(ip_id) }}
            fetchData={fetchIpLookup}
        />
    )

    const columns: ColumnsType<ServerModel> = [
        {
            title: FilterByHostname,
            dataIndex: 'hostname',
            key: 'hostname',
            width: 200,
            align: 'left',
            fixed: 'left',
            render: (text, rec) => {
                return (
                    <Link to={`/servers/edit/${rec.id}`}>{text}</Link>
                )
            }
        },
        {
            title: FilterByIp,
            dataIndex: 'primary_ip_name',
            key: 'primary_ip_name',
            width: 100
        },
        {
            title: FilterByState,
            dataIndex: 'status_id',
            key: 'status_id',
            width: 100,
            align: 'center',
            render: (text, rec) => renderState(rec, t)
        },
        {
            title: FilterByCustomer,
            dataIndex: 'customer',
            key: 'customer',
            width: 120,
            ellipsis: { showTitle: false },
            render: (customer, rec) => customer?.name,
        },
        {
            title: <span className='small'>{t('serversPage.comment')}</span>,
            dataIndex: 'comment',
            key: 'comment',
            width: 100,
            ellipsis: { showTitle: false },
            render: comment => (
                comment &&
                <Tooltip placement="topLeft" title={comment}>
                    <MessageTwoTone />
                </Tooltip>
            ),
        },
        {
            title: FilterByServiceType,
            dataIndex: 'services',
            key: 'services',
            width: 150,
            render: (text: ServerService[], rec: ServerModel) => rec.services.map((s) => generateServices(s)),
        },
        {
            title: 'Action',
            key: 'action',
            dataIndex: 'action',
            width: 50,
            align: 'center',
            fixed: 'right',
            render: (text: string, rec: ServerModel) => (
                <Space size='small'>
                    <Button title={t('general.btnHistory')} size='small'
                        onClick={() => {
                            fetchHistory('server', 'Server', rec.id)
                            setHistoryModelId(rec.id)
                            setHistoryModalVisible(true)
                        }}
                        icon={<InfoCircleTwoTone />}
                        className='actionButton'
                    />
                </Space>
            ),
        }
    ]

    if (!appSetting || Object.keys(appSetting).length === 0) {
        return (<Spin />)
    }


    return (
        <>
            <Card
                title={<><CloudServerOutlined /> &nbsp; {t('serversPage.title')}</>}
                extra={(
                    <Space>
                        <Button type='primary'
                            disabled={!isCreator}
                            onClick={() => { setModalNewVisible(true) }}>
                            <PlusCircleOutlined />{t('serversPage.create_server')}
                        </Button>
                    </Space>
                )}
                className='ServersPage'
            >

                <Table<ServerModel>
                    columns={columns}
                    dataSource={dataSource}
                    scroll={{ x: 'max-content' }}
                    rowKey='id'
                    bordered={true}
                    className='ServersTable'
                    loading={isLoadingServers}
                    showHeader={true}
                    pagination={false}
                    footer={() => Pager({
                        filename: 'fn',
                        total: pager.totalCount,
                        current: pager.page,
                        pageSize: pager.pageSize,
                        data: dataSource,
                        fetchRecords: fetchRecords
                    })}
                    onChange={(ev) => {
                        ev.pageSize && setPageSize(ev.pageSize)
                    }}
                />

            </Card>


            <Modal title={
                <div style={{ width: '100%', cursor: 'move' }}
                    onMouseOver={() => { if (disabled) { setDisabled(false) } }}
                    onMouseOut={() => { setDisabled(true) }}
                    onFocus={() => { }}
                    onBlur={() => { }}
                >
                    <><ApartmentOutlined /> &nbsp; {t('serversPage.create_server')}</>
                </div>
            }
                style={{ top: 20 }}
                width={480}
                visible={isModalNewVisible}
                onCancel={() => setModalNewVisible(false)}
                maskClosable={false}
                modalRender={(modal) => (
                    <Draggable disabled={disabled} bounds={bounds} onStart={(ev, data) => onStart(ev, data)}>
                        <div ref={draggleRef}>{modal}</div>
                    </Draggable>
                )}
                destroyOnClose
                footer={null}
            >
                <NewServerForm onClose={() => { setModalNewVisible(false) }} />
            </Modal>

            <HistoryModal service='server' model='Server'
                modelId={historyModelId}
                title={historyTitle}
                isModalVisible={isHistoryModalVisible}
                setModalVisible={() => setHistoryModalVisible(false)}
                modalRender={(modal) => (
                    <Draggable bounds={bounds} onStart={(ev, data) => onStart(ev, data)}>
                        <div ref={draggleRef}>{modal}</div>
                    </Draggable>
                )}
            />
        </>
    )
}

export default ServersPage
