import { DeleteMailAliasRequestAction, DeleteMailAliasResponseAction, DeleteMailAliasErrorAction } from '.'
import { protectedApiClient } from 'helpers/api'
import { handleApiErrorWithNotification } from 'helpers/errorHandling'

export default (domainId: number, name: string, emails: string, cb?: (isSuccess: boolean) => void) => {
	return (
		dispatch: (arg: DeleteMailAliasRequestAction | DeleteMailAliasResponseAction | DeleteMailAliasErrorAction) => void
	) => {
		const request = new DeleteMailAliasRequestAction(domainId, name)
		dispatch(request)

		protectedApiClient
			.delete(`/mail/domain/${domainId}/forward?alias=${name}&email[]=${encodeURIComponent(emails)}`)
			.then((response) => {
				dispatch(new DeleteMailAliasResponseAction(request))
				cb && cb(true)
			})
			.catch((error) => {
				dispatch(new DeleteMailAliasErrorAction(request, error))
				handleApiErrorWithNotification(error)
				cb && cb(false)
			})
	}
}
