import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import React, { useEffect, useRef, useState } from "react"
import { Card, Col, Collapse, Divider, message, Modal, Row, Space, Spin, Table, AutoComplete } from "antd"
import { ConsoleSqlOutlined, DeleteTwoTone, ExclamationCircleOutlined, SyncOutlined } from "@ant-design/icons"
import getRdbService from "./actions/getRdbService"
import { Link, useParams } from "react-router-dom"
import ErrorPage403 from "../../components/Errors/ErrorPage403"
import useLogger from "../../common/useLogger"
import { useLoggedUser } from "../../helpers/loginUserHelper"
import { LoadingIndicator } from "../../components"
import { useHistory } from "react-router"
import { AppState } from "../../common/models"
import getDaemon from "./actions/getDaemon"
import getDbVersion from "./actions/getDbVersion"
import Button from "antd-button-color"
import { GlobalOutlined, PlusCircleOutlined } from "@ant-design/icons/lib/icons"
import Draggable, { DraggableData, DraggableEvent } from "react-draggable"
import CreateDatabaseForm from "./CreateDatabaseForm"
import { getServiceUiType, renderService } from "../server/common"
import getServiceDatabases from "./actions/getServiceDatabases"
import { DatabaseModel, DbServiceModel } from "./models"
import HistoryLog from "../../components/History/HistoryLog"
import deleteRdbService from "./actions/deleteRdbService"
import { stopPropagation } from "common/fce"
import { removeDiac } from "common/fce"
import Highlighter from "react-highlight-words"
const { Panel } = Collapse
const { confirm } = Modal

export interface ParamTypes {
    service_id: string
}

const renderServiceBox = (name: string) => {
    if (!name) {
        return ''
    }
    const box = getServiceUiType(name)
    if (box) {
        return (
            <Card title={renderService(name)} key={box.id.toString()} style={
                {
                    textAlign: 'center',
                    width: '200px',
                    margin: '10% 25%',
                    padding: '10px',
                    borderRadius: '8px',
                    border: `2px solid ${box.color}`,
                    backgroundColor: '#ededed'
                }}>
            </Card>
        )
    }
    return ''
}

const RdbServiceDetailPage = () => {
    const CONTROL_NAME = 'page_rdb_service'
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const history = useHistory()
    const { service_id } = useParams<ParamTypes>()

    const { isLoadingService, service, service_databases, db_version, daemon, error } = useSelector((state: AppState) => state.rdbservice)

    const [dbName, setDbName] = useState<string>('')
    const [databaseSource, setDatabaseSource] = useState<DatabaseModel[]>([])
    const [isDatabaseModalVisible, setDatabaseModalVisible] = useState<boolean>(false)
    const [forceUpdateId, setForceUpdateId] = useState<number>(1)
    const [historyModelId, setHistoryModelId] = useState<number | undefined>()
    const [openDelete, setOpenDelete] = useState(false)
    const [confirmDelete, setConfirmDelete] = useState(false)

    const [isViewer, setViewer] = useState(false)
    const [isRdbAdmin, setRdbAdmin] = useState(false)
    const [isDbCreator, setDbCreator] = useState(false)
    const [isUserEditor, setUserEditor] = useState(false)
    const [isDbDeleter, setDbDeleter] = useState(false)

    // history drag modal
    const [disabled, setDisabled] = useState(true)
    const draggleRef = useRef<HTMLDivElement>(null)
    const [bounds, setBounds] = useState({ left: 0, top: 0, bottom: 0, right: 0 })
    const onStart = (_event: DraggableEvent, uiData: DraggableData) => {
        const { clientWidth, clientHeight } = window.document.documentElement
        const targetRect = draggleRef.current?.getBoundingClientRect()
        if (!targetRect) {
            return
        }
        setBounds({
            left: -targetRect.left + uiData.x,
            right: clientWidth - (targetRect.right - uiData.x),
            top: -targetRect.top + uiData.y,
            bottom: clientHeight - (targetRect.bottom - uiData.y),
        })
    }


    // get settings and current user
    const loggedUser = useLoggedUser()
    if (!loggedUser || !loggedUser.isLoaded()) {
        // waiting..
        return (
            <div className="fullwidth-loading" >
                <LoadingIndicator />
            </div>
        )
    }

    // required authorization
    if (!loggedUser.hasAccess(CONTROL_NAME)) {
        return <ErrorPage403 />
    }

    // settings
    const appSetting = loggedUser.getAppSettings()

    const logger = useLogger(appSetting, 'RdbServiceDetailPage')

    useEffect(() => {
        logger('service_id: ' + service_id)
        const access = loggedUser.hasAccess('page_rdb_service')
        setViewer(access)

        if (!access) {
            // failover 403
            message.error('No permissions!')
            history.replace('/rdb/databases')
            return
        }

        setRdbAdmin(loggedUser.hasRole('rdb-admin'))
        setDbCreator(loggedUser.hasAccess('page_rdb_databases_create_button'))
        setUserEditor(loggedUser.hasAccess('page_rdb_databases_edit_button'))
        setDbDeleter(loggedUser.hasAccess('page_rdb_databases_delete_button'))

        if (parseInt(service_id) > 0) {
            // loadOptions()
            const sid = parseInt(service_id)
            dispatch(getRdbService(sid))
        }
        else {
            // failover 404
            history.replace('/rdb/databases')
        }
    }, [])

    useEffect(() => {
        if (service && service.id === parseInt(service_id)) {
            checkServer()
            setHistoryModelId(service.id)
            dispatch(getServiceDatabases(service.id))
            loadHistory()
        }
    }, [service && service.id])

    useEffect(() => {
        if (service_databases) {
            loadHistory()
            renderDatabases()
        }
    }, [service_databases])

    useEffect(() => {
        renderDatabases()
    }, [dbName])

    const renderDatabases = () => {
        if (dbName && appSetting.checkMinSearch(dbName)) {
            setDatabaseSource(service_databases.filter(db => removeDiac(db.name).includes(removeDiac(dbName))))
        }
        else {
            setDatabaseSource(service_databases)
        }
    }

    const checkServer = () => {
        dispatch(getDaemon(parseInt(service_id)))
        dispatch(getDbVersion(parseInt(service_id)))
        loadHistory()
    }

    const isDaemonReady = (): boolean => {
        return !!daemon && parseInt(daemon) > 0
    }

    const loadHistory = () => {
        setForceUpdateId(forceUpdateId + 1)	// update history
    }

    const showConfirmDelete = (s: DbServiceModel, title: string) => {
        if (!s) {
            return
        }
        // if (!isDeleter) {
        //     message.error(t('general.error403'))
        //     return
        // }
        confirm({
            icon: <ExclamationCircleOutlined style={{ color: 'red' }} />,
            title: title,
            content: <p>{s.host}</p>,
            okText: t('general.yes'),
            cancelText: t('general.cancel'),
            okButtonProps: { loading: confirmDelete },
            className: 'confirm-alert',
            onOk() {
                setConfirmDelete(true)
                dispatch(deleteRdbService(s.id, suc => {
                    setConfirmDelete(false)
                    setOpenDelete(false)
                    if (suc) {
                        message.success(t('general.success'))
                        history.replace('/rdb/databases')
                    }
                }))
            },
            onCancel() {
                setOpenDelete(false)
            },
        }
        )
    }

    const FilterByName = (
        <AutoComplete
            placeholder={t('databasesPage.database')}
            style={{ width: '150px' }}
            value={dbName}
            allowClear={true}
            onClick={stopPropagation}
            onKeyDown={(e) => { if (e.key === 'Enter') { e.stopPropagation() } }}
            onChange={(v) => {
                setDbName(v)
            }}
        />
    )


    if (!appSetting || Object.keys(appSetting).length === 0) {
        return (<Spin />)
    }

    if (!service || !service.id) {
        return (<Spin />)
    }

    return (
        <>
            <Card
                title={<><ConsoleSqlOutlined /> &nbsp; {t('databasesPage.rdb_service')} &nbsp; {service.host}</>}
                className='RdbServiceDetailPage'
                loading={isLoadingService}
                extra={
                    <Button type='primary'
                        disabled={!service || !isDaemonReady() || !isDbCreator}
                        onClick={() => setDatabaseModalVisible(true)}>
                        <PlusCircleOutlined /> {t('databasesPage.new_database')}
                    </Button>
                }
            >

                <Row>
                    <Col xs={24} sm={24} md={24} lg={8} xl={8} className='pad4 center'>
                        &nbsp;{renderServiceBox('rdb')}
                        <Row>
                            <Col span={12} className='right bold pad4'>{t('databasesPage.daemon')}:</Col>
                            <Col span={12} className='left pad4'>
                                {(daemon && parseInt(daemon) > 0) && (
                                    <Space>
                                        <span className='text-success'>OK</span>
                                        <span className='small'>version</span>
                                        <b>{daemon}</b>
                                    </Space>
                                )}
                                {(daemon === null) && (
                                    <Space>
                                        <span className='text-alert'>{error}</span>
                                    </Space>
                                )}
                                {(daemon === undefined) && (
                                    <Space>
                                        <SyncOutlined spin color='blue' />
                                    </Space>
                                )}
                            </Col>
                            <Col span={12} className='right bold pad4'>{t('databasesPage.version')}:</Col>
                            <Col span={12} className='left pad4'>
                                {(db_version && parseInt(db_version) > 0) && (
                                    <Space>
                                        <span className='text-success'>OK</span>
                                        <span className='small'>version</span>
                                        <b>{db_version}</b>
                                    </Space>
                                )}
                                {(db_version === null) && (
                                    <Space>
                                        <span className='text-alert'>{error}</span>
                                    </Space>
                                )}
                                {(db_version === undefined) && (
                                    <Space>
                                        <SyncOutlined spin color='blue' />
                                    </Space>
                                )}
                            </Col>
                            <Col span={12} className='right bold pad4'></Col>
                            <Col span={12} className='left pad4'>
                                <Button size='small' onClick={() => checkServer()}>Check state</Button>
                            </Col>
                        </Row>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={16} xl={16} className='pad4'>
                        <Row>
                            <Col span={6} className='right bold pad4' style={{ marginTop: '8px' }}><b>{t('databasesPage.host')}</b>:</Col>
                            <Col span={18} className='pad4'><h2>{service.host}</h2></Col>
                        </Row>
                        <Row>
                            <Col span={6} className='right bold pad4'>id:</Col>
                            <Col span={18} className='pad4'>{service.id}</Col>
                        </Row>

                        <Row>
                            <Col span={6} className='right bold pad4'>{t('databasesPage.server')}:</Col>
                            <Col span={18} className='pad4'><Link to={`/servers/edit/${service?.server_id}`}>{service?.host}</Link></Col>
                        </Row>

                        <Row>
                            <Col span={6} className='right bold pad4'>{t('databasesPage.customer')}:</Col>
                            <Col span={18} className='pad4'>{service.customer?.name}</Col>
                        </Row>

                        <Row>
                            <Col span={6} className='right bold pad4'>{t('databasesPage.driver')}:</Col>
                            <Col span={18} className='pad4'>{service.driver?.name}</Col>
                        </Row>

                        <Row>
                            <Col span={6} className='right bold pad4'>{t('general.created')}:</Col>
                            <Col span={18} className='pad4'>{appSetting.renderDate(service.created_at)}</Col>
                        </Row>
                    </Col>
                </Row>

                <Divider />

                <Row>
                    <Col span={8} style={{ padding: '15px' }}>
                        <Table<DatabaseModel>
                            columns={[
                                {
                                    title: FilterByName,
                                    dataIndex: 'name',
                                    key: 'name',
                                    width: 200,
                                    align: 'left',
                                    render: (text: string, rec: DatabaseModel) => (
                                        <Link to={`/rdb/database/${rec.id}`}>
                                            <Highlighter
                                                highlightStyle={{
                                                    backgroundColor: '#ffc069',
                                                    color: '#2d9259',
                                                    padding: 0,
                                                }}
                                                searchWords={[dbName]}
                                                autoEscape
                                                textToHighlight={text.toString()}
                                            />
                                        </Link>
                                    ),
                                }]}
                            dataSource={databaseSource}
                            scroll={{ x: 'max-content' }}
                            rowKey='id'
                            bordered={true}
                            className='RdbServiceDatabasesTable'
                            style={{ width: '100%' }}
                            loading={isLoadingService}
                            showHeader={true}
                            size='small'
                            pagination={false}

                        />
                    </Col>
                    <Col span={16} style={{ padding: '15px' }}>
                        <HistoryLog service='rdb'
                            model='Service'
                            modelId={historyModelId}
                            forceUpdateId={forceUpdateId}
                            isModal={false}
                            showTitle={true} />
                    </Col>
                </Row>

                <Row>
                    <Col span={24} className='pad8'>
                        <Divider />
                        <Button danger size='small'
                            disabled={!isDbDeleter}
                            style={{ float: 'right' }}
                            onClick={() => {
                                if (!isDaemonReady()) {
                                    message.error(t('databasesPage.daemon_not_ready'))
                                    return
                                  }
                                showConfirmDelete(service, t('databasesPage.confirm_delete_service'))
                            }}
                            icon={<DeleteTwoTone twoToneColor='red' />}>{t('databasesPage.btn_delete_service')}</Button>

                    </Col>
                </Row>
            </Card>

            <Modal title={
                <div style={{ width: '100%', cursor: 'move' }}
                    onMouseOver={() => { if (disabled) { setDisabled(false) } }}
                    onMouseOut={() => { setDisabled(true) }}
                    onFocus={() => { }}
                    onBlur={() => { }}
                >
                    <GlobalOutlined /> &nbsp; {t('databasesPage.new_database')}
                </div>
            }
                destroyOnClose
                style={{ top: 20 }}
                width={600}
                visible={isDatabaseModalVisible}
                onCancel={() => setDatabaseModalVisible(false)}
                modalRender={(modal) => (
                    <Draggable disabled={disabled} bounds={bounds} onStart={(ev, data) => onStart(ev, data)}>
                        <div ref={draggleRef}>{modal}</div>
                    </Draggable>
                )}
                footer={null}
                confirmLoading={true}
            >
                <CreateDatabaseForm onClose={() => {
                    setDatabaseModalVisible(false)
                    loadHistory()
                }}
                />
            </Modal>
        </>
    )
}

export default RdbServiceDetailPage
