import {
    GetServiceDatabasesRequestAction,
    GetServiceDatabasesResponseAction,
    GetServiceDatabasesErrorAction,
} from '.'
import { protectedApiClient } from 'helpers/api'
import { handleApiErrorWithNotification } from 'helpers/errorHandling'
import {DatabaseGrid, DatabaseModel} from '../models'


export default (service_id: number, cb?: (isSuccess: boolean) => void) => {
    return (
        dispatch: (
            arg:
                | GetServiceDatabasesRequestAction
                | GetServiceDatabasesResponseAction
                | GetServiceDatabasesErrorAction
        ) => void
    ) => {
        const request = new GetServiceDatabasesRequestAction()
        dispatch(request)

        let url = `/rdb/service/${service_id}/database`
        protectedApiClient
            .get<DatabaseModel[]>(url)
            .then((response) => {
                dispatch(new GetServiceDatabasesResponseAction(request, response.data))
                cb && cb(true)
            })
            .catch((error) => {
                dispatch(new GetServiceDatabasesErrorAction(request, error))
                handleApiErrorWithNotification(error)
                cb && cb(false)
            })
    }
}
