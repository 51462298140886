import {GetCertificatesRequestAction, GetCertificatesResponseAction, GetCertificatesErrorAction} from '.'
import {protectedApiClient} from 'helpers/api'
import {handleApiErrorWithNotification} from 'helpers/errorHandling'
import {CertificateGrid} from '../models'

export default (pageSize: number, pageNumber: number, filter: string, cb?: (isSuccess: boolean) => void) => {
	return (
		dispatch: (arg: GetCertificatesRequestAction | GetCertificatesResponseAction | GetCertificatesErrorAction) => void,
	) => {
		const request = new GetCertificatesRequestAction()
		dispatch(request)

		protectedApiClient
			.get<CertificateGrid>(`/certificate/certificate?${filter}&expand=usages&pagination[pageSize]=${pageSize}&pagination[page]=${pageNumber}`)
			.then((response) => {
				dispatch(new GetCertificatesResponseAction(request, response.data))
				cb && cb(true)
			})
			.catch((error) => {
				dispatch(new GetCertificatesErrorAction(request, error))
				handleApiErrorWithNotification(error)
				cb && cb(false)
			})
	}
}
