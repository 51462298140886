import { CustomerDetails } from '../customer/models'
import moment from "moment"
import { PricelistDetails } from "../pricelist/models"
import { ProductDetails } from "../product/models"
import { ProductPriceDetails } from "../productprice/models"
import AppSetting from "../../../common/AppSettings"
import { sort_name } from "../../../common/sorting"
import { Col, Row, Tag } from "antd"
import { removeDiac } from "../../../common/fce"
import React from "react"
import { CustomerServiceDetails } from "./models"

export const filterPeriods = [
	'ACT_HALF',
	'ACT_MONTH',
	'ACT_QUARTER',
	'ACT_YEAR',
	'FIRST_HALF_AGO',
	'PREV_MONTH',
	'PREV_QUARTER',
	'PREV_YEAR',
	'SECOND_HALF_AGO',
	'TWO_MONTH_AGO',
	'TWO_QUARTER_AGO',
	'TWO_YEAR_AGO',
	'THIRD_HALF_AGO',
	'THREE_MONTH_AGO',
	'THREE_QUARTER_AGO',
	'THREE_YEAR_AGO',
	'MONTH_BEFORE_YEAR',
	'QUARTER_BEFORE_YEAR',
	'ALL_TIME'
]


export const getPriceNames = (pl: PricelistDetails, prods: ProductDetails[]): ProductPriceDetails[] => {
	let prices: ProductPriceDetails[] = []
	for (let key in pl['prices']) {
		prices.push(pl['prices'][key])
	}
	return prices.map((item) => ({
		...item,
		name: prods.find((p) => p.id === item.product_id)?.name || '...?',
	}))
}

export const getProdOptions = (items: ProductPriceDetails[], currency: string, appSetting: AppSetting) => {
	return items.sort(sort_name).map((pp) => ({
		label: (
			<Row>
				<Col flex='4'>{pp.name}</Col>
				<Col flex="1" >
					<Tag color={pp.calculation_type === "fix" ? "red" : pp.calculation_type === "unit" ? "blue" : pp.calculation_type === "range" ? "green" : "blue"} >
						{pp.calculation_type.toUpperCase()}
					</Tag>
				</Col>
				<Col flex='1'>
					{appSetting.renderPrice(pp.price)} {currency}
				</Col>
			</Row>
		),
		value: pp.product_id,
		key: `${pp.id}`,
		name: `${pp.name},${removeDiac(pp.name)},${pp.price / 100}${currency}${pp.calculation_type}`,
		cal_type: pp.calculation_type,
		price: pp.price
	}))
}

export const getServiceOptions = (items: CustomerServiceDetails[], currency: string, appSetting: AppSetting) => {
	return items.sort(sort_name).map((cs) => ({
		label: (cs.price ?
			(<Row>
				<Col flex='18'>{cs.name}</Col>
				<Col flex="2" >
					{
						cs.price.calculation_type && (
							<Tag color={cs.price.calculation_type === "fix" ? "red" : cs.price.calculation_type === "unit" ? "blue" : cs.price.calculation_type === "range" ? "green" : "blue"} >
								{cs.price.calculation_type.toUpperCase()}
							</Tag>
						)
					}

				</Col>
			</Row>
			) : (<Row><Col flex='24'>{cs.name}</Col></Row>)
		),
		value: cs.id,
		key: `${cs.id}`,
		name: `${cs.name},${removeDiac(cs.name)},${currency}`,
		cal_type: (cs.price && cs.price.calculation_type) ? cs.price.calculation_type : 'fix',
		price: cs.price ? cs.price.price_per_unit : 0
	}))
}

export const getRoundingPrice = (price: ProductPriceDetails | undefined) => {
	if (!price || !price.decimal_places) {
		return "any"
	}
	const decimalPlaces = price.decimal_places
	let x = "1"
	if (decimalPlaces === 0) {
		x = "1"
	}
	if (decimalPlaces === 1) {
		x = "0.1"
	}
	if (decimalPlaces === 2) {
		x = "0.01"
	}
	if (decimalPlaces === 3) {
		x = "0.001"
	}
	if (decimalPlaces === 4) {
		x = "0.0001"
	}
	return x
}

export const getNumbers = (numb: string, length: number) => {
	let result = ''
	for (let i = 0; i < length; i++) {
		result = result + numb
	}
	return result
}

export const getMax = (obj: ProductPriceDetails | undefined) => {
	if (!obj || !obj.decimal_places) {
		return "9999999"
	}
	let n = 7 - obj.decimal_places
	return getNumbers('9', n)
}
