import { CreateTrashRequestAction, CreateTrashResponseAction, CreateTrashErrorAction } from '.'
import { protectedApiClient } from 'helpers/api'
import { handleApiErrorWithNotification } from 'helpers/errorHandling'
import { CreateMailboxParams, MailboxModel } from '../models'

export default (params: CreateMailboxParams, cb?: (isSuccess: boolean) => void) => {
	return (
		dispatch: (arg: CreateTrashRequestAction | CreateTrashResponseAction | CreateTrashErrorAction) => void
	) => {
		const request = new CreateTrashRequestAction(params)
		dispatch(request)
		protectedApiClient
			.post<MailboxModel>(`/mail/domain/${params.mail_domain_id}/trash`, params)
			.then((response) => {
				dispatch(new CreateTrashResponseAction(request, response.data))
				cb && cb(true)
			})
			.catch((error) => {
				let msg = ''
				if (error) {
					if (error.message) {
						msg = error.message
					} else {
						msg = error.toString()
					}
				}
				dispatch(new CreateTrashErrorAction(request, msg))
				handleApiErrorWithNotification(error)
				cb && cb(false)
			})
	}
}
