import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { useForm } from "antd/lib/form/Form"
import { Button, Col, Form, Input, message, Row, Select, Spin, Switch } from "antd"
import React, { useEffect, useState } from "react"
import { Store } from "antd/lib/form/interface"
import { CreateServerParams } from "./models"
import createServer from "./actions/createServer"
import { sort_label } from "../../common/sorting"
import tableCustomers from "../login/actions/tableCustomers"
import { AppState } from "../../common/models"
import { useLoggedUser } from "../../helpers/loginUserHelper"
import { tailLayout } from "../../helpers/layoutHelpers"
import { _isValidDomain, _isValidIP4, stopPropagation } from "../../common/fce"
import listIp from "./actions/listIp"
import IpSelector from "../ip/subnet/IpSelector"
import { CheckOutlined, CloseOutlined } from "@ant-design/icons"


interface Props {
    onClose: () => void
}


const NewServerForm = (props: Props) => {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const [form1] = useForm()

    const { customers } = useSelector((state: AppState) => state.auth.tables)
    const { ip_addresses, isLoadingIps, server_types } = useSelector((state: AppState) => state.logic_server)

    const [customerOptions, setCustomerOptions] = useState<{ label: string, value: number, key: number }[]>([])
    const [serverTypeOptions, setServerTypeOptions] = useState<{ label: string, value: number, key: number }[]>([])
    const [selectedIpId, setSelectedIpId] = useState<number | undefined>()
    const [addDnsRec, setAddDnsRec] = useState<boolean>(false)
    const [addReverse, setAddReverse] = useState<boolean>(false)


    // get settings and logged user from store
    const loggedUser = useLoggedUser()
    if (!loggedUser || !loggedUser.isLoaded()) {
        return (<Spin />)
    }
    const appSetting = loggedUser.getAppSettings()

    useEffect(() => {
        // once
        if (!customers || customers.length === 0) {
            dispatch(tableCustomers())
        }
    }, [])

    useEffect(() => {
        if (customers && customers.length) {
            // render CustomerOptions
            const data = customers.map((c) => {
                return {
                    value: c.id,
                    key: c.id,
                    label: c.company!.name
                }
            })
            setCustomerOptions(data.sort(sort_label))
        }
    }, [customers])

    useEffect(() => {
        if (server_types) {
            setServerTypeOptions(
                server_types.map(item => ({
                    value: item.id,
                    key: item.id,
                    label: item.name
                }))
            )
        }
    }, [server_types])

    const submitServer = (values: Store): void => {
        // create a new server
        const params: CreateServerParams = {
            customer_id: loggedUser.user.is_zcom ? values.customer_id : loggedUser.customer.id,
            hostname: values.hostname,
            primary_ip_id: selectedIpId,
            type_id: values.type_id,
            add_reverse: values.add_reverse ? 1 : 0,
            add_dns_rec: values.add_dns_rec ? 1 : 0,
        }
        dispatch(createServer(params, suc => {
            if (suc) {
                message.success(t('general.success'))
                props.onClose()
            }
        }))
    }

    const fetchIpLookup = (searchText: string) => {
        // call lookup for Ip_name
        if (appSetting.checkMinSearch(searchText)) {
            dispatch(listIp(searchText))
        }
    }

    return (
        <Row justify='space-between' style={{ marginTop: '0' }}>
            <Col span={24} >
                <Form name='create-server'
                    labelCol={{ span: 6 }}
                    wrapperCol={{ span: 18 }}
                    layout="horizontal"
                    form={form1}
                    initialValues={{ customer_id: loggedUser.customer.id, type_id: 1 }}
                    onFinish={submitServer}
                >
                    <Form.Item name='customer_id' label={t('serversPage.customer_id')}
                        rules={[{ required: true, message: t('errors.field_required') }]}>
                        <Select optionFilterProp='label'
                            style={{ width: '100%' }}
                            options={customerOptions}
                            disabled={!loggedUser.user.is_zcom}
                            showSearch
                        />
                    </Form.Item>

                    <Form.Item name='hostname'
                        label={t('serversPage.hostname')}
                        rules={[
                            { required: true, message: t('errors.field_required') },
                            {
                                validator(_, value) {
                                    if (_isValidDomain(value) || _isValidIP4(value)) {
                                        return Promise.resolve()
                                    }
                                    return Promise.reject(new Error(t('domainPage.invalid_domain')))
                                }
                            }
                        ]}>
                        <Input placeholder='server.com' />
                    </Form.Item>

                    <Form.Item name='primary_ip_id' label={t('serversPage.primary_ip_id')}>
                        <IpSelector
                            dataSource={ip_addresses}
                            isLoadingIps={isLoadingIps}
                            handleSelectedIp={(ip_id) => { setSelectedIpId(ip_id) }}
                            fetchData={fetchIpLookup}
                            shadowUsed={true}
                        />
                    </Form.Item>

                    <Form.Item name='type_id' label={t('serversPage.server_type')}
                        rules={[{ required: true, message: t('errors.field_required') }]}>
                        <Select size='middle'
                            options={serverTypeOptions}
                            showSearch
                            optionFilterProp='label'
                        />
                    </Form.Item>

                    <Form.Item {...{
                        labelCol: { span: 21 },
                        wrapperCol: { span: 3 },
                    }}
                        name='add_dns_rec'
                        label={t('serversPage.add_dns_rec')}
                        style={{ fontSize: '0.8em' }}
                        valuePropName='checked'
                    >
                        <Switch size='small'
                            checkedChildren={<CheckOutlined />}
                            unCheckedChildren={<CloseOutlined />}
                            checked={addDnsRec}
                            onChange={() => {
                                setAddDnsRec(!addDnsRec)
                            }}
                        />
                    </Form.Item>

                    <Form.Item {...{
                        labelCol: { span: 21 },
                        wrapperCol: { span: 3 },
                    }}
                        name='add_reverse'
                        label={t('serversPage.add_reverse')}
                        valuePropName='checked'
                    >
                        <Switch size='small'
                            checkedChildren={<CheckOutlined />}
                            unCheckedChildren={<CloseOutlined />}
                            checked={addReverse}
                            onChange={() => {
                                setAddReverse(!addReverse)
                            }}
                        />
                    </Form.Item>

                    <Form.Item {...tailLayout}>
                        <Button type='primary' htmlType='submit' className='create-button'>
                            {t('serversPage.create_server')}
                        </Button>
                    </Form.Item>
                </Form>
            </Col>
        </Row>
    )
}

export default NewServerForm
