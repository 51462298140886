import { ActionType, AsyncJobActions } from './actions'
import { AsyncActionMode, NameItemStr, PAGE1 } from 'common/models'
import { AsyncJobModel, AsyncJobState } from './models'

const ASYNCJOB_INITIAL_STATE: AsyncJobState = {
	isLoading: false,
	isSaving: false,
	error: undefined,
	jobs: { items: [], pagination: PAGE1 },
	pager: PAGE1,
	server_lookup: [],
	isLoadingLookup: false,
	server: undefined,
	server_runner_log: undefined,
	job_states: [],
	job_names: [],
}

export default (state = ASYNCJOB_INITIAL_STATE, action: AsyncJobActions): AsyncJobState => {
	switch (action.type) {
		case ActionType.RESOLVE_JOB:
		case ActionType.CANCEL_JOB:
		case ActionType.RESTART_JOB:
			if (action.mode === AsyncActionMode.REQUEST) {
				return { ...state, actionedId: action.id, isSaving: true }
			}
			if (action.mode === AsyncActionMode.RESPONSE) {
				return {
					...state,
					job: action.data,
					jobs: {
						...state.jobs,
						items: state.jobs.items!.map((j) => (j.id === action.data.id ? { ...action.data } : j)),
					},
					isSaving: false,
				}
			}
			if (action.mode === AsyncActionMode.ERROR) {
				return {
					...state,
					isSaving: false,
					error: action.error,
				}
			}
			break
		case ActionType.GET_ASYNCJOB:
			if (action.mode === AsyncActionMode.REQUEST) {
				return { ...state, job: undefined, isLoading: true }
			}
			if (action.mode === AsyncActionMode.RESPONSE) {
				return {
					...state,
					job: action.data,
					isLoading: false,
				}
			}
			if (action.mode === AsyncActionMode.ERROR) {
				return {
					...state,
					isLoading: false,
					error: action.error,
				}
			}
			break

		case ActionType.GET_JOB_NAMES:
			if (action.mode === AsyncActionMode.REQUEST) {
				return { ...state, isLoading: true }
			}
			if (action.mode === AsyncActionMode.RESPONSE) {
				return {
					...state,
					job_names: action.data,
					isLoading: false,
				}
			}
			if (action.mode === AsyncActionMode.ERROR) {
				return {
					...state,
					isLoading: false,
					error: action.error,
				}
			}
			break
		case ActionType.GET_JOB_STATES:
			if (action.mode === AsyncActionMode.REQUEST) {
				return { ...state, isLoading: true }
			}
			if (action.mode === AsyncActionMode.RESPONSE) {
				return {
					...state,
					job_states: action.data,
					isLoading: false,
				}
			}
			if (action.mode === AsyncActionMode.ERROR) {
				return {
					...state,
					isLoading: false,
					error: action.error,
				}
			}
			break

		case ActionType.CREATE_ASYNCJOB:
			if (action.mode === AsyncActionMode.REQUEST) {
				return { ...state, isSaving: true }
			}
			if (action.mode === AsyncActionMode.RESPONSE) {
				return {
					...state,
					jobs: {
						...state.jobs,
						items: state.jobs.items.concat(action.data),
					},
					job: action.data,
					isSaving: false,
				}
			}
			if (action.mode === AsyncActionMode.ERROR) {
				return {
					...state,
					error: action.error,
					isSaving: false,
				}
			}
			break
		case ActionType.GET_ASYNCJOBS:
			if (action.mode === AsyncActionMode.REQUEST) {
				return { ...state, isLoading: true }
			}
			if (action.mode === AsyncActionMode.RESPONSE) {
				return {
					...state,
					isLoading: false,
					job: undefined,
					jobs: action.data,
					pager: { ...action.data.pagination, page: action.data.pagination.page + 1 },
				}
			}
			if (action.mode === AsyncActionMode.ERROR) {
				return {
					...state,
					isLoading: false,
					error: action.error,
				}
			}
			break
		case ActionType.DELETE_ASYNCJOB:
			if (action.mode === AsyncActionMode.REQUEST) {
				return { ...state, isSaving: true }
			}
			if (action.mode === AsyncActionMode.RESPONSE) {
				return {
					...state,
					job: undefined,
					jobs: {
						...state.jobs,
						items: state.jobs.items!.filter((j) => j.id !== action.request.id),
					},
					isSaving: false,
				}
			}
			if (action.mode === AsyncActionMode.ERROR) {
				return {
					...state,
					isSaving: false,
					error: action.error,
				}
			}
			break

		case ActionType.UPDATE_ASYNCJOB:
			if (action.mode === AsyncActionMode.REQUEST) {
				return { ...state, isSaving: true }
			}
			if (action.mode === AsyncActionMode.RESPONSE) {
				return {
					...state,
					job: action.data,
					jobs: {
						...state.jobs,
						items: state.jobs.items!.map((j) => (j.id === action.data.id ? { ...action.data } : j)),
					},
					isSaving: false,
				}
			}
			if (action.mode === AsyncActionMode.ERROR) {
				return {
					...state,
					isSaving: false,
					error: action.error,
				}
			}
			break

		case ActionType.LOOKUP_SERVER_JOB:
			if (action.mode === AsyncActionMode.REQUEST) {
				return { ...state, isLoadingLookup: true }
			}
			if (action.mode === AsyncActionMode.RESPONSE) {
				if (action.field === 'hostname') {
					return {
						...state,
						server_lookup: action.data,
						isLoadingLookup: false,
					}
				}
				console.error('Wrong action field.')
			}
			if (action.mode === AsyncActionMode.ERROR) {
				return {
					...state,
					error: action.error,
					isLoadingLookup: false,
				}
			}
			break

		case ActionType.FIND_SERVERS:
			if (action.mode === AsyncActionMode.REQUEST) {
				return { ...state, server: undefined, isLoadingLookup: true }
			}
			if (action.mode === AsyncActionMode.RESPONSE) {
				return {
					...state,
					isLoadingLookup: false,
					server: action.data.items.length > 0 ? action.data.items[0] : undefined,
				}
			}
			if (action.mode === AsyncActionMode.ERROR) {
				return {
					...state,
					isLoadingLookup: false,
					error: action.error,
				}
			}
			break
		case ActionType.GET_SERVER_JOB:
			if (action.mode === AsyncActionMode.REQUEST) {
				return { ...state, server: undefined, isLoadingLookup: true }
			}
			if (action.mode === AsyncActionMode.RESPONSE) {
				return {
					...state,
					isLoadingLookup: false,
					server: action.data,
				}
			}
			if (action.mode === AsyncActionMode.ERROR) {
				return {
					...state,
					isLoadingLookup: false,
					error: action.error,
				}
			}
			break

		case ActionType.GET_ASYNCJOB_ACCESS_LOG:
			if (action.mode === AsyncActionMode.REQUEST) {
				return { ...state, server_runner_log: undefined }
			}
			if (action.mode === AsyncActionMode.RESPONSE) {
				if (Array.isArray(action.data) && action.data.length > 0) {
					return { ...state, server_runner_log: action.data[0] }
				} else {
					return { ...state, server_runner_log: undefined }
				}
			}
			if (action.mode === AsyncActionMode.ERROR) {
				return {
					...state,
					error: action.error,
				}
			}
			break
		case ActionType.CLEAR_SERVER_JOB:
			if (action.mode === AsyncActionMode.REQUEST) {
				return { ...state, server: undefined }
			}
			break
	}
	return state
}
