import { AsyncActionMode } from 'common/models'
import { HistoryDetails } from '../models'
import {AsyncLogRecord, LogRecord} from "../../../pages/logviewer/models";


export enum ActionType {
	GET_HISTORY = 'GET_HISTORY',
	GET_HISTORY2 = 'GET_HISTORY2',
	GET_HISTORY_LOGS = 'GET_HISTORY_LOGS',
	GET_HISTORY_DETAILS = 'GET_HISTORY_DETAILS',
	GET_HISTORY_VPSADMIN = 'GET_HISTORY_VPSADMIN',
	GET_ASYNCJOB_LOGS = 'GET_ASYNCJOB_LOGS'
}

// History2 is second history object (not the main one)

export type HistoryActions = GetHistoryRequestAction
	| GetHistoryResponseAction
	| GetHistoryErrorAction
	| GetHistory2RequestAction
	| GetHistory2ResponseAction
	| GetHistory2ErrorAction
	| GetHistoryLogsRequestAction
	| GetHistoryLogsResponseAction
	| GetHistoryLogsErrorAction
	| GetHistoryDetailsRequestAction
	| GetHistoryDetailsResponseAction
	| GetHistoryDetailsErrorAction
	| GetLogsScanningRequestAction
	| GetLogsScanningResponseAction
	| GetLogsScanningErrorAction
	| GetAsyncLogsRequestAction
	| GetAsyncLogsResponseAction
	| GetAsyncLogsErrorAction

export class GetHistoryRequestAction {
	readonly type = ActionType.GET_HISTORY
	readonly mode = AsyncActionMode.REQUEST
	constructor() {
		''
	}
}
export class GetHistoryResponseAction {
	readonly type = ActionType.GET_HISTORY
	readonly mode = AsyncActionMode.RESPONSE
	constructor(
		public request: GetHistoryRequestAction,
		public data: HistoryDetails[],
	) { }
}
export class GetHistoryErrorAction {
	readonly type = ActionType.GET_HISTORY
	readonly mode = AsyncActionMode.ERROR
	constructor(public request: GetHistoryRequestAction, public error: string) { }
}


export class GetHistory2RequestAction {
	readonly type = ActionType.GET_HISTORY2
	readonly mode = AsyncActionMode.REQUEST
	constructor() {
		''
	}
}
export class GetHistory2ResponseAction {
	readonly type = ActionType.GET_HISTORY2
	readonly mode = AsyncActionMode.RESPONSE
	constructor(
		public request: GetHistory2RequestAction,
		public data: HistoryDetails[],
	) { }
}
export class GetHistory2ErrorAction {
	readonly type = ActionType.GET_HISTORY2
	readonly mode = AsyncActionMode.ERROR
	constructor(public request: GetHistory2RequestAction, public error: string) { }
}



export class GetHistoryLogsRequestAction {
	readonly type = ActionType.GET_HISTORY_LOGS
	readonly mode = AsyncActionMode.REQUEST
	constructor() {
		''
	}
}
export class GetHistoryLogsResponseAction {
	readonly type = ActionType.GET_HISTORY_LOGS
	readonly mode = AsyncActionMode.RESPONSE
	constructor(
		public request: GetHistoryLogsRequestAction,
		public data: LogRecord[],
	) { }
}
export class GetHistoryLogsErrorAction {
	readonly type = ActionType.GET_HISTORY_LOGS
	readonly mode = AsyncActionMode.ERROR
	constructor(public request: GetHistoryLogsRequestAction, public error: string) { }
}


export class GetHistoryDetailsRequestAction {
	readonly type = ActionType.GET_HISTORY_DETAILS
	readonly mode = AsyncActionMode.REQUEST
	constructor() {
		''
	}
}
export class GetHistoryDetailsResponseAction {
	readonly type = ActionType.GET_HISTORY_DETAILS
	readonly mode = AsyncActionMode.RESPONSE
	constructor(
		public request: GetHistoryDetailsRequestAction,
		public data: LogRecord[],
	) { }
}
export class GetHistoryDetailsErrorAction {
	readonly type = ActionType.GET_HISTORY_DETAILS
	readonly mode = AsyncActionMode.ERROR
	constructor(public request: GetHistoryDetailsRequestAction, public error: string) { }
}

// get logs from scanning hypervisors
export class GetLogsScanningRequestAction {
	readonly type = ActionType.GET_HISTORY_VPSADMIN
	readonly mode = AsyncActionMode.REQUEST
	constructor(public id: number, last_days: number) { }

}
export class GetLogsScanningResponseAction {
	readonly type = ActionType.GET_HISTORY_VPSADMIN
	readonly mode = AsyncActionMode.RESPONSE
	constructor(public request: GetLogsScanningRequestAction, public data: LogRecord[]) { }
}

export class GetLogsScanningErrorAction {
	readonly type = ActionType.GET_HISTORY_VPSADMIN
	readonly mode = AsyncActionMode.ERROR
	constructor(public request: GetLogsScanningRequestAction, public error: string) { }
}

export class GetAsyncLogsRequestAction {
	readonly type = ActionType.GET_ASYNCJOB_LOGS
	readonly mode = AsyncActionMode.REQUEST
	constructor(public task_id: number) {
		''
	}
}
export class GetAsyncLogsResponseAction {
	readonly type = ActionType.GET_ASYNCJOB_LOGS
	readonly mode = AsyncActionMode.RESPONSE
	constructor(public request: GetAsyncLogsRequestAction, public data: AsyncLogRecord[]) { }
}

export class GetAsyncLogsErrorAction {
	readonly type = ActionType.GET_ASYNCJOB_LOGS
	readonly mode = AsyncActionMode.ERROR
	constructor(public request: GetAsyncLogsRequestAction, public error: string) { }
}

