import {
    UpdateStatusPlanRequestAction,
    UpdateStatusPlanResponseAction,
    UpdateStatusPlanErrorAction,
} from '.'
import { protectedApiClient } from 'helpers/api'
import { handleApiErrorWithNotification } from 'helpers/errorHandling'
import { StatusPlan } from '../models'


export default (
    params: StatusPlan,
    cb?: (isSuccess: boolean) => void,
) => {
    return (
        dispatch: (
            arg:
                | UpdateStatusPlanRequestAction
                | UpdateStatusPlanResponseAction
                | UpdateStatusPlanErrorAction,
        ) => void,
    ) => {
        const request = new UpdateStatusPlanRequestAction(params)
        dispatch(request)

        protectedApiClient
            .put<StatusPlan>(`/server/status-scheduler/${params.id}`, params)
            .then((response) => {
                dispatch(new UpdateStatusPlanResponseAction(request, response.data))
                cb && cb(true)
            })
            .catch((error) => {
                dispatch(new UpdateStatusPlanErrorAction(request, error))
                handleApiErrorWithNotification(error)
                cb && cb(false)
            })
    }
}
