import { ActionType, LoginActions } from './actions'
import { AuthState, GTABLE1 } from './models'
import { AsyncActionMode, NameItem } from 'common/models'
import { CompanyDetails } from '../company/models'
import { CustomerDetails } from '../billing/customer/models'

const SIDEBAR_HELP = { title: 'ZIS3 Dokumentace', path: '', body: '', show: false }

const INITIAL_STATE: AuthState = {
	authenticated: false,
	isLoading: false,
	isSaving: false,
	self_settings: [],
	methods: [],
	reason: '',
	status: 0,
	buffer: [],
	right_sidebar: SIDEBAR_HELP,
	docs_section_content: {},
	tables: GTABLE1,
}

const loadCompanies = (items: NameItem[]): Map<number, string> | undefined => {
	let map: Map<number, string> = new Map()
	if (!items) {
		return undefined
	}
	for (const company of items) {
		map.set(company.id, company.name)
	}
	return map
}

const loadCustomers = (items: CustomerDetails[]): Map<number, string> | undefined => {
	if (!items) {
		return undefined
	}

	let map: Map<number, string> = new Map()
	let name: string | undefined
	for (const customer of items) {
		name = customer.company?.name
		name ? map.set(customer.id, name) : map.set(customer.id, `cust_${customer.id}`)
	}
	return map
}

export default (state: AuthState = INITIAL_STATE, action: LoginActions): AuthState => {
	switch (action.type) {
		case ActionType.TRY_TO_LOAD_USER:
			// it uses token from localStorage
			if (action.mode === AsyncActionMode.REQUEST) {
				return { ...state, isLoading: true }
			}
			if (action.mode === AsyncActionMode.RESPONSE) {
				return {
					...state,
					self: action.data,
					isLoading: false,
				}
			}
			if (action.mode === AsyncActionMode.ERROR) {
				return {
					...state,
					isLoading: false,
					error: action.error,
				}
			}
			break

		case ActionType.SELECT_CUSTOMER:
			if (action.mode === AsyncActionMode.REQUEST) {
				return { ...state, isLoading: true }
			}
			if (action.mode === AsyncActionMode.RESPONSE) {
				return {
					...state,
					select_customer: action.select_customer,
					isLoading: false,
				}
			}
			if (action.mode === AsyncActionMode.ERROR) {
				return {
					...state,
					isLoading: false,
					error: action.error,
				}
			}
			break
		case ActionType.GET_CUSTOMER_SELF:
			if (action.mode === AsyncActionMode.REQUEST) {
				return { ...state, isLoading: true }
			}
			if (action.mode === AsyncActionMode.RESPONSE) {
				return {
					...state,
					isLoading: false,
					self_customer: action.data,
				}
			}
			if (action.mode === AsyncActionMode.ERROR) {
				return {
					...state,
					isLoading: false,
					error: action.error,
					self_customer: undefined,
				}
			}
			break
		case ActionType.GET_COMPANY_SELF:
			if (action.mode === AsyncActionMode.REQUEST) {
				return { ...state, isLoading: true }
			}
			if (action.mode === AsyncActionMode.RESPONSE) {
				return {
					...state,
					isLoading: false,
					company: action.data,
				}
			}
			if (action.mode === AsyncActionMode.ERROR) {
				return {
					...state,
					isLoading: false,
					error: action.error,
				}
			}
			break
		case ActionType.USER_DETAILS:
			if (action.mode === AsyncActionMode.REQUEST) {
				return { ...state, isLoading: true }
			}
			if (action.mode === AsyncActionMode.RESPONSE) {
				return {
					...state,
					self: action.data,
					isLoading: false,
				}
			}
			if (action.mode === AsyncActionMode.ERROR) {
				return {
					...state,
					isLoading: false,
					error: action.error,
				}
			}
			break
		case ActionType.GET_MENU_ROLES:
			if (action.mode === AsyncActionMode.REQUEST) {
				return { ...state, isLoading: true }
			}
			if (action.mode === AsyncActionMode.RESPONSE) {
				return {
					...state,
					isLoading: false,
					menu_roles: action.data,
				}
			}
			if (action.mode === AsyncActionMode.ERROR) {
				return {
					...state,
					isLoading: false,
					error: action.error,
				}
			}
			break
		case ActionType.LOGIN_USER:
			if (action.mode === AsyncActionMode.REQUEST) {
				return { ...INITIAL_STATE, isLoading: true }
				// return { ...state, reason: '', methods: [], status: 0, isLoading: true }
			}
			if (action.mode === AsyncActionMode.RESPONSE) {
				return {
					...state,
					isLoading: false,
					token: action.data.token,
					reason: action.data.reason,
					methods: action.data.methods ? action.data.methods : [],
					authenticated: !!action.data.token,
					buffer: [],
					error: '',
					last_update: new Date().getTime(),
				}
			}
			if (action.mode === AsyncActionMode.ERROR) {
				return {
					...state,
					isLoading: false,
					token: undefined,
					error: action.error,
					authenticated: false,
					buffer: [],
				}
			}
			break
		case ActionType.SET_PASSWORD:
			if (action.mode === AsyncActionMode.REQUEST) {
				return { ...state, isLoading: true }
			}
			if (action.mode === AsyncActionMode.RESPONSE) {
				return {
					...state,
					isLoading: false,
					token: action.data.token,
					reason: action.data.reason,
					methods: action.data.methods ? action.data.methods : [],
					authenticated: !!action.data.token,
					last_update: new Date().getTime(),
				}
			}
			if (action.mode === AsyncActionMode.ERROR) {
				return {
					...state,
					isLoading: false,
					token: undefined,
					error: action.error,
					authenticated: false,
				}
			}
			break
		case ActionType.SET_PASSWORD_REQUEST:
			if (action.mode === AsyncActionMode.REQUEST) {
				return {
					...state,
					reason: 'reset-password',
					methods: [{ method: 'reset-password', request: action.requestId }],
					message: '',
					error: '',
				}
			}
			if (action.mode === AsyncActionMode.RESPONSE) {
				return {
					...state,
					isLoading: false,
					error: '',
				}
			}
			if (action.mode === AsyncActionMode.ERROR) {
				return {
					...state,
					isLoading: false,
					error: action.error,
				}
			}
			break
		case ActionType.LOGIN_2FA:
			if (action.mode === AsyncActionMode.REQUEST) {
				return { ...state, isLoading: true }
			}
			if (action.mode === AsyncActionMode.RESPONSE) {
				return {
					...state,
					isLoading: false,
					token: action.data.token,
					reason: action.data.reason,
					methods: action.data.methods ? action.data.methods : [],
					authenticated: !!action.data.token,
					status: 200,
					last_update: new Date().getTime(),
				}
			}
			if (action.mode === AsyncActionMode.ERROR) {
				return {
					...state,
					isLoading: false,
					token: undefined,
					error: action.error,
					authenticated: false,
				}
			}
			break

		case ActionType.SEND_2FA_CODE:
			if (action.mode === AsyncActionMode.REQUEST) {
				return { ...state }
			}
			if (action.mode === AsyncActionMode.RESPONSE) {
				return {
					...state,
					message: action.data.message,
				}
			}
			if (action.mode === AsyncActionMode.ERROR) {
				return {
					...state,
					error: action.error,
				}
			}
			break

		case ActionType.RELOAD_TOKEN:
			if (action.mode === AsyncActionMode.REQUEST) {
				return { ...state, isLoading: false }
			}
			if (action.mode === AsyncActionMode.RESPONSE) {
				return {
					...state,
					token: action.token,
					authenticated: true,
					isLoading: false,
				}
			}
			if (action.mode === AsyncActionMode.ERROR) {
				return {
					...state,
					isLoading: false,
					error: action.error,
				}
			}
			break

		case ActionType.SET_LAST_UPDATE:
			return {
				...state,
				last_update: new Date().getTime(),
			}

		case ActionType.GET_SETTING_SELF:
			if (action.mode === AsyncActionMode.REQUEST) {
				return { ...state, isLoading: true }
			}
			if (action.mode === AsyncActionMode.RESPONSE) {
				return {
					...state,
					isLoading: false,
					self_settings: action.data,
				}
			}
			if (action.mode === AsyncActionMode.ERROR) {
				return {
					...state,
					isLoading: false,
					self_settings: [],
				}
			}
			break

		case ActionType.UPDATE_SELF_SETTINGS:
			if (action.mode === AsyncActionMode.REQUEST) {
				return { ...state, isSaving: true }
			}
			if (action.mode === AsyncActionMode.RESPONSE) {
				//console.log('reducer: grid_page_size='+JSON.stringify(action.data))
				return {
					...state,
					isSaving: false,
					self_settings: state.self_settings.map((s) => (s.id === action.data.id ? action.data : s)),
				}
			}
			if (action.mode === AsyncActionMode.ERROR) {
				return {
					...state,
					isSaving: false,
					error: action.error,
				}
			}
			break

		case ActionType.UPDATE_SETTING_ITEM:
			if (action.mode === AsyncActionMode.REQUEST) {
				return { ...state, isSaving: true }
			}
			if (action.mode === AsyncActionMode.RESPONSE) {
				const obj = state.self_settings.find((s) => s.name === action.data.name)
				let settings = state.self_settings.filter((s) => s.id)
				if (obj) {
					settings = state.self_settings.filter((s) => s.name != action.data.name)
				}
				return {
					...state,
					isSaving: false,
					self_settings: settings.concat(action.data),
				}
			}
			if (action.mode === AsyncActionMode.ERROR) {
				return {
					...state,
					isSaving: false,
					error: action.error,
				}
			}
			break

		case ActionType.LOGOUT_USER:
			if (action.mode === AsyncActionMode.REQUEST) {
				return {
					...state,
					isLoading: false, // do false
				}
			}
			if (action.mode === AsyncActionMode.RESPONSE) {
				return {
					...state,
					isLoading: false,
					authenticated: false,
					token: undefined,
					menu_roles: {},
					self: undefined,
					self_customer: undefined,
					self_settings: [],
					select_customer: undefined,
					last_update: undefined,
					buffer: [],
				}
			}
			if (action.mode === AsyncActionMode.ERROR) {
				// do the same => logout
				return {
					...state,
					isLoading: false,
					authenticated: false,
					token: undefined,
					menu_roles: {},
					self: undefined,
					self_customer: undefined,
					self_settings: [],
					select_customer: undefined,
					last_update: undefined,
					buffer: [],
					error: action.error,
				}
			}
			break

		case ActionType.TOKEN_EXPIRED:
			return { ...state, token: undefined, authenticated: false, menu_roles: {}, self: undefined }

		case ActionType.LOGIN_USER_AS:
			if (action.mode === AsyncActionMode.REQUEST) {
				return {
					...state,
					origin_token: state.token ? state.token : undefined,
					origin_user: state.self,
					isLoading: true,
				}
			}
			if (action.mode === AsyncActionMode.RESPONSE) {
				return {
					...state,
					isLoading: false,
					token: action.data.token,
				}
			}
			if (action.mode === AsyncActionMode.ERROR) {
				return {
					...state,
					isLoading: false,
					origin_token: undefined,
					origin_user: undefined,
					error: action.error,
				}
			}
			break

		case ActionType.LOGOUT_USER_AS:
			if (action.mode === AsyncActionMode.REQUEST) {
				return {
					...state,
					token: state.origin_token,
					origin_token: undefined,
					origin_user: undefined,
					isLoading: true,
				}
			}
			if (action.mode === AsyncActionMode.RESPONSE) {
				return {
					...state,
					isLoading: false,
					error: undefined,
				}
			}
			if (action.mode === AsyncActionMode.ERROR) {
				return {
					...state,
					isLoading: false,
					error: action.error,
				}
			}
			break

		case ActionType.LOG_URL:
			if (action.mode === AsyncActionMode.REQUEST) {
				return { ...state }
			}
			if (action.mode === AsyncActionMode.RESPONSE) {
				let items: string[] = state.buffer
				const url = action.url.toLowerCase()
				// remove item
				const index = items.indexOf(url, 0)
				if (index > -1) {
					items.splice(index, 1)
				}
				items.push(url)
				if (items.length > 8) {
					items = items.slice(Math.max(items.length - 8, 0))
				}
				return {
					...state,
					buffer: items,
				}
			}
			if (action.mode === AsyncActionMode.ERROR) {
				return {
					...state,
				}
			}
			break

		case ActionType.TABLE_COMPANY:
			if (action.mode === AsyncActionMode.REQUEST) {
				return { ...state, isLoading: true }
			}
			if (action.mode === AsyncActionMode.RESPONSE) {
				return {
					...state,
					isLoading: false,
					tables: {
						...state.tables,
						companies: action.data,
						companyNames: loadCompanies(action.data),
						customers: state.tables ? state.tables.customers : [],
					},
				}
			}
			if (action.mode === AsyncActionMode.ERROR) {
				return {
					...state,
					isLoading: false,
					error: action.error,
				}
			}
			break
		case ActionType.TABLE_CUSTOMER:
			if (action.mode === AsyncActionMode.REQUEST) {
				return { ...state, isLoading: true }
			}
			if (action.mode === AsyncActionMode.RESPONSE) {
				return {
					...state,
					isLoading: false,
					tables: { ...state.tables, customers: action.data, customerNames: loadCustomers(action.data) },
				}
			}
			if (action.mode === AsyncActionMode.ERROR) {
				return {
					...state,
					isLoading: false,
					error: action.error,
				}
			}
			break
		case ActionType.GET_DOCS_TOC:
			if (action.mode === AsyncActionMode.REQUEST) {
				return { ...state }
			}
			if (action.mode === AsyncActionMode.RESPONSE) {
				return {
					...state,
					docs_toc: action.data,
				}
			}
			if (action.mode === AsyncActionMode.ERROR) {
				return {
					...state,
				}
			}
			break
		case ActionType.GET_DOCS_SECTIONS:
			if (action.mode === AsyncActionMode.REQUEST) {
				return { ...state }
			}
			if (action.mode === AsyncActionMode.RESPONSE) {
				return {
					...state,
					docs_sections: action.data,
				}
			}
			if (action.mode === AsyncActionMode.ERROR) {
				return {
					...state,
				}
			}
			break
		case ActionType.GET_DOCS_SECTION_CONTENT:
			if (action.mode === AsyncActionMode.REQUEST) {
				return { ...state }
			}
			if (action.mode === AsyncActionMode.RESPONSE) {
				const sectionKey = action.request.section
				let oldData = { ...state.docs_section_content }
				if (!oldData[sectionKey]) {
					oldData[sectionKey] = action.data
				} else {
					oldData[sectionKey] = action.data
				}

				return {
					...state,
					docs_section_content: oldData,
				}
			}
			if (action.mode === AsyncActionMode.ERROR) {
				return {
					...state,
				}
			}
			break
		case ActionType.GET_DOCS_SEARCH:
			if (action.mode === AsyncActionMode.REQUEST) {
				return { ...state }
			}
			if (action.mode === AsyncActionMode.RESPONSE) {
				return {
					...state,
					docs_sections: action.data,
				}
			}
			if (action.mode === AsyncActionMode.ERROR) {
				return {
					...state,
				}
			}
			break
		case ActionType.SHOW_RIGHT_SIDEBAR:
			return {
				...state,
				right_sidebar: state.right_sidebar ? { ...state.right_sidebar, show: action.isVisible } : SIDEBAR_HELP,
			}
			break
		case ActionType.UPDATE_RIGHT_SIDEBAR:
			return {
				...state,
				right_sidebar: action.payload ? action.payload : SIDEBAR_HELP,
			}
			break
	}
	return state
}
