import { GetRootGroupRequestAction, GetRootGroupResponseAction, GetRootGroupErrorAction } from '.'
import { protectedApiClient } from 'helpers/api'
import { handleApiErrorWithNotification } from 'helpers/errorHandling'
import { UserGroupModel } from '../models'

export default (customer_id: number | undefined, cb?: (isSuccess: boolean, id?: number) => void) => {
	return (
		dispatch: (args: GetRootGroupRequestAction | GetRootGroupResponseAction | GetRootGroupErrorAction) => void
	) => {
		const request = new GetRootGroupRequestAction(customer_id)
		dispatch(request)

		if (!customer_id) {
			customer_id = 1
		}
		let url = `/customer/${customer_id}/user-group/root?expand=customer,roles,resourceGroups`
		protectedApiClient
			.get<UserGroupModel>(url)
			.then((response) => {
				dispatch(new GetRootGroupResponseAction(request, response.data))
				cb && cb(true, response.data.id)
			})
			.catch((error) => {
				dispatch(new GetRootGroupErrorAction(request, error))
				handleApiErrorWithNotification(error)
				cb && cb(false)
			})
	}
}
