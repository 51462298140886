import { protectedApiClient } from 'helpers/api'
import { handleApiErrorWithNotification } from 'helpers/errorHandling'
import {
    ListPhysicalServersErrorAction,
    ListPhysicalServersRequestAction,
    ListPhysicalServersResponseAction
} from "./index";
import {NameItem} from "../../../common/models";



export default (cb?: (isSuccess: boolean) => void) => {
    return (
        dispatch: (
            arg:
                | ListPhysicalServersRequestAction
                | ListPhysicalServersResponseAction
                | ListPhysicalServersErrorAction,
        ) => void,
    ) => {
        const request = new ListPhysicalServersRequestAction()
        dispatch(request)

        protectedApiClient
            .get<NameItem[]>('/server/physical-server')
            .then((response) => {
                dispatch(new ListPhysicalServersResponseAction(request, response.data))
                cb && cb(true)
            })
            .catch((error) => {
                dispatch(new ListPhysicalServersErrorAction(request, error))
                handleApiErrorWithNotification(error)
                cb && cb(false)
            })
    }
}
