import {ClearServerJobRequestAction} from "./index";


export default (cb?: (isSuccess: boolean) => void) => {
    return (
        dispatch: (arg: ClearServerJobRequestAction) => void,
    ) => {
        const request = new ClearServerJobRequestAction()
        dispatch(request)
    }
}
