import { GetWebServiceRequestAction, GetWebServiceResponseAction, GetWebServiceErrorAction } from './index'
import { protectedApiClient } from 'helpers/api'
import { handleApiErrorWithNotification } from 'helpers/errorHandling'
import { WebServiceModel } from '../models'

export default (id: number, cb?: (isSuccess: boolean) => void) => {
	return (
		dispatch: (arg: GetWebServiceRequestAction | GetWebServiceResponseAction | GetWebServiceErrorAction) => void
	) => {
		const request = new GetWebServiceRequestAction(id)
		dispatch(request)

		protectedApiClient
			.get<WebServiceModel>(`/web/service/${id}?expand=server,customer,has_afw`)
			.then((response) => {
				dispatch(new GetWebServiceResponseAction(request, response.data))
				cb && cb(true)
			})
			.catch((error) => {
				dispatch(new GetWebServiceErrorAction(request, error.toString()))
				handleApiErrorWithNotification(error)
				cb && cb(false)
			})
	}
}
