import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { useForm } from "antd/lib/form/Form"
import { Button, Col, Form, Input, message, Modal, Row, Spin } from "antd"
import { Store } from "antd/lib/form/interface"
import { _isValidDomain } from "common/fce"
import { AppState } from "common/models"
import createAlias from "./actions/createAlias"
import { AliasParams } from "./models"
import TextArea from "antd/lib/input/TextArea"
import { useEffect } from "react"
import previewConfig from "./actions/previewConfig"

interface Props {
  onClose: () => void
}


const PreviewConfigForm = (props: Props) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [form] = useForm()

  const { vhost, vhost_config } = useSelector((state: AppState) => state.webservice)

  useEffect(() => {
    if (vhost && vhost.id) {
      dispatch(previewConfig(vhost.id))
    }
  }, [])


  if (!vhost) {
    return <Spin />
  }

  return (
    <Row justify='space-between' style={{ margin: 0, padding: 0 }}>
      <Col span={24} className='center' style={{ margin: 0, padding: 0 }} >

        <TextArea data-gramm="false"
          data-gramm_editor="false"
          data-enable-grammarly="false"
          rows={20}
          className='console-text'
          value={vhost_config}
        />

        <Button type="default" onClick={props.onClose} style={{ margin: 8 }}>
          {t('general.cancel')}
        </Button>

      </Col>
    </Row>
  )
}

export default PreviewConfigForm
