import {
  UserLoginRequestAction,
  UserLoginResponseAction,
  UserLoginErrorAction,
} from '.'
import {LoginParams, AuthState, Auth2faResponseData} from '../models'
import { apiClient } from 'helpers/api'
import { handleApiErrorWithNotification } from 'helpers/errorHandling'
import {errorPopup} from "../../../components/SwalPopup/swalPopup";

export default (params: LoginParams, cb?: (isSuccess: boolean) => void) => {
  return (
    dispatch: (
      arg:
        | UserLoginRequestAction
        | UserLoginResponseAction
        | UserLoginErrorAction,
    ) => void,
  ) => {
    const request = new UserLoginRequestAction(params)
    dispatch(request)

    apiClient
      .post<Auth2faResponseData>(`${process.env.REACT_APP_AUTH_PATH}`, params)
      .then((response) => {
        dispatch(new UserLoginResponseAction(request, response.data))
        cb && cb(true)
      })
      .catch((error) => {
        dispatch(new UserLoginErrorAction(request, error))
        if (error && error.message && error.message.includes('code 403')) {
          errorPopup('Missing permissions', error.message)
        }
        else {
          handleApiErrorWithNotification(error)
        }
        cb && cb(false)
      })
  }
}
