import { protectedApiClient } from 'helpers/api'
import { handleApiError, handleApiErrorWithNotification } from 'helpers/errorHandling'

import { ChangePasswordErrorAction, ChangePasswordRequestAction, ChangePasswordResponseAction } from './index'
import { Auth2faResponseData } from '../../login/models'
import { ChangePasswordParams } from '../models'

export default (params: ChangePasswordParams, cb?: (isSuccess: boolean) => void) => {
	return (
		dispatch: (arg: ChangePasswordRequestAction | ChangePasswordResponseAction | ChangePasswordErrorAction) => void
	) => {
		const request = new ChangePasswordRequestAction(params)
		dispatch(request)

		protectedApiClient
			.post<Auth2faResponseData>('/user/change-password', params)
			.then((response) => {
				dispatch(new ChangePasswordResponseAction(request, response.data))
				cb && cb(true)
			})
			.catch((error) => {
				if (error.response.status === 400) {
					const message: string = error.response.data.message
					dispatch(new ChangePasswordErrorAction(request, message))
					cb && cb(false)
					return
				}
				dispatch(new ChangePasswordErrorAction(request, error))
				handleApiErrorWithNotification(error)
				cb && cb(false)
			})
	}
}
