import {
    GetServersRdbRequestAction,
    GetServersRdbResponseAction,
    GetServersRdbErrorAction,
} from '.'
import { protectedApiClient } from 'helpers/api'
import { handleApiErrorWithNotification } from 'helpers/errorHandling'
import {ServerModel} from "../../server/models";



export default (prefix: string, cb?: (isSuccess: boolean) => void) => {
    return (
        dispatch: (
            arg:
                | GetServersRdbRequestAction
                | GetServersRdbResponseAction
                | GetServersRdbErrorAction,
        ) => void,
    ) => {
        const request = new GetServersRdbRequestAction()
        dispatch(request)

        let url = `/server/server`
        if (prefix) {
            url = `/server/server?hostname=${prefix}`
        }
        protectedApiClient
            .get<ServerModel[]>(url)
            .then((response) => {
                dispatch(new GetServersRdbResponseAction(request, response.data))
                cb && cb(true)
            })
            .catch((error) => {
                dispatch(new GetServersRdbErrorAction(request, error))
                handleApiErrorWithNotification(error)
                cb && cb(false)
            })
    }
}
