import { Col, Form, Input, message, Result, Row, Select, Spin, Switch, Tag } from "antd"
import { useForm } from "antd/lib/form/Form"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { useLoggedUser } from "../../helpers/loginUserHelper"
import React, { useEffect, useState } from "react"
import tableCustomers from "../login/actions/tableCustomers"
import { sort_label } from "../../common/sorting"
import { formItemLayout, tailLayout } from "../../helpers/layoutHelpers"
import { AppState } from "../../common/models"
import { Store } from "antd/lib/form/interface"
import { CreateDatabaseParams } from "./models"
import Button from "antd-button-color"
import createDatabase from "./actions/createDatabase"
import { removeDiac } from "../../common/fce"
import { useHistory } from "react-router"
import getDbPassword from "./actions/getDbPassword"
import { CheckCircleOutlined, CloseCircleOutlined, SyncOutlined } from "@ant-design/icons"
import getServiceDatabases from "./actions/getServiceDatabases"
import getRdbServiceDefault from "./actions/getRdbServiceDefault"



interface Props {
    onClose: () => void
}

const { Item } = Form


const CreateDatabaseForm = (props: Props) => {
    const [form] = useForm()
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const history = useHistory()

    const { customers } = useSelector((state: AppState) => state.auth.tables)
    const { database, isSavingDatabase, service, db_password, error, daemon } = useSelector((state: AppState) => state.rdbservice)

    const [customerOptions, setCustomerOptions] = useState<{ label: string, value: number, key: number }[]>([])
    const [updateStatus, setUpdateStatus] = useState<boolean>(false)


    const PAGE_FORM = 0
    const PAGE_LOADING = 1
    const PAGE_SUCCESS = 2
    const PAGE_ERROR = 3
    const [pageState, setPageState] = useState<number>(PAGE_FORM)

    // get settings and logged user from store
    const loggedUser = useLoggedUser()
    if (!loggedUser || !loggedUser.isLoaded()) {
        return (<Spin />)
    }
    const appSetting = loggedUser.getAppSettings()

    useEffect(() => {
        // once
        if (!service || !service.id) {
            const setting = appSetting.getSettingItem('rdb_service_id')
            if (setting) {
                dispatch(getRdbServiceDefault(setting.value))
            }
        }
        if (!customers || customers.length === 0) {
            dispatch(tableCustomers())
        }
    }, [])

    useEffect(() => {
        // once
        if (database && database.id && !db_password) {
            dispatch(getDbPassword(database.id))
        }
    }, [db_password])

    useEffect(() => {
        if (customers) {
            const data = customers.map((c) => {
                return {
                    value: c.id,
                    key: c.id,
                    label: c.company!.name
                }
            })
            setCustomerOptions(data.sort(sort_label))
        }
    }, [customers])

    const onFormSubmit = (values: Store) => {
        if (service && service.id) {
            const params: CreateDatabaseParams = {
                service_id: service.id,
                customer_id: values.customer_id,
                name: values.name,
                userhost: isSupportUserHost() ? values.userhost : undefined
            }
            setPageState(1)
            dispatch(createDatabase(params, (suc, id) => {
                if (suc) {
                    setPageState(2)
                }
                else {
                    setPageState(3)
                }
                dispatch(getServiceDatabases(service.id))
            }))
        }
    }

    const isSupportUserHost = (): boolean => {
        if (daemon) {
            const d = parseInt(daemon)
            if (d && d > 6) {
                return true
            }
        }
        return false
    }

    if (!appSetting || Object.keys(appSetting).length === 0) {
        return (<Spin />)
    }

    if (!service || !service.id) {
        return (<Spin />)
    }

    if (pageState === PAGE_FORM) {
        // form
        return (
            <Form
                onFinish={onFormSubmit}
                form={form}
                onChange={() => setUpdateStatus(true)}
                initialValues={{
                    customer_id: loggedUser.user.customer_id,
                    service_name: service.host,
                    userhost: '%'
                }}
                {...formItemLayout}
            >

                <Form.Item label={t('databasesPage.rdb_service')} name='service_name'>
                    <Input disabled />
                </Form.Item>

                <Form.Item name='customer_id' rules={[{ required: true }]} label={t('databasesPage.customer')}>
                    <Select
                        showSearch
                        filterOption={(val, opt) => {
                            return !!opt && removeDiac(opt.label).includes(removeDiac(val))
                        }}
                        optionFilterProp='label'
                        options={customerOptions}
                        disabled={!loggedUser.user.is_zcom}
                        placeholder={t('databasesPage.customer')}
                        style={{ width: '100%' }}
                    >
                    </Select>
                </Form.Item>

                <Form.Item label={t('databasesPage.db_name')} rules={[{ required: true }]} name='name'>
                    <Input />
                </Form.Item>

                {
                    isSupportUserHost() && (
                        <Form.Item label={t('databasesPage.userhost')} rules={[{ required: true }]} name='userhost'>
                            <Input placeholder='% or 127.0.0.1' />
                        </Form.Item>
                    )
                }

                <Form.Item {...tailLayout}>
                    <Button type='primary'
                        loading={isSavingDatabase}
                        disabled={!updateStatus}
                        htmlType='submit'>
                        {t('general.create')}
                    </Button>
                </Form.Item>
            </Form>
        )
    }

    if (pageState === PAGE_LOADING) {
        // loading
        return (
            <Row>
                <Col span={24} className='center'>Communication with server <b>{service.host}</b></Col>
                <Col span={24} className='center'>&nbsp;</Col>
                <Col span={24} className='center'>
                    <Tag icon={<SyncOutlined spin />} color="processing">
                        processing
                    </Tag>
                </Col>
            </Row>
        )
    }

    if (pageState === PAGE_SUCCESS) {
        // result
        return (
            <Result
                status="success"
                style={{ padding: 0 }}
                title={
                    <p style={{ fontSize: '16px' }}>
                        {t('databasesPage.database_created')} <br />
                    </p>
                }
                subTitle={
                    <Row className="desc">
                        <Col span={12} className='right pad4'>Host:</Col>
                        <Col span={12} className='left pad4'><span style={{ fontFamily: 'monospace' }}><Tag>{service.host}</Tag></span></Col>
                        <Col span={12} className='right pad4'>Database:</Col>
                        <Col span={12} className='left pad4'><span style={{ fontFamily: 'monospace' }}><Tag>{database?.name}</Tag></span></Col>
                        <Col span={12} className='right pad4'>Username:</Col>
                        <Col span={12} className='left pad4'><span style={{ fontFamily: 'monospace' }}><Tag>{database?.name}</Tag></span></Col>
                        <Col span={12} className='right pad4'>Password:</Col>
                        <Col span={12} className='left pad4'><span style={{ fontFamily: 'monospace' }}><Tag>{db_password}</Tag></span></Col>
                    </Row>
                }
                extra={[
                    <Button key='close' onClick={() => { props.onClose() }}>{t('general.close')}</Button>
                ]}
            />
        )
    }

    if (pageState === PAGE_ERROR) {
        // result
        console.error(JSON.stringify(error))
        return (
            <Row>
                <Col span={24} className='center'>Error in communication with server <b>{service.host}</b></Col>
                <Col span={24} className='center'>&nbsp;</Col>
                <Col span={24} className='center'><Tag icon={<CloseCircleOutlined />} color="error">{error}</Tag></Col>
                <Col span={24} className='center'>&nbsp;</Col>
                <Col span={24} className='center'><Button onClick={() => { props.onClose() }}>{t('general.close')}</Button></Col>
            </Row>
        )
    }
    return <div>Error state</div>
}

export default CreateDatabaseForm
