import {
	GetWebServerVersionRequestAction,
	GetWebServerVersionResponseAction,
	GetWebServerVersionErrorAction,
} from './index'
import { protectedApiClient } from 'helpers/api'
import { handleApiError, handleApiErrorWithNotification } from 'helpers/errorHandling'
import { WebServerVersionResp } from '../models'

export default (id: number, cb?: (isSuccess: boolean) => void) => {
	return (
		dispatch: (
			arg: GetWebServerVersionRequestAction | GetWebServerVersionResponseAction | GetWebServerVersionErrorAction
		) => void
	) => {
		const request = new GetWebServerVersionRequestAction(id)
		dispatch(request)

		let url = `/web/service/${id}/apache-version`
		protectedApiClient
			.get<WebServerVersionResp>(url)
			.then((response) => {
				dispatch(new GetWebServerVersionResponseAction(request, response.data))
				cb && cb(true)
			})
			.catch((error) => {
				let msg = error.toString()
				if (parseInt(error.response.status) === 502) {
					msg = 'ERROR HTTP 502'
				}
				dispatch(new GetWebServerVersionErrorAction(request, msg))
				handleApiError(error)
				cb && cb(false)
			})
	}
}
