import { GetDatabaseRequestAction, GetDatabaseResponseAction, GetDatabaseErrorAction } from '.'
import { protectedApiClient } from 'helpers/api'
import { handleApiErrorWithNotification } from 'helpers/errorHandling'
import { DatabaseModel } from '../models'

export default (id: number, cb?: (isSuccess: boolean) => void) => {
	return (dispatch: (arg: GetDatabaseRequestAction | GetDatabaseResponseAction | GetDatabaseErrorAction) => void) => {
		const request = new GetDatabaseRequestAction(id)
		dispatch(request)

		protectedApiClient
			.get<DatabaseModel>(`/rdb/database/${id}?expand=service,service_driver,customer,username`)
			.then((response) => {
				dispatch(new GetDatabaseResponseAction(request, response.data))
				cb && cb(true)
			})
			.catch((error) => {
				dispatch(new GetDatabaseErrorAction(request, error))
				handleApiErrorWithNotification(error)
				cb && cb(false)
			})
	}
}
