import {AsyncActionMode, NameItem} from 'common/models'
import {
	AddRemoveIpResponse,
	CreateServerParams,
	UpdateServerParams,
	ServerAddRemoveIpParams, ServerGrid,
	ServerModel, StatusPlan, CreateStatusPlanParams,
} from '../models'
import {PhysicalServerModel} from "../../inventory/physicalserver/models";
import {IpAddressModel, IpModel, UpdateIpParams} from "../../ip/subnet/models";

export enum ActionType {
	CREATE_SERVER = 'CREATE_SERVER',
	GET_SERVERS = 'GET_SERVERS',
	GET_SERVER = 'GET_SERVER',
	LIST_PHYSICAL_SERVERS = 'LIST_PHYSICAL_SERVERS',
	UPDATE_SERVER = 'UPDATE_SERVER',
	DELETE_SERVER = 'DELETE_SERVER',
	ADD_IP_TO_SERVER = 'ADD_IP_TO_SERVER',
	REMOVE_IP_FROM_SERVER = 'REMOVE_IP_FROM_SERVER',
	GET_SERVER_SERVICE_TYPE = 'GET_SERVER_SERVICE_TYPE',
	GET_SERVER_TYPE = 'GET_SERVER_TYPE',
	GET_SERVER_VPS_TYPE = 'GET_SERVER_VPS_TYPE',
	GET_MANAGEMENT_TYPE = 'GET_MANAGEMENT_TYPE',
	GET_NOTIFICATIONS = 'GET_NOTIFICATIONS',
	GET_BACKUP_SERVERS = 'GET_BACKUP_SERVERS',
	GET_SERVER_MONITORING_TYPE = 'GET_SERVER_MONITORING_TYPE',
	GET_IP_LIST = 'GET_IP_LIST',
	GET_HYPERVISORS = 'GET_HYPERVISORS',
	GET_HYPERVISOR = 'GET_HYPERVISOR',
	GET_PHYS_SERVER = 'GET_PHYS_SERVER',
	LOOKUP_SERVER = 'LOOKUP_SERVER',
	GET_IP_SERVER = 'GET_IP_SERVER',
	UPDATE_IP_COMMENT = 'UPDATE_IP_COMMENT',
	GET_STATUS_PLAN = 'GET_STATUS_PLAN',
	CREATE_STATUS_PLAN = 'CREATE_STATUS_PLAN',
	UPDATE_STATUS_PLAN = 'UPDATE_STATUS_PLAN',
	DELETE_STATUS_PLAN = 'DELETE_STATUS_PLAN'
}


export type ServerActions =
	| AddIpToServerRequestAction
	| AddIpToServerResponseAction
	| AddIpToServerErrorAction
	| RemoveIpFromServerRequestAction
	| RemoveIpFromServerResponseAction
	| RemoveIpFromServerErrorAction
	| CreateServerRequestAction
	| CreateServerResponseAction
	| CreateServerErrorAction
	| GetServersRequestAction
	| GetServersResponseAction
	| GetServersErrorAction
	| GetServerRequestAction
	| GetServerResponseAction
	| GetServerErrorAction
	| GetHypervisorRequestAction
	| GetHypervisorResponseAction
	| GetHypervisorErrorAction
	| GetPhysServerRequestAction
	| GetPhysServerResponseAction
	| GetPhysServerErrorAction
	| UpdateServerRequestAction
	| UpdateServerResponseAction
	| UpdateServerErrorAction
	| DeleteServerRequestAction
	| DeleteServerResponseAction
	| DeleteServerErrorAction
	| GetServiceTypeRequestAction
	| GetServiceTypeResponseAction
	| GetServiceTypeErrorAction

	| GetServerTypeRequestAction
	| GetServerTypeResponseAction
	| GetServerTypeErrorAction
	| GetServerVpsTypeRequestAction
	| GetServerVpsTypeResponseAction
	| GetServerVpsTypeErrorAction
	| GetManagementTypeRequestAction
	| GetManagementTypeResponseAction
	| GetManagementTypeErrorAction
	| GetNotificationsRequestAction
	| GetNotificationsResponseAction
	| GetNotificationsErrorAction
	| GetBackupServersRequestAction
	| GetBackupServersResponseAction
	| GetBackupServersErrorAction
	| GetServerMonitoringTypeRequestAction
	| GetServerMonitoringTypeResponseAction
	| GetServerMonitoringTypeErrorAction
	| ListIpRequestAction
	| ListIpResponseAction
	| ListIpErrorAction
	| ListPhysicalServersRequestAction
	| ListPhysicalServersResponseAction
	| ListPhysicalServersErrorAction
	| GetHypervisorsRequestAction
	| GetHypervisorsResponseAction
	| GetHypervisorsErrorAction
	| LookupServerRequestAction
	| LookupServerResponseAction
	| LookupServerErrorAction
	| GetIpRequestAction
	| GetIpResponseAction
	| GetIpErrorAction
	| UpdateIpRequestAction
	| UpdateIpResponseAction
	| UpdateIpErrorAction
	| GetStatusPlanRequestAction
	| GetStatusPlanResponseAction
	| GetStatusPlanErrorAction
	| CreateStatusPlanRequestAction
	| CreateStatusPlanResponseAction
	| CreateStatusPlanErrorAction
	| UpdateStatusPlanRequestAction
	| UpdateStatusPlanResponseAction
	| UpdateStatusPlanErrorAction
	| DeleteStatusPlanRequestAction
	| DeleteStatusPlanResponseAction
	| DeleteStatusPlanErrorAction


export class GetServiceTypeRequestAction {
	readonly type = ActionType.GET_SERVER_SERVICE_TYPE
	readonly mode = AsyncActionMode.REQUEST
	constructor() {''}
}
export class GetServiceTypeResponseAction {
	readonly type = ActionType.GET_SERVER_SERVICE_TYPE
	readonly mode = AsyncActionMode.RESPONSE
	constructor(public request: GetServiceTypeRequestAction, public data: NameItem[]) {}
}
export class GetServiceTypeErrorAction {
	readonly type = ActionType.GET_SERVER_SERVICE_TYPE
	readonly mode = AsyncActionMode.ERROR
	constructor(public request: GetServiceTypeRequestAction, public error: string) {}
}
export class GetServerMonitoringTypeRequestAction {
	readonly type = ActionType.GET_SERVER_MONITORING_TYPE
	readonly mode = AsyncActionMode.REQUEST
	constructor() {''}
}
export class GetServerMonitoringTypeResponseAction {
	readonly type = ActionType.GET_SERVER_MONITORING_TYPE
	readonly mode = AsyncActionMode.RESPONSE
	constructor(public request: GetServerMonitoringTypeRequestAction, public data: NameItem[]) {}
}
export class GetServerMonitoringTypeErrorAction {
	readonly type = ActionType.GET_SERVER_MONITORING_TYPE
	readonly mode = AsyncActionMode.ERROR
	constructor(public request: GetServerMonitoringTypeRequestAction, public error: string) {}
}


export class GetNotificationsRequestAction {
	readonly type = ActionType.GET_NOTIFICATIONS
	readonly mode = AsyncActionMode.REQUEST
	constructor() {''}
}
export class GetNotificationsResponseAction {
	readonly type = ActionType.GET_NOTIFICATIONS
	readonly mode = AsyncActionMode.RESPONSE
	constructor(public request: GetNotificationsRequestAction, public data: NameItem[]) {}
}
export class GetNotificationsErrorAction {
	readonly type = ActionType.GET_NOTIFICATIONS
	readonly mode = AsyncActionMode.ERROR
	constructor(public request: GetNotificationsRequestAction, public error: string) {}
}

export class GetBackupServersRequestAction {
	readonly type = ActionType.GET_BACKUP_SERVERS
	readonly mode = AsyncActionMode.REQUEST
	constructor() {''}
}
export class GetBackupServersResponseAction {
	readonly type = ActionType.GET_BACKUP_SERVERS
	readonly mode = AsyncActionMode.RESPONSE
	constructor(public request: GetBackupServersRequestAction, public data: NameItem[]) {}
}
export class GetBackupServersErrorAction {
	readonly type = ActionType.GET_BACKUP_SERVERS
	readonly mode = AsyncActionMode.ERROR
	constructor(public request: GetBackupServersRequestAction, public error: string) {}
}
export class GetManagementTypeRequestAction {
	readonly type = ActionType.GET_MANAGEMENT_TYPE
	readonly mode = AsyncActionMode.REQUEST
	constructor() {''}
}
export class GetManagementTypeResponseAction {
	readonly type = ActionType.GET_MANAGEMENT_TYPE
	readonly mode = AsyncActionMode.RESPONSE
	constructor(public request: GetManagementTypeRequestAction, public data: NameItem[]) {}
}
export class GetManagementTypeErrorAction {
	readonly type = ActionType.GET_MANAGEMENT_TYPE
	readonly mode = AsyncActionMode.ERROR
	constructor(public request: GetManagementTypeRequestAction, public error: string) {}
}
export class GetServerTypeRequestAction {
	readonly type = ActionType.GET_SERVER_TYPE
	readonly mode = AsyncActionMode.REQUEST
	constructor() {''}
}
export class GetServerTypeResponseAction {
	readonly type = ActionType.GET_SERVER_TYPE
	readonly mode = AsyncActionMode.RESPONSE
	constructor(public request: GetServerTypeRequestAction, public data: NameItem[]) {}
}
export class GetServerTypeErrorAction {
	readonly type = ActionType.GET_SERVER_TYPE
	readonly mode = AsyncActionMode.ERROR
	constructor(public request: GetServerTypeRequestAction, public error: string) {}
}
export class GetServerVpsTypeRequestAction {
	readonly type = ActionType.GET_SERVER_VPS_TYPE
	readonly mode = AsyncActionMode.REQUEST
	constructor() {''}
}
export class GetServerVpsTypeResponseAction {
	readonly type = ActionType.GET_SERVER_VPS_TYPE
	readonly mode = AsyncActionMode.RESPONSE
	constructor(public request: GetServerVpsTypeRequestAction, public data: NameItem[]) {}
}
export class GetServerVpsTypeErrorAction {
	readonly type = ActionType.GET_SERVER_VPS_TYPE
	readonly mode = AsyncActionMode.ERROR
	constructor(public request: GetServerVpsTypeRequestAction, public error: string) {}
}
export class RemoveIpFromServerRequestAction {
	readonly type = ActionType.REMOVE_IP_FROM_SERVER
	readonly mode = AsyncActionMode.REQUEST
	constructor(public payload: ServerAddRemoveIpParams) {}
}
export class RemoveIpFromServerResponseAction {
	readonly type = ActionType.REMOVE_IP_FROM_SERVER
	readonly mode = AsyncActionMode.RESPONSE
	constructor(public request: RemoveIpFromServerRequestAction, public data: ServerModel) {}
}
export class RemoveIpFromServerErrorAction {
	readonly type = ActionType.REMOVE_IP_FROM_SERVER
	readonly mode = AsyncActionMode.ERROR
	constructor(public request: RemoveIpFromServerRequestAction, public error: string) {}
}

export class AddIpToServerRequestAction {
	readonly type = ActionType.ADD_IP_TO_SERVER
	readonly mode = AsyncActionMode.REQUEST
	constructor(public payload: ServerAddRemoveIpParams) {}
}
export class AddIpToServerResponseAction {
	readonly type = ActionType.ADD_IP_TO_SERVER
	readonly mode = AsyncActionMode.RESPONSE
	constructor(public request: AddIpToServerRequestAction, public data: ServerModel) {}
}
export class AddIpToServerErrorAction {
	readonly type = ActionType.ADD_IP_TO_SERVER
	readonly mode = AsyncActionMode.ERROR
	constructor(public request: AddIpToServerRequestAction, public error: string) {}
}

export class CreateServerRequestAction {
	readonly type = ActionType.CREATE_SERVER
	readonly mode = AsyncActionMode.REQUEST
	constructor(public payload: CreateServerParams) {}
}
export class CreateServerResponseAction {
	readonly type = ActionType.CREATE_SERVER
	readonly mode = AsyncActionMode.RESPONSE
	constructor(public request: CreateServerRequestAction, public data: ServerModel) {}
}
export class CreateServerErrorAction {
	readonly type = ActionType.CREATE_SERVER
	readonly mode = AsyncActionMode.ERROR
	constructor(public request: CreateServerRequestAction, public error: string) {}
}

export class GetServerRequestAction {
	readonly type = ActionType.GET_SERVER
	readonly mode = AsyncActionMode.REQUEST
	constructor(public payload: number) {
		''
	}
}
export class GetServerResponseAction {
	readonly type = ActionType.GET_SERVER
	readonly mode = AsyncActionMode.RESPONSE
	constructor(public request: GetServerRequestAction, public data: ServerModel) {}
}
export class GetServerErrorAction {
	readonly type = ActionType.GET_SERVER
	readonly mode = AsyncActionMode.ERROR
	constructor(public request: GetServerRequestAction, public error: string) {}
}

export class GetHypervisorRequestAction {
	readonly type = ActionType.GET_HYPERVISOR
	readonly mode = AsyncActionMode.REQUEST
	constructor(public payload: number) {
		''
	}
}
export class GetHypervisorResponseAction {
	readonly type = ActionType.GET_HYPERVISOR
	readonly mode = AsyncActionMode.RESPONSE
	constructor(public request: GetHypervisorRequestAction, public data: ServerModel) {}
}
export class GetHypervisorErrorAction {
	readonly type = ActionType.GET_HYPERVISOR
	readonly mode = AsyncActionMode.ERROR
	constructor(public request: GetHypervisorRequestAction, public error: string) {}
}

export class GetIpRequestAction {
	readonly type = ActionType.GET_IP_SERVER
	readonly mode = AsyncActionMode.REQUEST
	constructor(public id: number) {
		''
	}
}
export class GetIpResponseAction {
	readonly type = ActionType.GET_IP_SERVER
	readonly mode = AsyncActionMode.RESPONSE
	constructor(public request: GetIpRequestAction, public data: IpAddressModel) {}
}
export class GetIpErrorAction {
	readonly type = ActionType.GET_IP_SERVER
	readonly mode = AsyncActionMode.ERROR
	constructor(public request: GetIpRequestAction, public error: string) {}
}
export class GetPhysServerRequestAction {
	readonly type = ActionType.GET_PHYS_SERVER
	readonly mode = AsyncActionMode.REQUEST
	constructor(public id: number) {
		''
	}
}
export class GetPhysServerResponseAction {
	readonly type = ActionType.GET_PHYS_SERVER
	readonly mode = AsyncActionMode.RESPONSE
	constructor(public request: GetPhysServerRequestAction, public data: PhysicalServerModel) {}
}
export class GetPhysServerErrorAction {
	readonly type = ActionType.GET_PHYS_SERVER
	readonly mode = AsyncActionMode.ERROR
	constructor(public request: GetPhysServerRequestAction, public error: string) {}
}

export class GetServersRequestAction {
	readonly type = ActionType.GET_SERVERS
	readonly mode = AsyncActionMode.REQUEST
	constructor() {
		''
	}
}
export class GetServersResponseAction {
	readonly type = ActionType.GET_SERVERS
	readonly mode = AsyncActionMode.RESPONSE
	constructor(public request: GetServersRequestAction, public data: ServerGrid) {}
}
export class GetServersErrorAction {
	readonly type = ActionType.GET_SERVERS
	readonly mode = AsyncActionMode.ERROR
	constructor(public request: GetServersRequestAction, public error: string) {}
}

export class ListIpRequestAction {
	readonly type = ActionType.GET_IP_LIST
	readonly mode = AsyncActionMode.REQUEST
	constructor() {
		''
	}
}
export class ListIpResponseAction {
	readonly type = ActionType.GET_IP_LIST
	readonly mode = AsyncActionMode.RESPONSE
	constructor(public request: ListIpRequestAction, public data: IpModel[]) {}
}
export class ListIpErrorAction {
	readonly type = ActionType.GET_IP_LIST
	readonly mode = AsyncActionMode.ERROR
	constructor(public request: ListIpRequestAction, public error: string) {}
}

export class ListPhysicalServersRequestAction {
	readonly type = ActionType.LIST_PHYSICAL_SERVERS
	readonly mode = AsyncActionMode.REQUEST
	constructor() {
		''
	}
}
export class ListPhysicalServersResponseAction {
	readonly type = ActionType.LIST_PHYSICAL_SERVERS
	readonly mode = AsyncActionMode.RESPONSE
	constructor(public request: ListPhysicalServersRequestAction, public data: NameItem[]) {}
}
export class ListPhysicalServersErrorAction {
	readonly type = ActionType.LIST_PHYSICAL_SERVERS
	readonly mode = AsyncActionMode.ERROR
	constructor(public request: ListPhysicalServersRequestAction, public error: string) {}
}
export class GetHypervisorsRequestAction {
	readonly type = ActionType.GET_HYPERVISORS
	readonly mode = AsyncActionMode.REQUEST
	constructor() {
		''
	}
}
export class GetHypervisorsResponseAction {
	readonly type = ActionType.GET_HYPERVISORS
	readonly mode = AsyncActionMode.RESPONSE
	constructor(public request: GetHypervisorsRequestAction, public data: ServerModel[]) {}
}
export class GetHypervisorsErrorAction {
	readonly type = ActionType.GET_HYPERVISORS
	readonly mode = AsyncActionMode.ERROR
	constructor(public request: GetHypervisorsRequestAction, public error: string) {}
}

export class UpdateServerRequestAction {
	readonly type = ActionType.UPDATE_SERVER
	readonly mode = AsyncActionMode.REQUEST
	constructor(public params: UpdateServerParams) {}
}
export class UpdateServerResponseAction {
	readonly type = ActionType.UPDATE_SERVER
	readonly mode = AsyncActionMode.RESPONSE
	constructor(public request: UpdateServerRequestAction, public data: ServerModel) {}
}
export class UpdateServerErrorAction {
	readonly type = ActionType.UPDATE_SERVER
	readonly mode = AsyncActionMode.ERROR
	constructor(public request: UpdateServerRequestAction, public error: string) {}
}

export class DeleteServerRequestAction {
	readonly type = ActionType.DELETE_SERVER
	readonly mode = AsyncActionMode.REQUEST
	constructor(public id: number) {
		''
	}
}
export class DeleteServerResponseAction {
	readonly type = ActionType.DELETE_SERVER
	readonly mode = AsyncActionMode.RESPONSE
	constructor(public request: DeleteServerRequestAction, public data: ServerModel) {}
}
export class DeleteServerErrorAction {
	readonly type = ActionType.DELETE_SERVER
	readonly mode = AsyncActionMode.ERROR
	constructor(public request: DeleteServerRequestAction, public error: string) {}
}

export class LookupServerRequestAction {
	readonly type = ActionType.LOOKUP_SERVER
	readonly mode = AsyncActionMode.REQUEST
	constructor(public field: string) {
		''
	}
}
export class LookupServerResponseAction {
	readonly type = ActionType.LOOKUP_SERVER
	readonly mode = AsyncActionMode.RESPONSE
	constructor(
		public request: LookupServerRequestAction,
		public field: string,
		public data: string[],
	) {}
}
export class LookupServerErrorAction {
	readonly type = ActionType.LOOKUP_SERVER
	readonly mode = AsyncActionMode.ERROR
	constructor(public request: LookupServerRequestAction, public error: string) {}
}

export class UpdateIpRequestAction {
	readonly type = ActionType.UPDATE_IP_COMMENT
	readonly mode = AsyncActionMode.REQUEST
	constructor(public payload: UpdateIpParams) {
		''
	}
}

export class UpdateIpResponseAction {
	readonly type = ActionType.UPDATE_IP_COMMENT
	readonly mode = AsyncActionMode.RESPONSE
	constructor(public request: UpdateIpRequestAction, public data:IpAddressModel) {}
}

export class UpdateIpErrorAction {
	readonly type = ActionType.UPDATE_IP_COMMENT
	readonly mode = AsyncActionMode.ERROR
	constructor(
		public request: UpdateIpRequestAction,
		public error: string,
	) {}
}

export class GetStatusPlanRequestAction {
	readonly type = ActionType.GET_STATUS_PLAN
	readonly mode = AsyncActionMode.REQUEST
	constructor() {
		''
	}
}
export class GetStatusPlanResponseAction {
	readonly type = ActionType.GET_STATUS_PLAN
	readonly mode = AsyncActionMode.RESPONSE
	constructor(public request: GetStatusPlanRequestAction, public data: StatusPlan[]) {}
}
export class GetStatusPlanErrorAction {
	readonly type = ActionType.GET_STATUS_PLAN
	readonly mode = AsyncActionMode.ERROR
	constructor(public request: GetStatusPlanRequestAction, public error: string) {}
}

export class CreateStatusPlanRequestAction {
	readonly type = ActionType.CREATE_STATUS_PLAN
	readonly mode = AsyncActionMode.REQUEST
	constructor(public payload: CreateStatusPlanParams) {
		''
	}
}

export class CreateStatusPlanResponseAction {
	readonly type = ActionType.CREATE_STATUS_PLAN
	readonly mode = AsyncActionMode.RESPONSE
	constructor(public request: CreateStatusPlanRequestAction, public data: StatusPlan) {}
}

export class CreateStatusPlanErrorAction {
	readonly type = ActionType.CREATE_STATUS_PLAN
	readonly mode = AsyncActionMode.ERROR
	constructor(
		public request: CreateStatusPlanRequestAction,
		public error: string,
	) {}
}
export class UpdateStatusPlanRequestAction {
	readonly type = ActionType.UPDATE_STATUS_PLAN
	readonly mode = AsyncActionMode.REQUEST
	constructor(public payload: StatusPlan) {
		''
	}
}

export class UpdateStatusPlanResponseAction {
	readonly type = ActionType.UPDATE_STATUS_PLAN
	readonly mode = AsyncActionMode.RESPONSE
	constructor(public request: UpdateStatusPlanRequestAction, public data: StatusPlan) {}
}

export class UpdateStatusPlanErrorAction {
	readonly type = ActionType.UPDATE_STATUS_PLAN
	readonly mode = AsyncActionMode.ERROR
	constructor(
		public request: UpdateStatusPlanRequestAction,
		public error: string,
	) {}
}
export class DeleteStatusPlanRequestAction {
	readonly type = ActionType.DELETE_STATUS_PLAN
	readonly mode = AsyncActionMode.REQUEST
	constructor(public id: number) {
		''
	}
}
export class DeleteStatusPlanResponseAction {
	readonly type = ActionType.DELETE_STATUS_PLAN
	readonly mode = AsyncActionMode.RESPONSE
	constructor(public request: DeleteStatusPlanRequestAction, public data: StatusPlan) {}
}
export class DeleteStatusPlanErrorAction {
	readonly type = ActionType.DELETE_STATUS_PLAN
	readonly mode = AsyncActionMode.ERROR
	constructor(public request: DeleteStatusPlanRequestAction, public error: string) {}
}