import { CreateVhostRequestAction, CreateVhostResponseAction, CreateVhostErrorAction } from './index'
import { protectedApiClient } from 'helpers/api'
import { handleApiErrorWithNotification } from 'helpers/errorHandling'
import { CreateVhostParams, VhostModel } from '../models'

export default (params: CreateVhostParams, cb?: (isSuccess: boolean) => void) => {
	return (dispatch: (arg: CreateVhostRequestAction | CreateVhostResponseAction | CreateVhostErrorAction) => void) => {
		const request = new CreateVhostRequestAction(params)
		dispatch(request)
		protectedApiClient
			.post<VhostModel>(`/domain/${params.domain_id}/vhost?expand=service,customer`, params)
			.then((response) => {
				dispatch(new CreateVhostResponseAction(request, response.data))
				cb && cb(true)
			})
			.catch((error) => {
				dispatch(new CreateVhostErrorAction(request, error.toString()))
				handleApiErrorWithNotification(error)
				cb && cb(false)
			})
	}
}
