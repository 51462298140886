import { GetMailDomainsRequestAction, GetMailDomainsResponseAction, GetMailDomainsErrorAction } from '.'
import { protectedApiClient } from 'helpers/api'
import { handleApiErrorWithNotification } from 'helpers/errorHandling'
import { MailDomainGrid, MailDomainModel } from '../models'


export default (pageSize: number, pageNumber: number, filter: string, cb?: (isSuccess: boolean) => void) => {
	return (
		dispatch: (arg: GetMailDomainsRequestAction | GetMailDomainsResponseAction | GetMailDomainsErrorAction) => void
	) => {
		const request = new GetMailDomainsRequestAction()
		dispatch(request)

		let url = `/mail/domain?expand=service,customer${filter}&pagination[pageSize]=${pageSize}&pagination[page]=${pageNumber}`
		protectedApiClient
			.get<MailDomainGrid>(url)
			.then((response) => {
				dispatch(new GetMailDomainsResponseAction(request, response.data))
				cb && cb(true)
			})
			.catch((error) => {
				dispatch(new GetMailDomainsErrorAction(request, error))
				handleApiErrorWithNotification(error)
				cb && cb(false)
			})
	}
}
