import {useTranslation} from "react-i18next";
import {useHistory} from "react-router";
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../../../common/models";
import React, {useEffect, useRef, useState} from "react";
import {useLoggedUser} from "../../../helpers/loginUserHelper";
import {LoadingIndicator} from "../../../components";
import ErrorPage403 from "../../../components/Errors/ErrorPage403";
import useLogger from "../../../common/useLogger";
import Draggable, {DraggableData, DraggableEvent} from "react-draggable";
import {AutoComplete, Badge, Card, Col, message, Modal, Row, Select, Space, Spin, Table, Tag} from "antd";
import {GlobalOutlined} from "@ant-design/icons/lib/icons";
import Button from "antd-button-color";
import {
    DeleteDnsZoneParams,
    DnsRecordModel,
    DnsZone,
} from "./models";
import {
    DeleteTwoTone,
    ExclamationCircleOutlined,
    WarningOutlined
} from "@ant-design/icons";
import {ColumnsType} from "antd/lib/table/interface";
import {sort_domain} from "../../../common/sorting";
import usePageSize from "../../../common/usePageSize";
import {Link, useParams} from "react-router-dom";
import {removeDiac, stopPropagation} from "../../../common/fce";
import DnsZoneForm from "./DnsZoneForm";
import DnsZoneExport from "./DnsZoneExport";
import SoaTtlForm from "./SoaTtlForm";
import DnsChecker from "./DnsChecker";
import getDnsZones from "./actions/getDnsZones";
import getDnsService from "./actions/getDnsService";
import {renderDriver, renderZoneState} from "./common";
import deleteDnsZone from "./actions/deleteDnsZone";

const { confirm } = Modal

interface ParamTypes {
    service_id: string
}

const ZonesPage = () => {
    const CONTROL_NAME = 'page_dns_zones'
    const {t} = useTranslation()
    const history = useHistory()
    const dispatch = useDispatch()
    const {service_id} = useParams<ParamTypes>()

    const {isLoading, dnsZones, isLoadingZone, dnsServices, dnsService} = useSelector((state: AppState) => state.dnsservice)

    const [dataSource, setDataSource] = useState<DnsZone[]>([])
    const [pageNumber, setPageNumber] = useState<number>(1)
    const [qsFilter, setQsFilter] = useState<string>('')
    const [state, setState] = useState('')

    const [selectedRecord, setSelectedRecord] = useState<DnsRecordModel>()

    const [searchDomain, setSearchDomain] = useState<string>('')
    const [searchCustomerId, setSearchCustomerId] = useState<number | undefined>(undefined)

    const [zone, setZone] = useState('')
    const [selectedDnsServiceId, setSelectedDnsServiceId] = useState(0)
    const [selectedZone, setSelectedZone] = useState<string>('')
    const [isZoneModalVisible, setZoneModalVisible] = useState<boolean>(false)
    const [isZoneExportModalVisible, setZoneExportModalVisible] = useState<boolean>(false)
    const [isSoaModalVisible, setSoaModalVisible] = useState<boolean>(false)
    const [isCheckerModalVisible, setCheckerModalVisible] = useState(false)
    const [openDelete, setOpenDelete] = useState(false)
    const [confirmDelete, setConfirmDelete] = useState(false)

    const stateOptions: any[] = [
        {label: t(`dnsPage.NOT_AUTORITATIVE`), value: 'NOT_AUTORITATIVE'},
        {label: t(`dnsPage.IS_AUTORITATIVE`), value: 'IS_AUTORITATIVE'},
        {label: t(`dnsPage.NOT_FOUND`), value: 'NOT_FOUND'},
    ];

    const [isZoneViewer, setZoneViewer] = useState(false)
    const [isZoneCreator, setZoneCreator] = useState(false)
    const [isZoneEditor, setZoneEditor] = useState(false)
    const [isZoneDeleter, setZoneDeleter] = useState(false)


    // get settings and current user
    const loggedUser = useLoggedUser()
    if (!loggedUser || !loggedUser.isLoaded()) {
        // waiting..
        return (
            <div className="fullwidth-loading" >
                <LoadingIndicator/>
            </div>
        )
    }

    // required authorization
    if (!loggedUser.hasAccess(CONTROL_NAME)) {
        return <ErrorPage403 />
    }

    // settings
    const appSetting = loggedUser.getAppSettings()
    const SEARCH_MIN = appSetting.min_search_length

    const logger = useLogger(appSetting, 'ZonesPage')
    const [pageSize, setPageSize] = useState<number>(appSetting.grid_page_size)
    usePageSize(appSetting, loggedUser.user.id, pageSize)

    // history drag modal
    const [disabled, setDisabled] = useState(true)
    const draggleRef = useRef<HTMLDivElement>(null)
    const [bounds, setBounds] = useState({ left: 0, top: 0, bottom: 0, right: 0 })
    const onStart = (_event: DraggableEvent, uiData: DraggableData) => {
        const { clientWidth, clientHeight } = window.document.documentElement;
        const targetRect = draggleRef.current?.getBoundingClientRect();
        if (!targetRect) {
            return;
        }
        setBounds({
            left: -targetRect.left + uiData.x,
            right: clientWidth - (targetRect.right - uiData.x),
            top: -targetRect.top + uiData.y,
            bottom: clientHeight - (targetRect.bottom - uiData.y),
        });
    }

    useEffect(() => {
        // trigger ONCE
        const access = loggedUser.hasAccess('page_dns_zones')
        setZoneViewer(access)
        setZoneCreator(loggedUser.hasAccess('page_dns_zones_create_button'))
        setZoneEditor(loggedUser.hasAccess('page_dns_zones_edit_button'))
        setZoneDeleter(loggedUser.hasAccess('page_dns_zones_delete_button'))
        if (!access) {
            // failover 403
            history.replace('/')
        }

        if (parseInt(service_id) > 0) {
            const sid = parseInt(service_id)
            dispatch(getDnsService(sid))
            dispatch(getDnsZones(sid, (suc) => {
                if (!suc) {
                    history.replace('/dns/services')
                    message.error(t('general.error'))
                }
            }))
        }
        else {
            // failover 404
            history.replace('/dns/services')
        }

    }, [])

    useEffect(() => {
        // update datasource when data was changed
        refreshGrid()
    }, [dnsZones, zone, state])

    useEffect(() => {
        // update QsFilter
        const qs: string = prepareQsFilter()
        logger(`qsFilter: ${qs}`)
        if (qs != qsFilter) {
            setQsFilter(qs)
        }
    }, [searchCustomerId, searchDomain])

    const refreshGrid = () => {
        setDataSource(filtered())
    }

    const filtered = () => {
        // search for pattern
        if (!dnsZones) {
            return []
        }
        let data = dnsZones
        logger('filtered dnsZones: '+data.length)
        if (zone && zone.length > SEARCH_MIN) {
            data = data.filter((z) => z.domain && removeDiac(z.domain).includes(removeDiac(zone)))
        }
        if (state) {
            data = data.filter((z) => z.domain && z.state.includes(state))
        }
        return data.sort(sort_domain)
    }

    const getQSFilter = (): string[] => {
        let qs: string[] = []
        if (searchCustomerId && searchCustomerId > 0) {
            qs.push('customer_id=' + searchCustomerId)
        }
        return qs
    }

    const prepareQsFilter = (): string => {
        // load filtered data from server
        let qs: string[]
        qs = getQSFilter()
        if (appSetting.checkMinSearch(searchDomain)) {
            qs.push('name=' + encodeURIComponent(searchDomain))
        }
        if (searchCustomerId && searchCustomerId > 0) {
            qs.push('customer_id=' + searchCustomerId)
        }
        logger('prepareQsFilter: '+qs.join("&"))
        return qs.join("&")
    }

    const showConfirmDelete = (z: DnsZone, title: string) => {
        if (!z || !dnsService) {
            return
        }

        confirm({
                icon: <ExclamationCircleOutlined style={{color: 'red'}} />,
                title: title,
                content: <p>{z.domain}</p>,
                okText: t('general.yes'),
                cancelText: t('general.cancel'),
                okButtonProps: { loading: confirmDelete },
                className: 'confirm-alert',
                onOk() {
                    setConfirmDelete(true)
                    const params: DeleteDnsZoneParams = {
                        id: dnsService.id,
                        zone: z.domain
                    }
                    dispatch(deleteDnsZone(params, suc => {
                        setConfirmDelete(false)
                        setOpenDelete(false)
                        suc && message.success(t('general.success'))
                    }))
                },
                onCancel() {
                    setOpenDelete(false)
                },
            }
        )
    }

    const FilterByZone = (
        <AutoComplete
            placeholder={t('zonesPage.zone')}
            style={{ width: '140px' }}
            value={zone}
            allowClear={true}
            onClick={stopPropagation}
            onKeyDown={(e) => {if (e.key === 'Enter') {e.stopPropagation()}}}
            onChange={(v) => { setZone(v) }}
        />
    )

    const FilterByState = (
        <Select
            placeholder={t('zonesPage.state')}
            options={stateOptions}
            value={state}
            style={{ width: '90%', marginRight: "1rem" }}
            allowClear
            onClick={stopPropagation}
            onChange={(v : string) => setState(v)}
        />
    )

    const columns: ColumnsType<DnsZone> = [
        {
            title: FilterByZone,
            key: 'domain',
            dataIndex: 'domain',
            width: '40%',
            fixed: 'left',
            ellipsis: {showTitle: false},
            render: (text) => {
                    return <Link to={`/dns/${dnsService?.id}/zones/${text}/records`}>{text}</Link>
            }
        },
        {
            title: t('zonesPage.dnssec'),
            key: 'dnssec',
            dataIndex: 'dnssec',
            align: 'center',
            width: 120,
            render: (text, rec: DnsZone) => {
                if (rec.dnssec) {
                    return <Badge status='success' />
                } else {
                    return <Badge status='error' />
                }
            }
        },
        {
            title: t('zonesPage.type'),
            key: 'type',
            dataIndex: 'type',
            align: 'center',
            width: 120,
        },
        {
            title: FilterByState,
            key: 'state',
            dataIndex: 'state',
            render: (text, rec: DnsZone) => { return renderZoneState(rec, t) }
        },
        {
            title: t('billing.servicetype.action'),
            key: 'action',
            width: 60,
            align: 'center',
            dataIndex: 'action',
            render: (text: string, rec: DnsZone) => (
                <Space size='small'>
                    <Button type='text' danger size='small'
                            disabled={!isZoneDeleter}
                            className='actionButton'
                            onClick={() => showConfirmDelete(rec, t('zonesPage.confirm_delete'))}
                            icon={<DeleteTwoTone twoToneColor={isZoneDeleter ? "red" : "#ccc"} />}
                    />
                </Space>
            ),
        },
    ]

    if (!appSetting || Object.keys(appSetting).length === 0) {
        return (<Spin/>)
    }

    return (
        <>
            <Card
                className='DnsPage'
                title={
                    <>
                        <Row>
                            <Col span={6}>&nbsp; <GlobalOutlined/> &nbsp;{t('zonesPage.title')}
                            </Col>

                            <Col span={6}>
                                {t('zonesPage.server')}: <b>{dnsService?.host}</b> {dnsService && renderDriver(dnsService?.driver_id)}
                            </Col>
                            <Col span={12}>

                            </Col>
                        </Row>
                    </>
                }
            >

                <Table<DnsZone>
                    rowClassName={() => 'highlight'}
                    bordered={true}
                    columns={columns}
                    loading={isLoading}
                    dataSource={dataSource}
                    rowKey={(record) => `${record.domain}`}
                    showHeader={true}
                    pagination={false}
                    onChange={(ev) => {
                        ev.pageSize && setPageSize(ev.pageSize)
                    }}
                />

            </Card>

            <Modal title={
                       <div style={{width: '100%', cursor: 'move'}}
                            onMouseOver={() => {if (disabled) { setDisabled(false)}}}
                            onMouseOut={() => {setDisabled(true)}}
                            onFocus={() => {}}
                            onBlur={() => {}}
                       >
                           <GlobalOutlined/> &nbsp;{t('dnsPage.create_zone_title')}
                       </div>
                   }
                   destroyOnClose
                   style={{top: 20}}
                   width={800}
                   visible={isZoneModalVisible}
                   onCancel={() => setZoneModalVisible(false)}
                   modalRender={(modal) => (
                       <Draggable disabled={disabled} bounds={bounds} onStart={(ev, data) => onStart(ev, data)}>
                           <div ref={draggleRef}>{modal}</div>
                       </Draggable>
                   )}
                   footer={null}
                   confirmLoading={true}
            >
                <DnsZoneForm serviceId={selectedDnsServiceId}
                             zone={selectedZone}
                             closeModal={() => {setZoneModalVisible(false)}}
                             setZoneModalVisible={setZoneModalVisible}/>
            </Modal>


            <Modal title={
                       <div style={{width: '100%', cursor: 'move'}}
                            onMouseOver={() => {if (disabled) { setDisabled(false)}}}
                            onMouseOut={() => {setDisabled(true)}}
                            onFocus={() => {}}
                            onBlur={() => {}}
                       >
                           <GlobalOutlined/> &nbsp;{t('dnsPage.zone_file')}
                       </div>
                   }
                   destroyOnClose
                   style={{top: 20}}
                   width={800}
                   visible={isZoneExportModalVisible}
                   onCancel={() => setZoneExportModalVisible(false)}
                   modalRender={(modal) => (
                       <Draggable disabled={disabled} bounds={bounds} onStart={(ev, data) => onStart(ev, data)}>
                           <div ref={draggleRef}>{modal}</div>
                       </Draggable>
                   )}
                   footer={null}
                   confirmLoading={true}
            >
                <DnsZoneExport serviceId={selectedDnsServiceId} zone={selectedZone}  />
            </Modal>

            <Modal title={
                       <div style={{width: '100%', cursor: 'move'}}
                           onMouseOver={() => {if (disabled) { setDisabled(false)}}}
                           onMouseOut={() => {setDisabled(true)}}
                           onFocus={() => {}}
                           onBlur={() => {}}
                       >
                           <GlobalOutlined/> &nbsp;{t('dnsPage.record')}&nbsp;{t('dnsPage.for_domain')}: <Tag color='blue' style={{fontSize: '1em'}}><b>{selectedRecord?.zone}</b></Tag>
                       </div>
                   }
                   destroyOnClose
                   style={{top: 20}}
                   width={600}
                   visible={isSoaModalVisible}
                   onCancel={() => setSoaModalVisible(false)}
                   maskClosable={false}
                   modalRender={(modal) => (
                       <Draggable disabled={disabled} bounds={bounds} onStart={(ev, data) => onStart(ev, data)}>
                           <div ref={draggleRef}>{modal}</div>
                       </Draggable>
                   )}
                   footer={null}
                   confirmLoading={true}
            >
                <SoaTtlForm record={selectedRecord} serviceId={selectedDnsServiceId} closeModal={() => {setSoaModalVisible(false)}}/>
            </Modal>

            <Modal title={
                <div style={{width: '100%', cursor: 'move'}}
                     onMouseOver={() => {if (disabled) { setDisabled(false)}}}
                     onMouseOut={() => {setDisabled(true)}}
                     onFocus={() => {}}
                     onBlur={() => {}}
                >
                    <><WarningOutlined />&nbsp;{t('dnsPage.check_zones')}..&nbsp; {dnsServices.find(s => s.id===selectedDnsServiceId)?.host}</>
                </div>
            }
                   destroyOnClose
                   style={{top: 50}}
                   bodyStyle={{minHeight: 450, height: 600, padding: 2}}
                   width='60%'
                   className='checkerModal'
                   visible={isCheckerModalVisible}
                   onCancel={() => setCheckerModalVisible(false)}
                   maskClosable={false}
                   modalRender={(modal) => (
                       <Draggable disabled={disabled} bounds={bounds} onStart={(ev, data) => onStart(ev, data)}>
                           <div ref={draggleRef}>{modal}</div>
                       </Draggable>
                   )}
                   footer={null}>
                <DnsChecker serviceId={selectedDnsServiceId} />
            </Modal>
        </>
    )
}

export default ZonesPage