import { GetDaemonRequestAction, GetDaemonResponseAction, GetDaemonErrorAction } from '.'
import { protectedApiClient } from 'helpers/api'
import {handleApiError, handleApiErrorWithNotification} from 'helpers/errorHandling'
import {DaemonVersionResp} from "../models";


export default (id: number, cb?: (isSuccess: boolean) => void) => {
    return (
        dispatch: (
            arg: GetDaemonRequestAction | GetDaemonResponseAction | GetDaemonErrorAction,
        ) => void,
    ) => {
        const request = new GetDaemonRequestAction(id)
        dispatch(request)

        protectedApiClient
            .get<DaemonVersionResp>(`/rdb/service/${id}/deamon-version`)
            .then((response) => {
                dispatch(new GetDaemonResponseAction(request, response.data))
                cb && cb(true)
            })
            .catch((error) => {
                let msg = error.toString()
                if (parseInt(error.response.status) === 502) {
                    msg = "ERROR HTTP 502"
                }
                dispatch(new GetDaemonErrorAction(request, msg))
                handleApiError(error)
                cb && cb(false)
            })
    }
}
