import { GetDatabasesRequestAction, GetDatabasesResponseAction, GetDatabasesErrorAction } from '.'
import { protectedApiClient } from 'helpers/api'
import { handleApiErrorWithNotification } from 'helpers/errorHandling'
import { DatabaseGrid, DatabaseModel } from '../models'

export default (pageSize: number, pageNumber: number, filter: string, cb?: (isSuccess: boolean) => void) => {
	return (
		dispatch: (arg: GetDatabasesRequestAction | GetDatabasesResponseAction | GetDatabasesErrorAction) => void
	) => {
		const request = new GetDatabasesRequestAction()
		dispatch(request)

		let url = `/rdb/database?expand=service${filter}&pagination[pageSize]=${pageSize}&pagination[page]=${pageNumber}`
		protectedApiClient
			.get<DatabaseGrid>(url)
			.then((response) => {
				dispatch(new GetDatabasesResponseAction(request, response.data))
				cb && cb(true)
			})
			.catch((error) => {
				dispatch(new GetDatabasesErrorAction(request, error))
				handleApiErrorWithNotification(error)
				cb && cb(false)
			})
	}
}
