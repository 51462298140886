import { DeleteTrashRequestAction, DeleteTrashResponseAction, DeleteTrashErrorAction } from '.'
import { protectedApiClient } from 'helpers/api'
import { handleApiErrorWithNotification } from 'helpers/errorHandling'

export default (id: number, cb?: (isSuccess: boolean) => void) => {
	return (
		dispatch: (
			arg: DeleteTrashRequestAction | DeleteTrashResponseAction | DeleteTrashErrorAction
		) => void
	) => {
		const request = new DeleteTrashRequestAction(id)
		dispatch(request)

		protectedApiClient
			.delete(`/mail/domain/${id}/trash`)
			.then((response) => {
				dispatch(new DeleteTrashResponseAction(request))
				cb && cb(true)
			})
			.catch((error) => {
				dispatch(new DeleteTrashErrorAction(request, error))
				handleApiErrorWithNotification(error)
				cb && cb(false)
			})
	}
}
