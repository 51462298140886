import React, {useEffect, useState} from 'react'
import {Button, Form, Input, InputNumber, Switch, Select, Collapse, Space, Row, Col, message, Spin} from 'antd'
import {useTranslation} from 'react-i18next'
import {useForm} from 'antd/lib/form/Form'
import {formItemLayout, tailLayout} from 'helpers/layoutHelpers'
import {UpdateCertificateParams, CHALLENGE_ENUM} from 'pages/certificate/models'
import {CheckOutlined, CloseOutlined} from '@ant-design/icons'
import {_isValidDomain, ValidateParams} from "../../common/fce";
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../../common/models";
import {Store} from "antd/lib/form/interface";
import updateCertificate from "./actions/updateCertificate";


const {Item} = Form
const {TextArea} = Input

interface Props {
	closeModal: () => void
}

export const CertificateEditForm = ({closeModal}: Props) => {
	const [form] = useForm()
	const {t} = useTranslation()
	const dispatch = useDispatch()

	const {certificate, isLoading, isSaving} = useSelector((state: AppState) => state.certificate)

	const [domainField, setDomainField] = useState<ValidateParams>({value: '', validateStatus: 'success', errorMsg: ''})
	const [autoProlong, setAutoProlong] = useState<boolean>(false)


	useEffect(() => {
		// trigger from Side menu clicked
		if (certificate) {
			setAutoProlong(certificate.auto_prolong === 1)
		}
	}, [certificate])


	const handleSubmit = (values: Store): void => {
		if (!certificate) {
			message.error('Certificate unknown.')
			return
		}

		const params: UpdateCertificateParams = {
			id: certificate.id,
			challenge: values.challenge,
			auto_prolong: autoProlong ? 1 : 0,
			prolong_before_days: values.prolong_before_days ? values.prolong_before_days : 20,
			comment: values.comment
		}
		console.log(params)
		dispatch(updateCertificate(params, suc => {
			if (suc) {
				message.success(t('general.success'))
				closeModal()
			}
		}))
	}

	if (isLoading) {
		return <Spin />
	}

	return (
		<Form {...formItemLayout} form={form}
			  onFinish={handleSubmit}
			  initialValues={{...certificate}}>

			<Item
				name='subjects' label={t('certificatesPage.subjects')}
				validateStatus={domainField.validateStatus}
				hasFeedback>
				<TextArea disabled={true}/>
			</Item>

			<Item name='checksum' label='Hash'
				  validateStatus={domainField.validateStatus} hasFeedback>
				<Input disabled={true}/>
			</Item>

			<Item name='challenge' label={t('certificatesPage.challenge')}
				  rules={[{required: true, message: t('certificatesPage.error.challenge')}]}>
				<Select options={Object.values(CHALLENGE_ENUM).map((val) => ({label: val, value: val}))} />
			</Item>

			<Item name='auto_prolong' valuePropName='checked' label={t('certificatesPage.auto_prolong')}>
				<Switch
					checkedChildren={<CheckOutlined />}
					unCheckedChildren={<CloseOutlined />}
					checked={autoProlong}
					onChange={() => { setAutoProlong(!autoProlong) }}
				/>
			</Item>

			{
				autoProlong && (
					<Item name='prolong_before_days' label={t('certificatesPage.prolong_before_days')}>
						<InputNumber style={{width: 60}} />
					</Item>
				)
			}

			<Item name='comment' label={t('certificatesPage.comment')}>
				<TextArea cols={5}
						  data-gramm="false"
						  data-gramm_editor="false"
						  data-enable-grammarly="false"
						  style={{minHeight: '100px'}} />
			</Item>

			<Item {...tailLayout}>
				<Button type='primary' htmlType='submit' loading={isSaving}>
					{t('general.save')}
				</Button>
			</Item>
		</Form>
	)
}

export default CertificateEditForm


