import {protectedApiClient} from "../../../helpers/api";
import {handleApiErrorWithNotification} from "../../../helpers/errorHandling";
import {LookupCertificateErrorAction, LookupCertificateRequestAction, LookupCertificateResponseAction} from "./index";



export default (field: string, qs: string, cb?: (isSuccess: boolean) => void) => {
    return (
        dispatch: (
            arg: LookupCertificateRequestAction | LookupCertificateResponseAction | LookupCertificateErrorAction,
        ) => void,
    ) => {
        const request = new LookupCertificateRequestAction(field)
        dispatch(request)
        let url = `/certificate/certificate/lookup?`+qs
        protectedApiClient
            .get<string[]>(url)
            .then((response) => {
                dispatch(new LookupCertificateResponseAction(request, field, response.data))
                cb && cb(true)
            })
            .catch((error) => {
                dispatch(new LookupCertificateErrorAction(request, error))
                handleApiErrorWithNotification(error)
                cb && cb(false)
            })
    }
}
