import { GetUserRequestAction, GetUserResponseAction, GetUserErrorAction } from '.'
import { protectedApiClient } from 'helpers/api'
import { handleApiErrorWithNotification } from 'helpers/errorHandling'
import { UserDetails } from 'pages/user/models'

export default (id: number, cb?: (isSuccess: boolean) => void) => {
	return (dispatch: (arg: GetUserRequestAction | GetUserResponseAction | GetUserErrorAction) => void) => {
		const request = new GetUserRequestAction()
		dispatch(request)

		protectedApiClient
			.get<UserDetails>(
				`/user/${id}?expand=customer,roles,groups,userAuthentications,authentications,userVerifications,auth_2fa_ips,auth_2fa_authentications`
			)
			.then((response) => {
				dispatch(new GetUserResponseAction(request, response.data))
				cb && cb(true)
			})
			.catch((error) => {
				dispatch(new GetUserErrorAction(request, error))
				handleApiErrorWithNotification(error)
				cb && cb(false)
			})
	}
}
