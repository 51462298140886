import { useTranslation } from "react-i18next"
import { useHistory } from "react-router"
import { useDispatch, useSelector } from "react-redux"
import { AppState } from "../../common/models"
import React, { useEffect, useRef, useState } from "react"
import { useLoggedUser } from "../../helpers/loginUserHelper"
import { LoadingIndicator } from "../../components"
import ErrorPage403 from "../../components/Errors/ErrorPage403"
import useLogger from "../../common/useLogger"
import Draggable, { DraggableData, DraggableEvent } from "react-draggable"
import { removeDiac, stopPropagation } from "../../common/fce"
import { DatabaseModel } from "./models"
import { ColumnsType } from "antd/lib/table"
import { AutoComplete, Card, message, Modal, Space, Spin, Table, Tag } from "antd"
import Button from "antd-button-color"
import {
    ConsoleSqlOutlined,
    InfoCircleTwoTone,
} from "@ant-design/icons"
import { GlobalOutlined, PlusCircleOutlined } from "@ant-design/icons/lib/icons"
import usePageSize from "../../common/usePageSize"
import Pager from "../../components/pagination/pager"
import getDatabases from "./actions/getDatabases"
import { Link } from "react-router-dom"
import lookupDatabase from "./actions/lookupDatabase"
import lookupRdbService from "./actions/lookupRdbService"
import CreateRdbForm from "./CreateRdbForm"
import "./Databases.scss"
import HistoryModal from "../../components/History/HistoryModal"
import CreateDatabaseForm from "./CreateDatabaseForm"

const { confirm } = Modal

const renderDriver = (name: string) => {
    if (!name) {
        return ''
    }
    if (name.toLowerCase() === 'percona8') {
        return (<Tag color="geekblue">{name}</Tag>)
    }
    else {
        return (<Tag color="orange">{name}</Tag>)
    }
}


const DatabasesPage = () => {
    const CONTROL_NAME = 'page_rdb_databases'
    const { t } = useTranslation()
    const history = useHistory()
    const dispatch = useDispatch()

    const { customers, customerNames } = useSelector((state: AppState) => state.auth.tables)
    const { isLoadingService, service, databases, drivers, pager, db_pager,
        lookup_database, lookup_host, isLoadingLookup } = useSelector((state: AppState) => state.rdbservice)
    const { rdb_services } = useSelector((state: AppState) => state.sidenav)

    const [dataSource, setDataSource] = useState<DatabaseModel[]>([])
    const [sourceFull, setSourceFull] = useState<DatabaseModel[]>([])

    const [searchName, setSearchName] = useState<string>('')
    const [searchNameLookup, setSearchNameLookup] = useState<string>('')
    const [searchHost, setSearchHost] = useState<string>('')
    const [searchHostLookup, setSearchHostLookup] = useState<string>('')
    const [searchCustomerId, setSearchCustomerId] = useState<number | undefined>(undefined)
    const [selectedCustomerId, setSelectedCustomerId] = useState<number | undefined>(undefined)
    const [searchCustomer, setSearchCustomer] = useState<string>('')
    const [selectedServiceId, setSelectedServiceId] = useState<number | undefined>(undefined)

    const [customerOptions, setCustomerOptions] = useState<{ label: string, value: number }[]>([])
    const [nameOptions, setNameOptions] = useState<{ label: string, value: string }[]>([])
    const [hostOptions, setHostOptions] = useState<{ label: string, value: string }[]>([])

    const [pageNumber, setPageNumber] = useState<number>(1)
    const [qsFilter, setQsFilter] = useState<string>('')
    const [loaded, setLoaded] = useState(0)         // when records are loaded
    const [ready, setReady] = useState<number>(0)   // when customers are loaded

    const [isDbServiceModalVisible, setDbServiceModalVisible] = useState<boolean>(false)
    const [isDatabaseModalVisible, setDatabaseModalVisible] = useState<boolean>(false)

    const [isHistoryModalVisible, setHistoryModalVisible] = useState(false)
    const [historyTitle, setHistoryTitle] = useState('')
    const [historyModelId, setHistoryModelId] = useState<number | undefined>()

    const [isDbServiceViewer, setDbServiceViewer] = useState(false)
    const [isDbServiceCreator, setDbServiceCreator] = useState(false)
    const [isDbServiceEditor, setDbServiceEditor] = useState(false)
    const [isDbServiceDeleter, setDbServiceDeleter] = useState(false)
    const [isDbCreator, setDbCreator] = useState(false)

    // get settings and current user
    const loggedUser = useLoggedUser()
    if (!loggedUser || !loggedUser.isLoaded()) {
        // waiting..
        return (
            <div className="fullwidth-loading" >
                <LoadingIndicator />
            </div>
        )
    }

    // required authorization
    if (!loggedUser.hasAccess(CONTROL_NAME)) {
        return <ErrorPage403 />
    }

    // settings
    const appSetting = loggedUser.getAppSettings()
    const SEARCH_MIN = appSetting.min_search_length

    // usage: logger(msg, obj=null)
    const logger = useLogger(appSetting, 'DatabasesPage')
    const [pageSize, setPageSize] = useState<number>(appSetting.grid_page_size)
    usePageSize(appSetting, loggedUser.user.id, pageSize)

    // history drag modal
    const [disabled, setDisabled] = useState(true)
    const draggleRef = useRef<HTMLDivElement>(null)
    const [bounds, setBounds] = useState({ left: 0, top: 0, bottom: 0, right: 0 })
    const onStart = (_event: DraggableEvent, uiData: DraggableData) => {
        const { clientWidth, clientHeight } = window.document.documentElement
        const targetRect = draggleRef.current?.getBoundingClientRect()
        if (!targetRect) {
            return
        }
        setBounds({
            left: -targetRect.left + uiData.x,
            right: clientWidth - (targetRect.right - uiData.x),
            top: -targetRect.top + uiData.y,
            bottom: clientHeight - (targetRect.bottom - uiData.y),
        })
    }

    useEffect(() => {
        // trigger ONCE
        const access = loggedUser.hasAccess('page_rdb_databases')
        setDbServiceViewer(access)
        setDbServiceCreator(loggedUser.hasAccess('page_rdb_service_create_button'))
        setDbServiceEditor(loggedUser.hasAccess('page_rdb_service_edit_button'))
        setDbServiceDeleter(loggedUser.hasAccess('page_rdb_service_delete_button'))
        setDbCreator(loggedUser.hasAccess('page_rdb_databases_create_button'))
    }, [])

    useEffect(() => {
        // trigger from Side menu clicked
        logger('rdb_services---')
        if (!isLoadingService && isDbServiceViewer) {
            // reload
            fetchRecords(1, pageSize)
        }
    }, [rdb_services])

    useEffect(() => {
        // populate nameOptions
        logger('lookup_database changed: ' + JSON.stringify(lookup_database))
        setNameOptions(lookup_database.map(s => ({ label: s, value: s })))
    }, [lookup_database])

    useEffect(() => {
        // populate hostOptions
        logger('lookup_host changed: ' + JSON.stringify(lookup_host))
        setHostOptions(lookup_host.map(s => ({ label: s, value: s })))
    }, [lookup_host])


    useEffect(() => {
        if (customers) {
            setReady(ready + 1)
            setCustomerOptions(
                customers.map(item => ({
                    value: item.id,
                    label: item.company?.name!
                }))
            )
        }
    }, [customers])

    useEffect(() => {
        // when filter is changed
        // show the first page
        logger(`qsFilter changed: page: ${pageNumber}, pageSize: ${pageSize}, qs=${qsFilter}`)
        let pn = pageNumber
        if (ready) {    // do not change page for F5
            pn = 1
        }
        fetchRecords(pn, pageSize)
    }, [qsFilter])

    useEffect(() => {
        if (databases.items) {
            setSourceFull(databases.items.map(s => {
                let name = customers.find(c => c.id === s.customer_id)?.company!.name
                return {
                    ...s,
                    customer_name: name,
                    searchableName: removeDiac(s.name),
                }
            }))
        }
    }, [databases.items])

    useEffect(() => {
        // update QsFilter will trigger fetchRecords
        const qs: string = prepareQsFilter()
        logger(`qsFilter:${qs}`)
        if (qs.trim() != qsFilter.trim()) {
            setQsFilter(qs)
        }
    }, [searchName, searchHost, searchCustomerId, selectedCustomerId])

    useEffect(() => {
        // Filters
        if (filterIsValid()) {
            refreshGrid()
        }
    }, [sourceFull])

    const refreshGrid = () => {
        setDataSource(sourceFull)
    }

    const fetchRecords = (pn: number, ps: number) => {
        setPageNumber(pn)
        setPageSize(ps)
        if (!isLoadingService && filterIsValid()) {
            // purchase_from is required
            logger(`fetchRecords: page: ${pn}, pageSize: ${ps}, qs=${qsFilter}`)
            let f = ''
            if (qsFilter) {
                f = '&' + qsFilter
            }
            dispatch(getDatabases(ps, pn - 1, f, suc => { }))
            setLoaded(1)
        }
    }

    const filterIsValid = (): boolean => {
        // no rules
        return true
    }


    const getQSFilter = (): string[] => {
        let qs: string[] = []
        return qs
    }

    const prepareQsFilter = (): string => {
        // load filtered data from server
        let qs: string[]
        qs = getQSFilter()
        if (searchName && appSetting.checkMinSearch(searchName)) {
            qs.push('name=' + encodeURIComponent(searchName))
        }
        if (searchHost && appSetting.checkMinSearch(searchHost)) {
            qs.push('service[host]=' + encodeURIComponent(searchHost))
        }
        if (searchCustomerId && searchCustomerId > 0) {
            qs.push('customer_id=' + searchCustomerId)
        }
        logger('prepareQsFilter: ' + qs.join("&"))
        return (qs.length === 0) ? '' : qs.join("&")
    }

    const handleCreate = () => {
        setDbServiceModalVisible(true)
    }

    const onClearCustomer = () => {
        setSearchCustomer('')
        setSearchCustomerId(undefined)
    }

    const onSelectCustomer = (v: string) => {
        const cid = parseInt(v)
        if (cid > 0) {
            logger('onSelectCustomer.. ' + v)
            setSearchCustomerId(cid)
            const name = customerNames!.get(cid)
            name && setSearchCustomer(name)
        }
        else {
            setSearchCustomerId(undefined)
            v && setSearchCustomer(v)
        }
    }

    const onChangeCustomerLookup = (data: string) => {
        if (!data) {
            if (searchCustomer.length === 1) {
                setSearchCustomer('')
            }
            return
        }
        if (data != searchCustomer) {
            setSearchCustomer(data)
        }
    }

    const fetchNameLookup = (searchText: string) => {
        // call lookup for name
        searchText = searchText.trim()
        if (appSetting.checkMinSearch(searchText.trim())) {
            let qs: string[]
            qs = getQSFilter()
            if (appSetting.checkMinSearch(searchText)) {
                qs.push('field=name')
                qs.push('value=' + encodeURIComponent(searchText.trim()))
                logger('lookupDatabase: ' + qs.join("&"))
                dispatch(lookupDatabase('name', qs.join("&")))
            }
        }
    }

    const onClearName = () => {
        setSearchNameLookup('')
        setSearchName('')
        setNameOptions([])
    }

    const onSelectName = (data: string) => {
        setSearchName(data)
        setPageNumber(1)
    }

    const onChangeNameLookup = (data: string) => {
        if (!data) {
            if (searchNameLookup.length === 1) {
                setSearchNameLookup('')
                fetchNameLookup('')
            }
            return
        }
        if (data != searchNameLookup) {
            setSearchNameLookup(data)
            fetchNameLookup(data)
        }
    }

    const fetchHostLookup = (searchText: string) => {
        // call lookup for db host
        searchText = searchText.trim()
        if (appSetting.checkMinSearch(searchText.trim())) {
            let qs: string[]
            qs = getQSFilter()
            if (appSetting.checkMinSearch(searchText)) {
                qs.push('field=host')
                qs.push('value=' + encodeURIComponent(searchText.trim()))
                logger('fetchHostLookup: ' + qs.join("&"))
                dispatch(lookupRdbService('host', qs.join("&")))
            }
        }
    }

    const onClearHost = () => {
        setSearchHostLookup('')
        setSearchHost('')
        setHostOptions([])
    }

    const onSelectHost = (data: string) => {
        setSearchHost(data)
        setPageNumber(1)
    }

    const onChangeHostLookup = (data: string) => {
        if (!data) {
            if (searchHostLookup.length === 1) {
                setSearchHostLookup('')
                fetchHostLookup('')
            }
            return
        }
        if (data != searchHostLookup) {
            setSearchHostLookup(data)
            fetchHostLookup(data)
        }
    }

    const FilterByName = (
        <AutoComplete
            showSearch
            placeholder={t('databasesPage.database')}
            style={{ width: '200px' }}
            value={searchNameLookup}
            options={nameOptions}
            dropdownMatchSelectWidth={200}
            onInputKeyDown={(e) => {
                if (e.key === 'Enter') {
                    onSelectName(e.currentTarget.value)
                }
            }}
            onSelect={onSelectName}
            onChange={onChangeNameLookup}
            onClear={onClearName}
            onClick={stopPropagation}
            notFoundContent={isLoadingLookup && <Spin />}
            optionFilterProp='label'
            filterOption={false}
            allowClear={true}
        />
    )

    const FilterByHost = (
        <AutoComplete
            showSearch
            placeholder='DB Server'
            style={{ width: '200px' }}
            value={searchHostLookup}
            options={hostOptions}
            dropdownMatchSelectWidth={200}
            onInputKeyDown={(e) => {
                if (e.key === 'Enter') {
                    onSelectHost(e.currentTarget.value)
                }
            }}
            onSelect={onSelectHost}
            onChange={onChangeHostLookup}
            onClear={onClearHost}
            onClick={stopPropagation}
            notFoundContent={isLoadingLookup && <Spin />}
            optionFilterProp='label'
            filterOption={false}
            allowClear={true}
        />
    )

    const FilterByCustomer = (
        <AutoComplete
            showSearch
            placeholder={t('certificatesPage.customer')}
            style={{ width: '150px' }}
            value={searchCustomer}
            options={customerOptions}
            dropdownMatchSelectWidth={200}
            onInputKeyDown={(e) => {
                if (e.key === 'Enter') {
                    onSelectCustomer(e.currentTarget.value)
                }
            }}
            onSelect={onSelectCustomer}
            onChange={onChangeCustomerLookup}
            onClear={onClearCustomer}
            onClick={stopPropagation}
            optionFilterProp='label'
            filterOption={(input, opt) => removeDiac(opt?.label + '').includes(removeDiac(input))}
            allowClear={true}
        />
    )

    const columns: ColumnsType<DatabaseModel> = [
        {
            title: FilterByName,
            dataIndex: 'name',
            key: 'name',
            width: '40%',
            align: 'left',
            fixed: 'left',
            render: (name: string, rec: DatabaseModel) => <Link to={`/rdb/database/${rec.id}`}>{rec.name}</Link>
        },
        {
            title: FilterByHost,
            dataIndex: 'service_id',
            key: 'service_id',
            width: '30%',
            render: (name: string, rec: DatabaseModel) => (
                <Tag className='rdbServer'>
                    {
                        isDbServiceEditor && <Link className='linkRdb' to={`/rdb/services/${rec.service_id}`}>{rec.service?.name}</Link>
                    }
                    {
                        !isDbServiceEditor && <span>{rec.service?.name}</span>
                    }
                </Tag>)
        },
        {
            title: FilterByCustomer,
            dataIndex: 'customer_name',
            key: 'customer_name',
            width: '20%',
        },
        {
            title: (<div className='center'>Action</div>),
            key: 'action',
            dataIndex: 'action',
            width: '80px',
            align: 'center',
            render: (text: string, record: DatabaseModel) => (
                <Space size={1} onClick={(e) => e.stopPropagation()}>
                    <Button title={t('general.btnHistory')} size='small'
                        onClick={() => {
                            setHistoryModelId(record.id)
                            setHistoryTitle(record.name)
                            setHistoryModalVisible(true)
                        }}
                        icon={<InfoCircleTwoTone />}
                        className='actionButton'
                    />
                </Space>
            ),
        }
    ]


    return (
        <>
            <Card title={<><ConsoleSqlOutlined /> &nbsp;{t('databasesPage.title')}</>}
                loading={isLoadingService}
                className='DbServicePage'
                extra={

                    <Space>
                        <Button type='primary'
                            disabled={!isDbCreator}
                            onClick={() => setDatabaseModalVisible(true)}>
                            <PlusCircleOutlined /> {t('databasesPage.new_database')}
                        </Button>
                        <Button type='primary' onClick={handleCreate} disabled={!isDbServiceCreator}>
                            <PlusCircleOutlined /> {t('databasesPage.new_db_server')}
                        </Button>
                    </Space>

                }
            >
                <Table<DatabaseModel>
                    columns={columns}
                    dataSource={dataSource}
                    scroll={{ x: 'max-content' }}
                    rowKey='id'
                    bordered={true}
                    className='DatabasesTable'
                    loading={isLoadingService}
                    showHeader={true}
                    size='small'
                    pagination={false}
                    footer={() => Pager({
                        filename: 'fn',
                        total: db_pager.totalCount,
                        current: db_pager.page,
                        pageSize: db_pager.pageSize,
                        data: dataSource,
                        fetchRecords: fetchRecords
                    })}
                    onChange={(ev) => {
                        ev.pageSize && setPageSize(ev.pageSize)
                    }}
                />

            </Card>

            <Modal title={
                <div style={{ width: '100%', cursor: 'move' }}
                    onMouseOver={() => { if (disabled) { setDisabled(false) } }}
                    onMouseOut={() => { setDisabled(true) }}
                    onFocus={() => { }}
                    onBlur={() => { }}
                >
                    <GlobalOutlined /> &nbsp; {t('databasesPage.new_db_server')}
                </div>
            }
                destroyOnClose
                style={{ top: 20 }}
                width={600}
                visible={isDbServiceModalVisible}
                onCancel={() => setDbServiceModalVisible(false)}
                modalRender={(modal) => (
                    <Draggable disabled={disabled} bounds={bounds} onStart={(ev, data) => onStart(ev, data)}>
                        <div ref={draggleRef}>{modal}</div>
                    </Draggable>
                )}
                footer={null}
                confirmLoading={true}
            >
                <CreateRdbForm serviceId={selectedServiceId} onClose={() => setDbServiceModalVisible(false)} />
            </Modal>

            <Modal title={
                <div style={{ width: '100%', cursor: 'move' }}
                    onMouseOver={() => { if (disabled) { setDisabled(false) } }}
                    onMouseOut={() => { setDisabled(true) }}
                    onFocus={() => { }}
                    onBlur={() => { }}
                >
                    <GlobalOutlined /> &nbsp; {t('databasesPage.new_database')}
                </div>
            }
                destroyOnClose
                style={{ top: 20 }}
                width={600}
                visible={isDatabaseModalVisible}
                onCancel={() => setDatabaseModalVisible(false)}
                modalRender={(modal) => (
                    <Draggable disabled={disabled} bounds={bounds} onStart={(ev, data) => onStart(ev, data)}>
                        <div ref={draggleRef}>{modal}</div>
                    </Draggable>
                )}
                footer={null}
                confirmLoading={true}
            >
                <CreateDatabaseForm onClose={() => {
                    setDatabaseModalVisible(false)
                }}
                />
            </Modal>

            <HistoryModal service='rdb' model='ServiceDatabase'
                modelId={historyModelId}
                title={historyTitle}
                isModalVisible={isHistoryModalVisible}
                setModalVisible={() => setHistoryModalVisible(false)}
                modalRender={(modal) => (
                    <Draggable bounds={bounds} onStart={(ev, data) => onStart(ev, data)}>
                        <div ref={draggleRef}>{modal}</div>
                    </Draggable>
                )}
            />
        </>
    )
}

export default DatabasesPage
