import {ResGroupModel, ResourceModel, ResourcePageState, ResourceRowModel} from "./models";
import {ActionType, ResourceActions} from "./actions";
import {AsyncActionMode} from "../../../common/models";


const RESOURCE_PAGE_INITIAL_STATE: ResourcePageState = {
    isLoadingResGroup: false,
    isLoadingRes: false,
    isLoadingResAll: false,
    isLoadingEntity: false,
    isSaving: false,
    resGroups: [],
    resources: [],
    allResources: [],
    entities: [],
    error: undefined
}

const transformRes = (resources: ResourceModel[]): ResourceRowModel[] => {
    // transform ResourceModel => ResourceRowModel
    const result: ResourceRowModel[] = []
    for (const res of resources) {
        let row = {id: res.id, resource: res.resource, actionsAll: res.actions, label: '-', actions: [''], key: res.resource+res.id}
        if (res.entities && res.entities.length) {
            for (const ent of res.entities) {
                row = {id: res.id, resource: res.resource, actionsAll: res.actions, label: '-', actions: [''], key: res.resource+res.id}
                row.label = ent.label
                row.actions = ent.actions
                row.key = res.resource.replace('app\\models\\', '') + '\\' + ent.id
                result.push(row)
            }
        }
    }
    return result
}

export default (
    state = RESOURCE_PAGE_INITIAL_STATE,
    action: ResourceActions,
): ResourcePageState => {
    switch (action.type) {
        case ActionType.GET_ENTITIES:
            if (action.mode === AsyncActionMode.REQUEST) {
                return { ...state, entities:[], isLoadingEntity: true }
            }
            if (action.mode === AsyncActionMode.RESPONSE) {
                return {
                    ...state,
                    isLoadingEntity: false,
                    entities: action.data,
                }
            }
            if (action.mode === AsyncActionMode.ERROR) {
                return {
                    ...state,
                    isLoadingEntity: false,
                    error: action.error,
                }
            }
            break
        case ActionType.GET_RESGROUP:
            if (action.mode === AsyncActionMode.REQUEST) {
                return { ...state,
                    resGroup: undefined,
                    resources: [],
                    isLoadingResGroup: true
                }
            }
            if (action.mode === AsyncActionMode.RESPONSE) {
                return {
                    ...state,
                    isLoadingResGroup: false,
                    resGroup: {...action.data},
                    resources: action.data.resources ? transformRes(action.data.resources) : [],
                }
            }
            if (action.mode === AsyncActionMode.ERROR) {
                return {
                    ...state,
                    isLoadingResGroup: false,
                    error: action.error,
                }
            }
            break
        case ActionType.GET_RESGROUPS:
            if (action.mode === AsyncActionMode.REQUEST) {
                return { ...state, resGroups:[], isLoadingResGroup: true }
            }
            if (action.mode === AsyncActionMode.RESPONSE) {
                return {
                    ...state,
                    isLoadingResGroup: false,
                    resGroups: action.data,
                }
            }
            if (action.mode === AsyncActionMode.ERROR) {
                return {
                    ...state,
                    isLoadingResGroup: false,
                    error: action.error,
                }
            }
            break
        case ActionType.CREATE_RESGROUP:
            if (action.mode === AsyncActionMode.REQUEST) {
                return { ...state,
                    isSaving: true,
                    resGroup: undefined,
                    resources: []
                }
            }
            if (action.mode === AsyncActionMode.RESPONSE) {
                return {
                    ...state,
                    resGroup: action.data,
                    resGroups: state.resGroups?.concat(action.data),
                    resources: action.data.resources ? transformRes(action.data.resources) : [],
                    isSaving: false,
                }
            }
            if (action.mode === AsyncActionMode.ERROR) {
                return {
                    ...state,
                    isSaving: false,
                    error: action.error,
                }
            }
            break
        case ActionType.UPDATE_RESGROUP:
            if (action.mode === AsyncActionMode.REQUEST) {
                return { ...state, isSaving: true, resources: [] }
            }
            if (action.mode === AsyncActionMode.RESPONSE) {
                return {
                    ...state,
                    resGroup: action.data,
                    resGroups: state.resGroups!.map((item) =>
                        item.id === action.data.id ? { ...action.data } : item,
                    ),
                    resources: action.data.resources ? transformRes(action.data.resources) : [],
                    isSaving: false,
                }
            }
            if (action.mode === AsyncActionMode.ERROR) {
                return {
                    ...state,
                    isSaving: false,
                    error: action.error,
                }
            }
            break
        case ActionType.DELETE_RESGROUP:
            if (action.mode === AsyncActionMode.REQUEST) {
                return { ...state, isSaving: true, resources: [] }
            }
            if (action.mode === AsyncActionMode.RESPONSE) {
                return {
                    ...state,
                    resGroup: undefined,
                    resGroups: state.resGroups!.filter(
                        (item) => item.id !== action.request.id,
                    ),
                    isSaving: false,
                }
            }
            if (action.mode === AsyncActionMode.ERROR) {
                return {
                    ...state,
                    isSaving: false,
                    error: action.error,
                }
            }
            break

        case ActionType.GET_ALL_RESOURCES:
            if (action.mode === AsyncActionMode.REQUEST) {
                return { ...state, isLoadingResAll: true }
            }
            if (action.mode === AsyncActionMode.RESPONSE) {
                return {
                    ...state,
                    isLoadingResAll: false,
                    allResources: action.data,
                }
            }
            if (action.mode === AsyncActionMode.ERROR) {
                return {
                    ...state,
                    isLoadingResAll: false,
                    error: action.error,
                }
            }
            break

        case ActionType.GET_RESOURCES:
            if (action.mode === AsyncActionMode.REQUEST) {
                return { ...state, resources: [], isLoadingRes: true }
            }
            if (action.mode === AsyncActionMode.RESPONSE) {
                console.log('action.data.resources: '+action.data.resources)
                return {
                    ...state,
                    isLoadingRes: false,
                    resources: transformRes(action.data.resources)
                }
            }
            if (action.mode === AsyncActionMode.ERROR) {
                return {
                    ...state,
                    isLoadingRes: false,
                    error: action.error,
                }
            }
            break
        case ActionType.SELECT_RESOURCE:
            if (action.mode === AsyncActionMode.REQUEST) {
                return { ...state,
                    resource: {...action.resource},
                    }
            }
            break
        case ActionType.GET_RESOURCE:
            if (action.mode === AsyncActionMode.REQUEST) {
                return { ...state, resource: undefined, isLoadingRes: true }
            }
            if (action.mode === AsyncActionMode.RESPONSE) {
                let row = {
                    id: action.data.id,
                    resource: action.data.resource,
                    actionsAll: action.data.actions,
                    label: '*',
                    actions: [],
                    key: action.data.resource}
                return {
                    ...state,
                    isLoadingRes: false,
                    resource: row
                }
            }
            if (action.mode === AsyncActionMode.ERROR) {
                return {
                    ...state,
                    isLoadingRes: false,
                    error: action.error,
                }
            }
            break
        case ActionType.UPDATE_RESOURCE:
            if (action.mode === AsyncActionMode.REQUEST) {
                return { ...state, isSaving: true }
            }
            if (action.mode === AsyncActionMode.RESPONSE) {
                return {
                    ...state,
                    resGroup: action.data,
                    resGroups: state.resGroups!.map((item) =>
                        item.id === action.data.id ? { ...action.data } : item,
                    ),
                    resources: action.data.resources ? transformRes(action.data.resources) : [],
                    isSaving: false,
                }
            }
            if (action.mode === AsyncActionMode.ERROR) {
                return {
                    ...state,
                    isSaving: false,
                    error: action.error,
                }
            }
            break

    }
    return state
}
