import { GetUserCompanyRequestAction, GetUserCompanyResponseAction, GetUserCompanyErrorAction } from '.'
import { protectedApiClient } from 'helpers/api'
import { handleApiErrorWithNotification } from 'helpers/errorHandling'
import { CompanyDetails } from '../../company/models'

export default (id: number, cb?: (isSuccess: boolean) => void) => {
	return (
		dispatch: (args: GetUserCompanyRequestAction | GetUserCompanyResponseAction | GetUserCompanyErrorAction) => void
	) => {
		const request = new GetUserCompanyRequestAction()
		dispatch(request)

		protectedApiClient
			.get<CompanyDetails>(`/company/company/${id}`)
			.then((response) => {
				dispatch(new GetUserCompanyResponseAction(request, response.data))
				cb && cb(true)
			})
			.catch((error) => {
				dispatch(new GetUserCompanyErrorAction(request, error))
				handleApiErrorWithNotification(error)
				cb && cb(false)
			})
	}
}
