import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {useForm} from "antd/lib/form/Form";
import {AppState} from "../../common/models";
import React, {useEffect, useRef, useState} from "react";
import {useLoggedUser} from "../../helpers/loginUserHelper";
import {
    Button,
    Checkbox, Col,
    DatePicker,
    Form,
    Input, message, Row,
    Select,
    Spin,
    Table,
    Typography
} from "antd";
import {ItemState, SERVER_STATES} from "./common";
import moment from "moment";
import {_isValidDomain, SQL_DATETIME2_FORMAT, SQL_DATETIME_FORMAT} from "../../common/fce";
import {CreateStatusPlanParams, StatusPlan} from "./models";
import createStatusPlan from "./actions/createStatusPlan";
import {sort_str} from "../../common/sorting";
import updateStatusPlan from "./actions/updateStatusPlan";
import deleteStatusPlan from "./actions/deleteStatusPlan";
import {BaseSelectRef} from "rc-select";



interface Props {
    onClose: () => void
}

interface EditableCellProps extends React.HTMLAttributes<HTMLElement> {
    editing: boolean;
    dataIndex: string;
    title: any;
    record: StatusPlan;
    index: number;
    children: React.ReactNode;
}

const _isValidDatetime = (s) => {
    if (!s) {
        return false
    }
    const now = moment();
    const now2y = moment().add(2, 'years');
    try {
        const dt1 = moment(s, SQL_DATETIME2_FORMAT)
        if ((dt1.unix() > now.unix()) && (dt1.unix() < now2y.unix())) {
            return true
        }
    }
    catch (e) {
        console.error(e)
    }
    try {
        const dt2 = moment(s, SQL_DATETIME_FORMAT)
        if ((dt2.unix() > now.unix()) && (dt2.unix() < now2y.unix())) {
            return true
        }
    }
    catch (e) {console.error(e)}
    return false
}

const EditableCell: React.FC<EditableCellProps> = ({
                                                       editing,
                                                       dataIndex,
                                                       title,
                                                       record,
                                                       index,
                                                       children,
                                                       ...restProps
                                                   }) => {

    const [validFrom, setValidFrom] = useState<boolean>();

    let inputNode = <Input allowClear={true} />
    if (dataIndex === 'valid_from') {
        inputNode = <Input allowClear={true}
                           tabIndex={1}
                           onKeyDown={(e) => {
                               const b = _isValidDatetime(e.currentTarget.value)
                               setValidFrom(b)
                           }}
                           onKeyPress={(e) => {
                               if (e.key === 'Enter') {
                                   const b = _isValidDatetime(e.currentTarget.value)
                                   setValidFrom(b)
                               }
                               if (!/[0-9+-: ]/.test(e.key)) {
                                   e.preventDefault();
                               }
                           }} />
        return (
            <td {...restProps}>
                {editing ? (
                    <Form.Item
                        name={dataIndex}
                        style={{ margin: 0 }}
                        rules={[
                            {required: true, message: `Please Input ${title}!`},
                            () => ({
                                validator(_, value) {
                                    console.log('validator: '+value.toString())
                                    const b = _isValidDatetime(value.toString())
                                    if (b) {
                                        return Promise.resolve()
                                    }
                                    return Promise.reject(new Error('Invalid format'))
                                },
                            })
                        ]}
                    >
                        {inputNode}
                    </Form.Item>
                ) : (
                    children
                )}
            </td>
        );
    }
    if (dataIndex === 'description') {
        inputNode = <Input tabIndex={3} allowClear={true} />
    }
    if (dataIndex === 'status_id') {
        inputNode = (
            <Select size='middle' tabIndex={2}
                    options={SERVER_STATES.map(item => ({
                        value: item.id,
                        key: item.id,
                        label: item.name
                    }))}
            />
        )
    }
    if (dataIndex === 'is_notification') {
        inputNode = <Checkbox />
        return (
            <td {...restProps}>
                {editing ? (
                    <Form.Item
                        name={dataIndex}
                        style={{ margin: 0 }}
                        valuePropName="checked"
                        rules={[{ required: true, message: `Please Input ${title}!`}]}
                    >
                        {inputNode}
                    </Form.Item>
                ) : (
                    children
                )}
            </td>
        );
    }

    return (
        <td {...restProps}>
            {editing ? (
                <Form.Item
                    name={dataIndex}
                    style={{ margin: 0 }}
                    rules={[{ required: true, message: `Please Input ${title}!`}]}
                >
                    {inputNode}
                </Form.Item>
            ) : (
                children
            )}
        </td>
    );
};

const ServerPlanForm = (props: Props) => {
    const {t} = useTranslation()
    const dispatch = useDispatch()
    const [form] = useForm()
    const input2Ref = useRef<BaseSelectRef>(null);

    const {server, status_plans} = useSelector( ( state: AppState ) => state.logic_server)

    const [planSource, setPlanSource] = useState<StatusPlan[]>([])
    const [editingKey, setEditingKey] = useState<number | undefined>();

    // get settings and logged user from store
    const loggedUser = useLoggedUser()
    if (!loggedUser || !loggedUser.isLoaded()) {
        return (<Spin/>)
    }
    const appSetting = loggedUser.getAppSettings()

    useEffect(() => {
        if (status_plans) {
            setPlanSource(status_plans.sort((a, b) => sort_str(b.valid_from, a.valid_from)))
        }
    }, [status_plans])

    const isEditing = (record: StatusPlan) => record.id === editingKey;

    const handleEdit = (rec: Partial<StatusPlan>) => {
        form.setFieldsValue({
            valid_from: rec.valid_from,
            status_id: rec.status_id,
            is_notification: rec.is_notification,
            description: rec.description, ...rec });
        setEditingKey(rec.id);
    };

    const handleDelete = (rec: Partial<StatusPlan>) => {
        if ( rec && rec.id && rec.id > 0 ) {
            // delete
            const newData = planSource.filter(p => p.id != rec.id)
            setPlanSource(newData);
            setEditingKey(undefined);
            dispatch(deleteStatusPlan(rec.id, suc => {
                suc && message.success(t('general.success'))
            }))
            props.onClose
            return
        }
        else {
            message.error('Missing Plan!')
        }
    }

    const cancel = () => {
        setEditingKey(undefined);
        setPlanSource(planSource.filter(p => p.id > 0))
    };

    const handleSave = async (plan: StatusPlan) => {
        try {
            const row = (await form.validateFields()) as StatusPlan;
            const newData = [...planSource];
            const index = newData.findIndex(item => item.id === plan.id);
            if (index > -1) {
                const item = newData[index];
                console.log('Save: '+JSON.stringify(item))
                newData.splice(index, 1, {
                    ...item,
                    ...row,
                });
                setPlanSource(newData);
                console.log('newData: '+JSON.stringify(newData[index]))
                savePlan(newData[index])
                setEditingKey(undefined);
            } else {
                newData.push(row);
                setPlanSource(newData);
                setEditingKey(undefined);
            }
        } catch (errInfo) {
            console.log('Validate Failed:', errInfo);
        }
    };

    const addNewPlan = () => {
        const today = moment()
        const newPlan = {
            id: 0,
            key: '0',
            server_id: server!.id,
            user_id: loggedUser?.user.id,
            status_id: 1,
            valid_from: today.format(SQL_DATETIME2_FORMAT),
            description: '',
            is_notification: 0
        }
        setPlanSource([newPlan, ...planSource])
        setEditingKey(0);
        handleEdit(newPlan)
    }

    const savePlan = (plan: StatusPlan) => {
        if (!server) {
            message.error('Missing server!')
            return
        }
        const dt = moment(plan.valid_from, SQL_DATETIME2_FORMAT)

        plan.is_notification = plan.is_notification ? 1 : 0
        plan.valid_from = dt.format(SQL_DATETIME_FORMAT)
        if ( plan && plan.id && plan.id > 0 ) {
            // handleEdit
            dispatch(updateStatusPlan(plan, suc => {
                suc && message.success(t('general.success'))
                props.onClose
            }))
            return
        }
        else {
            const params: CreateStatusPlanParams = {
                server_id: server.id,
                status_id: plan.status_id,
                valid_from: dt.format(SQL_DATETIME_FORMAT),
                description: plan.description,
                is_notification: plan.is_notification ? 1 : 0
            }
            dispatch(createStatusPlan(params, suc => {
                suc && message.success(t('general.success'))
                props.onClose
            }))
        }
    }

    const columns = [
        {
            title: t('serversPage.time'),
            dataIndex: 'valid_from',
            width: '25%',
            editable: true,
            render: (dt, rec) => (moment(dt, SQL_DATETIME_FORMAT).format(SQL_DATETIME2_FORMAT)),
        },
        {
            title: t('serversPage.state'),
            dataIndex: 'status_id',
            width: '15%',
            editable: true,
            render: (sid, rec) => t(`serversPage.${SERVER_STATES.find( s => s.id === rec.status_id)?.name.toLowerCase()}`),

        },
        {
            title: 'Notif.',
            dataIndex: 'is_notification',
            width: 40,
            editable: true,
            render: (customer_name, rec) => rec.is_notification ? 1 : 0,
        },
        {
            title: t('serversPage.note'),
            dataIndex: 'description',
            width: '40%',
            editable: true,
        },
        {
            title: 'operation',
            dataIndex: 'operation',
            render: (_: any, rec: StatusPlan) => {
                const editable = isEditing(rec);
                return editable ? (
                    <span>
                        <Typography.Link onClick={() => handleSave(rec)} style={{ marginRight: 8 }}>
                          Save
                        </Typography.Link>
                          <a onClick={cancel}>Cancel</a>
                    </span>
                    ) : (
                        <>
                            <Typography.Link disabled={!!editingKey || (moment(rec.valid_from) < moment())} onClick={() => handleEdit(rec)}>
                                Edit
                            </Typography.Link>
                            &nbsp;|&nbsp;
                            <Typography.Link disabled={!!editingKey || (moment(rec.valid_from) < moment())} onClick={() => handleDelete(rec)}>
                                Delete
                            </Typography.Link>
                        </>

                    );
            },
        },
    ];

    const mergedColumns = columns.map(col => {
        if (!col.editable) {
            return col;
        }
        return {
            ...col,
            onCell: (rec: StatusPlan) => ({
                rec,
                dataIndex: col.dataIndex,
                title: col.title,
                editing: isEditing(rec),
            }),
        };
    });

    return (
        <Form form={form} component={false}>
            <Row>
                <Col span={24} style={{textAlign: 'right'}}>
                    <Button type='primary' size='small'
                            style={{margin: '2px'}}
                            disabled={planSource.filter(p => !p.id).length > 0}
                            onClick={() => {addNewPlan()}}>{t('serversPage.add_new')}</Button>
                </Col>
            </Row>
            <Table
                components={{
                    body: {
                        cell: EditableCell,
                    },
                }}
                bordered
                dataSource={planSource}
                columns={mergedColumns}
                rowClassName="editable-row"
                pagination={false}
            />

        </Form>
    );

}

export default ServerPlanForm
