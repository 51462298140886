import { ApplyChangesVhostRequestAction, ApplyChangesVhostResponseAction, ApplyChangesVhostErrorAction } from './index'
import { protectedApiClient } from 'helpers/api'
import { handleApiError, handleApiErrorWithNotification } from 'helpers/errorHandling'
import { ApplyChangesParams, VhostModel, VhostParams } from '../models'

export default (id: number, cb?: (isSuccess: boolean) => void) => {
	return (
		dispatch: (
			arg: ApplyChangesVhostRequestAction | ApplyChangesVhostResponseAction | ApplyChangesVhostErrorAction
		) => void
	) => {
		const request = new ApplyChangesVhostRequestAction(id)
		dispatch(request)

		protectedApiClient
			.post<VhostModel>(`/web/vhost/${id}/apply-config?expand=service,domain,is_config_applied`)
			.then((response) => {
				dispatch(new ApplyChangesVhostResponseAction(request, response.data))
				cb && cb(true)
			})
			.catch((error) => {
				let msg = error.toString()
				if (parseInt(error.response.status) === 409) {
					dispatch(new ApplyChangesVhostErrorAction(request, error.response.data.message))
					handleApiError(error)
					cb && cb(false)
					return
				}

				if (parseInt(error.response.status) === 502) {
					// msg = 'ERROR HTTP 502'
					msg = error.response.data.message
				}
				dispatch(new ApplyChangesVhostErrorAction(request, msg))
				handleApiErrorWithNotification(error)
				cb && cb(false)
			})
	}
}
