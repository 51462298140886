import { GetMailboxRequestAction, GetMailboxResponseAction, GetMailboxErrorAction } from '.'
import { protectedApiClient } from 'helpers/api'
import { handleApiErrorWithNotification } from 'helpers/errorHandling'
import { MailboxModel } from '../models'

export default (domain_id: number, mailbox: string, cb?: (isSuccess: boolean) => void) => {
	return (dispatch: (arg: GetMailboxRequestAction | GetMailboxResponseAction | GetMailboxErrorAction) => void) => {
		const request = new GetMailboxRequestAction()
		dispatch(request)

		protectedApiClient
			.get<MailboxModel>(`/mail/domain/${domain_id}/mailbox?mailbox=${mailbox}`)
			.then((response) => {
				dispatch(new GetMailboxResponseAction(request, response.data))
				cb && cb(true)
			})
			.catch((error) => {
				dispatch(new GetMailboxErrorAction(request, error))
				handleApiErrorWithNotification(error)
				cb && cb(false)
			})
	}
}
