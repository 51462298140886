import React, {useEffect, useState} from 'react'
import {Button, Checkbox, Col, Divider, Form, Input, message, Row, Select, Spin, Switch, Tag} from 'antd'
import {useForm} from 'antd/lib/form/Form'
import {AppState} from 'common/models'
import {formItemLayout, tailLayout} from 'helpers/layoutHelpers'
import {useTranslation} from 'react-i18next'
import {useDispatch, useSelector} from 'react-redux'
import {Store} from 'antd/lib/form/interface'
import {ResourceParams} from "./models";
import {CheckboxValueType} from "antd/lib/checkbox/Group";
import {CheckboxChangeEvent} from "antd/lib/checkbox";
import {useLoggedUser} from "../../../helpers/loginUserHelper";
import updateResource from "./actions/updateResource";
import {sort_label} from "../../../common/sorting";
import getEntities from "./actions/getEntities";
import {renderID, renderResource} from "../common";

const CheckboxGroup = Checkbox.Group;

interface Props {
    setModalVisible: (param: boolean) => void
}

const {Item} = Form

const EditResourceForm = ({setModalVisible}: Props) => {

    const [form] = useForm()
    const {t} = useTranslation()
    const dispatch = useDispatch()

    const {isSaving, resource, resGroup, entities} = useSelector((state: AppState) => state.resourcePage)
    const [status, setStatus] = useState<boolean>(false)
    const [path, setPath] = useState<string>('')
    const [allMethods, setAllMethods] = useState<string[]>([])
    const [entityOptions, setEntityOptions] = useState<{ label: string, value: string }[]>([])
    const [useSpecificEntity, setUseSpecificEntity] = useState<boolean>(false)
    const [selectedEntity, setSelectedEntity] = useState<string>('*')

    const [checkedList, setCheckedList] = useState<CheckboxValueType[]>([]);
    const [indeterminate, setIndeterminate] = useState(true);
    const [checkAll, setCheckAll] = useState(false);

    // get settings and logged user from store
    const loggedUser = useLoggedUser()
    if (!loggedUser || !loggedUser.isLoaded()) {
        return (<Spin />)
    }
    const appSetting = loggedUser.getAppSettings()

    useEffect(() => {
        // load resource
        if (resource) {
            resource.id && dispatch(getEntities(resource.id, loggedUser.user.customer_id))
            setPath(resource.resource)
            setCheckedList(resource.actions)
            setAllMethods(resource.actionsAll)
            // setSelectedEntity('')
            setUseSpecificEntity(resource.label != '*')
        }
    }, [resource])

    useEffect(() => {
        if (resource && resource.id) {
            if (useSpecificEntity) {

                setStatus(true)
            }
            else {
                if (selectedEntity != '*') {
                    setSelectedEntity('*')
                    setStatus(true)
                }
            }
        }
    }, [useSpecificEntity])

    useEffect(() => {
        if (entities && entities.length) {
            const items = entities.map(c => ({label: c.label ? c.label.replace('App\\models\\', '') : `item${c.model_id}`, value: `${c.id}`}))
            setEntityOptions(items.sort(sort_label))
        }
    }, [entities])

    useEffect(() => {
        if (entityOptions && entityOptions.length) {
            resource && setSelectedEntity(resource.label)
        }
    }, [entityOptions])

    const onChangeMethods = (list: CheckboxValueType[]) => {
        setCheckedList(list)
        setIndeterminate(!!list.length && list.length < allMethods.length)
        setCheckAll(list.length === allMethods.length)
        setStatus(true)
    };

    const onCheckAllChange = (e: CheckboxChangeEvent) => {
        setCheckedList(e.target.checked ? allMethods : [])
        setIndeterminate(false)
        setCheckAll(e.target.checked)
        setStatus(true)
    };

    const onFormSubmit = (values: Store) => {
        // save data
        if (resGroup && resGroup.id && resource && resource.id) {
            const params: ResourceParams = {
                res_group_id: resGroup.id,
                resource_id: resource.id,
                entity_id: selectedEntity,
                actions: checkedList.map(m => m.valueOf().toString())}
            dispatch(updateResource(params, (isSuccess: boolean) => {
                    if (isSuccess) {
                        setModalVisible(false)
                        message.success(t('general.success'))
                    }
                }
            ))
        }
    }

    if (!resGroup || !resource) {
        // resGroup is required
        return (<Spin />)
    }

    return (
        <Form
            {...formItemLayout}
            onFinish={onFormSubmit}
            form={form}
            initialValues={{}}
        >
            <Row>
                <Col span={8} className='right' style={{padding: '5px'}}>{ t('rights.resources.group')}:</Col>
                <Col span={16} style={{padding: '5px'}}><Tag color='blue' style={{fontSize: '1em'}}>{resGroup.name}</Tag></Col>
            </Row>
            <Row>
                <Col span={8} className='right' style={{padding: '5px'}}>{ t('rights.resources.resource')}:</Col>
                <Col span={16} style={{padding: '5px'}}>{renderResource(path)}</Col>
            </Row>
            <Row>
                <Col span={8} className='right' style={{padding: '5px'}}>{ t('rights.resources.entity')}:</Col>
                <Col span={16} style={{padding: '5px'}}>{renderID(selectedEntity)}</Col>
            </Row>
            <Row>
                <Col span={8} className='right' style={{padding: '5px'}}>{ t('rights.resources.specific_entity')}:</Col>
                <Col span={16} style={{padding: '5px'}}>
                    <Select
                        showSearch
                        style={{display: 'block', width: '200px', float: 'left'}}
                        size='small'
                        dropdownMatchSelectWidth={260}
                        disabled={!useSpecificEntity}
                        optionFilterProp='label'
                        placeholder={t('general.choose')}
                        options={entityOptions}
                        onChange={(value => setSelectedEntity(value))}
                        value={selectedEntity}
                    />
                    <Switch style={{float: 'left', marginLeft: '10px'}}
                            checked={useSpecificEntity}
                            onChange={() => {
                                setUseSpecificEntity(!useSpecificEntity)
                            }}
                            checkedChildren={t('rights.resources.set')}
                            unCheckedChildren={t('rights.resources.set')} />
                </Col>
            </Row>
            {
                allMethods && allMethods.length > 1 &&
                <>
                    <Divider />
                    <Row style={{marginTop: '25px'}}>
                        <Col span={8} className='right' style={{padding: '5px'}}>{ t('rights.resources.check_all')}:</Col>
                        <Col span={16} className='left' style={{padding: '5px'}}>
                            <Checkbox indeterminate={indeterminate} onChange={onCheckAllChange} checked={checkAll} />
                        </Col>
                    </Row>
                </>
            }

            <Row style={{marginTop: '25px'}}>
                <Col span={8} className='right' style={{padding: '5px'}}>{ t('rights.resources.actions')}:</Col>
                <Col span={16} className='left' style={{padding: '5px'}}>
                    <CheckboxGroup options={allMethods} value={checkedList} onChange={onChangeMethods} />
                </Col>
            </Row>

            <Divider />
            <Item {...tailLayout}>
                <Button loading={isSaving} type='primary' htmlType='submit' disabled={!status}>
                    { t('general.save')}
                </Button>
            </Item>

        </Form>
    )
}

export default EditResourceForm
