import { GetInvoiceCompanyRequestAction, GetInvoiceCompanyResponseAction, GetInvoiceCompanyErrorAction } from '.'
import { protectedApiClient } from 'helpers/api'
import { handleApiErrorWithNotification } from 'helpers/errorHandling'
import {CompanyDetails} from "../../../company/models";


export default (
    id: number,
    expand?: string,
    cb?: (isSuccess: boolean) => void,
) => {
    return (
        dispatch: (
            arg:
                | GetInvoiceCompanyRequestAction
                | GetInvoiceCompanyResponseAction
                | GetInvoiceCompanyErrorAction,
        ) => void,
    ) => {
        const request = new GetInvoiceCompanyRequestAction()
        dispatch(request)

        protectedApiClient
            .get<CompanyDetails>(`/company/company/${id}`)
            .then((response) => {
                dispatch(new GetInvoiceCompanyResponseAction(request, response.data))
                cb && cb(true)
            })
            .catch((error) => {
                dispatch(new GetInvoiceCompanyErrorAction(request, error))
                handleApiErrorWithNotification(error)
                cb && cb(false)
            })
    }
}
