import {
  GetServerRequestAction,
  GetServerResponseAction,
  GetServerErrorAction,
} from '.'
import { protectedApiClient } from 'helpers/api'
import { handleApiErrorWithNotification } from 'helpers/errorHandling'
import { ServerModel } from '../models'

export default (id: number, cb?: (isSuccess: boolean) => void) => {
  return (
    dispatch: (
      arg:
        | GetServerRequestAction
        | GetServerResponseAction
        | GetServerErrorAction,
    ) => void,
  ) => {
    const request = new GetServerRequestAction(id)
    dispatch(request)

    protectedApiClient
      .get<ServerModel>(`/server/server/${id}?expand=customer,location,ips,type,services,management_type,monitoring_type,primary_ip,backup_type,status`)
      .then((response) => {
        dispatch(new GetServerResponseAction(request, response.data))
        cb && cb(true)
      })
      .catch((error) => {
        dispatch(new GetServerErrorAction(request, error))
        handleApiErrorWithNotification(error)
        cb && cb(false)
      })
  }
}
