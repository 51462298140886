import { Modal } from "antd"
import { ExclamationCircleOutlined } from "@ant-design/icons"
import i18next from "i18next"

const { confirm } = Modal


export const confirmDeleteBox = (
  title: string, 
  content: string, 
  isLoadingButton: boolean, 
  onOkHandler: () => void, 
  onCancelHandler: () => void,
  t: i18next.TFunction) => {
    
  confirm({
    icon: <ExclamationCircleOutlined style={{ color: 'red' }} />,
    title: title,
    content: <p>{content}</p>,
    okText: t('general.yes'),
    cancelText: t('general.cancel'),
    okButtonProps: { loading: isLoadingButton },
    className: 'confirm-alert',
    onOk() { onOkHandler() },
    onCancel() { onCancelHandler }
  })
}

