import { protectedApiClient } from 'helpers/api'
import { handleApiErrorWithNotification } from 'helpers/errorHandling'
import {TableCompanyErrorAction, TableCompanyRequestAction, TableCompanyResponseAction} from "./index";
import {CompanyDetails} from "../../company/models";
import {NameItem} from "../../../common/models";


export default (cb?: (isSuccess: boolean) => void) => {
    return (
        dispatch: (
            arg:
                | TableCompanyRequestAction
                | TableCompanyResponseAction
                | TableCompanyErrorAction,
        ) => void,
    ) => {
        const request = new TableCompanyRequestAction()
        dispatch(request)

        let url = `/company/company/list`
        protectedApiClient
            .get<NameItem[]>(url)
            .then((response) => {
                dispatch(new TableCompanyResponseAction(request, response.data))
                cb && cb(true)
            })
            .catch((error) => {
                dispatch(new TableCompanyErrorAction(request, error))
                handleApiErrorWithNotification(error)
                cb && cb(false)
            })
    }
}
