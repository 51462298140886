import { GetMailVersionRequestAction, GetMailVersionResponseAction, GetMailVersionErrorAction } from '.'
import { protectedApiClient } from 'helpers/api'
import {handleApiError, handleApiErrorWithNotification} from 'helpers/errorHandling'
import {MailVersionResp} from "../models";


export default (id: number, cb?: (isSuccess: boolean) => void) => {
    return (
        dispatch: (
            arg: GetMailVersionRequestAction | GetMailVersionResponseAction | GetMailVersionErrorAction,
        ) => void,
    ) => {
        const request = new GetMailVersionRequestAction()
        dispatch(request)

        protectedApiClient
            .get<MailVersionResp>(`/mail/service/${id}/mail-version`)
            .then((response) => {
                dispatch(new GetMailVersionResponseAction(request, response.data))
                cb && cb(true)
            })
            .catch((error) => {
                let msg = error.toString()
                if (parseInt(error.response.status) === 502) {
                    msg = "ERROR HTTP 502"
                }
                dispatch(new GetMailVersionErrorAction(request, msg))
                handleApiError(error)
                cb && cb(false)
            })
    }
}
