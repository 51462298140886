import { CreateMailAliasRequestAction, CreateMailAliasResponseAction, CreateMailAliasErrorAction } from '.'
import { protectedApiClient } from 'helpers/api'
import { handleApiErrorWithNotification } from 'helpers/errorHandling'
import { CreateMailAliasParams, MailAliasModel } from '../models'

export default (params: CreateMailAliasParams, cb?: (isSuccess: boolean) => void) => {
	return (
		dispatch: (arg: CreateMailAliasRequestAction | CreateMailAliasResponseAction | CreateMailAliasErrorAction) => void
	) => {
		const request = new CreateMailAliasRequestAction(params)
		const qs = params.emails.map((email) => `email[]=${encodeURIComponent(email)}`).join('&')

		dispatch(request)
		protectedApiClient
			.post<MailAliasModel[]>(`/mail/domain/${params.mail_domain_id}/forward?${qs}`, params)
			.then((response) => {
				dispatch(new CreateMailAliasResponseAction(request, response.data))
				cb && cb(true)
			})
			.catch((error) => {
				let msg = ''
				if (error) {
					if (error.message) {
						msg = error.message
					} else {
						msg = error.toString()
					}
				}
				dispatch(new CreateMailAliasErrorAction(request, msg))
				handleApiErrorWithNotification(error)
				cb && cb(false)
			})
	}
}
