import {AsyncActionMode} from './models'
import {LoginActions} from '../pages/login/actions'
import {ThemeActions} from '../components/ThemeSwitcher/actions'
import {FontActions} from '../components/FontSwitcher/actions'
import {UserActions} from 'pages/user/actions'
import {CompanyActions} from 'pages/company/actions'
import {CustomerActions} from 'pages/billing/customer/actions'
import {IpSubnetActions} from 'pages/ip/subnet/actions'
import {CountriesActions} from 'components/SelectCountries/actions'
import {TaxActions} from 'pages/billing/tax/actions'
import {ProductActions} from 'pages/billing/product/actions'
import {PricelistActions} from 'pages/billing/pricelist/actions'
import {ProductPriceActions} from 'pages/billing/productprice/actions'
import {ProductPriceRangeActions} from 'pages/billing/productpricerange/actions'
import {CustomerServiceActions} from 'pages/billing/customerservice/actions'
import {InvoiceActions} from 'pages/billing/invoice/actions'
import {InvoiceDocumentActions} from 'pages/billing/invoicedocument/actions'
import {InvoiceCostAllocationActions} from 'pages/billing/invoicecostallocation/actions'
import {CostAllocationActions} from 'pages/billing/costallocation/actions'
import {BillActions} from 'pages/billing/bill/actions'
import {CurrenciesActions} from 'components/SelectCurrencies/actions'
import {InventoryActions} from 'pages/inventory/inventory/actions'
import {ServerActions} from 'pages/server/actions'
import {InventoryLocationActions} from 'pages/inventory/location/actions'
import {InventoryTypeActions} from 'pages/inventory/type/actions'
import {PhysicalServerActions} from 'pages/inventory/physicalserver/actions'
import {DataCenterActions} from 'pages/datacenter/datacenter/actions'
import {RackActions} from 'pages/datacenter/rack/actions'
import {BlockActions} from 'pages/datacenter/block/actions'
import {HistoryActions} from 'components/History/actions'
import {DnsServiceActions} from 'pages/dns/service/actions'
import {AsyncJobActions} from 'pages/asyncjob/job/actions'
import {CertificateActions} from 'pages/certificate/actions'
import {VhostActions} from 'pages/webservice/actions'
import {ServiceTypeActions} from 'pages/billing/servicetype/actions'
import {MailActions} from 'pages/billing/mail/actions'
import {VpsSettingActions} from "../pages/vpsadmin/actions";
import {ResourceActions} from "../pages/rights/resources/actions";
import {RoleActions} from "../pages/rights/roles/actions";
import {UserRightsActions} from "../pages/rights/users/actions";
import {GroupActions} from "../pages/rights/usergroups/actions";



export type DispatchFunc = (action: ReduxAction | ApiAction | ((dispatch: DispatchFunc) => void)) => void

export type ReduxAction =
	| LoginActions
	| ThemeActions
	| UserActions
	| FontActions
	| CompanyActions
	| CustomerActions
	| IpSubnetActions
	| CountriesActions
	| TaxActions
	| ProductActions
	| PricelistActions
	| ProductPriceActions
	| ProductPriceRangeActions
	| CustomerServiceActions
	| InvoiceActions
	| InvoiceDocumentActions
	| InvoiceCostAllocationActions
	| CostAllocationActions
	| BillActions
	| CurrenciesActions
	| InventoryActions
	| ServerActions
	| InventoryLocationActions
	| InventoryTypeActions
	| PhysicalServerActions
	| DataCenterActions
	| RackActions
	| BlockActions
	| HistoryActions
	| DnsServiceActions
	| AsyncJobActions
	| CertificateActions
	| VhostActions
	| ServiceTypeActions
	| MailActions
	| VpsSettingActions
	| ResourceActions
	| RoleActions
	| UserRightsActions
	| GroupActions


export enum ApiMethod {
	GET = 'get',
	POST = 'post',
	PUT = 'put',
	PATCH = 'patch',
	DELETE = 'delete',
}

export class ApiAction {
	constructor(
		public type: string,
		public method: ApiMethod,
		public url: string,
		public params?: object,
		public cb?: (success: boolean) => void,
	) {}
}

export interface RequestAction<TType extends string> {
	mode: typeof AsyncActionMode.REQUEST
	type: TType
}

export interface ResponseAction<TType extends string, T> {
	mode: typeof AsyncActionMode.RESPONSE
	type: TType
	payload: T
}

export interface ErrorAction<TType extends string> {
	mode: typeof AsyncActionMode.ERROR
	type: TType
	error: string
}
