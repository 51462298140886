import {GetJobNamesRequestAction, GetJobNamesResponseAction, GetJobNamesErrorAction} from '.'
import {protectedApiClient} from 'helpers/api'
import {handleApiErrorWithNotification} from 'helpers/errorHandling'
import {NameItemStr} from "../../../../common/models";


export default (cb?: (isSuccess: boolean) => void) => {
    return (
        dispatch: (arg: GetJobNamesRequestAction | GetJobNamesResponseAction | GetJobNamesErrorAction) => void,
    ) => {
        const request = new GetJobNamesRequestAction()
        dispatch(request)

        protectedApiClient
            .get<NameItemStr[]>('/asyncjob/job/names')
            .then((response) => {
                dispatch(new GetJobNamesResponseAction(request, response.data))
                cb && cb(true)
            })
            .catch((error) => {
                dispatch(new GetJobNamesErrorAction(request, error))
                handleApiErrorWithNotification(error)
                cb && cb(false)
            })
    }
}
