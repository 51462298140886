import {GetMailServicesRequestAction, GetMailServicesResponseAction, GetMailServicesErrorAction} from './index'
import {protectedApiClient} from 'helpers/api'
import {handleApiErrorWithNotification} from 'helpers/errorHandling'
import { NameItem } from 'common/models'


export default (cb?: (isSuccess: boolean) => void) => {
    return (dispatch: (arg: GetMailServicesRequestAction | GetMailServicesResponseAction | GetMailServicesErrorAction) => void) => {
        const request = new GetMailServicesRequestAction()
        dispatch(request)

        let url = `/mail/service/list`
        protectedApiClient
            .get<NameItem[]>(url)
            .then((response) => {
                dispatch(new GetMailServicesResponseAction(request, response.data))
                cb && cb(true)
            })
            .catch((error) => {
                dispatch(new GetMailServicesErrorAction(request, error.toString()))
                handleApiErrorWithNotification(error)
                cb && cb(false)
            })
    }
}
