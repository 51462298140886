import { useHistory } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { useTranslation } from "react-i18next"
import { useForm } from "antd/es/form/Form"
import { Alert, Button, Form, Input, message, Select, Spin } from "antd"
import { formItemLayout, tailLayout } from "helpers/layoutHelpers"
import { CreateMailAliasParams } from "./models"
import { AppState } from "common/models"
import { useState } from "react"
import { Store } from "antd/es/form/interface"
import createMailAlias from "./actions/createMailAlias"
import TextArea from "antd/lib/input/TextArea"
import { _validateEmail, _validateEmailName } from "common/fce"

interface Props {
  onClose: (refresh: boolean) => void
}

const { Item } = Form


const CreateAliasForm = (props: Props) => {
  const [form] = useForm()
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const history = useHistory()

  const { mail_domain, isSavingMailAlias } = useSelector((state: AppState) => state.mailservice)

  const [updateStatus, setUpdateStatus] = useState(false)



  const onFormSubmit = (values: Store) => {
    if (!mail_domain) {
      message.error('Mail domain is not found')
      return
    }
    if (!values.alias) {
      message.error('Alias is required')
      return
    }

    if (!_validateEmailName(values.alias)) {
      message.error('Invalid alias')
      return
    }

    if (!values.emails) {
      message.error('Email is required')
      return
    }

    const emails = values.emails.split("\n")
    const validEmails = emails.filter(email => _validateEmail(email))
    if (validEmails.length !== emails.length) {
      message.error('Invalid email')
      return
    }

    const params: CreateMailAliasParams = {
      mail_domain_id: mail_domain.id,
      emails: values.emails.split("\n"),
      alias: values.alias
    }
    dispatch(createMailAlias(params, (suc) => {
      if (suc) {
        message.success(t('general.success'))
        props.onClose(true)
      }
    }))
  }

  if (!mail_domain || !mail_domain.id) {
    return <Spin />
  }

  return (
    <Form
      onFinish={onFormSubmit}
      form={form}
      onChange={() => setUpdateStatus(true)}
      initialValues={{ alias_type: 'FORWARD' }}
      {...formItemLayout}
    >

      <Form.Item name='alias' label={t('mailDomainsPage.alias')} rules={[{ required: true }]}>
        <Input data-gramm="false" data-1p-ignore suffix={`@${mail_domain.name}`} />
      </Form.Item>

      <Form.Item name='emails' label={t('mailDomainsPage.emails')} rules={[{ required: true }]}>
        <TextArea data-gramm="false" placeholder="mail@box.com" data-1p-ignore />
      </Form.Item>



      <Item {...tailLayout}>
        <Button type='primary'
          loading={isSavingMailAlias}
          disabled={!updateStatus}
          htmlType='submit'>
          {t('general.create')}
        </Button>
      </Item>

      <Alert type="info" className="small pad4 center" message={t('mailDomainsPage.alias_create_text')} />
    </Form>
  )
}

export default CreateAliasForm
