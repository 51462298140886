import { useSelector } from 'react-redux'
import { AppState } from '../common/models'
import { useEffect, useState } from 'react'
import LoggedUser from '../common/LoggedUser'

export const useLoggedUser = () => {
	// returns the current logged user from the store
	const { auth } = useSelector((state: AppState) => state)

	if (
		auth.authenticated &&
		auth.self &&
		auth.self.roles &&
		auth.self.roles.length > 0 &&
		auth.self_customer &&
		auth.company &&
		auth.self_settings &&
		auth.self_settings.length > 0 &&
		auth.menu_roles
	) {
		return new LoggedUser(auth)
	} else {
		return undefined
	}
}
