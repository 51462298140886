import { GetCompanyCustomerRequestAction, GetCompanyCustomerResponseAction, GetCompanyCustomerErrorAction } from '.'
import { protectedApiClient } from 'helpers/api'
import { handleApiErrorWithNotification } from 'helpers/errorHandling'
import { ContactModel } from '../models'
import {CompanyDetails} from "../../../company/models";


export default (
    id: number,
    expand?: string,
    cb?: (isSuccess: boolean) => void,
) => {
    return (
        dispatch: (
            arg:
                | GetCompanyCustomerRequestAction
                | GetCompanyCustomerResponseAction
                | GetCompanyCustomerErrorAction,
        ) => void,
    ) => {
        const request = new GetCompanyCustomerRequestAction()
        dispatch(request)

        protectedApiClient
            .get<CompanyDetails>(`/company/company/${id}`)
            .then((response) => {
                dispatch(new GetCompanyCustomerResponseAction(request, response.data))
                cb && cb(true)
            })
            .catch((error) => {
                dispatch(new GetCompanyCustomerErrorAction(request, error))
                handleApiErrorWithNotification(error)
                cb && cb(false)
            })
    }
}
