import { ActionType, DbServiceActions } from './actions'
import { AsyncActionMode, PAGE1 } from 'common/models'
import { DbServiceModel, DatabaseModel, DbUserModel, DatabasesPageState } from './models'

const RDB_SERVICE_INITIAL_STATE: DatabasesPageState = {
	isSavingService: false,
	isSavingDatabase: false,
	isSavingUser: false,
	isLoadingService: false,
	isLoadingDatabase: false,
	isLoadingUser: false,
	isLoadingLookup: false,
	lookup_database: [],
	lookup_host: [],
	service: undefined,
	service_databases: [],
	services: { items: [], pagination: PAGE1 },
	pager: PAGE1,
	database: undefined,
	databases: { items: [], pagination: PAGE1 },
	db_pager: PAGE1,
	user: undefined,
	users: [],
	servers: [],
	isLoadingServers: false,
	drivers: [],
	daemon: undefined,
	db_version: undefined,
	error: '',
}

export default (state = RDB_SERVICE_INITIAL_STATE, action: DbServiceActions): DatabasesPageState => {
	switch (action.type) {
		case ActionType.GET_DB_SERVICE:
			if (action.mode === AsyncActionMode.REQUEST) {
				return { ...state, daemon: undefined, db_version: undefined, isLoadingService: true }
			}
			if (action.mode === AsyncActionMode.RESPONSE) {
				return {
					...state,
					service: action.data,
					isLoadingService: false,
				}
			}
			if (action.mode === AsyncActionMode.ERROR) {
				return {
					...state,
					isLoadingService: false,
					error: action.error,
				}
			}
			break
		case ActionType.GET_DB_SERVICE_DEFAULT:
			if (action.mode === AsyncActionMode.REQUEST) {
				return { ...state, daemon: undefined, db_version: undefined, isLoadingService: true }
			}
			if (action.mode === AsyncActionMode.RESPONSE) {
				return {
					...state,
					service: action.data && action.data.length ? action.data[0] : undefined,
					isLoadingService: false,
				}
			}
			if (action.mode === AsyncActionMode.ERROR) {
				return {
					...state,
					isLoadingService: false,
					error: action.error,
				}
			}
			break

		case ActionType.GET_DB_SERVICES:
			if (action.mode === AsyncActionMode.REQUEST) {
				return { ...state, servers: [], users: [], lookup_host: [], lookup_database: [], isLoadingService: true }
			}
			if (action.mode === AsyncActionMode.RESPONSE) {
				return {
					...state,
					isLoadingService: false,
					service: undefined,
					services: action.data,
					pager: { ...action.data.pagination, page: action.data.pagination.page + 1 },
				}
			}
			if (action.mode === AsyncActionMode.ERROR) {
				return {
					...state,
					isLoadingService: false,
					error: action.error,
				}
			}
			break

		case ActionType.CREATE_DB_SERVICE:
			if (action.mode === AsyncActionMode.REQUEST) {
				return { ...state, isSavingService: true }
			}
			if (action.mode === AsyncActionMode.RESPONSE) {
				// add to first pos.
				let new_list = [...state.services.items] // force new obj
				new_list.unshift(action.data)
				return {
					...state,
					service: action.data,
					services: {
						...state.services,
						items: new_list,
					},
					isSavingService: false,
				}
			}
			if (action.mode === AsyncActionMode.ERROR) {
				return {
					...state,
					isSavingService: false,
					error: action.error,
				}
			}
			break

		case ActionType.UPDATE_DB_SERVICE:
			if (action.mode === AsyncActionMode.REQUEST) {
				return { ...state, isSavingService: true }
			}
			if (action.mode === AsyncActionMode.RESPONSE) {
				return {
					...state,
					service: action.data,
					services: {
						...state.services,
						items: state.services.items!.map((c) => (c.id === action.data.id ? { ...action.data } : c)),
					},
					isSavingService: false,
				}
			}
			if (action.mode === AsyncActionMode.ERROR) {
				return {
					...state,
					isSavingService: false,
					error: action.error,
				}
			}
			break

		case ActionType.DELETE_DB_SERVICE:
			if (action.mode === AsyncActionMode.REQUEST) {
				return { ...state, isSavingService: true }
			}
			if (action.mode === AsyncActionMode.RESPONSE) {
				return {
					...state,
					service: undefined,
					services: {
						...state.services,
						items: state.services.items!.filter((c) => c.id !== action.request.id),
					},
					isSavingService: false,
				}
			}
			if (action.mode === AsyncActionMode.ERROR) {
				return {
					...state,
					isSavingService: false,
					error: action.error,
				}
			}
			break

		case ActionType.GET_DATABASES:
			if (action.mode === AsyncActionMode.REQUEST) {
				return { ...state, database: undefined, daemon: undefined, db_version: undefined, isLoadingService: true }
			}
			if (action.mode === AsyncActionMode.RESPONSE) {
				return {
					...state,
					isLoadingService: false,
					databases: action.data,
					db_pager: { ...action.data.pagination, page: action.data.pagination.page + 1 },
				}
			}
			if (action.mode === AsyncActionMode.ERROR) {
				return {
					...state,
					isLoadingService: false,
					error: action.error,
				}
			}
			break
		case ActionType.GET_DATABASE:
			if (action.mode === AsyncActionMode.REQUEST) {
				return { ...state, db_password: undefined, isLoadingDatabase: true }
			}
			if (action.mode === AsyncActionMode.RESPONSE) {
				return {
					...state,
					isLoadingDatabase: false,
					database: action.data,
				}
			}
			if (action.mode === AsyncActionMode.ERROR) {
				return {
					...state,
					isLoadingDatabase: false,
					error: action.error,
				}
			}
			break
		case ActionType.CREATE_DATABASE:
			if (action.mode === AsyncActionMode.REQUEST) {
				return { ...state, db_password: undefined, isSavingDatabase: true }
			}
			if (action.mode === AsyncActionMode.RESPONSE) {
				return {
					...state,
					database: action.data,
					databases: {
						...state.databases,
						items: state.databases.items.concat(action.data),
					},
					isSavingDatabase: false,
				}
			}
			if (action.mode === AsyncActionMode.ERROR) {
				return {
					...state,
					isSavingDatabase: false,
					error: action.error,
				}
			}
			break
		case ActionType.GET_SERVICE_DATABASES:
			if (action.mode === AsyncActionMode.REQUEST) {
				return { ...state, service_databases: [], isLoadingService: true }
			}
			if (action.mode === AsyncActionMode.RESPONSE) {
				return {
					...state,
					isLoadingService: false,
					service_databases: action.data,
				}
			}
			if (action.mode === AsyncActionMode.ERROR) {
				return {
					...state,
					isLoadingService: false,
					error: action.error,
				}
			}
			break
		case ActionType.UPDATE_DB_PASSWORD:
			if (action.mode === AsyncActionMode.REQUEST) {
				return { ...state, isSavingDatabase: true }
			}
			if (action.mode === AsyncActionMode.RESPONSE) {
				return {
					...state,
					db_password: undefined, // there is separate call for password
					isSavingDatabase: false,
				}
			}
			if (action.mode === AsyncActionMode.ERROR) {
				return {
					...state,
					isSavingDatabase: false,
					error: action.error,
				}
			}
			break
		case ActionType.DELETE_DATABASE:
			if (action.mode === AsyncActionMode.REQUEST) {
				return { ...state, isSavingDatabase: true }
			}
			if (action.mode === AsyncActionMode.RESPONSE) {
				return {
					...state,
					database: undefined,
					databases: {
						...state.databases,
						items: state.databases.items!.filter((c) => c.id !== action.request.id),
					},
					isSavingDatabase: false,
				}
			}
			if (action.mode === AsyncActionMode.ERROR) {
				return {
					...state,
					isSavingDatabase: false,
					error: action.error,
				}
			}
			break

		case ActionType.GET_DAEMON:
			if (action.mode === AsyncActionMode.REQUEST) {
				return { ...state, daemon: undefined, error: undefined }
			}
			if (action.mode === AsyncActionMode.RESPONSE) {
				return {
					...state,
					daemon: action.data.deamon_version ? `${action.data.deamon_version}` : null,
				}
			}
			if (action.mode === AsyncActionMode.ERROR) {
				return {
					...state,
					daemon: null,
					error: action.error,
				}
			}
			break
		case ActionType.GET_DB_VERSION:
			if (action.mode === AsyncActionMode.REQUEST) {
				return { ...state, db_version: undefined, error: undefined }
			}
			if (action.mode === AsyncActionMode.RESPONSE) {
				return {
					...state,
					db_version: action.data.database_version ? `${action.data.database_version}` : null,
				}
			}
			if (action.mode === AsyncActionMode.ERROR) {
				return {
					...state,
					db_version: null,
					error: action.error,
				}
			}
			break
		case ActionType.GET_DB_PASSWORD:
			if (action.mode === AsyncActionMode.REQUEST) {
				return { ...state, db_password: undefined }
			}
			if (action.mode === AsyncActionMode.RESPONSE) {
				return {
					...state,
					db_password: action.data.password ? action.data.password : undefined,
				}
			}
			if (action.mode === AsyncActionMode.ERROR) {
				return {
					...state,
					db_password: undefined,
					error: action.error,
				}
			}
			break

		case ActionType.GET_DRIVERS:
			if (action.mode === AsyncActionMode.REQUEST) {
				return { ...state }
			}
			if (action.mode === AsyncActionMode.RESPONSE) {
				return {
					...state,
					drivers: action.data,
				}
			}
			if (action.mode === AsyncActionMode.ERROR) {
				return {
					...state,
					error: action.error,
				}
			}
			break

		case ActionType.LOOKUP_DATABASE:
			if (action.mode === AsyncActionMode.REQUEST) {
				return { ...state, lookup_database: [], isLoadingLookup: true }
			}
			if (action.mode === AsyncActionMode.RESPONSE) {
				if (action.field === 'name') {
					return {
						...state,
						lookup_database: action.data,
						isLoadingLookup: false,
					}
				}
				console.error('Wrong action field.')
			}
			if (action.mode === AsyncActionMode.ERROR) {
				return {
					...state,
					error: action.error,
					isLoadingLookup: false,
				}
			}
			break
		case ActionType.GET_SERVERS_RDB:
			if (action.mode === AsyncActionMode.REQUEST) {
				return { ...state, servers: [], isLoadingServers: true }
			}
			if (action.mode === AsyncActionMode.RESPONSE) {
				return {
					...state,
					servers: action.data,
					isLoadingServers: false,
				}
			}
			if (action.mode === AsyncActionMode.ERROR) {
				return {
					...state,
					isLoadingServers: false,
					error: action.error,
				}
			}
			break
		case ActionType.LOOKUP_RDB_SERVICE:
			if (action.mode === AsyncActionMode.REQUEST) {
				return { ...state, lookup_host: [], isLoadingLookup: true }
			}
			if (action.mode === AsyncActionMode.RESPONSE) {
				if (action.field === 'host') {
					return {
						...state,
						lookup_host: action.data,
						isLoadingLookup: false,
					}
				}
				console.error('Wrong action field.')
			}
			if (action.mode === AsyncActionMode.ERROR) {
				return {
					...state,
					error: action.error,
					isLoadingLookup: false,
				}
			}
			break
	}

	return state
}
