import { DomainExistsRequestAction, DomainExistsResponseAction, DomainExistsErrorAction } from './index'
import { protectedApiClient } from 'helpers/api'
import { handleApiError } from 'helpers/errorHandling'
import { ZisExistsResp } from 'pages/dns/service/models'


export default (domain: string, cb?: (isSuccess: boolean) => void) => {
	return (
		dispatch: (arg: DomainExistsRequestAction | DomainExistsResponseAction | DomainExistsErrorAction) => void
	) => {
		const request = new DomainExistsRequestAction()
		dispatch(request)

		protectedApiClient
			.get<ZisExistsResp>(`/domain/zis-exist?name=${domain}`)
			.then((response) => {
				dispatch(new DomainExistsResponseAction(request, response.data))
				cb && cb(true)
			})
			.catch((error) => {
				let msg = error.toString()
				dispatch(new DomainExistsErrorAction(request, msg))
				handleApiError(error)
				cb && cb(false)
			})
	}
}
