import { CheckMXRequestAction, CheckMXResponseAction, CheckMXErrorAction } from '.'
import { protectedApiClient } from 'helpers/api'
import { handleApiErrorWithNotification } from 'helpers/errorHandling'
import { MailboxModel } from '../models'

export default (domain_id: number, cb?: (isSuccess: boolean) => void) => {
	return (dispatch: (arg: CheckMXRequestAction | CheckMXResponseAction | CheckMXErrorAction) => void) => {
		const request = new CheckMXRequestAction(domain_id)
		dispatch(request)

		protectedApiClient
			.get<MailboxModel>(`/mail/domain/${domain_id}/dns-status`)
			.then((response) => {
				dispatch(new CheckMXResponseAction(request, response.data))
				cb && cb(true)
			})
			.catch((error) => {
				dispatch(new CheckMXErrorAction(request, error))
				handleApiErrorWithNotification(error)
				cb && cb(false)
			})
	}
}
