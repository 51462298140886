import {ActionType, CustomerActions} from './actions'
import {AsyncActionMode} from 'common/models'
import {ContactModel, CustomerDetails, CustomerState} from './models'
import {removeDiac} from "../../../common/fce";
import {CompanyDetails} from "../../company/models";


const CUSTOMER_INITIAL_STATE: CustomerState = {
  isLoading: false,
  isSaving: false,
  isDeleting: false,
  error: undefined,
  company: undefined,
  customers: [],
  contacts: [],
  contacts_all: [],
  customer_users: []
}


export default (
    state: CustomerState = CUSTOMER_INITIAL_STATE,
    action: CustomerActions,
): CustomerState => {

  function processContacts(data: ContactModel[]): ContactModel[] {
    // prepare data for search
    if (data && data.length > 0) {
      return data.map((c) => {
        let name = `${c.title},${c.email},${c.phone},${c.note}`
        c.searchableName = removeDiac(name)
        return c
      })
    }
    return []
  }

  switch (action.type) {
    case ActionType.GET_CUSTOMER:
      // dont use isLoading
      // it is used for customers
      if (action.mode === AsyncActionMode.REQUEST) {
        return { ...state, isLoading: true}
      }
      if (action.mode === AsyncActionMode.RESPONSE) {
        return {
          ...state,
          customer: action.data,
          customers: state.customers.map((c) =>
              c.id === action.data.id ? action.data : c,
          ),
          isLoading: false
        }
      }
      if (action.mode === AsyncActionMode.ERROR) {
        return {
          ...state,
          error: action.error,
          customer: undefined,
          isLoading: false
        }
      }
      break

    case ActionType.GET_CUSTOMERS:
      if (action.mode === AsyncActionMode.REQUEST) {
        return { ...state, isLoading: true }
      }
      if (action.mode === AsyncActionMode.RESPONSE) {
        return {
          ...state,
          isLoading: false,
          customers: action.data
        }
      }
      if (action.mode === AsyncActionMode.ERROR) {
        return {
          ...state,
          isLoading: false,
          error: action.error
        }
      }
      break

    case ActionType.CLEAR_CUSTOMER:
      return { ...state, isLoading: false, customer: undefined, customer_users: [] }
    case ActionType.CREATE_CUSTOMER:
      if (action.mode === AsyncActionMode.REQUEST) {
        return { ...state, isSaving: true }
      }
      if (action.mode === AsyncActionMode.RESPONSE) {
        return {
          ...state,
          customers: state.customers.concat(action.data),
          isSaving: false,
        }
      }
      if (action.mode === AsyncActionMode.ERROR) {
        return {
          ...state,
          isSaving: false,
          error: action.error,
        }
      }
      break
    case ActionType.DELETE_CUSTOMER:
      if (action.mode === AsyncActionMode.REQUEST) {
        return { ...state, isSaving: true }
      }
      if (action.mode === AsyncActionMode.RESPONSE) {
        return {
          ...state,
          customers: state.customers!.filter(
              (c) => c.id !== action.request.id,
          ),
          isSaving: false,
        }
      }
      if (action.mode === AsyncActionMode.ERROR) {
        return {
          ...state,
          isSaving: false,
          error: action.error,
        }
      }
      break
    case ActionType.UPDATE_CUSTOMER:
      if (action.mode === AsyncActionMode.REQUEST) {
        return { ...state, isSaving: true }
      }
      if (action.mode === AsyncActionMode.RESPONSE) {
        const cust = action.data
        cust.searchableName = removeDiac(cust.company?.name)
        return {
          ...state,
          isSaving: false,
          customers: state.customers.map((c) => c.id === action.data.id ? cust : c),
          customer: cust
        }
      }
      if (action.mode === AsyncActionMode.ERROR) {
        return {
          ...state,
          isSaving: false,
          error: action.error,
        }
      }
      break
    case ActionType.GET_CUSTOMER_USERS:
      if (action.mode === AsyncActionMode.REQUEST) {
        return {
          ...state,
          error: '',
          customer_users: []}
      }
      if (action.mode === AsyncActionMode.RESPONSE) {
        return {
          ...state,
          customer_users: action.data,
        }
      }
      if (action.mode === AsyncActionMode.ERROR) {
        return {
          ...state,
          error: action.error,
          customer_users: []
        }
      }
      break

    case ActionType.GET_COMPANY_CUSTOMER:
      if (action.mode === AsyncActionMode.REQUEST) {
        return { ...state, company: undefined}
      }
      if (action.mode === AsyncActionMode.RESPONSE) {
        return {...state, company: action.data}
      }
      if (action.mode === AsyncActionMode.ERROR) {
        return {...state, error: action.error}
      }
      break

    case ActionType.GET_CONTACTS:
      if (action.mode === AsyncActionMode.REQUEST) {
        return {
          ...state,
          error: '',
          isLoading: true,
          contacts: []}
      }
      if (action.mode === AsyncActionMode.RESPONSE) {
        return {
          ...state,
          isLoading: false,
          contacts: processContacts(action.data),
        }
      }
      if (action.mode === AsyncActionMode.ERROR) {
        return {
          ...state,
          isLoading: false,
          error: action.error,
        }
      }
      break

    case ActionType.GET_CONTACTS_ALL:
      if (action.mode === AsyncActionMode.REQUEST) {
        return {
          ...state,
          error: '',
          isLoading: true,
          contacts_all: []}
      }
      if (action.mode === AsyncActionMode.RESPONSE) {
        return {
          ...state,
          isLoading: false,
          contacts_all: processContacts(action.data),
        }
      }
      if (action.mode === AsyncActionMode.ERROR) {
        return {
          ...state,
          isLoading: false,
          error: action.error,
        }
      }
      break

    case ActionType.GET_CONTACT:
      if (action.mode === AsyncActionMode.REQUEST) {
        return {
          ...state,
          error: '',
          contact: undefined}
      }
      if (action.mode === AsyncActionMode.RESPONSE) {
        return {
          ...state,
          contact: action.data,
        }
      }
      if (action.mode === AsyncActionMode.ERROR) {
        return {
          ...state,
          error: action.error,
        }
      }
      break

    case ActionType.CREATE_CONTACT:
      if (action.mode === AsyncActionMode.REQUEST) {
        return { ...state, isSaving: true }
      }
      if (action.mode === AsyncActionMode.RESPONSE) {
        return {
          ...state,
          contacts: processContacts(state.contacts?.concat(action.data)),
          isSaving: false,
        }
      }
      if (action.mode === AsyncActionMode.ERROR) {
        return {
          ...state,
          isSaving: false,
          error: action.error,
        }
      }
      break

    case ActionType.UPDATE_CONTACT:
      if (action.mode === AsyncActionMode.REQUEST) {
        return { ...state, isSaving: true }
      }
      if (action.mode === AsyncActionMode.RESPONSE) {
        return {
          ...state,
          isSaving: false,
          contacts: processContacts(state.contacts.map((c) =>
              c.id === action.data.id ? { ...action.data } : c,
          ))
        }
      }
      if (action.mode === AsyncActionMode.ERROR) {
        return {
          ...state,
          isSaving: false,
          error: action.error,
        }
      }
      break

    case ActionType.DELETE_CONTACT:
      if (action.mode === AsyncActionMode.REQUEST) {
        return { ...state, isDeleting: true }
      }
      if (action.mode === AsyncActionMode.RESPONSE) {
        return {
          ...state,
          contacts: state.contacts!.filter(
              (c) => c.id !== action.request.id,
          ),
          isDeleting: false,
        }
      }
      if (action.mode === AsyncActionMode.ERROR) {
        return {
          ...state,
          isDeleting: false,
          error: action.error,
        }
      }
      break

    case ActionType.GET_SOFT_DELETE:
      if (action.mode === AsyncActionMode.REQUEST) {
        return { ...state, isSaving: true }
      }
      if (action.mode === AsyncActionMode.RESPONSE) {
        return {
          ...state,
          isSaving: false,
          soft_delete: action.data,
        }
      }
      if (action.mode === AsyncActionMode.ERROR) {
        return {
          ...state,
          isSaving: false,
          error: action.error,
          soft_delete: undefined
        }
      }
      break
    case ActionType.SET_TERMINATED:
      if (action.mode === AsyncActionMode.REQUEST) {
        return { ...state, isSaving: true }
      }
      if (action.mode === AsyncActionMode.RESPONSE) {
        const new_customers = state.customers.map((customer) =>
            customer.id === action.data.id ? { ...customer, terminated: action.data.terminated} : customer,
        )
        return {
          ...state,
          isSaving: false,
          customers: new_customers
        }
      }
      if (action.mode === AsyncActionMode.ERROR) {
        return {
          ...state,
          isSaving: false,
          error: action.error,
        }
      }
      break
    case ActionType.FLAG_UP:
      if (action.mode === AsyncActionMode.REQUEST) {
        return { ...state, isSaving: true }
      }
      if (action.mode === AsyncActionMode.RESPONSE) {
        return {
          ...state,
          isSaving: false,
          customers: state.customers.map((c) =>
              c.id === action.data.id ? { ...c, flag: action.data.flag } : c
          )
        }
      }
      if (action.mode === AsyncActionMode.ERROR) {
        return {
          ...state,
          isSaving: false,
          error: action.error,
        }
      }
      break
  }
  return state
}
