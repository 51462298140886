import {useTranslation} from "react-i18next";
import {AutoComplete, Spin} from "antd";
import React, {useEffect, useState} from "react";
import {ServerModel} from "./models";
import {useLoggedUser} from "../../helpers/loginUserHelper";
import {stopPropagation} from "../../common/fce";


interface Props {
    dataSource: ServerModel[]
    isLoading: boolean
    handleSelectedServer: (ip_id?: number) => void
    fetchData?: (searchText: string) => void
}


const ServerSelector = (props: Props) => {
    const {t} = useTranslation()

    const {dataSource, isLoading, fetchData, handleSelectedServer} = props
    const [searchServerLookup, setSearchServerLookup] = useState<string | undefined>()
    const [searchServer, setSearchServer] = useState<string | undefined>()
    const [serverOptions, setServerOptions] = useState<{ label: JSX.Element, key: string, value: string}[]>([])

    // get settings and logged user from store
    const loggedUser = useLoggedUser()
    if (!loggedUser || !loggedUser.isLoaded()) {
        return (<Spin/>)
    }
    const appSetting = loggedUser.getAppSettings()

    useEffect(() => {
        if(dataSource && dataSource.length) {
            // render CustomerOptions
            const data = dataSource.map((s) => {
                return {
                    value: `${s.id}`,
                    key: s.hostname,
                    label: <span>{s.hostname}</span>
                }
            })
            setServerOptions(data)
        }
        else {
            setServerOptions([])
        }
    }, [dataSource])

    const onClearServer = () => {
        setSearchServerLookup(undefined)
        setSearchServer(undefined)
        setServerOptions([])
        handleSelectedServer(undefined)
    }

    const onSelectServer = (data: string) => {
        const item = serverOptions.find(s => s.value === data)
        if (item) {
            // raise event IP Selected
            setSearchServer(item.key)
            setSearchServerLookup(item.key)
            handleSelectedServer(parseInt(item.value))
        }
    }

    const onChangeServerLookup = (searchText: string) => {
        const n = parseInt(searchText)
        if (n > 0) {
            return  // no numbers
        }
        if (!searchText) {
            if (searchServerLookup && searchServerLookup.length === 1) {
                setSearchServerLookup(undefined)
            }
            return
        }
        if (searchText != searchServerLookup){
            if (appSetting.checkMinSearch(searchText)) {
                if (searchText != searchServerLookup) {
                    console.log('fetchData: '+searchText)
                    fetchData && fetchData(searchText)
                }
            }
            setSearchServerLookup(searchText)
        }
    }

    return (
        <AutoComplete
            showSearch
            value={searchServer}
            options={serverOptions}
            style={{width: '100%'}}
            dropdownMatchSelectWidth={200}
            onInputKeyDown={(e) => {
                if (e.key === 'Enter') {
                    onSelectServer(e.currentTarget.value)
                }
            }}
            onSelect={onSelectServer}
            onChange={onChangeServerLookup}
            onClear={onClearServer}
            onClick={stopPropagation}
            notFoundContent={isLoading && <Spin />}
            filterOption={false}
            optionFilterProp='label'
            placeholder={t('serversPage.server')}
            allowClear={true}
        />
    )
}

export default ServerSelector
