import { AsyncActionMode } from 'common/models'
import { SettingsDetails, SettingsParams} from "../models";


export enum ActionType {
    GET_APP_SETTING = 'GET_APP_SETTING',
    GET_APP_SETTINGS2 = 'GET_APP_SETTINGS2',
    UPDATE_APP_SETTING2 = 'UPDATE_APP_SETTING2',
}

export type AppSettingsActions =
    | GetAppSettingRequestAction
    | GetAppSettingResponseAction
    | GetAppSettingErrorAction
    | GetAppSettingsRequestAction
    | GetAppSettingsResponseAction
    | GetAppSettingsErrorAction
    | UpdateAppSettingRequestAction
    | UpdateAppSettingResponseAction
    | UpdateAppSettingErrorAction


export class GetAppSettingRequestAction {
    readonly type = ActionType.GET_APP_SETTING
    readonly mode = AsyncActionMode.REQUEST
    constructor() {
        ''
    }
}
export class GetAppSettingResponseAction {
    readonly type = ActionType.GET_APP_SETTING
    readonly mode = AsyncActionMode.RESPONSE
    constructor(public request: GetAppSettingRequestAction, public data: SettingsDetails) {}
}

export class GetAppSettingErrorAction {
    readonly type = ActionType.GET_APP_SETTING
    readonly mode = AsyncActionMode.ERROR
    constructor(public request: GetAppSettingRequestAction, public error: string) {
        ''
    }
}

export class GetAppSettingsRequestAction {
    readonly type = ActionType.GET_APP_SETTINGS2
    readonly mode = AsyncActionMode.REQUEST
    constructor() {
        ''
    }
}
export class GetAppSettingsResponseAction {
    readonly type = ActionType.GET_APP_SETTINGS2
    readonly mode = AsyncActionMode.RESPONSE
    constructor(public request: GetAppSettingsRequestAction, public data: SettingsDetails[]) {}
}

export class GetAppSettingsErrorAction {
    readonly type = ActionType.GET_APP_SETTINGS2
    readonly mode = AsyncActionMode.ERROR
    constructor(public request: GetAppSettingsRequestAction, public error: string) {
        ''
    }
}

export class UpdateAppSettingRequestAction {
    readonly type = ActionType.UPDATE_APP_SETTING2
    readonly mode = AsyncActionMode.REQUEST
    constructor(public payload: SettingsParams) {}
}
export class UpdateAppSettingResponseAction {
    readonly type = ActionType.UPDATE_APP_SETTING2
    readonly mode = AsyncActionMode.RESPONSE
    constructor(
        public request: UpdateAppSettingRequestAction,
        public data: SettingsDetails,
    ) {}
}
export class UpdateAppSettingErrorAction {
    readonly type = ActionType.UPDATE_APP_SETTING2
    readonly mode = AsyncActionMode.ERROR
    constructor(
        public request: UpdateAppSettingRequestAction,
        public error: string,
    ) {}
}