import { NameItem } from 'common/models'
import {
	GetCertificatesVhostRequestAction,
	GetCertificatesVhostResponseAction,
	GetCertificatesVhostErrorAction,
} from './index'
import { protectedApiClient } from 'helpers/api'
import { handleApiErrorWithNotification } from 'helpers/errorHandling'

export default (cb?: (isSuccess: boolean) => void) => {
	return (
		dispatch: (
			arg: GetCertificatesVhostRequestAction | GetCertificatesVhostResponseAction | GetCertificatesVhostErrorAction
		) => void
	) => {
		const request = new GetCertificatesVhostRequestAction()
		dispatch(request)

		let url = `/certificate/certificate/list`
		protectedApiClient
			.get<NameItem[]>(url)
			.then((response) => {
				dispatch(new GetCertificatesVhostResponseAction(request, response.data))
				cb && cb(true)
			})
			.catch((error) => {
				dispatch(new GetCertificatesVhostErrorAction(request, error.toString()))
				handleApiErrorWithNotification(error)
				cb && cb(false)
			})
	}
}
