import {UpdateWebTemplateRequestAction, UpdateWebTemplateResponseAction, UpdateWebTemplateErrorAction} from './index'
import {protectedApiClient} from 'helpers/api'
import {handleApiErrorWithNotification} from 'helpers/errorHandling'
import {WebTemplateModel} from '../models'


export default (params: WebTemplateModel, cb?: (isSuccess: boolean) => void) => {
    return (dispatch: (arg: UpdateWebTemplateRequestAction | UpdateWebTemplateResponseAction | UpdateWebTemplateErrorAction) => void) => {
        const request = new UpdateWebTemplateRequestAction(params)
        dispatch(request)

        protectedApiClient
            .put<WebTemplateModel>(`/web/template/${params.id}?expand=apache_version,php_version`, params)
            .then((response) => {
                dispatch(new UpdateWebTemplateResponseAction(request, response.data))
                cb && cb(true)
            })
            .catch((error) => {
                dispatch(new UpdateWebTemplateErrorAction(request, error.toString()))
                handleApiErrorWithNotification(error)
                cb && cb(false)
            })
    }
}
