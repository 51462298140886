import {UpdateWebTemplateVersionsRequestAction, UpdateWebTemplateVersionsResponseAction, UpdateWebTemplateVersionsErrorAction} from './index'
import {protectedApiClient} from 'helpers/api'
import {handleApiErrorWithNotification} from 'helpers/errorHandling'
import {UpdateWebTemplateVersionsParams, VhostModel, WebTemplateModel} from '../models'


export default (params: UpdateWebTemplateVersionsParams, cb?: (isSuccess: boolean) => void) => {
    return (dispatch: (arg: UpdateWebTemplateVersionsRequestAction | UpdateWebTemplateVersionsResponseAction | UpdateWebTemplateVersionsErrorAction) => void) => {
        const request = new UpdateWebTemplateVersionsRequestAction(params)
        dispatch(request)

        protectedApiClient
            .put<WebTemplateModel>(`/web/template/${params.id}/version?expand=apache_version,php_version`, params)
            .then((response) => {
                dispatch(new UpdateWebTemplateVersionsResponseAction(request, response.data))
                cb && cb(true)
            })
            .catch((error) => {
                dispatch(new UpdateWebTemplateVersionsErrorAction(request, error.toString()))
                handleApiErrorWithNotification(error)
                cb && cb(false)
            })
    }
}
