import React, {useEffect, useState} from 'react'
import {Col, Form, Input, InputNumber, message, Radio, Row, Select, Space, Typography,} from 'antd'
import {useTranslation} from 'react-i18next'
import {AppState} from 'common/models'
import {useDispatch, useSelector} from 'react-redux'
import {formItemLayout, labelTopLayout} from 'helpers/layoutHelpers'
import Button from 'antd-button-color'
import DnsRecordArchivedDropdown from './DnsRecordArchivedDropdown'
import {useForm} from 'antd/es/form/Form'
import {AddDnsRecordParams} from "./models";
import getDnsZone from "./actions/getDnsZone";
import {onKeyPressOnlyNum} from "../../../common/fce";
import addDomainDnsRecord from "./actions/addDomainDnsRecord";
import {dnsRecordTypes, isValidRecord} from "./ZoneEditRecordForm";


interface Props {
    zone?: string
    name?: string
    digest?: string
    type?: string
    content?: string
    ttl?: number
    is_new?: Boolean
    closeModal: () => void
}

const {Item} = Form


interface RTOption {
    label: string
    value: string
    description: string
    rule: string
    disabled: boolean
}


const DnsRecordForm = (props: Props) => {
    const {t} = useTranslation()
    const dispatch = useDispatch()
    const [form] = useForm()

    const {dnsService, domain} = useSelector((state: AppState) => state.dnsservice)

    const [selectedType, setSelectedType] = useState<RTOption>()
    const [dnsRecordOptions, setDnsRecordOptions] = useState<RTOption[]>(dnsRecordTypes)

    useEffect(() => {
        if (!domain || domain.name != props.zone) {
            message.error('Wrong zone.')
            //props.closeModal()
            //return
        }

        if (!props.is_new && props.type) {
            setSelectedType(dnsRecordTypes.find(t => t.value === props.type?.toUpperCase()))
        }

    }, [])


    const handleSubmit = (values) => {
        console.log('form: ' + JSON.stringify(form.getFieldsValue()))
        if (!domain) {
            message.error('Missing domain.')
            return
        }
        if (!dnsService) {
            message.error('Missing DNS service.')
            return
        }
        let params: AddDnsRecordParams = {
            digest: values.digest,
            id: domain.id,
            zone: domain.name,
            name: values.name,
            type: values.type,
            content: values.content,
            ttl: values.ttl
        }
        if (selectedType && selectedType.value === 'MX') {
            params = {...params, content: `${values.priority} ${values.content}`}
        }
        dispatch(addDomainDnsRecord(params, suc => {
            if (suc) {
                if (!props.is_new && props.name && props.type) {
                    dispatch(getDnsZone(domain.id))
                    message.success(t('general.success'))
                    props.closeModal()
                }
                else {
                    dispatch(getDnsZone(domain.id))
                    message.success(t('general.success'))
                    props.closeModal()
                }
            }
        }))
    }

    return (
        <Form
            {...formItemLayout}
            form={form}
            onFinish={handleSubmit}
            initialValues={{
                domain: domain?.name,
                digest: props.digest,
                type: props.type,
                name: props.name,
                content: props.content,
                ttl: props.ttl ? props.ttl : 1800
            }}
        >
            <Item name='service_id' style={{display: 'none'}}>
                <Input type='hidden'/>
            </Item>
            <Item name='domain' style={{display: 'none'}} >
                <Input type='hidden' />
            </Item>
            <Item name='digest' style={{display: 'none'}} >
                <Input type='hidden' />
            </Item>

            <Row gutter={8}>
                <Col span={24}>
                    <Item name='type' label={t('dnsPage.dnsRecordForm.input_type')} rules={[{required: true}]}>
                        <Select optionFilterProp='label'
                                style={{width: '100px'}}
                                disabled={!props.is_new}
                                options={dnsRecordOptions}
                                onChange={ (value) => {
                                    setSelectedType(dnsRecordTypes.find(t => t.value === value.toUpperCase()))
                                }}
                        />
                    </Item>
                </Col>

                <Col span={24}>
                    <Item name='name'
                          rules={[{required: true}]}
                          label={t('dnsPage.dnsRecordForm.input_name')}>
                        <Input data-gramm="false" disabled={!props.is_new}/>
                    </Item>
                </Col>

                {
                    selectedType && selectedType.value === 'MX' && (
                        <Col span={24}>
                            <Item name='priority'
                                  rules={[{required: true}]}
                                  label={t('dnsPage.dnsRecordForm.priority')}>
                                <Input type='number' min={1} data-gramm="false" />
                            </Item>
                        </Col>
                    )
                }

                <Col span={24}>
                    <Item name='content'
                          rules={[
                              {
                                  required: true,
                                  message: `Invalid content: ${selectedType?.rule}`,
                                  validator: async (rule, value) => {
                                      const t = form.getFieldValue('type')
                                      const isValid = isValidRecord(t, value)
                                      if (!isValid) {
                                          throw new Error('Something wrong!');
                                      }
                                  }
                              },
                          ]}
                          label={t('dnsPage.dnsRecordForm.input_content')}
                    >
                        <Input disabled={selectedType && selectedType.value === 'SOA'}
                               placeholder={selectedType?.rule} data-gramm="false"/>
                    </Item>
                </Col>

                <Col span={24}>
                    <Item name='ttl'
                          rules={[{required: true}]}
                          label={t('dnsPage.dnsRecordForm.input_ttl')}
                    >
                        <InputNumber
                            min={1}
                            step={1}
                            onKeyPress={onKeyPressOnlyNum}
                            style={{width: '100%'}}
                            placeholder='3600'
                        />
                    </Item>
                </Col>

                <Col span={12}>

                    <DnsRecordArchivedDropdown
                        onSelect={(record) => form.setFieldsValue(record)}
                    />

                </Col>
                <Col span={12}>
                    <Item>
                        <Button type='primary' htmlType='submit'>
                            {props.is_new ? t('dnsPage.dnsRecordForm.button_create') : t('dnsPage.dnsRecordForm.button_update')}
                        </Button>
                    </Item>
                </Col>

            </Row>
        </Form>
    )
}

export default DnsRecordForm
