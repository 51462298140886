import { useTranslation } from "react-i18next"
import { AutoComplete, message, Popconfirm, Spin } from "antd"
import React, { useEffect, useState } from "react"
import { stopPropagation } from "../../../common/fce"
import { useLoggedUser } from "../../../helpers/loginUserHelper"
import { IpModel } from "./models"

interface Props {
    dataSource: IpModel[]
    isLoadingIps: boolean
    handleSelectedIp: (ip_id?: number) => void
    fetchData?: (searchText: string) => void
    shadowUsed?: boolean
    initValue?: string
}


const IpSelector = (props: Props) => {
    const { t } = useTranslation()

    const { dataSource, isLoadingIps, fetchData, handleSelectedIp } = props
    const [searchIpLookup, setSearchIpLookup] = useState<string | undefined>()
    const [searchIP, setSearchIP] = useState<string | undefined>()
    const [ipAddressesOptions, setIpAddressesOptions] = useState<{ label: JSX.Element, key: string, value: string }[]>([])

    // get settings and logged user from store
    const loggedUser = useLoggedUser()
    if (!loggedUser || !loggedUser.isLoaded()) {
        return (<Spin />)
    }
    const appSetting = loggedUser.getAppSettings()

    useEffect(() => {
        if (dataSource && dataSource.length) {
            // render CustomerOptions
            const data = dataSource.map((ip) => {
                if (ip.is_ipmi) {
                    if (ip.is_used) {
                        return {
                            value: `${ip.id}`,
                            key: ip.name,
                            label: (<span style={{ color: props.shadowUsed ? '#ccc' : '#000' }}>{ip.name} (used IPMI)</span>)
                        }
                    }
                    return {
                        value: `${ip.id}`,
                        key: ip.name,
                        label: (<span style={{ color: 'green' }}>{ip.name} (IPMI)</span>)
                    }
                }
                if (ip.is_used) {
                    return {
                        value: `${ip.id}`,
                        key: ip.name,
                        label: (<span style={{ color: props.shadowUsed ? '#ccc' : '#000' }}>{ip.name} (used)</span>)
                    }
                }
                else {
                    return {
                        value: `${ip.id}`,
                        key: ip.name,
                        label: <span style={{ color: 'blue' }}>{ip.name}</span>
                    }
                }
            })
            setIpAddressesOptions(data)
        }
        else {
            setIpAddressesOptions([])
        }
    }, [dataSource])

    const onClearIp = () => {
        setSearchIpLookup(undefined)
        setSearchIP(undefined)
        setIpAddressesOptions([])
        handleSelectedIp(undefined)
    }

    const onSelectIp = (data: string) => {
        const item = ipAddressesOptions.find(ip => ip.value === data)
        if (item) {
            // raise event IP Selected
            setSearchIP(item.key)
            setSearchIpLookup(item.key)
            handleSelectedIp(parseInt(item.value))
        }
    }

    const onChangeIpLookup = (searchText: string) => {
        if (!searchText) {
            if (searchIpLookup && searchIpLookup.length === 1) {
                setSearchIpLookup(undefined)
            }
            return
        }
        if (searchText != searchIpLookup) {
            if (checkMinSearch(searchText)) {
                if (searchText.replace('.', '') != searchIpLookup?.replace('.', '')) {
                    fetchData && fetchData(searchText)
                }
            }
            setSearchIpLookup(searchText)
        }
    }

    const checkMinSearch = (val: string | undefined) => {
        if (!val) {
            return false
        }
        return val.length > appSetting.min_search_length
    }

    return (
        <AutoComplete
            showSearch
            value={searchIP}
            defaultValue={props.initValue}
            options={ipAddressesOptions}
            style={{ width: '100%' }}
            dropdownMatchSelectWidth={200}
            onInputKeyDown={(e) => {
                if (e.key === 'Enter') {
                    onSelectIp(e.currentTarget.value)
                }
            }}
            onSelect={onSelectIp}
            onChange={onChangeIpLookup}
            onClear={onClearIp}
            onClick={stopPropagation}
            notFoundContent={isLoadingIps && <Spin />}
            filterOption={false}
            optionFilterProp='label'
            placeholder={t('serversPage.ip')}
            allowClear={true}
        />
    )
}

export default IpSelector
