import { useTranslation } from "react-i18next"
import { connect, useDispatch, useSelector } from "react-redux"
import { useHistory } from "react-router"
import { Link, useParams } from "react-router-dom"
import { AppState } from "../../common/models"
import React, { useEffect, useRef, useState } from "react"
import Draggable, { DraggableData, DraggableEvent } from "react-draggable"
import { useLoggedUser } from "../../helpers/loginUserHelper"
import { LoadingIndicator } from "../../components"
import ErrorPage403 from "../../components/Errors/ErrorPage403"
import useLogger from "../../common/useLogger"
import { AutoComplete, Card, Col, Divider, message, Modal, Row, Space, Spin, Table } from "antd"
import { ConsoleSqlOutlined, DeleteTwoTone, ExclamationCircleOutlined, SyncOutlined } from "@ant-design/icons"
import { GlobalOutlined, PlusCircleOutlined } from "@ant-design/icons/lib/icons"
import Button from "antd-button-color"
import HistoryLog from "../../components/History/HistoryLog"
import CreateVhostForm from "./CreateVhostForm"
import { getServiceUiType, renderService } from "../server/common"
import { VhostModel, WebServiceModel } from "./models"
import getWebService from "./actions/getWebService"
import getServiceVhosts from "./actions/getServiceVhosts"
import deleteWebService from "./actions/deleteWebService"
import getWebDaemon from "./actions/getWebDaemon"
import getPhpVersion from "./actions/getPhpVersion"
import getWebServerVersion from "./actions/getWebServerVersion"
import { removeDiac, stopPropagation } from "common/fce"
import Highlighter from "react-highlight-words"

const { confirm } = Modal

interface ParamTypes {
    service_id: string
}

interface Props {
    serviceId: number | undefined
    onClose: () => void
}

const renderServiceBox = (name: string) => {
    if (!name) {
        return ''
    }
    const box = getServiceUiType(name)
    if (box) {
        return (
            <Card title={renderService(name)} key={box.id.toString()} style={
                {
                    textAlign: 'center',
                    width: '220px',
                    margin: '10% 25%',
                    padding: '10px',
                    borderRadius: '8px',
                    border: `2px solid ${box.color}`,
                    backgroundColor: '#ededed'
                }}>
            </Card>
        )
    }
    return ''
}

const WebServiceDetailPage = (props: Props) => {
    const CONTROL_NAME = 'page_web_service_detail'
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const history = useHistory()
    const { service_id } = useParams<ParamTypes>()

    const { isLoadingService, service, service_vhosts,
        web_daemon, php_version, web_server_version, error } = useSelector((state: AppState) => state.webservice)

    const [vhostName, setVhostName] = useState<string>('')
    const [vhostSource, setVhostSource] = useState<VhostModel[]>([])
    const [isVhostModalVisible, setVhostModalVisible] = useState<boolean>(false)
    const [forceUpdateId, setForceUpdateId] = useState<number>(1)
    const [historyModelId, setHistoryModelId] = useState<number | undefined>()
    const [openDelete, setOpenDelete] = useState(false)
    const [confirmDelete, setConfirmDelete] = useState(false)
    const [connected, setConnected] = useState(false)

    const [isWebServiceViewer, setWebServiceViewer] = useState(false)
    const [isWebServiceDeleter, setWebServiceDeleter] = useState(false)
    const [isVhostCreator, setVhostCreator] = useState(false)

    // history drag modal
    const [disabled, setDisabled] = useState(true)
    const draggleRef = useRef<HTMLDivElement>(null)
    const [bounds, setBounds] = useState({ left: 0, top: 0, bottom: 0, right: 0 })
    const onStart = (_event: DraggableEvent, uiData: DraggableData) => {
        const { clientWidth, clientHeight } = window.document.documentElement
        const targetRect = draggleRef.current?.getBoundingClientRect()
        if (!targetRect) {
            return
        }
        setBounds({
            left: -targetRect.left + uiData.x,
            right: clientWidth - (targetRect.right - uiData.x),
            top: -targetRect.top + uiData.y,
            bottom: clientHeight - (targetRect.bottom - uiData.y),
        })
    }


    // get settings and current user
    const loggedUser = useLoggedUser()
    if (!loggedUser || !loggedUser.isLoaded()) {
        // waiting..
        return (
            <div className="fullwidth-loading" >
                <LoadingIndicator />
            </div>
        )
    }

    // required authorization
    if (!loggedUser.hasAccess(CONTROL_NAME)) {
        return <ErrorPage403 />
    }

    // settings
    const appSetting = loggedUser.getAppSettings()
    const SEARCH_MIN = appSetting.min_search_length

    const logger = useLogger(appSetting, 'WebServiceDetailPage')

    useEffect(() => {
        logger('service_id: ' + service_id)
        const access = loggedUser.hasAccess(CONTROL_NAME)
        setWebServiceViewer(access)

        if (!access) {
            // failover 403
            message.error('No permissions!')
            history.replace('/web/vhosts')
            return
        }

        setWebServiceDeleter(loggedUser.hasAccess('page_web_service_delete_button'))
        setVhostCreator(loggedUser.hasAccess('page_web_vhost_create_button'))

        if (parseInt(service_id) > 0) {
            // loadOptions()
            const sid = parseInt(service_id)
            dispatch(getWebService(sid))
        }
        else {
            // failover 404
            history.replace('/web/vhosts')
        }
    }, [])

    useEffect(() => {
        if (service && service.id === parseInt(service_id)) {
            checkServer()
            setHistoryModelId(service.id)
            dispatch(getServiceVhosts(service.id))
            loadHistory()
        }
    }, [service && service.id])

    useEffect(() => {
        if (service_vhosts) {
            loadHistory()
            renderVhosts()
        }
    }, [service_vhosts])

    useEffect(() => {
        renderVhosts()
    }, [vhostName])


    useEffect(() => {
        if (web_daemon && parseInt(web_daemon) > 0) {
            setConnected(true)
        }
    }, [web_daemon])

    const renderVhosts = () => {
        if (vhostName && appSetting.checkMinSearch(vhostName)) {
            setVhostSource(service_vhosts.filter(h => removeDiac(h.name).includes(removeDiac(vhostName))))
        }
        else {
            setVhostSource(service_vhosts)
        }
    }

    const checkServer = () => {
        dispatch(getWebDaemon(parseInt(service_id)))
        dispatch(getWebServerVersion(parseInt(service_id)))
        dispatch(getPhpVersion(parseInt(service_id)))
        loadHistory()
    }

    const loadHistory = () => {
        setForceUpdateId(forceUpdateId + 1)	// update history
    }

    const isDaemonReady = (): boolean => {
        return !!web_daemon && parseInt(web_daemon) > 0
    }

    const showConfirmDelete = (s: WebServiceModel, title: string) => {
        if (!s) {
            return
        }
        if (!isDaemonReady()) {
            message.error(t('webPage.daemon_not_ready'))
            return
        }

        // if (!isDeleter) {
        //     message.error(t('general.error403'))
        //     return
        // }
        confirm({
            icon: <ExclamationCircleOutlined style={{ color: 'red' }} />,
            title: title,
            content: <p>{s.host}</p>,
            okText: t('general.yes'),
            cancelText: t('general.cancel'),
            okButtonProps: { loading: confirmDelete },
            className: 'confirm-alert',
            onOk() {
                setConfirmDelete(true)
                dispatch(deleteWebService(s.id!, suc => {
                    setConfirmDelete(false)
                    setOpenDelete(false)
                    if (suc) {
                        message.success(t('general.success'))
                        history.replace('/web/vhosts')
                    }
                }))
            },
            onCancel() {
                setOpenDelete(false)
            },
        }
        )
    }

    const FilterByName = (
        <AutoComplete
            placeholder={t('webPage.vhosts.title')}
            style={{ width: '150px' }}
            value={vhostName}
            allowClear={true}
            onClick={stopPropagation}
            onKeyDown={(e) => { if (e.key === 'Enter') { e.stopPropagation() } }}
            onChange={(v) => {
                setVhostName(v)
            }}
        />
    )

    if (!appSetting || Object.keys(appSetting).length === 0) {
        return (<Spin />)
    }

    if (!service || !service.id) {
        return (<Spin />)
    }

    return (
        <>
            <Card
                title={<><ConsoleSqlOutlined /> &nbsp; {t('webPage.vhosts.web_service')} &nbsp; {service.host}</>}
                className='WebServiceDetailPage'
                loading={isLoadingService}
                extra={
                    <Button type='primary'
                        disabled={!service || !isVhostCreator}
                        onClick={() => setVhostModalVisible(true)}>
                        <PlusCircleOutlined /> {t('webPage.vhosts.new_vhost')}
                    </Button>
                }
            >

                <Row>
                    <Col xs={24} sm={24} md={24} lg={8} xl={8} className='pad4 center'>
                        &nbsp;{renderServiceBox('web')}
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={16} xl={16} className='pad4'>
                        <Row>
                            <Col span={6} className='right bold pad4' style={{ marginTop: '8px' }}><b>{t('webPage.vhosts.host')}</b>:</Col>
                            <Col span={18} className='pad4'><h2>{service.host}</h2></Col>
                        </Row>
                        <Row>
                            <Col span={6} className='right bold pad4'>id:</Col>
                            <Col span={18} className='pad4'>{service.id}</Col>
                        </Row>

                        <Row>
                            <Col span={6} className='right bold pad4'>{t('webPage.vhosts.server')}:</Col>
                            <Col span={18} className='pad4'><Link to={`/servers/edit/${service?.server_id}`}>{service?.host}</Link></Col>
                        </Row>

                        <Row>
                            <Col span={6} className='right bold pad4'>{t('webPage.vhosts.customer')}:</Col>
                            <Col span={18} className='pad4'>{service.customer?.name}</Col>
                        </Row>
                        <Row>
                            <Col span={6} className='right bold pad4'>{t('general.created')}:</Col>
                            <Col span={18} className='pad4'>{appSetting.renderDate(service.created_at)}</Col>
                        </Row>
                        <Row>
                            <Col span={6} className='right bold pad4'>AFW:</Col>
                            {
                                connected && (
                                    <Col span={18} className='pad4'>{service.has_afw ? t('general.yes') : t('general.no')}</Col>
                                )
                            }
                            {
                                !connected && (
                                    <Col span={18} className='pad4'>?</Col>
                                )
                            }

                        </Row>
                    </Col>
                </Row>
                <Row>
                    <Col span={4} className='right bold pad4'>{t('webPage.vhosts.daemon')}:</Col>
                    <Col span={20} className='left pad4'>
                        {(web_daemon && parseInt(web_daemon) > 0) && (
                            <Space>
                                <span className='text-success'>OK</span>
                                <span className='small'>version</span>
                                <b>{web_daemon}</b>
                            </Space>
                        )}
                        {(web_daemon === null) && (
                            <Space>
                                <span className='text-alert'>{error}</span>
                            </Space>
                        )}
                        {(web_daemon === undefined) && (
                            <Space>
                                <SyncOutlined spin color='blue' />
                            </Space>
                        )}
                    </Col>
                    <Col span={4} className='right bold pad4'>{t('webPage.vhosts.web_server_version')}:</Col>
                    <Col span={20} className='left pad4'>
                        {(web_server_version && web_server_version.length > 0) && (
                            <Space>
                                <span className='text-success'>OK</span>
                                <span className='small'>version</span>
                                <b>{web_server_version}</b>
                            </Space>
                        )}
                        {(web_server_version === null) && (
                            <Space>
                                <span className='text-alert'>{error}</span>
                            </Space>
                        )}
                        {(web_server_version === undefined) && (
                            <Space>
                                <SyncOutlined spin color='blue' />
                            </Space>
                        )}
                    </Col>
                    <Col span={4} className='right bold pad4'>{t('webPage.vhosts.php_version')}:</Col>
                    <Col span={20} className='left pad4'>
                        {(php_version && parseInt(php_version) > 0) && (
                            <Space>
                                <span className='text-success'>OK</span>
                                <span className='small'>version</span>
                                <b>{php_version}</b>
                            </Space>
                        )}
                        {(php_version === null) && (
                            <Space>
                                <span className='text-alert'>{error}</span>
                            </Space>
                        )}
                        {(php_version === undefined) && (
                            <Space>
                                <SyncOutlined spin color='blue' />
                            </Space>
                        )}
                    </Col>
                    <Col span={4} className='right bold pad4'></Col>
                    <Col span={20} className='left pad4'>
                        <Button size='small' onClick={() => checkServer()}>Check state</Button>
                    </Col>
                </Row>

                <Divider />

                <Row>
                    <Col span={8} style={{ padding: '15px' }}>
                        <Table<VhostModel>
                            columns={[
                                {
                                    title: FilterByName,
                                    dataIndex: 'name',
                                    key: 'name',
                                    width: 200,
                                    align: 'left',
                                    render: (text: string, rec: VhostModel) => (
                                        <Link to={`/web/vhost/${rec.id}`}>
                                            <Highlighter
                                                highlightStyle={{
                                                    backgroundColor: '#ffc069',
                                                    color: '#2d9259',
                                                    padding: 0,
                                                }}
                                                searchWords={[vhostName]}
                                                autoEscape
                                                textToHighlight={text.toString()}
                                            />
                                        </Link>
                                    ),
                                }]}
                            dataSource={vhostSource}
                            scroll={{ x: 'max-content' }}
                            rowKey='id'
                            bordered={true}
                            className='WebServiceVhostsTable'
                            style={{ width: '100%' }}
                            loading={isLoadingService}
                            showHeader={true}
                            size='small'
                            pagination={false}
                        />
                    </Col>
                    <Col span={16} style={{ padding: '15px' }}>
                        <HistoryLog service='web'
                            model='Service'
                            modelId={historyModelId}
                            forceUpdateId={forceUpdateId}
                            isModal={false}
                            showTitle={true} />
                    </Col>
                </Row>

                <Row>
                    <Col span={24} className='pad8'>
                        <Divider />
                        <Button danger size='small'
                            disabled={!isWebServiceDeleter}
                            style={{ float: 'right' }}
                            onClick={() => {
                                showConfirmDelete(service, t('webPage.vhosts.confirm_delete_service'))
                            }}
                            icon={<DeleteTwoTone twoToneColor='red' />}>{t('webPage.vhosts.btn_delete_service')}</Button>

                    </Col>
                </Row>
            </Card>

            <Modal title={
                <div style={{ width: '100%', cursor: 'move' }}
                    onMouseOver={() => { if (disabled) { setDisabled(false) } }}
                    onMouseOut={() => { setDisabled(true) }}
                    onFocus={() => { }}
                    onBlur={() => { }}
                >
                    <GlobalOutlined /> &nbsp; {t('webPage.vhosts.new_vhost')}
                </div>
            }
                destroyOnClose
                style={{ top: 20 }}
                width={700}
                visible={isVhostModalVisible}
                onCancel={() => setVhostModalVisible(false)}
                modalRender={(modal) => (
                    <Draggable disabled={disabled} bounds={bounds} onStart={(ev, data) => onStart(ev, data)}>
                        <div ref={draggleRef}>{modal}</div>
                    </Draggable>
                )}
                footer={null}
                confirmLoading={true}
            >
                <CreateVhostForm onClose={() => {
                    setVhostModalVisible(false)
                    loadHistory()
                }}
                />
            </Modal>
        </>
    )
}

export default WebServiceDetailPage
