import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { useForm } from "antd/lib/form/Form"
import { Button, Col, Form, Input, message, Modal, Row, Spin } from "antd"
import { Store } from "antd/lib/form/interface"
import { _isValidDomain } from "common/fce"
import { AppState } from "common/models"
import createAlias from "./actions/createAlias"
import { AliasParams } from "./models"

interface Props {
  onClose: () => void
}


const NewAliasForm = (props: Props) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [form] = useForm()

  const { vhost } = useSelector((state: AppState) => state.webservice)

  const handleSubmit = (values: Store) => {
    if (!vhost) {
      return
    }

    const params: AliasParams = { vhost_id: vhost.id, name: values.name }
    dispatch(createAlias(params, (suc: boolean) => {
      if (suc) {
        message.success(t('general.success'))
        form.resetFields()
        props.onClose()
      }
    }))
  }

  if (!vhost) {
    return <Spin />
  }

  return (
    <Row justify='space-between' style={{ marginTop: '0' }}>
      <Col span={24} className='center' >
        <Form
          form={form}
          layout='horizontal'
          onFinish={handleSubmit}
        >
          <Form.Item
            name="name"
            label='Alias'
            rules={[{ required: true, message: t('errors.field_required') },
            () => ({
              validator(_, value) {
                if (_isValidDomain(value)) {
                  return Promise.resolve()
                }
                return Promise.reject(new Error(t('domainPage.invalid_domain')))
              },
            })
            ]}
          >
            <Input data-gramm="false"
              data-gramm_editor="false"
              data-enable-grammarly="false" />
          </Form.Item>

          <Form.Item className="text-right" style={{ marginTop: 8 }}>
            <Button type="default" onClick={props.onClose} style={{ marginRight: 8 }}>
              {t('general.cancel')}
            </Button>
            <Button type="primary" htmlType="submit">
              {t('general.create')}
            </Button>
          </Form.Item>
        </Form>
      </Col>
    </Row>
  )
}

export default NewAliasForm
