import { PreviewConfigRequestAction, PreviewConfigResponseAction, PreviewConfigErrorAction } from './index'
import { protectedApiClient } from 'helpers/api'
import { handleApiErrorWithNotification } from 'helpers/errorHandling'
import { VhostModel } from '../models'


export default (id: number, cb?: (isSuccess: boolean) => void) => {
	return (dispatch: (arg: PreviewConfigRequestAction | PreviewConfigResponseAction | PreviewConfigErrorAction) => void) => {
		const request = new PreviewConfigRequestAction(id)
		dispatch(request)

		protectedApiClient
			.get<string>(`/web/vhost/${id}/preview-config`)
			.then((response) => {
				dispatch(new PreviewConfigResponseAction(request, response.data))
				cb && cb(true)
			})
			.catch((error) => {
				dispatch(new PreviewConfigErrorAction(request, error.toString()))
				handleApiErrorWithNotification(error)
				cb && cb(false)
			})
	}
}
