import { CreateWebServiceRequestAction, CreateWebServiceResponseAction, CreateWebServiceErrorAction } from './index'
import { protectedApiClient } from 'helpers/api'
import { handleApiErrorWithNotification } from 'helpers/errorHandling'
import { CreateWebServiceParams, WebServiceModel } from '../models'

export default (params: CreateWebServiceParams, cb?: (suc: boolean, id?: number) => void) => {
	return (
		dispatch: (
			arg: CreateWebServiceRequestAction | CreateWebServiceResponseAction | CreateWebServiceErrorAction
		) => void
	) => {
		const request = new CreateWebServiceRequestAction(params)
		dispatch(request)
		protectedApiClient
			.post<WebServiceModel>(`/server/server/${params.server_id}/web`, params)
			.then((response) => {
				dispatch(new CreateWebServiceResponseAction(request, response.data))
				cb && cb(true, response.data.id)
			})
			.catch((error) => {
				dispatch(new CreateWebServiceErrorAction(request, error.toString()))
				handleApiErrorWithNotification(error)
				cb && cb(false)
			})
	}
}
