import { DeleteMailServiceRequestAction, DeleteMailServiceResponseAction, DeleteMailServiceErrorAction } from '.'
import { protectedApiClient } from 'helpers/api'
import { handleApiErrorWithNotification } from 'helpers/errorHandling'

export default (id: number, cb?: (isSuccess: boolean) => void) => {
	return (
		dispatch: (
			arg: DeleteMailServiceRequestAction | DeleteMailServiceResponseAction | DeleteMailServiceErrorAction
		) => void
	) => {
		const request = new DeleteMailServiceRequestAction(id)
		dispatch(request)

		protectedApiClient
			.delete(`/mail/service/${id}`)
			.then((response) => {
				dispatch(new DeleteMailServiceResponseAction(request))
				cb && cb(true)
			})
			.catch((error) => {
				let msg = error.toString()
				if (parseInt(error.response.status) > 399) {
					msg = 'ERROR HTTP ' + error.response.status
				}
				dispatch(new DeleteMailServiceErrorAction(request, msg))
				handleApiErrorWithNotification(error)
				cb && cb(false)
			})
	}
}
