import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { AppState } from "../../common/models"
import React, { useEffect, useRef, useState } from "react"
import { LogRecord } from "../../pages/logviewer/models"
import { LoadingIndicator } from "../index"
import { AutoComplete, Button, Card, Empty, Popover, Space, Table, Tag } from "antd"
import {
    getLogColorById, getModulColorByShort, getShortModulName,
    getZisColorById,
    getZisLevelById,
} from "../../common/enums"
import { ColumnsType } from "antd/lib/table"
import { ReloadOutlined, UpSquareOutlined } from "@ant-design/icons"
import './HistoryLog.scss'
import { _renderFullDateTime, removeDiac, stopPropagation } from "../../common/fce"
import getHistoryDetails from "./actions/getHistoryDetails"
import getHistoryLogs from "./actions/getHistoryLogs"
import LoggedUser from "common/LoggedUser"
import { useLoggedUser } from "helpers/loginUserHelper"


interface Props {
    isModal?: boolean
    showTitle?: boolean
    title?: string
    setModalVisible?: (visible: boolean) => void
    service?: string
    model?: string
    modelId?: number
    forceUpdateId?: number   // force update after saving item (just increment it to update)
}

const getDetail = (rec: LogRecord) => {
    return (
        <pre>
            {JSON.stringify(rec, null, 2)}
        </pre>
    )
}

const HistoryLog = (props: Props) => {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const isMounted = useRef(false)

    const { records, entries, isLoading, isLoadingDetails } = useSelector((state: AppState) => state.historylog)

    const [dataSource, setDataSource] = useState<LogRecord[]>([])
    const [expandedRequestId, setExpandedRequestId] = useState<string>('')
    const [historyTitle, setHistoryTitle] = useState('')
    const [triggerOneTimer, setTriggerOneTimer] = useState<number>(0)
    const [searchHistory, setSearchHistory] = useState<string>('')
    const [fetched, setFetched] = useState(false)

    // get settings and current user
    const loggedUser = useLoggedUser()
    if (!loggedUser || !loggedUser.isLoaded()) {
        // waiting..
        return (
            <div className="fullwidth-loading" >
                <LoadingIndicator />
            </div>
        )
    }
    const appSetting = loggedUser.getAppSettings()

    useEffect(() => {
        if (props.isModal) {
            fetchHistory()
        }

        isMounted.current = true
        return () => { isMounted.current = false }
        // setTimeout(() => {fetchHistory()}, 1000);
    }, [])

    useEffect(() => {
        if (isMounted.current && props.forceUpdateId) {
            setTimeout(() => { fetchHistory() }, 1000)
        }
    }, [props.forceUpdateId])


    useEffect(() => {
        if (entries && triggerOneTimer > 0) {
            fetched && renderData()
        }
    }, [entries])

    useEffect(() => {
        fetched && renderData()
    }, [searchHistory, fetched])

    const renderData = () => {
        if (searchHistory && appSetting.checkMinSearch(searchHistory)) {
            setDataSource(entries.filter(rec => (removeDiac(rec.message).includes(removeDiac(searchHistory)) && !!rec.zis_request_id)).map((item) => { return { ...item, key: item.zis_request_id } }))
        }
        else {
            setDataSource(entries.filter(rec => !!rec.zis_request_id).map((item) => { return { ...item, key: item.zis_request_id } }))
        }
    }

    useEffect(() => {
        if (entries && expandedRequestId) {
            dispatch(getHistoryDetails(expandedRequestId))
        }
    }, [expandedRequestId])

    const fetchHistory = () => {
        if (isHistoryReady()) {
            setTriggerOneTimer(Date.now().valueOf())
            setHistoryTitle(`${props.service} / ${props.model} / ${props.modelId}`)
            props.service && props.model && props.modelId &&
                dispatch(getHistoryLogs(props.service, props.model, props.modelId, '', suc => { setFetched(true) }))
        }
    }

    const isHistoryReady = (): boolean => {
        return Boolean(props.service && props.model && props.modelId && props.modelId > 0)
    }

    const FilterByName = (
        <AutoComplete
            placeholder={t('history.message')}
            style={{ width: '150px' }}
            value={searchHistory}
            allowClear={true}
            onClick={stopPropagation}
            onKeyDown={(e) => { if (e.key === 'Enter') { e.stopPropagation() } }}
            onChange={(v) => {
                setSearchHistory(v)
            }}
        />
    )

    const historyColumns: ColumnsType<LogRecord> = [
        {
            title: t('history.date'),
            dataIndex: 'timestamp',
            key: 'timestamp',
            width: 180,
            className: 'left',
            fixed: 'left',
            render: (_, record: LogRecord) => (<span className='text-small noWrap'>{_renderFullDateTime(_).replace(' ', '\u00a0')}</span>)
        },
        {
            title: t('history.user'),
            dataIndex: 'username',
            key: 'username',
            className: 'left',
            width: 100,
            fixed: 'left',
            render: (_, rec: LogRecord) => rec.user ? (<div className='text-small noWrap'>{rec.user.name}</div>) : (<div className='text-small noWrap'>{rec.username}</div>)
        },
        {
            title: FilterByName,
            dataIndex: 'message',
            key: 'message',
            width: '8000px',
            className: 'msg-col',
            render: (_, record: LogRecord) => (
                <div className='msg-box text-small noWrap'>
                    {record.message}
                </div>
            )
        }
    ]

    const expandedRowRender = (parent: LogRecord, i) => {
        return <Table columns={subColumns}
            dataSource={records.filter(rec => rec.zis_request_id === parent.zis_request_id)}
            rowKey='id'
            loading={isLoadingDetails}
            pagination={false}
            className='HistorySubLogTable' />
    }

    const subColumns: ColumnsType<LogRecord> = [
        {
            title: t('logViewer.service'),
            dataIndex: 'service_name',
            key: 'service_name',
            width: 80,
            className: 'center',
            fixed: 'left',
            render: (_, record: LogRecord) => (<Tag style={{ width: '80px', textAlign: 'center' }} color={getModulColorByShort(getShortModulName(_))}>{getShortModulName(_)}</Tag>)
        },
        {
            title: t('history.level'),
            dataIndex: 'zis_level',
            key: 'zis_level',
            className: 'tdLevel',
            width: 80,
            fixed: 'left',
            render: (_, record: LogRecord) => (
                <span style={{ fontWeight: 'bold', color: getZisColorById(record.zis_level) }}>
                    {getZisLevelById(record.zis_level).toUpperCase()}
                </span>
            )
        },
        {
            title: t('history.message'),
            dataIndex: 'message',
            key: 'message',
            className: 'msg-col2',
            render: (_, record: LogRecord) => (
                <div className='msg-box2' style={{ color: getLogColorById(record.level) }}>
                    <Popover content={getDetail(record)} title="Message" trigger="click">
                        <UpSquareOutlined style={{ marginRight: '4px' }} />{record.message}
                    </Popover>
                </div>)
        },
    ]


    return (
        <Card title={props.showTitle && (
            <div>
                <span>{t('history.title')}: {props.title}</span>
                <Button title={t('general.refresh')}
                    className='reloadBtn' style={{ marginLeft: '35px' }}
                    onClick={() => { fetchHistory() }}
                    disabled={!isHistoryReady()}
                    loading={isLoading}
                    type='text' size='small'><ReloadOutlined />{t('general.refresh')}</Button>
            </div>
        )}
            className='histLogCard'>

            <Table<LogRecord>
                rowClassName={() => 'highlight'}
                rowKey={'id'}
                bordered={true}
                size="small"
                scroll={{ y: 450 }}
                columns={historyColumns}
                expandable={{ expandedRowRender }}
                onExpand={(expanded, record) => {
                    if (record.zis_request_id && expanded) {
                        setExpandedRequestId(record.zis_request_id)
                    }
                }}
                expandRowByClick={true}
                loading={isLoading} // isLoading
                dataSource={dataSource}
                className={props.isModal ? 'HistoryLogTable' : 'HistoryLogPanel'}
                pagination={false}
                footer={() => (<span>{t('history.total')}: {dataSource.length}</span>)}
            />

        </Card>
    )

}

export default HistoryLog
