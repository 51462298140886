import { Col, Form, Input, message, Result, Row, Select, Spin, Switch, Tag, Timeline } from "antd"
import { useForm } from "antd/lib/form/Form"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { useHistory } from "react-router"
import { AppState, NameItem } from "../../common/models"
import React, { useEffect, useState } from "react"
import { useLoggedUser } from "../../helpers/loginUserHelper"
import tableCustomers from "../login/actions/tableCustomers"
import { sort_label, sort_name } from "../../common/sorting"
import { Store } from "antd/lib/form/interface"
import { formItemLayout, tailLayout } from "../../helpers/layoutHelpers"
import { _isValidDomain, removeDiac, stopPropagation } from "../../common/fce"
import Button from "antd-button-color"
import { CheckOutlined, CloseCircleOutlined, CloseOutlined, SyncOutlined } from "@ant-design/icons"
import createVhost from "./actions/createVhost"
import { CreateVhostParams } from "./models"
import getServiceTemplates from "./actions/getServiceTemplates"
import getWebServiceDefault from "./actions/getWebServiceDefault"
import DomainSelector from "pages/dns/service/DomainSelector"
import getDomains from "./actions/getDomains"
import getDomainStatus from "./actions/getDomainStatus"
import domainExists from "./actions/domainExists"
import { Link } from "react-router-dom"
import getMailServices from "./actions/getMailServices"
import getDnsServices from "./actions/getDnsServices"
import getRdbServices from "./actions/getRdbServices"
import { CreateDomainParam } from "pages/dns/service/models"
import createDomainVhost from "./actions/createDomainVhost"
import { CreateDatabaseDomainParams } from "pages/database/models"
import createDatabaseVhost from "./actions/createDatabaseVhost"
import getWebDaemon from "./actions/getWebDaemon"
import getServiceVhosts from "./actions/getServiceVhosts"
import getWebServices from "./actions/getWebServices"
import getWebService from "./actions/getWebService"
import createMailDomain from "pages/mail/actions/createMailDomain"
import { CreateMailDomainParams } from "pages/mail/models"
import { hasDnsService } from "pages/dns/service/common"

interface Props {
    onClose: () => void
}

const { Item } = Form


const CreateVhostForm = (props: Props) => {
    const [form] = useForm()
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const history = useHistory()

    const { customers } = useSelector((state: AppState) => state.auth.tables)
    const { vhost, isSavingVhost, service, service_templates, error, web_daemon, domains, isLoadingDomains, fqdn_web_services,
        fqdn_domain_status, fqdn_zis_exists, fqdn_dns_services, fqdn_rdb_services, fqdn_mail_services, fqdn_domain, fqdn_database
    } = useSelector((state: AppState) => state.webservice)

    const [domainOptions, setDomainOptions] = useState<NameItem[]>([])
    const [customerOptions, setCustomerOptions] = useState<{ label: string, value: number, key: number }[]>([])
    const [serviceTemplateOptions, setServiceTemplateOptions] = useState<{ label: string, value: number, key: number }[]>([])
    const [createDns, setCreateDns] = useState<boolean>(false)
    const [createDb, setCreateDb] = useState<boolean>(false)
    const [createMail, setCreateMail] = useState<boolean>(false)
    const [createWeb, setCreateWeb] = useState<boolean>(false)
    const [updateStatus, setUpdateStatus] = useState<boolean>(false)
    const [selectedDomainId, setSelectedDomainId] = useState<number>()
    const [selectedCustomerId, setSelectedCustomerId] = useState<number>()
    const [domainStatus, setDomainStatus] = useState<string>("")
    const [fqdn, setFqdn] = useState<string>("")
    const [defaultDnsServiceName, setDefaultDnsServiceName] = useState<string>("")
    const [defaultRdbServiceName, setDefaultRdbServiceName] = useState<string>("")
    const [defaultMailServiceName, setDefaultMailServiceName] = useState<string>("")
    const [defaultWebServiceName, setDefaultWebServiceName] = useState<string>("")
    const [defaultDnsServiceId, setDefaultDnsServiceId] = useState<number>(0)
    const [defaultRdbServiceId, setDefaultRdbServiceId] = useState<number>(0)
    const [defaultMailServiceId, setDefaultMailServiceId] = useState<number>(0)
    const [defaultWebServiceId, setDefaultWebServiceId] = useState<number>(0)
    const [webServerOptions, setWebServerOptions] = useState<{ label: string, value: number, key: number }[]>([])
    const [dnsServiceOptions, setDnsServiceOptions] = useState<{ label: string, value: number, key: number }[]>([])
    const [rdbServiceOptions, setRdbServiceOptions] = useState<{ label: string, value: number, key: number }[]>([])
    const [mailServiceOptions, setMailServiceOptions] = useState<{ label: string, value: number, key: number }[]>([])
    const [domainState, setDomainState] = useState(false)
    const [dnsState, setDnsState] = useState(false)
    const [vhostState, setVhostState] = useState(false)
    const [databaseState, setDatabaseState] = useState(false)
    const [mailState, setMailState] = useState(false)

    const [domainStateCompleted, setDomainStateCompleted] = useState(false)
    const [dnsStateCompleted, setDnsStateCompleted] = useState(false)
    const [vhostStateCompleted, setVhostStateCompleted] = useState(false)
    const [databaseStateCompleted, setDatabaseStateCompleted] = useState(false)
    const [mailStateCompleted, setMailStateCompleted] = useState(false)

    const [mailDomainId, setMailDomainId] = useState<number>()
    const [isVhostCreator, setVhostCreator] = useState(false)

    const PAGE_FORM = 0
    const PAGE_LOADING = 1
    const PAGE_SUCCESS = 2
    const PAGE_ERROR = 3
    const [pageState, setPageState] = useState<number>(PAGE_FORM)

    // get settings and logged user from store
    const loggedUser = useLoggedUser()
    if (!loggedUser || !loggedUser.isLoaded()) {
        return (<Spin />)
    }
    const appSetting = loggedUser.getAppSettings()
    const isConnected = () => web_daemon && web_daemon.length > 0

    useEffect(() => {
        // once
        setVhostCreator(loggedUser.hasAccess('page_web_vhost_create_button'))
        if (service && service.id) {
            // it is called from WebServerDetailPage
            isConnected() && dispatch(getServiceTemplates(service.id))
        }
        else {
            // it is called from VhostsPage
            const settingWeb = appSetting.getSettingItem('web_service_id')
            if (settingWeb) {
                dispatch(getWebServiceDefault(settingWeb.value))
            }
        }
        if (!customers || customers.length === 0) {
            dispatch(tableCustomers())
        }
        setSelectedCustomerId(loggedUser.user.customer_id)
        dispatch(getDnsServices())
        dispatch(getRdbServices())
        dispatch(getMailServices())
        if (loggedUser.user.is_zcom) {
            dispatch(getWebServices())
        }
        const settingDns = appSetting.getSettingItem('dns_service_id')
        if (settingDns) {
            setDefaultDnsServiceName(settingDns.value.toLowerCase())
        }
        const settingRdb = appSetting.getSettingItem('rdb_service_id')
        if (settingRdb) {
            setDefaultRdbServiceName(settingRdb.value.toLowerCase())
        }
        const settingMail = appSetting.getSettingItem('mail_service_id')
        if (settingMail) {
            setDefaultMailServiceName(settingMail.value.toLowerCase())
        }
        const settingWeb = appSetting.getSettingItem('web_service_id')
        if (settingWeb) {
            setDefaultWebServiceName(settingWeb.value.toLowerCase())
        }
    }, [])

    useEffect(() => {
        if (service && service.id) {
            if (!isConnected()) {
                dispatch(getWebDaemon(service.id))
            }
        }
    }, [service])

    useEffect(() => {
        if (service && service.id) {
            if (isConnected()) {
                dispatch(getServiceTemplates(service.id))
            }
        }
    }, [web_daemon])

    useEffect(() => {
        if (customers) {
            const data = customers.map((c) => {
                return {
                    value: c.id,
                    key: c.id,
                    label: c.company!.name
                }
            })
            setCustomerOptions(data.sort(sort_label))
        }
    }, [customers])

    useEffect(() => {
        if (fqdn_dns_services && fqdn_dns_services.length > 0) {
            const defaultService = fqdn_dns_services.find(s => s.name && s.name.toLowerCase() === defaultDnsServiceName)
            if (defaultService) {
                setDefaultDnsServiceId(defaultService.id)
            }
            setDnsServiceOptions(fqdn_dns_services.map(s => ({ label: s.name, value: s.id, key: s.id })))
            if (defaultService) {
                form.setFieldsValue({ dns_service: defaultService.id })
            }
        }
    }, [fqdn_dns_services])

    useEffect(() => {
        if (fqdn_rdb_services && fqdn_rdb_services.length > 0) {
            const defaultService = fqdn_rdb_services.find(s => s.name && s.name.toLowerCase() === defaultRdbServiceName)
            if (defaultService) {
                setDefaultRdbServiceId(defaultService.id)
            }
            setRdbServiceOptions(fqdn_rdb_services.map(s => ({ label: s.name, value: s.id, key: s.id })))
            console.log(`defaultRdbServiceId: ` + defaultRdbServiceId)
            if (defaultService) {
                form.setFieldsValue({ rdb_service: defaultService.id })
            }
        }
    }, [fqdn_rdb_services])

    useEffect(() => {
        if (fqdn_mail_services && fqdn_mail_services.length > 0) {
            const defaultService = fqdn_mail_services.find(s => s.name && s.name.toLowerCase() === defaultMailServiceName)
            if (defaultService) {
                setDefaultMailServiceId(defaultService.id)
            }
            setMailServiceOptions(fqdn_mail_services.map(s => ({ label: s.name, value: s.id, key: s.id })))
            if (defaultService) {
                form.setFieldsValue({ mail_service: defaultService.id })
            }
        }
    }, [fqdn_mail_services])

    useEffect(() => {
        if (fqdn_web_services && fqdn_web_services.length > 0) {
            const defaultService = fqdn_web_services.find(s => s.name && s.name.toLowerCase() === defaultWebServiceName)
            if (defaultService) {
                setDefaultWebServiceId(defaultService.id)
            }
            setWebServerOptions(fqdn_web_services.map(s => ({ label: s.name, value: s.id, key: s.id })))
            if (loggedUser.user.is_zcom && defaultService) {
                form.setFieldsValue({ service_id: defaultService.id })
            }
        }
    }, [fqdn_web_services])

    useEffect(() => {
        if (isConnected() && service_templates) {
            setServiceTemplateOptions(
                service_templates.map(t => ({ label: t.name, value: t.id, key: t.id }))
            )
        }
    }, [service_templates])

    useEffect(() => {
        if (domains) {
            const data = domains.filter(d => d.customer_id === selectedCustomerId).map((c) => {
                return {
                    id: c.id,
                    name: c.name,
                }
            })
            console.log('domains: ' + JSON.stringify(domains))
            console.log('setDomainOptions: ' + JSON.stringify(data))
            console.log('selectedCustomerId: ' + selectedCustomerId)
            setDomainOptions(data.sort(sort_name))
        }
    }, [domains])

    useEffect(() => {
        fqdn_domain && setDnsState(hasDnsService(fqdn_domain))
    }, [fqdn_domain])


    const handleSelectedDomain = (name: string, sid?: number) => {
        console.log('setSelectedDomainId: ' + sid)
        if (sid) {
            setSelectedDomainId(sid)
            form.setFieldsValue([{ domain_id: sid }])
            form.setFieldsValue([{ name: name }])
        }
        else {
            setSelectedDomainId(undefined)
            form.setFieldsValue([{ domain_id: undefined }])
            form.setFieldsValue([{ name: undefined }])
        }

        if (_isValidDomain(name)) {
            form.setFieldsValue([{ name: name }])
            setFqdn(name)
            dispatch(domainExists(name))
            dispatch(getDomainStatus(name))
        }
        console.log('Form: ' + JSON.stringify(form.getFieldsValue()))
    }

    const domainIsValid = () => {
        return _isValidDomain(fqdn)
    }

    const loadDomainLookup = (pattern: string) => {
        if (appSetting.checkMinSearch(pattern)) {
            dispatch(getDomains(pattern))
        }
    }

    const isDomainRegistered = () => {
        return fqdn_domain_status?.ns && fqdn_domain_status.ns.length > 0
    }

    const getDomainMessage = () => {
        if (!domainIsValid()) {
            return ""
        }
        let msg = "Domena v databázi neexistuje."
        if (fqdn_zis_exists) {
            msg = "Domena již v databázi existuje."
        }

        let msg0 = "Domena je volná v DNS."
        if (isDomainRegistered()) {
            msg0 = `Domena je zaregistrovaná na ${fqdn_domain_status!.ns![0]}`
        }

        let msg1 = "Domena nesměřuje na ZCOM servery."
        if (fqdn_domain_status?.is_zcom === 1) {
            msg1 = "Domena směřuje na ZCOM servery."
        }
        return (
            <div>
                <div><Tag color={!fqdn_zis_exists ? "success" : "warning"}>{msg}</Tag></div>
                <div><Tag color={(!isDomainRegistered()) ? "success" : "warning"}>{msg0}</Tag></div>
                {
                    isDomainRegistered() && (<div><Tag color={(fqdn_domain_status?.is_zcom === 1) ? "success" : "warning"}>{msg1}</Tag></div>)
                }
                {(fqdn_domain_status?.is_zcom === 1 && fqdn_domain_status?.servers?.length > 0) && <div><Link style={{ fontSize: '0.8em' }} to={`/server/server/${fqdn_domain_status.servers[0].id}`}>{fqdn_domain_status.servers[0].name}</Link></div>}
            </div>
        )
    }

    const onFormSubmit = (values: Store) => {
        // validations
        if (!values.customer_id) {
            message.error('Customer is required')
            return
        }
        if (!values.template_id) {
            message.error('Template is required')
            return
        }
        if (!fqdn) {
            message.error('Domain name is required')
            return
        }

        let service_id = 0
        if (!loggedUser.user.is_zcom) {
            // customer
            if (!service || !service.id) {
                message.error('Web service is required')
                return
            }
            service_id = service.id
        }

        if (loggedUser.user.is_zcom) {
            // zcom
            if (!values.service_id) {
                message.error('Web service is required')
                return
            }
            service_id = values.service_id
        }
        setCreateWeb(true)
        const params: CreateVhostParams = {
            service_id: service_id,
            template_id: values.template_id,
            customer_id: values.customer_id,
            domain_id: selectedDomainId ? selectedDomainId : null,
            fqdn: fqdn,
            create_dns: createDns,
            create_rdb: createDb,
            create_mail: createMail,
            dns_service: values.dns_service,
            rdb_service: values.rdb_service,
            mail_service: values.mail_service
        }
        handleSubmit(params)
    }

    const handleSubmit = (data: CreateVhostParams) => {
        console.log(`handleSubmit`)

        setPageState(PAGE_LOADING)
        console.log(`params: ` + JSON.stringify(data))
        console.log(`state: ${fqdn_zis_exists}, ${selectedDomainId}, ${JSON.stringify(fqdn_domain_status)}`)
        if (!data.customer_id) {
            message.error('Customer is required')
            return
        }
        if (data.domain_id) {
            // domain exists
            // create vhost
            setDomainState(true)
            setDomainStateCompleted(true)
            createVhostAsync(data.domain_id, data.service_id, data.template_id)
            createAdditionalServices(data.domain_id, data.rdb_service, data.mail_service)
        }
        else {
            // domain not exists
            const params3: CreateDomainParam = {
                name: fqdn,
                id: data.customer_id,
                nameserver_id: (createDns && data.dns_service) ? data.dns_service : undefined
            }
            dispatch(createDomainVhost(params3, (suc, did) => {
                setDnsStateCompleted(true)
                setDomainStateCompleted(true)
                if (suc && did) {
                    createVhostAsync(did, data.service_id, data.template_id)
                    createAdditionalServices(did, data.rdb_service, data.mail_service)
                }
                setDomainState(suc)
            }))
        }
    }

    const createAdditionalServices = (domainId: number, rdbServideId?: number, mailServiceId?: number) => {
        if (createDb) {
            if (!rdbServideId) {
                message.error('rdbServideId is missing')
                return
            }
            createDatabaseAsync(domainId, rdbServideId)
        }
        if (createMail) {
            if (!mailServiceId) {
                message.error('mailServiceId is missing')
                return
            }
            createMailAsync(domainId, mailServiceId)
        }
    }

    const createVhostAsync = (domainId: number, serviceId: number, templateId: number) => {
        const params2: CreateVhostParams = {
            domain_id: domainId,
            fqdn: fqdn,
            service_id: serviceId,
            template_id: templateId
        }
        dispatch(createVhost(params2, (suc) => {
            setVhostStateCompleted(true)
            setVhostState(suc)
            // service && service.id && dispatch(getServiceVhosts(service.id))
        }))
    }

    const createDatabaseAsync = (domainId: number, serviceId: number) => {
        const params2: CreateDatabaseDomainParams = {
            domain_id: domainId,
            service_id: serviceId,
            name: fqdn
        }
        dispatch(createDatabaseVhost(params2, (suc) => {
            setDatabaseStateCompleted(true)
            setDatabaseState(suc)
        }))
    }

    const createMailAsync = (domainId: number, serviceId: number) => {
        const params2: CreateMailDomainParams = {
            domain_id: domainId,
            service_id: serviceId,
            use_quota: 0
        }
        dispatch(createMailDomain(params2, (suc, id) => {
            setMailStateCompleted(true)
            setMailState(suc)
            setMailDomainId(id)
        }))
    }

    if (!appSetting || Object.keys(appSetting).length === 0) {
        return (<Spin />)
    }

    if (!service || !service.id) {
        return (<Spin />)
    }

    if (pageState === PAGE_FORM) {
        // form
        return (
            <Form
                onFinish={onFormSubmit}
                form={form}
                onChange={() => setUpdateStatus(true)}
                initialValues={{
                    customer_id: loggedUser.user.customer_id,
                    name: '',
                    service_name: service.host,
                    dns_service: defaultDnsServiceId,
                    rdb_service: defaultRdbServiceId,
                    mail_service: defaultMailServiceId
                }}
                {...formItemLayout}
            >

                <Row style={{ marginTop: '8px' }}>
                    <Col span={8} className='right pad4'>{t('webPage.vhosts.web_service')}</Col>
                    <Col span={16}>

                        {
                            !loggedUser.user.is_zcom && (
                                <Form.Item name='service_name'>
                                    <Input disabled />
                                </Form.Item>
                            )
                        }

                        {
                            loggedUser.user.is_zcom && (
                                <Form.Item name='service_id' rules={[{ required: true }]}>
                                    <Select
                                        showSearch
                                        filterOption={(val, opt) => {
                                            return !!opt && removeDiac(opt.label).includes(removeDiac(val))
                                        }}
                                        optionFilterProp='label'
                                        options={webServerOptions}
                                        onChange={(sid) => { sid && dispatch(getWebService(sid)) }}
                                        placeholder='web server'
                                        style={{ width: '100%' }}
                                    >
                                    </Select>
                                </Form.Item>
                            )
                        }
                    </Col>
                </Row>

                <Row style={{ marginTop: '8px' }}>
                    <Col span={8} className='right pad4'>{t('webPage.vhosts.customer')}</Col>
                    <Col span={16}>
                        <Form.Item name='customer_id' rules={[{ required: true }]}>
                            <Select
                                showSearch
                                filterOption={(val, opt) => {
                                    return !!opt && removeDiac(opt.label).includes(removeDiac(val))
                                }}
                                optionFilterProp='label'
                                options={customerOptions}
                                onChange={cid => setSelectedCustomerId(cid)}
                                disabled={!loggedUser.user.is_zcom}
                                placeholder={t('webPage.vhosts.customer')}
                                style={{ width: '100%' }}
                            >
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>


                <Row style={{ marginTop: '8px' }}>
                    <Col span={8} className='right pad4'>{t('webPage.vhosts.fqdn')}</Col>
                    <Col span={16}>
                        <Form.Item name='name'
                        // rules={[{ required: true, message: t('errors.field_required') },
                        // () => ({
                        //     validator(_, value) {
                        //         if (_isValidDomain(value)) {
                        //             return Promise.resolve()
                        //         }
                        //         return Promise.reject(new Error(t('domainPage.invalid_domain')))
                        //     },
                        // })
                        // ]}
                        >

                            <DomainSelector
                                dataSource={domainOptions}
                                isLoading={isLoadingDomains}
                                handleSelectedDomain={handleSelectedDomain}
                                fetchData={loadDomainLookup}
                                handleStatus={(status) => { setDomainStatus(status) }}
                            />

                        </Form.Item>
                    </Col>
                </Row>

                <Row>
                    <Col span={8} className='right pad4'></Col>
                    <Col span={16}>{getDomainMessage()}</Col>
                </Row>


                <Row style={{ marginTop: '8px' }}>
                    <Col span={8} className='right pad4'>{t('webPage.vhosts.template')}</Col>
                    <Col span={16}>
                        <Form.Item rules={[{ required: true, message: t('errors.field_required') }]} name='template_id'>
                            {
                                !isConnected() && (<Input value='Web server is not connected' style={{ backgroundColor: '#eeeeee', color: '#aaaaaa' }} status="error" readOnly={true} />)
                            }
                            {
                                isConnected() && (
                                    <Select
                                        showSearch
                                        optionFilterProp='label'
                                        options={serviceTemplateOptions}
                                        placeholder={t('webPage.vhosts.template')}
                                        style={{ width: '100%' }}
                                        onChange={(v) => {
                                            form.setFieldsValue({ template_id: v })
                                        }}
                                    >
                                    </Select>
                                )
                            }
                        </Form.Item>
                    </Col>
                </Row>

                {
                    (!fqdn_zis_exists && fqdn_domain_status?.ns && fqdn_domain_status.ns.length === 0) && (
                        <Row style={{ marginTop: '8px' }}>
                            <Col span={8} className='right pad4'>{t('webPage.vhosts.create_dns')}</Col>
                            <Col span={4}>
                                <Form.Item name='dns' valuePropName='checked'  >
                                    <Switch
                                        checkedChildren={<CheckOutlined />}
                                        unCheckedChildren={<CloseOutlined />}
                                        checked={createDns}
                                        onChange={() => {
                                            setCreateDns(!createDns)
                                        }}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item name='dns_service' >
                                    <Select
                                        showSearch
                                        filterOption={(val, opt) => {
                                            return !!opt && removeDiac(opt.label).includes(removeDiac(val))
                                        }}
                                        optionFilterProp='label'
                                        options={dnsServiceOptions}
                                        disabled={!createDns}
                                        placeholder={t('webPage.vhosts.dns_service')}
                                        style={{ width: '100%', fontSize: '0.8em', border: 0 }}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                    )
                }

                <Row style={{ marginTop: '8px' }}>
                    <Col span={8} className='right pad4'>{t('webPage.vhosts.database')}</Col>
                    <Col span={4}>
                        <Form.Item name='rdb' valuePropName='checked'>
                            <Switch
                                checkedChildren={<CheckOutlined />}
                                unCheckedChildren={<CloseOutlined />}
                                checked={createDb}
                                onChange={() => {
                                    setCreateDb(!createDb)
                                }}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item name='rdb_service' >
                            <Select
                                showSearch
                                filterOption={(val, opt) => {
                                    return !!opt && removeDiac(opt.label).includes(removeDiac(val))
                                }}
                                optionFilterProp='label'
                                options={rdbServiceOptions}
                                disabled={!createDb}
                                placeholder={t('webPage.vhosts.rdb_service')}
                                style={{ width: '100%', fontSize: '0.8em', border: 0 }}
                            />
                        </Form.Item>
                    </Col>
                </Row>

                <Row style={{ marginTop: '8px' }}>
                    <Col span={8} className='right pad4'>{t('webPage.vhosts.mail')}</Col>
                    <Col span={4}>
                        <Form.Item name='mail' valuePropName='checked' >
                            <Switch
                                checkedChildren={<CheckOutlined />}
                                unCheckedChildren={<CloseOutlined />}
                                checked={createMail}
                                onChange={() => {
                                    setCreateMail(!createMail)
                                }}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item name='mail_service' >
                            <Select
                                showSearch
                                filterOption={(val, opt) => {
                                    return !!opt && removeDiac(opt.label).includes(removeDiac(val))
                                }}
                                optionFilterProp='label'
                                options={mailServiceOptions}
                                disabled={!createMail}
                                placeholder={t('webPage.vhosts.mail_service')}
                                style={{ width: '100%', fontSize: '0.8em' }}
                            />
                        </Form.Item>
                    </Col>
                </Row>

                <Row style={{ marginTop: '8px' }}>
                    <Col span={24}>
                        <Form.Item {...tailLayout}>
                            <Button type='primary'
                                loading={isSavingVhost}
                                disabled={!updateStatus || !isConnected()}
                                htmlType='submit'>
                                {t('general.create')}
                            </Button>
                        </Form.Item>
                    </Col>
                </Row>

                <Row style={{ marginTop: '8px' }}>
                    <Col span={8}></Col>
                    <Col span={16}>
                        {!isConnected() && <Tag color="error">Web server is not connected</Tag>}
                    </Col>
                </Row>
            </Form>
        )
    }

    if (pageState === PAGE_LOADING) {
        // loading
        return (
            <Row>
                <Col span={24} className='left'>{t('webPage.vhosts.server_communication')} <b>{service.host}</b></Col>
                <Col span={24} className='center'>&nbsp;</Col>
                <Col span={24} className='left'>
                    <Timeline>

                        <Timeline.Item color={domainStateCompleted ? (domainState ? 'green' : 'red') : 'blue'}>{t('webPage.vhosts.domain')}: {fqdn} {domainState}</Timeline.Item>

                        {
                            createDns && (<Timeline.Item color={dnsStateCompleted ? (dnsState ? 'green' : 'red') : 'blue'}>{t('webPage.vhosts.create_dns')}: {dnsStateCompleted ? (dnsState ? 'OK' : 'Failed') : 'Pending'}</Timeline.Item>)
                        }

                        {
                            createWeb && (<Timeline.Item color={vhostStateCompleted ? (vhostState ? 'green' : 'red') : 'blue'}>Vhost: {vhostStateCompleted ? (vhostState ? <span>OK&nbsp;&nbsp;<Link to={`/web/vhost/${vhost && vhost.id}`}>{vhost && vhost.name}</Link></span> : 'Failed') : 'Pending'}</Timeline.Item>)
                        }

                        {
                            createDb && (<Timeline.Item color={databaseStateCompleted ? (databaseState ? 'green' : 'red') : 'blue'}>{t('webPage.vhosts.database')}: {databaseStateCompleted ? (databaseState ? <span>OK&nbsp;&nbsp;<Link to={`/rdb/database/${fqdn_database && fqdn_database.id}`}>{fqdn_database && fqdn_database.name}</Link></span> : 'Failed') : 'Pending'}</Timeline.Item>)
                        }

                        {
                            createMail && (<Timeline.Item color={mailStateCompleted ? (mailState ? 'green' : 'red') : 'blue'}>{t('webPage.vhosts.mail_domain')}: {mailStateCompleted ? (mailState ? <span>OK&nbsp;&nbsp;<Link to={`/mail/domain/${mailDomainId}`}>{fqdn_domain && fqdn_domain.name}</Link></span> : 'Failed') : 'Pending'}</Timeline.Item>)
                        }
                    </Timeline>
                </Col>

                <Col span={24} style={{ marginTop: '8px', textAlign: 'center' }}>
                    <Button type='primary' onClick={() => { history.push(`/web/vhost/${vhost && vhost.id}`); props.onClose() }}>
                        {t('general.close')}
                    </Button>
                </Col>

            </Row>
        )
    }

    if (pageState === PAGE_SUCCESS) {
        // result
        return (
            <Result
                status="success"
                style={{ padding: 0 }}
                title={
                    <p style={{ fontSize: '16px' }}>
                        New database was created.{t('webPage.database_created')} <br />
                    </p>
                }
                subTitle={
                    <Row className="desc">
                        <Col span={12} className='right pad4'>Host:</Col>
                        <Col span={12} className='left pad4'><span style={{ fontFamily: 'monospace' }}><Tag>{service.host}</Tag></span></Col>
                        <Col span={12} className='right pad4'>Database:</Col>
                        <Col span={12} className='left pad4'><span style={{ fontFamily: 'monospace' }}><Tag>{vhost?.name}</Tag></span></Col>
                        <Col span={12} className='right pad4'>Username:</Col>
                        <Col span={12} className='left pad4'><span style={{ fontFamily: 'monospace' }}><Tag>{vhost?.name}</Tag></span></Col>
                    </Row>
                }
                extra={[
                    <Button onClick={() => { props.onClose() }}>{t('general.close')}</Button>
                ]}
            />
        )
    }

    if (pageState === PAGE_ERROR) {
        // result
        console.error(JSON.stringify(error))
        return (
            <Row>
                <Col span={24} className='center'>Error in communication with server <b>{service.host}</b></Col>
                <Col span={24} className='center'>&nbsp;</Col>
                <Col span={24} className='center'><Tag icon={<CloseCircleOutlined />} color="error">{error}</Tag></Col>
                <Col span={24} className='center'>&nbsp;</Col>
                <Col span={24} className='center'><Button onClick={() => { props.onClose() }}>{t('general.close')}</Button></Col>
            </Row>
        )
    }
    return <div>Error state</div>
}

export default CreateVhostForm
