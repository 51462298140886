import {Col, Form, Input, message, Row, Spin} from "antd";
import {useForm} from "antd/lib/form/Form";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import React, {useEffect, useState} from "react";
import {formItemSlim4} from "../../helpers/layoutHelpers";
import {AppState} from "../../common/models";
import {Store} from "antd/lib/form/interface";
import Button from "antd-button-color";
import TextArea from "antd/lib/input/TextArea";
import {useHistory} from "react-router";
import {CreateWebTemplateParams} from "./models";
import createWebTemplate from "./actions/createWebTemplate";


interface Props {
    onClose: () => void
}

const {Item} = Form


const CreateWebTemplateForm = (props: Props) => {
    const [form] = useForm()
    const {t} = useTranslation()
    const dispatch = useDispatch()
    const history = useHistory()

    const {isSavingTemplate} = useSelector((state: AppState) => state.webservice)

    const [updateStatus, setUpdateStatus] = useState<boolean>(false)


    useEffect(() => {
        // once

    }, [])

    const onFormSubmit = (values: Store) => {
        if (values.name) {
            const params: CreateWebTemplateParams = {
                name: values.name,
                code: values.code,
                comment: values.comment
            }
            dispatch(createWebTemplate(params, (suc, id) => {
                message.success(t('general.success'))
                props.onClose()
            }))
        }
    }

    return (
        <Form
            onFinish={onFormSubmit}
            form={form}
            onChange={() => setUpdateStatus(true)}
            initialValues={{name:'', comment: '', code: ''}}
            {...formItemSlim4}
        >

            <Item label={t('webPage.templates.name')} rules={[{required: true}]} name='name'>
                <Input />
            </Item>

            <Item label={t('webPage.templates.comment')} name='comment'>
                <TextArea rows={3}
                          data-gramm="false"
                          data-gramm_editor="false"
                          data-enable-grammarly="false"  />
            </Item>

            <Item label={t('webPage.templates.code')} rules={[{required: true}]} name='code'>
                <TextArea rows={20}
                          data-gramm="false"
                          data-gramm_editor="false"
                          data-enable-grammarly="false"  />
            </Item>

            <Row style={{marginTop: '35px'}}>
                <Col span={4} className='pad4'></Col>
                <Col span={20} className='pad4'>
                    <Button type='primary'
                            loading={isSavingTemplate}
                            disabled={!updateStatus}
                            htmlType='submit'>
                        {t('general.save')}
                    </Button>
                </Col>
            </Row>
        </Form>
    )
}

export default CreateWebTemplateForm
