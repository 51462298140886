import {Tag, Tooltip} from "antd";
import {AppstoreAddOutlined, BlockOutlined, FolderTwoTone, TrophyOutlined, UserOutlined} from "@ant-design/icons";
import React, {useEffect} from "react";
import {EmptyFunction} from "../../common/fce";
import {RoleModel} from "./roles/models";


export const replResource = (res: string) => {
    return res.toString().replace('app\\models\\', '')
}

export const renderRole = (name: string, selected: boolean, clicked: EmptyFunction | undefined) => {
    if (clicked) {
        return (<Tag icon={<TrophyOutlined twoToneColor='gold' />}
                     color={ selected ? 'blue' : 'gold'}
                     key={name}
                     title={name}
                     className='tag-role'
                     onClick={clicked}
                     style={{fontSize: '1em'}}>{name}</Tag>)
    }
    else {
        return (<Tag icon={<TrophyOutlined twoToneColor='gold' />}
                     color={ selected ? 'blue' : 'gold'}
                     key={name}
                     title={name}
                     style={{fontSize: '1em', cursor: 'default'}}>{name}</Tag>)
    }
}

export const renderRoleModel = (role: RoleModel, selected: boolean, clicked: EmptyFunction | undefined) => {
    if (clicked) {
        return (
            <Tooltip title={role.description ? role.description : ''}>
                <Tag icon={<TrophyOutlined twoToneColor='gold' />}
                     color={ selected ? 'blue' : 'gold'}
                     key={role.name}
                     title={role.name}
                     className='tag-role'
                     onClick={clicked}
                     style={{fontSize: '1em'}}>{role.name}
                </Tag>
            </Tooltip>)
    }
    else {
        return (
            <Tooltip title={role.description ? role.description : ''}>
                <Tag icon={<TrophyOutlined twoToneColor='gold' />}
                     color={ selected ? 'blue' : 'gold'}
                     key={role.name}
                     title={role.name}
                     style={{fontSize: '1em', cursor: 'default'}}>{role.name}
                </Tag>
            </Tooltip>)
    }
}

export const renderResGroup = (res: string, customer: string, selected: boolean, clicked: EmptyFunction | undefined) => {
    if (clicked) {
        return (<Tag icon={<AppstoreAddOutlined twoToneColor='orange' />}
                     color={ selected ? 'blue' : (customer.toLowerCase() === 'zcom' ? 'green' : 'orange')}
                     className='tag-res-group'
                     title={customer}
                     key={replResource(res)}
                     onClick={clicked}
                     style={{fontSize: '1em'}}>{replResource(res)}</Tag>)
    }
    else {
        return (<Tag icon={<AppstoreAddOutlined twoToneColor='orange' />}
                     color={ selected ? 'blue' : (customer.toLowerCase() === 'zcom' ? 'green' : 'orange')}
                     title={customer}
                     key={replResource(res)}
                     style={{fontSize: '1em', cursor: 'default'}}>{replResource(res)}</Tag>)
    }
}

export const renderUserGroup = (res: string, customer: string, selected: boolean) => {
    return (<Tag icon={<BlockOutlined twoToneColor='geekblue' />}
                 color={ selected ? 'blue' : (customer && customer.toLowerCase() === 'zcom' ? 'geekblue' : 'geekblue')}
                 title={customer}
                 key={res + customer}
                 style={{fontSize: '1em'}}>{res}</Tag>)
}

export const renderUser = (res: string, customer: string, selected: boolean, clicked: EmptyFunction | undefined) => {
    if (clicked) {
        return (<Tag icon={<UserOutlined twoToneColor='gray' />}
                     color={ selected ? 'blue' : ''}
                     className='tag-user'
                     title={customer}
                     key={res + customer}
                     onClick={clicked}
                     style={{fontSize: '1em'}}>{res}</Tag>)
    }
    else {
        return (<Tag icon={<UserOutlined twoToneColor='gray' />}
                     color={ selected ? 'blue' : ''}
                     className='tag-user'
                     title={customer}
                     key={res + customer}
                     style={{fontSize: '1em'}}>{res}</Tag>)
    }

}

export const renderResource = (res: string) => {
    return (<Tag icon={<FolderTwoTone twoToneColor='orange' />}
    color='orange'
    key={replResource(res)}
    style={{fontSize: '1em'}}>{replResource(res)}</Tag>)
}

export const renderID = (res: string) => {
    return (<Tag color='orange'
                 key={replResource(res)}
                 style={{fontSize: '0.9em'}}>{res}</Tag>)
}

export const renderSelected = (res: string) => {
    return (<Tag color='blue' key={res} style={{fontSize: '1em', border: 0}}>{res}</Tag>)
}

export const getMethodColor = (method: string) => {
    switch (method.toLowerCase()) {
        case 'get': return '#0f6ab4'
        case 'read': return '#0f6ab4'
        case 'post': return '#10a54a'
        case 'put': return '#c5862b'
        case 'write': return '#10a54a'
        case 'delete': return '#a41e22'
    }
    return '#c5862b'
}

export const renderMethods = (actions: string[]) => {
    const children = actions.map( m => {return (<Tag key={m.toLowerCase()} className='tag-action' color={getMethodColor(m)}>{m.toUpperCase()}</Tag>)})
    return (<>{children}</>)
}
