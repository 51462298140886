import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { Link, useHistory, useLocation } from "react-router-dom"
const { Paragraph, Text } = Typography
import {
    AutoComplete,
    Button,
    Card,
    Col,
    message,
    Modal,
    Row,
    Space,
    Spin,
    Table,
    TableColumnsType, Tag,
    Tooltip, Typography
} from "antd"
import {
    CloseCircleOutlined,
    DeleteTwoTone,
    EditTwoTone, ExclamationCircleOutlined,
    InfoCircleTwoTone,
    PlusCircleTwoTone,
} from "@ant-design/icons"
import { ApartmentOutlined, PlusCircleOutlined } from "@ant-design/icons/lib/icons"
import React, { ReactNode, useEffect, useRef, useState } from "react"
import { useLoggedUser } from "../../../helpers/loginUserHelper"
import { LoadingIndicator } from "../../../components"
import ErrorPage403 from "../../../components/Errors/ErrorPage403"
import useLogger from "../../../common/useLogger"
import usePageSize from "../../../common/usePageSize"
import { AppState } from "../../../common/models"
import { IpAddressModel, IpSubnetModel } from "./models"
import queryString, { ParsedQuery } from "query-string"
import { _isNotEmptyObject, removeDiac, stopPropagation } from "../../../common/fce"
import getIpSubnet from "./actions/getIpSubnet"
import { sort_int64, sort_label, sort_str } from "../../../common/sorting"
import Pager from "../../../components/pagination/pager"
import ipRegex from "ip-regex"
import getIp from "./actions/getIp"
import deleteIP from "./actions/deleteIP"
import './SubnetsPage.scss'
import Draggable, { DraggableData, DraggableEvent } from "react-draggable"
import EditIpForm from "./EditIpForm"
import HistoryModal from "../../../components/History/HistoryModal"
import lookupIP from "./actions/lookupIP"
import tableCustomers from "../../login/actions/tableCustomers"
import getIpAddresses from "./actions/getIpAddresses"
import NewExtIpForm from "./NewExtIpForm"
import { useParams } from "react-router"
import removeIP from "./actions/removeIP"
import NewIpv6 from "./NewIpv6"
import { isSubnetExternal } from "./ip_common"
import EditPtrForm from "./EditPtrForm"
import NewIpv4 from "./NewIpv4"
import NewServerIpForm from "./NewServerIpForm"


const { confirm } = Modal

export const renderIpUsage = (ip: IpAddressModel) => {
    if (ip.usages && ip.usages.length > 0) {
        const serverUsage = ip.usages.filter(u => u.model.toLowerCase() !== 'ip')
        if (serverUsage.length > 0) {
            return (
                <div>
                    {
                        serverUsage.filter(u1 => u1.model.toLowerCase() === 'server').length > 0 && (
                            serverUsage
                                .filter(u1 => u1.model.toLowerCase() === 'server')
                                .map(u => <Tag key={u.id} color="green"><Link to={`/servers/edit/${u.id}`}>{u.name}</Link></Tag>)
                        )
                    }
                    {
                        serverUsage.filter(u1 => u1.model.toLowerCase() === 'inventory').length > 0 && (
                            serverUsage
                                .filter(u1 => u1.model.toLowerCase() === 'inventory')
                                .map(u => <Tag color="orange"><Link to={`/inventory?id=${u.id}`}>{u.name}</Link></Tag>)
                        )
                    }
                </div>
            )
        }
    }

    return <div>No available</div>
}

interface ParamTypes {
    id: string
}


const IpAddressesPage = () => {
    const CONTROL_NAME = 'page_ip'
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const { search } = useLocation()
    const history = useHistory()
    const { id } = useParams<ParamTypes>()

    const { customers, customerNames } = useSelector((state: AppState) => state.auth.tables)
    const { subnet, pager, isLoadingIps, isLoadingLookup,
        lookup_ip_note, lookup_ip_addr4, lookup_ip_addr6, ips } = useSelector((state: AppState) => state.ipSubnet)

    // options
    const [customerOptions, setCustomerOptions] = useState<{ label: string, value: string }[]>([])
    const [noteOptions, setNoteOptions] = useState<{ label: string | ReactNode, value: string }[]>([])
    const [addr4Options, setAddr4Options] = useState<{ label: string, value: string }[]>([])
    const [addr6Options, setAddr6Options] = useState<{ label: string, value: string }[]>([])


    // data
    const [dataSource, setDataSource] = useState<IpAddressModel[]>([])
    const [searchableAddresses, setSearchableAddresses] = useState<IpAddressModel[]>([])
    const [pageNumber, setPageNumber] = useState<number>(1)
    const [selectedIp, setSelectedIp] = useState<IpAddressModel | undefined>(undefined)
    const [processing, setProcessing] = useState<boolean>(false)
    const [openDelete, setOpenDelete] = useState(false)
    const [confirmDelete, setConfirmDelete] = useState(false)


    // const [note, setNote] = useState('')
    const [reverse, setReverse] = useState('')
    const [qsFilter, setQsFilter] = useState<string>('')

    // filters
    const [parsed, setParsed] = useState<ParsedQuery<string>>(queryString.parse(search))
    const [iid, setIid] = useState(0)
    const [subnetId, setSubnetId] = useState(0)
    const [pageStarted, setPageStarted] = useState(false)


    const [searchAddr4Lookup, setSearchAddr4Lookup] = useState<string>('')
    const [searchAddr4, setSearchAddr4] = useState<string>('')
    const [searchAddr6Lookup, setSearchAddr6Lookup] = useState<string>('')
    const [searchAddr6, setSearchAddr6] = useState<string>('')
    const [searchNoteLookup, setSearchNoteLookup] = useState<string>('')
    const [searchNote, setSearchNote] = useState<string>('')

    const [searchCustomerId, setSearchCustomerId] = useState<number | undefined>(undefined)
    const [ready, setReady] = useState<number>(0)

    // page
    const [isHistoryModalVisible, setHistoryModalVisible] = useState(false)
    const [historyTitle, setHistoryTitle] = useState('')
    const [historyModelId, setHistoryModelId] = useState<number | undefined>()
    const [isModalNewIpVisible, setModalNewIpVisible] = useState(false)
    const [isModalNewIpv6Visible, setModalNewIpv6Visible] = useState(false)
    const [isModalNewIpv4Visible, setModalNewIpv4Visible] = useState(false)
    const [isModalEditIpVisible, setModalEditIpVisible] = useState(false)
    const [isModalEditPtrVisible, setModalEditPtrVisible] = useState(false)
    const [isModalNewServerVisible, setModalNewServerVisible] = useState(false)

    // permissions
    const [isViewer, setViewer] = useState(false)
    const [isIpCreator, setIpCreator] = useState(false)
    const [isIpEditor, setIpEditor] = useState(false)
    const [isIpSetRevers, setIpSetRevers] = useState(false)
    const [isIpAssignServer, setIpAssignServer] = useState(false)
    const [isIpDeleter, setIpDeleter] = useState(false)


    // get settings and current user
    const loggedUser = useLoggedUser()
    if (!loggedUser || !loggedUser.isLoaded()) {
        // waiting..
        return (
            <div className="fullwidth-loading" >
                <LoadingIndicator />
            </div>
        )
    }

    // required authorization
    if (!loggedUser.hasAccess(CONTROL_NAME)) {
        return <ErrorPage403 />
    }

    // settings
    const appSetting = loggedUser.getAppSettings()

    // usage: logger(msg, obj=null)
    const logger = useLogger(appSetting, 'IPsPage')
    const [pageSize, setPageSize] = useState<number>(appSetting.grid_page_size)
    usePageSize(appSetting, loggedUser.user.id, pageSize)

    // history drag modal
    const [disabled, setDisabled] = useState(true)
    const draggleRef = useRef<HTMLDivElement>(null)
    const [bounds, setBounds] = useState({ left: 0, top: 0, bottom: 0, right: 0 })
    const onStart = (_event: DraggableEvent, uiData: DraggableData) => {
        const { clientWidth, clientHeight } = window.document.documentElement
        const targetRect = draggleRef.current?.getBoundingClientRect()
        if (!targetRect) {
            return
        }
        setBounds({
            left: -targetRect.left + uiData.x,
            right: clientWidth - (targetRect.right - uiData.x),
            top: -targetRect.top + uiData.y,
            bottom: clientHeight - (targetRect.bottom - uiData.y),
        })
    }

    useEffect(() => {
        // trigger ONCE
        logger('=== ONCE  id=' + id)
        const viewer = loggedUser.hasAccess('page_ip')
        setViewer(viewer)
        setIpCreator(loggedUser.hasAccess('page_ip_create_button'))
        setIpEditor(loggedUser.hasAccess('page_ip_edit_button'))            // ip-allocator
        setIpSetRevers(loggedUser.hasAccess('page_ip_set_revers'))          // role ip-ptr-records-editor
        setIpAssignServer(loggedUser.hasAccess('page_ip_assign_server'))    // ip-allocator
        setIpDeleter(loggedUser.hasAccess('page_ip_delete_button'))
        if (!viewer) {
            history.replace('/ip/subnets')
            return
        }

        if (id) {
            const sid = parseInt(id)    // subnetId
            if (sid > 0) {
                loadOptions()
                setPageSize(appSetting.grid_page_size)
                setPageStarted(true)
                setSubnetId(sid)
                dispatch(getIpSubnet(sid, ''))
                handleQueryStringFilters()
                return // ok
            }
        }
        // failover 404
        history.replace('/ip/subnets')
    }, [])

    useEffect(() => {
        // process Options
        if (subnetId > 0 && ready > 0) {
            logger('subnetId=' + subnetId)
            fetchRecords(pageNumber, pageSize)
        }
    }, [subnetId])

    useEffect(() => {
        // process Options
        if (ready) {
            logger(`Ready=${ready} subnetId=${subnetId}`)
            fetchRecords(pageNumber, pageSize)
        }
    }, [ready])

    useEffect(() => {
        // check if Ready
        if (customers && customers.length && customerNames) {
            // options are loaded
            // populate customerOptions
            const data = customers.map(c => ({ value: `${c.id}`, label: getCustomerName(c.id) }))
            setCustomerOptions(data.sort(sort_label))
            setReady(ready + 1)
        }
    }, [customers, customerNames])



    useEffect(() => {
        // populate addr4Options
        setAddr4Options(lookup_ip_addr4.map(s => ({ label: s, value: s })))
    }, [lookup_ip_addr4])

    useEffect(() => {
        // populate addr6Options
        setAddr6Options(lookup_ip_addr6.map(s => ({ label: s, value: s })))
    }, [lookup_ip_addr6])

    useEffect(() => {
        // populate noteOptions
        setNoteOptions(lookup_ip_note.map(s => ({ label: s, value: s })))
    }, [lookup_ip_note])

    useEffect(() => {
        replaceHistory()
    }, [pageSize, pageNumber, qsFilter])


    useEffect(() => {
        // update QsFilter
        const qs: string = prepareQsFilter()
        logger(`qsFilter: ${qs}`)
        if (qs != qsFilter) {
            setQsFilter(qs)
        }
    }, [searchCustomerId, searchAddr4, searchAddr6, searchNote, subnetId])

    useEffect(() => {
        // when filter is changed
        // show the first page
        logger(`qsFilter changed: page: ${pageNumber}, pageSize: ${pageSize}, qs=${qsFilter}`)
        let pn = pageNumber
        if (ready) {    // do not change page for F5
            pn = 1
        }
        fetchRecords(pn, pageSize)
    }, [qsFilter])

    function ipToInt(ip: string): BigInt {
        if (ipRegex.v4({ exact: true }).test(ip)) {
            const n = ip.split('.')
                .map(Number)
                .reduce((acc, octet) => (acc << 8) + octet, 0)
            return BigInt(n)
        }
        return ipv6ToBigInt(ip)
    }

    function ipv6ToBigInt(ip: string): bigint {
        const sections = ip.split(':') // Split by ":"
        const expanded = expandIPv6(sections) // Expand shorthand notation (::)

        return expanded
            .map(part => BigInt(parseInt(part, 16))) // Convert each part to BigInt
            .reduce((acc, val) => (acc << BigInt(16)) + val, BigInt(0)) // Shift and add
    }

    // Helper function: Expand shorthand notation (::)
    function expandIPv6(parts: string[]): string[] {
        const missing = 8 - parts.filter(part => part !== "").length // Find missing zeros
        return parts.flatMap(part => (part === "" ? Array(missing).fill("0") : part))
    }

    useEffect(() => {
        // process new server-data
        logger(`ips.items:${ips.items.length}`)
        if (!ready) {
            return
        }
        if (ips.items && filterIsValid()) {
            const data = ips.items.map(item => {
                return {
                    ...item,
                    address4: ipRegex.v4({ exact: true }).test(item.address) ? item.address : '',
                    address6: ipRegex.v6({ exact: true }).test(item.address) ? item.address : '',
                    order: ipToInt(item.address),
                    searchableName: removeDiac(item.address)
                }
            })
            logger(`setSearchableAddresses:${data.length}`)
            setSearchableAddresses(data)
            !isLoadingIps && setProcessing(false)
        }
    }, [ips.items])

    useEffect(() => {
        // render data
        if (!ready) {
            return
        }
        if (filterIsValid()) {
            refreshGrid()
        }
    }, [searchableAddresses])



    // ------------------------------ end hooks

    const loadOptions = () => {
        // load Options for refresh F5
        if (!customers || customers.length === 0) {
            dispatch(tableCustomers())
        }
        else {
            setReady(ready + 1)
        }
    }

    const getCustomerName = (cid) => {
        if (customerNames) {
            let name = customerNames!.get(cid)
            return name ? name : `cust.${cid}`
        }
        return `err.${cid}`
    }

    const refreshGrid = () => {
        setDataSource(searchableAddresses.sort((a, b) => sort_int64(a.order, b.order)))
    }

    const replaceHistory = () => {
        if (filterIsValid()) {
            const url = `/ip/subnet/${subnetId}?pagination[pageSize]=${pageSize}&pagination[page]=${pageNumber}`
            if (qsFilter) {
                history.replace(`${url}&${qsFilter}`)
            }
            else {
                history.replace(url)
            }
        }
    }

    const filterIsValid = (): boolean => {
        // subnet_id is required
        return subnetId > 0
    }

    const getQSFilter = (): string[] => {
        let qs: string[] = []
        if (subnetId > 0) {
            if (searchCustomerId && searchCustomerId > 0) {
                qs.push('customer_id=' + searchCustomerId)
            }
        }
        return qs
    }

    const prepareQsFilter = (): string => {
        if (iid > 0) {
            let qs: string[] = []
            // inv_no is uniq
            qs.push('id=' + iid)
            return qs.join("&")
        }
        // load filtered data from server
        let qs: string[]
        qs = getQSFilter()
        if (appSetting.checkMinSearch(searchNote)) {
            qs.push('note=' + encodeURIComponent(searchNote))
        }
        if (appSetting.checkMinSearch(searchAddr4)) {
            qs.push('address=' + encodeURIComponent(searchAddr4))
            qs.push('type_id=1')
            qs.push('subnet_id=' + subnetId)
        }
        if (appSetting.checkMinSearch(searchAddr6)) {
            qs.push('address=' + encodeURIComponent(searchAddr6))
            qs.push('type_id=2')
            qs.push('subnet_id=' + subnetId)
        }
        if (searchCustomerId && searchCustomerId > 0) {
            qs.push('customer_id=' + searchCustomerId)
        }
        logger('prepareQsFilter: ' + qs.join("&"))
        return qs.join("&")
    }

    const onClearAddr4 = () => {
        setSearchAddr4Lookup('')
        setSearchAddr4('')
        setAddr4Options([])
    }

    const onSelectAddr4 = (data: string) => {
        setSearchAddr4(data)
    }

    const onChangeAddr4Lookup = (data: string) => {
        if (!data) {
            if (searchAddr4Lookup.length === 1) {
                setSearchAddr4Lookup('')
                fetchAddr4Lookup('')
            }
            return
        }
        if (data != searchAddr4Lookup) {
            setSearchAddr4Lookup(data)
            fetchAddr4Lookup(data)
        }
    }

    const onClearAddr6 = () => {
        setSearchAddr6Lookup('')
        setSearchAddr6('')
        setAddr6Options([])
    }

    const onSelectAddr6 = (data: string) => {
        setSearchAddr6(data)
    }

    const onChangeAddr6Lookup = (data: string) => {
        if (!data) {
            if (searchAddr6Lookup.length === 1) {
                setSearchAddr6Lookup('')
                fetchAddr6Lookup('')
            }
            return
        }
        if (data != searchAddr6Lookup) {
            setSearchAddr6Lookup(data)
            fetchAddr6Lookup(data)
        }
    }

    const onClearNote = () => {
        setSearchNoteLookup('')
        setSearchNote('')
        setNoteOptions([])
    }

    const onSelectNote = (data: string) => {
        setSearchNote(data)
    }

    const onChangeNoteLookup = (data: string) => {
        if (!data) {
            if (searchNoteLookup.length === 1) {
                setSearchNoteLookup('')
                fetchNoteLookup('')
            }
            return
        }
        if (data != searchNoteLookup) {
            setSearchNoteLookup(data)
            fetchNoteLookup(data)
        }
    }

    const fetchRecords = (pn: number, ps: number) => {
        if (!isViewer) {
            return
        }
        setPageNumber(pn)
        setPageSize(ps)
        logger('fetchRecords ready=' + ready + ', qsFilter: ' + qsFilter)
        if (ready && filterIsValid()) {
            // subnet_id is required
            logger(`fetchRecords: page: ${pn}, pageSize: ${ps}, qs=${qsFilter}`)
            dispatch(getIpAddresses(ps, pn - 1, subnetId, qsFilter, suc => { }))
            setProcessing(true)
        }
    }

    const fetchAddr4Lookup = (searchText: string) => {
        // call lookup for addr4
        searchText = searchText.trim()
        if (appSetting.checkMinSearch(searchText.trim())) {
            let qs: string[]
            qs = getQSFilter()
            if (appSetting.checkMinSearch(searchNote)) {
                qs.push('note=' + encodeURIComponent(searchNote))
            }
            if (appSetting.checkMinSearch(searchText)) {
                qs.push('field=address')
                qs.push('type_id=1')
                qs.push('subnet_id=' + subnetId)
                qs.push('value=' + encodeURIComponent(searchText.trim()))
                logger('lookupIP: ' + qs.join("&"))
                dispatch(lookupIP('address', 1, qs.join("&")))
            }
        }
    }

    const fetchAddr6Lookup = (searchText: string) => {
        // call lookup for addr6
        searchText = searchText.trim()
        if (appSetting.checkMinSearch(searchText.trim())) {
            let qs: string[]
            qs = getQSFilter()
            if (appSetting.checkMinSearch(searchNote)) {
                qs.push('note=' + encodeURIComponent(searchNote))
            }
            if (appSetting.checkMinSearch(searchText)) {
                qs.push('type_id=2')
                qs.push('subnet_id=' + subnetId)
                qs.push('field=address')
                qs.push('value=' + encodeURIComponent(searchText.trim()))
                logger('lookupIP: ' + qs.join("&"))
                dispatch(lookupIP('address', 2, qs.join("&")))
            }
        }
    }

    const fetchNoteLookup = (searchText: string) => {
        // call lookup for note
        searchText = searchText.trim()
        if (appSetting.checkMinSearch(searchText.trim())) {
            let qs: string[]
            qs = getQSFilter()
            if (appSetting.checkMinSearch(searchAddr4)) {
                qs.push('address=' + encodeURIComponent(searchAddr4))
                qs.push('subnet_id=' + subnetId)
                qs.push('type_id=1')
            }
            if (appSetting.checkMinSearch(searchAddr6)) {
                qs.push('address=' + encodeURIComponent(searchAddr6))
                qs.push('subnet_id=' + subnetId)
                qs.push('type_id=2')
            }
            if (appSetting.checkMinSearch(searchText)) {
                qs.push('field=note')
                qs.push('value=' + encodeURIComponent(searchText.trim()))
                logger('lookupSubnet: ' + qs.join("&"))
                dispatch(lookupIP('note', 1, qs.join("&")))
            }
        }
    }

    const handleQueryStringFilters = () => {
        // parse query string
        // and set filters
        // /billing/invoice?filter_id=6&number=122&from=2022-06-01&to=2022-08-10
        let passedRange = 0
        if (parsed && _isNotEmptyObject(parsed)) {
            logger(`handleQueryStringFilters..`)

            const qs_note: string | string[] | null = parsed['note']
            if (qs_note && typeof qs_note === 'string') {
                setSearchNoteLookup(qs_note)  // textbox
                setSearchNote(qs_note) // filter
            }
            const qs_addr: string | string[] | null = parsed['address']
            if (qs_addr && typeof qs_addr === 'string') {
                const qs_type: string | string[] | null = parsed['type_id']
                if (qs_type == '2') {
                    setSearchAddr6Lookup(qs_addr)  // textbox
                    setSearchAddr6(qs_addr) // filter
                }
                else {
                    setSearchAddr4Lookup(qs_addr)  // textbox
                    setSearchAddr4(qs_addr) // filter
                }
            }
            const qs_customer_id: string | string[] | null = parsed['customer_id']
            if (qs_customer_id && typeof qs_customer_id === 'string') {
                const cid = parseInt(qs_customer_id)
                if (cid > 0) {
                    setSearchCustomerId(cid)
                }
            }

            const qs_size: string | string[] | null = parsed['pagination[pageSize]']
            if (qs_size && typeof qs_size === 'string') {
                const ps = parseInt(qs_size)
                if (ps > 0) {
                    setPageSize(ps)
                }
            }
            const qs_num: string | string[] | null = parsed['pagination[page]']
            if (qs_num && typeof qs_num === 'string') {
                const pn = parseInt(qs_num)
                setPageNumber(pn)
            }
        }
    }

    const showConfirmDelete = (ip: IpAddressModel, title: string) => {
        if (!ip) {
            return
        }
        // if (!isDeleter) {
        //     message.error(t('general.error403'))
        //     return
        // }
        confirm({
            icon: <ExclamationCircleOutlined style={{ color: 'red' }} />,
            title: title,
            content: <p>{ip.address}</p>,
            okText: t('general.yes'),
            cancelText: t('general.cancel'),
            okButtonProps: { loading: confirmDelete },
            className: 'confirm-alert',
            onOk() {
                setConfirmDelete(true)
                dispatch(deleteIP(ip.id, suc => {
                    setConfirmDelete(false)
                    setOpenDelete(false)
                    if (suc) {
                        message.success(t('general.success'))
                    }
                }))
            },
            onCancel() {
                setOpenDelete(false)
            },
        }
        )
    }

    const compIPV6 = (input) => {
        return input.replace(/\b(?:0+:){2,}/, ':')
    }

    const FilterByAddr4 = (
        <AutoComplete
            showSearch
            placeholder={t('ipSubnetPage.address4')}
            style={{ width: '140px' }}
            value={searchAddr4Lookup}
            options={addr4Options}
            dropdownMatchSelectWidth={200}
            onInputKeyDown={(e) => {
                if (e.key === 'Enter') {
                    onSelectAddr4(e.currentTarget.value)
                }
            }}
            onSelect={onSelectAddr4}
            //onSearch={onChangeAddr4Lookup}
            onChange={onChangeAddr4Lookup}
            onClear={onClearAddr4}
            onClick={stopPropagation}
            notFoundContent={isLoadingLookup && <Spin />}
            filterOption={true}
            optionFilterProp='label'
            allowClear={true}
        />
    )

    const FilterByAddr6 = (
        <AutoComplete
            showSearch
            placeholder={t('ipSubnetPage.address6')}
            style={{ width: '200px' }}
            value={searchAddr6Lookup}
            options={addr6Options}
            dropdownMatchSelectWidth={320}
            onInputKeyDown={(e) => {
                if (e.key === 'Enter') {
                    onSelectAddr6(e.currentTarget.value)
                }
            }}
            onSelect={onSelectAddr6}
            //onSearch={onChangeAddr6Lookup}
            onChange={onChangeAddr6Lookup}
            onClear={onClearAddr6}
            onClick={stopPropagation}
            notFoundContent={isLoadingLookup && <Spin />}
            filterOption={true}
            optionFilterProp='label'
            allowClear={true}
        />
    )


    const FilterByNote = (
        <AutoComplete
            showSearch
            placeholder={t('ipSubnetPage.note')}
            style={{ width: '140px' }}
            value={searchNoteLookup}
            options={noteOptions}
            dropdownMatchSelectWidth={200}
            onInputKeyDown={(e) => {
                if (e.key === 'Enter') {
                    onSelectNote(e.currentTarget.value)
                }
            }}
            onSelect={onSelectNote}
            //onSearch={onChangeNoteLookup}
            onChange={onChangeNoteLookup}
            onClear={onClearNote}
            onClick={stopPropagation}
            notFoundContent={isLoadingLookup && <Spin />}
            filterOption={true}
            optionFilterProp='label'
            allowClear={true}
        />
    )

    const FilterByReverse = (
        <AutoComplete
            placeholder={t('ipSubnetPage.revers')}
            style={{ width: '140px' }}
            value={reverse}
            allowClear={true}
            onClick={stopPropagation}
            onKeyDown={(e) => { if (e.key === 'Enter') { e.stopPropagation() } }}
            onChange={(v) => { setReverse(v) }}
        />
    )

    const getSubnetClassName = () => {
        if (!subnet) {
            return ''
        }
        if (subnet.is_internal) {
            return 'tagInternal'
        }
        if (isSubnetExternal(subnet.id)) {
            return 'tagExternal'
        }
        else {
            return 'tagPublic'
        }
    }

    const getHostname = (ip: IpAddressModel | undefined) => {
        if (!ip) {
            return ''
        }
        if (ip.usages && ip.usages.length > 0) {
            return ip.usages[0].name
        }
        return ip.ptr ? ip.ptr.ptrdname : ''
    }

    const columns: TableColumnsType<IpAddressModel> = [
        {
            title: FilterByAddr4,
            dataIndex: 'address4',
            key: 'address4',
            width: 160,
            render: (address4: string, rec) => {
                if (rec && rec.address4) {
                    return <Tag style={{ fontSize: '1.1em' }}>{address4}</Tag>
                }
            }
        },
        {
            title: FilterByAddr6,
            dataIndex: 'address6',
            key: 'address6',
            width: 320,
            render: (address6, rec) => {
                if (rec && rec.address6) {
                    // it is only IPv6
                    return <Tag style={{ float: 'left' }}>{compIPV6(rec.address6).toUpperCase()}</Tag>
                }
                else {
                    // it is IPv4
                    if (rec && rec.ipv6 && rec.ipv6.address && ipRegex.v6({ exact: true }).test(rec.ipv6.address)) {
                        return (
                            <div>
                                <Tag style={{ float: 'left' }}>{compIPV6(rec.ipv6.address).toUpperCase()}</Tag>
                                {
                                    isIpEditor && <Button type="text" danger size="small"
                                        title={t('general.delete')}
                                        className='actionButton'
                                        style={{ marginLeft: '12px' }}
                                        onClick={(ev) => { dispatch(removeIP({ id: rec.id, address: rec.ipv6!.address })) }}
                                        icon={<CloseCircleOutlined title={t('general.delete') + ' IPv6'} twoToneColor="red" />}
                                    />
                                }
                            </div>
                        )
                    } else {
                        return <>
                            <Button title={t('general.add')}
                                type='link' size="small"
                                disabled={!isIpEditor}
                                onClick={() => {
                                    // show selected IP ??
                                    dispatch(getIp(rec.id))
                                    setModalNewIpv6Visible(true)
                                }}
                                icon={<PlusCircleTwoTone twoToneColor={isIpEditor ? "" : "#ccc"} />}>&nbsp;</Button>&nbsp;<span style={{ color: '#cccccc' }}>IPv6</span>
                        </>
                    }
                }
            }
        },
        {
            title: FilterByNote,
            dataIndex: 'note',
            key: 'note',
            ellipsis: { showTitle: false },
            render: note => (
                <Tooltip placement="topLeft" title={note}>
                    {note}
                </Tooltip>
            ),
        },
        {
            title: t('ipSubnetPage.revers'),
            dataIndex: 'reverse_domain',
            key: 'reverse_domain',
            render: (ptr, rec) => {
                if (rec.subnet_id < 3) {
                    return rec.reverse_domain ? <span>{rec.reverse_domain}</span> : ''
                }
                if (isIpSetRevers) {
                    if (rec && rec.reverse_domain) {
                        return <span className='linkUnder' onClick={() => {
                            setSelectedIp(rec)
                            dispatch(getIp(rec.id))
                            setModalEditPtrVisible(true)
                        }
                        }>{rec.reverse_domain}</span>
                    }
                    else {
                        return <Button type="text" size="small"
                            className='actionButton'
                            title={t('general.create')}
                            onClick={() => {
                                setSelectedIp(rec)
                                dispatch(getIp(rec.id))
                                setModalEditPtrVisible(true)
                            }
                            }
                            icon={<PlusCircleTwoTone />}
                        />
                    }
                }
                else {
                    return rec.reverse_domain ? <span>{rec.reverse_domain}</span> : ''
                }
            }
        },
        {
            title: t('ipSubnetPage.used'),
            dataIndex: 'usages',
            key: 'usages',
            render: (text, rec: IpAddressModel) => {
                if (rec.usages && rec.usages.filter(u => u.model.toLowerCase() !== 'ip').length > 0) {
                    return renderIpUsage(rec)
                } else {
                    return (isIpAssignServer &&
                        <Button type="link"
                            size="small"
                            className='actionButton'
                            title={t('ipSubnetPage.add_server')}
                            disabled={!isIpAssignServer}
                            onClick={() => {
                                // todo Add server
                                setModalNewServerVisible(true)
                                setSelectedIp(rec)
                            }}
                            icon={<PlusCircleTwoTone twoToneColor={isIpAssignServer ? "" : "#ccc"} />}
                        >{t('ipSubnetPage.btn_set')}</Button>
                    )
                }
            }
        },
        {
            title: 'Action',
            key: 'action',
            dataIndex: 'action',
            width: '120px',
            align: 'center',
            showSorterTooltip: false,
            fixed: 'right',
            render: (text: string, rec: IpAddressModel) => (
                <Space size={1}>

                    <Button type="text"
                        size="small"
                        disabled={!isIpEditor}
                        className='actionButton'
                        title={t('general.change')}
                        onClick={() => {
                            if (isIpEditor) {
                                setSelectedIp(rec)
                                dispatch(getIp(rec.id))
                                setModalEditIpVisible(true)
                            }
                        }}
                        icon={<EditTwoTone twoToneColor={isIpEditor ? "green" : "#ccc"} />}
                    />

                    {isSubnetExternal(rec.subnet_id) && (
                        <Button type="text" danger size="small"
                            disabled={!isIpDeleter}
                            onClick={() => {
                                if (isIpDeleter) {
                                    showConfirmDelete(rec, t('ipSubnetPage.confirm_delete_ip'))
                                }
                            }}
                            title={t('general.delete')}
                            className='actionButton'
                            icon={<DeleteTwoTone twoToneColor={isIpDeleter ? "red" : "#ccc"} />}
                        />
                    )
                    }
                    <Button title={t('general.btnHistory')} size='small'
                        onClick={() => {
                            setHistoryModelId(rec.id)
                            setHistoryTitle(rec.address)
                            setHistoryModalVisible(true)
                        }}
                        icon={<InfoCircleTwoTone />}
                        className='actionButton'
                    />
                    <span>&nbsp;</span>
                </Space>)
        },
    ]

    if (!appSetting || Object.keys(appSetting).length === 0 || !subnet) {
        return (<Spin />)
    }

    return (
        <>
            <Card title={
                <>
                    <Row>
                        <Col span={2}><Button className='btnBack' onClick={() => history.replace('/ip/subnets')}>{t('general.back')}</Button></Col>
                        <Col span={10} style={{ padding: '8px' }}>
                            <Row>
                                <Col xs={4} xl={6} className='right pad4' style={{ paddingTop: '10px' }}>
                                    <Tag className={getSubnetClassName()}>{t('ipSubnetPage.subnet')}: </Tag>
                                </Col>
                                <Col xs={20} xl={18} className='left pad4'><b style={{ fontSize: '1.5em' }}>{subnet?.cidr}</b></Col>
                            </Row>
                        </Col>
                        <Col span={12} style={{ padding: '8px' }}>
                            <Row>
                                <Col xs={24} className='left pad4' style={{ paddingTop: '10px' }}>
                                    <b style={{ fontSize: '1.2em' }}>{subnet?.note ? subnet?.note.substring(0, 40) : ''}</b>
                                </Col>
                            </Row>

                        </Col>
                    </Row>

                    {
                        (!isSubnetExternal(subnet.id)) && (
                            <Row>
                                <Col span={2} style={{ padding: '8px' }}>&nbsp;</Col>
                                <Col span={10}>
                                    <Row>
                                        <Col xs={4} xl={6} className='right pad4'>
                                            <Text type='secondary'>{t('ipSubnetPage.location')}: </Text>
                                        </Col>
                                        <Col xs={20} xl={18} className='left pad4'>{subnet?.location}</Col>
                                    </Row>
                                    <Row>
                                        <Col xs={4} xl={6} className='right pad4'>
                                            <Text type='secondary'>NS: </Text>
                                        </Col>
                                        <Col xs={20} xl={18} className='left pad4'>
                                            <Text>{subnet?.ns}</Text>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs={4} xl={6} className='right pad4'>
                                            <Text type='secondary'>{t('ipSubnetPage.reverse_zone')}: </Text>
                                        </Col>
                                        <Col xs={20} xl={18} className='left pad4'>
                                            <Text>{subnet?.reverse_domain}</Text>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col span={12}>
                                    <Row>
                                        <Col xs={4} xl={6} className='right pad4'>
                                            <Text type='secondary'>VLAN: </Text>
                                        </Col>
                                        <Col xs={20} xl={18} className='left pad4'>{subnet?.vlan ? subnet?.vlan : ''}</Col>
                                    </Row>
                                    <Row>
                                        <Col xs={4} xl={6} className='right pad4'>
                                            <Text type='secondary'>{t('ipSubnetPage.free_ip')}: </Text>
                                        </Col>
                                        <Col xs={20} xl={18} className='left pad4'>
                                            <Text>{subnet?.ips?.length}</Text>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs={4} xl={6} className='right pad4'>
                                            <Text type='secondary'> </Text>
                                        </Col>
                                        <Col xs={20} xl={18} className='left pad4'>
                                            <Text></Text>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        )
                    }


                </>
            }
                extra={
                    (subnet && isSubnetExternal(subnet.id)) && (
                        <Row style={{ marginTop: isSubnetExternal(subnet.id) ? 0 : '100px', verticalAlign: 'bottom' }}>
                            <Col span={24}>
                                <Button type='primary' disabled={!isIpCreator}
                                    style={{ margin: '15px' }} onClick={() => { isIpCreator && setModalNewIpVisible(true) }}>
                                    <PlusCircleOutlined /> {t('ipSubnetPage.add_ip_address')}
                                </Button>
                            </Col>
                        </Row>
                    )
                }
                className='IpsPage'
            >

                <Table<IpAddressModel>
                    className='ipsTable'
                    rowKey='id'
                    showHeader={true}
                    size='small'
                    bordered={true}
                    columns={columns}
                    scroll={{ x: 680 }}
                    dataSource={dataSource}
                    loading={isLoadingIps || processing}
                    pagination={false}
                    footer={() => Pager({
                        filename: 'fn',
                        total: pager.totalCount,
                        current: pager.page,
                        pageSize: pager.pageSize,
                        data: dataSource,
                        fetchRecords: fetchRecords
                    })}
                    onChange={(ev) => {
                        ev.pageSize && setPageSize(ev.pageSize)
                    }}
                />

            </Card>

            <Modal title={
                <div style={{ width: '100%', cursor: 'move' }}
                    onMouseOver={() => { if (disabled) { setDisabled(false) } }}
                    onMouseOut={() => { setDisabled(true) }}
                    onFocus={() => { }}
                    onBlur={() => { }}
                >
                    <><ApartmentOutlined /> &nbsp; {t('ipSubnetPage.new_ip_address')}</>
                </div>
            }
                style={{ top: 20 }}
                width={540}
                visible={isModalNewIpVisible}
                onCancel={() => setModalNewIpVisible(false)}
                maskClosable={false}
                modalRender={(modal) => (
                    <Draggable disabled={disabled} bounds={bounds} onStart={(ev, data) => onStart(ev, data)}>
                        <div ref={draggleRef}>{modal}</div>
                    </Draggable>
                )}
                destroyOnClose
                footer={null}
            >
                <NewExtIpForm onClose={() => { setModalNewIpVisible(false) }} />
            </Modal>

            <Modal title={
                <div style={{ width: '100%', cursor: 'move' }}
                    onMouseOver={() => { if (disabled) { setDisabled(false) } }}
                    onMouseOut={() => { setDisabled(true) }}
                    onFocus={() => { }}
                    onBlur={() => { }}
                >
                    <><ApartmentOutlined /> &nbsp; {t('ipSubnetPage.add_ipv6')}</>
                </div>
            }
                style={{ top: 20 }}
                width={600}
                visible={isModalNewIpv6Visible}
                onCancel={() => setModalNewIpv6Visible(false)}
                maskClosable={false}
                modalRender={(modal) => (
                    <Draggable disabled={disabled} bounds={bounds} onStart={(ev, data) => onStart(ev, data)}>
                        <div ref={draggleRef}>{modal}</div>
                    </Draggable>
                )}
                destroyOnClose
                footer={null}
            >
                <NewIpv6 onClose={() => { setModalNewIpv6Visible(false) }} />
            </Modal>

            <Modal title={
                <div style={{ width: '100%', cursor: 'move' }}
                    onMouseOver={() => { if (disabled) { setDisabled(false) } }}
                    onMouseOut={() => { setDisabled(true) }}
                    onFocus={() => { }}
                    onBlur={() => { }}
                >
                    <><ApartmentOutlined /> &nbsp; {t('ipSubnetPage.add_ipv4')}</>
                </div>
            }
                style={{ top: 20 }}
                width={600}
                visible={isModalNewIpv4Visible}
                onCancel={() => setModalNewIpv4Visible(false)}
                maskClosable={false}
                modalRender={(modal) => (
                    <Draggable disabled={disabled} bounds={bounds} onStart={(ev, data) => onStart(ev, data)}>
                        <div ref={draggleRef}>{modal}</div>
                    </Draggable>
                )}
                destroyOnClose
                footer={null}
            >
                <NewIpv4 onClose={() => { setModalNewIpv4Visible(false) }} />
            </Modal>

            <Modal title={
                <div style={{ width: '100%', cursor: 'move' }}
                    onMouseOver={() => { if (disabled) { setDisabled(false) } }}
                    onMouseOut={() => { setDisabled(true) }}
                    onFocus={() => { }}
                    onBlur={() => { }}
                >
                    <><ApartmentOutlined /> &nbsp; {t('ipSubnetPage.edit_ptr')}</>
                </div>
            }
                style={{ top: 20 }}
                width={600}
                visible={isModalEditPtrVisible}
                onCancel={() => setModalEditPtrVisible(false)}
                maskClosable={false}
                modalRender={(modal) => (
                    <Draggable disabled={disabled} bounds={bounds} onStart={(ev, data) => onStart(ev, data)}>
                        <div ref={draggleRef}>{modal}</div>
                    </Draggable>
                )}
                destroyOnClose
                footer={null}
            >
                <EditPtrForm hint={getHostname(selectedIp)} onClose={() => { setModalEditPtrVisible(false) }} />
            </Modal>


            <Modal title={
                <div style={{ width: '100%', cursor: 'move' }}
                    onMouseOver={() => { if (disabled) { setDisabled(false) } }}
                    onMouseOut={() => { setDisabled(true) }}
                    onFocus={() => { }}
                    onBlur={() => { }}
                >
                    <><ApartmentOutlined /> &nbsp; {t('ipSubnetPage.update_ip')}</>
                </div>
            }
                style={{ top: 20 }}
                width={600}
                visible={isModalEditIpVisible}
                onCancel={() => setModalEditIpVisible(false)}
                maskClosable={false}
                modalRender={(modal) => (
                    <Draggable disabled={disabled} bounds={bounds} onStart={(ev, data) => onStart(ev, data)}>
                        <div ref={draggleRef}>{modal}</div>
                    </Draggable>
                )}
                destroyOnClose
                footer={null}
            >
                <EditIpForm id={selectedIp?.id} onClose={() => { setSelectedIp(undefined); setModalEditIpVisible(false) }} />
            </Modal>

            <Modal title={
                <div style={{ width: '100%', cursor: 'move' }}
                    onMouseOver={() => { if (disabled) { setDisabled(false) } }}
                    onMouseOut={() => { setDisabled(true) }}
                    onFocus={() => { }}
                    onBlur={() => { }}
                >
                    <><ApartmentOutlined /> &nbsp; {t('serversPage.create_server')}</>
                </div>
            }
                style={{ top: 20 }}
                width={480}
                visible={isModalNewServerVisible}
                onCancel={() => setModalNewServerVisible(false)}
                maskClosable={false}
                modalRender={(modal) => (
                    <Draggable disabled={disabled} bounds={bounds} onStart={(ev, data) => onStart(ev, data)}>
                        <div ref={draggleRef}>{modal}</div>
                    </Draggable>
                )}
                destroyOnClose
                footer={null}
            >
                <NewServerIpForm selectedIp={selectedIp} onClose={() => { setReady(ready + 1); setModalNewServerVisible(false) }} />
            </Modal>

            <HistoryModal service='ip' model='Ip'
                modelId={historyModelId}
                title={historyTitle}
                isModalVisible={isHistoryModalVisible}
                setModalVisible={() => setHistoryModalVisible(false)}
                modalRender={(modal) => (
                    <Draggable bounds={bounds} onStart={(ev, data) => onStart(ev, data)}>
                        <div ref={draggleRef}>{modal}</div>
                    </Draggable>
                )}
            />
        </>
    )
}

export default IpAddressesPage
