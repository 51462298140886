import { AsyncActionMode } from 'common/models'
import {
  UpdateIpParams,
  CreateSubnetIpParams,
  UpdateSubnetIpParams,
  CreateIpParams,
  SubnetGrid,
  IpAddressGrid,
  AddIpParams,
  IpAddressModel,
  EditPtrParams,
} from '../models'

import {DnsServiceName} from "../../../dns/service/models";
import { ServerModel } from 'pages/server/models'




export enum ActionType {
  GET_SUBNET_IP = 'GET_SUBNET_IP',
  LIST_SUBNET_IP = 'LIST_SUBNET_IP',
  CREATE_SUBNET_IP = 'CREATE_SUBNET_IP',
  UPDATE_SUBNET_IP = 'UPDATE_SUBNET_IP',
  DELETE_SUBNET_IP = 'DELETE_SUBNET_IP',
  GET_IP = 'GET_IP',
  LIST_IP = 'LIST_IP',
  CREATE_IP = 'CREATE_IP',
  UPDATE_IP = 'UPDATE_IP',
  DELETE_IP = 'DELETE_IP',
  ADD_IP = 'ADD_IP',
  REMOVE_IP = 'REMOVE_IP',
  ADD_PTR = 'ADD_PTR',
  REMOVE_PTR = 'REMOVE_PTR',
  LOOKUP_SUBNET = 'LOOKUP_SUBNET',
  LOOKUP_IP = 'LOOKUP_IP',
  GET_DNSSERVICES2 = 'GET_DNSSERVICES2',
  GET_SERVERS_IP = 'GET_SERVERS_IP'
}

export type IpSubnetActions =
    | GetSubnetIpRequestAction
    | GetSubnetIpResponseAction
    | GetSubnetIpErrorAction
    | ListSubnetIpRequestAction
    | ListSubnetIpResponseAction
    | ListSubnetIpErrorAction
    | CreateSubnetIpRequestAction
    | CreateSubnetIpResponseAction
    | CreateSubnetIpErrorAction
    | UpdateSubnetIpRequestAction
    | UpdateSubnetIpResponseAction
    | UpdateSubnetIpErrorAction
    | DeleteSubnetIpRequestAction
    | DeleteSubnetIpResponseAction
    | DeleteSubnetIpErrorAction
    | GetIpRequestAction
    | GetIpResponseAction
    | GetIpErrorAction
    | ListIpAddressRequestAction
    | ListIpAddressResponseAction
    | ListIpAddressErrorAction
    | CreateIpRequestAction
    | CreateIpResponseAction
    | CreateIpErrorAction
    | UpdateIpRequestAction
    | UpdateIpResponseAction
    | UpdateIpErrorAction
    | DeleteIpRequestAction
    | DeleteIpResponseAction
    | DeleteIpErrorAction

    | AddIpRequestAction
    | AddIpResponseAction
    | AddIpErrorAction
    | RemoveIpRequestAction
    | RemoveIpResponseAction
    | RemoveIpErrorAction

    | AddPtrRequestAction
    | AddPtrResponseAction
    | AddPtrErrorAction
    | RemovePtrRequestAction
    | RemovePtrResponseAction
    | RemovePtrErrorAction

    | LookupSubnetRequestAction
    | LookupSubnetResponseAction
    | LookupSubnetErrorAction
    | LookupIpRequestAction
    | LookupIpResponseAction
    | LookupIpErrorAction
    | GetDnsServicesRequestAction
    | GetDnsServicesResponseAction
    | GetDnsServicesErrorAction
    | GetServersIpRequestAction
    | GetServersIpResponseAction
    | GetServersIpErrorAction

export class GetSubnetIpRequestAction {
  readonly type = ActionType.GET_SUBNET_IP
  readonly mode = AsyncActionMode.REQUEST
  constructor(public payload: number) {}
}
export class GetSubnetIpResponseAction {
  readonly type = ActionType.GET_SUBNET_IP
  readonly mode = AsyncActionMode.RESPONSE
  constructor(public request: GetSubnetIpRequestAction, public data) {}
}
export class GetSubnetIpErrorAction {
  readonly type = ActionType.GET_SUBNET_IP
  readonly mode = AsyncActionMode.ERROR
  constructor(
    public request: GetSubnetIpRequestAction,
    public error: string,
  ) {}
}

export class ListSubnetIpRequestAction {
  readonly type = ActionType.LIST_SUBNET_IP
  readonly mode = AsyncActionMode.REQUEST
  constructor() {
    ''
  }
}

export class ListSubnetIpResponseAction {
  readonly type = ActionType.LIST_SUBNET_IP
  readonly mode = AsyncActionMode.RESPONSE
  constructor(
    public request: ListSubnetIpRequestAction,
    public data: SubnetGrid,
  ) {}
}

export class ListSubnetIpErrorAction {
  readonly type = ActionType.LIST_SUBNET_IP
  readonly mode = AsyncActionMode.ERROR
  constructor(
    public request: ListSubnetIpRequestAction,
    public error: string,
  ) {}
}

export class CreateSubnetIpRequestAction {
  readonly type = ActionType.CREATE_SUBNET_IP
  readonly mode = AsyncActionMode.REQUEST
  constructor(public payload: CreateSubnetIpParams) {}
}

export class CreateSubnetIpResponseAction {
  readonly type = ActionType.CREATE_SUBNET_IP
  readonly mode = AsyncActionMode.RESPONSE
  constructor(public request: CreateSubnetIpRequestAction, public data) {}
}

export class CreateSubnetIpErrorAction {
  readonly type = ActionType.CREATE_SUBNET_IP
  readonly mode = AsyncActionMode.ERROR
  constructor(
    public request: CreateSubnetIpRequestAction,
    public error: string,
  ) {}
}

export class UpdateSubnetIpRequestAction {
  readonly type = ActionType.UPDATE_SUBNET_IP
  readonly mode = AsyncActionMode.REQUEST
  constructor(public payload: UpdateSubnetIpParams) {
    ''
  }
}

export class UpdateSubnetIpResponseAction {
  readonly type = ActionType.UPDATE_SUBNET_IP
  readonly mode = AsyncActionMode.RESPONSE
  constructor(public request: UpdateSubnetIpRequestAction, public data) {}
}

export class UpdateSubnetIpErrorAction {
  readonly type = ActionType.UPDATE_SUBNET_IP
  readonly mode = AsyncActionMode.ERROR
  constructor(
    public request: UpdateSubnetIpRequestAction,
    public error: string,
  ) {}
}

export class DeleteSubnetIpRequestAction {
  readonly type = ActionType.DELETE_SUBNET_IP
  readonly mode = AsyncActionMode.REQUEST
  constructor(public id: number) {
    ''
  }
}

export class DeleteSubnetIpResponseAction {
  readonly type = ActionType.DELETE_SUBNET_IP
  readonly mode = AsyncActionMode.RESPONSE
  constructor(public request: DeleteSubnetIpRequestAction, public id: number) {}
}

export class DeleteSubnetIpErrorAction {
  readonly type = ActionType.DELETE_SUBNET_IP
  readonly mode = AsyncActionMode.ERROR
  constructor(
    public request: DeleteSubnetIpRequestAction,
    public error: string,
  ) {}
}

export class GetIpRequestAction {
  readonly type = ActionType.GET_IP
  readonly mode = AsyncActionMode.REQUEST
  constructor(public payload: number) {}
}
export class GetIpResponseAction {
  readonly type = ActionType.GET_IP
  readonly mode = AsyncActionMode.RESPONSE
  constructor(public request: GetIpRequestAction, public data: IpAddressModel) {}
}
export class GetIpErrorAction {
  readonly type = ActionType.GET_IP
  readonly mode = AsyncActionMode.ERROR
  constructor(
      public request: GetIpRequestAction,
      public error: string,
  ) {}
}

export class ListIpAddressRequestAction {
  readonly type = ActionType.LIST_IP
  readonly mode = AsyncActionMode.REQUEST
  constructor(
      public pageSize: number,
      public pageNumber: number
  ) {}
}

export class ListIpAddressResponseAction {
  readonly type = ActionType.LIST_IP
  readonly mode = AsyncActionMode.RESPONSE
  constructor(
      public request: ListIpAddressRequestAction,
      public data: IpAddressGrid,
  ) {}
}

export class ListIpAddressErrorAction {
  readonly type = ActionType.LIST_IP
  readonly mode = AsyncActionMode.ERROR
  constructor(
      public request: ListIpAddressRequestAction,
      public error: string,
  ) {}
}

export class CreateIpRequestAction {
  readonly type = ActionType.CREATE_IP
  readonly mode = AsyncActionMode.REQUEST
  constructor(public payload: CreateIpParams) {}
}

export class CreateIpResponseAction {
  readonly type = ActionType.CREATE_IP
  readonly mode = AsyncActionMode.RESPONSE
  constructor(public request: CreateIpRequestAction, public data) {}
}

export class CreateIpErrorAction {
  readonly type = ActionType.CREATE_IP
  readonly mode = AsyncActionMode.ERROR
  constructor(
      public request: CreateIpRequestAction,
      public error: string,
  ) {}
}

export class UpdateIpRequestAction {
  readonly type = ActionType.UPDATE_IP
  readonly mode = AsyncActionMode.REQUEST
  constructor(public payload: UpdateIpParams) {
    ''
  }
}

export class UpdateIpResponseAction {
  readonly type = ActionType.UPDATE_IP
  readonly mode = AsyncActionMode.RESPONSE
  constructor(public request: UpdateIpRequestAction, public data:IpAddressModel) {}
}

export class UpdateIpErrorAction {
  readonly type = ActionType.UPDATE_IP
  readonly mode = AsyncActionMode.ERROR
  constructor(
      public request: UpdateIpRequestAction,
      public error: string,
  ) {}
}

export class DeleteIpRequestAction {
  readonly type = ActionType.DELETE_IP
  readonly mode = AsyncActionMode.REQUEST
  constructor(public id: number) {
    ''
  }
}

export class DeleteIpResponseAction {
  readonly type = ActionType.DELETE_IP
  readonly mode = AsyncActionMode.RESPONSE
  constructor(public request: DeleteIpRequestAction, public id: number) {}
}

export class DeleteIpErrorAction {
  readonly type = ActionType.DELETE_IP
  readonly mode = AsyncActionMode.ERROR
  constructor(
      public request: DeleteIpRequestAction,
      public error: string,
  ) {}
}


export class AddIpRequestAction {
  readonly type = ActionType.ADD_IP
  readonly mode = AsyncActionMode.REQUEST
  constructor(public payload: AddIpParams) {
    ''
  }
}

export class AddIpResponseAction {
  readonly type = ActionType.ADD_IP
  readonly mode = AsyncActionMode.RESPONSE
  constructor(public request: AddIpRequestAction, public data: IpAddressModel) {}
}

export class AddIpErrorAction {
  readonly type = ActionType.ADD_IP
  readonly mode = AsyncActionMode.ERROR
  constructor(
      public request: AddIpRequestAction,
      public error: string,
  ) {}
}
export class RemoveIpRequestAction {
  readonly type = ActionType.REMOVE_IP
  readonly mode = AsyncActionMode.REQUEST
  constructor(public payload: AddIpParams) {
    ''
  }
}

export class RemoveIpResponseAction {
  readonly type = ActionType.REMOVE_IP
  readonly mode = AsyncActionMode.RESPONSE
  constructor(public request: RemoveIpRequestAction, public data: IpAddressModel) {}
}

export class RemoveIpErrorAction {
  readonly type = ActionType.REMOVE_IP
  readonly mode = AsyncActionMode.ERROR
  constructor(
      public request: RemoveIpRequestAction,
      public error: string,
  ) {}
}


export class AddPtrRequestAction {
  readonly type = ActionType.ADD_PTR
  readonly mode = AsyncActionMode.REQUEST
  constructor(public payload: EditPtrParams) {
    ''
  }
}

export class AddPtrResponseAction {
  readonly type = ActionType.ADD_PTR
  readonly mode = AsyncActionMode.RESPONSE
  constructor(public request: AddPtrRequestAction, public data: IpAddressModel) {}
}

export class AddPtrErrorAction {
  readonly type = ActionType.ADD_PTR
  readonly mode = AsyncActionMode.ERROR
  constructor(
      public request: AddPtrRequestAction,
      public error: string,
  ) {}
}
export class RemovePtrRequestAction {
  readonly type = ActionType.REMOVE_PTR
  readonly mode = AsyncActionMode.REQUEST
  constructor(public id: number ) {
    ''
  }
}

export class RemovePtrResponseAction {
  readonly type = ActionType.REMOVE_PTR
  readonly mode = AsyncActionMode.RESPONSE
  constructor(public request: RemovePtrRequestAction, public data: IpAddressModel) {}
}

export class RemovePtrErrorAction {
  readonly type = ActionType.REMOVE_PTR
  readonly mode = AsyncActionMode.ERROR
  constructor(
      public request: RemovePtrRequestAction,
      public error: string,
  ) {}
}




export class LookupSubnetRequestAction {
  readonly type = ActionType.LOOKUP_SUBNET
  readonly mode = AsyncActionMode.REQUEST
  constructor(public field: string) {
    ''
  }
}
export class LookupSubnetResponseAction {
  readonly type = ActionType.LOOKUP_SUBNET
  readonly mode = AsyncActionMode.RESPONSE
  constructor(
      public request: LookupSubnetRequestAction,
      public field: string,
      public data: string[],
  ) {}
}
export class LookupSubnetErrorAction {
  readonly type = ActionType.LOOKUP_SUBNET
  readonly mode = AsyncActionMode.ERROR
  constructor(public request: LookupSubnetRequestAction, public error: string) {}
}

export class LookupIpRequestAction {
  readonly type = ActionType.LOOKUP_IP
  readonly mode = AsyncActionMode.REQUEST
  constructor(public field: string,
              public type_id: number) {
    ''
  }
}
export class LookupIpResponseAction {
  readonly type = ActionType.LOOKUP_IP
  readonly mode = AsyncActionMode.RESPONSE
  constructor(
      public request: LookupIpRequestAction,
      public field: string,
      public data: string[],
  ) {}
}
export class LookupIpErrorAction {
  readonly type = ActionType.LOOKUP_IP
  readonly mode = AsyncActionMode.ERROR
  constructor(public request: LookupIpRequestAction, public error: string) {}
}


export class GetDnsServicesRequestAction {
  readonly type = ActionType.GET_DNSSERVICES2
  readonly mode = AsyncActionMode.REQUEST
  constructor() {
    ''
  }
}
export class GetDnsServicesResponseAction {
  readonly type = ActionType.GET_DNSSERVICES2
  readonly mode = AsyncActionMode.RESPONSE
  constructor(
      public request: GetDnsServicesRequestAction,
      public data: DnsServiceName[],
  ) {}
}
export class GetDnsServicesErrorAction {
  readonly type = ActionType.GET_DNSSERVICES2
  readonly mode = AsyncActionMode.ERROR
  constructor(public request: GetDnsServicesRequestAction, public error: string) {}
}

export class GetServersIpRequestAction {
	readonly type = ActionType.GET_SERVERS_IP
	readonly mode = AsyncActionMode.REQUEST
	constructor() {
		''
	}
}
export class GetServersIpResponseAction {
	readonly type = ActionType.GET_SERVERS_IP
	readonly mode = AsyncActionMode.RESPONSE
	constructor(public request: GetServersIpRequestAction, public data: ServerModel[]) {}
}
export class GetServersIpErrorAction {
	readonly type = ActionType.GET_SERVERS_IP
	readonly mode = AsyncActionMode.ERROR
	constructor(public request: GetServersIpRequestAction, public error: string) {}
}
