import { useTranslation } from "react-i18next"
import { Checkbox, Divider, Form, Input, message } from "antd"
import { useForm } from "antd/lib/form/Form"
import { useHistory } from "react-router"
import { useDispatch, useSelector } from "react-redux"
import { Card, Col, Row, Spin } from "antd"
import { ConsoleSqlOutlined } from "@ant-design/icons"
import React, { useEffect, useState } from "react"
import { AppState } from "../../common/models"
import { useParams } from "react-router-dom"
import { useLoggedUser } from "../../helpers/loginUserHelper"
import { LoadingIndicator } from "../../components"
import ErrorPage403 from "../../components/Errors/ErrorPage403"
import useLogger from "../../common/useLogger"
import getWebTemplate from "./actions/getWebTemplate"
import TextArea from "antd/es/input/TextArea"
import { shortStr } from "../../common/fce"
import Button from "antd-button-color"
import { formItemSlim } from "../../helpers/layoutHelpers"
import { Store } from "antd/lib/form/interface"
import updateWebTemplate from "./actions/updateWebTemplate"
import { UpdateWebTemplateVersionsParams, WebTemplateModel } from "./models"
import getPhpVersions from "./actions/getPhpVersions"
import getWebServerVersions from "./actions/getWebServerVersions"
import { CheckboxChangeEvent } from "antd/es/checkbox"
import updateWebTemplateVersions from "./actions/updateWebTemplateVersions"


const { Item } = Form

interface Props {
    serviceId: number | undefined
    onClose: () => void
}

interface ParamTypes {
    id: string
}

interface VersionItem {
    id: number
    name: string
    checked: boolean
}

const WebTemplateDetailPage = () => {
    const CONTROL_NAME = 'page_web_template_detail'
    const { t } = useTranslation()
    const [form] = useForm()
    const [form2] = useForm()
    const history = useHistory()
    const dispatch = useDispatch()
    const { id } = useParams<ParamTypes>()

    const { isLoadingTemplate, template, isSavingTemplate, php_versions, web_server_versions } = useSelector((state: AppState) => state.webservice)

    const [phpIds, setPhpIds] = useState<number[]>([])
    const [apacheIds, setApacheIds] = useState<number[]>([])
    const [versionItems, setVersionItems] = useState<VersionItem[]>([])

    const [updateStatus, setUpdateStatus] = useState<boolean>(false)
    const [updateStatus2, setUpdateStatus2] = useState<boolean>(false)

    const [isWebTemplateViewer, setWebTemplateViewer] = useState(false)
    const [isWebTemplateEditor, setWebTemplateEditor] = useState(false)


    // get settings and current user
    const loggedUser = useLoggedUser()
    if (!loggedUser || !loggedUser.isLoaded()) {
        // waiting..
        return (
            <div className="fullwidth-loading" >
                <LoadingIndicator />
            </div>
        )
    }

    // required authorization
    if (!loggedUser.hasAccess(CONTROL_NAME)) {
        return <ErrorPage403 />
    }

    // settings
    const appSetting = loggedUser.getAppSettings()
    const logger = useLogger(appSetting, 'WebTemplateDetailPage')

    useEffect(() => {
        // trigger ONCE
        const access = loggedUser.hasAccess(CONTROL_NAME)
        setWebTemplateViewer(access)
        setWebTemplateEditor(loggedUser.hasAccess('page_web_template_edit_button'))

        if (access && parseInt(id) > 0) {
            // loadOptions()
            const did = parseInt(id)
            dispatch(getWebTemplate(did))
            dispatch(getPhpVersions())
            dispatch(getWebServerVersions())
        }
        else {
            // failover 404
            history.replace('/web/templates')
        }
    }, [])

    useEffect(() => {
        if (template && php_versions.length && web_server_versions.length) {
            const arr: VersionItem[] = []
            php_versions.forEach((v, i) => {
                arr.push({ id: v.id, name: v.name, checked: !!template.php_version && template.php_version.some(item => item.id === v.id) })
            })
            web_server_versions.forEach((v, i) => {
                arr.push({ id: v.id, name: v.name, checked: !!template.apache_version && template.apache_version.some(item => item.id === v.id) })
            })

            setVersionItems(arr)

            //template.php_version && setPhpIds(template.php_version.map(item => item.id))
            //template.apache_version && setApacheIds(template.apache_version.map(item => item.id))
        }
    }, [template, php_versions, web_server_versions])


    const onChange = (e: CheckboxChangeEvent) => {
        let items = versionItems
        items.forEach((v, i) => {
            if (v.id === e.target.value) {
                v.checked = e.target.checked
            }
        })
        setVersionItems(items)
    }

    const onChangePhp = (e: CheckboxChangeEvent) => {
        if (e.target.checked) {
            const x = phpIds.concat(e.target.value)
            setPhpIds(x)
        }
        else {
            setPhpIds(phpIds.filter(item => item != e.target.value))
        }

    }

    const isChecked = (id: number): boolean => {
        if (template) {
            if (template.php_version?.find(v => v.id === id)) {
                return true
            }
            if (template.apache_version?.find(v => v.id === id)) {
                return true
            }
        }
        return false
    }

    const onFormSubmit = (values: Store) => {
        if (template) {
            const params: WebTemplateModel = {
                ...template,
                name: values.name,
                code: values.code,
                comment: values.comment
            }

            dispatch(updateWebTemplate(params, (suc) => {
                if (suc) {
                    message.success(t('general.success'))
                    setUpdateStatus(false)
                }
            }))
        }
    }

    const onForm2Submit = (values: Store) => {
        if (template && template.id) {
            const versions = versionItems.filter(x => x.checked).map(item => item.id)
            const params: UpdateWebTemplateVersionsParams = {
                id: template.id,
                versions: versions
            }
            dispatch(updateWebTemplateVersions(params, (suc) => {
                if (suc) {
                    message.success(t('general.success'))
                    setUpdateStatus2(false)
                }
            }))
        }
    }

    if (!appSetting || Object.keys(appSetting).length === 0) {
        return (<Spin />)
    }

    if (!template || !template.id) {
        return (<Spin />)
    }

    if (php_versions.length === 0) {
        return (<Spin />)
    }



    return (
        <>
            <Card
                title={<><ConsoleSqlOutlined /> &nbsp; {t('webPage.templates.template')} &nbsp; {shortStr(template.name, 64)}</>}
                className='WebTemplateDetailPage'
                loading={isLoadingTemplate}
            >
                <Row style={{ marginTop: '35px' }}>
                    <Col span={24} style={{ padding: '8px' }}>
                        <Form
                            onFinish={onFormSubmit}
                            form={form}
                            onChange={() => setUpdateStatus(true)}
                            initialValues={template}
                            {...formItemSlim}
                        >

                            <Item label={t('webPage.templates.name')} name='name'>
                                <Input />
                            </Item>

                            <Item label={t('webPage.templates.comment')} name='comment'>
                                <TextArea rows={3}
                                    data-gramm="false"
                                    data-gramm_editor="false"
                                    data-enable-grammarly="false" />
                            </Item>

                            <Item label={t('webPage.templates.code')} name='code'>
                                <TextArea rows={20}
                                    className='console-text'
                                    data-gramm="false"
                                    data-gramm_editor="false"
                                    data-enable-grammarly="false" />
                            </Item>

                            <Row style={{ marginTop: '35px' }}>
                                <Col span={2} className='right bold pad4'>
                                    <Button type='ghost' onClick={() => { history.push('/web/templates') }}>
                                        {t('general.cancel')}
                                    </Button>
                                </Col>
                                <Col span={22} className='pad4'>
                                    <Button type='primary'
                                        loading={isSavingTemplate}
                                        disabled={!updateStatus || !isWebTemplateEditor}
                                        htmlType='submit'>
                                        {t('general.save')}
                                    </Button>
                                </Col>
                            </Row>
                        </Form>
                    </Col>
                </Row>

                <Divider />
                <Row style={{ marginTop: '35px' }}>

                    <Col span={12}>
                        <Row>
                            <Col span={12} className='pad4 right bold'>Variables for Template</Col>
                            <Col span={12} className='pad4'></Col>
                        </Row>
                        <Row>
                            <Col span={12} className='pad4 right bold'>$fqdn</Col>
                            <Col span={12} className='pad4'>Full Qualified Domain Name</Col>
                        </Row>
                        <Row>
                            <Col span={12} className='pad4 right bold'>$ssl</Col>
                            <Col span={12} className='pad4'>Use https [ 0|1|2 ]</Col>
                        </Row>
                        <Row>
                            <Col span={12} className='pad4 right bold'>$http_aliases</Col>
                            <Col span={12} className='pad4'>Http aliases [(id,name),...]</Col>
                        </Row>
                        <Row>
                            <Col span={12} className='pad4 right bold'>$force_alias_redirect</Col>
                            <Col span={12} className='pad4'>Redirect aliases to target [ 0|1 ]</Col>
                        </Row>
                        <Row>
                            <Col span={12} className='pad4 right bold'>$redirect_target</Col>
                            <Col span={12} className='pad4'>Target domain for aliases</Col>
                        </Row>
                        <Row>
                            <Col span={12} className='pad4 right bold'>$force_https_redirect</Col>
                            <Col span={12} className='pad4'>Redirect http to https [ 0|1 ]</Col>
                        </Row>
                        <Row>
                            <Col span={12} className='pad4 right bold'>$custom_config</Col>
                            <Col span={12} className='pad4'>Custom config content</Col>
                        </Row>
                        <Row>
                            <Col span={12} className='pad4 right bold'>$fgdn</Col>
                            <Col span={12} className='pad4'>Full Qualified Domain Name (old admin)</Col>
                        </Row>
                    </Col>
                    <Col span={12}></Col>
                </Row>

                <Divider />

                <Form
                    onFinish={onForm2Submit}
                    form={form2}
                    onChange={() => setUpdateStatus2(true)}
                    {...formItemSlim}
                >
                    <Row style={{ marginTop: '35px' }}>
                        <Col span={14}>
                            <Row>
                                <Col span={16} className='pad4 right bold'>Web Server Versions</Col>
                                <Col span={8} className='pad4'></Col>
                            </Row>

                            {
                                web_server_versions.map(v => {
                                    return (
                                        <Row key={v.id}>
                                            <Col span={16} className='pad4 right'>
                                                {v.name}
                                            </Col>
                                            <Col span={8} className='pad4'>
                                                <Checkbox value={v.id} onChange={onChange} defaultChecked={isChecked(v.id)} />
                                            </Col>
                                        </Row>
                                    )
                                })
                            }

                        </Col>
                        <Col span={10}>
                            <Row>
                                <Col span={12} className='pad4 right bold'>PHP Versions</Col>
                                <Col span={12} className='pad4'></Col>
                            </Row>

                            {
                                php_versions.map(v => {
                                    return (
                                        <Row key={v.id}>
                                            <Col span={12} className='pad4 right'>
                                                {v.name}
                                            </Col>
                                            <Col span={12} className='pad4'>
                                                <Checkbox value={v.id} onChange={onChange} defaultChecked={isChecked(v.id)} />
                                            </Col>
                                        </Row>
                                    )
                                })
                            }
                        </Col>
                    </Row>
                    <Row style={{ marginTop: '35px' }}>
                        <Col span={2} className='right bold pad4'>
                            <Button type='ghost' onClick={() => { history.push('/web/templates') }}>
                                {t('general.cancel')}
                            </Button>
                        </Col>
                        <Col span={22} className='pad4'>
                            <Button type='primary'
                                loading={isSavingTemplate}
                                disabled={!updateStatus2 || !isWebTemplateEditor}
                                htmlType='submit'>
                                {t('general.save')}
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </Card>

        </>
    )
}

export default WebTemplateDetailPage
