import {protectedApiClient} from "../../../helpers/api";
import {handleApiErrorWithNotification} from "../../../helpers/errorHandling";
import {LookupWebServiceErrorAction, LookupWebServiceRequestAction, LookupWebServiceResponseAction} from "./index";



export default (field: string, qs: string, cb?: (isSuccess: boolean) => void) => {
    return (
        dispatch: (
            arg: LookupWebServiceRequestAction | LookupWebServiceResponseAction | LookupWebServiceErrorAction,
        ) => void,
    ) => {
        const request = new LookupWebServiceRequestAction(field)
        dispatch(request)
        let url = `/rdb/service/lookup?`+qs
        protectedApiClient
            .get<string[]>(url)
            .then((response) => {
                dispatch(new LookupWebServiceResponseAction(request, field, response.data))
                cb && cb(true)
            })
            .catch((error) => {
                dispatch(new LookupWebServiceErrorAction(request, error.toString()))
                handleApiErrorWithNotification(error)
                cb && cb(false)
            })
    }
}
