import { GetDocsTocRequestAction, GetDocsTocResponseAction, GetDocsTocErrorAction } from '.'
import {protectedApiClient} from 'helpers/api'
import {handleApiErrorWithNotification} from 'helpers/errorHandling'
import { TocItem } from '../models'

export default (cb?: (isSuccess: boolean) => void) => {
    return (dispatch: (args: GetDocsTocRequestAction | GetDocsTocResponseAction | GetDocsTocErrorAction) => void) => {
        const request = new GetDocsTocRequestAction()
        dispatch(request)

        protectedApiClient
            .get<TocItem[]>(`/zdoc/toc`)
            .then((response) => {
                dispatch(new GetDocsTocResponseAction(request, response.data))
                cb && cb(true)
            })
            .catch(error => {
                dispatch(new GetDocsTocErrorAction(request, error))
                handleApiErrorWithNotification(error)
                cb && cb(false)
            })
    }
}
