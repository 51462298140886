import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {Form, Input, InputNumber, message, Select, Spin, Switch} from 'antd'
import {formItemLayout, tailLayout} from 'helpers/layoutHelpers'
import {DnsServiceModel} from 'pages/dns/service/models'
import Button from 'antd-button-color'
import {AppState} from 'common/models'
import {useTranslation} from 'react-i18next'
import updateDnsService from "./actions/updateDnsService";
import createDnsService from "./actions/createDnsService";
import {Store} from "antd/lib/form/interface";
import {useForm} from "antd/lib/form/Form";
import {_isValidDomain, _isValidIP4} from "../../../common/fce";
import {sort_label} from "../../../common/sorting";
import tableCustomers from "../../login/actions/tableCustomers";
import getDnsServices from "./actions/getDnsServices";
import {CheckOutlined, CloseOutlined} from "@ant-design/icons";
import getServersDns from "./actions/getServersDns";
import ServerSelector from "../../server/ServerSelector";
import IpSelector from "../../ip/subnet/IpSelector";
import {useLoggedUser} from "../../../helpers/loginUserHelper";


interface Props {
    serviceId: number | undefined
    onClose: () => void
}

const {Item} = Form


const DnsServiceForm = (props: Props) => {
    const [form] = useForm()
    const {t} = useTranslation()
    const dispatch = useDispatch()

    const {self} = useSelector((state: AppState) => state.auth)
    const {customers} = useSelector((state: AppState) => state.auth.tables)
    const {isSaving, dnsService, isLoadingService, servers, isLoadingServers} = useSelector((state: AppState) => state.dnsservice)

    const [objToUpdate, setObjToUpdate] = useState<DnsServiceModel>()
    const [customerOptions, setCustomerOptions] = useState<{ label: string, value: number, key: number }[]>([])
    const [serverOptions, setServerOptions] = useState<{ label: string, value: number, key: number }[]>([])
    const [updateStatus, setUpdateStatus] = useState<boolean>(false)
    const [isActive, setActive] = useState<boolean>(true)
    const [selectedServerId, setSelectedServerId] = useState<number | undefined>()

    const isNewService = () => { return !(props.serviceId && props.serviceId > 0) }

    // get settings and logged user from store
    const loggedUser = useLoggedUser()
    if (!loggedUser || !loggedUser.isLoaded()) {
        return (<Spin/>)
    }
    const appSetting = loggedUser.getAppSettings()

    useEffect(() => {
        // once
        setObjToUpdate(getEmptyService())
        form.setFieldsValue(getEmptyService())
        if(!customers || customers.length === 0) {
            dispatch(tableCustomers())
        }
        // isNewService() && dispatch(getServersDns())
    }, [])

    useEffect(() => {
        if (customers) {
            const data = customers.map((c) => {return {
                value: c.id,
                key: c.id,
                label: c.company!.name
            }})
            setCustomerOptions(data.sort(sort_label))
        }
    }, [customers])

    useEffect(() => {
        if (servers) {
            const data = servers.map((s) => {return {
                value: s.id,
                key: s.id,
                label: s.hostname
            }})
            setServerOptions(data.sort(sort_label))
        }
    }, [servers])

    useEffect(() => {
        // trigger from Side menu clicked
        if (dnsService) {
            setObjToUpdate(dnsService)
            setSelectedServerId(dnsService.server_id)
            dnsService.is_active ? setActive(true) : setActive(false)

            if (dnsService.params) {
                // @ts-ignore
                const {token} = dnsService.params;
                form.setFieldsValue({...dnsService, token_value: token})
            }
        }
    }, [dnsService])

    const loadServerLookup = (pattern: string) => {
        if (appSetting.checkMinSearch(pattern)) {
            dispatch(getServersDns(pattern))
        }
    }

    const getEmptyService = () => {
        return {
            id: 0,
            owner_id: self!.customer_id,
            driver_id: 1,
            protocol: "https",
            host: '',
            server_id: undefined,
            port: 443,
            params: "{}",
            instance: 'localhost',
            username: '',
            password: '',
            base_url: '',
            token_name: '',
            is_active: 1,
            token_value: ''
        }
    }

    const isEdit = (): boolean => {
        // edit or create
        return !!(dnsService && dnsService.id)
    }

    const onFormSubmit = (values: Store) => {
        if (!isEdit()) {
            const params = {
                owner_id: values.owner_id,
                host: values.host,
                server_id: selectedServerId,
                driver_id: values.driver_id,
                protocol: values.protocol,
                port: values.port,
                instance: values.instance,
                is_active: isActive ? 1 : 0,
                params: JSON.stringify({token: values.token_value})
            }
            dispatch(createDnsService(params, (suc) => {
                if (suc) {
                    //dispatch(getDnsServices())
                    message.success(t('general.success'))
                    props.onClose()
                }
            }))
        }
        else {
            const param2 = {
                id: dnsService!.id,
                host: values.host,
                owner_id: values.owner_id,
                server_id: values.server_id,
                driver_id: values.driver_id,
                protocol: values.protocol,
                port: values.port,
                instance: values.instance,
                is_active: isActive ? 1 : 0,
                params: JSON.stringify({token: values.token_value})
            }
            dispatch(updateDnsService(param2, suc => {
                if (suc) {
                    message.success(t('general.success'))
                    props.onClose()
                }
            }))
        }
    }

    if (!isNewService() && isLoadingService) {
        return <Spin />
    }

    return (
        <Form
            onFinish={onFormSubmit}
            form={form}
            onChange={() => setUpdateStatus(true)}
            initialValues={objToUpdate}
            {...formItemLayout}
        >
            <Form.Item name='owner_id' label={t('domainPage.customer')}>
                <Select optionFilterProp='label'
                        style={{width: '100%'}}
                        options={customerOptions}
                        disabled={isEdit()}
                />
            </Form.Item>

            <Item label={t('dnsPage.driver')} name='driver_id' rules={[{required: true}]}>
                <Select options={[
                            {label: 'PowerDns', value: 1, key: 'PowerDns'},
                            {label: 'Amazon', value: 2, key: 'Amazon'}
                        ]}
                        disabled={isEdit()}
                />
            </Item>

            <Form.Item name='server_id' label={t('dnsPage.server')}>
                <ServerSelector
                    dataSource={servers}
                    isLoading={isLoadingServers}
                    handleSelectedServer={(sid) => {setSelectedServerId(sid); form.setFieldsValue([{server_id: sid}])}}
                    fetchData={loadServerLookup}
                />
            </Form.Item>

            <Item label={t('dnsPage.protocol')} name='protocol' rules={[{required: true}]}>
                <Select options={[
                    {label: 'http', value: 'http', key: 'http'},
                    {label: 'https', value: 'https', key: 'https'}
                ]}/>
            </Item>

            <Item label={t('dnsPage.port')} name='port' rules={[{required: true}]}>
                <InputNumber min={0} max={65535} step={1}/>
            </Item>

            <Item label={t('dnsPage.instance')} name='instance'>
                <Input/>
            </Item>

            <Item label={t('dnsPage.token_value')+' X-API-Key'} name='token_value'>
                <Input/>
            </Item>

            <Item label={t('dnsPage.is_active')} name='is_active'>
                <Switch
                    checkedChildren={<CheckOutlined />}
                    unCheckedChildren={<CloseOutlined />}
                    checked={isActive}
                    onChange={() => {setActive(!isActive)}}
                />
            </Item>

            <Item {...tailLayout}>
                <Button type='primary'
                        loading={isSaving}
                        disabled={!selectedServerId}
                        htmlType='submit'>
                    {isEdit() ? t('general.save') : t('general.create')}
                </Button>
            </Item>
        </Form>
    )
}

export default DnsServiceForm
