import {GetWebTemplateRequestAction, GetWebTemplateResponseAction, GetWebTemplateErrorAction} from './index'
import {protectedApiClient} from 'helpers/api'
import {handleApiErrorWithNotification} from 'helpers/errorHandling'
import {WebTemplateModel} from '../models'

export default (id: number, cb?: (isSuccess: boolean) => void) => {
    return (dispatch: (arg: GetWebTemplateRequestAction | GetWebTemplateResponseAction | GetWebTemplateErrorAction) => void) => {
        const request = new GetWebTemplateRequestAction(id)
        dispatch(request)

        protectedApiClient
            .get<WebTemplateModel>(`/web/template/${id}?expand=apache_version,php_version`)
            .then((response) => {
                dispatch(new GetWebTemplateResponseAction(request, response.data))
                cb && cb(true)
            })
            .catch((error) => {
                dispatch(new GetWebTemplateErrorAction(request, error.toString()))
                handleApiErrorWithNotification(error)
                cb && cb(false)
            })
    }
}
