import {CreateDomainVhostErrorAction, CreateDomainVhostRequestAction, CreateDomainVhostResponseAction} from '.';
import { protectedApiClient } from 'helpers/api'
import { handleApiErrorWithNotification } from 'helpers/errorHandling'
import { CreateDomainParam } from '../../dns/service/models'
import { DomainModel } from 'pages/dns/service/models'


export default (
    params: CreateDomainParam,
    cb?: (isSuccess: boolean, domainId?: number) => void
) => {
    return (
        dispatch: (
            arg:
                | CreateDomainVhostRequestAction
                | CreateDomainVhostResponseAction
                | CreateDomainVhostErrorAction
        ) => void
    ) => {
        const request = new CreateDomainVhostRequestAction(params)
        dispatch(request)
        protectedApiClient
            .post<DomainModel>(`/customer/${params.id}/domain?expand=services`, params)
            .then((response) => {
                dispatch(new CreateDomainVhostResponseAction(request, response.data))
                cb && cb(true, response.data.id)
            })
            .catch((error) => {
                dispatch(new CreateDomainVhostErrorAction(request, error.toString()))
                handleApiErrorWithNotification(error)
                cb && cb(false, undefined)
            })
    }
}
