import {
    DeleteStatusPlanRequestAction,
    DeleteStatusPlanResponseAction,
    DeleteStatusPlanErrorAction,
} from '.'
import { protectedApiClient } from 'helpers/api'
import { handleApiErrorWithNotification } from 'helpers/errorHandling'

export default (id: number, cb?: (isSuccess: boolean) => void) => {
    return (
        dispatch: (
            arg:
                | DeleteStatusPlanRequestAction
                | DeleteStatusPlanResponseAction
                | DeleteStatusPlanErrorAction,
        ) => void,
    ) => {
        const request = new DeleteStatusPlanRequestAction(id)
        dispatch(request)

        protectedApiClient
            .delete(`/server/status-scheduler/${id}`)
            .then((response) => {
                dispatch(new DeleteStatusPlanResponseAction(request, response.data))
                cb && cb(true)
            })
            .catch((error) => {
                dispatch(new DeleteStatusPlanErrorAction(request, error))
                handleApiErrorWithNotification(error)
                cb && cb(false)
            })
    }
}
