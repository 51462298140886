import { protectedApiClient } from '../../../helpers/api'
import { handleApiErrorWithNotification } from '../../../helpers/errorHandling'
import { LookupMailDomainErrorAction, LookupMailDomainRequestAction, LookupMailDomainResponseAction } from './index'

export default (field: string, qs: string, cb?: (isSuccess: boolean) => void) => {
	return (
		dispatch: (
			arg: LookupMailDomainRequestAction | LookupMailDomainResponseAction | LookupMailDomainErrorAction
		) => void
	) => {
		const request = new LookupMailDomainRequestAction(field)
		dispatch(request)
		let url = `/mail/domain/lookup?` + qs
		protectedApiClient
			.get<string[]>(url)
			.then((response) => {
				dispatch(new LookupMailDomainResponseAction(request, field, response.data))
				cb && cb(true)
			})
			.catch((error) => {
				dispatch(new LookupMailDomainErrorAction(request, error))
				handleApiErrorWithNotification(error)
				cb && cb(false)
			})
	}
}
