import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from "react-redux"
import { AppState } from "../../../common/models"
import { Button, Col, Form, Input, InputNumber, message, Row, Select, Spin, Switch } from "antd"
import { halfLayout } from "../../../helpers/layoutHelpers"
import { useForm } from "antd/lib/form/Form"
import { Store } from "antd/lib/form/interface"
import createDomain from "./actions/createDomain"
import { useLoggedUser } from "../../../helpers/loginUserHelper"
import { LoadingIndicator } from "../../../components"
import { CustomerDetails } from "../../billing/customer/models"
import { sort_ident } from "../../../common/sorting"
import tableCustomers from "../../login/actions/tableCustomers"
import { CheckOutlined, CloseOutlined } from "@ant-design/icons"
import getDnsServices from "./actions/getDnsServices"
import { _isValidDomain } from "../../../common/fce"


interface Props {
    closeModal: () => void
}

const NewDomainForm = (props: Props) => {
    const { t } = useTranslation()
    const [form] = useForm()
    const dispatch = useDispatch()

    const { customers } = useSelector((state: AppState) => state.auth.tables)
    const { isSaving, dnsServiceNames } = useSelector((state: AppState) => state.dnsservice)

    const [name, setName] = useState<string>('')
    const [customerSource, setCustomerSource] = useState<CustomerDetails[]>([])
    const [selectedCustomerId, setSelectedCustomerId] = useState<number | undefined>()
    const [serviceOptions, setServiceOptions] = useState<{ label: string, value: number }[]>([])
    const [selectedServiceId, setSelectedServiceId] = useState<number | undefined>()
    const [updateStatus, setUpdateStatus] = useState<boolean>(false)
    const [createDns, setCreateDns] = useState<boolean>(false)


    // get settings and logged user from store
    const loggedUser = useLoggedUser()
    if (!loggedUser || !loggedUser.isLoaded()) {
        return (
            <div className="fullwidth-loading">
                <LoadingIndicator />
            </div>
        )
    }
    const appSetting = loggedUser.getAppSettings()

    useEffect(() => {
        // once
        if (customers && customers.length) {
            const data = customers.map((c) => { return { ...c, ident: c.company!.name } })
            setCustomerSource(data.sort(sort_ident))
        }
        else {
            dispatch(tableCustomers())
        }

        if (!dnsServiceNames || !dnsServiceNames.length) {
            dispatch(getDnsServices())
        }
    }, [])

    useEffect(() => {
        setSelectedCustomerId(loggedUser.customer.id)
    }, [dispatch, customers])

    useEffect(() => {
        if (dnsServiceNames.length > 0) {
            console.log(JSON.stringify(dnsServiceNames))
            setServiceOptions(dnsServiceNames.map(dns => ({ label: dns.name, value: dns.id })))
        }
    }, [dnsServiceNames])

    const onFormSubmit = (values: Store) => {
        // save data
        if (!selectedCustomerId) {
            message.error('Choose Customer')
            return
        }
        if (createDns && !selectedServiceId) {
            message.error('Choose DnsService')
            return
        }

        if (name) {
            const customer_id = loggedUser.user.is_zcom ? selectedCustomerId : loggedUser.customer.id
            let params = { id: customer_id, name: name, nameserver_id: selectedServiceId }
            if (!createDns) {
                params = { id: customer_id, name: name, nameserver_id: undefined }
            }
            dispatch(createDomain(params, (suc: boolean) => {
                if (suc) {
                    props.closeModal()
                    message.success(t('general.success'))
                }
            }))
        }
    }

    if (!customers || customers.length === 0) {
        return (<Spin />)
    }

    return (
        <Form
            {...halfLayout}
            onFinish={onFormSubmit}
            form={form}
            onChange={() => setUpdateStatus(true)}
            initialValues={{ customer_id: loggedUser.user.customer_id, service_id: selectedServiceId }}
        >

            <Form.Item name='name' label={t('domainPage.domain')}
                rules={[
                    { required: true, message: t('errors.field_required') },
                    () => ({
                        validator(_, value) {
                            if (_isValidDomain(value)) {
                                return Promise.resolve()
                            }
                            return Promise.reject(new Error(t('domainPage.invalid_domain')))
                        },
                    })
                ]}
                hasFeedback
            >
                <Input style={{ width: '100%' }} placeholder='www.domain.com'
                    onChange={(e) => setName(e.target.value)}
                />
            </Form.Item>

            <Form.Item name='customer_id' label={t('domainPage.customer')}
                rules={[{ required: true, message: t('errors.field_required') }]}
            >
                <Select optionFilterProp='label'
                    style={{ width: '100%' }}
                    disabled={!loggedUser.user.is_zcom}
                    options={customerSource.map(i => ({
                        value: i.id,
                        key: i.id,
                        label: i.ident
                    }))}
                    onChange={(value: number) => {
                        setSelectedCustomerId(value)
                    }}
                />
            </Form.Item>

            <Form.Item name='create_dns' label={t('domainPage.create_dns')}
                valuePropName='checked'
            >
                <Switch
                    checkedChildren={<CheckOutlined />}
                    unCheckedChildren={<CloseOutlined />}
                    checked={createDns}
                    onChange={() => {
                        setCreateDns(!createDns)
                    }}
                />
            </Form.Item>

            <Form.Item name='service_id' label={t('domainPage.nameserver')}>
                <Select optionFilterProp='label'
                    style={{ width: '100%' }}
                    options={serviceOptions}
                    disabled={!createDns}
                    onChange={(value: number) => {
                        setSelectedServiceId(value)
                    }}
                />
            </Form.Item>

            <Row>
                <Col span={12}></Col>
                <Col span={12}>
                    <Button loading={isSaving} type='primary' htmlType='submit'
                        disabled={!updateStatus || ((createDns && !selectedServiceId))}>
                        {t('domainPage.save')}
                    </Button>
                </Col>
            </Row>

        </Form>
    )
}

export default NewDomainForm
