import React, { useState, useEffect, ReactNode, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Card, Button, Modal, Row, Col, Space, Table, AutoComplete, Spin, Tag, Form, Select, Input, message } from 'antd'
import { EditOutlined, InfoCircleOutlined } from '@ant-design/icons/lib/icons'
import { useTranslation } from 'react-i18next'
import getAsyncJobs from './actions/getAsyncJobs'
import { AsyncJobModel } from './models'
import AsyncJobForm from 'pages/asyncjob/job/AsyncJobForm'
import { InfoCircleTwoTone, MedicineBoxTwoTone, PlayCircleOutlined } from "@ant-design/icons"
import DateRange from "../../../components/RangeFilter/DateRange"
import UserStorage from "../../../common/userStorage"
import { useLoggedUser } from "../../../helpers/loginUserHelper"
import { LoadingIndicator } from "../../../components"
import ErrorPage403 from "../../../components/Errors/ErrorPage403"
import useLogger from "../../../common/useLogger"
import { useHistory } from "react-router"
import moment, { Moment } from "moment"
import { AppState } from "../../../common/models"
import { ColumnsType } from "antd/lib/table"
import queryString, { ParsedQuery } from "query-string"
import { Link, useLocation } from "react-router-dom"
import { removeDiac, SQL_DATE_FORMAT, stopPropagation } from "../../../common/fce"
import getJobNames from "./actions/getJobNames"
import getJobStates from "./actions/getJobStates"
import usePageSize from "../../../common/usePageSize"
import Pager from "../../../components/pagination/pager"
import getAsyncJob from "./actions/getAsyncJob"
import { sort_label } from "../../../common/sorting"
import lookupServerJob from "./actions/lookupServerJob"
import findServerByHostname from "./actions/findServerByHostname"
import clearServer from "./actions/clearServer"
import './AsyncJobPage.scss'
import Draggable, { DraggableData, DraggableEvent } from "react-draggable"
import HistoryModal from "../../../components/History/HistoryModal"
import AsyncLog from 'components/History/AsyncLog'
import getLastAccess from './actions/getLastAccess'


interface Props { }

const initRangeStr = (): [string, string | undefined] => {
	let before30days = moment().add(-30, 'day')
	return [before30days.format(SQL_DATE_FORMAT), undefined]
}

const renderJobState = (state: string) => {
	if (!state) {
		return ''
	}
	if (state === 'new') {
		return <Tag color="blue" className='bold'>NEW</Tag>
	}
	if (state === 'waiting') {
		return <Tag color="gold" className='bold'>WAITING</Tag>
	}
	if (state === 'ready') {
		return <Tag color="orange" className='bold'>READY</Tag>
	}
	if (state === 'running') {
		return <Tag color="red" className='bold'>RUNNING</Tag>
	}
	if (state === 'done') {
		return <Tag color="#87d068">OK</Tag>
	}
	if (state === 'error') {
		return <Tag color="#f50000">ERROR</Tag>
	}
	if (state === 'error_resolved') {
		return <Tag color="#9400D3">RESOLVED</Tag>
	}
	if (state === 'cancelled') {
		return <Tag color="">CANCELLED</Tag>
	}
}

const AsyncJobPage = (props: Props) => {
	const CONTROL_NAME = 'page_jobs'
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const history = useHistory()
	const { search } = useLocation()
	let timer: any

	const { jobs, pager, job, server, isLoading, server_lookup, isLoadingLookup, job_names, job_states, server_runner_log } = useSelector((state: AppState) => state.asyncjob)
	const { a_jobs } = useSelector((state: AppState) => state.sidenav)

	const [dataSource, setDataSource] = useState<AsyncJobModel[]>([])
	const [pageNumber, setPageNumber] = useState<number>(1)
	const [qsFilter, setQsFilter] = useState<string>('')
	const [selectedJobId, setSelectedJobId] = useState<number | undefined>()

	// options
	const [stateOptions, setStateOptions] = useState<{ label: string | ReactNode, value: string }[]>([])
	const [nameOptions, setNameOptions] = useState<{ label: string | ReactNode, value: string }[]>([])
	const [searchHostnameLookup, setSearchHostnameLookup] = useState<string>('')
	const [searchHostname, setSearchHostname] = useState<string>('')
	const [hostnameOptions, setHostnameOptions] = useState<{ label: string | ReactNode, value: string }[]>([])


	// filters
	const [parsed, setParsed] = useState<ParsedQuery<string>>(queryString.parse(search))
	const [rangeStart, setRangeStart] = useState<string>('')
	const [rangeEnd, setRangeEnd] = useState<string | undefined>()
	const [searchState, setSearchState] = useState<string | undefined>()
	const [searchName, setSearchName] = useState<string | undefined>()
	const [searchId, setSearchId] = useState<string | undefined>()
	const [searchGroupId, setSearchGroupId] = useState<string | undefined>()
	const [ready, setReady] = useState<number>(0)
	const [refreshInterval, setRefreshInterval] = useState<number>(0)
	const [refreshOptions, setRefreshOptions] = useState<{ label: string | ReactNode, value: number }[]>([])

	const [counter, setCounter] = useState(0)
	const [isRunning, setIsRunning] = useState(false)

	const [isModalEditVisible, setModalEditVisible] = useState<boolean>(false)
	const [isHistoryModalVisible, setHistoryModalVisible] = useState(false)
	const [historyTitle, setHistoryTitle] = useState('')
	const [historyModelId, setHistoryModelId] = useState<number | undefined>()

	const [isJobViewer, setJobViewer] = useState(false)
	const [isJobEditor, setJobEditor] = useState(false)
	const [isServerViewer, setServerViewer] = useState(false)


	// get settings and current user
	const loggedUser = useLoggedUser()
	if (!loggedUser || !loggedUser.isLoaded()) {
		// waiting..
		return (
			<div className="fullwidth-loading" >
				<LoadingIndicator />
			</div>
		)
	}

	// required authorization
	if (!loggedUser.hasAccess(CONTROL_NAME)) {
		return <ErrorPage403 />
	}

	// settings
	const appSetting = loggedUser.getAppSettings()
	const SEARCH_MIN = appSetting.min_search_length

	// usage: logger(msg, obj=null)
	const logger = useLogger(appSetting, 'AsyncJobPage')
	const [pageSize, setPageSize] = useState<number>(appSetting.grid_page_size)
	usePageSize(appSetting, loggedUser.user.id, pageSize)

	// history drag modal
	const draggleRef = useRef<HTMLDivElement>(null)
	const [bounds, setBounds] = useState({ left: 0, top: 0, bottom: 0, right: 0 })
	const onStart = (_event: DraggableEvent, uiData: DraggableData) => {
		const { clientWidth, clientHeight } = window.document.documentElement
		const targetRect = draggleRef.current?.getBoundingClientRect()
		if (!targetRect) {
			return
		}
		setBounds({
			left: -targetRect.left + uiData.x,
			right: clientWidth - (targetRect.right - uiData.x),
			top: -targetRect.top + uiData.y,
			bottom: clientHeight - (targetRect.bottom - uiData.y),
		})
	}

	// to handle SideNav menu pressed after 1sec
	const [triggerOneTimer, setTriggerOneTimer] = useState(0)

	useEffect(() => {
		setTriggerOneTimer(Date.now().valueOf())

		const access = loggedUser.hasAccess('page_jobs')
		setJobViewer(access)
		if (!access) {
			// failover 403
			message.error('No permissions!')
			history.replace('/')
			return
		}

		setRefreshOptions([
			{ label: 'no refresh', value: 0 },
			{ label: '1 minute', value: 1 },
			{ label: '5 minutes', value: 5 },
			{ label: '10 minutes', value: 10 }
		])

		setJobEditor(loggedUser.hasAccess('page_jobs_edit_button'))
		setServerViewer(loggedUser.hasAccess('page_servers'))
		loadOptions()
		initializeRange()
		dispatch(getLastAccess(2485))
	}, [])

	useEffect(() => {
		// render data
		if (filterIsValid()) {
			refreshGrid()
		}
	}, [jobs])

	useEffect(() => {
		// SideNav menu pressed
		const when = triggerOneTimer + 1000
		if (triggerOneTimer > 0 && when < Date.now().valueOf()) {
			logger('--- SideNav menu pressed')
			clearFilters()
			fetchRecords(1, pageSize)
		}
	}, [a_jobs])

	useEffect(() => {
		// check if Ready
		if (job_states && job_states.length && job_names && job_names.length) {
			// options are loaded
			setStateOptions(job_states.map(item => ({ label: item.name, value: item.id })))
			setNameOptions(job_names.map(item => ({ label: item.name, value: item.id })))
			setReady(ready + 1)
		}
	}, [job_states, job_names])

	useEffect(() => {
		// update QsFilter
		const qs: string = prepareQsFilter()
		logger(`qsFilter:${qs}`)
		if (qs != qsFilter) {
			setQsFilter(qs)
		}
	}, [rangeStart, rangeEnd, searchState, searchName, server, searchId, searchGroupId])

	useEffect(() => {
		// find server by hostname
		if (appSetting.checkMinSearch(searchHostname)) {
			dispatch(findServerByHostname(1, 0, `hostname=${searchHostname}`, suc => { }))
		}
		else {
			dispatch(clearServer())
		}
	}, [searchHostname])

	useEffect(() => {
		// if (server)
	}, [server])

	useEffect(() => {
		// populate hostnameOptions
		setHostnameOptions(server_lookup.map(s => ({ label: s, value: s })))
	}, [server_lookup])

	useEffect(() => {
		if (refreshInterval > 0) {
			if (counter > (refreshInterval * 60)) {
				fetchRecords(pageNumber, pageSize)
				setCounter(0)
			}
		}
	}, [counter])

	useEffect(() => {
		let timer: NodeJS.Timeout
		if (isRunning) {
			timer = setInterval(() => {
				setCounter((prev) => prev + 1)
			}, 1000)
		}
		return () => clearInterval(timer)
	}, [isRunning])

	useEffect(() => {
		// when filter is changed
		// show the first page
		logger(`qsFilter changed: page: ${pageNumber}, pageSize: ${pageSize}, qs=${qsFilter}`)
		let pn = pageNumber
		if (ready) {    // do not change page for F5
			pn = 1
		}
		fetchRecords(pn, pageSize)
	}, [qsFilter])

	useEffect(() => {
		// process Options
		if (ready) {
			fetchRecords(pageNumber, pageSize)
		}
	}, [ready])

	// ------------------------------ end hooks

	const fetchRecords = (pn: number, ps: number) => {
		if (!isJobViewer) {
			return
		}
		setPageNumber(pn)
		setPageSize(ps)
		if (ready && filterIsValid()) {
			// purchase_from is required
			logger(`fetchRecords: page: ${pn}, pageSize: ${ps}, qs=${qsFilter}`)
			dispatch(getAsyncJobs(ps, pn - 1, qsFilter, suc => { }))
		}
	}

	const loadJob = (jid: number) => {
		// load job with expands
		jid && dispatch(getAsyncJob(jid))
	}

	const refreshGrid = () => {
		logger('refreshGrid ')
		setDataSource(jobs.items)
	}

	const filterIsValid = (): boolean => {
		// purchase_from is required
		return !!qsFilter && qsFilter.includes('created_from=')
	}

	const loadOptions = () => {
		// load Options for refresh F5
		if (!job_names || job_names.length === 0) {
			dispatch(getJobNames())
		}
		if (!job_states || job_states.length === 0) {
			dispatch(getJobStates())
		}
	}

	const initializeRange = () => {
		// set default range (from 2000 till now)
		logger(`initializeRange`)
		const [from, to] = initRangeStr()
		setRangeStart(from)
		setRangeEnd(to)
	}

	const handleDateChange = (dt_from: string, dt_to: string) => {
		// use SQL_DATE_FORMAT
		if (dt_from && dt_to) {
			logger(`create_from: ${dt_from} - created_to: ${dt_to}`)
			setRangeStart(dt_from)
			setRangeEnd(dt_to)
		}
	}

	const getQSFilter = (): string[] => {
		let qs: string[] = []
		if (rangeStart) {
			let dt1 = moment(rangeStart)
			qs.push('created_from=' + dt1.format(SQL_DATE_FORMAT))
		}
		if (rangeEnd) {
			let dt2 = moment(rangeEnd)
			qs.push('created_to=' + dt2.format(SQL_DATE_FORMAT))
		}
		return qs
	}

	const prepareQsFilter = (): string => {
		// load filtered data from server
		let qs: string[]
		qs = getQSFilter()
		if (appSetting.checkMinSearch(searchState)) {
			qs.push('state=' + searchState)
		}
		if (searchName && appSetting.checkMinSearch(searchName)) {
			qs.push('name=' + encodeURIComponent(searchName))
		}
		if (searchId && parseInt(searchId) > 0) {
			qs.push('id=' + searchId)
		}
		if (searchGroupId && parseInt(searchGroupId) > 0) {
			qs.push('group_id=' + searchGroupId)
		}
		if (server && server.id) {
			qs.push('server_id=' + server.id)
		}
		logger('prepareQsFilter: ' + qs.join("&"))
		return qs.join("&")
	}

	const clearFilters = () => {
		// used when clicked on left menu
		logger('clearFilters')
		onClearName()
		onClearState()
	}

	const onClearHostname = () => {
		setSearchHostnameLookup('')
		setSearchHostname('')
		setHostnameOptions([])
	}

	const onChangeHostnameLookup = (data: string) => {
		if (!data) {
			if (searchHostnameLookup.length === 1) {
				setSearchHostnameLookup('')
				fetchHostnameLookup('')
			}
			return
		}
		if (data != searchHostnameLookup) {
			setSearchHostnameLookup(data)
			fetchHostnameLookup(data)
		}
	}

	const fetchHostnameLookup = (searchText: string) => {
		// call lookup serial
		if (appSetting.checkMinSearch(searchText)) {
			let qs: string[]
			qs = getQSFilter()
			qs.push('field=hostname')
			qs.push('value=' + encodeURIComponent(searchText.trim()))
			logger('lookupServerJob: ' + qs.join("&"))
			dispatch(lookupServerJob('hostname', qs.join("&")))
		}
	}

	const onSelectHostname = (data: string) => {
		setSearchHostname(data)
	}

	const onClearState = () => {
		setSearchState(undefined)
		setStateOptions([])
	}

	const onChangeState = (data: string) => {
		if (!data) {
			if (searchState && searchState.length === 1) {
				setSearchState(undefined)
			}
			return
		}
		if (data != searchState) {
			setSearchState(data)
		}
	}

	const onSelectState = (data: string) => {
		setSearchState(data)
	}

	const onClearName = () => {
		setSearchName(undefined)
		setNameOptions([])
	}

	const onChangeName = (data: string) => {
		if (!data) {
			if (searchName && searchName.length === 1) {
				setSearchName(undefined)
			}
			return
		}
		if (data != searchName) {
			setSearchName(data)
		}
	}

	const onSelectName = (data: string) => {
		setSearchName(data)
	}

	const handleFilterChange = (name: string, value: any) => {
		if (name === 'state') {
			if (value && value > 0) {
				setSearchState(value)
			} else {
				setSearchState(undefined)
			}
		}
		if (name === 'name') {
			if (value && value > 0) {
				setSearchName(value)
			} else {
				setSearchName(undefined)
			}
		}
	}

	const expandedRowRender = (rec: AsyncJobModel) => {
		let parsedArgs
		try {
			// logger(`|${atob(rec.args)}|`)
			parsedArgs = JSON.stringify(JSON.parse(atob(rec.args)), null, 2)
			// parsedArgs = JSON.stringify(JSON.parse(rec.args), null, 2);
		} catch (error) {
			console.error('Error decoding args:', error)
			parsedArgs = 'Error decoding arguments'
		}
		return (
			<>
				<Row className='jobDetail'>
					<Col span='2' className='right bold pad4'>
						{t('asyncJobPage.server')}:&nbsp;
					</Col>
					<Col span='10' className='pad4'>
						{job?.server ? job?.server.name : rec.server_id}
					</Col>
					<Col span='2' className='right bold pad4'>
						{t('asyncJobPage.user')}:&nbsp;
					</Col>
					<Col span='10' className='pad4'>
						{job?.user ? job?.user.name : rec.user_id}
					</Col>

					<Col span='2' className='right bold pad4'>
						{t('asyncJobPage.description')}:&nbsp;
					</Col>
					<Col span='10' className='pad4'>
						{rec.desc}
					</Col>
					<Col span='2' className='right bold pad4'>
						{t('asyncJobPage.parent_id')}:&nbsp;
					</Col>
					<Col span='10' className='pad4'>
						{rec.parent_id}
					</Col>

					<Col span='2' className='right bold pad4'>
						{t('asyncJobPage.runtime')}:&nbsp;
					</Col>
					<Col span='10' className='pad4'>
						{rec.runtime} sec.
					</Col>
					<Col span='2' className='right bold pad4'>
						{t('asyncJobPage.totaltime')}:&nbsp;
					</Col>
					<Col span='10' className='pad4'>
						{rec.totaltime} sec.
					</Col>

					<Col span='2' className='right bold pad4'>
						{t('asyncJobPage.max_running_time')}:&nbsp;
					</Col>
					<Col span='10' className='pad4' style={{ color: '#888888' }}>
						{rec.max_running_time} sec.
					</Col>
					<Col span='2' className='right bold pad4'>
						{t('asyncJobPage.start_after')}:&nbsp;
					</Col>
					<Col span='10' className='pad4'>
						{rec.start_after && appSetting.renderFullDateTime(rec.start_after)}
					</Col>


					<Col span='2' className='right bold pad4'>
						{t('asyncJobPage.args')}:&nbsp;
					</Col>
					<Col span='10' className='pad4' style={{ color: '#888888' }}>
						<pre>
							{parsedArgs}
						</pre>
					</Col>
					<Col span='2' className='right bold pad4'>
						&nbsp;
					</Col>
					<Col span='10' className='pad4'>
						&nbsp;
					</Col>
				</Row>
			</>
		)
	}


	const FilterByName = (
		<Select
			showSearch
			placeholder={t('asyncJobPage.name')}
			options={nameOptions}
			value={searchName}
			style={{ width: '130px', marginRight: "1rem" }}
			dropdownMatchSelectWidth={200}
			optionFilterProp="children"
			filterOption={(input, opt) => removeDiac(opt?.label + '').includes(removeDiac(input))}
			filterSort={sort_label}
			allowClear
			onClick={stopPropagation}
			onSelect={(v) => setSearchName(v)}
			onClear={() => setSearchName('')}
		/>
	)

	const FilterByState = (
		<Select
			showSearch
			placeholder={t('asyncJobPage.state')}
			options={stateOptions}
			value={searchState}
			style={{ width: '120px', marginRight: "1rem" }}
			dropdownMatchSelectWidth={200}
			optionFilterProp="children"
			filterOption={(input, opt) => removeDiac(opt?.label + '').includes(removeDiac(input))}
			filterSort={sort_label}
			allowClear
			onClick={stopPropagation}
			onSelect={(v) => setSearchState(v)}
			onClear={() => setSearchState('')}
		/>
	)

	const FilterByHostname = (
		<AutoComplete
			showSearch
			placeholder={t('asyncJobPage.server_id')}
			value={searchHostnameLookup}
			options={hostnameOptions}
			style={{ width: 120 }}
			dropdownMatchSelectWidth={200}
			onInputKeyDown={(e) => {
				if (e.key === 'Enter') {
					onSelectHostname(e.currentTarget.value)
				}
			}}
			onSelect={onSelectHostname}
			onSearch={onChangeHostnameLookup}
			onChange={onChangeHostnameLookup}
			onClear={onClearHostname}
			onClick={stopPropagation}
			notFoundContent={isLoadingLookup && <Spin />}
			filterOption={false}
			optionFilterProp='label'
			allowClear={true}
		/>
	)

	const FilterByID = (<Input
		placeholder='ID'
		style={{ width: 60, fontSize: '0.8em', padding: '4px' }}
		allowClear
		onClick={stopPropagation}
		onChange={e => { if (!e.currentTarget.value) { setSearchId(undefined) } }}	// clear
		onKeyPress={
			(e) => {
				if (e.which === 13) {
					e.currentTarget.value ? setSearchId(e.currentTarget.value) : setSearchId(undefined)
				}
			}
		}
	/>)

	const FilterByGID = (<Input
		placeholder='GID'
		style={{ width: 60, fontSize: '0.8em', padding: '4px' }}
		allowClear
		onClick={stopPropagation}
		onChange={e => { if (!e.currentTarget.value) { setSearchGroupId(undefined) } }}	// clear
		onKeyPress={
			(e) => {
				if (e.which === 13) {
					e.currentTarget.value ? setSearchGroupId(e.currentTarget.value) : setSearchGroupId(undefined)
				}
			}
		}
	/>)

	const columns: ColumnsType<AsyncJobModel> = [
		{
			title: FilterByID,
			dataIndex: 'id',
			key: 'id',
			width: 60,
		},
		{
			title: FilterByGID,
			dataIndex: 'group_id',
			key: 'group_id',
			width: 60,
		},
		{
			title: FilterByName,
			dataIndex: 'name',
			key: 'name',
			width: 160,
			render: (name: string) => <span className='text-small' style={{ fontFamily: 'monospace' }}>{name}</span>
		},
		{
			title: FilterByHostname,
			dataIndex: 'server_id',
			key: 'server_id',
			width: 200,
			render: (sid: string, rec: AsyncJobModel) => {
				if (isServerViewer) {
					return <Link to={`/servers/edit/${rec.server?.id}`} className='text-small noWrap'>{rec.server?.name}</Link>
				} else {
					return <span className='text-small'>{rec.server?.name}</span>
				}
			}
		},
		{
			title: t('asyncJobPage.user_id'),
			dataIndex: 'user_id',
			key: 'user_id',
			render: (sid: string, rec: AsyncJobModel) => {
				if (isServerViewer) {
					return <span className='text-small noWrap'>{rec.user?.name}</span>
				} else {
					return <span className='text-small noWrap'>{rec.user_id}</span>
				}
			}
		},
		{
			title: FilterByState,
			dataIndex: 'state',
			key: 'state',
			render: (state: string, rec: AsyncJobModel) => {
				if (state === 'error' || state === 'new' || state === 'running' || state === 'ready') {
					return <>
						{
							renderJobState(state)
						}
						<Button title='Resolve?'
							icon={<MedicineBoxTwoTone twoToneColor={(isJobEditor) ? "red" : "#ccc"} />}
							size='small'
							type='text'
							className='actionButton'
							disabled={!isJobEditor}
							onClick={() => {
								if (isJobEditor) {
									setSelectedJobId(rec.id)
									dispatch(getAsyncJob(rec.id))
									setModalEditVisible(true)
								}
							}}
						/>
					</>
				}
				else {
					return renderJobState(state)
				}
			},
		},
		{
			title: t('asyncJobPage.result'),
			dataIndex: 'result',
			key: 'result',
			render: (result: string) => <span className='text-small'>{result}</span>
		},
		{
			title: t('asyncJobPage.created_at'),
			dataIndex: 'created_at',
			key: 'created_at',
			render: (dt: number) => <span className='text-small noWrap'>{appSetting.renderMonoDateTime(dt)}</span>,
		},
		{
			title: t('asyncJobPage.finished_at'),
			dataIndex: 'finished_at',
			key: 'finished_at',
			render: (dt: number) => <span className='text-small noWrap'>{appSetting.renderMonoDateTime(dt)}</span>,
		},
		{
			title: t('asyncJobPage.actions'),
			key: 'actions',
			width: 50,
			align: 'center',
			render: (_, rec) => (
				<Button title={t('general.btnHistory')} size='small'
					onClick={() => {
						setHistoryModelId(rec.id)
						setHistoryTitle(`JobID:${rec.id}`)
						setHistoryModalVisible(true)
					}}
					className='actionButton'
					icon={<InfoCircleTwoTone />}
				/>
			),
		},
	]

	const getMinutesFromNow = (dt: number) => {
		const now = new Date()
		const ts = Math.floor(now.getTime() / 1000)
		const diff = ts - dt
		return Math.round(diff / 60)
	}

	if (!appSetting || Object.keys(appSetting).length === 0) {
		return (<Spin />)
	}

	return (
		<>
			<Card
				title={
					<Row>
						<Col span={4}>
							<PlayCircleOutlined /> &nbsp; {t('asyncJobPage.title')}
						</Col>
						<Col span={8}>&nbsp;
						</Col>
						<Col span={2} className='right' style={{ padding: '2px' }}>{t('billing.bill.period_filter')}:&nbsp;</Col>
						<Col span={10}>&nbsp;
							<DateRange format={appSetting.date_picker_format} // not Moment formats!!
								initStart={UserStorage.GetInitRangeStr()[0]}
								initEnd={UserStorage.GetInitRangeStr()[1]}
								onChange={handleDateChange}
							/>
						</Col>
					</Row>
				}
				className='AsyncJobPage'>

				<Table<AsyncJobModel>
					className='JobsTable'
					showHeader={true}
					size='small'
					bordered={true}
					columns={columns}
					scroll={{ x: 680 }}
					dataSource={dataSource}
					rowKey='id'
					expandable={{ expandedRowRender }}
					onExpand={(exp, rec) => { exp && loadJob(rec.id) }}
					pagination={false}
					footer={() => Pager({
						filename: 'fn',
						total: pager.totalCount,
						current: pager.page,
						pageSize: pager.pageSize,
						data: dataSource,
						fetchRecords: fetchRecords
					})}
					onChange={(ev) => {
						ev.pageSize && setPageSize(ev.pageSize)
					}}
				/>


				<Row style={{ marginTop: '25px' }}>
					<Col span={2} className='bold pad4 right'>Legenda</Col>
					<Col span={10}>&nbsp;</Col>
					<Col span={12}><b>RUNNER PING</b>: <span style={{ color: '#888888', fontWeight: 'bold' }}>{server_runner_log ? `${getMinutesFromNow(server_runner_log.last_update)} min. ago` : '..'}</span></Col>
					<Col span={2} className='pad4 right'>{renderJobState('new')}</Col>
					<Col span={10} className='pad4'>{t('asyncJobPage.state_new_desc')}</Col>
					<Col span={12} className='pad4'>&nbsp;
						<b>Refresh Page:</b>&nbsp;
						<Select
							options={refreshOptions}
							value={refreshInterval}
							style={{ width: '130px' }}
							size='small'
							onChange={(value) => {
								setRefreshInterval(value)
								setIsRunning(value > 0)
								setCounter(0)
							}}
						/>
					</Col>

					<Col span={2} className='pad4 right'>{renderJobState('waiting')}</Col>
					<Col span={10} className='pad4'>{t('asyncJobPage.state_waiting_desc')}</Col>
					<Col span={12} className='pad4'>&nbsp;
					</Col>

					<Col span={2} className='pad4 right'>{renderJobState('ready')}</Col>
					<Col span={10} className='pad4'>{t('asyncJobPage.state_ready_desc')}</Col>
					<Col span={12} className='pad4'>&nbsp;</Col>

					<Col span={2} className='pad4 right'>{renderJobState('running')}</Col>
					<Col span={10} className='pad4'>{t('asyncJobPage.state_running_desc')}</Col>
					<Col span={12} className='pad4'>&nbsp;</Col>

					<Col span={2} className='pad4 right'>{renderJobState('done')}</Col>
					<Col span={10} className='pad4'>{t('asyncJobPage.state_done_desc')}</Col>
					<Col span={12} className='pad4'>&nbsp;</Col>

					<Col span={2} className='pad4 right'>{renderJobState('error')}</Col>
					<Col span={10} className='pad4'>{t('asyncJobPage.state_error_desc')}</Col>
					<Col span={12} className='pad4'>&nbsp;</Col>

					<Col span={2} className='pad4 right'>{renderJobState('error_resolved')}</Col>
					<Col span={10} className='pad4'>{t('asyncJobPage.state_resolved_desc')}</Col>
					<Col span={12} className='pad4'>&nbsp;</Col>

					<Col span={2} className='pad4 right'>{renderJobState('cancelled')}</Col>
					<Col span={10} className='pad4'>{t('asyncJobPage.state_cancelled_desc')}</Col>
					<Col span={12} className='pad4'>&nbsp;</Col>
				</Row>

			</Card>

			<Modal
				title={(<div style={{ width: '100%', cursor: 'move' }}><InfoCircleOutlined />&nbsp;{t('general.history')}: {historyTitle}</div>)}
				destroyOnClose
				style={{ top: 50 }}
				bodyStyle={{ height: '60%', minHeight: 450, padding: 2 }}
				width='60%'
				className='historyLogModal'
				visible={isHistoryModalVisible}
				onCancel={() => setHistoryModalVisible(false)}
				maskClosable={false}
				modalRender={(modal) => (
					<Draggable bounds={bounds} onStart={(ev, data) => onStart(ev, data)}>
						<div ref={draggleRef}>{modal}</div>
					</Draggable>
				)}
				footer={null}>

				<AsyncLog taskId={historyModelId} isModal={true} showTitle={false} />
			</Modal>

			<Modal
				destroyOnClose
				width={500}
				title={
					<>
						<EditOutlined /> &nbsp;{t('asyncJobPage.update_title')}
					</>
				}
				visible={isModalEditVisible}
				onCancel={() => setModalEditVisible(false)}
				footer={null}
				confirmLoading={true}>
				<AsyncJobForm id={selectedJobId} onClose={() => setModalEditVisible(false)} />
			</Modal>
		</>
	)
}

export default AsyncJobPage
