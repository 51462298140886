import {
    FindServersByHostnameRequestAction,
    FindServersByHostnameResponseAction,
    FindServersByHostnameErrorAction,
} from '.'
import { protectedApiClient } from 'helpers/api'
import { handleApiErrorWithNotification } from 'helpers/errorHandling'
import {ServerGrid} from "../../../server/models";


export default (pageSize: number, pageNumber: number, filter: string, cb?: (isSuccess: boolean) => void) => {
    return (
        dispatch: (
            arg:
                | FindServersByHostnameRequestAction
                | FindServersByHostnameResponseAction
                | FindServersByHostnameErrorAction,
        ) => void,
    ) => {
        const request = new FindServersByHostnameRequestAction()
        dispatch(request)

        protectedApiClient
            .get<ServerGrid>(`/server/server?${filter}&pagination[pageSize]=${pageSize}&pagination[page]=${pageNumber}`)
            .then((response) => {
                dispatch(new FindServersByHostnameResponseAction(request, response.data))
                cb && cb(true)
            })
            .catch((error) => {
                dispatch(new FindServersByHostnameErrorAction(request, error))
                handleApiErrorWithNotification(error)
                cb && cb(false)
            })
    }
}
