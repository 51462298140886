import {
    DeleteDatabaseRequestAction,
    DeleteDatabaseResponseAction,
    DeleteDatabaseErrorAction,
} from '.'
import { protectedApiClient } from 'helpers/api'
import { handleApiErrorWithNotification } from 'helpers/errorHandling'

export default (id: number, cb?: (isSuccess: boolean) => void) => {
    return (
        dispatch: (
            arg:
                | DeleteDatabaseRequestAction
                | DeleteDatabaseResponseAction
                | DeleteDatabaseErrorAction
        ) => void
    ) => {
        const request = new DeleteDatabaseRequestAction(id)
        dispatch(request)

        protectedApiClient
            .delete(`/rdb/database/${id}?expand=service,service_driver,username`)
            .then((response) => {
                dispatch(new DeleteDatabaseResponseAction(request, response.data))
                cb && cb(true)
            })
            .catch((error) => {
                dispatch(new DeleteDatabaseErrorAction(request, error))
                handleApiErrorWithNotification(error)
                cb && cb(false)
            })
    }
}
