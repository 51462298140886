import { useDispatch, useSelector } from "react-redux"
import { useTranslation } from "react-i18next"
import { useForm } from "antd/es/form/Form"
import { Button, Form, Input, InputNumber, message, Spin } from "antd"
import { formItemLayout, tailLayout } from "helpers/layoutHelpers"
import { UpdateMailboxParams } from "./models"
import { AppState } from "common/models"
import { useEffect, useState } from "react"
import { Store } from "antd/es/form/interface"
import updateMailbox from "./actions/updateMailbox"


interface Props {
  onClose: () => void
}

const { Item } = Form


const UpdateMailboxForm = (props: Props) => {
  const [form] = useForm()
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const { mail_box, mail_domain, isSavingMailbox } = useSelector((state: AppState) => state.mailservice)

  const [updateStatus, setUpdateStatus] = useState(false)

  useEffect(() => {
    if (mail_box) {
      form.setFieldsValue({
        email: mail_box.email,
      })
    }
  }, [mail_box])

  const onFormSubmit = (values: Store) => {
    if (!mail_domain) {
      message.error('Mail domain is not found')
      return
    }
    if (!mail_box || !mail_box.mailbox) {
      message.error('Mailbox is not found')
      return
    }
    const params: UpdateMailboxParams = {
      domain_id: mail_domain.id,
      mailbox: mail_box.mailbox,
      quota: values.quota ? values.quota : 0,
    }
    dispatch(updateMailbox(params, (suc) => {
      if (suc) {
        message.success(t('general.success'))
        props.onClose()
      }
    }))
  }

  if (!mail_domain || !mail_domain.id) {
    return <Spin />
  }

  if (!mail_box) {
    return <Spin />
  }

  return (
    <Form
      onFinish={onFormSubmit}
      form={form}
      onChange={() => setUpdateStatus(true)}
      initialValues={mail_box}
      {...formItemLayout}
    >

      <Form.Item name='email' label='Email'>
        <Input disabled={true} />
      </Form.Item>

      <Form.Item name='quota' label={t('mailDomainsPage.quota')}>
        <InputNumber min={0} max={1000000000} step={1} addonAfter='MB'
          onChange={() => setUpdateStatus(true)}
          placeholder={t('mailDomainsPage.password')}
          data-gramm="false"
          data-1p-ignore />
      </Form.Item>

      <Item {...tailLayout}>
        <Button type='primary'
          loading={isSavingMailbox}
          disabled={!updateStatus}
          htmlType='submit'>
          {t('general.save')}
        </Button>
      </Item>
    </Form>
  )
}

export default UpdateMailboxForm
