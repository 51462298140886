import { GetVhostAliasesRequestAction, GetVhostAliasesResponseAction, GetVhostAliasesErrorAction } from './index'
import { protectedApiClient } from 'helpers/api'
import { handleApiErrorWithNotification } from 'helpers/errorHandling'
import { AliasModel } from '../models'

export default (vhost_id: number, cb?: (isSuccess: boolean) => void) => {
	return (
		dispatch: (arg: GetVhostAliasesRequestAction | GetVhostAliasesResponseAction | GetVhostAliasesErrorAction) => void
	) => {
		const request = new GetVhostAliasesRequestAction(vhost_id)
		dispatch(request)

		let url = `/web/vhost/${vhost_id}/alias-list`
		protectedApiClient
			.get<AliasModel[]>(url)
			.then((response) => {
				dispatch(new GetVhostAliasesResponseAction(request, response.data))
				cb && cb(true)
			})
			.catch((error) => {
				dispatch(new GetVhostAliasesErrorAction(request, error.toString()))
				handleApiErrorWithNotification(error)
				cb && cb(false)
			})
	}
}
