import { protectedApiClient } from 'helpers/api'
import { handleApiErrorWithNotification } from 'helpers/errorHandling'
import {
    GetInventoryIPMIRequestAction,
    GetInventoryIPMIResponseAction,
    GetInventoryIPMIErrorAction,
} from '.'
import {IpModel, IpSubnetModel} from "../../../ip/subnet/models";


export default (prefix: string, cb?: (isSuccess: boolean) => void) => {
    return (
        dispatch: (
            arg:
                | GetInventoryIPMIRequestAction
                | GetInventoryIPMIResponseAction
                | GetInventoryIPMIErrorAction,
        ) => void,
    ) => {
        const request = new GetInventoryIPMIRequestAction()
        dispatch(request)

        protectedApiClient
            .get<IpModel[]>(`/ip/ip/list?prefix=${prefix}`)
            .then((response) => {
                dispatch(new GetInventoryIPMIResponseAction(request, response.data))
                cb && cb(true)
            })
            .catch((error) => {
                dispatch(new GetInventoryIPMIErrorAction(request, error))
                handleApiErrorWithNotification(error)
                cb && cb(false)
            })
    }
}
