import { protectedApiClient } from 'helpers/api'
import { handleApiErrorWithNotification } from 'helpers/errorHandling'
import {IpModel} from "../../ip/subnet/models";
import {ListIpErrorAction, ListIpRequestAction, ListIpResponseAction} from "./index";


export default (prefix: string, cb?: (isSuccess: boolean) => void) => {
    return (
        dispatch: (
            arg:
                | ListIpRequestAction
                | ListIpResponseAction
                | ListIpErrorAction,
        ) => void,
    ) => {
        const request = new ListIpRequestAction()
        dispatch(request)
        protectedApiClient
            .get<IpModel[]>(`/ip/ip/list?prefix=${prefix}`)
            .then((response) => {
                dispatch(new ListIpResponseAction(request, response.data))
                cb && cb(true)
            })
            .catch((error) => {
                dispatch(new ListIpErrorAction(request, error))
                handleApiErrorWithNotification(error)
                cb && cb(false)
            })
    }
}
