import {CreateWebTemplateRequestAction, CreateWebTemplateResponseAction, CreateWebTemplateErrorAction} from './index'
import {protectedApiClient} from 'helpers/api'
import {handleApiErrorWithNotification} from 'helpers/errorHandling'
import {CreateWebTemplateParams, WebTemplateModel} from '../models'

export default (params: CreateWebTemplateParams, cb?: (isSuccess: boolean, id: number | undefined) => void) => {
    return (dispatch: (arg: CreateWebTemplateRequestAction | CreateWebTemplateResponseAction | CreateWebTemplateErrorAction) => void) => {
        const request = new CreateWebTemplateRequestAction(params)
        dispatch(request)
        protectedApiClient
            .post<WebTemplateModel>(`/web/template`, params)
            .then((response) => {
                dispatch(new CreateWebTemplateResponseAction(request, response.data))
                cb && cb(true, response.data.id)
            })
            .catch((error) => {
                dispatch(new CreateWebTemplateErrorAction(request, error.toString()))
                handleApiErrorWithNotification(error)
                cb && cb(false, undefined)
            })
    }
}
