import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { LoadingIndicator } from "../../../components"
import React, { useEffect, useRef, useState } from "react"
import { Card, Col, Input, message, Row, Select, Space, Table, Tag, Tree } from "antd"
import { PlusCircleOutlined, SplitCellsOutlined } from "@ant-design/icons/lib/icons"
import Button from "antd-button-color"
import {
    BlockOutlined,
    DeleteTwoTone, EditTwoTone,
    FolderTwoTone, InfoCircleOutlined,
    InfoCircleTwoTone, MinusCircleTwoTone, PlusCircleTwoTone,
    UserOutlined
} from "@ant-design/icons"
import { useLoggedUser } from "../../../helpers/loginUserHelper"
import PopConfirmZis from "../../../components/popconfirm/PopConfirmZis"
import { UserGroupModel } from "./models"
import { AppState } from "../../../common/models"
import "./GroupPage.scss"
import Modal from "antd/lib/modal/Modal"
import Draggable, { DraggableData, DraggableEvent } from "react-draggable"
import { sort_ident, sort_label, sort_name, sort_str, sort_title } from "../../../common/sorting"
import { ColumnsType } from "antd/lib/table"
import { useHistory } from "react-router"
import NewUserGroupForm from "./NewUserGroupForm"
import { CustomerDetails } from "../../billing/customer/models"
import { DataNode } from "antd/lib/tree"
import deleteGroup from "./actions/deleteGroup"
import getGroupByParent from "./actions/getGroupByParent"
import getRootGroup from "./actions/getRootGroup"
import { ResGroupModel } from "../resources/models"
import { renderResGroup, renderRole, renderSelected, renderUser } from "../common"
import addRoleInGroup from "./actions/addRoleInGroup"
import removeRoleFromGroup from "./actions/removeRoleFromGroup"
import removeResGroupFromGroup from "./actions/removeResGroupFromGroup"
import addResGroupToGroup from "./actions/addResGroupToGroup"
import getSelf from "../../login/actions/getSelf"
import HistoryLog from "../../../components/History/HistoryLog"
import { UserDetails } from "../../user/models"
import getGroup from "./actions/getGroup"
import { stopPropagation, ZCOM_CUSTOMER_ID } from "../../../common/fce"
import addUserToGroup from "./actions/addUserToGroup"
import removeUserFromGroup from "./actions/removeUserFromGroup"
import {
    ROLE_ACCESS_MANAGER,
    ROLE_APP_ADMIN,
    ROLE_CUSTOMER_MANAGER,
    ROLE_USER_MANAGER
} from "../../../common/LoggedUser"



interface ParamTypes {
    role_key: string
}

interface TDataNode extends DataNode {
    type: string
}

interface RoleItem {
    label: string
    value: string
}

const GroupsPage = () => {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const history = useHistory()

    const { customers } = useSelector((state: AppState) => state.auth.tables)
    const { users: allUsers } = useSelector((state: AppState) => state.user)
    const { hierarchy, nodes, users, roles, resGroups, isLoading } = useSelector((state: AppState) => state.groupsPage)
    const { rights_usergroups } = useSelector((state: AppState) => state.sidenav)

    const [treeDataSource, setTreeDataSource] = useState<TDataNode[]>([])
    // const [selectedCustomer, setSelectedCustomer] = useState<CustomerDetails>()
    const [custOptions, setCustOptions] = useState<{ label: string, value: number }[]>([])
    const [selectedCustomerId, setSelectedCustomerId] = useState<number | undefined>()
    const [selectedNode, setSelectedNode] = useState<UserGroupModel | undefined>()
    const [editGroup, setEditGroup] = useState<UserGroupModel | undefined>()
    const [defaultExpandedKeys, setDefaultExpandedKeys] = useState<string[]>([])
    const [expandedKeys, setExpandedKeys] = useState<string[]>([])
    const [isHistoryModalVisible, setHistoryModalVisible] = useState(false)
    const [historyTitle, setHistoryTitle] = useState('')
    const [historyModelId, setHistoryModelId] = useState<number | undefined>()
    const [forceUpdateHistoryId, setForceUpdateHistoryId] = useState<number>(1)

    const [searchName, setSearchName] = useState('')
    const [groupTreePath, setGroupTreePath] = useState('')

    const [customerSource, setCustomerSource] = useState<CustomerDetails[]>([])
    const [roleSource, setRoleSource] = useState<RoleItem[]>([])
    const [userSource, setUserSource] = useState<UserDetails[]>([])
    const [userAllSource, setUserAllSource] = useState<UserDetails[]>([])
    const [roleAllSource, setRoleAllSource] = useState<RoleItem[]>([])
    const [resGroupSource, setResGroupSource] = useState<ResGroupModel[]>([])
    const [resGroupAllSource, setResGroupAllSource] = useState<ResGroupModel[]>([])
    const [isGroupModalVisible, setGroupModalVisible] = useState<boolean>(false)
    const [searchRole, setSearchRole] = useState<string>('')
    const [searchResource, setSearchResource] = useState<string>('')
    const [searchUser, setSearchUser] = useState<string>('')


    // get settings and logged user from store
    const loggedUser = useLoggedUser()
    if (!loggedUser || !loggedUser.isLoaded()) {
        return (
            <div className="fullwidth-loading" >
                <LoadingIndicator />
            </div>
        )
    }
    const appSetting = loggedUser.getAppSettings()

    // logger
    const logger = (msg, obj: any = null) => { if (appSetting && appSetting.debug) { obj ? console.log('GroupsPage: ' + msg + ' > ' + JSON.stringify(obj)) : console.log('GroupsPage: ' + msg) } }

    // history drag modal
    const draggleRef = useRef<HTMLDivElement>(null)
    const [bounds, setBounds] = useState({ left: 0, top: 0, bottom: 0, right: 0 })
    const onStart = (_event: DraggableEvent, uiData: DraggableData) => {
        const { clientWidth, clientHeight } = window.document.documentElement
        const targetRect = draggleRef.current?.getBoundingClientRect()
        if (!targetRect) {
            return
        }
        setBounds({
            left: -targetRect.left + uiData.x,
            right: clientWidth - (targetRect.right - uiData.x),
            top: -targetRect.top + uiData.y,
            bottom: clientHeight - (targetRect.bottom - uiData.y),
        })
    }

    // permissions
    // permissions
    const [isViewer, setViewer] = useState(false)
    const [isCreator, setCreator] = useState(false)
    const [isEditor, setEditor] = useState(false)
    const [isDeleter, setDeleter] = useState(false)
    const [isEditorMember, setEditorMember] = useState(false)
    const [isEditorRole, setEditorRole] = useState(false)
    const [isEditorResource, setEditorResource] = useState(false)

    useEffect(() => {
        const viewer = loggedUser.hasAccess('page_rights_usergroups')
        setViewer(viewer)
        setCreator(loggedUser.hasAccess('page_usergroups_create_button'))
        setEditor(loggedUser.hasAccess('page_usergroups_edit_button'))
        setDeleter(loggedUser.hasAccess('page_usergroups_delete_button'))
        setEditorMember(loggedUser.hasAccess('page_usergroups_add_member') && loggedUser.hasAccess('page_usergroups_remove_member'))
        setEditorRole(loggedUser.hasAccess('page_usergroups_add_role') && loggedUser.hasAccess('page_usergroups_remove_role'))
        setEditorResource(loggedUser.hasAccess('page_usergroups_add_resources') && loggedUser.hasAccess('page_usergroups_remove_resources'))

        if (!viewer) {
            // go to dashboard
            message.error(t('general.error403'))
            history.replace('/')
            return
        }

        // all roles from loggedUser
        dispatch(getSelf('roles,resourceGroups,groups,userAuthentications,authentications', suc => {
            setRoleAllSource(loggedUser.roles.map(r => { return { label: r, value: r } }).sort(sort_label))
            setResGroupAllSource(loggedUser.resourceGroups.sort(sort_name))
        }))

        // set customer
        const cid = localStorage.getItem('customerId')
        if (cid && parseInt(cid) > 0) {
            setSelectedCustomerId(parseInt(cid))
            setUserAllSource(allUsers.filter(u => u.customer_id === parseInt(cid)).sort(sort_title))
        }
        else {
            setSelectedCustomerId(loggedUser.user.customer_id)
            setUserAllSource(allUsers.filter(u => u.customer_id === loggedUser.user.customer_id).sort(sort_title))
        }
        renderAllUsers()
    }, [])

    useEffect(() => {
        // Customer Options
        if (loggedUser.user.is_zcom) {
            if (customers && customers.length) {
                const items = customers.map(c => ({ label: c.company!.name, value: c.id }))
                setCustOptions(items.sort(sort_label))
            }
        }
        else {
            setCustOptions([{ label: loggedUser.company.name, value: loggedUser.customer.id }])
        }
    }, [customers])

    useEffect(() => {
        // fetch root
        if (selectedCustomerId) {
            fetchRoot(selectedCustomerId)
        }
        logger('selectedCustomerId=' + selectedCustomerId)
    }, [selectedCustomerId])

    useEffect(() => {
        renderRoles()
        renderResGroups()
        renderUsers()
    }, [selectedNode, selectedNode?.id, nodes])

    useEffect(() => {
        renderRoles()
    }, [roles, searchRole])

    useEffect(() => {
        renderResGroups()
    }, [resGroups, searchResource])

    useEffect(() => {
        renderUsers()
        renderAllUsers()
    }, [users])

    useEffect(() => {
        renderAllUsers()
    }, [searchUser])

    useEffect(() => {
        logger('groupTreePath: ' + groupTreePath)
        localStorage.setItem('groupTreePath', groupTreePath)
    }, [groupTreePath])

    useEffect(() => {
        // update datasource when data was changed
        const pattern = searchName.replace(/\s/g, '')
        setCustomerSource(filtered(pattern))
    }, [customers, searchName])

    useEffect(() => {
        if (hierarchy) {
            const tree: TDataNode[] = transform(hierarchy)
            setTreeDataSource(tree)
        }
    }, [hierarchy])

    useEffect(() => {
        // trigger from Side menu clicked
        // if (!isLoadingRole) {
        //     setSelectedRoleKey('')
        //     dispatch(getRoles())
        // }
    }, [rights_usergroups])

    const findSelectedNode = () => {
        let fn: UserGroupModel | undefined
        if (selectedNode) {
            if (!selectedNode.parent_id) {
                // root
                fn = findGroupNode(selectedNode.id)
            } else {
                fn = findFullNode(selectedNode.id, selectedNode.parent_id, selectedNode.type)
            }
        }
        return fn
    }

    const onChangeCustomer = (value) => {
        if (value) {
            setSelectedCustomerId(value)
            localStorage.setItem('customerId', value)
            setUserAllSource(allUsers.filter(u => u.customer_id === value).sort(sort_title))
        }
        else {
            setSelectedCustomerId(loggedUser.user.customer_id)
            localStorage.setItem('customerId', `${loggedUser.user.customer_id}`)
            setUserAllSource(allUsers.filter(u => u.customer_id === loggedUser.user.customer_id).sort(sort_title))
        }
    }

    const renderUsers = () => {
        if (selectedNode) {
            let fn = findSelectedNode()
            if (fn) {
                // filter out already selected
                const used = fn.users.filter(u => u != undefined)
                setUserSource(used.sort(sort_title))
            }
        }
    }

    const renderAllUsers = () => {
        if (selectedNode) {
            let fn = findSelectedNode()
            if (fn) {
                // filter out already selected
                const used = fn.users.filter(u => u != undefined)
                const usedIDs = used.map(u => u.id)
                const unUsedUser = allUsers.filter((u) => !usedIDs.includes(u.id))
                setUserAllSource(unUsedUser.filter(u => u.title.toLowerCase().includes(searchUser.toLowerCase())).sort(sort_title))
            }
        }
        else {
            setUserAllSource(allUsers.filter(u => u.title.toLowerCase().includes(searchUser.toLowerCase())).sort(sort_title))
        }
    }

    const renderRoles = () => {
        if (selectedNode) {
            logger('renderRoles selectedNode: ' + selectedNode.id)
            let fn = findSelectedNode()
            if (fn) {
                logger('renderRoles fn: ' + JSON.stringify(fn.roles))
                // filter out already selected
                const used = roles.filter(role => role != undefined)
                let unUsedRoles = loggedUser.roles.filter((role) => !used.includes(role))
                unUsedRoles = loggedUser.roles.filter((role) => role.includes(searchRole.toLowerCase()))

                setRoleAllSource(unUsedRoles.map(r => { return { label: r, value: r } }).sort(sort_label))
                setRoleSource(roles.map(r => { return { label: r, value: r } }).sort(sort_label))
                return
            }
        }
        setRoleSource([])
        setRoleAllSource(loggedUser.roles.filter((role) => role.includes(searchRole.toLowerCase())).map(r => { return { label: r, value: r } }).sort(sort_label))
    }

    const renderResGroups = () => {
        if (selectedNode) {
            let fn = findSelectedNode()
            if (fn) {
                // filter out already selected
                //logger('fn...' + JSON.stringify(fn.resourceGroups))
                //const usedIds = resGroups.filter(rg => rg.id && rg.id > 0).map(g => g.id)
                //let unUsedGroups = loggedUser.resourceGroups.filter((g) => !usedIds.includes(g.id))
                //unUsedGroups = unUsedGroups.filter((rg) => rg.name.toLowerCase().includes(searchResource.toLowerCase()))
                // setResGroupAllSource(unUsedGroups.sort(sort_name))

                let unGroupResGroups = loggedUser.resourceGroups.filter((rg) => rg.name.toLowerCase().includes(searchResource.toLowerCase()))
                setResGroupAllSource(unGroupResGroups.sort(sort_name))
                setResGroupSource(resGroups.sort(sort_name))
                return
            }
        }
        const rgs = loggedUser.resourceGroups.filter((rg) => rg.name.toLowerCase().includes(searchResource.toLowerCase()))
        setResGroupAllSource(rgs.sort(sort_name))
        setResGroupSource([])
    }

    const _getNode = (group: UserGroupModel): TDataNode => {
        let title = group.title
        if (title === 'root' && group.parent_id === 2 && group.type === 'group' && group.customer) {
            // Customers
            title = group.customer?.name
        }

        if (!group.children) {
            return {
                title: title,
                key: group.key,
                type: group.type,
                icon: group.type === 'user' ? <UserOutlined style={{ color: 'green' }} /> : <FolderTwoTone twoToneColor='orange' />,
                children: []
            }
        }
        let children: TDataNode[] = []
        for (let child of group.children) {
            child.type != 'user' && children.push(_getNode(child))
        }
        return {
            title: <>{title} (<UserOutlined /> {group.users.length})</>,
            key: group.key,
            type: group.type,
            icon: group.type === 'user' ? <UserOutlined style={{ color: 'green' }} /> : <FolderTwoTone twoToneColor='orange' />,
            children: children
        }
    }

    const transform = (data: UserGroupModel): TDataNode[] => {
        // create hierarchy of nodes
        return [_getNode(data)]
    }

    const filtered = (name: string) => {
        // search for pattern
        if (!customers) {
            return []
        }
        let data = customers.map(c => { return { ...c, ident: c.company!.name } })
        if (!name) {
            return data.sort(sort_ident)
        }
        return data.filter((c) => c.ident.toLowerCase().includes(name.toLowerCase())).sort(sort_ident)
    }

    const findFullNode = (id: number, parentId: number, type: string): UserGroupModel | undefined => {
        // we have 2 types [group, user]
        // group-node has unique id
        // user-node is always a leaf, but it has multiple occurrence
        if (!nodes) {
            return undefined
        }
        let node: UserGroupModel | undefined
        if (type === 'group') {
            node = nodes.find(n => n.id === id && n.type === type)
        }
        if (type === 'user') {
            node = nodes.find(n => n.id === id && n.parent_id === parentId && n.type === type)
        }
        if (!node) {
            logger('findFullNode: node not found. id=' + id + ', parent_id=' + parentId + ', t=' + type + ', nodes=' + nodes.length)
        }
        return node
    }


    const fetchRoot = (customerId) => {
        // getRootGroup - finds the root of customer by logged-in user
        dispatch(getRootGroup(customerId, (suc, id) => {
            if (suc && id) {
                setDefaultExpandedKeys([])
                setSelectedNode(undefined)
                dispatch(getGroupByParent(id, (suc) => {
                    !suc && message.error("Error load User groups!")
                    if (suc) {
                        const node = nodes.find(g => (g.id === 1 && g.parent_id === null))
                        if (node) {
                            setSelectedNode(node)
                            setDefaultExpandedKeys(['1_0'])
                            setGroupTreePath('1_0')
                        }
                    }
                }))
            }
            else {
                message.error("Error load User groups!")
            }
        }))
    }

    const onExpandTree = (expandedKeys, { expanded, node }) => {
        const uniqueKeys: string[] = Array.from(new Set(expandedKeys))
        if (!expanded) {
            // remove sub nodes
            let toRemove = uniqueKeys.filter(item => item != node.key)
            toRemove = toRemove.filter(item => !item.startsWith(`${node.key}-`))
            setExpandedKeys(toRemove)
        }
        else {
            setExpandedKeys(uniqueKeys)
        }
    }

    const onSelectTree = (keys: React.Key[], info: any) => {
        const key = info.node.key.toString()            // 1_0-30
        const type = info.node.type
        logger('onSelectTree key=', key)
        setGroupTreePath(key)
        let id = '0'
        const sid = key.split('-').pop()        // 3
        const last = sid.slice(-1)

        if (last === '0') {
            id = sid.split('_')[0]
        }
        else {
            id = sid
        }
        // console.log('key: key='+ key + ', id='+ id + ', type='+ type)
        const node = nodes.find(g => (g.id === parseInt(id) && g.type === type))
        if (!node) {
            logger('node: id=' + id)
            for (let n of nodes) {
                logger('node: ' + n.id + ' ' + n.type)
            }
            return
        }
        setSelectedNode(node)
        if (type === 'group') {
            dispatch(getGroup(parseInt(id), (suc) => {
                !suc && message.error("Error load User groups!")

            }
            )
            )
            dispatch(getGroupByParent(parseInt(id), (suc) =>
                !suc && message.error("Error load User group children!")
            )
            )
        }
        expandedKeys.push(key)
        onExpandTree(expandedKeys, { expanded: true, node: node })
    }

    const findGroupNode = (id) => {
        if (nodes) {
            return nodes.find(n => n.id === id && n.type === 'group')
        }
    }

    const handleDeleteGroup = () => {
        // delete group
        if (!selectedNode || selectedNode.type === 'user') {
            return
        }
        const fn = findGroupNode(selectedNode.id)
        if (!fn) {
            return
        }

        if (fn.users && fn.users.length) {
            message.error(t("rights.userGroups.error_has_users"))
            return
        }
        if (fn.children && fn.children.length) {
            message.error(t("rights.userGroups.error_has_children"))
            return
        }
        if (fn.roles && fn.roles.length) {
            message.error(t("rights.userGroups.error_has_roles"))
            return
        }
        if (fn.resourceGroups && fn.resourceGroups.length) {
            message.error(t("rights.userGroups.error_has_res_groups"))
            return
        }

        dispatch(
            deleteGroup(selectedNode.id, (suc) => {
                if (suc) {
                    message.success(t("general.success"))
                    setSelectedNode(undefined)
                    fn.parent_id && dispatch(getGroupByParent(fn.parent_id, (suc) =>
                        !suc && message.error("Error load User groups!")
                    )
                    )
                } else {
                    message.error("Error delete User group")
                }
            })
        )
    }

    const getTitle = (cid: number) => {
        if (cid === ZCOM_CUSTOMER_ID) {
            return 'ZCOM'
        }
        const c = customers.find(c => c.id === cid)
        return c && c.company!.name ? c.company!.name : '?' + cid
    }

    const reRenderRole = (rec: RoleItem) => {
        if (loggedUser.hasAccess('left_menu_rights_roles')) {
            return renderRole(rec.label, false, () => { history.push('/rights/role/' + rec.label) })
        }
        return renderRole(rec.label, false, undefined)
    }

    const reRenderResGroup = (rec: ResGroupModel) => {
        if (loggedUser.hasAccess('left_menu_rights_resources')) {
            return renderResGroup(rec.name, getTitle(rec.customer_id), false, () => { history.push('/rights/resource-group/' + rec.id) })
        }
        return renderResGroup(rec.name, getTitle(rec.customer_id), false, undefined)
    }

    const columnsRole: ColumnsType<RoleItem> = [
        {
            title: t('rights.userGroups.roles_name') + ': ' + (selectedNode ? selectedNode?.title : ''),
            dataIndex: 'label',
            key: 'label',
            render: (_, rec) => reRenderRole(rec)
        },
        {
            title: '',
            key: 'action',
            width: '50px',
            dataIndex: 'label',
            fixed: 'right',
            render: (sRole) => (
                <Space size='small'>
                    {
                        !isEditorRole && (<Button type='text' size='small' disabled={true} className='actionButton' icon={<MinusCircleTwoTone twoToneColor="#cccccc" />} />)
                    }
                    {
                        isEditorRole && (
                            <Button type='text' size='small'
                                disabled={!selectedNode}
                                onClick={() => {
                                    if (selectedNode && selectedNode.id && sRole) {
                                        // remove role
                                        const params = { id: selectedNode.id, roles: [sRole] }
                                        dispatch(removeRoleFromGroup(params))
                                    }
                                }}
                                className='actionButton'
                                icon={(selectedNode && selectedNode.id) ? <MinusCircleTwoTone twoToneColor='red' /> : <MinusCircleTwoTone twoToneColor="#cccccc" />}
                            />
                        )
                    }
                </Space>
            ),
        },
    ]

    const columnsRes: ColumnsType<ResGroupModel> = [
        {
            title: t('rights.userGroups.resource_name') + ': ' + (selectedNode ? selectedNode?.title : ''),
            dataIndex: 'name',
            key: 'name',
            render: (_, rec) => reRenderResGroup(rec)
        },
        {
            title: '',
            key: 'action',
            width: '50px',
            dataIndex: 'id',
            fixed: 'right',
            render: (gid) => (
                <Space size='small'>
                    {
                        !isEditorResource && (<Button type='text' size='small' disabled={true} className='actionButton' icon={<MinusCircleTwoTone twoToneColor="#cccccc" />} />)
                    }
                    {
                        isEditorResource && (
                            <Button type='text' size='small'
                                disabled={!selectedNode}
                                onClick={() => {
                                    if (selectedNode && selectedNode.id && gid) {
                                        // remove resourceGroups
                                        const params = { id: selectedNode.id, resource_group_id: [gid] }
                                        dispatch(removeResGroupFromGroup(params))
                                    }
                                }}
                                className='actionButton'
                                icon={(selectedNode && selectedNode.id) ? <MinusCircleTwoTone twoToneColor='red' /> : <MinusCircleTwoTone twoToneColor="#cccccc" />}
                            />
                        )
                    }
                </Space>
            ),
        },
    ]

    const FilterByRole = (
        <>
            <span>{t('rights.roles.my_name') + ' @' + loggedUser.user.username}</span>
            <Input
                placeholder={t('rights.roles.name')}
                style={{ width: '80px', marginLeft: '60px', padding: '2px' }}
                value={searchRole}
                allowClear={true}
                onClick={stopPropagation}
                onChange={(current) => {
                    setSearchRole(current.target.value)
                }}
            />
        </>
    )

    const columnsRoleAll: ColumnsType<RoleItem> = [
        {
            title: FilterByRole,
            dataIndex: 'label',
            key: 'label',
            render: (_, rec) => reRenderRole(rec)
        },
        {
            title: '',
            key: 'action',
            width: '50px',
            dataIndex: 'label',
            fixed: 'right',
            render: (sRole) => (
                <Space size='small'>
                    {
                        !isEditorRole && (<Button type='text' size='small' disabled={true} className='actionButton' icon={<PlusCircleTwoTone twoToneColor="#cccccc" />} />)
                    }
                    {
                        isEditorRole && (
                            <Button type='text' size='small'
                                disabled={!selectedNode}
                                onClick={() => {
                                    if (selectedNode && selectedNode.id && sRole) {
                                        // add role
                                        const params = { id: selectedNode.id, roles: [sRole] }
                                        dispatch(addRoleInGroup(params))
                                    }
                                }}
                                className='actionButton'
                                icon={(selectedNode && selectedNode.id) ? <PlusCircleTwoTone /> : <PlusCircleTwoTone twoToneColor="#cccccc" />}
                            />
                        )
                    }
                </Space>
            ),
        },
    ]

    const FilterByRes = (
        <>
            <span>{t('rights.resources.my_name') + ' @' + loggedUser.user.username}</span>
            <Input
                placeholder={t('rights.resources.name')}
                style={{ width: '80px', marginLeft: '60px', padding: '2px' }}
                value={searchResource}
                allowClear={true}
                onClick={stopPropagation}
                onChange={(current) => {
                    setSearchResource(current.target.value)
                }}
            />
        </>
    )

    const columnsResAll: ColumnsType<ResGroupModel> = [
        {
            title: FilterByRes,
            dataIndex: 'name',
            key: 'name',
            render: (_, rec) => reRenderResGroup(rec)
        },
        {
            title: '',
            key: 'action',
            width: '50px',
            dataIndex: 'id',
            fixed: 'right',
            render: (_, rec: ResGroupModel) => (
                <Space size='small'>
                    {
                        !isEditorResource && (<Button type='text' size='small' disabled={true} className='actionButton' icon={<PlusCircleTwoTone twoToneColor="#cccccc" />} />)
                    }
                    {
                        isEditorResource && (
                            <Button type='text' size='small'
                                disabled={!selectedNode}
                                onClick={() => {
                                    if (selectedNode && selectedNode.id && rec.id) {
                                        // add resourceGroups
                                        const params = { id: selectedNode.id, resource_group_id: [rec.id] }
                                        dispatch(addResGroupToGroup(params))
                                    }
                                }}
                                className='actionButton'
                                icon={(selectedNode && selectedNode.id) ? <PlusCircleTwoTone /> : <PlusCircleTwoTone twoToneColor="#cccccc" />}
                            />
                        )
                    }
                </Space>
            ),
        },
    ]

    const columnsUser: ColumnsType<UserDetails> = [
        {
            title: t('rights.userGroups.members') + ': ' + (selectedNode ? selectedNode?.title : ''),
            dataIndex: 'title',
            key: 'title',
            render: (_, rec) => renderUser(rec.title, rec.username, false, () => { history.push(`/rights/user?user_id=${rec.id}`) })
        },
        {
            title: '',
            key: 'action',
            width: '50px',
            dataIndex: 'label',
            fixed: 'right',
            render: (_, rec: UserDetails) => (
                <Space size='small'>
                    {
                        !isEditorMember && (<Button type='text' size='small' disabled={true} className='actionButton' icon={<MinusCircleTwoTone twoToneColor="#cccccc" />} />)
                    }
                    {
                        isEditorMember && (
                            <Button type='text' size='small'
                                disabled={!selectedNode}
                                onClick={() => {
                                    if (selectedNode && selectedNode.id && rec) {
                                        // remove user
                                        const params = { id: selectedNode.id, user_ids: [rec.id] }
                                        dispatch(removeUserFromGroup(params))
                                    }
                                }}
                                className='actionButton'
                                icon={(selectedNode && selectedNode.id) ? <MinusCircleTwoTone twoToneColor='red' /> : <MinusCircleTwoTone twoToneColor="#cccccc" />}
                            />
                        )
                    }
                </Space>
            ),
        },
    ]

    const FilterByUser = (
        <>
            <span>{t('rights.userGroups.all_users')}</span>
            <Input
                placeholder={t('rights.userGroups.user_name')}
                style={{ width: '80px', marginLeft: '60px', padding: '2px' }}
                value={searchUser}
                allowClear={true}
                onClick={stopPropagation}
                onChange={(current) => {
                    setSearchUser(current.target.value)
                }}
            />
        </>
    )
    const columnsAllUser: ColumnsType<UserDetails> = [
        {
            title: FilterByUser,
            dataIndex: 'title',
            key: 'title',
            render: (_, rec) => renderUser(rec.title, rec.username, false, () => { history.push(`/rights/user?user_id=${rec.id}`) })
        },
        {
            title: '',
            key: 'action',
            width: '50px',
            dataIndex: 'id',
            fixed: 'right',
            render: (_, rec: UserDetails) => (
                <Space size='small'>
                    {
                        !isEditorMember && (<Button type='text' size='small' disabled={true} className='actionButton' icon={<PlusCircleTwoTone twoToneColor="#cccccc" />} />)
                    }
                    {
                        isEditorMember && (
                            <Button type='text' size='small'
                                disabled={!selectedNode}
                                onClick={() => {
                                    if (selectedNode && selectedNode.id && rec.id) {
                                        // add user
                                        const params = { id: selectedNode.id, user_ids: [rec.id] }
                                        dispatch(addUserToGroup(params))
                                    }
                                }}
                                className='actionButton'
                                icon={(selectedNode && selectedNode.id) ? <PlusCircleTwoTone /> : <PlusCircleTwoTone twoToneColor="#cccccc" />}
                            />
                        )
                    }
                </Space>
            ),
        },
    ]

    return (
        <>
            <Card className='GroupPage'
                title={
                    <Row>
                        <Col span={8}>
                            <BlockOutlined />&nbsp;{selectedNode ? t('rights.userGroups.group') : t('rights.userGroups.title')} &nbsp;
                            {selectedNode && renderSelected(selectedNode?.title)} &nbsp;
                            {
                                selectedNode && (
                                    <Button title={t('general.btnHistory')} size='small'
                                        onClick={() => {
                                            if (selectedNode.id) {
                                                setHistoryModelId(selectedNode.id)
                                                setHistoryTitle(`user / UserGroup / ${selectedNode.id}`)
                                                setHistoryModalVisible(true)
                                                setForceUpdateHistoryId(forceUpdateHistoryId)
                                            }
                                        }}
                                        icon={<InfoCircleTwoTone />}
                                        className='actionButton'
                                    />
                                )
                            }

                            {
                                isEditor && selectedNode && (
                                    <Button title={t('general.rename')} size='small'
                                        onClick={() => {
                                            if (selectedNode.id) {
                                                setEditGroup(selectedNode)
                                                setGroupModalVisible(true)
                                            }
                                        }}
                                        icon={<EditTwoTone twoToneColor='green' />}
                                        className='actionButton'
                                    />
                                )
                            }
                            {
                                isEditor && selectedNode &&
                                <PopConfirmZis title={t('rights.userGroups.delete_group_confirm')} onConfirm={() => {
                                    if (selectedNode) {
                                        handleDeleteGroup()
                                    }
                                }}>
                                    <Button type='text' danger size='small'
                                        disabled={!selectedNode}
                                        title={t('rights.userGroups.delete_group')}
                                        className='actionButton'
                                        icon={<DeleteTwoTone twoToneColor='red' />}
                                    />
                                </PopConfirmZis>
                            }
                        </Col>
                        <Col span={16} className='center'>&nbsp;{t('rights.resources.filter')} &nbsp;
                            <Select
                                showSearch
                                placeholder={t('general.all')}
                                allowClear={true}
                                value={selectedCustomerId}
                                style={{ width: '240px', textAlign: 'left', fontSize: '1.2em', fontWeight: 'bold' }}
                                onChange={onChangeCustomer}
                                onClear={() => onChangeCustomer(loggedUser.user.customer_id)}
                                optionFilterProp='label'
                                disabled={!loggedUser.user.is_zcom}
                                options={custOptions}
                            />
                        </Col>
                    </Row>
                }
                extra={
                    <Button type='primary'
                        disabled={!selectedNode || !isCreator}
                        onClick={() => {
                            setEditGroup(undefined)
                            setGroupModalVisible(true)
                        }}>
                        <PlusCircleOutlined /> {t('rights.userGroups.new_group')}
                    </Button>
                }
            >

                <Row gutter={[8, 8]} style={{ borderTop: '1px solid #dcdcdc' }}>
                    <Col span={8} className='panel' style={{ marginRight: '0px' }}>
                        <div style={{ width: '100%', height: '99%', overflowY: 'auto', border: 'solid #eeeeee 1px' }}>
                            <Tree
                                showLine={{ showLeafIcon: false }}
                                showIcon={true}
                                defaultExpandedKeys={['1_0']}
                                defaultExpandAll={true}
                                expandedKeys={expandedKeys}
                                autoExpandParent={true}
                                onSelect={onSelectTree}
                                onExpand={onExpandTree}
                                treeData={treeDataSource}
                            />
                        </div>
                    </Col>
                    <Col span={8} className='panel' style={{ marginRight: '0px' }}>

                        <div style={{ overflowY: 'hidden', borderBottom: 'solid 1px #eeeeee', marginBottom: '4px' }}>
                            <Table<RoleItem>
                                rowClassName={() => 'highlight'}
                                bordered={true}
                                size='small'
                                columns={columnsRole}
                                dataSource={roleSource}
                                loading={isLoading}
                                className='role-table'
                                rowKey='value'
                                scroll={{ y: 300 }}
                                pagination={false}
                            />
                        </div>

                        <div style={{ overflowY: 'hidden', borderBottom: 'solid 1px #eeeeee', marginBottom: '4px' }}>
                            <Table<ResGroupModel>
                                rowClassName={() => 'highlight'}
                                bordered={true}
                                columns={columnsRes}
                                dataSource={resGroupSource}
                                loading={isLoading}
                                className='res-group-table'
                                rowKey='id'
                                scroll={{ y: 300 }}
                                style={{ marginTop: '5px' }}
                                pagination={false}
                            />
                        </div>

                        <div style={{ overflowY: 'hidden', borderBottom: 'solid 1px #eeeeee' }}>
                            <Table<UserDetails>
                                rowClassName={() => 'highlight'}
                                bordered={true}
                                columns={columnsUser}
                                dataSource={userSource}
                                loading={isLoading}
                                className='res-group-table'
                                rowKey='id'
                                scroll={{ y: 300 }}
                                style={{ marginTop: '5px' }}
                                pagination={false}
                            />
                        </div>
                    </Col>
                    <Col span={8} className='panel' style={{ marginRight: '0px' }}>
                        <div style={{ overflowY: 'hidden', borderBottom: 'solid 1px #eeeeee' }}>
                            <Table<RoleItem>
                                rowClassName={() => 'highlight'}
                                bordered={true}
                                size='small'
                                columns={columnsRoleAll}
                                dataSource={roleAllSource}
                                className='role-all-table'
                                rowKey='value'
                                scroll={{ y: 300 }}
                                pagination={false}
                            />
                        </div>

                        <div style={{ overflowY: 'hidden', borderBottom: 'solid 1px #eeeeee' }}>
                            <Table<ResGroupModel>
                                rowClassName={() => 'highlight'}
                                bordered={true}
                                columns={columnsResAll}
                                dataSource={resGroupAllSource}
                                className='res-group-all-table'
                                rowKey='id'
                                scroll={{ y: 300 }}
                                style={{ marginTop: '5px' }}
                                pagination={false}
                            />
                        </div>

                        <div style={{ overflowY: 'hidden', borderBottom: 'solid 1px #eeeeee' }}>
                            <Table<UserDetails>
                                rowClassName={() => 'highlight'}
                                bordered={true}
                                columns={columnsAllUser}
                                dataSource={userAllSource}
                                className='res-group-table'
                                rowKey='id'
                                scroll={{ y: 300 }}
                                style={{ marginTop: '5px' }}
                                pagination={false}
                            />
                        </div>
                    </Col>
                </Row>

                <Row gutter={[8, 8]} style={{ borderTop: '1px solid #dcdcdc' }}>
                    <Col span={6} className='panel' style={{ marginRight: '0px' }}>

                    </Col>
                    <Col span={12} className='panel' style={{ marginRight: '0px' }}>

                    </Col>
                    <Col span={6} className='panel' style={{ marginRight: '0px' }}>

                    </Col>
                </Row>
            </Card>

            {
                selectedNode && selectedNode.id &&
                <Modal
                    destroyOnClose={true}
                    style={{ top: 20 }}
                    title={
                        <>
                            <BlockOutlined /> {t('rights.userGroups.new_group')}
                        </>
                    }
                    visible={isGroupModalVisible}
                    onCancel={() => setGroupModalVisible(false)}
                    maskClosable={false}
                    footer={null}>
                    <NewUserGroupForm parentId={selectedNode.id} group={editGroup} customerId={selectedNode.customer_id} setModalVisible={setGroupModalVisible} />
                </Modal>
            }

            <Modal
                destroyOnClose
                style={{ top: 50 }}
                bodyStyle={{ height: '60%', minHeight: 450, padding: 2 }}
                width='60%'
                className='historyLogModal'
                title={(<><InfoCircleOutlined />&nbsp;{t('general.history')}: {historyTitle}</>)}
                visible={isHistoryModalVisible}
                onCancel={() => setHistoryModalVisible(false)}
                maskClosable={false}
                modalRender={(modal) => (
                    <Draggable bounds={bounds} onStart={(ev, data) => onStart(ev, data)}>
                        <div ref={draggleRef}>{modal}</div>
                    </Draggable>
                )}
                footer={null}>

                <HistoryLog isModal={true}
                    service='user'
                    model='UserGroup'
                    modelId={historyModelId}
                    forceUpdateId={forceUpdateHistoryId}
                    showTitle={false} />

            </Modal>

        </>
    )
}

export default GroupsPage
