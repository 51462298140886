import {DeleteWebServiceRequestAction, DeleteWebServiceResponseAction, DeleteWebServiceErrorAction} from './index'
import {protectedApiClient} from 'helpers/api'
import {handleApiErrorWithNotification} from 'helpers/errorHandling'

export default (id: number, cb?: (isSuccess: boolean) => void) => {
    return (dispatch: (arg: DeleteWebServiceRequestAction | DeleteWebServiceResponseAction | DeleteWebServiceErrorAction) => void) => {
        const request = new DeleteWebServiceRequestAction(id)
        dispatch(request)

        protectedApiClient
            .delete(`/web/service/${id}`)
            .then((response) => {
                dispatch(new DeleteWebServiceResponseAction(request, response.data))
                cb && cb(true)
            })
            .catch((error) => {
                dispatch(new DeleteWebServiceErrorAction(request, error.toString()))
                handleApiErrorWithNotification(error)
                cb && cb(false)
            })
    }
}
