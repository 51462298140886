import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { AppState } from "../../common/models"
import React, { useEffect, useRef, useState } from "react"
import { AsyncLogRecord, LogRecord } from "../../pages/logviewer/models"
import { LoadingIndicator } from "../index"
import { AutoComplete, Button, Card, Table, Tag } from "antd"
import { ColumnsType } from "antd/lib/table"
import { ReloadOutlined } from "@ant-design/icons"
import './HistoryLog.scss'
import { _renderFullDateTime, removeDiac, stopPropagation } from "../../common/fce"
import { useLoggedUser } from "helpers/loginUserHelper"
import getAsyncLogs from "./actions/getAsyncLogs"


interface Props {
  isModal?: boolean
  showTitle?: boolean
  title?: string
  setModalVisible?: (visible: boolean) => void
  taskId?: number
  forceUpdateId?: number   // force update after saving item (just increment it to update)
}

const getDetail = (rec: LogRecord) => {
  return (
    <pre>
      {JSON.stringify(rec, null, 2)}
    </pre>
  )
}

const AsyncLog = (props: Props) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const isMounted = useRef(false)

  const { daemonLogs, isLoading, isLoadingDetails } = useSelector((state: AppState) => state.historylog)

  const [dataSource, setDataSource] = useState<AsyncLogRecord[]>([])
  const [historyTitle, setHistoryTitle] = useState('')
  const [triggerOneTimer, setTriggerOneTimer] = useState<number>(0)
  const [searchHistory, setSearchHistory] = useState<string>('')

  // get settings and current user
  const loggedUser = useLoggedUser()
  if (!loggedUser || !loggedUser.isLoaded()) {
    // waiting..
    return (
      <div className="fullwidth-loading" >
        <LoadingIndicator />
      </div>
    )
  }
  const appSetting = loggedUser.getAppSettings()

  useEffect(() => {
    if (props.isModal) {
      fetchHistory()
    }

    isMounted.current = true
    return () => { isMounted.current = false }
    // setTimeout(() => {fetchHistory()}, 1000);
  }, [])

  useEffect(() => {
    if (isMounted.current && props.forceUpdateId) {
      setTimeout(() => { fetchHistory() }, 2000)
    }
  }, [props.forceUpdateId])


  useEffect(() => {
    if (daemonLogs && triggerOneTimer > 0) {
      renderData()
    }
  }, [daemonLogs])

  useEffect(() => {
    renderData()
  }, [searchHistory])

  const renderData = () => {
    if (searchHistory && appSetting.checkMinSearch(searchHistory)) {
      setDataSource(daemonLogs.filter(rec => (removeDiac(rec.message).includes(removeDiac(searchHistory)))).map((item) => { return { ...item, key: item.id } }))
    }
    else {
      setDataSource(daemonLogs.map((item) => { return { ...item, key: item.id } }))
    }
  }

  const fetchHistory = () => {
    if (isHistoryReady()) {
      setTriggerOneTimer(Date.now().valueOf())
      setHistoryTitle(`Job ${props.taskId}`)
      props.taskId && dispatch(getAsyncLogs(props.taskId))
    }
  }

  const isHistoryReady = (): boolean => {
    return Boolean(props.taskId && props.taskId > 0)
  }

  const FilterByName = (
    <AutoComplete
      placeholder={t('history.message')}
      style={{ width: '150px' }}
      value={searchHistory}
      allowClear={true}
      onClick={stopPropagation}
      onKeyDown={(e) => { if (e.key === 'Enter') { e.stopPropagation() } }}
      onChange={(v) => {
        setSearchHistory(v)
      }}
    />
  )

  const historyColumns: ColumnsType<AsyncLogRecord> = [
    {
      title: t('history.date'),
      dataIndex: 'timestamp',
      key: 'timestamp',
      width: 180,
      className: 'left',
      fixed: 'left',
      render: (_, record: AsyncLogRecord) => (<span className='text-small noWrap'>{_renderFullDateTime(_).replace(' ', '\u00a0')}</span>)
    },
    {
      title: 'logger',
      dataIndex: 'logger',
      key: 'logger',
      width: 100,
      render: (logger: string) => <span className='text-small noWrap'>{logger}</span>
    },
    {
      title: FilterByName,
      dataIndex: 'message',
      key: 'message',
      width: '8000px',
      className: 'msg-col',
      render: (_, record: AsyncLogRecord) => (
        <div className='msg-box text-small noWrap'>
          {(record.message && record.message.length > 1000) ? record.message.substring(0, 1000) + '...' : record.message}
        </div>
      )
    }
  ]


  return (
    <Card title={props.showTitle && (
      <div>
        <span>{t('history.title')}: {props.title}</span>
        <Button title={t('general.refresh')}
          className='reloadBtn' style={{ marginLeft: '35px' }}
          onClick={() => { fetchHistory() }}
          disabled={!isHistoryReady()}
          loading={isLoading}
          type='text' size='small'><ReloadOutlined />{t('general.refresh')}</Button>
      </div>
    )}
      className='histLogCard'>

      <Table<AsyncLogRecord>
        rowClassName={() => 'highlight'}
        rowKey={'id'}
        bordered={true}
        size="small"
        scroll={{ y: 450 }}
        columns={historyColumns}
        loading={isLoading} // isLoading
        dataSource={dataSource}
        className={props.isModal ? 'HistoryLogTable' : 'HistoryLogPanel'}
        pagination={false}
        footer={() => (<span>{t('history.total')}: {dataSource.length}</span>)}
      />

    </Card>
  )

}

export default AsyncLog
