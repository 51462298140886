import { AsyncActionMode } from 'common/models'
import {
	CreateUserParams,
	UpdateUserParams,
	ProfileUserParams,
	CreateUserMailParams,
	UserMailDetail,
	SendUserMailParams,
	UserSentMailDetail,
	AuthenticationModel,
	UserAuthenticationModel,
	UserVerificationModel,
	UserAuthenticationParams,
	ChangePasswordParams,
	SshKeys,
	SetSshKeysParams,
} from '../models'
import { CompanyDetails } from 'pages/company/models'
import { UserDetails, SendWElcomeEmail } from 'pages/user/models'
import { Auth2faResponseData, Params2FA } from '../../login/models'
import { SettingsDetails, SettingsParams } from '../../settings/models'

export enum ActionType {
	CREATE_USER = 'CREATE_USER',
	UPDATE_USER = 'UPDATE_USER',
	DELETE_USER = 'DELETE_USER',
	GET_USER = 'GET_USER',
	GET_USER_COMPANY = 'GET_USER_COMPANY',
	ALL_USERS = 'ALL_USERS',
	GET_LAST_SEEN = 'GET_LAST_SEEN',
	GET_USER_ROLES = 'GET_USER_ROLES',
	GET_USER_SETTINGS = 'GET_USER_SETTINGS',
	GET_CUSTOMER_SETTINGS = 'GET_CUSTOMER_SETTINGS',
	GET_APP_SETTINGS = 'GET_APP_SETTINGS',
	UPDATE_SELF_SETTINGS = 'UPDATE_SELF_SETTINGS',
	UPDATE_USER_SETTINGS = 'UPDATE_USER_SETTINGS',
	UPDATE_CUSTOMER_SETTINGS = 'UPDATE_CUSTOMER_SETTINGS',
	UPDATE_APP_SETTINGS = 'UPDATE_APP_SETTINGS',
	PROFILE_USER = 'PROFILE_USER',
	RESET_ALL = 'RESET_ALL',
	CHANGE_PASSWORD = 'CHANGE_PASSWORD',
	CHANGE_PASSWORD_2FA_CONFIRM = 'CHANGE_PASSWORD_2FA_CONFIRM',
	RESET_PASSWORD = 'RESET_PASSWORD',
	SEND_WELCOME_EMAIL = 'SEND_WELCOME_EMAIL',
	GET_USER_GROUPS = 'GET_USER_GROUPS',
	CREATE_USER_MAIL = 'CREATE_USER_MAIL',
	SEND_USER_MAIL = 'SEND_USER_MAIL',
	ALL_AUTHENTICATIONS = 'ALL_AUTHENTICATIONS',
	CREATE_USER_AUTHENTICATION = 'CREATE_USER_AUTHENTICATION',
	UPDATE_USER_AUTHENTICATION = 'UPDATE_USER_AUTHENTICATION',
	DELETE_USER_AUTHENTICATION = 'DELETE_USER_AUTHENTICATION',
	DELETE_USER_AUTH_LOG = 'DELETE_USER_AUTH_LOG',
	GET_USER_AUTHENTICATION = 'GET_USER_AUTHENTICATION',
	REQUEST_VERIFICATION_CODE = 'REQUEST_VERIFICATION_CODE',
	CONFIRM_VERIFICATION_CODE = 'CONFIRM_VERIFICATION_CODE',
	GET_SSH_KEYS = 'GET_SSH_KEYS',
	SET_SSH_KEYS = 'SET_SSH_KEYS',
}

export type UserActions =
	| CreateUserRequestAction
	| CreateUserResponseAction
	| CreateUserErrorAction
	| UpdateUserRequestAction
	| UpdateUserResponseAction
	| UpdateUserErrorAction
	| DeleteUserRequestAction
	| DeleteUserResponseAction
	| DeleteUserErrorAction
	| ResetAllRequestAction
	| ResetAllResponseAction
	| ResetAllErrorAction
	| ChangePasswordRequestAction
	| ChangePasswordResponseAction
	| ChangePasswordErrorAction
	| ChangePassword2faConfirmRequestAction
	| ChangePassword2faConfirmResponseAction
	| ChangePassword2faConfirmErrorAction
	| GetUserRequestAction
	| GetUserResponseAction
	| GetUserErrorAction
	| AllUsersRequestAction
	| AllUsersResponseAction
	| AllUsersErrorAction
	| LastSeenRequestAction
	| LastSeenResponseAction
	| LastSeenErrorAction
	| UserRolesRequestAction
	| UserRolesResponseAction
	| UserRolesErrorAction
	| ProfileUserRequestAction
	| ProfileUserResponseAction
	| ProfileUserErrorAction
	| SendWelcomeEmailRequestAction
	| SendWelcomeEmailResponseAction
	| SendWelcomeEmailErrorAction
	| UserGroupsRequestAction
	| UserGroupsResponseAction
	| UserGroupsErrorAction
	| GetUserSettingsRequestAction
	| GetUserSettingsResponseAction
	| GetUserSettingsErrorAction
	| GetCustomerSettingsRequestAction
	| GetCustomerSettingsResponseAction
	| GetCustomerSettingsErrorAction
	| GetAppSettingsRequestAction
	| GetAppSettingsResponseAction
	| GetAppSettingsErrorAction
	| UpdateUserSettingRequestAction
	| UpdateUserSettingResponseAction
	| UpdateUserSettingErrorAction
	| UpdateCustomerSettingRequestAction
	| UpdateCustomerSettingResponseAction
	| UpdateCustomerSettingErrorAction
	| UpdateAppSettingRequestAction
	| UpdateAppSettingResponseAction
	| UpdateAppSettingErrorAction
	| CreateUserMailRequestAction
	| CreateUserMailResponseAction
	| CreateUserMailErrorAction
	| SendUserMailRequestAction
	| SendUserMailResponseAction
	| SendUserMailErrorAction
	| AllAuthenticationsRequestAction
	| AllAuthenticationsResponseAction
	| AllAuthenticationsErrorAction
	| CreateUserAuthenticationRequestAction
	| CreateUserAuthenticationResponseAction
	| CreateUserAuthenticationErrorAction
	| UpdateUserAuthenticationRequestAction
	| UpdateUserAuthenticationResponseAction
	| UpdateUserAuthenticationErrorAction
	| DeleteUserAuthenticationRequestAction
	| DeleteUserAuthenticationResponseAction
	| DeleteUserAuthenticationErrorAction
	| DeleteUserAuthLogRequestAction
	| DeleteUserAuthLogResponseAction
	| DeleteUserAuthLogErrorAction
	| RequestVerificationCodeRequestAction
	| RequestVerificationCodeResponseAction
	| RequestVerificationCodeErrorAction
	| ConfirmVerificationCodeRequestAction
	| ConfirmVerificationCodeResponseAction
	| ConfirmVerificationCodeErrorAction
	| GetUserAuthenticationRequestAction
	| GetUserAuthenticationResponseAction
	| GetUserAuthenticationErrorAction
	| GetUserCompanyRequestAction
	| GetUserCompanyResponseAction
	| GetUserCompanyErrorAction
	| GetSshKeysRequestAction
	| GetSshKeysResponseAction
	| GetSshKeysErrorAction
	| SetSshKeysRequestAction
	| SetSshKeysResponseAction
	| SetSshKeysErrorAction

export class GetUserAuthenticationRequestAction {
	readonly type = ActionType.GET_USER_AUTHENTICATION
	readonly mode = AsyncActionMode.REQUEST
	constructor() {
		''
	}
}
export class GetUserAuthenticationResponseAction {
	readonly type = ActionType.GET_USER_AUTHENTICATION
	readonly mode = AsyncActionMode.RESPONSE
	constructor(public request: GetUserAuthenticationRequestAction, public data: UserAuthenticationModel) {}
}

export class GetUserAuthenticationErrorAction {
	readonly type = ActionType.GET_USER_AUTHENTICATION
	readonly mode = AsyncActionMode.ERROR
	constructor(public request: GetUserAuthenticationRequestAction, public error: string) {
		''
	}
}

export class RequestVerificationCodeRequestAction {
	readonly type = ActionType.REQUEST_VERIFICATION_CODE
	readonly mode = AsyncActionMode.REQUEST
	constructor(public payload: UserVerificationModel) {}
}
export class RequestVerificationCodeResponseAction {
	readonly type = ActionType.REQUEST_VERIFICATION_CODE
	readonly mode = AsyncActionMode.RESPONSE
	constructor(public request: RequestVerificationCodeRequestAction, public data: UserVerificationModel) {}
}
export class RequestVerificationCodeErrorAction {
	readonly type = ActionType.REQUEST_VERIFICATION_CODE
	readonly mode = AsyncActionMode.ERROR
	constructor(public request: RequestVerificationCodeRequestAction, public error: string) {}
}

export class ConfirmVerificationCodeRequestAction {
	readonly type = ActionType.CONFIRM_VERIFICATION_CODE
	readonly mode = AsyncActionMode.REQUEST
	constructor(public payload: UserVerificationModel) {}
}
export class ConfirmVerificationCodeResponseAction {
	readonly type = ActionType.CONFIRM_VERIFICATION_CODE
	readonly mode = AsyncActionMode.RESPONSE
	constructor(public request: ConfirmVerificationCodeRequestAction, public data: UserVerificationModel) {}
}
export class ConfirmVerificationCodeErrorAction {
	readonly type = ActionType.CONFIRM_VERIFICATION_CODE
	readonly mode = AsyncActionMode.ERROR
	constructor(public request: ConfirmVerificationCodeRequestAction, public error: string) {}
}
export class ChangePasswordRequestAction {
	readonly type = ActionType.CHANGE_PASSWORD
	readonly mode = AsyncActionMode.REQUEST
	constructor(public payload: ChangePasswordParams) {}
}
export class ChangePasswordResponseAction {
	readonly type = ActionType.CHANGE_PASSWORD
	readonly mode = AsyncActionMode.RESPONSE
	constructor(public request: ChangePasswordRequestAction, public data: Auth2faResponseData) {}
}
export class ChangePasswordErrorAction {
	readonly type = ActionType.CHANGE_PASSWORD
	readonly mode = AsyncActionMode.ERROR
	constructor(public request: ChangePasswordRequestAction, public error: string) {}
}

export class ChangePassword2faConfirmRequestAction {
	readonly type = ActionType.CHANGE_PASSWORD_2FA_CONFIRM
	readonly mode = AsyncActionMode.REQUEST
	constructor(public payload: Params2FA) {}
}
export class ChangePassword2faConfirmResponseAction {
	readonly type = ActionType.CHANGE_PASSWORD_2FA_CONFIRM
	readonly mode = AsyncActionMode.RESPONSE
	constructor(public request: ChangePassword2faConfirmRequestAction, public data: string) {}
}
export class ChangePassword2faConfirmErrorAction {
	readonly type = ActionType.CHANGE_PASSWORD_2FA_CONFIRM
	readonly mode = AsyncActionMode.ERROR
	constructor(public request: ChangePassword2faConfirmRequestAction, public error: string) {}
}

export class CreateUserAuthenticationRequestAction {
	readonly type = ActionType.CREATE_USER_AUTHENTICATION
	readonly mode = AsyncActionMode.REQUEST
	constructor(public payload: UserAuthenticationModel) {}
}
export class CreateUserAuthenticationResponseAction {
	readonly type = ActionType.CREATE_USER_AUTHENTICATION
	readonly mode = AsyncActionMode.RESPONSE
	constructor(public request: CreateUserAuthenticationRequestAction, public data: UserAuthenticationModel) {}
}
export class CreateUserAuthenticationErrorAction {
	readonly type = ActionType.CREATE_USER_AUTHENTICATION
	readonly mode = AsyncActionMode.ERROR
	constructor(public request: CreateUserAuthenticationRequestAction, public error: string) {}
}

export class UpdateUserAuthenticationRequestAction {
	readonly type = ActionType.UPDATE_USER_AUTHENTICATION
	readonly mode = AsyncActionMode.REQUEST
	constructor(public payload: UserAuthenticationParams) {}
}
export class UpdateUserAuthenticationResponseAction {
	readonly type = ActionType.UPDATE_USER_AUTHENTICATION
	readonly mode = AsyncActionMode.RESPONSE
	constructor(public request: UpdateUserAuthenticationRequestAction, public data: UserAuthenticationModel) {}
}
export class UpdateUserAuthenticationErrorAction {
	readonly type = ActionType.UPDATE_USER_AUTHENTICATION
	readonly mode = AsyncActionMode.ERROR
	constructor(public request: UpdateUserAuthenticationRequestAction, public error: string) {}
}

export class DeleteUserAuthLogRequestAction {
	readonly type = ActionType.DELETE_USER_AUTH_LOG
	readonly mode = AsyncActionMode.REQUEST
	constructor(public id: number) {}
}
export class DeleteUserAuthLogResponseAction {
	readonly type = ActionType.DELETE_USER_AUTH_LOG
	readonly mode = AsyncActionMode.RESPONSE
	constructor(public request: DeleteUserAuthLogRequestAction, public id: number) {}
}
export class DeleteUserAuthLogErrorAction {
	readonly type = ActionType.DELETE_USER_AUTH_LOG
	readonly mode = AsyncActionMode.ERROR
	constructor(public request: DeleteUserAuthLogRequestAction, public error: string) {}
}
export class DeleteUserAuthenticationRequestAction {
	readonly type = ActionType.DELETE_USER_AUTHENTICATION
	readonly mode = AsyncActionMode.REQUEST
	constructor(public id: number) {}
}
export class DeleteUserAuthenticationResponseAction {
	readonly type = ActionType.DELETE_USER_AUTHENTICATION
	readonly mode = AsyncActionMode.RESPONSE
	constructor(public request: DeleteUserAuthenticationRequestAction, public id: number) {}
}
export class DeleteUserAuthenticationErrorAction {
	readonly type = ActionType.DELETE_USER_AUTHENTICATION
	readonly mode = AsyncActionMode.ERROR
	constructor(public request: DeleteUserAuthenticationRequestAction, public error: string) {}
}

export class AllAuthenticationsRequestAction {
	readonly type = ActionType.ALL_AUTHENTICATIONS
	readonly mode = AsyncActionMode.REQUEST
	constructor() {
		''
	}
}
export class AllAuthenticationsResponseAction {
	readonly type = ActionType.ALL_AUTHENTICATIONS
	readonly mode = AsyncActionMode.RESPONSE
	constructor(public request: AllAuthenticationsRequestAction, public data: AuthenticationModel[]) {}
}
export class AllAuthenticationsErrorAction {
	readonly type = ActionType.ALL_AUTHENTICATIONS
	readonly mode = AsyncActionMode.ERROR
	constructor(public request: AllAuthenticationsRequestAction, public error: string) {}
}

export class AllUsersRequestAction {
	readonly type = ActionType.ALL_USERS
	readonly mode = AsyncActionMode.REQUEST
	constructor() {
		''
	}
}
export class AllUsersResponseAction {
	readonly type = ActionType.ALL_USERS
	readonly mode = AsyncActionMode.RESPONSE
	constructor(public request: AllUsersRequestAction, public data: UserDetails[]) {}
}
export class AllUsersErrorAction {
	readonly type = ActionType.ALL_USERS
	readonly mode = AsyncActionMode.ERROR
	constructor(public request: AllUsersRequestAction, public error: string) {}
}

export class GetUserRequestAction {
	readonly type = ActionType.GET_USER
	readonly mode = AsyncActionMode.REQUEST
	constructor() {
		''
	}
}
export class GetUserResponseAction {
	readonly type = ActionType.GET_USER
	readonly mode = AsyncActionMode.RESPONSE
	constructor(public request: GetUserRequestAction, public data: UserDetails) {}
}

export class GetUserErrorAction {
	readonly type = ActionType.GET_USER
	readonly mode = AsyncActionMode.ERROR
	constructor(public request: GetUserRequestAction, public error: string) {
		''
	}
}

export class GetUserGroupsRequestAction {
	readonly type = ActionType.GET_USER_GROUPS
	readonly mode = AsyncActionMode.REQUEST
	constructor() {
		''
	}
}
export class GetUserGroupsResponseAction {
	readonly type = ActionType.GET_USER_GROUPS
	readonly mode = AsyncActionMode.RESPONSE
	constructor(public request: GetUserRequestAction, public data: UserDetails) {}
}

export class GetUserGroupsErrorAction {
	readonly type = ActionType.GET_USER_GROUPS
	readonly mode = AsyncActionMode.ERROR
	constructor(public request: GetUserRequestAction, public error: string) {
		''
	}
}

export class GetUserSettingsRequestAction {
	readonly type = ActionType.GET_USER_SETTINGS
	readonly mode = AsyncActionMode.REQUEST
	constructor() {
		''
	}
}
export class GetUserSettingsResponseAction {
	readonly type = ActionType.GET_USER_SETTINGS
	readonly mode = AsyncActionMode.RESPONSE
	constructor(public request: GetUserSettingsRequestAction, public data: SettingsDetails[]) {}
}

export class GetUserSettingsErrorAction {
	readonly type = ActionType.GET_USER_SETTINGS
	readonly mode = AsyncActionMode.ERROR
	constructor(public request: GetUserSettingsRequestAction, public error: string) {
		''
	}
}

export class GetCustomerSettingsRequestAction {
	readonly type = ActionType.GET_CUSTOMER_SETTINGS
	readonly mode = AsyncActionMode.REQUEST
	constructor() {
		''
	}
}
export class GetCustomerSettingsResponseAction {
	readonly type = ActionType.GET_CUSTOMER_SETTINGS
	readonly mode = AsyncActionMode.RESPONSE
	constructor(public request: GetCustomerSettingsRequestAction, public data: SettingsDetails[]) {}
}

export class GetCustomerSettingsErrorAction {
	readonly type = ActionType.GET_CUSTOMER_SETTINGS
	readonly mode = AsyncActionMode.ERROR
	constructor(public request: GetCustomerSettingsRequestAction, public error: string) {
		''
	}
}

export class GetAppSettingsRequestAction {
	readonly type = ActionType.GET_APP_SETTINGS
	readonly mode = AsyncActionMode.REQUEST
	constructor() {
		''
	}
}
export class GetAppSettingsResponseAction {
	readonly type = ActionType.GET_APP_SETTINGS
	readonly mode = AsyncActionMode.RESPONSE
	constructor(public request: GetAppSettingsRequestAction, public data: SettingsDetails[]) {}
}

export class GetAppSettingsErrorAction {
	readonly type = ActionType.GET_APP_SETTINGS
	readonly mode = AsyncActionMode.ERROR
	constructor(public request: GetAppSettingsRequestAction, public error: string) {
		''
	}
}

export class UpdateUserSettingRequestAction {
	readonly type = ActionType.UPDATE_USER_SETTINGS
	readonly mode = AsyncActionMode.REQUEST
	constructor(public payload: SettingsParams) {}
}
export class UpdateUserSettingResponseAction {
	readonly type = ActionType.UPDATE_USER_SETTINGS
	readonly mode = AsyncActionMode.RESPONSE
	constructor(public request: UpdateUserSettingRequestAction, public data: SettingsDetails) {}
}
export class UpdateUserSettingErrorAction {
	readonly type = ActionType.UPDATE_USER_SETTINGS
	readonly mode = AsyncActionMode.ERROR
	constructor(public request: UpdateUserSettingRequestAction, public error: string) {}
}
export class UpdateCustomerSettingRequestAction {
	readonly type = ActionType.UPDATE_CUSTOMER_SETTINGS
	readonly mode = AsyncActionMode.REQUEST
	constructor(public payload: SettingsParams) {}
}
export class UpdateCustomerSettingResponseAction {
	readonly type = ActionType.UPDATE_CUSTOMER_SETTINGS
	readonly mode = AsyncActionMode.RESPONSE
	constructor(public request: UpdateCustomerSettingRequestAction, public data: SettingsDetails) {}
}
export class UpdateCustomerSettingErrorAction {
	readonly type = ActionType.UPDATE_CUSTOMER_SETTINGS
	readonly mode = AsyncActionMode.ERROR
	constructor(public request: UpdateCustomerSettingRequestAction, public error: string) {}
}
export class UpdateAppSettingRequestAction {
	readonly type = ActionType.UPDATE_APP_SETTINGS
	readonly mode = AsyncActionMode.REQUEST
	constructor(public payload: SettingsParams) {}
}
export class UpdateAppSettingResponseAction {
	readonly type = ActionType.UPDATE_APP_SETTINGS
	readonly mode = AsyncActionMode.RESPONSE
	constructor(public request: UpdateAppSettingRequestAction, public data: SettingsDetails) {}
}
export class UpdateAppSettingErrorAction {
	readonly type = ActionType.UPDATE_APP_SETTINGS
	readonly mode = AsyncActionMode.ERROR
	constructor(public request: UpdateAppSettingRequestAction, public error: string) {}
}

export class LastSeenRequestAction {
	readonly type = ActionType.GET_LAST_SEEN
	readonly mode = AsyncActionMode.REQUEST
	constructor() {
		''
	}
}
export class LastSeenResponseAction {
	readonly type = ActionType.GET_LAST_SEEN
	readonly mode = AsyncActionMode.RESPONSE
	constructor(public request: LastSeenRequestAction, public data: UserDetails[]) {}
}
export class LastSeenErrorAction {
	readonly type = ActionType.GET_LAST_SEEN
	readonly mode = AsyncActionMode.ERROR
	constructor(public request: LastSeenRequestAction, public error: string) {}
}

export class CreateUserRequestAction {
	readonly type = ActionType.CREATE_USER
	readonly mode = AsyncActionMode.REQUEST
	constructor(public payload: CreateUserParams) {}
}
export class CreateUserResponseAction {
	readonly type = ActionType.CREATE_USER
	readonly mode = AsyncActionMode.RESPONSE
	constructor(public request: CreateUserRequestAction, public data: UserDetails) {}
}
export class CreateUserErrorAction {
	readonly type = ActionType.CREATE_USER
	readonly mode = AsyncActionMode.ERROR
	constructor(public request: CreateUserRequestAction, public error: string) {}
}

export class UpdateUserRequestAction {
	readonly type = ActionType.UPDATE_USER
	readonly mode = AsyncActionMode.REQUEST
	constructor(public payload: UpdateUserParams) {}
}
export class UpdateUserResponseAction {
	readonly type = ActionType.UPDATE_USER
	readonly mode = AsyncActionMode.RESPONSE
	constructor(public request: UpdateUserRequestAction, public data: UserDetails) {}
}
export class UpdateUserErrorAction {
	readonly type = ActionType.UPDATE_USER
	readonly mode = AsyncActionMode.ERROR
	constructor(public request: UpdateUserRequestAction, public error: string) {}
}

export class ProfileUserRequestAction {
	readonly type = ActionType.PROFILE_USER
	readonly mode = AsyncActionMode.REQUEST
	constructor(public payload: ProfileUserParams) {}
}

export class ProfileUserResponseAction {
	readonly type = ActionType.PROFILE_USER
	readonly mode = AsyncActionMode.RESPONSE
	constructor(public request: ProfileUserRequestAction, public data: UserDetails) {}
}

export class ProfileUserErrorAction {
	readonly type = ActionType.PROFILE_USER
	readonly mode = AsyncActionMode.ERROR
	constructor(public request: ProfileUserRequestAction, public error: string) {}
}

export class DeleteUserRequestAction {
	readonly type = ActionType.DELETE_USER
	readonly mode = AsyncActionMode.REQUEST
	constructor() {
		''
	}
}
export class DeleteUserResponseAction {
	readonly type = ActionType.DELETE_USER
	readonly mode = AsyncActionMode.RESPONSE
	constructor(public request: DeleteUserRequestAction, public data: UserDetails) {}
}
export class DeleteUserErrorAction {
	readonly type = ActionType.DELETE_USER
	readonly mode = AsyncActionMode.ERROR
	constructor(public request: DeleteUserRequestAction, public error: string) {}
}

export class ResetAllRequestAction {
	readonly type = ActionType.RESET_ALL
	readonly mode = AsyncActionMode.REQUEST
	constructor() {
		''
	}
}
export class ResetAllResponseAction {
	readonly type = ActionType.RESET_ALL
	readonly mode = AsyncActionMode.RESPONSE
	constructor(public request: ResetAllRequestAction) {}
}
export class ResetAllErrorAction {
	readonly type = ActionType.RESET_ALL
	readonly mode = AsyncActionMode.ERROR
	constructor(public request: ResetAllRequestAction, public error: string) {}
}

export class UserRolesRequestAction {
	readonly type = ActionType.GET_USER_ROLES
	readonly mode = AsyncActionMode.REQUEST
	constructor() {
		''
	}
}

export class UserRolesResponseAction {
	readonly type = ActionType.GET_USER_ROLES
	readonly mode = AsyncActionMode.RESPONSE
	constructor(public request: UserRolesRequestAction, public data: string[]) {}
}

export class UserRolesErrorAction {
	readonly type = ActionType.GET_USER_ROLES
	readonly mode = AsyncActionMode.ERROR
	constructor(public request: UserRolesRequestAction, public error: string) {}
}

// Get User Roles
export class UserGroupsRequestAction {
	readonly type = ActionType.GET_USER_GROUPS
	readonly mode = AsyncActionMode.REQUEST
	public url: string
	constructor(url: string) {
		this.url = url
	}
}

export class UserGroupsResponseAction {
	readonly type = ActionType.GET_USER_GROUPS
	readonly mode = AsyncActionMode.RESPONSE
	constructor(public request: UserGroupsRequestAction, public data: UserDetails) {}
}

export class UserGroupsErrorAction {
	readonly type = ActionType.GET_USER_GROUPS
	readonly mode = AsyncActionMode.ERROR
	constructor(public request: UserGroupsRequestAction, public error: string) {}
}

export class SendWelcomeEmailRequestAction {
	readonly type = ActionType.SEND_WELCOME_EMAIL
	readonly mode = AsyncActionMode.REQUEST
	constructor(public payload: SendWElcomeEmail) {}
}

export class SendWelcomeEmailResponseAction {
	readonly type = ActionType.SEND_WELCOME_EMAIL
	readonly mode = AsyncActionMode.RESPONSE
	constructor(public request: SendWelcomeEmailRequestAction, public data: SendWElcomeEmail) {}
}

export class SendWelcomeEmailErrorAction {
	readonly type = ActionType.SEND_WELCOME_EMAIL
	readonly mode = AsyncActionMode.ERROR
	constructor(public request: SendWelcomeEmailRequestAction, public error: string) {}
}

export class CreateUserMailRequestAction {
	readonly type = ActionType.CREATE_USER_MAIL
	readonly mode = AsyncActionMode.REQUEST
	constructor(public payload: CreateUserMailParams) {
		''
	}
}

export class CreateUserMailResponseAction {
	readonly type = ActionType.CREATE_USER_MAIL
	readonly mode = AsyncActionMode.RESPONSE
	constructor(public request: CreateUserMailRequestAction, public data: UserMailDetail) {}
}

export class CreateUserMailErrorAction {
	readonly type = ActionType.CREATE_USER_MAIL
	readonly mode = AsyncActionMode.ERROR
	constructor(public request: CreateUserMailRequestAction, public error: string) {}
}

export class SendUserMailRequestAction {
	readonly type = ActionType.SEND_USER_MAIL
	readonly mode = AsyncActionMode.REQUEST
	constructor(public payload: SendUserMailParams) {}
}

export class SendUserMailResponseAction {
	readonly type = ActionType.SEND_USER_MAIL
	readonly mode = AsyncActionMode.RESPONSE
	constructor(public request: SendUserMailRequestAction, public data: UserSentMailDetail) {}
}

export class SendUserMailErrorAction {
	readonly type = ActionType.SEND_USER_MAIL
	readonly mode = AsyncActionMode.ERROR
	constructor(public request: SendUserMailRequestAction, public error: string) {}
}

export class GetUserCompanyRequestAction {
	readonly type = ActionType.GET_USER_COMPANY
	readonly mode = AsyncActionMode.REQUEST
	constructor() {
		''
	}
}

export class GetUserCompanyResponseAction {
	readonly type = ActionType.GET_USER_COMPANY
	readonly mode = AsyncActionMode.RESPONSE
	constructor(public request: GetUserCompanyRequestAction, public data: CompanyDetails) {}
}

export class GetUserCompanyErrorAction {
	readonly type = ActionType.GET_USER_COMPANY
	readonly mode = AsyncActionMode.ERROR
	constructor(public request: GetUserCompanyRequestAction, public error: string) {}
}

export class GetSshKeysRequestAction {
	readonly type = ActionType.GET_SSH_KEYS
	readonly mode = AsyncActionMode.REQUEST
	constructor(public user_id: number) {}
}

export class GetSshKeysResponseAction {
	readonly type = ActionType.GET_SSH_KEYS
	readonly mode = AsyncActionMode.RESPONSE
	constructor(public request: GetSshKeysRequestAction, public data: SshKeys) {}
}

export class GetSshKeysErrorAction {
	readonly type = ActionType.GET_SSH_KEYS
	readonly mode = AsyncActionMode.ERROR
	constructor(public request: GetSshKeysRequestAction, public error: string) {}
}

export class SetSshKeysRequestAction {
	readonly type = ActionType.SET_SSH_KEYS
	readonly mode = AsyncActionMode.REQUEST
	constructor(public payload: SetSshKeysParams) {}
}

export class SetSshKeysResponseAction {
	readonly type = ActionType.SET_SSH_KEYS
	readonly mode = AsyncActionMode.RESPONSE
	constructor(public request: SetSshKeysRequestAction, public data: SshKeys) {}
}

export class SetSshKeysErrorAction {
	readonly type = ActionType.SET_SSH_KEYS
	readonly mode = AsyncActionMode.ERROR
	constructor(public request: SetSshKeysRequestAction, public error: string) {}
}
