import {GetAsyncJobsRequestAction, GetAsyncJobsResponseAction, GetAsyncJobsErrorAction} from '.'
import {protectedApiClient} from 'helpers/api'
import {handleApiErrorWithNotification} from 'helpers/errorHandling'
import {AsyncJobModel, JobGrid} from '../models'

export default (pageSize: number, pageNumber: number, filter: string, cb?: (isSuccess: boolean) => void) => {
	return (
		dispatch: (arg: GetAsyncJobsRequestAction | GetAsyncJobsResponseAction | GetAsyncJobsErrorAction) => void,
	) => {
		const request = new GetAsyncJobsRequestAction()
		dispatch(request)

		protectedApiClient
			.get<JobGrid>(`/asyncjob/job?expand=user,state,server&${filter}&pagination[pageSize]=${pageSize}&pagination[page]=${pageNumber}`)
			.then((response) => {
				dispatch(new GetAsyncJobsResponseAction(request, response.data))
				cb && cb(true)
			})
			.catch((error) => {
				dispatch(new GetAsyncJobsErrorAction(request, error))
				handleApiErrorWithNotification(error)
				cb && cb(false)
			})
	}
}
