import { AsyncActionMode, NameItem } from 'common/models'
import {
	CreateDomainParam,
	DnsCheck,
	DnsServiceModel,
	DnsServiceName,
	DnsServiceParams,
	DnsZone,
	DomainGrid,
	DomainModel,
	DomainServiceParams,
} from '../models'
import {
	AddDnsRecordParams,
	CreateDnsZoneParams,
	DeleteDnsZoneParams,
	DnsZoneName,
	DnsZoneModel,
	ExportDnsZoneParams,
	GetZoneParams,
	RemoveDnsRecordParams,
	SetDnsSecDnsZoneParams,
	SetSoaTtlDnsZoneParams,
} from '../models'
import { ServerModel } from 'pages/server/models'

export enum ActionType {
	GET_DOMAIN = 'GET_DOMAIN',
	GET_ZONE = 'GET_ZONE',
	GET_DOMAINS = 'GET_DOMAINS',
	GET_SERVERS_DNS = 'GET_SERVERS_DNS',
	CREATE_DOMAIN = 'CREATE_DOMAIN',
	DELETE_DOMAIN = 'DELETE_DOMAIN',
	CREATE_DNSSERVICE = 'CREATE_DNSSERVICE',
	ADD_DNSSERVICE = 'ADD_DNSSERVICE',
	REMOVE_DNSSERVICE = 'REMOVE_DNSSERVICE',
	CLEAR_DNSSERVICE = 'CLEAR_DNSSERVICE',
	GET_DNSSERVICEES = 'GET_DNSSERVICEES',
	GET_DNS_SERVICE_BY_NAME = 'GET_DNS_SERVICE_BY_NAME',
	GET_DNSSERVICE = 'GET_DNSSERVICE',
	UPDATE_DNSSERVICE = 'UPDATE_DNSSERVICE',
	DELETE_DNSSERVICE = 'DELETE_DNSSERVICE',

	CREATE_DNS_ZONE = 'CREATE_DNS_ZONE',
	GET_DNS_ZONES = 'GET_DNS_ZONES',
	GET_DNS_ZONE = 'GET_DNS_ZONE',
	CHECK_ZONE = 'CHECK_ZONE',
	DELETE_DNS_ZONE = 'DELETE_DNS_ZONE',
	EXPORT_DNS_ZONE = 'EXPORT_DNS_ZONE',
	SET_DNSSEC_DNS_ZONE = 'SET_DNSSEC_DNS_ZONE',
	ADD_RECORD_DNS_ZONE = 'ADD_RECORD_DNS_ZONE',
	REMOVE_RECORD_DNS_ZONE = 'REMOVE_RECORD_DNS_ZONE',
	GET_ARCHIVED_DNS_ZONE = 'GET_ARCHIVED_DNS_ZONE',
	CLEAR_ARCHIVED = 'CLEAR_ARCHIVED',
	RECOVER_DNS_ZONE = 'RECOVER_DNS_ZONE',
	LOOKUP_DOMAIN = 'LOOKUP_DOMAIN',
}

export type DnsServiceActions =
	| GetDomainRequestAction
	| GetDomainResponseAction
	| GetDomainErrorAction
	| GetZoneRequestAction
	| GetZoneResponseAction
	| GetZoneErrorAction
	| GetDomainsRequestAction
	| GetDomainsResponseAction
	| GetDomainsErrorAction
	| GetServersDnsRequestAction
	| GetServersDnsResponseAction
	| GetServersDnsErrorAction
	| CreateDomainRequestAction
	| CreateDomainResponseAction
	| CreateDomainErrorAction
	| DeleteDomainRequestAction
	| DeleteDomainResponseAction
	| DeleteDomainErrorAction
	| CreateDnsServiceRequestAction
	| CreateDnsServiceResponseAction
	| CreateDnsServiceErrorAction
	| AddDnsServiceRequestAction
	| AddDnsServiceResponseAction
	| AddDnsServiceErrorAction
	| RemoveDnsServiceRequestAction
	| RemoveDnsServiceResponseAction
	| RemoveDnsServiceErrorAction
	| GetDnsServicesRequestAction
	| GetDnsServicesResponseAction
	| GetDnsServicesErrorAction
	| GetDnsServiceRequestAction
	| GetDnsServiceResponseAction
	| GetDnsServiceErrorAction
	| ClearDnsServiceResponseAction
	| UpdateDnsServiceRequestAction
	| UpdateDnsServiceResponseAction
	| UpdateDnsServiceErrorAction
	| DeleteDnsServiceRequestAction
	| DeleteDnsServiceResponseAction
	| DeleteDnsServiceErrorAction
	| CreateDnsZoneRequestAction
	| CreateDnsZoneResponseAction
	| CreateDnsZoneErrorAction
	| GetDnsZonesRequestAction
	| GetDnsZonesResponseAction
	| GetDnsZonesErrorAction
	| GetDnsZoneRequestAction
	| GetDnsZoneResponseAction
	| GetDnsZoneErrorAction
	| CheckZoneRequestAction
	| CheckZoneResponseAction
	| CheckZoneErrorAction
	| DeleteDnsZoneRequestAction
	| DeleteDnsZoneResponseAction
	| DeleteDnsZoneErrorAction
	| DeleteDomainDnsZoneRequestAction
	| DeleteDomainDnsZoneResponseAction
	| DeleteDomainDnsZoneErrorAction
	| ExportDnsZoneRequestAction
	| ExportDnsZoneResponseAction
	| ExportDnsZoneErrorAction
	| SetDnsSecDnsZoneRequestAction
	| SetDnsSecDnsZoneResponseAction
	| SetDnsSecDnsZoneErrorAction
	| AddDnsRecordRequestAction
	| AddDnsRecordResponseAction
	| AddDnsRecordErrorAction
	| AddDomainDnsRecordRequestAction
	| AddDomainDnsRecordResponseAction
	| AddDomainDnsRecordErrorAction
	| RemoveDnsRecordRequestAction
	| RemoveDnsRecordResponseAction
	| RemoveDnsRecordErrorAction
	| RemoveDomainDnsRecordRequestAction
	| RemoveDomainDnsRecordResponseAction
	| RemoveDomainDnsRecordErrorAction
	| GetArchivedDnsZoneRequestAction
	| GetArchivedDnsZoneResponseAction
	| GetArchivedDnsZoneErrorAction
	| ClearArchivedResponseAction
	| RecoverDnsZoneRequestAction
	| RecoverDnsZoneResponseAction
	| RecoverDnsZoneErrorAction
	| LookupDomainRequestAction
	| LookupDomainResponseAction
	| LookupDomainErrorAction
	| GetDnsServiceByNameRequestAction
	| GetDnsServiceByNameResponseAction
	| GetDnsServiceByNameErrorAction

export class CreateDnsServiceRequestAction {
	readonly type = ActionType.CREATE_DNSSERVICE
	readonly mode = AsyncActionMode.REQUEST
	constructor(public payload: DnsServiceParams) {}
}
export class CreateDnsServiceResponseAction {
	readonly type = ActionType.CREATE_DNSSERVICE
	readonly mode = AsyncActionMode.RESPONSE
	constructor(public request: CreateDnsServiceRequestAction, public data: DnsServiceModel) {}
}
export class CreateDnsServiceErrorAction {
	readonly type = ActionType.CREATE_DNSSERVICE
	readonly mode = AsyncActionMode.ERROR
	constructor(public request: CreateDnsServiceRequestAction, public error: string) {}
}

export class AddDnsServiceRequestAction {
	readonly type = ActionType.ADD_DNSSERVICE
	readonly mode = AsyncActionMode.REQUEST
	constructor(public payload: DomainServiceParams) {}
}
export class AddDnsServiceResponseAction {
	readonly type = ActionType.ADD_DNSSERVICE
	readonly mode = AsyncActionMode.RESPONSE
	constructor(public request: AddDnsServiceRequestAction, public data: DomainModel) {}
}
export class AddDnsServiceErrorAction {
	readonly type = ActionType.ADD_DNSSERVICE
	readonly mode = AsyncActionMode.ERROR
	constructor(public request: AddDnsServiceRequestAction, public error: string) {}
}

export class RemoveDnsServiceRequestAction {
	readonly type = ActionType.REMOVE_DNSSERVICE
	readonly mode = AsyncActionMode.REQUEST
	constructor(public id: number) {}
}
export class RemoveDnsServiceResponseAction {
	readonly type = ActionType.REMOVE_DNSSERVICE
	readonly mode = AsyncActionMode.RESPONSE
	constructor(public request: RemoveDnsServiceRequestAction, public data: DomainModel) {}
}
export class RemoveDnsServiceErrorAction {
	readonly type = ActionType.REMOVE_DNSSERVICE
	readonly mode = AsyncActionMode.ERROR
	constructor(public request: RemoveDnsServiceRequestAction, public error: string) {}
}

export class CreateDomainRequestAction {
	readonly type = ActionType.CREATE_DOMAIN
	readonly mode = AsyncActionMode.REQUEST
	constructor(public payload: CreateDomainParam) {}
}
export class CreateDomainResponseAction {
	readonly type = ActionType.CREATE_DOMAIN
	readonly mode = AsyncActionMode.RESPONSE
	constructor(public request: CreateDomainRequestAction, public data: DomainModel) {}
}
export class CreateDomainErrorAction {
	readonly type = ActionType.CREATE_DOMAIN
	readonly mode = AsyncActionMode.ERROR
	constructor(public request: CreateDomainRequestAction, public error: string) {}
}

export class ClearDnsServiceResponseAction {
	readonly type = ActionType.CLEAR_DNSSERVICE
	readonly mode = AsyncActionMode.RESPONSE
	constructor() {}
}

export class GetDnsServiceRequestAction {
	readonly type = ActionType.GET_DNSSERVICE
	readonly mode = AsyncActionMode.REQUEST
	constructor(public payload: number) {
		''
	}
}
export class GetDnsServiceResponseAction {
	readonly type = ActionType.GET_DNSSERVICE
	readonly mode = AsyncActionMode.RESPONSE
	constructor(public request: GetDnsServiceRequestAction, public data: DnsServiceModel) {}
}
export class GetDnsServiceErrorAction {
	readonly type = ActionType.GET_DNSSERVICE
	readonly mode = AsyncActionMode.ERROR
	constructor(public request: GetDnsServiceRequestAction, public error: string) {}
}

export class GetDomainRequestAction {
	readonly type = ActionType.GET_DOMAIN
	readonly mode = AsyncActionMode.REQUEST
	constructor(domain: string) {
		''
	}
}
export class GetDomainResponseAction {
	readonly type = ActionType.GET_DOMAIN
	readonly mode = AsyncActionMode.RESPONSE
	constructor(public request: GetDomainRequestAction, public data: DomainModel) {}
}
export class GetDomainErrorAction {
	readonly type = ActionType.GET_DOMAIN
	readonly mode = AsyncActionMode.ERROR
	constructor(public request: GetDomainRequestAction, public error: string) {}
}

export class GetServersDnsRequestAction {
	readonly type = ActionType.GET_SERVERS_DNS
	readonly mode = AsyncActionMode.REQUEST
	constructor() {
		''
	}
}
export class GetServersDnsResponseAction {
	readonly type = ActionType.GET_SERVERS_DNS
	readonly mode = AsyncActionMode.RESPONSE
	constructor(public request: GetServersDnsRequestAction, public data: ServerModel[]) {}
}
export class GetServersDnsErrorAction {
	readonly type = ActionType.GET_SERVERS_DNS
	readonly mode = AsyncActionMode.ERROR
	constructor(public request: GetServersDnsRequestAction, public error: string) {}
}
export class GetZoneRequestAction {
	readonly type = ActionType.GET_ZONE
	readonly mode = AsyncActionMode.REQUEST
	constructor(public service_id: number) {}
}
export class GetZoneResponseAction {
	readonly type = ActionType.GET_ZONE
	readonly mode = AsyncActionMode.RESPONSE
	constructor(public request: GetZoneRequestAction, public data: DnsZoneModel) {}
}
export class GetZoneErrorAction {
	readonly type = ActionType.GET_ZONE
	readonly mode = AsyncActionMode.ERROR
	constructor(public request: GetZoneRequestAction, public error: string) {}
}

export class GetDomainsRequestAction {
	readonly type = ActionType.GET_DOMAINS
	readonly mode = AsyncActionMode.REQUEST
	constructor() {
		''
	}
}
export class GetDomainsResponseAction {
	readonly type = ActionType.GET_DOMAINS
	readonly mode = AsyncActionMode.RESPONSE
	constructor(public request: GetDomainsRequestAction, public data: DomainGrid) {}
}
export class GetDomainsErrorAction {
	readonly type = ActionType.GET_DOMAINS
	readonly mode = AsyncActionMode.ERROR
	constructor(public request: GetDomainsRequestAction, public error: string) {}
}

export class GetDnsServicesRequestAction {
	readonly type = ActionType.GET_DNSSERVICEES
	readonly mode = AsyncActionMode.REQUEST
	constructor() {
		''
	}
}
export class GetDnsServicesResponseAction {
	readonly type = ActionType.GET_DNSSERVICEES
	readonly mode = AsyncActionMode.RESPONSE
	constructor(public request: GetDnsServicesRequestAction, public data: DnsServiceName[]) {}
}
export class GetDnsServicesErrorAction {
	readonly type = ActionType.GET_DNSSERVICEES
	readonly mode = AsyncActionMode.ERROR
	constructor(public request: GetDnsServicesRequestAction, public error: string) {}
}

export class UpdateDnsServiceRequestAction {
	readonly type = ActionType.UPDATE_DNSSERVICE
	readonly mode = AsyncActionMode.REQUEST
	constructor(public data: DnsServiceModel) {}
}
export class UpdateDnsServiceResponseAction {
	readonly type = ActionType.UPDATE_DNSSERVICE
	readonly mode = AsyncActionMode.RESPONSE
	constructor(public request: UpdateDnsServiceRequestAction, public data: DnsServiceModel) {}
}
export class UpdateDnsServiceErrorAction {
	readonly type = ActionType.UPDATE_DNSSERVICE
	readonly mode = AsyncActionMode.ERROR
	constructor(public request: UpdateDnsServiceRequestAction, public error: string) {}
}

export class DeleteDnsServiceRequestAction {
	readonly type = ActionType.DELETE_DNSSERVICE
	readonly mode = AsyncActionMode.REQUEST
	constructor(public id: number) {
		''
	}
}
export class DeleteDnsServiceResponseAction {
	readonly type = ActionType.DELETE_DNSSERVICE
	readonly mode = AsyncActionMode.RESPONSE
	constructor(public request: DeleteDnsServiceRequestAction, public data: DnsServiceModel) {}
}
export class DeleteDnsServiceErrorAction {
	readonly type = ActionType.DELETE_DNSSERVICE
	readonly mode = AsyncActionMode.ERROR
	constructor(public request: DeleteDnsServiceRequestAction, public error: string) {}
}

export class DeleteDomainRequestAction {
	readonly type = ActionType.DELETE_DOMAIN
	readonly mode = AsyncActionMode.REQUEST
	constructor(public id: number) {
		''
	}
}
export class DeleteDomainResponseAction {
	readonly type = ActionType.DELETE_DOMAIN
	readonly mode = AsyncActionMode.RESPONSE
	constructor(public request: DeleteDomainRequestAction, public data: DnsServiceModel) {}
}
export class DeleteDomainErrorAction {
	readonly type = ActionType.DELETE_DOMAIN
	readonly mode = AsyncActionMode.ERROR
	constructor(public request: DeleteDomainRequestAction, public error: string) {}
}

export class GetArchivedDnsZoneRequestAction {
	readonly type = ActionType.GET_ARCHIVED_DNS_ZONE
	readonly mode = AsyncActionMode.REQUEST
	constructor(public payload: GetZoneParams) {}
}
export class GetArchivedDnsZoneResponseAction {
	readonly type = ActionType.GET_ARCHIVED_DNS_ZONE
	readonly mode = AsyncActionMode.RESPONSE
	constructor(public request: GetArchivedDnsZoneRequestAction, public data: DnsZoneModel | undefined) {}
}
export class GetArchivedDnsZoneErrorAction {
	readonly type = ActionType.GET_ARCHIVED_DNS_ZONE
	readonly mode = AsyncActionMode.ERROR
	constructor(public request: GetArchivedDnsZoneRequestAction, public error: string) {}
}

export class ClearArchivedResponseAction {
	readonly type = ActionType.CLEAR_ARCHIVED
	readonly mode = AsyncActionMode.RESPONSE
	constructor() {}
}

export class RecoverDnsZoneRequestAction {
	readonly type = ActionType.RECOVER_DNS_ZONE
	readonly mode = AsyncActionMode.REQUEST
	constructor(public payload: GetZoneParams) {}
}
export class RecoverDnsZoneResponseAction {
	readonly type = ActionType.RECOVER_DNS_ZONE
	readonly mode = AsyncActionMode.RESPONSE
	constructor(public request: RecoverDnsZoneRequestAction, public data: DnsZoneModel | undefined) {}
}
export class RecoverDnsZoneErrorAction {
	readonly type = ActionType.RECOVER_DNS_ZONE
	readonly mode = AsyncActionMode.ERROR
	constructor(public request: RecoverDnsZoneRequestAction, public error: string) {}
}

export class RemoveDnsRecordRequestAction {
	readonly type = ActionType.REMOVE_RECORD_DNS_ZONE
	readonly mode = AsyncActionMode.REQUEST
	constructor(public payload: RemoveDnsRecordParams) {}
}
export class RemoveDnsRecordResponseAction {
	readonly type = ActionType.REMOVE_RECORD_DNS_ZONE
	readonly mode = AsyncActionMode.RESPONSE
	constructor(public request: RemoveDnsRecordRequestAction, public data: DnsZoneModel) {}
}
export class RemoveDnsRecordErrorAction {
	readonly type = ActionType.REMOVE_RECORD_DNS_ZONE
	readonly mode = AsyncActionMode.ERROR
	constructor(public request: RemoveDnsRecordRequestAction, public error: string) {}
}

export class RemoveDomainDnsRecordRequestAction {
	readonly type = ActionType.REMOVE_RECORD_DNS_ZONE
	readonly mode = AsyncActionMode.REQUEST
	constructor(public payload: RemoveDnsRecordParams) {}
}
export class RemoveDomainDnsRecordResponseAction {
	readonly type = ActionType.REMOVE_RECORD_DNS_ZONE
	readonly mode = AsyncActionMode.RESPONSE
	constructor(public request: RemoveDomainDnsRecordRequestAction, public data: DnsZoneModel) {}
}
export class RemoveDomainDnsRecordErrorAction {
	readonly type = ActionType.REMOVE_RECORD_DNS_ZONE
	readonly mode = AsyncActionMode.ERROR
	constructor(public request: RemoveDomainDnsRecordRequestAction, public error: string) {}
}

export class AddDnsRecordRequestAction {
	readonly type = ActionType.ADD_RECORD_DNS_ZONE
	readonly mode = AsyncActionMode.REQUEST
	constructor(public payload: AddDnsRecordParams) {}
}
export class AddDnsRecordResponseAction {
	readonly type = ActionType.ADD_RECORD_DNS_ZONE
	readonly mode = AsyncActionMode.RESPONSE
	constructor(public request: AddDnsRecordRequestAction, public data: DnsZoneModel) {}
}
export class AddDnsRecordErrorAction {
	readonly type = ActionType.ADD_RECORD_DNS_ZONE
	readonly mode = AsyncActionMode.ERROR
	constructor(public request: AddDnsRecordRequestAction, public error: string) {}
}

export class AddDomainDnsRecordRequestAction {
	readonly type = ActionType.ADD_RECORD_DNS_ZONE
	readonly mode = AsyncActionMode.REQUEST
	constructor(public payload: AddDnsRecordParams) {}
}
export class AddDomainDnsRecordResponseAction {
	readonly type = ActionType.ADD_RECORD_DNS_ZONE
	readonly mode = AsyncActionMode.RESPONSE
	constructor(public request: AddDomainDnsRecordRequestAction, public data: DnsZoneModel) {}
}
export class AddDomainDnsRecordErrorAction {
	readonly type = ActionType.ADD_RECORD_DNS_ZONE
	readonly mode = AsyncActionMode.ERROR
	constructor(public request: AddDomainDnsRecordRequestAction, public error: string) {}
}

export class SetDnsSecDnsZoneRequestAction {
	readonly type = ActionType.SET_DNSSEC_DNS_ZONE
	readonly mode = AsyncActionMode.REQUEST
	constructor(public payload: SetDnsSecDnsZoneParams) {}
}
export class SetDnsSecDnsZoneResponseAction {
	readonly type = ActionType.SET_DNSSEC_DNS_ZONE
	readonly mode = AsyncActionMode.RESPONSE
	constructor(public request: SetDnsSecDnsZoneRequestAction, public data: DnsZoneModel) {}
}
export class SetDnsSecDnsZoneErrorAction {
	readonly type = ActionType.SET_DNSSEC_DNS_ZONE
	readonly mode = AsyncActionMode.ERROR
	constructor(public request: SetDnsSecDnsZoneRequestAction, public error: string) {}
}

export class ExportDnsZoneRequestAction {
	readonly type = ActionType.EXPORT_DNS_ZONE
	readonly mode = AsyncActionMode.REQUEST
	constructor(public payload: ExportDnsZoneParams) {}
}
export class ExportDnsZoneResponseAction {
	readonly type = ActionType.EXPORT_DNS_ZONE
	readonly mode = AsyncActionMode.RESPONSE
	constructor(public request: ExportDnsZoneRequestAction, public data: string) {}
}
export class ExportDnsZoneErrorAction {
	readonly type = ActionType.EXPORT_DNS_ZONE
	readonly mode = AsyncActionMode.ERROR
	constructor(public request: ExportDnsZoneRequestAction, public error: string) {}
}

export class CreateDnsZoneRequestAction {
	readonly type = ActionType.CREATE_DNS_ZONE
	readonly mode = AsyncActionMode.REQUEST
	constructor(public payload: CreateDnsZoneParams) {}
}
export class CreateDnsZoneResponseAction {
	readonly type = ActionType.CREATE_DNS_ZONE
	readonly mode = AsyncActionMode.RESPONSE
	constructor(public request: CreateDnsZoneRequestAction, public data: DnsZoneName[]) {}
}
export class CreateDnsZoneErrorAction {
	readonly type = ActionType.CREATE_DNS_ZONE
	readonly mode = AsyncActionMode.ERROR
	constructor(public request: CreateDnsZoneRequestAction, public error: string) {}
}

export class GetDnsZoneRequestAction {
	readonly type = ActionType.GET_DNS_ZONE
	readonly mode = AsyncActionMode.REQUEST
	constructor(public domain_id: number) {}
}
export class GetDnsZoneResponseAction {
	readonly type = ActionType.GET_DNS_ZONE
	readonly mode = AsyncActionMode.RESPONSE
	constructor(public request: GetDnsZoneRequestAction, public data: DnsZoneModel) {}
}
export class GetDnsZoneErrorAction {
	readonly type = ActionType.GET_DNS_ZONE
	readonly mode = AsyncActionMode.ERROR
	constructor(public request: GetDnsZoneRequestAction, public error: string) {}
}

export class CheckZoneRequestAction {
	readonly type = ActionType.CHECK_ZONE
	readonly mode = AsyncActionMode.REQUEST
	constructor(public payload: GetZoneParams) {}
}
export class CheckZoneResponseAction {
	readonly type = ActionType.CHECK_ZONE
	readonly mode = AsyncActionMode.RESPONSE
	constructor(public request: CheckZoneRequestAction, public data: DnsCheck) {}
}
export class CheckZoneErrorAction {
	readonly type = ActionType.CHECK_ZONE
	readonly mode = AsyncActionMode.ERROR
	constructor(public request: CheckZoneRequestAction, public error: string) {}
}

export class GetDnsZonesRequestAction {
	readonly type = ActionType.GET_DNS_ZONES
	readonly mode = AsyncActionMode.REQUEST
	constructor(public service_id: number) {
		''
	}
}
export class GetDnsZonesResponseAction {
	readonly type = ActionType.GET_DNS_ZONES
	readonly mode = AsyncActionMode.RESPONSE
	constructor(public request: GetDnsZonesRequestAction, public data: DnsZone[]) {}
}
export class GetDnsZonesErrorAction {
	readonly type = ActionType.GET_DNS_ZONES
	readonly mode = AsyncActionMode.ERROR
	constructor(public request: GetDnsZonesRequestAction, public error: string) {}
}

export class DeleteDnsZoneRequestAction {
	readonly type = ActionType.DELETE_DNS_ZONE
	readonly mode = AsyncActionMode.REQUEST
	constructor(public params: DeleteDnsZoneParams) {
		''
	}
}
export class DeleteDnsZoneResponseAction {
	readonly type = ActionType.DELETE_DNS_ZONE
	readonly mode = AsyncActionMode.RESPONSE
	constructor(public request: DeleteDnsZoneRequestAction, public data: DnsZoneName[]) {}
}
export class DeleteDnsZoneErrorAction {
	readonly type = ActionType.DELETE_DNS_ZONE
	readonly mode = AsyncActionMode.ERROR
	constructor(public request: DeleteDnsZoneRequestAction, public error: string) {}
}

export class DeleteDomainDnsZoneRequestAction {
	readonly type = ActionType.DELETE_DNS_ZONE
	readonly mode = AsyncActionMode.REQUEST
	constructor(public params: DeleteDnsZoneParams) {
		''
	}
}
export class DeleteDomainDnsZoneResponseAction {
	readonly type = ActionType.DELETE_DNS_ZONE
	readonly mode = AsyncActionMode.RESPONSE
	constructor(public request: DeleteDomainDnsZoneRequestAction, public data: DnsZoneName[]) {}
}
export class DeleteDomainDnsZoneErrorAction {
	readonly type = ActionType.DELETE_DNS_ZONE
	readonly mode = AsyncActionMode.ERROR
	constructor(public request: DeleteDomainDnsZoneRequestAction, public error: string) {}
}

export class LookupDomainRequestAction {
	readonly type = ActionType.LOOKUP_DOMAIN
	readonly mode = AsyncActionMode.REQUEST
	constructor(public field: string) {
		''
	}
}
export class LookupDomainResponseAction {
	readonly type = ActionType.LOOKUP_DOMAIN
	readonly mode = AsyncActionMode.RESPONSE
	constructor(public request: LookupDomainRequestAction, public field: string, public data: string[]) {}
}
export class LookupDomainErrorAction {
	readonly type = ActionType.LOOKUP_DOMAIN
	readonly mode = AsyncActionMode.ERROR
	constructor(public request: LookupDomainRequestAction, public error: string) {}
}

export class GetDnsServiceByNameRequestAction {
	readonly type = ActionType.GET_DNS_SERVICE_BY_NAME
	readonly mode = AsyncActionMode.REQUEST
	constructor(public name: string) {}
}

export class GetDnsServiceByNameResponseAction {
	readonly type = ActionType.GET_DNS_SERVICE_BY_NAME
	readonly mode = AsyncActionMode.RESPONSE
	constructor(public request: GetDnsServiceByNameRequestAction, public data: DnsServiceModel[]) {}
}

export class GetDnsServiceByNameErrorAction {
	readonly type = ActionType.GET_DNS_SERVICE_BY_NAME
	readonly mode = AsyncActionMode.ERROR
	constructor(public request: GetDnsServiceByNameRequestAction, public error: any) {}
}
