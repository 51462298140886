import { GetServersRequestAction, GetServersResponseAction, GetServersErrorAction } from '.'
import { protectedApiClient } from 'helpers/api'
import { handleApiErrorWithNotification } from 'helpers/errorHandling'
import { ServerGrid } from '../models'

export default (pageSize: number, pageNumber: number, filter: string, cb?: (isSuccess: boolean) => void) => {
	return (dispatch: (arg: GetServersRequestAction | GetServersResponseAction | GetServersErrorAction) => void) => {
		const request = new GetServersRequestAction()
		dispatch(request)

		protectedApiClient
			.get<ServerGrid>(
				`/server/server?expand=customer,services,primary_ip,status${filter}&pagination[pageSize]=${pageSize}&pagination[page]=${pageNumber}`
			)
			.then((response) => {
				dispatch(new GetServersResponseAction(request, response.data))
				cb && cb(true)
			})
			.catch((error) => {
				dispatch(new GetServersErrorAction(request, error))
				handleApiErrorWithNotification(error)
				cb && cb(false)
			})
	}
}
