import React, { useState, useEffect, useRef, ReactNode } from 'react'
import Draggable, { DraggableData, DraggableEvent } from 'react-draggable'
import { Card, Button, Modal, message, Table, Space, Tag, Spin, AutoComplete, Select } from 'antd'
import { GroupOutlined, PlusCircleOutlined } from '@ant-design/icons/lib/icons'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { AppState } from 'common/models'
import { PhysicalServerModel } from './models'
import './physicalServersPage.scss'
import { useHistory } from "react-router"
import { Link, useLocation } from "react-router-dom"
import queryString, { ParsedQuery } from "query-string"
import { useLoggedUser } from "../../../helpers/loginUserHelper"
import { LoadingIndicator } from "../../../components"
import getInventoryLocations from "../location/actions/getLocations"
import { _isNotEmptyObject, getJsonParam, removeDiac, SQL_DATE_FORMAT, stopPropagation } from "../../../common/fce"
import getTypes from "../type/actions/getTypes"
import { EditTwoTone, InfoCircleTwoTone, ProfileTwoTone } from "@ant-design/icons"
import getPhysicalServer from "./actions/getPhysicalServer"
import ServerCaseForm from "./ServerCaseForm"
import NoInventoryForm from "./NoInventoryForm"
import CreateServerForm from "./CreateServerForm"
import ComponentsForm from "./ComponentsForm"
import useLogger from "../../../common/useLogger"
import usePageSize from "../../../common/usePageSize"
import { ColumnsType } from "antd/lib/table"
import ErrorPage403 from "../../../components/Errors/ErrorPage403"
import { sort_ident, sort_label, sort_num, sort_str } from "../../../common/sorting"
import HistoryModal from "../../../components/History/HistoryModal"
import Pager from "../../../components/pagination/pager"
import lookupPhysicalServer from "./actions/lookupPhysicalServer"
import getPhysicalServers from "./actions/getPhysicalServers"
import tableCompanies from "../../login/actions/tableCompanies"


const getItemType = (item: PhysicalServerModel) => {
    //logger(JSON.stringify(person))
    if (!item.inventory) {
        return ''
    }
    const tid = item.inventory.type_id
    if (tid === 1) {
        return (<Tag color="green">{item.type}</Tag>)
    }
    if (tid === 2) {
        return (<Tag color="blue">{item.type}</Tag>)
    }
    if (tid === 4) {
        return (<Tag color="orange">{item.type}</Tag>)
    }
    else {
        return (<Tag color="gray">unknown</Tag>)
    }
}


const PhysicalServersPage = () => {
    const CONTROL_NAME = 'page_inventory_physical'
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const history = useHistory()
    const { search } = useLocation()

    const { companies } = useSelector((state: AppState) => state.auth.tables)
    const { locations } = useSelector((state: AppState) => state.inventorylocation)
    const { inventory_types } = useSelector((state: AppState) => state.inventorytype)
    const { physicalServers, physicalServer, pager, isLoading, isLoadingLookup,
        lookup_ident, lookup_inv_no, lookup_conf } = useSelector((state: AppState) => state.inventoryphysicalserver)
    const { inventory_servers } = useSelector((state: AppState) => state.sidenav)

    const [typeOptions, setTypeOptions] = useState<{ label: string, value: number }[]>([])
    const [locationOptions, setLocationOptions] = useState<{ label: string, value: number }[]>([])
    const [identOptions, setIdentOptions] = useState<{ label: string | ReactNode, value: string }[]>([])
    const [nameOptions, setNameOptions] = useState<{ label: string | ReactNode, value: string }[]>([])
    const [numberOptions, setNumberOptions] = useState<{ label: string | ReactNode, value: string }[]>([])

    // data
    const [dataSource, setDataSource] = useState<PhysicalServerModel[]>([])
    const [searchableServers, setSearchableServers] = useState<PhysicalServerModel[]>([])
    const [pageNumber, setPageNumber] = useState<number>(1)
    const [qsFilter, setQsFilter] = useState<string>('')

    // filters
    const [parsed, setParsed] = useState<ParsedQuery<string>>(queryString.parse(search))
    const [iid, setIid] = useState(0)
    const [searchInventory, setSearchInventory] = useState<string>('')
    const [searchServer, setSearchServer] = useState<string>('')
    const [searchNameLookup, setSearchNameLookup] = useState<string>('')
    const [searchName, setSearchName] = useState<string>('')
    const [searchIdentLookup, setSearchIdentLookup] = useState<string>('')
    const [searchIdent, setSearchIdent] = useState<string>('')
    const [searchNumberLookup, setSearchNumberLookup] = useState<string>('')
    const [searchNumber, setSearchNumber] = useState<string>('')
    const [searchType, setSearchType] = useState<number | undefined>(undefined)
    const [searchLocation, setSearchLocation] = useState<number | undefined>(undefined)
    const [searchIpmi, setSearchIpmi] = useState<string | undefined>(undefined)
    const [callServer, setCallServer] = useState<boolean>(false)

    // page states
    const [dataToUpdate, setDataToUpdate] = useState<PhysicalServerModel>()
    const [selectedId, setSelectedId] = useState<number>(0)
    const [isHistoryModalVisible, setHistoryModalVisible] = useState(false)
    const [historyTitle, setHistoryTitle] = useState('')
    const [historyModelId, setHistoryModelId] = useState<number | undefined>()
    const [isModalComponentsVisible, setModalComponentsVisible] = useState<boolean>(false)
    const [isModalServerCaseVisible, setModalServerCaseVisible] = useState<boolean>(false)
    const [isModalRouterVisible, setModalRouterVisible] = useState<boolean>(false)
    const [isModalBladeVisible, setModalBladeVisible] = useState<boolean>(false)
    const [isModalNoInventoryVisible, setModalNoInventoryVisible] = useState<boolean>(false)
    const [isModalCreateVisible, setModalCreateVisible] = useState<boolean>(false)
    const [ready, setReady] = useState<number>(0)

    // permissions
    const [isViewer, setViewer] = useState(false)
    const [isCreator, setCreator] = useState(false)
    const [isEditor, setEditor] = useState(false)
    const [isDeleter, setDeleter] = useState(false)

    // get settings and current user
    const loggedUser = useLoggedUser()
    if (!loggedUser || !loggedUser.isLoaded()) {
        // waiting..
        return (
            <div className="fullwidth-loading" >
                <LoadingIndicator />
            </div>
        )
    }

    // required authorization
    if (!loggedUser.hasAccess(CONTROL_NAME)) {
        return <ErrorPage403 />
    }

    // settings
    const appSetting = loggedUser.getAppSettings()
    const SEARCH_MIN = appSetting.min_search_length

    // usage: logger(msg, obj=null)
    const logger = useLogger(appSetting, 'PhysicalServersPage')
    usePageSize(appSetting, loggedUser.user.id, appSetting.grid_page_size)
    const [pageSize, setPageSize] = useState<number>(appSetting.grid_page_size)
    usePageSize(appSetting, loggedUser.user.id, pageSize)

    // history drag modal
    const [disabled, setDisabled] = useState(true)
    const draggleRef = useRef<HTMLDivElement>(null)
    const [bounds, setBounds] = useState({ left: 0, top: 0, bottom: 0, right: 0 })
    const onStart = (_event: DraggableEvent, uiData: DraggableData) => {
        const { clientWidth, clientHeight } = window.document.documentElement
        const targetRect = draggleRef.current?.getBoundingClientRect()
        if (!targetRect) {
            return
        }
        setBounds({
            left: -targetRect.left + uiData.x,
            right: clientWidth - (targetRect.right - uiData.x),
            top: -targetRect.top + uiData.y,
            bottom: clientHeight - (targetRect.bottom - uiData.y),
        })
    }

    // to handle SideNav menu pressed after 1sec
    const [triggerOneTimer, setTriggerOneTimer] = useState(0)

    useEffect(() => {
        // trigger ONCE
        setTriggerOneTimer(Date.now().valueOf())
        logger('=== ONCE  url=' + search)
        setPageSize(appSetting.grid_page_size)
        if (loggedUser) {
            setViewer(loggedUser.hasAccess('page_inventory_physical'))
            setCreator(loggedUser.hasAccess('page_inventory_physical_create_button'))
            setEditor(loggedUser.hasAccess('page_inventory_physical_edit_button'))
            setDeleter(loggedUser.hasAccess('page_inventory_physical_delete_button'))
        }

        // load Options for refresh F5
        loadOptions()
        handleQueryStringFilters()
    }, [])

    useEffect(() => {
        // handle a single item by ID
        logger('=== IID ' + iid)
    }, [iid])

    useEffect(() => {
        // SideNav menu pressed
        const when = triggerOneTimer + 1000
        if (triggerOneTimer > 0 && when < Date.now().valueOf()) {
            logger('--- SideNav menu pressed')
            clearFilters()
            fetchRecords(1, pageSize)
        }
    }, [inventory_servers])

    useEffect(() => {
        // check if Ready
        if (companies && companies.length &&
            locations && locations.length &&
            inventory_types && inventory_types.length) {
            // options are loaded
            setReady(ready + 1)
        }
    }, [companies, locations, inventory_types])

    useEffect(() => {
        // process Options
        if (ready) {
            logger(`Ready=${ready} iid=${iid}`)
            setLocationOptions(locations.map(t => ({ label: t.name, value: t.id })).sort(sort_label))
            setTypeOptions(inventory_types.map(t => ({ label: t.name, value: t.id })).sort(sort_label))
        }
    }, [ready])

    useEffect(() => {
        // populate numberOptions
        setNumberOptions(lookup_inv_no.map(s => ({ label: s, value: s })))
    }, [lookup_inv_no])

    useEffect(() => {
        // populate identOptions
        setIdentOptions(lookup_ident.map(s => ({ label: s, value: s })))
    }, [lookup_ident])

    useEffect(() => {
        // populate nameOptions
        setNameOptions(lookup_conf.map(s => ({ label: s, value: s })))
    }, [lookup_conf])

    useEffect(() => {
        replaceHistory()
    }, [pageSize, pageNumber, qsFilter])

    useEffect(() => {
        // update QsFilter
        if (!isModalCreateVisible) {
            const qs: string = prepareQsFilter()
            logger(`qsFilter:${qs}`)
            if (qs != qsFilter) {
                setQsFilter(qs)
            }
        }
    }, [searchLocation, searchType, searchNumber, searchName, searchIdent, searchIpmi, ready])

    useEffect(() => {
        // when filter is changed
        // show the first page
        logger(`qsFilter changed: page: ${pageNumber}, pageSize: ${pageSize}, qs=${qsFilter}`)
        let pn = pageNumber
        if (ready) {    // do not change page for F5
            pn = 1
        }
        fetchRecords(pn, pageSize)
    }, [qsFilter])

    useEffect(() => {
        if (!ready) {
            return
        }
        if (physicalServers) {
            logger('useEffect physicalServers=' + physicalServers.items.length)
            setSearchableServers(physicalServers.items.map(server => {
                const it = server.inventory && inventory_types.find(t => server.inventory?.type_id === t.id)
                let t_name = it ? it.name : ''
                const loc = server.inventory && locations.find(l => server.inventory?.location_id === l.id)
                let l_name = loc ? loc.name : ''
                let rack_name = server.rack ? (server.rack_pos ? server.rack.name + ` (${server.rack_pos})` : server.rack.name) : ''
                return {
                    ...server,
                    type: t_name,
                    location_id: server.inventory && server.inventory.location_id,
                    location: l_name,
                    rack_name: rack_name,
                    u_size: server.inventory && getJsonParam(server.inventory.parameters, 'u_size'),
                    ipmi_ip: server.inventory && getJsonParam((server.inventory.parameters), 'ipmi_ip'),
                    searchableName: server.inventory && server.inventory.inv_no + '|' + removeDiac(server.conf)
                }
            }))
        }
    }, [physicalServers.items])

    useEffect(() => {
        if (!ready) {
            return
        }
        // update datasource when data was changed
        if (!isModalServerCaseVisible && !isModalRouterVisible && !isModalBladeVisible && !isModalComponentsVisible) {
            refreshGrid()
        }
    }, [searchableServers, isModalServerCaseVisible, isModalRouterVisible, isModalBladeVisible, isModalComponentsVisible])


    // ------------------------------ end hooks

    const replaceHistory = () => {
        if (filterIsValid()) {
            const url = `/inventory/physical-servers?pagination[pageSize]=${pageSize}&pagination[page]=${pageNumber}&` + qsFilter
            history.replace(url)
        }
    }

    const getServerCaseTypes = () => {
        return inventory_types.filter(t => t.id === 1 || t.id === 2 || t.id === 4 || t.id === 15)
    }

    const handleQueryStringFilters = () => {
        // parse query string
        // and set filters
        // /billing/invoice?filter_id=6&number=122&from=2022-06-01&to=2022-08-10
        if (parsed && _isNotEmptyObject(parsed)) {
            logger(`handleQueryStringFilters..`)
            const qs_id: string | string[] | null = parsed['id']
            if (qs_id && typeof qs_id === 'string') {
                // call by ID
                const id = parseInt(qs_id)
                if (id > 0) {
                    setIid(id)
                    return
                }
            }

            // iid=0
            const qs_conf: string | string[] | null = parsed['conf']
            if (qs_conf && typeof qs_conf === 'string') {
                setSearchNameLookup(qs_conf)  // textbox
                setSearchName(qs_conf) // filter
            }
            const qs_ident: string | string[] | null = parsed['ident']
            if (qs_ident && typeof qs_ident === 'string') {
                setSearchIdentLookup(qs_ident.toLowerCase())  // textbox
                setSearchIdent(qs_ident.toLowerCase()) // filter
            }
            const qs_number: string | string[] | null = parsed['inv_no']
            if (qs_number && typeof qs_number === 'string') {
                setSearchNumberLookup(qs_number.toLowerCase())  // textbox
                setSearchNumber(qs_number.toLowerCase()) // filter
            }
            const qs_type: string | string[] | null = parsed['type_id']
            if (qs_type && typeof qs_type === 'string') {
                const tid = parseInt(qs_type)
                if (tid > 0) {
                    setSearchType(tid)
                }
            }
            const qs_location: string | string[] | null = parsed['location_id']
            if (qs_location && typeof qs_location === 'string') {
                const lid = parseInt(qs_location)
                if (lid > 0) {
                    setSearchType(lid)
                }
            }
            const qs_size: string | string[] | null = parsed['pagination[pageSize]']
            if (qs_size && typeof qs_size === 'string') {
                const ps = parseInt(qs_size)
                if (ps > 0) {
                    setPageSize(ps)
                }
            }
            const qs_num: string | string[] | null = parsed['pagination[page]']
            if (qs_num && typeof qs_num === 'string') {
                const pn = parseInt(qs_num)
                setPageNumber(pn)
            }
        }
    }

    const loadOptions = () => {
        // load Options for refresh F5
        if (!companies || companies.length === 0) {
            dispatch(tableCompanies())
        }
        if (!locations || locations.length === 0) {
            dispatch(getInventoryLocations())
        }
        if (!inventory_types || inventory_types.length === 0) {
            dispatch(getTypes())
        }
    }

    const clearFilters = () => {
        // used when clicked on left menu
        logger('clearFilters')
        setIid(0)
        setSearchNameLookup('')
        setSearchNumberLookup('')
        setSearchIdentLookup('')
        setSearchName('')
        setSearchNumber('')
        setSearchIdent('')
        setSearchType(undefined)
        setSearchLocation(undefined)
    }

    const getQSFilter = (): string[] => {
        let qs: string[] = []
        if (searchType && searchType > 0) {
            qs.push('type_id=' + searchType)
        }
        if (searchLocation && searchLocation > 0) {
            qs.push('location_id=' + searchLocation)
        }
        !ready && qs.push('ready=0')
        return qs
    }

    const prepareQsFilter = (): string => {
        if (iid > 0) {
            let qs: string[] = []
            qs.push('id=' + iid)
            return qs.join("&")
        }
        // load filtered data from server
        let qs: string[]
        qs = getQSFilter()
        if (appSetting.checkMinSearch(searchNameLookup)) {
            qs.push('conf=' + encodeURIComponent(searchNameLookup))
        }
        if (appSetting.checkMinSearch(searchNumberLookup)) {
            qs.push('inv_no=' + encodeURIComponent(searchNumberLookup))
        }
        if (appSetting.checkMinSearch(searchIdentLookup)) {
            qs.push('ident=' + encodeURIComponent(searchIdentLookup))
        }
        logger('prepareQsFilter: ' + qs.join("&"))
        return qs.join("&")
    }

    const fetchNumberLookup = (searchText: string) => {
        // call lookup for inv.number
        searchText = searchText.trim()
        if (appSetting.checkMinSearch(searchText.trim())) {
            let qs: string[]
            qs = getQSFilter()
            if (appSetting.checkMinSearch(searchName)) {
                qs.push('conf=' + encodeURIComponent(searchName))
            }
            if (appSetting.checkMinSearch(searchIdent)) {
                qs.push('ident=' + encodeURIComponent(searchIdent))
            }
            if (appSetting.checkMinSearch(searchText)) {
                qs.push('field=inv_no')
                qs.push('value=' + encodeURIComponent(searchText.trim()))
                logger('lookupPhysicalServer: ' + qs.join("&"))
                dispatch(lookupPhysicalServer('inv_no', qs.join("&")))
            }
        }
    }

    const fetchNameLookup = (searchText: string) => {
        // call lookup for name(conf)
        if (appSetting.checkMinSearch(searchText)) {
            let qs: string[]
            qs = getQSFilter()
            if (appSetting.checkMinSearch(searchNumber)) {
                qs.push('inv_no=' + encodeURIComponent(searchNumber))
            }
            if (appSetting.checkMinSearch(searchIdent)) {
                qs.push('ident=' + encodeURIComponent(searchIdent))
            }
            if (appSetting.checkMinSearch(searchText)) {
                qs.push('field=conf')
                qs.push('value=' + encodeURIComponent(searchText))
                logger('lookupPhysicalServer: ' + qs.join("&"))
                dispatch(lookupPhysicalServer('conf', qs.join("&")))
            }
        }
    }

    const fetchIdentLookup = (searchText: string) => {
        // call lookup Ident
        if (appSetting.checkMinSearch(searchText)) {
            let qs: string[]
            qs = getQSFilter()
            if (appSetting.checkMinSearch(searchName)) {
                qs.push('conf=' + encodeURIComponent(searchName))
            }
            if (appSetting.checkMinSearch(searchNumber)) {
                qs.push('inv_no=' + encodeURIComponent(searchNumber))
            }
            if (appSetting.checkMinSearch(searchText)) {
                qs.push('field=ident')
                qs.push('value=' + encodeURIComponent(searchText.trim()))
                logger('lookupPhysicalServer: ' + qs.join("&"))
                dispatch(lookupPhysicalServer('ident', qs.join("&")))
            }
        }
    }

    const filterIsValid = (): boolean => {
        // purchase_from is required
        return true
    }

    const fetchRecords = (pn: number, ps: number) => {
        if (!isViewer) {
            return
        }
        setPageNumber(pn)
        setPageSize(ps)
        if (ready && filterIsValid()) {
            // purchase_from is required
            logger(`fetchRecords: page: ${pn}, pageSize: ${ps}, qs=${qsFilter}`)
            dispatch(getPhysicalServers(ps, pn - 1, qsFilter, suc => { }))
        }
    }

    const refreshGrid = () => {
        logger('refreshGrid')
        setDataSource(searchableServers.sort(sort_ident))
    }

    const onClearName = () => {
        setSearchNameLookup('')
        setSearchName('')
        setNameOptions([])
    }

    const onSelectName = (data: string) => {
        setSearchName(data)
    }

    const onChangeNameLookup = (data: string) => {
        if (!data) {
            if (searchNameLookup.length === 1) {
                setSearchNameLookup('')
                fetchNameLookup('')
            }
            return
        }
        if (data != searchNameLookup) {
            setSearchNameLookup(data)
            fetchNameLookup(data)
        }
    }

    const onClearNumber = () => {
        setSearchNumberLookup('')
        setSearchNumber('')
        setNumberOptions([])
    }

    const onSelectNumber = (data: string) => {
        setSearchNumber(data)
    }

    const onChangeNumberLookup = (data: string) => {
        if (!data) {
            if (searchNumberLookup.length === 1) {
                setSearchNumberLookup('')
                fetchNumberLookup('')
            }
            return
        }
        if (data != searchNumberLookup) {
            setSearchNumberLookup(data)
            fetchNumberLookup(data)
        }
    }

    const onClearIdent = () => {
        setSearchIdentLookup('')
        setSearchIdent('')
        setIdentOptions([])
    }

    const onChangeIdentLookup = (data: string) => {
        if (!data) {
            if (searchIdentLookup.length === 1) {
                setSearchIdentLookup('')
                fetchIdentLookup('')
            }
            return
        }
        if (data != searchIdentLookup) {
            setSearchIdentLookup(data)
            fetchIdentLookup(data)
        }
    }

    const onSelectIdent = (data: string) => {
        setSearchIdent(data)
    }

    const handleFilterChange = (name: string, value: any) => {
        logger('handleFilterChange: ' + value)
        if (name === 'location') {
            if (value) {
                setSearchLocation(value)
            } else {
                setSearchLocation(undefined)
                setCallServer(!callServer)
            }
        }
        if (name === 'type') {
            if (value && value > 0) {
                setSearchType(value)
            } else {
                setSearchType(undefined)
            }
        }
        if (name === 'ipmi') {
            if (value) {
                setSearchIpmi(value)
            } else {
                setSearchIpmi(undefined)
                setCallServer(!callServer)
            }
        }
        if (name === 'inventory') {
            if (value) {
                setSearchInventory(value)
            } else {
                setSearchInventory('')
                setCallServer(!callServer)
            }
        }
        if (name === 'server') {
            if (value) {
                setSearchName(value)
            } else {
                // call server
                setSearchName('')
                setCallServer(!callServer)
            }
        }
        if (name === 'ident') {
            if (value) {
                setSearchNumber(value)
            } else {
                // call server
                setSearchNumber('')
                setCallServer(!callServer)
            }
        }
    }

    const FilterByLocation = locations && (
        <Select
            showSearch
            placeholder={t('inventoryPage.location')}
            options={locationOptions}
            value={searchLocation}
            style={{ width: '120px', marginRight: "1rem" }}
            dropdownMatchSelectWidth={200}
            optionFilterProp="children"
            filterOption={(input, opt) => removeDiac(opt?.label ?? '').includes(removeDiac(input))}
            filterSort={sort_label}
            allowClear
            onClick={stopPropagation}
            onChange={(v) => { handleFilterChange('location', v) }}
        />
    )

    const FilterByIpmi = (
        <AutoComplete
            placeholder={t('inventoryPage.ipmi_ip')}
            value={searchIpmi}
            style={{ width: '70px' }}
            onClick={stopPropagation}
            onKeyDown={(e) => { if (e.key === 'Enter') { e.stopPropagation() } }}
            allowClear={true}
            onChange={v => {
                handleFilterChange('ipmi', v)
            }}
        />
    )

    const FilterByInventory = (
        <AutoComplete
            showSearch
            placeholder={t('inventoryPage.inv_no')}
            style={{ width: '80px', marginRight: "1rem" }}
            value={searchNumberLookup}
            options={numberOptions}
            dropdownMatchSelectWidth={200}
            onInputKeyDown={(e) => {
                if (e.key === 'Enter') {
                    onSelectNumber(e.currentTarget.value)
                }
            }}
            onSelect={onSelectNumber}
            onSearch={onChangeNumberLookup}
            onChange={onChangeNumberLookup}
            onClear={onClearNumber}
            onClick={stopPropagation}
            notFoundContent={isLoadingLookup && <Spin />}
            filterOption={true}
            optionFilterProp='label'
            allowClear={true}
        />
    )

    const FilterByName = (
        <AutoComplete
            showSearch
            value={searchNameLookup}
            options={nameOptions}
            style={{ width: 200 }}
            dropdownMatchSelectWidth={400}
            onInputKeyDown={(e) => {
                if (e.key === 'Enter') {
                    onSelectName(e.currentTarget.value)
                }
            }}
            onSelect={onSelectName}
            onSearch={onChangeNameLookup}
            onChange={onChangeNameLookup}
            onClear={onClearName}
            onClick={stopPropagation}
            notFoundContent={isLoadingLookup && <Spin />}
            filterOption={true}
            optionFilterProp='label'
            placeholder={t('physicalServersPage.server')}
            allowClear={true}
        />
    )

    const FilterByServer = (
        <AutoComplete
            placeholder={t('physicalServersPage.server')}
            value={searchServer}
            style={{ width: '160px' }}
            onClick={stopPropagation}
            onKeyDown={(e) => { if (e.key === 'Enter') { e.stopPropagation() } }}
            allowClear={true}
            onChange={v => {
                handleFilterChange('server', v)
            }}
        />
    )

    const FilterByIdent = (
        <AutoComplete
            showSearch
            placeholder={t('physicalServersPage.ident')}
            style={{ width: '60px', marginRight: '4px' }}
            value={searchIdentLookup}
            options={identOptions}
            dropdownMatchSelectWidth={200}
            onInputKeyDown={(e) => {
                if (e.key === 'Enter') {
                    onSelectIdent(e.currentTarget.value)
                }
            }}
            onSelect={onSelectIdent}
            onSearch={onChangeIdentLookup}
            onChange={onChangeIdentLookup}
            onClear={onClearIdent}
            onClick={stopPropagation}
            notFoundContent={isLoadingLookup && <Spin />}
            filterOption={true}
            optionFilterProp='label'
            allowClear={true}
        />
    )

    const columns: ColumnsType<PhysicalServerModel> = [
        {
            title: FilterByIdent,
            dataIndex: 'ident',
            key: 'ident',
            width: 100,
            fixed: 'left',
            showSorterTooltip: false,
            render: (text: number, record: PhysicalServerModel) => record.ident
        },
        {
            title: FilterByName,
            dataIndex: 'conf',
            key: 'conf',
            width: '35%',
            fixed: 'left',
            ellipsis: true,
            showSorterTooltip: false,
            render: (text: string, record: PhysicalServerModel) => record.conf,
        },
        {
            title: FilterByInventory,
            dataIndex: 'inventory_id',
            key: 'inventory_id',
            fixed: 'left',
            width: 140,
            showSorterTooltip: false,
            render: (text: string, rec: PhysicalServerModel) => {
                if (!rec.inventory) {
                    return (<span>&nbsp;</span>)
                }
                return (<Link style={{ textDecoration: 'underline' }} to={`/inventory?id=${rec.inventory.inv_no}`}>{rec.inventory.inv_no}</Link>)
            }
        },
        {
            title: t('physicalServersPage.server'),
            dataIndex: 'type_id',
            key: 'type_id',
            width: 140,
            render: (text: string, record: PhysicalServerModel) => (<span></span>), // log.server proklik
        },
        {
            title: FilterByLocation,
            dataIndex: 'location',
            key: 'location',
            width: 170,
            showSorterTooltip: false,
        },
        {
            title: FilterByIpmi,
            dataIndex: 'text_ipmi',
            key: 'text_ipmi',
            width: 110,
            ellipsis: true,
            showSorterTooltip: false,
        },
        {
            title: t('physicalServersPage.u_size'),
            dataIndex: 'u_size',
            key: 'u_size',
            width: 70,
            showSorterTooltip: false,
            render: (text: string, rec: PhysicalServerModel) => {
                if (rec.inventory && rec.inventory.parameters) {
                    const u_size = getJsonParam(rec.inventory.parameters, 'u_size')
                    if (u_size) {
                        return u_size
                    }
                }
                return ''
            }
        },
        {
            title: 'Action',
            key: 'action',
            align: 'center',
            dataIndex: 'action',
            fixed: 'right',
            width: 130,
            render: (text: string, record: PhysicalServerModel) => (
                <Space size={1}>
                    {
                        isEditor && (
                            <Button title={t('physicalServersPage.components')}
                                type='text' size='small'
                                icon={<ProfileTwoTone twoToneColor='blue' />}
                                className='actionButton'
                                onClick={() => {
                                    if (record.inventory) {
                                        // components
                                        setDataToUpdate(record)
                                        setModalComponentsVisible(true)
                                        dispatch(getPhysicalServer(record.id, 'inventory,server_components,text_ipmi,rack,text_location'))	// load fresh record
                                    }
                                    else {
                                        setDataToUpdate(record)
                                        setSelectedId(record.id)
                                        setModalNoInventoryVisible(true)
                                        dispatch(getPhysicalServer(record.id, 'server_components,text_ipmi,rack,text_location'))	// load fresh record
                                    }
                                }} />
                        )
                    }

                    {
                        isEditor && (
                            <Button title={t('physicalServersPage.edit_server')}
                                type='text' size='small'
                                onClick={() => {
                                    if (record.inventory) {
                                        // if (record.inventory.type_id === 2) {
                                        // server/case
                                        dispatch(getPhysicalServer(record.id, 'inventory,server_components,text_ipmi,rack,text_location'))	// load fresh record
                                        setDataToUpdate(record)
                                        setSelectedId(record.id)
                                        setModalServerCaseVisible(true)

                                    }
                                    else {
                                        dispatch(getPhysicalServer(record.id, 'server_components,text_ipmi,rack,text_location'))	// load fresh record
                                        setDataToUpdate(record)
                                        setModalNoInventoryVisible(true)
                                    }

                                }}
                                className='actionButton'
                                icon={<EditTwoTone twoToneColor='green' />}
                            />
                        )
                    }

                    <Button title={t('general.btnHistory')}
                        size='small'
                        style={{ marginRight: '4px' }}
                        onClick={() => {
                            setHistoryModelId(record.id)
                            record.conf && setHistoryTitle(record.conf)
                            setHistoryModalVisible(true)
                        }}
                        icon={<InfoCircleTwoTone />}
                        className='actionButton'
                    />
                </Space>
            ),
        }
    ]

    if (!appSetting || Object.keys(appSetting).length === 0) {
        return (<Spin />)
    }

    return (
        <>
            <Card className='PhysicalServersPage'
                title={<><GroupOutlined /> &nbsp; {t('physicalServersPage.title')}</>}
                extra={
                    <Button type='primary' disabled={!isCreator}
                        onClick={() => {
                            setDataToUpdate(undefined)
                            setModalCreateVisible(true)
                        }}
                    ><PlusCircleOutlined /> {t('physicalServersPage.new_item')} </Button>
                }
            >
                <Table<PhysicalServerModel>
                    className='PhysicalServersTable'
                    showHeader={true}
                    columns={columns}
                    scroll={{ x: 680 }}
                    dataSource={dataSource}
                    rowKey='id'
                    size='small'
                    bordered={true}
                    loading={isLoading}
                    pagination={false}
                    footer={() => Pager({
                        filename: 'fn',
                        total: pager.totalCount,
                        current: pager.page,
                        pageSize: pager.pageSize,
                        data: dataSource,
                        fetchRecords: fetchRecords
                    })}
                    onChange={(ev) => {
                        ev.pageSize && setPageSize(ev.pageSize)
                    }}
                />
            </Card>

            <Modal title={
                <div style={{ width: '100%', cursor: 'move' }}
                    onMouseOver={() => { if (disabled) { setDisabled(false) } }}
                    onMouseOut={() => { setDisabled(true) }}
                    onFocus={() => { }}
                    onBlur={() => { }}
                >
                    <><GroupOutlined /> &nbsp; {dataToUpdate ? t('physicalServersPage.update-physicalserver') : t('physicalServersPage.create-physicalserver')}</>
                </div>
            }
                destroyOnClose
                style={{ top: 20 }}
                width={680}
                visible={isModalServerCaseVisible}
                onCancel={() => setModalServerCaseVisible(false)}
                modalRender={(modal) => (
                    <Draggable disabled={disabled} bounds={bounds} onStart={(ev, data) => onStart(ev, data)}>
                        <div ref={draggleRef}>{modal}</div>
                    </Draggable>
                )}
                footer={null}
                confirmLoading={true}
            >
                <ServerCaseForm id={selectedId} setModalVisible={setModalServerCaseVisible} />
            </Modal>

            <Modal title={
                <div style={{ width: '100%', cursor: 'move' }}
                    onMouseOver={() => { if (disabled) { setDisabled(false) } }}
                    onMouseOut={() => { setDisabled(true) }}
                    onFocus={() => { }}
                    onBlur={() => { }}
                >
                    <><GroupOutlined /> &nbsp; {t('physicalServersPage.update-physicalserver')}: <Tag color='processing'>{physicalServer && physicalServer.ident}</Tag></>
                </div>
            }
                destroyOnClose
                style={{ top: 20, minWidth: '960px' }}
                width={960}
                visible={isModalComponentsVisible}
                onCancel={() => setModalComponentsVisible(false)}
                modalRender={(modal) => (
                    <Draggable disabled={disabled} bounds={bounds} onStart={(ev, data) => onStart(ev, data)}>
                        <div ref={draggleRef}>{modal}</div>
                    </Draggable>
                )}
                footer={null}
                confirmLoading={true}
            >
                <ComponentsForm dataToUpdate={dataToUpdate} setModalVisible={setModalComponentsVisible} />
            </Modal>

            <Modal title={
                <div style={{ width: '100%', cursor: 'move' }}
                    onMouseOver={() => { if (disabled) { setDisabled(false) } }}
                    onMouseOut={() => { setDisabled(true) }}
                    onFocus={() => { }}
                    onBlur={() => { }}
                >
                    <><GroupOutlined /> &nbsp; {dataToUpdate ? t('physicalServersPage.update-physicalserver') : t('physicalServersPage.create-physicalserver')}</>
                </div>
            }
                destroyOnClose
                style={{ top: 20 }}
                width={680}
                visible={isModalNoInventoryVisible}
                onCancel={() => setModalNoInventoryVisible(false)}
                modalRender={(modal) => (
                    <Draggable disabled={disabled} bounds={bounds} onStart={(ev, data) => onStart(ev, data)}>
                        <div ref={draggleRef}>{modal}</div>
                    </Draggable>
                )}
                footer={null}
                confirmLoading={true}
            >
                <NoInventoryForm dataToUpdate={dataToUpdate} setModalVisible={setModalNoInventoryVisible} />
            </Modal>

            <Modal title={
                <div style={{ width: '100%', cursor: 'move' }}
                    onMouseOver={() => { if (disabled) { setDisabled(false) } }}
                    onMouseOut={() => { setDisabled(true) }}
                    onFocus={() => { }}
                    onBlur={() => { }}
                >
                    <><GroupOutlined /> &nbsp; {t('physicalServersPage.create-physicalserver')}</>
                </div>
            }
                destroyOnClose
                style={{ top: 20 }}
                width={680}
                visible={isModalCreateVisible}
                onCancel={() => setModalCreateVisible(false)}
                modalRender={(modal) => (
                    <Draggable disabled={disabled} bounds={bounds} onStart={(ev, data) => onStart(ev, data)}>
                        <div ref={draggleRef}>{modal}</div>
                    </Draggable>
                )}
                footer={null}
                confirmLoading={true}
            >
                <CreateServerForm setModalVisible={setModalCreateVisible} />
            </Modal>

            <HistoryModal service='inventory' model='PhysicalServer'
                modelId={historyModelId}
                title={historyTitle}
                isModalVisible={isHistoryModalVisible}
                setModalVisible={() => setHistoryModalVisible(false)}
                modalRender={(modal) => (
                    <Draggable bounds={bounds} onStart={(ev, data) => onStart(ev, data)}>
                        <div ref={draggleRef}>{modal}</div>
                    </Draggable>
                )}
            />
        </>
    )
}

export default PhysicalServersPage
