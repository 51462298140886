import { GetPhpVersionRequestAction, GetPhpVersionResponseAction, GetPhpVersionErrorAction } from './index'
import { protectedApiClient } from 'helpers/api'
import { handleApiError } from 'helpers/errorHandling'
import { PhpVersionResp } from '../models'

export default (id: number, cb?: (isSuccess: boolean) => void) => {
	return (
		dispatch: (arg: GetPhpVersionRequestAction | GetPhpVersionResponseAction | GetPhpVersionErrorAction) => void
	) => {
		const request = new GetPhpVersionRequestAction()
		dispatch(request)

		protectedApiClient
			.get<PhpVersionResp>(`/web/service/${id}/php-version`)
			.then((response) => {
				dispatch(new GetPhpVersionResponseAction(request, response.data))
				cb && cb(true)
			})
			.catch((error) => {
				let msg = error.toString()
				if (parseInt(error.response.status) === 502) {
					msg = 'ERROR HTTP 502'
				}
				dispatch(new GetPhpVersionErrorAction(request, msg))
				handleApiError(error)
				cb && cb(false)
			})
	}
}
