import {
    CreateDbServiceRequestAction,
    CreateDbServiceResponseAction,
    CreateDbServiceErrorAction,
} from '.'
import { protectedApiClient } from 'helpers/api'
import { handleApiErrorWithNotification } from 'helpers/errorHandling'
import { CreateDbServiceParams, DbServiceModel } from '../models'

export default (params: CreateDbServiceParams, cb?: (isSuccess: boolean, id?: number) => void) => {
    return (
        dispatch: (
            arg:
                | CreateDbServiceRequestAction
                | CreateDbServiceResponseAction
                | CreateDbServiceErrorAction,
        ) => void,
    ) => {
        const request = new CreateDbServiceRequestAction(params)
        dispatch(request)
        protectedApiClient
            .post<DbServiceModel>(`/server/server/${params.server_id}/rdb`, params)
            .then((response) => {
                dispatch(new CreateDbServiceResponseAction(request, response.data))
                cb && cb(true, response.data.id)
            })
            .catch((error) => {
                dispatch(new CreateDbServiceErrorAction(request, error))
                handleApiErrorWithNotification(error)
                cb && cb(false)
            })
    }
}
