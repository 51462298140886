import React, {useEffect, useState} from 'react'
import {Card, Col, Row, Space, Table, Tag} from 'antd'
import {FunnelPlotOutlined} from '@ant-design/icons'
import {protectedApiClient} from 'helpers/api'
import axios from 'axios'
import {useSelector} from "react-redux";
import {AppState} from "../../common/models";
import LoggedUser, {ROLE_USER_ZCOM} from "../../common/LoggedUser";
import {useLoggedUser} from "../../helpers/loginUserHelper";
import {Link} from "react-router-dom";


interface ServiceState {
	name: string //'service-billing',
	version: string //'3.0.0.1',
	status: string //'OK',
	migration: string //'failed'|'m200523_100749'
}

const StatusPage: React.FC = () => {

	const {authenticated} = useSelector((state: AppState) => state.auth)
	const [services, setServices] = useState<ServiceState[]>()

	// get settings and logged user from store
	let loggedUser: LoggedUser | undefined = undefined

	if (authenticated) {
		try {
			loggedUser = useLoggedUser()
		} catch (error) {
			console.error('Error getting logged user', error)
		}
	}

	const loadStatus = () => {
		const CancelToken = axios.CancelToken
		const source = CancelToken.source()
		protectedApiClient
			.get<ServiceState[]>(`/ping`, {cancelToken: source.token})
			.then((response) => setServices(response.data))
			.catch((error) => {
				if (axios.isCancel(error)) {
					// console.log( "cancelled", error )
				} else {
					throw error
				}
			})
		return () => source.cancel()
	}


	useEffect(() => {
		loadStatus()
	}, [])


	const columns = [
		{
			title: 'Service',
			dataIndex: 'name',
			key: 'name',
			className: 'left',
			width: '100px',
			render: (_, record: ServiceState) => (<b>{record.name}</b>)
		},
		{
			title: 'Version',
			dataIndex: 'version',
			key: 'version',
			className: 'right',
			width: '50px'
		},
		{
			title: 'Status',
			dataIndex: 'status',
			key: 'status',
			className: 'center',
			width: '50px',
			render: (_, record: ServiceState) => (
				<Space size='small'>
					{
						record.status === 'OK' && (<Tag color="success">{record.status}</Tag>)
					}
					{
						record.status === 'FAILED' && (<Tag color="error">{record.status}</Tag>)
					}
				</Space>
			)
		},
		{
			title: 'Migration',
			dataIndex: 'migration',
			key: 'migration',
			className: 'left',
			width: '100px'
		},
		{
			title: 'Log',
			dataIndex: 'log',
			key: 'log',
			className: 'left',
			width: '50px',
			render: (_, rec: ServiceState) => (
				<Space size='small'>
					{
						authenticated && loggedUser && loggedUser.user.is_zcom && loggedUser.hasRole(ROLE_USER_ZCOM) && (
							<>
								<Link to={`/logviewer?service_name[]=${rec.name}`}>Logs</Link>
							</>
						)
					}
				</Space>
			),
		},
	]

	return (
		<>
			<Card
				  title={
					  <Row>
						  <Col span={6}>
							  <FunnelPlotOutlined />Status
						  </Col>
						  <Col span={18}></Col>
					  </Row>
				  }
			>
				{
					<Table<ServiceState>
						rowClassName={() => 'highlight'}
						style={{width: '800px', margin: '25px 20% 100px 20%'}}
						bordered={true}
						columns={columns}
						dataSource={services}
						rowKey='name'
						pagination={false}
					/>
				}
			</Card>
		</>
	)

}

export default StatusPage
