import { GetMailServiceRequestAction, GetMailServiceResponseAction, GetMailServiceErrorAction } from '.'
import { protectedApiClient } from 'helpers/api'
import { handleApiErrorWithNotification } from 'helpers/errorHandling'
import { MailServiceModel } from '../models'

export default (id: number, cb?: (isSuccess: boolean) => void) => {
	return (
		dispatch: (arg: GetMailServiceRequestAction | GetMailServiceResponseAction | GetMailServiceErrorAction) => void
	) => {
		const request = new GetMailServiceRequestAction(id)
		dispatch(request)

		protectedApiClient
			.get<MailServiceModel>(`/mail/service/${id}?expand=server,customer`)
			.then((response) => {
				dispatch(new GetMailServiceResponseAction(request, response.data))
				cb && cb(true)
			})
			.catch((error) => {
				let msg = error.toString()
				if (parseInt(error.response.status) === 502) {
					msg = 'ERROR HTTP 502'
				}
				dispatch(new GetMailServiceErrorAction(request, msg))
				handleApiErrorWithNotification(error)
				cb && cb(false)
			})
	}
}
