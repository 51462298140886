import React, {ReactNode, useEffect, useState} from 'react'
import {Form, Input, Select, Spin, Button, message} from 'antd'
import {useForm} from 'antd/lib/form/Form'
import {formItemLayout, tailLayout} from 'helpers/layoutHelpers'
import {useDispatch, useSelector} from 'react-redux'
import {AppState} from 'common/models'
import {useTranslation} from 'react-i18next'
import {Store} from "antd/lib/form/interface";
import {UpdateIpParams} from "../../ip/subnet/models";
import updateComment from "../../server/actions/updateComment";
import resolveJob from "./actions/resolveJob";
import restartJob from "./actions/restartJob";


interface Props {
	id?: number
	onClose: () => void
}

const AsyncJobForm = (props: Props) => {
	const {t} = useTranslation()
	const [form] = useForm()
	const dispatch = useDispatch()
	const {job, isLoading, isSaving, server} = useSelector((state: AppState) => state.asyncjob)

	const [updateStatus, setUpdateStatus] = useState<boolean>(false)
	const [stateOptions, setStateOptions] = useState<{ label: string | ReactNode, value: string }[]>([])

	const states = [
		{id: 'error', name: 'ERROR'},
		{id: 'waiting', name: 'WAITING'},
		{id: 'error_resolved', name: 'ERROR_RESOLVED'}
	]

	useEffect(() => {
		// once
		setStateOptions(states.map(item => ({label: item.name, value: item.id})))
	}, [])

	const handleSubmit = (values: Store): void => {
		if (!job) {
			message.error('Job unknown.')
			return
		}

		if (values.state_id === 'error_resolved') {
			dispatch(resolveJob(job.id, suc => {
				suc && message.success(t('general.success'))
				props.onClose()
			}))
		}
		if (values.state_id === 'waiting') {
			dispatch(restartJob(job.id, suc => {
				suc && message.success(t('general.success'))
				props.onClose()
			}))
		}
	}

	if (isLoading) {
		return (<Spin/>)
	}

	if (!job || job.id != props.id) {
		return (<Spin/>)
	}

	return (
		<Form
			name='create-job'
			className='JobForm'
			onFinish={handleSubmit}
			{...formItemLayout}
			form={form}
			initialValues={{...job, state_id: 'error'}}
			autoComplete='off'>

			<Form.Item name='id' label='ID'>
				<Input disabled/>
			</Form.Item>

			<Form.Item name='name' label={t('asyncJobPage.name')}>
				<Input disabled/>
			</Form.Item>

			<Form.Item name='server_id' label={t('asyncJobPage.server_id')}>
				<Input value={job.server?.name} disabled />
			</Form.Item>

			<Form.Item name='state_id' label={t('asyncJobPage.state')}>
				<Select optionFilterProp='label'
						options={stateOptions}
				onChange={(v) => setUpdateStatus(true)}/>
			</Form.Item>

			<Form.Item {...tailLayout}>
				<Button type='primary' htmlType='submit'
						loading={isSaving}
						disabled={!updateStatus}
						className='create-button'>
					{t('general.save')}
				</Button>
			</Form.Item>
		</Form>
	)
}

export default AsyncJobForm
