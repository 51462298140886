import {GetWebServicesRequestAction, GetWebServicesResponseAction, GetWebServicesErrorAction} from './index'
import {protectedApiClient} from 'helpers/api'
import {handleApiErrorWithNotification} from 'helpers/errorHandling'
import { NameItem } from 'common/models'


export default (cb?: (isSuccess: boolean) => void) => {
    return (dispatch: (arg: GetWebServicesRequestAction | GetWebServicesResponseAction | GetWebServicesErrorAction) => void) => {
        const request = new GetWebServicesRequestAction()
        dispatch(request)

        let url = `/web/service/list`
        protectedApiClient
            .get<NameItem[]>(url)
            .then((response) => {
                dispatch(new GetWebServicesResponseAction(request, response.data))
                cb && cb(true)
            })
            .catch((error) => {
                dispatch(new GetWebServicesErrorAction(request, error))
                handleApiErrorWithNotification(error)
                cb && cb(false)
            })
    }
}
