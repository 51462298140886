import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import React, { useEffect, useRef, useState } from "react"
import { ConsoleSqlOutlined, DeleteTwoTone, ExclamationCircleOutlined } from "@ant-design/icons"
import { Alert, Button, Card, Col, Divider, message, Modal, Row, Spin } from "antd"
import { useHistory } from "react-router"
import { AppState } from "../../common/models"
import { Link, useParams } from "react-router-dom"
import getRdbService from "./actions/getRdbService"
import { useLoggedUser } from "../../helpers/loginUserHelper"
import { LoadingIndicator } from "../../components"
import ErrorPage403 from "../../components/Errors/ErrorPage403"
import useLogger from "../../common/useLogger"
import getDatabase from "./actions/getDatabase"
import getDbPassword from "./actions/getDbPassword"
import { GlobalOutlined } from "@ant-design/icons/lib/icons"
import Draggable, { DraggableData, DraggableEvent } from "react-draggable"
import DbPasswordForm from "./DbPasswordForm"
import { DatabaseModel } from "./models"
import deleteDatabase from "./actions/deleteDatabase"
import getDaemon from "./actions/getDaemon"

const { confirm } = Modal

export interface ParamTypes {
    id: string
}


const DatabaseDetailPage = () => {
    const CONTROL_NAME = 'page_rdb_database_detail'
    const { t } = useTranslation()
    const history = useHistory()
    const dispatch = useDispatch()
    const { id } = useParams<ParamTypes>()

    const { customers } = useSelector((state: AppState) => state.auth.tables)
    const { isLoadingDatabase, service, database, db_password, daemon } = useSelector((state: AppState) => state.rdbservice)

    const [isShowPassword, setShowPassword] = useState<boolean>(false)
    const [password, setPassword] = useState<string>('')
    const [isDbPasswordModalVisible, setDbPasswordModalVisible] = useState(false)
    const [openDelete, setOpenDelete] = useState(false)
    const [confirmDelete, setConfirmDelete] = useState(false)

    const [isViewer, setViewer] = useState(false)
    const [isDeleter, setDeleter] = useState(false)
    const [isEditor, setEditor] = useState(false)
    const [isDbServiceEditor, setDbServiceEditor] = useState(false)


    // get settings and current user
    const loggedUser = useLoggedUser()
    if (!loggedUser || !loggedUser.isLoaded()) {
        // waiting..
        return (
            <div className="fullwidth-loading" >
                <LoadingIndicator />
            </div>
        )
    }

    // required authorization
    if (!loggedUser.hasAccess(CONTROL_NAME)) {
        return <ErrorPage403 />
    }

    // settings
    const appSetting = loggedUser.getAppSettings()
    const SEARCH_MIN = appSetting.min_search_length

    const logger = useLogger(appSetting, 'DatabaseDetailPage')

    // history drag modal
    const [disabled, setDisabled] = useState(true)
    const draggleRef = useRef<HTMLDivElement>(null)
    const [bounds, setBounds] = useState({ left: 0, top: 0, bottom: 0, right: 0 })
    const onStart = (_event: DraggableEvent, uiData: DraggableData) => {
        const { clientWidth, clientHeight } = window.document.documentElement
        const targetRect = draggleRef.current?.getBoundingClientRect()
        if (!targetRect) {
            return
        }
        setBounds({
            left: -targetRect.left + uiData.x,
            right: clientWidth - (targetRect.right - uiData.x),
            top: -targetRect.top + uiData.y,
            bottom: clientHeight - (targetRect.bottom - uiData.y),
        })
    }

    useEffect(() => {
        logger('id: ' + id)
        const access = loggedUser.hasAccess(CONTROL_NAME)
        setViewer(access)

        if (!access) {
            // failover 403
            message.error('No permissions!')
            history.replace('/rdb/databases')
            return
        }
        setEditor(loggedUser.hasAccess('page_rdb_databases_edit_button'))
        setDeleter(loggedUser.hasAccess('page_rdb_databases_delete_button'))
        setDbServiceEditor(loggedUser.hasAccess('page_rdb_service_edit_button'))
        if (parseInt(id) > 0) {
            // loadOptions()
            const did = parseInt(id)
            dispatch(getDatabase(did))
            setPassword('')
        }
        else {
            // failover 404
            history.replace('/rdb/databases')
        }
    }, [])

    useEffect(() => {
        if (database) {
            dispatch(getRdbService(database.service_id))
            dispatch(getDaemon(database.service_id))
        }
    }, [database])

    useEffect(() => {
        if (db_password) {
            setPassword(db_password)
        }
    }, [db_password])


    const handleSetPassword = () => {
        setDbPasswordModalVisible(true)
    }

    const isDaemonReady = (): boolean => {
        return !!daemon && parseInt(daemon) > 0
    }

    const showConfirmDelete = (d: DatabaseModel, title: string) => {
        if (!d) {
            return
        }
        // if (!isDeleter) {
        //     message.error(t('general.error403'))
        //     return
        // }
        confirm({
            icon: <ExclamationCircleOutlined style={{ color: 'red' }} />,
            title: title,
            content: <p>{d.name}</p>,
            okText: t('general.yes'),
            cancelText: t('general.cancel'),
            okButtonProps: { loading: confirmDelete },
            className: 'confirm-alert',
            onOk() {
                setConfirmDelete(true)
                dispatch(deleteDatabase(d.id, suc => {
                    setConfirmDelete(false)
                    setOpenDelete(false)
                    if (suc) {
                        message.success(t('general.success'))
                        history.replace('/rdb/databases')
                    }
                }))
            },
            onCancel() {
                setOpenDelete(false)
            },
        }
        )
    }

    const handleShowPassword = () => {
        if (!isEditor || !database) {
            return
        }
        if (!isShowPassword) {
            if (!database.username) {
                message.error('Database user was not found.')
                return
            }
            !password && dispatch(getDbPassword(database.id))
        }
        setShowPassword(!isShowPassword)
    }

    const getPassword = () => {
        if (!password) {
            return <Spin />
        }
        return password
    }


    if (!appSetting || Object.keys(appSetting).length === 0) {
        return (<Spin />)
    }

    if (!database || !database.id) {
        return (<Spin />)
    }

    return (
        <>
            <Card
                title={<><ConsoleSqlOutlined /> &nbsp; {t('databasesPage.title')} &nbsp; {database.name}</>}
                className='DatabaseDetailPage'
                loading={isLoadingDatabase}
            >
                <Row style={{ marginTop: '35px' }}>
                    <Col span={6} className='right bold pad4'>{t('databasesPage.database')}:</Col>
                    <Col span={6} className='pad4'><b>{database.name}</b></Col>
                    <Col span={12}>&nbsp;</Col>
                </Row>
                <Row>
                    <Col span={6} className='right bold pad4' style={{ fontSize: '0.8em' }}>id:</Col>
                    <Col span={6} className='pad4' style={{ fontSize: '0.8em' }}>{database.id}</Col>
                    <Col span={12}>&nbsp;</Col>
                </Row>
                <Row>
                    <Col span={6} className='right bold pad4'>{t('databasesPage.rdb_service')}:</Col>
                    <Col span={6} className='pad4'>
                        {
                            isDbServiceEditor && <Link to={`/rdb/services/${service?.id}`}>{service?.host}</Link>
                        }
                        {
                            !isDbServiceEditor && <span>{service?.host}</span>
                        }
                    </Col>
                    <Col span={12}>&nbsp;</Col>
                </Row>
                <Row>
                    <Col span={6} className='right bold pad4'>{t('databasesPage.driver')}:</Col>
                    <Col span={6} className='pad4'>{service?.driver?.name}</Col>
                    <Col span={12}>&nbsp;</Col>
                </Row>

                <Row>
                    <Col span={6} className='right bold pad4'>{t('databasesPage.customer')}:</Col>
                    <Col span={6} className='pad4'>{database?.customer?.name}</Col>
                    <Col span={12}>&nbsp;</Col>
                </Row>

                <Row>
                    <Col span={6} className='right bold pad4'>{t('general.created')}:</Col>
                    <Col span={6} className='pad4'>{appSetting.renderDate(database.created_at)}</Col>
                    <Col span={12}>&nbsp;</Col>
                </Row>

                {!isDaemonReady() && (
                    <Row>
                        <Col span={24} className='pad4'>
                            <Alert className="center" message="The database is not ready. Please check the database service status." type="error" />
                        </Col>
                    </Row>
                )}

                <Divider />

                <Row>
                    <Col span={6} className='right bold pad4'>{t('databasesPage.username')}:</Col>
                    <Col span={6} className='pad4'>{database?.username}</Col>
                    <Col span={12}>&nbsp;</Col>
                </Row>

                <Row>
                    <Col span={6} className='right bold pad4'>{t('databasesPage.password')}:</Col>
                    <Col span={6} className='pad4'>
                        <span style={{ fontFamily: 'monospace', color: '#aaaaaa' }}>{isShowPassword ? getPassword() : '********'}</span>
                        <Button size='small'
                            style={{ marginLeft: '40px' }}
                            disabled={!isEditor}
                            onClick={handleShowPassword} >{isShowPassword ? t('general.hide') : t('general.show')}</Button> </Col>
                    <Col span={12}>&nbsp;</Col>
                </Row>

                <Divider />

                <Row style={{ marginBottom: '15px' }}>
                    <Col span={6} className='pad4'>
                        <Button danger
                            disabled={!isDeleter}
                            onClick={() => {
                                showConfirmDelete(database, t('databasesPage.confirm_delete_db'))
                            }}
                            icon={<DeleteTwoTone twoToneColor='red' />}>{t('databasesPage.btn_delete_db')}</Button>
                    </Col>
                    <Col span={6} className='pad4'>
                        <Button type='primary'
                            disabled={!database.username}
                            onClick={() => handleSetPassword()}>
                            {t('databasesPage.set_password')}
                        </Button>
                    </Col>
                    <Col span={12}>&nbsp;</Col>
                </Row>
            </Card>


            <Modal title={
                <div style={{ width: '100%', cursor: 'move' }}
                    onMouseOver={() => { if (disabled) { setDisabled(false) } }}
                    onMouseOut={() => { setDisabled(true) }}
                    onFocus={() => { }}
                    onBlur={() => { }}
                >
                    <GlobalOutlined /> &nbsp; {t('databasesPage.set_password')}
                </div>
            }
                destroyOnClose
                style={{ top: 20 }}
                width={600}
                visible={isDbPasswordModalVisible}
                onCancel={() => setDbPasswordModalVisible(false)}
                modalRender={(modal) => (
                    <Draggable disabled={disabled} bounds={bounds} onStart={(ev, data) => onStart(ev, data)}>
                        <div ref={draggleRef}>{modal}</div>
                    </Draggable>
                )}
                footer={null}
                confirmLoading={true}
            >
                <DbPasswordForm onClose={(pwd) => {
                    setDbPasswordModalVisible(false)
                    setPassword(pwd)
                }} />
            </Modal>
        </>
    )
}

export default DatabaseDetailPage
