import { GetServersMailRequestAction, GetServersMailResponseAction, GetServersMailErrorAction } from '.'
import { protectedApiClient } from 'helpers/api'
import { handleApiErrorWithNotification } from 'helpers/errorHandling'
import { ServerModel } from '../../server/models'

export default (prefix: string, cb?: (isSuccess: boolean) => void) => {
	return (
		dispatch: (arg: GetServersMailRequestAction | GetServersMailResponseAction | GetServersMailErrorAction) => void
	) => {
		const request = new GetServersMailRequestAction()
		dispatch(request)

		let url = `/server/server`
		if (prefix) {
			url = `/server/server?hostname=${prefix}`
		}
		protectedApiClient
			.get<ServerModel[]>(url)
			.then((response) => {
				dispatch(new GetServersMailResponseAction(request, response.data))
				cb && cb(true)
			})
			.catch((error) => {
				dispatch(new GetServersMailErrorAction(request, error))
				handleApiErrorWithNotification(error)
				cb && cb(false)
			})
	}
}
