import { useHistory } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { Button, Form, message, Spin } from "antd"
import { useForm } from "antd/es/form/Form"
import { useTranslation } from "react-i18next"
import ServerSelector from "pages/server/ServerSelector"
import { useEffect } from "react"
import { AppState } from "common/models"
import { useState } from "react"
import { useLoggedUser } from "helpers/loginUserHelper"
import { Store } from "antd/es/form/interface"
import { CreateMailServiceParams } from "./models"
import { formItemLayout, tailLayout } from "helpers/layoutHelpers"
import createMailService from "./actions/createMailService"
import tableCustomers from "pages/login/actions/tableCustomers"
import getServersMail from "./actions/getServersMail"
import TextArea from "antd/es/input/TextArea"



interface Props {
  serviceId: number | undefined
  onClose: () => void
}

const { Item } = Form


const CreateMailServiceForm = (props: Props) => {
  const [form] = useForm()
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const history = useHistory()


  const { customers } = useSelector((state: AppState) => state.auth.tables)
  const { isLoadingService, isSavingService, servers, isLoadingServers } = useSelector((state: AppState) => state.mailservice)

  const [updateStatus, setUpdateStatus] = useState<boolean>(false)
  const [selectedServerId, setSelectedServerId] = useState<number | undefined>()


  // get settings and logged user from store
  const loggedUser = useLoggedUser()
  if (!loggedUser || !loggedUser.isLoaded()) {
    return (<Spin />)
  }
  const appSetting = loggedUser.getAppSettings()

  useEffect(() => {
    // once
    if (!customers || customers.length === 0) {
      dispatch(tableCustomers())
    }
  }, [])

  const loadServerLookup = (pattern: string) => {
    if (appSetting.checkMinSearch(pattern)) {
      dispatch(getServersMail(pattern))
    }
  }

  const onFormSubmit = (values: Store) => {
    if (selectedServerId) {
      const params: CreateMailServiceParams = {
        server_id: selectedServerId,
        comment: values.comment
      }
      dispatch(createMailService(params, (suc, id) => {
        if (suc) {
          message.success(t('general.success'))
          props.onClose()
        }
      }))
    }
  }

  return (
    <Form
      onFinish={onFormSubmit}
      form={form}
      onChange={() => setUpdateStatus(true)}
      initialValues={{ customer_id: 1, is_active: true }}
      {...formItemLayout}
    >

      <Form.Item name='server_id' label={t('mailDomainsPage.server')}>
        <ServerSelector
          dataSource={servers}
          isLoading={isLoadingServers}
          handleSelectedServer={(sid) => {
            setSelectedServerId(sid); form.setFieldsValue([{ server_id: sid }])
            setUpdateStatus(true)
          }}
          fetchData={loadServerLookup}
        />
      </Form.Item>

      <Item label={t('mailDomainsPage.comment')} name='comment'>
        <TextArea rows={3}
          data-gramm="false"
          data-1p-ignore />
      </Item>

      <Item {...tailLayout}>
        <Button type='primary'
          loading={isSavingService}
          disabled={!updateStatus}
          htmlType='submit'>
          {t('general.create')}
        </Button>
      </Item>
    </Form>
  )
}

export default CreateMailServiceForm
