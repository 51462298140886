import React, { useEffect, useState } from 'react'
import { Button, Card, Col, message, Row, Tag } from 'antd'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from "react-redux"
import { AppState } from "../../common/models"
import { SetSshKeysParams } from "./models"
import TextArea from 'antd/lib/input/TextArea'
import getSshKeys from './actions/getSshKeys'
import setSshKeys from './actions/setSshKeys'


const PanelSshKeys = (props) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const { user } = useSelector((state: AppState) => state.user)

  const [userRsa, setUserRsa] = useState<string>('')
  const [userSha2, setUserSha2] = useState<string>('')
  const [updateStatus, setUpdateStatus] = useState<boolean>(false)


  useEffect(() => {
    if (user) {
      dispatch(getSshKeys(user.id))
    }
  }, [])

  useEffect(() => {
    if (user) {
      setUserRsa(user.rsa_key || '')
      setUserSha2(user.sha2_key || '')
    }
  }, [user?.rsa_key, user?.sha2_key])

  const saveSshKeys = () => {
    if (user && user.id) {
      const params: SetSshKeysParams = {
        user_id: user.id,
        rsa: userRsa,
        sha2: userSha2
      }
      dispatch(setSshKeys(params, suc => {
        if (suc) {
          message.success(t('general.success'))
        }
      }))
    }
  }

  return (
    <Card title={t('usersPage.menu_ssh_keys')} className='fblock'
      style={{ marginTop: 0 }}>

      <Row className='settingItem'>
        <Col span={4} className='labelCol'><span className='label'> {t('usersPage.user_rsa_key')} </span> </Col>
        <Col span={16} className='valueCol'>
          <TextArea rows={4}
            onChange={(el) => { setUserRsa(el.target.value); setUpdateStatus(true) }}
            value={userRsa}
            disabled={user?.is_zcom}
            data-gramm="false"
            data-gramm_editor="false"
            data-enable-grammarly="false" />
        </Col>
        <Col span={4} className='btnCol'></Col>
        <Col span={4}></Col>
        <Col span={20}>
          <span className='textGray'>{t('usersPage.user_rsa_key_desc')}</span>
        </Col>
        <Col span={4} className='labelCol'><span className='label'> {t('usersPage.user_sha2_key')} </span> </Col>
        <Col span={16} className='valueCol'>
          <TextArea rows={4}
            onChange={(el) => { setUserSha2(el.target.value); setUpdateStatus(true) }}
            value={userSha2}
            disabled={user?.is_zcom}
            data-gramm="false"
            data-gramm_editor="false"
            data-enable-grammarly="false" />
        </Col>
        <Col span={4} className='btnCol'>
          <Button size='small' type='primary' style={{ marginTop: '70%' }}
            onClick={() => saveSshKeys()}
            disabled={!updateStatus || user?.is_zcom}>{t('general.save')}</Button>
        </Col>
        <Col span={4}></Col>
        <Col span={20}>
          <span className='textGray'>{t('usersPage.user_sha2_key_desc')}</span>
        </Col>
      </Row>

    </Card>
  )
}

export default PanelSshKeys
