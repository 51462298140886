import {
    CreateDatabaseRequestAction,
    CreateDatabaseResponseAction,
    CreateDatabaseErrorAction,
} from '.'
import { protectedApiClient } from 'helpers/api'
import { handleApiErrorWithNotification } from 'helpers/errorHandling'
import {CreateDatabaseParams, DatabaseModel, DbServiceModel} from '../models'

export default (params: CreateDatabaseParams, cb?: (isSuccess: boolean, id?: number) => void) => {
    return (
        dispatch: (
            arg:
                | CreateDatabaseRequestAction
                | CreateDatabaseResponseAction
                | CreateDatabaseErrorAction,
        ) => void,
    ) => {
        const request = new CreateDatabaseRequestAction(params)
        dispatch(request)
        protectedApiClient
            .post<DatabaseModel>(`/rdb/service/${params.service_id}/database?expand=service`, params)
            .then((response) => {
                dispatch(new CreateDatabaseResponseAction(request, response.data))
                cb && cb(true, response.data.id)
            })
            .catch((error) => {
                let msg = ''
                if (error) {
                    if ( error.message ){
                        msg = error.message
                    }
                    else {
                        msg = error.toString()
                    }
                }
                dispatch(new CreateDatabaseErrorAction(request, msg))
                handleApiErrorWithNotification(error)
                cb && cb(false)
            })
    }
}
