import { useTranslation } from "react-i18next"
import { useHistory } from "react-router"
import { useDispatch, useSelector } from "react-redux"
import { AppState, NameItem, ValueItem } from "../../common/models"
import React, { useEffect, useRef, useState } from "react"
import { useLoggedUser } from "../../helpers/loginUserHelper"
import { LoadingIndicator } from "../../components"
import ErrorPage403 from "../../components/Errors/ErrorPage403"
import useLogger from "../../common/useLogger"
import Draggable, { DraggableData, DraggableEvent } from "react-draggable"
import { removeDiac, shortStr, stopPropagation } from "../../common/fce"
import { ColumnsType } from "antd/lib/table"
import { AutoComplete, Card, message, Modal, Space, Select, Table, Tag, Tooltip, Empty } from "antd"
import Button from "antd-button-color"
import { AuditOutlined, InfoCircleTwoTone, MessageTwoTone, RadarChartOutlined, SyncOutlined } from "@ant-design/icons"
import { GlobalOutlined, PlusCircleOutlined } from "@ant-design/icons/lib/icons"
import usePageSize from "../../common/usePageSize"
import { Link } from "react-router-dom"

import "./WebServices.scss"
import HistoryModal from "../../components/History/HistoryModal"
import { WebTemplateModel } from "./models"
import getWebTemplates from "./actions/getWebTemplates"
import TotalNum from "../../components/TotalNum/TotalNum"
import { sort_id, sort_searchable } from "../../common/sorting"
import Highlighter from "react-highlight-words"
import CreateWebTemplateForm from "./CreateWebTemplateForm"
import getPhpVersions from "./actions/getPhpVersions"
import getWebServerVersions from "./actions/getWebServerVersions"

const { confirm } = Modal

const renderApacheVersion = (t: WebTemplateModel) => {
    return (
        <>
            {
                t.apache_version?.map(v => <div style={{ color: 'white' }} key={v.id}>{v.name}</div>)
            }
        </>
    )
}
const renderPhpVersion = (t: WebTemplateModel) => {
    return (
        <>
            {
                t.php_version?.map(v => <div style={{ color: 'white' }} key={v.id}>{v.name}</div>)
            }
        </>
    )
}

const TemplatePage = () => {
    const CONTROL_NAME = 'page_web_templates'
    const { t } = useTranslation()
    const history = useHistory()
    const dispatch = useDispatch()

    const { isLoadingTemplate, templates, php_versions, web_server_versions } = useSelector((state: AppState) => state.webservice)
    const { web_services } = useSelector((state: AppState) => state.sidenav)

    const [phpVersions, setPhpVersions] = useState<NameItem[]>([])
    const [webServerVersions, setWebServerVersions] = useState<NameItem[]>([])

    const [dataSource, setDataSource] = useState<WebTemplateModel[]>([])
    const [sourceFull, setSourceFull] = useState<WebTemplateModel[]>([])

    const [searchName, setSearchName] = useState<string>('')
    const [selectedPhpId, setSelectedPhpId] = useState<number | undefined>()
    const [selectedWebServerId, setSelectedWebServerId] = useState<number | undefined>()

    const [nameOptions, setNameOptions] = useState<{ label: string, value: string }[]>([])
    const [hostOptions, setHostOptions] = useState<{ label: string, value: string }[]>([])
    const [webServerOptions, setWebServerOptions] = useState<{ label: string, value: number }[]>([])
    const [phpOptions, setPhpOptions] = useState<{ label: string, value: number }[]>([])



    const [pageNumber, setPageNumber] = useState<number>(1)
    const [qsFilter, setQsFilter] = useState<string>('')
    const [loaded, setLoaded] = useState(0)         // when records are loaded
    const [ready, setReady] = useState<number>(0)   // when customers are loaded

    const [isWebTemplateModalVisible, setWebTemplateModalVisible] = useState<boolean>(false)

    const [isHistoryModalVisible, setHistoryModalVisible] = useState(false)
    const [historyTitle, setHistoryTitle] = useState('')
    const [historyModelId, setHistoryModelId] = useState<number | undefined>()

    const [isWebTemplateViewer, setWebTemplateViewer] = useState(false)
    const [isWebTemplateEditor, setWebTemplateEditor] = useState(false)
    const [isWebTemplateCreator, setWebTemplateCreator] = useState(false)
    const [isWebTemplateDeletor, setWebTemplateDeletor] = useState(false)


    // get settings and current user
    const loggedUser = useLoggedUser()
    if (!loggedUser || !loggedUser.isLoaded()) {
        // waiting..
        return (
            <div className="fullwidth-loading" >
                <LoadingIndicator />
            </div>
        )
    }

    // required authorization
    if (!loggedUser.hasAccess(CONTROL_NAME)) {
        return <ErrorPage403 />
    }

    // settings
    const appSetting = loggedUser.getAppSettings()
    const SEARCH_MIN = appSetting.min_search_length

    // usage: logger(msg, obj=null)
    const logger = useLogger(appSetting, 'TemplatesPage')
    const [pageSize, setPageSize] = useState<number>(appSetting.grid_page_size)
    usePageSize(appSetting, loggedUser.user.id, pageSize)

    // history drag modal
    const [disabled, setDisabled] = useState(true)
    const draggleRef = useRef<HTMLDivElement>(null)
    const [bounds, setBounds] = useState({ left: 0, top: 0, bottom: 0, right: 0 })
    const onStart = (_event: DraggableEvent, uiData: DraggableData) => {
        const { clientWidth, clientHeight } = window.document.documentElement
        const targetRect = draggleRef.current?.getBoundingClientRect()
        if (!targetRect) {
            return
        }
        setBounds({
            left: -targetRect.left + uiData.x,
            right: clientWidth - (targetRect.right - uiData.x),
            top: -targetRect.top + uiData.y,
            bottom: clientHeight - (targetRect.bottom - uiData.y),
        })
    }

    useEffect(() => {
        // trigger ONCE
        const access = loggedUser.hasAccess(CONTROL_NAME)
        setWebTemplateViewer(access)
        setWebTemplateCreator(loggedUser.hasAccess('page_web_template_create_button'))
        setWebTemplateEditor(loggedUser.hasAccess('page_web_template_edit_button'))
        setWebTemplateDeletor(loggedUser.hasAccess('page_web_template_delete_button'))
        dispatch(getWebTemplates())

    }, [])

    useEffect(() => {
        // trigger from Side menu clicked
        logger('web_templates---')
        if (!isLoadingTemplate && isWebTemplateViewer) {
            // reload
            fetchRecords()
        }
    }, [web_services])

    useEffect(() => {
        if (webServerVersions) {
            setWebServerOptions(
                webServerVersions.map(item => ({
                    value: item.id,
                    label: item.name
                }))
            )
        }
    }, [webServerVersions])

    useEffect(() => {
        if (phpVersions) {
            setPhpOptions(
                phpVersions.map(item => ({
                    value: item.id,
                    label: item.name
                }))
            )
        }
    }, [phpVersions])

    const addItemIfNotExists = (list: NameItem[], newItem: NameItem) => {
        const exists = list.some(item => item.id === newItem.id)
        if (!exists) {
            list.push(newItem)
        }
    }

    const getPhpLabel = (id: number) => {
        if (phpVersions) {
            const item = phpVersions.find(v => v.id === id)
            if (item) {
                return `Php ${item.name}`
            }
        }
        return ''
    }

    const getApacheLabel = (id: number) => {
        if (webServerVersions) {
            const item = webServerVersions.find(v => v.id === id)
            if (item) {
                return item.name
            }
        }
        return ''
    }

    useEffect(() => {
        if (templates) {
            setSourceFull(templates)
            let phpList: NameItem[] = []
            let apacheList: NameItem[] = []
            templates.forEach((t, idx) => {
                t.php_version?.forEach((v, i) => {
                    addItemIfNotExists(phpList, v)
                })
                t.apache_version?.forEach((v, i) => {
                    addItemIfNotExists(apacheList, v)
                })
            })
            logger(phpList)
            logger(apacheList)
            setPhpVersions(phpList)
            setWebServerVersions(apacheList)
        }
    }, [templates])

    useEffect(() => {
        // Filters
        refreshGrid()
    }, [sourceFull, searchName, selectedWebServerId, selectedPhpId])

    const filtered = () => {
        // search for pattern
        if (!sourceFull) {
            return []
        }
        let qs: string[] = []
        let data = sourceFull
        data = data.filter(t => t.deleted_at === 0)

        if (searchName && searchName.length > SEARCH_MIN) {
            data = data.filter((t) => t.name.toLowerCase().includes(searchName.toLowerCase()))
            qs.push('name=' + searchName)
        }
        if (selectedPhpId && selectedPhpId > 0) {
            data = data.filter((t) => t.php_version?.some(item => item.id === selectedPhpId))
            qs.push('php_version=' + selectedPhpId)
        }
        if (selectedWebServerId && selectedWebServerId > 0) {
            data = data.filter((t) => t.apache_version?.some(item => item.id === selectedWebServerId))
            qs.push('apache_version=' + selectedWebServerId)
        }
        history.replace('/web/templates?' + qs.join("&"))
        logger(`filtered sourceFull: ${sourceFull.length}, dataSource: ${data.length}`)
        return data.sort(sort_id)
    }

    const refreshGrid = () => {
        setDataSource(filtered())
    }

    const fetchRecords = () => {
        if (!isLoadingTemplate) {
            dispatch(getWebTemplates(suc => { }))
            setLoaded(1)
        }
    }



    const getQSFilter = (): string[] => {
        let qs: string[] = []
        return qs
    }

    const prepareQsFilter = (): string => {
        // load filtered data from server
        let qs: string[]
        qs = getQSFilter()
        if (searchName && appSetting.checkMinSearch(searchName)) {
            qs.push('name=' + encodeURIComponent(searchName))
        }
        logger('prepareQsFilter: ' + qs.join("&"))
        return (qs.length === 0) ? '' : qs.join("&")
    }

    const handleCreate = () => {
        setWebTemplateModalVisible(true)
    }

    const fetchNameLookup = (searchText: string) => {
        // call lookup for name
        searchText = searchText.trim()
        if (appSetting.checkMinSearch(searchText.trim())) {
            let qs: string[]
            qs = getQSFilter()
            if (appSetting.checkMinSearch(searchText)) {
                qs.push('field=name')
                qs.push('value=' + encodeURIComponent(searchText.trim()))
                logger('lookupDatabase: ' + qs.join("&"))
                //dispatch(lookupDatabase('name', qs.join("&")))
            }
        }
    }

    const onClearName = () => {
        setSearchName('')
        setNameOptions([])
    }

    const onSelectName = (data: string) => {
        setSearchName(data)
    }

    const onChangeNameLookup = (data: string) => {
        setSearchName(data)
    }

    const fetchHostLookup = (searchText: string) => {
        // call lookup for db host
        searchText = searchText.trim()
        if (appSetting.checkMinSearch(searchText.trim())) {
            let qs: string[]
            qs = getQSFilter()
            if (appSetting.checkMinSearch(searchText)) {
                qs.push('field=host')
                qs.push('value=' + encodeURIComponent(searchText.trim()))
                logger('fetchHostLookup: ' + qs.join("&"))
                //dispatch(lookupRdbService('host', qs.join("&")))
            }
        }
    }

    const FilterByName = (
        <AutoComplete
            showSearch
            placeholder={t('webPage.templates.name')}
            style={{ width: '200px' }}
            value={searchName}
            options={nameOptions}
            dropdownMatchSelectWidth={200}
            onInputKeyDown={(e) => {
                if (e.key === 'Enter') {
                    onSelectName(e.currentTarget.value)
                }
            }}
            onSelect={onSelectName}
            onChange={onChangeNameLookup}
            onClear={onClearName}
            onClick={stopPropagation}
            //notFoundContent={isLoadingLookup && <Spin />}
            optionFilterProp='label'
            filterOption={false}
            allowClear={true}
        />
    )

    const FilterByPhp = (
        <Select placeholder='Php version'
            size='middle'
            style={{ width: 120 }}
            showSearch
            optionFilterProp='label'
            filterOption={(val, opt) => {
                return !!opt && removeDiac(opt.label).includes(removeDiac(val))
            }}
            options={phpOptions}
            onChange={v => setSelectedPhpId(v)}
            value={selectedPhpId}
            allowClear={true}
        />
    )

    const FilterByWebServer = (
        <Select placeholder='WebServer version'
            size='middle'
            style={{ width: 160 }}
            showSearch
            optionFilterProp='label'
            filterOption={(val, opt) => {
                return !!opt && removeDiac(opt.label).includes(removeDiac(val))
            }}
            options={webServerOptions}
            onChange={v => setSelectedWebServerId(v)}
            value={selectedWebServerId}
            allowClear={true}
        />
    )

    const columns: ColumnsType<WebTemplateModel> = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
            width: 40,
            align: 'left',
            fixed: 'left'
        },
        {
            title: FilterByName,
            dataIndex: 'name',
            key: 'name',
            width: '50%',
            align: 'left',
            fixed: 'left',
            render: (name: string, rec: WebTemplateModel) => (
                <Link to={`/web/templates/${rec.id}`}>
                    <Highlighter
                        highlightStyle={{
                            backgroundColor: '#ffc069',
                            color: '#2d9259',
                            padding: 0,
                        }}
                        searchWords={[searchName]}
                        autoEscape
                        textToHighlight={name.toString()}
                    />
                </Link>
            ),
        },
        {
            title: t('webPage.templates.comment'),
            dataIndex: 'comment',
            key: 'comment',
            width: 40,
            ellipsis: true,
            render: comment => (
                comment &&
                <Tooltip placement="topLeft" title={comment}>
                    <MessageTwoTone />
                </Tooltip>
            ),
        },
        {
            title: FilterByWebServer,
            dataIndex: 'apache_version',
            key: 'apache_version',
            width: 100,
            render: (val: number, rec: WebTemplateModel) =>
                selectedWebServerId ? <Tooltip placement="topLeft" title={renderApacheVersion(rec)}>{getApacheLabel(selectedWebServerId)}</Tooltip> : <Tooltip placement="topLeft" title={renderApacheVersion(rec)}>{rec.apache_version?.length} Server Versions</Tooltip>
        },
        {
            title: FilterByPhp,
            dataIndex: 'php_version',
            key: 'php_version',
            width: 100,
            render: (val: number, rec: WebTemplateModel) =>
                selectedPhpId ? <Tooltip placement="topLeft" title={renderPhpVersion(rec)}>{getPhpLabel(selectedPhpId)}</Tooltip> : <Tooltip placement="topLeft" title={renderPhpVersion(rec)}>{rec.php_version?.length} Php Versions</Tooltip>
        },
        {
            title: (<div className='center'>Action</div>),
            key: 'action',
            dataIndex: 'action',
            width: 60,
            align: 'center',
            render: (text: string, record: WebTemplateModel) => (
                <Space size={1} onClick={(e) => e.stopPropagation()}>
                    <Button title={t('general.btnHistory')} size='small'
                        onClick={() => {
                            setHistoryModelId(record.id)
                            setHistoryTitle(record.name)
                            setHistoryModalVisible(true)
                        }}
                        icon={<InfoCircleTwoTone />}
                        className='actionButton'
                    />
                </Space>
            ),
        }
    ]

    if (isLoadingTemplate) {
        return (
            <Card title={<><AuditOutlined /> &nbsp;{t('webPage.templates.title')}</>}
                className='WebTemplatePage'>
                <Empty style={{ margin: '25px' }} description='Wait a minute.' >
                    Loading matrix..<SyncOutlined spin /><br />
                </Empty>
            </Card>
        )
    }

    return (
        <>
            <Card title={<><AuditOutlined /> &nbsp;{t('webPage.templates.title')}</>}
                loading={isLoadingTemplate}
                className='WebTemplatePage'
                extra={
                    <Space>
                        <Button type='primary'
                            disabled={!isWebTemplateCreator}
                            onClick={() => setWebTemplateModalVisible(true)}>
                            <PlusCircleOutlined /> {t('webPage.templates.new_template')}
                        </Button>
                    </Space>

                }
            >
                <Table<WebTemplateModel>
                    columns={columns}
                    dataSource={dataSource}
                    rowKey='id'
                    bordered={true}
                    className='TemplateTable'
                    loading={isLoadingTemplate}
                    showHeader={true}
                    size='small'
                    pagination={false}
                    footer={() => TotalNum(Number(dataSource?.length), 'Templates', dataSource)}
                />

            </Card>


            <Modal title={
                <div style={{ width: '100%', cursor: 'move' }}
                    onMouseOver={() => { if (disabled) { setDisabled(false) } }}
                    onMouseOut={() => { setDisabled(true) }}
                    onFocus={() => { }}
                    onBlur={() => { }}
                >
                    <GlobalOutlined /> &nbsp; {t('webPage.templates.new_template')}
                </div>
            }
                destroyOnClose
                style={{ top: 20 }}
                width={600}
                visible={isWebTemplateModalVisible}
                onCancel={() => setWebTemplateModalVisible(false)}
                modalRender={(modal) => (
                    <Draggable disabled={disabled} bounds={bounds} onStart={(ev, data) => onStart(ev, data)}>
                        <div ref={draggleRef}>{modal}</div>
                    </Draggable>
                )}
                footer={null}
                confirmLoading={true}
            >
                <CreateWebTemplateForm onClose={() => { setWebTemplateModalVisible(false) }} />
            </Modal>

            <HistoryModal service='web' model='ServiceVhost'
                modelId={historyModelId}
                title={historyTitle}
                isModalVisible={isHistoryModalVisible}
                setModalVisible={() => setHistoryModalVisible(false)}
                modalRender={(modal) => (
                    <Draggable bounds={bounds} onStart={(ev, data) => onStart(ev, data)}>
                        <div ref={draggleRef}>{modal}</div>
                    </Draggable>
                )}
            />
        </>
    )
}

export default TemplatePage
