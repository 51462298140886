import {
	GetDocsSectionContentRequestAction,
	GetDocsSectionContentResponseAction,
	GetDocsSectionContentErrorAction,
} from '.'
import { protectedApiClient } from 'helpers/api'
import { handleApiErrorWithNotification } from 'helpers/errorHandling'
import { SectionContent, SectionItem } from '../models'

export default (sectionKey: string, cb?: (isSuccess: boolean) => void) => {
	return (
		dispatch: (
			args: GetDocsSectionContentRequestAction | GetDocsSectionContentResponseAction | GetDocsSectionContentErrorAction
		) => void
	) => {
		const request = new GetDocsSectionContentRequestAction(sectionKey)
		dispatch(request)

		protectedApiClient
			.get<SectionContent>(`/zdoc/sub-section?sub_id=${sectionKey}`)
			.then((response) => {
				dispatch(new GetDocsSectionContentResponseAction(request, response.data))
				cb && cb(true)
			})
			.catch((error) => {
				dispatch(new GetDocsSectionContentErrorAction(request, error))
				handleApiErrorWithNotification(error)
				cb && cb(false)
			})
	}
}
