import { Alert, AutoComplete, Button, Divider, Form, Input, InputNumber, message, Modal, Select, Space, Switch, Table } from "antd"
import { Spin } from "antd"
import { Row } from "antd"
import { Col } from "antd"
import { Card } from "antd"
import { useTranslation } from "react-i18next"
import { Link, useHistory } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { useParams } from "react-router-dom"
import ErrorPage403 from "components/Errors/ErrorPage403"
import { LoadingIndicator } from "components"
import { useLoggedUser } from "helpers/loginUserHelper"
import useLogger from "common/useLogger"
import { AppState } from "common/models"
import { CheckOutlined, CloseOutlined, DeleteTwoTone, EditTwoTone, LockTwoTone, MailOutlined, PlusCircleOutlined } from "@ant-design/icons"
import { useEffect, useRef, useState } from "react"
import Draggable, { DraggableEvent, DraggableData } from "react-draggable"
import getMailDomain from "./actions/getMailDomain"
import getMailService from "./actions/getMailService"
import getMailboxes from "./actions/getMailboxes"
import { CreateMailboxParams, CreateTrashParams, MailAliasModel, MailboxModel, MailDomainModel, UpdateMailDomainParams } from "./models"
import { ColumnsType } from "antd/es/table"
import { removeDiac, stopPropagation } from "common/fce"
import CreateMailboxForm from "./CreateMailbox"
import React from "react"
import getDaemonVersion from "./actions/getDaemonVersion"
import deleteMailbox from "./actions/deleteMailbox"
import getMailAliases from "./actions/getMailAliases"
import CreateAliasForm from "./CreateAlias"
import Highlighter from "react-highlight-words"
import SetMailPasswordForm from "./SetMailPassword"
import getMailbox from "./actions/getMailbox"
import UpdateMailboxForm from "./UpdateMailboxForm"
import { useForm } from "antd/es/form/Form"
import { formItemLayout } from "helpers/layoutHelpers"
import { Store } from "antd/es/form/interface"
import updateMailDomain from "./actions/updateMailDomain"
import HistoryLog from "components/History/HistoryLog"
import { confirmDeleteBox } from "common/utils"
import deleteMailAlias from "./actions/deleteMailAlias"
import TotalNum from "components/TotalNum/TotalNum"
import deleteMailDomain from "./actions/deleteMailDomain"
import { sort_str } from "common/sorting"
import deleteTrash from "./actions/deleteTrash"
import createTrash from "./actions/createTrash"


interface ParamTypes {
  id: string
}


const MailDomainDetailPage = () => {
  const CONTROL_NAME = 'page_mail_domain_detail'
  const { t } = useTranslation()
  const history = useHistory()
  const dispatch = useDispatch()
  const { id } = useParams<ParamTypes>()
  const [form] = useForm()

  const { isLoadingDomain, mail_service, mail_domain, mail_boxes, isLoadingMailbox,
    mail_aliases, isLoadingMailAliases, daemon_version } = useSelector((state: AppState) => state.mailservice)

  const [dataSource, setDataSource] = useState<MailboxModel[]>([])
  const [dataSourceAliases, setDataSourceAliases] = useState<MailAliasModel[]>([])
  const [searchName, setSearchName] = useState<string>('')
  const [searchAlias, setSearchAlias] = useState<string>('')
  const [quota, setQuota] = useState<number>(0)
  const [trash, setTrash] = useState<string>('')
  const [selectedTrash, setSelectedTrash] = useState<string>(t('general.not_set'))
  const [mbOptions, setMbOptions] = useState<{ label: string, value: string, key: string }[]>([])
  const [searchDestination, setSearchDestination] = useState<string>('')
  const [historyModelId, setHistoryModelId] = useState<number | undefined>()
  const [historyTitle, setHistoryTitle] = useState<string>('')
  const [historyModalVisible, setHistoryModalVisible] = useState<boolean>(false)
  const [isMailboxModalVisible, setMailboxModalVisible] = useState(false)
  const [isAliasModalVisible, setAliasModalVisible] = useState(false)
  const [isMailboxPasswordModalVisible, setMailboxPasswordModalVisible] = useState(false)
  const [isMailboxUpdateModalVisible, setMailboxUpdateModalVisible] = useState(false)
  const [isDomainUseQuota, setDomainUseQuota] = useState(false)
  const [updateStatus, setUpdateStatus] = useState(false)
  const [updateTrash, setUpdateTrash] = useState(false)
  const [forceUpdateId, setForceUpdateId] = useState<number>(1)
  const [openDelete, setOpenDelete] = useState(false)
  const [confirmDelete, setConfirmDelete] = useState(false)

  const [isViewer, setViewer] = useState(false)
  const [isDeleter, setDeleter] = useState(false)
  const [isEditor, setEditor] = useState(false)
  const [isMailboxEditor, setMailboxEditor] = useState(false)
  const [isMailAdmin, setMailAdmin] = useState(false)

  // get settings and current user
  const loggedUser = useLoggedUser()
  if (!loggedUser || !loggedUser.isLoaded()) {
    // waiting..
    return (
      <div className="fullwidth-loading" >
        <LoadingIndicator />
      </div>
    )
  }

  // required authorization
  if (!loggedUser.hasAccess(CONTROL_NAME)) {
    return <ErrorPage403 />
  }

  // settings
  const appSetting = loggedUser.getAppSettings()
  const SEARCH_MIN = appSetting.min_search_length

  const logger = useLogger(appSetting, 'MailDomainDetailPage')

  // history drag modal
  const [disabled, setDisabled] = useState(true)
  const draggleRef = useRef<HTMLDivElement>(null)
  const [bounds, setBounds] = useState({ left: 0, top: 0, bottom: 0, right: 0 })
  const onStart = (_event: DraggableEvent, uiData: DraggableData) => {
    const { clientWidth, clientHeight } = window.document.documentElement
    const targetRect = draggleRef.current?.getBoundingClientRect()
    if (!targetRect) {
      return
    }
    setBounds({
      left: -targetRect.left + uiData.x,
      right: clientWidth - (targetRect.right - uiData.x),
      top: -targetRect.top + uiData.y,
      bottom: clientHeight - (targetRect.bottom - uiData.y),
    })
  }

  useEffect(() => {
    logger('id: ' + id)
    const access = loggedUser.hasAccess(CONTROL_NAME)
    setViewer(access)

    if (!access) {
      // failover 403
      message.error('No permissions!')
      history.replace('/mail/domains')
      return
    }
    setMailAdmin(loggedUser.hasRole('mail-admin'))
    setEditor(loggedUser.hasAccess('page_mail_domains_edit_button'))
    setDeleter(loggedUser.hasAccess('page_mail_domains_delete_button'))
    if (parseInt(id) > 0) {
      // loadOptions()
      const did = parseInt(id)
      setHistoryModelId(did)
      dispatch(getMailDomain(did))

    }
    else {
      // failover 404
      history.replace('/mail/domains')
    }
  }, [])

  useEffect(() => {
    if (mail_domain) {
      dispatch(getMailService(mail_domain.service_id))
      dispatch(getDaemonVersion(mail_domain.service_id))
      setDomainUseQuota(mail_domain.use_quota === 1)
      loadHistory()
    }
  }, [mail_domain])

  useEffect(() => {
    if (mail_domain && daemon_version) {
      dispatch(getMailboxes(mail_domain.id))
    }
  }, [daemon_version])


  useEffect(() => {
    renderMailboxes()
    mail_domain && dispatch(getMailAliases(mail_domain.id))
    if (mail_boxes && mail_boxes.length > 0) {
      setQuota(mail_boxes.reduce((acc, mb) => acc + (mb.quota || 0), 0))
    }
  }, [mail_boxes, searchName])


  useEffect(() => {
    refreshGridAliases()
    if (mail_aliases.length > 0) {
      setTrash(mail_aliases.find(ma => ma.type === 'TRASH')?.destination || '')
    }
    if (mail_boxes.length > 0) {
      setMbOptions(mail_boxes.map(mb => ({ label: mb.email || '', value: mb.email || '', key: mb.email || '' })))
    }
  }, [mail_aliases, searchAlias, searchDestination])

  const renderMailboxes = () => {
    if (searchName && appSetting.checkMinSearch(searchName)) {
      setDataSource(mail_boxes.filter(mb => removeDiac(mb.email).includes(removeDiac(searchName))))
    }
    else {
      setDataSource(mail_boxes.sort((a, b) => sort_str(a.email, b.email)))
    }
  }

  const refreshGridAliases = () => {

    if ((searchAlias && appSetting.checkMinSearch(searchAlias)) || (searchDestination && appSetting.checkMinSearch(searchDestination))) {
      setDataSourceAliases(mail_aliases.filter(ma => ma.type != 'TRASH').filter(ma => removeDiac(ma.alias).includes(removeDiac(searchAlias)) && removeDiac(ma.destination).includes(removeDiac(searchDestination))))
    }
    else {
      setDataSourceAliases(mail_aliases.filter(ma => ma.type != 'TRASH').sort((a, b) => sort_str(a.alias, b.alias)))
    }
  }

  const isDaemonReady = (): boolean => {
    return !!daemon_version && parseInt(daemon_version) > 0
  }

  const loadHistory = () => {
    setForceUpdateId(forceUpdateId + 1)	// update history
  }

  const handleDeleteDomain = (rec: MailDomainModel) => {
    if (rec) {
      setConfirmDelete(true)
      dispatch(deleteMailDomain(rec.id, suc => {
        setConfirmDelete(false)
        setOpenDelete(false)
        if (suc) {
          message.success(t('general.success'))
          history.replace('/mail/domains')
        }
      }))
    }
    else {
      logger(JSON.stringify(rec))
      message.error(t('general.error'))
    }
  }

  const handleDeleteAlias = (rec: MailAliasModel) => {
    if (rec && mail_domain) {
      setConfirmDelete(true)
      dispatch(deleteMailAlias(mail_domain.id, rec.alias, rec.destination, suc => {
        setConfirmDelete(false)
        setOpenDelete(false)
        dispatch(getMailAliases(mail_domain.id))
        loadHistory()
        if (suc) {
          message.success(t('general.success'))
        }
      }))
    }
    else {
      logger(JSON.stringify(rec))
      logger(JSON.stringify(mail_domain))
      message.error(t('general.error'))
    }
  }

  const handleDeleteMailbox = (rec: MailboxModel) => {
    if (rec && rec.mailbox && mail_domain) {
      setConfirmDelete(true)
      dispatch(deleteMailbox(mail_domain.id, rec.mailbox, suc => {
        setConfirmDelete(false)
        setOpenDelete(false)
        dispatch(getMailboxes(mail_domain.id))
        loadHistory()
        if (suc) {
          message.success(t('general.success'))
        }
      }))
    }
    else {
      logger(JSON.stringify(rec))
      logger(JSON.stringify(mail_domain))
      message.error(t('general.error'))
    }
  }

  const handleDeleteTrash = () => {
    if (mail_domain) {
      setConfirmDelete(true)
      dispatch(deleteTrash(mail_domain.id, suc => {
        setConfirmDelete(false)
        setOpenDelete(false)
        dispatch(getMailboxes(mail_domain.id))
        dispatch(getMailAliases(mail_domain.id))
        setSelectedTrash(t('general.not_set'))
        loadHistory()
        if (suc) {
          message.success(t('general.success'))
        }
      }))
    }
    else {
      logger(JSON.stringify(mail_domain))
      message.error(t('general.error'))
    }
  }

  const onDomainFormSubmit = (values: Store) => {
    if (!mail_domain) {
      message.error('Mail domain is not found')
      return
    }

    const params: UpdateMailDomainParams = {
      id: mail_domain.id,
      use_quota: isDomainUseQuota ? 1 : 0,
      quota: values.quota,
    }
    dispatch(updateMailDomain(params, (suc) => {
      if (suc) {
        message.success(t('general.success'))
        setUpdateStatus(false)
        loadHistory()
      }
    }))
  }

  const FilterByName = (
    <AutoComplete
      placeholder='Email'
      style={{ width: '150px' }}
      value={searchName}
      allowClear={true}
      onClick={stopPropagation}
      onKeyDown={(e) => { if (e.key === 'Enter') { e.stopPropagation() } }}
      onChange={(v) => {
        setSearchName(v)
      }}
    />
  )

  const FilterByAlias = (
    <AutoComplete
      placeholder='Alias'
      style={{ width: '150px' }}
      value={searchAlias}
      allowClear={true}
      onClick={stopPropagation}
      onKeyDown={(e) => { if (e.key === 'Enter') { e.stopPropagation() } }}
      onChange={(v) => {
        setSearchAlias(v)
      }}
    />
  )

  const FilterByDestination = (
    <AutoComplete
      placeholder={t('mailDomainsPage.destination')}
      style={{ width: '150px' }}
      value={searchDestination}
      allowClear={true}
      onClick={stopPropagation}
      onKeyDown={(e) => { if (e.key === 'Enter') { e.stopPropagation() } }}
      onChange={(v) => {
        setSearchDestination(v)
      }}
    />
  )

  const columnsAliases: ColumnsType<MailAliasModel> = [
    {
      title: FilterByAlias,
      dataIndex: 'alias',
      key: 'alias',
      width: '40%',
      align: 'left',
      fixed: 'left',
      render: (text: string, rec: MailAliasModel) => (
        <Highlighter
          key={rec.alias}
          highlightStyle={{
            backgroundColor: '#ffc069',
            color: '#2d9259',
            padding: 0,
          }}
          searchWords={[searchAlias]}
          autoEscape
          textToHighlight={text && text.toString() + '@' + mail_domain?.name}
        />
      ),
    },
    {
      title: FilterByDestination,
      dataIndex: 'destination',
      key: 'destination',
      width: '30%',
      align: 'left',
      render: (text: string, rec: MailAliasModel) => (
        <Highlighter
          highlightStyle={{
            backgroundColor: '#ffc069',
            color: '#2d9259',
            padding: 0,
          }}
          searchWords={[searchDestination]}
          autoEscape
          textToHighlight={text && text.toString()}
        />
      ),
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
      width: '20%',
      align: 'left',
      render: (text: string) => text.toUpperCase(),
    },
    {
      title: (<div className='center'>Action</div>),
      key: 'action',
      dataIndex: 'action',
      width: 100,
      align: 'center',
      render: (text: string, rec: MailAliasModel) => (
        <Space size={1} onClick={(e) => e.stopPropagation()}>

          <Button type='text' danger size='small'
            className='actionButton'
            disabled={!isEditor}
            onClick={() => {
              if (rec && mail_domain) {
                confirmDeleteBox(
                  t('mailDomainsPage.confirm_delete_alias'),
                  rec.alias,
                  confirmDelete,
                  () => handleDeleteAlias(rec),
                  () => { setOpenDelete(false) },
                  t
                )
              }
            }}
            icon={<DeleteTwoTone twoToneColor='red' />} />
        </Space>
      ),
    }
  ]

  const columns: ColumnsType<MailboxModel> = [
    {
      title: FilterByName,
      dataIndex: 'email',
      key: 'email',
      width: '50%',
      align: 'left',
      fixed: 'left',
      render: (text: string, rec: MailboxModel) => (
        <>
          {rec.email === trash ? <DeleteTwoTone twoToneColor='gray' /> : ''}&nbsp;
          <Highlighter
            highlightStyle={{
              backgroundColor: '#ffc069',
              color: '#2d9259',
              padding: 0,
            }}
            searchWords={[searchName]}
            autoEscape
            textToHighlight={text.toString()}
          />
        </>
      ),
    },
    {
      title: t('mailDomainsPage.quota_usage'),
      dataIndex: 'quota_usage',
      key: 'quota_usage',
      width: '20%',
      align: 'left',
    },
    {
      title: t('mailDomainsPage.quota'),
      dataIndex: 'quota',
      key: 'quota',
      width: '20%',
      align: 'left',
    },
    {
      title: (<div className='center'>Action</div>),
      key: 'action',
      dataIndex: 'action',
      width: 100,
      align: 'center',
      render: (text: string, rec: MailboxModel) => (
        <Space size={1} onClick={(e) => e.stopPropagation()}>
          <Button type='text' size='small'
            disabled={!isEditor}
            onClick={() => {
              dispatch(getMailbox(rec.mail_domain_id, rec.mailbox!))
              setMailboxPasswordModalVisible(true)
            }}
            className='actionButton'
            icon={<LockTwoTone twoToneColor={isEditor ? "" : "#ccc"} />}
          />

          <Button type='text' size='small'
            disabled={!isEditor}
            onClick={() => {
              dispatch(getMailbox(rec.mail_domain_id, rec.mailbox!))
              setMailboxUpdateModalVisible(true)
            }}
            className='actionButton'
            icon={<EditTwoTone twoToneColor={isEditor ? "green" : "#ccc"} />}
          />

          <Button type='text' danger size='small'
            className='actionButton'
            disabled={!isEditor}
            onClick={() => {
              if (rec && rec.email && mail_domain) {
                confirmDeleteBox(
                  t('mailDomainsPage.confirm_delete_mailbox'),
                  rec.email,
                  confirmDelete,
                  () => handleDeleteMailbox(rec),
                  () => { setOpenDelete(false) },
                  t
                )
              }
            }}
            icon={<DeleteTwoTone twoToneColor='red' />} />

        </Space>
      ),
    }
  ]

  if (!appSetting || Object.keys(appSetting).length === 0) {
    return (<Spin />)
  }

  if (!mail_domain || !mail_domain.id || !mail_service || !mail_service.id) {
    return (<Spin />)
  }


  return (
    <>
      <Card
        title={<><MailOutlined /> &nbsp; {t('mailDomainsPage.domain')} &nbsp; <b>{mail_domain.name}</b></>}
        className='MailDomainDetailPage'
        loading={isLoadingDomain}
      >
        <Row style={{ marginTop: '35px' }}>
          <Col span={6} className='right bold pad4'>{t('mailDomainsPage.domain')}:</Col>
          <Col span={6} className='pad4'><span style={{ fontSize: '1.2em', fontWeight: 'bold' }}>{mail_domain.name}</span></Col>
          <Col span={12}>&nbsp;</Col>
        </Row>
        <Row>
          <Col span={6} className='right bold pad4'>{t('mailDomainsPage.mail_service')}:</Col>
          <Col span={6} className='pad4'>
            {
              (loggedUser.user.is_zcom && isMailAdmin) ?
                <Link to={`/mail/services/${mail_service?.id}`}>{mail_service?.host}</Link>
                :
                mail_service?.host
            }
          </Col>
          <Col span={12}>&nbsp;</Col>
        </Row>
        <Row>
          <Col span={6} className='right bold pad4'>{t('mailDomainsPage.customer')}:</Col>
          <Col span={6} className='pad4'>{mail_domain.customer?.name}</Col>
          <Col span={12}>&nbsp;</Col>
        </Row>

        <Row>
          <Col span={6} className='right bold pad4'>{t('general.created')}:</Col>
          <Col span={6} className='pad4'>{appSetting.renderDate(mail_domain.created_at)}</Col>
          <Col span={12}>&nbsp;</Col>
        </Row>

        <Row>
          <Col span={6} className='right bold pad4'>{t('mailDomainsPage.quota_usage')}:</Col>
          <Col span={6} className='pad4'>{quota} MB</Col>
          <Col span={12}>&nbsp;</Col>
        </Row>


        <Form
          onFinish={onDomainFormSubmit}
          form={form}
          onChange={() => setUpdateStatus(true)}
          initialValues={mail_domain}
          {...formItemLayout}
        >
          <Row>
            <Col span={6} className='right bold pad4'>{t('mailDomainsPage.use_quota')}:</Col>
            <Col span={6} className='pad4'>
              <Switch
                checkedChildren={<CheckOutlined />}
                unCheckedChildren={<CloseOutlined />}
                checked={isDomainUseQuota}
                size="small"
                onChange={() => {
                  setDomainUseQuota(!isDomainUseQuota)
                  setUpdateStatus(true)
                }}
              />
            </Col>
            <Col span={12}>&nbsp;</Col>
          </Row>

          <Row>
            <Col span={6} className='right bold pad4' style={{ marginTop: '5px' }}>{t('mailDomainsPage.quota_total')}:</Col>
            <Col span={6} className='pad4'>
              <Form.Item name='quota' style={{ marginBottom: 0 }}>
                <InputNumber size="small"
                  onChange={() => setUpdateStatus(true)}
                  min={0} max={1000000000} step={1}
                  addonAfter='MB' />
              </Form.Item>
              <Button type='primary' size="small" htmlType="submit"
                disabled={!mail_service || !isDaemonReady() || !isEditor || !updateStatus} >
                {t('general.save')}
              </Button>
            </Col>
            <Col span={12}>&nbsp;</Col>
          </Row>
        </Form>

        {isDaemonReady() && (
          <>
            <Row style={{ marginTop: '35px', padding: '10px' }}>
              <Col span={12}>&nbsp;<b>{t('mailDomainsPage.mailboxes')}</b></Col>
              <Col span={12} className="right">
                <Button type='primary' size="small"
                  disabled={!mail_service || !isDaemonReady() || !isEditor}
                  onClick={() => setMailboxModalVisible(true)}>
                  <PlusCircleOutlined /> {t('mailDomainsPage.new_mailbox')}
                </Button>
              </Col>
              <Col span={24} style={{ marginTop: '5px' }}>
                <Table<MailboxModel>
                  dataSource={dataSource}
                  columns={columns}
                  rowKey='email'
                  bordered={true}
                  style={{ width: '100%' }}
                  loading={isLoadingMailbox}
                  pagination={false}
                  footer={() => TotalNum(Number(dataSource?.length), 'Mailbox', dataSource)}
                />
              </Col>
            </Row>

            <Row style={{ marginTop: '35px', padding: '10px' }}>
              <Col span={12}>&nbsp;<b>{t('mailDomainsPage.aliases')}</b></Col>
              <Col span={12} className="right">
                <Button type='primary' size="small"
                  disabled={!mail_service || !isDaemonReady() || !isEditor}
                  onClick={() => setAliasModalVisible(true)}>
                  <PlusCircleOutlined /> {t('mailDomainsPage.new_alias')}
                </Button>
              </Col>
              <Col span={24} style={{ marginTop: '5px' }}>
                <Table<MailAliasModel>
                  dataSource={dataSourceAliases}
                  columns={columnsAliases}
                  rowKey='alias'
                  bordered={true}
                  style={{ width: '100%' }}
                  loading={isLoadingMailAliases}
                  pagination={false}
                  footer={() => TotalNum(Number(dataSourceAliases?.length), 'Alias', dataSourceAliases)}
                />
              </Col>
            </Row>

            <Divider />

            <Row>
              <Col span={2} className='pad8 right'>
                <DeleteTwoTone twoToneColor='gray' />&nbsp;<b>{t('mailDomainsPage.trash')}</b>:
              </Col>
              <Col span={8} className='pad8 bold'>
                {trash}
                {
                  trash && (
                    <Button type='text' danger size='small'
                      className='actionButton'
                      disabled={!isEditor}
                      onClick={() => {
                        if (mail_domain && mail_domain.id) {
                          confirmDeleteBox(
                            t('mailDomainsPage.confirm_delete_trash'),
                            trash,
                            confirmDelete,
                            () => handleDeleteTrash(),
                            () => { setOpenDelete(false) },
                            t
                          )
                        }
                      }}
                      icon={<DeleteTwoTone twoToneColor='red' />} />
                  )
                }
              </Col>
              <Col span={14} className='pad8'>
                <Space size={10}>

                  <Select
                    filterOption={(val, opt) => {
                      return !!opt && removeDiac(opt.label).includes(removeDiac(val))
                    }}
                    optionFilterProp='label'
                    options={mbOptions}
                    value={selectedTrash}
                    onChange={(val) => {
                      if (val) {
                        setSelectedTrash(val)
                        setUpdateTrash(true)
                      } else {
                        setSelectedTrash(t('general.not_set'))
                        setUpdateTrash(false)
                      }
                    }}
                    onClear={() => {
                      setSelectedTrash(t('general.not_set'))
                      setUpdateTrash(false)
                    }}
                    placeholder={t('general.not_set')}
                    style={{ width: '200px' }}
                  >
                  </Select>
                  
                  {
                    trash != selectedTrash && (
                      <Button type='primary' size='small'
                        disabled={selectedTrash === t('general.not_set') || !isEditor || !updateTrash}
                        onClick={() => {
                          if (mail_domain && mail_domain.id) {
                            const parts = selectedTrash.split("@")
                            const params: CreateTrashParams = {
                              mail_domain_id: mail_domain.id,
                              alias: parts[0],
                            }
                            setTrash('')
                            setUpdateTrash(false)
                            dispatch(createTrash(params, suc => {
                              if (suc) {
                                message.success(t('general.success'))
                                dispatch(getMailboxes(mail_domain.id))
                                dispatch(getMailAliases(mail_domain.id))
                                loadHistory()
                              }
                            }))
                          }
                        }}
                      >{t('general.save')}</Button>
                    )
                  }
                </Space>

              </Col>
            </Row>

            <Row>
              <Col span={24} className='pad4'>
                <Divider />
              </Col>
              <Col span={24} style={{ padding: '15px' }}>
                <HistoryLog service='mail'
                  model='ServiceDomain'
                  modelId={historyModelId}
                  forceUpdateId={forceUpdateId}
                  isModal={false}
                  showTitle={true} />
              </Col>
            </Row>
          </>
        )}

        {!isDaemonReady() && (
          <Row>
            <Col span={24} className='pad4'>
              <Divider />
              <Alert className="center" message="The mail service is not ready. Please check the mail service status." type="error" />
            </Col>
          </Row>
        )}

        <Row>
          <Col span={24} className='pad8'>
            <Divider />
            <Button danger size='small'
              disabled={!isDeleter}
              onClick={() => {
                if (mail_service && mail_domain) {
                  if (!isDaemonReady()) {
                    message.error(t('mailDomainsPage.daemon_not_ready'))
                    return
                  }
                  if (mail_boxes.length > 0) {
                    message.error(t('mailDomainsPage.need_to_delete_mailboxes'))
                    return
                  }
                  confirmDeleteBox(
                    t('mailDomainsPage.confirm_delete_mail'),
                    mail_domain.name,
                    confirmDelete,
                    () => handleDeleteDomain(mail_domain),
                    () => { setOpenDelete(false) },
                    t
                  )
                }
              }}
              style={{ float: 'right' }}
              icon={<DeleteTwoTone twoToneColor='red' />}>{t('mailDomainsPage.btn_delete_domain')}</Button>

          </Col>
        </Row>
      </Card>


      <Modal title={
        <div style={{ width: '100%', cursor: 'move' }}
          onMouseOver={() => { if (disabled) { setDisabled(false) } }}
          onMouseOut={() => { setDisabled(true) }}
          onFocus={() => { }}
          onBlur={() => { }}
        >
          <MailOutlined /> &nbsp; {t('mailDomainsPage.new_mailbox')}
        </div>
      }
        destroyOnClose
        style={{ top: 20 }}
        width={600}
        visible={isMailboxModalVisible}
        onCancel={() => setMailboxModalVisible(false)}
        modalRender={(modal) => (
          <Draggable disabled={disabled} bounds={bounds} onStart={(ev, data) => onStart(ev, data)}>
            <div ref={draggleRef}>{modal}</div>
          </Draggable>
        )}
        footer={null}
        confirmLoading={true}
      >
        <CreateMailboxForm onClose={() => { setMailboxModalVisible(false); dispatch(getMailboxes(mail_domain.id)); loadHistory() }} />
      </Modal>

      <Modal title={
        <div style={{ width: '100%', cursor: 'move' }}
          onMouseOver={() => { if (disabled) { setDisabled(false) } }}
          onMouseOut={() => { setDisabled(true) }}
          onFocus={() => { }}
          onBlur={() => { }}
        >
          <MailOutlined /> &nbsp; {t('mailDomainsPage.new_alias')}
        </div>
      }
        destroyOnClose
        style={{ top: 20 }}
        width={600}
        visible={isAliasModalVisible}
        onCancel={() => setAliasModalVisible(false)}
        modalRender={(modal) => (
          <Draggable disabled={disabled} bounds={bounds} onStart={(ev, data) => onStart(ev, data)}>
            <div ref={draggleRef}>{modal}</div>
          </Draggable>
        )}
        footer={null}
        confirmLoading={true}
      >
        <CreateAliasForm onClose={(b) => { setAliasModalVisible(false); b && dispatch(getMailAliases(mail_domain.id)); b && loadHistory() }} />
      </Modal>


      <Modal title={
        <div style={{ width: '100%', cursor: 'move' }}
          onMouseOver={() => { if (disabled) { setDisabled(false) } }}
          onMouseOut={() => { setDisabled(true) }}
          onFocus={() => { }}
          onBlur={() => { }}
        >
          <MailOutlined /> &nbsp; {t('mailDomainsPage.set_password')}
        </div>
      }
        destroyOnClose
        style={{ top: 20 }}
        width={600}
        visible={isMailboxPasswordModalVisible}
        onCancel={() => setMailboxPasswordModalVisible(false)}
        modalRender={(modal) => (
          <Draggable disabled={disabled} bounds={bounds} onStart={(ev, data) => onStart(ev, data)}>
            <div ref={draggleRef}>{modal}</div>
          </Draggable>
        )}
        footer={null}
        confirmLoading={true}
      >
        <SetMailPasswordForm onClose={() => { setMailboxPasswordModalVisible(false); loadHistory() }} />
      </Modal>


      <Modal title={
        <div style={{ width: '100%', cursor: 'move' }}
          onMouseOver={() => { if (disabled) { setDisabled(false) } }}
          onMouseOut={() => { setDisabled(true) }}
          onFocus={() => { }}
          onBlur={() => { }}
        >
          <MailOutlined /> &nbsp; {t('mailDomainsPage.edit_mailbox')}
        </div>
      }
        destroyOnClose
        style={{ top: 20 }}
        width={600}
        visible={isMailboxUpdateModalVisible}
        onCancel={() => setMailboxUpdateModalVisible(false)}
        modalRender={(modal) => (
          <Draggable disabled={disabled} bounds={bounds} onStart={(ev, data) => onStart(ev, data)}>
            <div ref={draggleRef}>{modal}</div>
          </Draggable>
        )}
        footer={null}
        confirmLoading={true}
      >
        <UpdateMailboxForm onClose={() => { setMailboxUpdateModalVisible(false); loadHistory() }} />
      </Modal>
    </>
  )
}

export default MailDomainDetailPage
