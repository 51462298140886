import {
    GetPhysServerRequestAction,
    GetPhysServerResponseAction,
    GetPhysServerErrorAction,
} from '.'
import { protectedApiClient } from 'helpers/api'
import { handleApiErrorWithNotification } from 'helpers/errorHandling'
import {PhysicalServerModel} from "../../inventory/physicalserver/models";

export default (id: number, cb?: (isSuccess: boolean) => void) => {
    return (
        dispatch: (
            arg:
                | GetPhysServerRequestAction
                | GetPhysServerResponseAction
                | GetPhysServerErrorAction,
        ) => void,
    ) => {
        const request = new GetPhysServerRequestAction(id)
        dispatch(request)

        protectedApiClient
            .get<PhysicalServerModel>(`/inventory/physical-server/${id}?expand=inventory,rack,text_ipmi,text_location`)
            .then((response) => {
                dispatch(new GetPhysServerResponseAction(request, response.data))
                cb && cb(true)
            })
            .catch((error) => {
                dispatch(new GetPhysServerErrorAction(request, error))
                handleApiErrorWithNotification(error)
                cb && cb(false)
            })
    }
}
