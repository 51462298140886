import React, {ChangeEvent, useEffect, useState} from 'react'
import {Button, Form, Select, Input, Modal, message, Spin, Alert, Col, Row, Divider} from 'antd'
import {formItemLayout, tailLayout} from 'helpers/layoutHelpers'
import {CustomerDetails} from 'pages/billing/customer/models'
import {useForm} from 'antd/lib/form/Form'
import {AppState} from 'common/models'
import {useDispatch, useSelector} from 'react-redux'
import {useTranslation} from 'react-i18next'
import {CheckCircleOutlined, DeleteTwoTone, ExclamationCircleOutlined, RollbackOutlined} from "@ant-design/icons";
import {_validateEmail, getInvoiceCurrency, removeDiac, ValidateParams} from "../../../common/fce";
import {CompanyDetails} from "../../company/models";
import updateCustomer from "./actions/updateCustomer";
import createCustomer from "./actions/createCustomer";
import {useLoggedUser} from "../../../helpers/loginUserHelper";
import getCurrencies from "../../../components/SelectCurrencies/actions/getCurrencies";
import {sort_name} from "../../../common/sorting";
import TextArea from "antd/lib/input/TextArea";
import Zalert from "../../../components/Messages/Zalert";
import {euPhoneNumberRegex} from "../../../helpers/stringHelpers";
import setTerminated from "./actions/setTerminated";
import getCustomers from "./actions/getCustomers";
import getCompanyCustomer from "./actions/getCompanyCustomer";
import tableCustomers from "../../login/actions/tableCustomers";


interface Props {
    setModalVisible: () => void
    isCreate?: boolean
}

const validateInvoicingEmail = (emailStr: string, errMessage: string): ValidateParams => {
    if (!emailStr) {
        return {
            value: emailStr,
            validateStatus: 'error',
            errorMsg: errMessage
        }
    }
    emailStr = emailStr.toLowerCase()
    const emails = emailStr.trim().split(',')
    let result = true
    const successEmails: string[] = []
    for (const email of emails) {
        if (email === '') {
            continue
        }
        result = _validateEmail(email)
        if (!result) break
        successEmails.push(email)
    }
    if (!result) {
        return {
            value: emailStr,
            validateStatus: 'error',
            errorMsg: errMessage
        }
    } else {
        return {
            value: successEmails.join(','),
            validateStatus: 'success',
            errorMsg: ''
        }
    }
}

const {Item} = Form
const { confirm } = Modal

const CustomerForm = (props: Props) => {
    let [form] = useForm()
    const {t} = useTranslation()
    const dispatch = useDispatch()

    const {companies} = useSelector((state: AppState) => state.auth.tables)
    const {customer, customers, company, isSaving} = useSelector((state: AppState) => state.customer)
    const {currencies, isLoading} = useSelector((state: AppState) => state.currencies)

    const [updateStatus, setUpdateStatus] = useState<boolean>(false)
    const [dataToUpdate, setDataToUpdate] = useState<CustomerDetails | undefined>(undefined)
    const [selectedCustomer, setSelectedCustomer] = useState<CustomerDetails | undefined>(undefined)
    const [isCompanyCustomer, setCompanyCustomer] = useState<boolean>(false)
    const [email, setEmail] = useState<string>('')
    const [phone, setPhone] = useState<string>('')
    const [currencyOptions, setCurrencyOptions] = useState<{ label: string, value: string }[]>([])
    const [companyOptions, setCompanyOptions] = useState<{ label: string, value: number }[]>([])
    const [billingPeriodOptions, setBillingPeriodOptions] = useState<{ label: string, value: string}[]>([])
    const [invoicingField, setInvoicingField] = useState<ValidateParams>({
        value: '',
        validateStatus: 'success',
        errorMsg: ''
    })
    const [openDelete, setOpenDelete] = useState(false)
    const [confirmDelete, setConfirmDelete] = useState(false)

    const [isEditor, setEditor] = useState(false)
    const [isDeleter, setDeleter] = useState(false)

    // get settings and logged user from store
    const loggedUser = useLoggedUser()
    if (!loggedUser || !loggedUser.isLoaded()) {
        return (<Spin/>)
    }
    const appSetting = loggedUser.getAppSettings()

    // logger
    const logger = (msg, obj: any = null) => {
        if (appSetting && appSetting.debug) {
            obj ? console.log('CustomerForm: ' + msg + ' > ' + JSON.stringify(obj)) : console.log('CustomerForm: ' + msg)
        }
    }

    useEffect(() => {
        setEditor(loggedUser.hasAccess('page_billing_customer_edit_button'))
        setDeleter(loggedUser.hasAccess('page_billing_customer_delete_button'))
        if (!currencies || currencies.length === 0) {
            dispatch(getCurrencies())
        }
        const billingPeriods = ['month', 'quarter_year', 'half_year', 'year', 'iregular']
        setBillingPeriodOptions(billingPeriods.map(p => { return {label: t('RangeFilter.' + p), value: p, key: p}}))
     }, []);

    useEffect(() => {
        if (currencies && currencies.length) {
            setCurrencyOptions(getInvoiceCurrency(currencies).map((c, _) => { return {label: c, value: c, key: c} }))
        }
    }, [currencies]);

    useEffect(() => {
        // when loaded
        if (props.isCreate) {
            // create
            // for company selector
            setCompanyOptions(companies.sort(sort_name).map(c => ({
                label: c.name,
                value: c.id
            })))
        }
        else {
            // edit
            if (customer) {
                // ask for customer users
                setDataToUpdate(customer)
            }
        }
    }, [customer, companies, customers]);

    useEffect(() => {
        logger('dataToUpdate:', dataToUpdate)
    }, [dataToUpdate]);

    const isReadOnly = () => {
        return isCompanyCustomer
    }

    useEffect(() => {
        if (company) {
            setEmail(company.email ? company.email : '')
            setPhone(company.phone ? company.phone : '')
            form.setFieldsValue({email: company.email})
            form.setFieldsValue({phone: company.phone})
        } else {
            setEmail('')
            setPhone('')
            form.setFieldsValue({email: ''})
            form.setFieldsValue({phone: ''})
            setCompanyCustomer(false)
            setSelectedCustomer(undefined)
        }
    }, [company]);

    const handleCompanyChange = (companyId: number) => {
        if (companyId) {
            dispatch(getCompanyCustomer(companyId))
        }

        const cust1 = customers.find(c => c.company_id === companyId)
        if (cust1 && cust1.id > 0) {
            setCompanyCustomer(true)
            setSelectedCustomer(cust1)
        }
        else {
            setCompanyCustomer(false)
            setSelectedCustomer(undefined)
        }
    }

    const handleInvoiceChange = (e: ChangeEvent<HTMLInputElement>) => {
        const emailStr = e.target.value
        setInvoicingField(validateInvoicingEmail(emailStr, t('customerPage.invoicingErr')))
        setUpdateStatus(true)
    }

    const showConfirmDelete = (obj: CustomerDetails, title: string, terminated: number) => {
        if (!obj) {
            return
        }
        confirm({
                icon: <ExclamationCircleOutlined style={{color: 'red'}} />,
                title: title,
                content: <p>{obj.company!.name}</p>,
                okText: t('general.yes'),
                cancelText: t('general.cancel'),
                okButtonProps: { loading: confirmDelete },
                className: 'confirm-alert',
                onOk() {
                    setConfirmDelete(true)
                    dispatch(setTerminated({id: obj.id, terminated: terminated},suc => {
                        setConfirmDelete(false)
                        setOpenDelete(false)
                        if (suc) {
                            message.success(t('general.success'))
                            dispatch(getCustomers( 'company,last_invoice'))
                        }
                        else {
                            message.error(t('general.error'))
                        }
                    }))
                },
                onCancel() {
                    setOpenDelete(false)
                },
            }
        )
    }


    const handleSubmit = (values): void => {
        if (!company) {
            message.error('Missing company!')
            return;
        }
        let invoicing = values['invoicing']
        if (!invoicing) {
            invoicing = ''
        }
        invoicing = invoicing.replace(/\s/g, '')
        const invoicingVal = validateInvoicingEmail(invoicing, t('customerPage.invoicingErr'))
        setInvoicingField(invoicingVal)
        if (invoicingVal.validateStatus != 'success') {
            return
        }

        // call API
        if (!props.isCreate && values && customer) {
            // edit
            let params = {...values, id: customer.id, invoicing: invoicing}
            dispatch(updateCustomer(params, (suc) => {
                        if (suc) {
                            message.success(t('general.success'))
                            dispatch(tableCustomers())
                            props.setModalVisible()
                        }
                    },
                ),
            )
            return
        }
        // create
        if (props.isCreate) {
            let params = {...values, ident: company.name, invoicing: invoicing, invoice_detail: 2, language: 'cs'}
            dispatch(createCustomer(params, (suc) => {
                    if (suc) {
                        message.success(t('general.success'))
                        dispatch(tableCustomers())
                        props.setModalVisible()
                    }
                }),
            )
        }
    }

    const checkAndSubmit = (values) => {
        if (invoicingField.validateStatus === 'success') {
            if (values.billing_currency !== dataToUpdate?.billing_currency && dataToUpdate) {
                Modal.confirm({
                    content: t('customerPage.modal'),
                    okText: t('customerPage.modal_okText'),
                    cancelText: t('customerPage.modal_cancelText'),
                    onOk() {
                        handleSubmit(values)
                    },
                    onCancel() {
                        const old = dataToUpdate?.billing_currency
                        form.setFieldsValue({billing_currency: old})
                    },
                    icon: <CheckCircleOutlined/>,
                })
            } else {
                handleSubmit(values)
            }
        }
        else {
            console.log('validateStatus='+invoicingField.validateStatus)
        }
    }

    if (!dataToUpdate && !props.isCreate) {
        return (<Spin/>)
    }

    if (!customer && !props.isCreate) {
        return (<Spin/>)
    }

    return (
        <>
        <Form
            {...formItemLayout}
            form={form}
            onFinish={checkAndSubmit}
            initialValues={dataToUpdate ? {
                ...dataToUpdate,
                language: 'cs'
            } : {company_id: null, billing_period: 'month', billing_currency: 'CZK', invoicing: '', language: 'cs'}}>

            {
                //when click create customer button, not from creating new company
                ((props.isCreate) &&
                    <Form.Item name='company_id'
                               label={t('customerPage.company_id')}
                               rules={[{required: true, message: t('errors.field_required')}]}
                               hasFeedback
                    >
                        <Select showSearch
                                allowClear
                                optionFilterProp='label'
                                filterOption={(val, opt) => {
                                    return !!opt && removeDiac(opt.label).includes(removeDiac(val))
                                }}
                                options={companyOptions}
                                onChange={handleCompanyChange}
                        />
                    </Form.Item>
                )
            }

            {
                props.isCreate &&
                <>
                    <Form.Item name='id' style={{display: 'none'}}>
                        <Input type='hidden'/>
                    </Form.Item>
                    <Form.Item name='ident' style={{display: 'none'}}>
                        <Input type='hidden'/>
                    </Form.Item>
                    <Form.Item name='email'
                               label={t('createUserPage.email')}
                               rules={[{
                                   required: false, type: "email",
                                   message: t('errors.error_email')
                               }]}
                               hasFeedback>
                        <Input type='text' disabled={isReadOnly()} />
                    </Form.Item>
                    <Form.Item name='phone'
                               label={t('createUserPage.phone')}
                               rules={[{
                                   pattern: new RegExp(euPhoneNumberRegex),
                                   message: t('errors.error_phone')
                               }]}
                               hasFeedback>
                        <Input type='text' disabled={isReadOnly()} />
                    </Form.Item>
                </>
            }

            <Form.Item name='billing_period'
                       label={t('customerPage.billing_period')}
                       rules={[{required: true, message: t('errors.field_required')}]}
                       hasFeedback>
                <Select onChange={() => setUpdateStatus(true)}
                        disabled={isReadOnly()}
                        options={billingPeriodOptions}
                />
            </Form.Item>

            <Form.Item name='billing_currency'
                       label={t('customerPage.billing_currency')}
                       rules={[{required: true, message: t('errors.field_required')}]}
                       hasFeedback>
                <Select onChange={() => setUpdateStatus(true)}
                        loading={isLoading}
                        showSearch
                        optionFilterProp='label'
                        options={currencyOptions}
                        disabled={isReadOnly()}
                />
            </Form.Item>

            <Form.Item name='invoicing'
                       label={t('customerPage.invoice')}
                       rules={[
                           {required: true, message: t('errors.field_required')},
                           {validator: (_, value) => {
                               let result = validateInvoicingEmail(value, t('customerPage.invoicingErr'))
                                   setInvoicingField(result)
                                   setUpdateStatus(true)
                                   if (result.validateStatus === 'success') {
                                       return Promise.resolve();
                                   } else {
                                       return Promise.reject('validateInvoicingEmail failed');
                                   }
                               },
                               message: 'this is custom'
                           }
                       ]}
                       validateStatus={invoicingField.validateStatus}
                       help={invoicingField.errorMsg}
                       hasFeedback>
                <Input type='text' disabled={isReadOnly()} />
            </Form.Item>
            {
                !props.isCreate &&
                <>
                    <Form.Item name='invoice_detail' label={t('customerPage.invoice_detail')} hasFeedback>
                        <Select optionFilterProp='label'
                                onChange={() => setUpdateStatus(true)}
                                disabled={isReadOnly()}
                                options={[
                                    ({label: t('billing.invoice.single_item'), value: 0}),
                                    ({label: t('billing.invoice.group_item'), value: 1, disabled: true}),
                                    ({label: t('billing.invoice.all_details'), value: 2}),
                                ]}
                        />
                    </Form.Item>
                    <Form.Item name='terminated' label={t('customerPage.terminated')} hasFeedback>
                        <Select optionFilterProp='label'
                                onChange={() => setUpdateStatus(true)}
                                disabled={isReadOnly()}
                                options={[({label: t('general.no'), value: 0}), ({label: t('general.yes'), value: 1})]}
                        />
                    </Form.Item>
                </>
            }

            <Form.Item name='note' label={t('createUserPage.note')}>
                <TextArea data-gramm="false"
                          data-gramm_editor="false"
                          data-enable-grammarly="false"
                          onChange={() => setUpdateStatus(true)}
                          disabled={isReadOnly()} />
            </Form.Item>

            <Form.Item {...tailLayout}>
                <Button type='primary' htmlType='submit'
                        className='login-form-button'
                        loading={isSaving}
                        disabled={(!props.isCreate && !updateStatus || isReadOnly())}>
                    {dataToUpdate ? t('general.update') : t('general.create')}
                </Button>
            </Form.Item>

            <Zalert type="warning"
                    condition={isCompanyCustomer && !!selectedCustomer}
                    message={selectedCustomer && selectedCustomer.terminated ? t('customerPage.existingCustomerTerminated') : t('customerPage.existingCustomer')}
                     />

            {
                !props.isCreate && customer && (
                    <Row style={{borderTop: '1px solid #dcdcdc', marginTop: '5px', paddingTop: '5px'}}>
                        <Col span={8}>
                            { // neukonceny
                                (isDeleter && !customer.terminated) ? (
                                    <Button danger size='small' style={{cursor: 'pointer'}}
                                            title={t('billing.customer.terminate_customer')}
                                            onClick={() => showConfirmDelete(customer, t('billing.customer.terminate_service_title'), 1)}
                                            icon={<DeleteTwoTone twoToneColor='red'/>}>{t('billing.customer.terminate_customer')}</Button>
                                ) : (
                                    <Button danger size='small'
                                            title={t('billing.customer.resurrect_customer')}
                                            onClick={() => showConfirmDelete(customer, t('billing.customer.resurrect_service_title'), 0)}
                                            icon={<RollbackOutlined twoToneColor='red'/>}>{t('billing.customer.resurrect_customer')}</Button>
                                )
                            }
                        </Col>
                        <Col span={16} className='left' style={{color: '#ccc'}}>

                        </Col>
                    </Row>
                )
            }

        </Form>


    </>
    )
}

export default CustomerForm
