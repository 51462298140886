import {GetStatusPlanRequestAction, GetStatusPlanResponseAction, GetStatusPlanErrorAction} from '.'
import {protectedApiClient} from 'helpers/api'
import {handleApiErrorWithNotification} from 'helpers/errorHandling'
import {StatusPlan} from "../models";


export default (id: number, cb?: (isSuccess: boolean) => void) => {
    return (
        dispatch: (
            arg: GetStatusPlanRequestAction | GetStatusPlanResponseAction | GetStatusPlanErrorAction,
        ) => void,
    ) => {
        const request = new GetStatusPlanRequestAction()
        dispatch(request)

        protectedApiClient
            .get<StatusPlan[]>(`/server/server/${id}/status-scheduler`)
            .then((response) => {
                dispatch(new GetStatusPlanResponseAction(request, response.data))
                cb && cb(true)
            })
            .catch((error) => {
                dispatch(new GetStatusPlanErrorAction(request, error))
                handleApiErrorWithNotification(error)
                cb && cb(false)
            })
    }
}
