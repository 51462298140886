import {protectedApiClient} from "../../../helpers/api";
import {handleApiErrorWithNotification} from "../../../helpers/errorHandling";
import {LookupVhostErrorAction, LookupVhostRequestAction, LookupVhostResponseAction} from "./index";



export default (field: string, qs: string, cb?: (isSuccess: boolean) => void) => {
    return (
        dispatch: (
            arg: LookupVhostRequestAction | LookupVhostResponseAction | LookupVhostErrorAction,
        ) => void,
    ) => {
        const request = new LookupVhostRequestAction(field)
        dispatch(request)
        let url = `/web/vhost/lookup?`+qs
        protectedApiClient
            .get<string[]>(url)
            .then((response) => {
                dispatch(new LookupVhostResponseAction(request, field, response.data))
                cb && cb(true)
            })
            .catch((error) => {
                dispatch(new LookupVhostErrorAction(request, error.toString()))
                handleApiErrorWithNotification(error)
                cb && cb(false)
            })
    }
}
