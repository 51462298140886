import { GetDomainsVhostRequestAction, GetDomainsVhostResponseAction, GetDomainsVhostErrorAction } from '.'
import { protectedApiClient } from 'helpers/api'
import { handleApiErrorWithNotification } from 'helpers/errorHandling'
import { DomainModel } from 'pages/dns/service/models'

export default (prefix: string, cb?: (isSuccess: boolean) => void) => {
	return (
		dispatch: (arg: GetDomainsVhostRequestAction | GetDomainsVhostResponseAction | GetDomainsVhostErrorAction) => void
	) => {
		const request = new GetDomainsVhostRequestAction()
		dispatch(request)

		let url = `/domain`
		if (prefix) {
			url = `/domain?name=${prefix}`
		}
		protectedApiClient
			.get<DomainModel[]>(url)
			.then((response) => {
				dispatch(new GetDomainsVhostResponseAction(request, response.data))
				cb && cb(true)
			})
			.catch((error) => {
				dispatch(new GetDomainsVhostErrorAction(request, error.toString()))
				handleApiErrorWithNotification(error)
				cb && cb(false)
			})
	}
}
