import {GetPhpVersionsRequestAction, GetPhpVersionsResponseAction, GetPhpVersionsErrorAction} from './index'
import {protectedApiClient} from 'helpers/api'
import {handleApiErrorWithNotification} from 'helpers/errorHandling'
import {NameItem} from "../../../common/models";


export default (cb?: (isSuccess: boolean) => void) => {
    return (dispatch: (arg: GetPhpVersionsRequestAction | GetPhpVersionsResponseAction | GetPhpVersionsErrorAction) => void) => {
        const request = new GetPhpVersionsRequestAction()
        dispatch(request)

        let url = `/web/version-type/2/version-list`
        protectedApiClient
            .get<NameItem[]>(url)
            .then((response) => {
                dispatch(new GetPhpVersionsResponseAction(request, response.data))
                cb && cb(true)
            })
            .catch((error) => {
                dispatch(new GetPhpVersionsErrorAction(request, error.toString()))
                handleApiErrorWithNotification(error)
                cb && cb(false)
            })
    }
}
