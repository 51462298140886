import {GetServiceTemplatesRequestAction, GetServiceTemplatesResponseAction, GetServiceTemplatesErrorAction} from './index'
import {protectedApiClient} from 'helpers/api'
import {handleApiErrorWithNotification} from 'helpers/errorHandling'
import {NameItem} from '../../../common/models'

export default (service_id: number, cb?: (isSuccess: boolean) => void) => {
    return (dispatch: (arg: GetServiceTemplatesRequestAction | GetServiceTemplatesResponseAction | GetServiceTemplatesErrorAction) => void) => {
        const request = new GetServiceTemplatesRequestAction(service_id)
        dispatch(request)

        let url = `/web/service/${service_id}/template-list`
        protectedApiClient
            .get<NameItem[]>(url)
            .then((response) => {
                dispatch(new GetServiceTemplatesResponseAction(request, response.data))
                cb && cb(true)
            })
            .catch((error) => {
                dispatch(new GetServiceTemplatesErrorAction(request, error.toString()))
                handleApiErrorWithNotification(error)
                cb && cb(false)
            })
    }
}

