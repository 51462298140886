import { useHistory } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { useTranslation } from "react-i18next"
import { useForm } from "antd/es/form/Form"
import { Button, Form, Input, message, Select, Spin } from "antd"
import { formItemLayout, tailLayout } from "helpers/layoutHelpers"
import { _isValidDomain, removeDiac } from "common/fce"
import { useLoggedUser } from "helpers/loginUserHelper"
import { AppState, NameItem } from "common/models"
import createMailDomain from "./actions/createMailDomain"
import { CreateMailDomainParams } from "./models"
import { useEffect, useState } from "react"
import { Store } from "antd/es/form/interface"
import DomainSelector from "pages/dns/service/DomainSelector"
import getDomains from "./actions/getDomains"
import getMailServices from "./actions/getMailServices"
import { InputStatus } from "antd/lib/_util/statusUtils"


interface Props {
  onClose: () => void
}

const { Item } = Form


const CreateMailDomainForm = (props: Props) => {
  const [form] = useForm()
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const history = useHistory()

  const { customers } = useSelector((state: AppState) => state.auth.tables)
  const { mail_domain, isSavingDomain, isLoadingDomains, mail_services, domains, daemon_version } = useSelector((state: AppState) => state.mailservice)

  const [domainOptions, setDomainOptions] = useState<{ label: string, value: number, key: number }[]>([])
  const [serviceOptions, setServiceOptions] = useState<{ label: string, value: number, key: number }[]>([])
  const [selectedDomainId, setSelectedDomainId] = useState<number>()
  const [domainStatus, setDomainStatus] = useState<InputStatus>('')
  const [domainError, setDomainError] = useState<string>("")
  const [updateStatus, setUpdateStatus] = useState<boolean>(false)

  // get settings and logged user from store
  const loggedUser = useLoggedUser()
  if (!loggedUser || !loggedUser.isLoaded()) {
    return (<Spin />)
  }
  const appSetting = loggedUser.getAppSettings()

  useEffect(() => {
    // once
    if (!loggedUser.user.is_zcom) {
      // customer loads domains
      dispatch(getDomains(''))
    }
    dispatch(getMailServices())
  }, [])


  useEffect(() => {
    if (mail_services && mail_services.length > 0) {
      setServiceOptions(mail_services.map(s => ({ label: s.host, value: s.id, key: s.id })))
    }
  }, [mail_services])

  useEffect(() => {
    if (domains && domains.length > 0) {
      setDomainOptions(domains.map(d => ({ label: d.name, value: d.id, key: d.id })))
    }
  }, [domains])

  const isDomainValid = () => {
    return selectedDomainId && selectedDomainId > 0
  }

  const isFormValid = () => {
    const sid = form.getFieldValue('service_id')
    return isDomainValid() && sid && sid > 0
  }

  const handleSelectedDomain = (name: string, sid?: number) => {
    console.log('setSelectedDomainId: ' + sid)
    if (sid) {
      setSelectedDomainId(sid)
      form.setFieldsValue([{ domain_id: sid }])
      form.setFieldsValue([{ name: name }])
      setDomainError('')
      setDomainStatus('')
    }
    else {
      // message.error('Domain not found in ZIS.')
      setDomainError('Domain not found in ZIS.')
      setDomainStatus('error')
      setSelectedDomainId(undefined)
      form.setFieldsValue([{ domain_id: undefined }])
      form.setFieldsValue([{ name: undefined }])
    }
    console.log('Form: ' + JSON.stringify(form.getFieldsValue()))
  }

  const loadDomainLookup = (pattern: string) => {
    if (appSetting.checkMinSearch(pattern)) {
      dispatch(getDomains(pattern))
    }
  }

  const onFormSubmit = (values: Store) => {
    console.log('Form: ' + JSON.stringify(form.getFieldsValue()))
    if (!values.service_id || !selectedDomainId) {
      message.error('Mail server and domain are required')
      return
    }

    const params: CreateMailDomainParams = {
      service_id: values.service_id,
      domain_id: selectedDomainId,
    }

    dispatch(createMailDomain(params, (suc, id) => {
      if (suc) {
        message.success(t('general.success'))
        props.onClose()
      }
    }))
  }

  if (!appSetting || Object.keys(appSetting).length === 0) {
    return (<Spin />)
  }

  return (
    <Form
      onFinish={onFormSubmit}
      form={form}
      onChange={() => setUpdateStatus(true)}
      {...formItemLayout}
    >

      <Form.Item label={t('mailDomainsPage.mail_service')} name='service_id'>
        <Select
          showSearch
          filterOption={(val, opt) => {
            return !!opt && removeDiac(opt.label).includes(removeDiac(val))
          }}
          optionFilterProp='label'
          options={serviceOptions}
          placeholder={t('mailDomainsPage.mail_service')}
          style={{ width: '100%' }}
        >
        </Select>
      </Form.Item>

      {
        // non ZCOM
        !loggedUser.user.is_zcom && (
          <Form.Item name='domain_id' label={t('mailDomainsPage.domain')}>
            <Select
              showSearch
              filterOption={(val, opt) => {
                return !!opt && removeDiac(opt.label).includes(removeDiac(val))
              }}
              optionFilterProp='label'
              options={domainOptions}
              placeholder={t('mailDomainsPage.domain_choose')}
              style={{ width: '100%' }}
            >
            </Select>
          </Form.Item>
        )
      }

      {
        // ZCOM
        loggedUser.user.is_zcom && (
          <Form.Item label={t('mailDomainsPage.domain_choose')} name='domain_id'
            help={domainError}
            status={domainStatus}
          >
            <DomainSelector
              dataSource={domains.map(d => ({ name: d.name, id: d.id }))}
              isLoading={isLoadingDomains}
              handleSelectedDomain={handleSelectedDomain}
              fetchData={loadDomainLookup}
              handleStatus={(status) => { setDomainStatus(status) }}
            />
          </Form.Item>
        )
      }

      <Form.Item {...tailLayout}>
        <Button type='primary'
          loading={isSavingDomain}
          disabled={!isFormValid()}
          htmlType='submit'>
          {t('general.create')}
        </Button>
      </Form.Item>
    </Form>
  )
}

export default CreateMailDomainForm
