import {GenCertificateRequestAction, GenCertificateResponseAction, GenCertificateErrorAction} from '.'
import {protectedApiClient} from 'helpers/api'
import {handleApiErrorWithNotification} from 'helpers/errorHandling'
import {GenCertificateParams} from '../models'


export default (params: GenCertificateParams, cb?: (isSuccess: boolean) => void) => {
    // creates scheduled job only
    return (
        dispatch: (
            arg: GenCertificateRequestAction | GenCertificateResponseAction | GenCertificateErrorAction,
        ) => void,
    ) => {
        const request = new GenCertificateRequestAction(params)
        dispatch(request)
        protectedApiClient
            .post<string>(`/customer/${params.customer_id}/certificate-generate`, params)
            .then((response) => {
                dispatch(new GenCertificateResponseAction(request, response.data))
                cb && cb(true)
            })
            .catch((error) => {
                dispatch(new GenCertificateErrorAction(request, error))
                handleApiErrorWithNotification(error)
                cb && cb(false)
            })
    }
}
