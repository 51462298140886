import { SetSshKeysRequestAction, SetSshKeysResponseAction, SetSshKeysErrorAction } from '.'
import { protectedApiClient } from 'helpers/api'
import { handleApiErrorWithNotification } from 'helpers/errorHandling'
import { SetSshKeysParams, SshKeys } from '../models'

export default (params: SetSshKeysParams, cb?: (isSuccess: boolean) => void) => {
	return (dispatch: (arg: SetSshKeysRequestAction | SetSshKeysResponseAction | SetSshKeysErrorAction) => void) => {
		const request = new SetSshKeysRequestAction(params)
		dispatch(request)

		const data = { rsa: params.rsa || null, sha2: params.sha2 || null }
		protectedApiClient
			.put<SshKeys>(`/user/${params.user_id}/ssh-key`, data)
			.then((response) => {
				dispatch(new SetSshKeysResponseAction(request, response.data))
				cb && cb(true)
			})
			.catch((error) => {
				dispatch(new SetSshKeysErrorAction(request, error))
				handleApiErrorWithNotification(error)
				cb && cb(false)
			})
	}
}
