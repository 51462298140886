import {protectedApiClient} from "../../../helpers/api";
import {handleApiErrorWithNotification} from "../../../helpers/errorHandling";
import {LookupMailServiceErrorAction, LookupMailServiceRequestAction, LookupMailServiceResponseAction} from "./index";



export default (field: string, qs: string, cb?: (isSuccess: boolean) => void) => {
    return (
        dispatch: (
            arg: LookupMailServiceRequestAction | LookupMailServiceResponseAction | LookupMailServiceErrorAction,
        ) => void,
    ) => {
        const request = new LookupMailServiceRequestAction(field)
        dispatch(request)
        let url = `/mail/service/lookup?`+qs
        protectedApiClient
            .get<string[]>(url)
            .then((response) => {
                dispatch(new LookupMailServiceResponseAction(request, field, response.data))
                cb && cb(true)
            })
            .catch((error) => {
                dispatch(new LookupMailServiceErrorAction(request, error))
                handleApiErrorWithNotification(error)
                cb && cb(false)
            })
    }
}
