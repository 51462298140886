import {AuthState} from '../pages/login/models'
import {ThemeSwitcherState} from '../components/ThemeSwitcher/models'
import {FontSwitcherState} from '../components/FontSwitcher/models'
import {UserDetails, UserState} from 'pages/user/models'
import {CompanyDetails, CompanyState} from 'pages/company/models'
import {CustomerDetails, CustomerState} from 'pages/billing/customer/models'
import {SubnetPageState} from 'pages/ip/subnet/models'
import {CountriesState} from '../components/SelectCountries/models'
import {TaxState} from 'pages/billing/tax/models'
import {ProductState} from 'pages/billing/product/models'
import {PricelistState} from 'pages/billing/pricelist/models'
import {ProductPriceState} from 'pages/billing/productprice/models'
import {ProductPriceRangeState} from 'pages/billing/productpricerange/models'
import {CustomerServiceState} from 'pages/billing/customerservice/models'
import {InvoiceState} from 'pages/billing/invoice/models'
import {InvoiceDocumentState} from 'pages/billing/invoicedocument/models'
import {InvoiceCostAllocationState} from 'pages/billing/invoicecostallocation/models'
import {CostAllocationCategoryState} from 'pages/billing/costallocation/models'
import {BillPageState} from 'pages/billing/bill/models'
import {BillAttachmentState} from 'components/BillVat/BillAttachment/models';
import {CurrenciesState} from 'components/SelectCurrencies/models'
import {InventoryPageState} from 'pages/inventory/inventory/models'
import {ServersPageState} from 'pages/server/models'
import {InventoryLocationState} from 'pages/inventory/location/models'
import {InventoryTypeState} from 'pages/inventory/type/models'
import {PhysicalServerPageState} from 'pages/inventory/physicalserver/models'
import {DataCenterPageState} from 'pages/datacenter/datacenter/actions/models'
import {RackState} from 'pages/datacenter/rack/models'
import {BlockState} from 'pages/datacenter/block/models'
import {HistoryState} from 'components/History/models'
import {DnsPageState} from 'pages/dns/service/models'
import {AsyncJobState} from 'pages/asyncjob/job/models'
import {CertificateState} from 'pages/certificate/models'
import {WebServiceState} from 'pages/webservice/models'
import {ServiceTypeState} from 'pages/billing/servicetype/model'
import {SettingsDetails, SettingsState} from 'pages/settings/models'
import {MailState} from 'pages/billing/mail/models'
import {MailTemplateState} from "../pages/billing/mailtemplate/models";
import {HypervisorPageState} from "../pages/vpsadmin/models";
import {CountriesPageState } from 'pages/billing/countries/models'
import {SideNavState} from "../components/SideNav/models";
import {LogState} from "../pages/logviewer/models";
import {RolesPageState} from "../pages/rights/roles/models";
import {ResGroupModel, ResourcePageState} from "../pages/rights/resources/models";
import {GroupsPageState, UserGroupModel} from "../pages/rights/usergroups/models";
import {UserRightsPageState} from "../pages/rights/users/models";
import {DatabasesPageState} from "../pages/database/models";
import { MailDomainsPageState } from 'pages/mail/models'


export interface AppState {
	auth: AuthState
	theme: ThemeSwitcherState
	font: FontSwitcherState
	user: UserState
	appSettingPage: SettingsState
	rolesPage: RolesPageState
	userRightsPage: UserRightsPageState
	resourcePage: ResourcePageState
	groupsPage: GroupsPageState
	company: CompanyState
	customer: CustomerState
	sidenav: SideNavState
	ipSubnet: SubnetPageState
	countries: CountriesState
	logs: LogState
	product: ProductState
	pricelist: PricelistState
	productprice: ProductPriceState
	productpricerange: ProductPriceRangeState
	customerservice: CustomerServiceState
	tax: TaxState
	currencies: CurrenciesState
	invoice: InvoiceState
	invoicedocument: InvoiceDocumentState
	invoiceCostAllocation: InvoiceCostAllocationState
	costAllocationCategory: CostAllocationCategoryState
	bill: BillPageState
	billattachment: BillAttachmentState
	inventory: InventoryPageState
	inventorylocation: InventoryLocationState
	inventorytype: InventoryTypeState
	inventoryphysicalserver: PhysicalServerPageState
	logic_server: ServersPageState
	datacenter: DataCenterPageState
	rack: RackState
	block: BlockState
	historylog: HistoryState
	dnsservice: DnsPageState
	rdbservice: DatabasesPageState
	mailservice: MailDomainsPageState
	asyncjob: AsyncJobState
	certificate: CertificateState
	vpsadmin: HypervisorPageState
	webservice: WebServiceState
	servicetype: ServiceTypeState
	mail: MailState
	mailtemplate: MailTemplateState
	countriesPage: CountriesPageState
}

export interface NameItemStr {
	id: string
	name: string
}

export interface NameItem {
	id: number
	key?: string
	name: string
}

export interface ValueItem {
	id: number
	key?: string
	value: string
}

export interface ModelName {
	id: number
	model: string
	name: string
}

export enum AsyncActionMode {
	REQUEST = 'REQUEST',
	RESPONSE = 'RESPONSE',
	ERROR = 'ERROR',
}

export interface AppUser {
	id: number
	username: string
	token: string
}

export interface TableQuery {
	skip: number
	limit: number
}

export interface TableResult<T> {
	items: T[]
	skip: number
	limit: number
	totalCount: number
}

export interface TablePagination {
	current: number
	pageSize: number
	totalCount: number
}

export interface ILoggedUserData {
	authenticated: boolean
	user: UserDetails
	groups: UserGroupModel[]
	roles: string[]
	resourceGroups: ResGroupModel[]
	customer: CustomerDetails
	company: CompanyDetails
	validCustomers: CustomerDetails[]
	page_permissions: {}
	settings: SettingsDetails[]
}

// @ts-ignore
export interface ILoggedUser extends ILoggedUserData {
	hasRole: (name: string) => boolean
}

export interface Pagination {
	totalCount: number
	page: number
	pageSize: number
	pageCount: number
}

export const PAGE1 = {totalCount: 1, page: 1, pageSize: 10, pageCount: 1}
