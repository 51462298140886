import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { LoadingIndicator } from "../../../components"
import React, { useEffect, useRef, useState } from "react"
import { Card, Col, Input, message, Row, Select, Space, Table, Tag } from "antd"
import { PlusCircleOutlined, SplitCellsOutlined } from "@ant-design/icons/lib/icons"
import Button from "antd-button-color"
import {
    AppstoreAddOutlined,
    DeleteTwoTone,
    EditTwoTone, FolderTwoTone, InfoCircleOutlined,
    InfoCircleTwoTone,
    PlusCircleTwoTone,
} from "@ant-design/icons"
import { ResGroupModel, ResourceModel, ResourceRowModel } from "./models"
import { AppState } from "../../../common/models"
import { useLoggedUser } from "../../../helpers/loginUserHelper"
import Draggable, { DraggableData, DraggableEvent } from "react-draggable"
import { sort_label, sort_name, sort_resource } from "../../../common/sorting"
import { _isNotEmptyObject, stopPropagation } from "../../../common/fce"
import PopConfirmZis from "../../../components/popconfirm/PopConfirmZis"
import { ColumnsType } from "antd/lib/table"
import getAllResources from "./actions/getAllResources"
import getResGroups from "./actions/getResGroups"
import getResGroup from "./actions/getResGroup"
import getResource from "./actions/getResource"
import "./ResourcePage.scss"
import Modal from "antd/lib/modal/Modal"
import NewResGroupForm from "./NewResGroupForm"
import deleteResGroup from "./actions/deleteResGroup"
import EditResourceForm from "./EditResourceForm"
import { useHistory } from "react-router"
import { useParams } from "react-router-dom"
import selectResource from "./actions/selectResource"
import { renderID, renderMethods, renderResGroup, renderResource, renderSelected, replResource } from "../common"
import HistoryLog from "../../../components/History/HistoryLog"
import {
    ROLE_ACCESS_MANAGER,
    ROLE_APP_ADMIN,
    ROLE_CUSTOMER_MANAGER,
    ROLE_USER_MANAGER
} from "../../../common/LoggedUser"


interface ParamTypes {
    group_id: string
}


const ResourcePage = () => {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const history = useHistory()
    const { group_id } = useParams<ParamTypes>()

    const { resGroup, resGroups, resources, allResources, isLoadingResGroup, isLoadingRes, isLoadingResAll } = useSelector((state: AppState) => state.resourcePage)
    const { rights_resources } = useSelector((state: AppState) => state.sidenav)
    const { customers } = useSelector((state: AppState) => state.auth.tables)

    const [searchResGroup, setSearchResGroup] = useState<string>('')
    const [searchResourceAll, setSearchResourceAll] = useState<string>('')
    const [selectedResGroup, setSelectedResGroup] = useState<ResGroupModel | undefined>()
    const [editGroup, setEditGroup] = useState<ResGroupModel | undefined>()
    const [selectedResource, setSelectedResource] = useState<ResourceRowModel | undefined>()
    const [custOptions, setCustOptions] = useState<{ label: string, value: number }[]>([])
    const [selectedCustomerId, setSelectedCustomerId] = useState<number | undefined>()
    const [isHistoryModalVisible, setHistoryModalVisible] = useState(false)
    const [historyTitle, setHistoryTitle] = useState('')
    const [historyModelId, setHistoryModelId] = useState<number | undefined>()
    const [forceUpdateHistoryId, setForceUpdateHistoryId] = useState<number>(1)

    const [dsResGroups, setDsResGroups] = useState<ResGroupModel[]>([])
    const [dsResources, setDsResources] = useState<ResourceRowModel[]>([])
    const [dsAllResources, setDsAllResources] = useState<ResourceModel[]>([])
    const [isResGroupModalVisible, setResGroupModalVisible] = useState<boolean>(false)
    const [isResourceModalVisible, setResourceModalVisible] = useState<boolean>(false)

    // get settings and logged user from store
    const loggedUser = useLoggedUser()
    if (!loggedUser || !loggedUser.isLoaded()) {
        return (
            <div className="fullwidth-loading" >
                <LoadingIndicator />
            </div>
        )
    }
    const appSetting = loggedUser.getAppSettings()
    const SEARCH_MIN = appSetting.min_search_length

    // logger
    const logger = (msg, obj: any = null) => { if (appSetting && appSetting.debug) { obj ? console.log('RolesPage: ' + msg + ' > ' + JSON.stringify(obj)) : console.log('RolesPage: ' + msg) } }

    // history drag modal
    const draggleRef = useRef<HTMLDivElement>(null)
    const [bounds, setBounds] = useState({ left: 0, top: 0, bottom: 0, right: 0 })
    const onStart = (_event: DraggableEvent, uiData: DraggableData) => {
        const { clientWidth, clientHeight } = window.document.documentElement
        const targetRect = draggleRef.current?.getBoundingClientRect()
        if (!targetRect) {
            return
        }
        setBounds({
            left: -targetRect.left + uiData.x,
            right: clientWidth - (targetRect.right - uiData.x),
            top: -targetRect.top + uiData.y,
            bottom: clientHeight - (targetRect.bottom - uiData.y),
        })
    }

    // permissions
    // required: ROLE_ACCESS_MANAGER | ROLE_APP_ADMIN
    const [isViewer, setViewer] = useState(false)
    const [isCreator, setCreator] = useState(false)
    const [isEditor, setEditor] = useState(false)
    const [isDeleter, setDeleter] = useState(false)

    useEffect(() => {
        // trigger ONCE
        const access_users = loggedUser.hasRole(ROLE_ACCESS_MANAGER) || loggedUser.hasRole(ROLE_APP_ADMIN)
        setViewer(access_users)
        setCreator(access_users)
        setEditor(access_users)
        setDeleter(access_users)
        if (!access_users) {
            // go to dashboard
            message.error(t('general.error403'))
            history.replace('/')
            return
        }

        dispatch(getAllResources())
        dispatch(getResGroups())

        if (group_id && parseInt(group_id) > 0) {
            // read QueryString /2
            dispatch(getResGroup(parseInt(group_id)))
        }
        else {
            setSelectedResGroup(undefined)
        }
        const cid = localStorage.getItem('customerId')
        cid && parseInt(cid) > 0 && setSelectedCustomerId(parseInt(cid))
    }, [])

    useEffect(() => {
        // Customer Options
        if (customers && customers.length) {
            if (loggedUser.user.is_zcom) {
                const items = customers.map(c => ({ label: c.company!.name ? c.company!.name : '', value: c.id }))
                setCustOptions(items.sort(sort_label))
            }
        }
    }, [customers])

    useEffect(() => {
        // handle breadcrumb click
        if (group_id && parseInt(group_id) > 0) {
            // check QueryString
            dispatch(getResGroup(parseInt(group_id)))
        }
    }, [group_id])

    useEffect(() => {
        refreshResGroups()
        if (selectedResGroup) {
            setSelectedResGroup(undefined)
        }
    }, [selectedCustomerId])

    useEffect(() => {
        // trigger from Side menu clicked
        if (!isLoadingResGroup) {
            setSelectedResGroup(undefined)
            dispatch(getResGroups())
        }
    }, [rights_resources])

    useEffect(() => {
        // update datasource
        if (!isResGroupModalVisible) {
            refreshResGroups()
        }
    }, [resGroups, searchResGroup, isResGroupModalVisible])

    useEffect(() => {
        // select ResGroup if changed
        if (resGroup) {
            if (!selectedResGroup) {
                if (group_id) {
                    const rg = resGroups.find(g => g.id === parseInt(group_id))
                    if (rg) {
                        setSelectedResGroup(rg)
                    }
                    return
                }
            }

            if (selectedResGroup && selectedResGroup.id) {
                if (resGroup.id != selectedResGroup.id) {
                    setSelectedResGroup(resGroup)
                }
            }
            else {
                setSelectedResGroup(resGroup)
            }
        }
        else {
            setSelectedResGroup(undefined)
        }
    }, [resGroup])

    useEffect(() => {
        // update datasource
        if (!isResGroupModalVisible) {
            refreshAllResources()
        }
    }, [allResources, searchResourceAll, isResGroupModalVisible])

    useEffect(() => {
        // update resources
        if (!isResGroupModalVisible) {
            refreshResources()
            refreshAllResources()
        }
    }, [resources, isResGroupModalVisible])


    useEffect(() => {
        // update resources for res-group
        if (selectedResGroup) {
            history.replace('/rights/resource-group/' + selectedResGroup.id)
        }
    }, [selectedResGroup])

    const refreshAllResources = () => {
        setDsAllResources(filteredAllERes())
    }

    const refreshResources = () => {
        logger('refreshResources', resources)
        setDsResources(resources.sort(sort_resource))
    }

    const onChangeCustomer = (value) => {
        setSelectedCustomerId(value)
        localStorage.setItem('customerId', value)
    }

    const filteredResGroup = () => {
        // search for pattern
        if (!resGroups) {
            return []
        }

        let data = resGroups.map(r => { return { ...r, key: r.name } })
        if (selectedCustomerId) {
            data = data.filter((r) => r.customer_id === selectedCustomerId)
        }
        if (searchResGroup && searchResGroup.length > SEARCH_MIN) {
            data = data.filter((r) => r.name.toLowerCase().includes(searchResGroup.toLowerCase()))
        }
        return data.sort(sort_name)
    }

    const filteredAllERes = () => {
        // search for pattern
        if (!allResources) {
            return []
        }
        let data = allResources
        const iid = resources.map(r => r.id)
        if (iid) {
            // filter out already selected
            // data = data.filter((r) => !iid.includes(r.id))
        }

        if (searchResourceAll && searchResourceAll.length > SEARCH_MIN) {
            // Search
            data = data.filter((r) => r.resource.toLowerCase().includes(searchResourceAll.toLowerCase()))
        }
        data = data.map(res => { return { ...res, resource: replResource(res.resource) } })
        return data.sort(sort_resource)
    }

    const refreshResGroups = () => {
        setDsResGroups(filteredResGroup())
    }

    const onDeleteResGroup = () => {
        selectedResGroup && logger('onDeleteResGroup ' + selectedResGroup.id)
        selectedResGroup && selectedResGroup.id &&
            dispatch(deleteResGroup(selectedResGroup.id, (suc) => suc && message.success(t('general.success'))))
    }

    const FilterByResGroup = (
        <Input
            placeholder={t('rights.resources.group_name')}
            style={{ width: '100%' }}
            value={searchResGroup}
            allowClear={true}
            onClick={stopPropagation}
            onChange={(current) => {
                setSearchResGroup(current.target.value)
            }}
        />
    )
    const HeaderRes = (
        <div style={{ width: '100%', padding: '5px' }}
        >{t('rights.resources.resources')}</div>
    )

    const FilterByResAll = (
        <Input
            placeholder={t('rights.resources.allResource')}
            style={{ width: '100%' }}
            value={searchResourceAll}
            allowClear={true}
            onClick={stopPropagation}
            onChange={(current) => {
                setSearchResourceAll(current.target.value)
            }}
        />
    )

    const getTitle = (cid: number) => {
        if (cid === 1) {
            //return 'ZCOM'
        }
        const cust = customers.find(c => c.id === cid)
        return cust && cust.company!.name ? cust.company!.name : ''
    }

    const columnsResGroup = [
        {
            title: FilterByResGroup,
            dataIndex: 'name',
            key: 'name',
            width: '95%',
            className: 'td-blue tdOver',
            render: (_, rec: ResGroupModel) => renderResGroup(rec.name, getTitle(rec.customer_id), !!selectedResGroup && (rec.id === selectedResGroup.id), undefined)
        },
    ]

    const columnsRes: ColumnsType<ResourceRowModel> = [
        {
            title: HeaderRes,
            dataIndex: 'resource',
            key: 'resource',
            width: '30%',
            render: (_, rec: ResourceRowModel) => renderResource(rec.resource)
        },
        {
            title: 'Label',
            dataIndex: 'label',
            key: 'label',
            width: '30%',
            render: (_, rec: ResourceRowModel) => renderID(rec.label)
        },
        {
            title: t('rights.resources.actions'),
            dataIndex: 'actions',
            key: 'actions',
            render: (_, rec: ResourceRowModel) => renderMethods(rec.actions)
        },
        {
            title: '',
            key: 'action',
            width: '34px',
            dataIndex: 'action',
            align: 'right',
            fixed: 'right',
            render: (_, rec: ResourceRowModel) => (
                <Space>
                    <Button type='text' size='small'
                        disabled={!selectedResGroup}
                        onClick={() => {
                            if (selectedResGroup && selectedResGroup.id && rec.id) {
                                setResourceModalVisible(true)
                                setSelectedResource(rec)
                                dispatch(selectResource(rec))
                            }
                        }}
                        className='actionButton'
                        icon={<EditTwoTone twoToneColor='green' />}
                    />
                </Space>
            ),
        },
    ]


    const columnsALL: ColumnsType<ResourceModel> = [
        {
            title: FilterByResAll,
            dataIndex: 'resource',
            key: 'resource',
            fixed: 'left',
            className: 'tdOver'
        },
        {
            title: '',
            key: 'action',
            width: '50px',
            dataIndex: 'action',
            fixed: 'right',
            render: (_, rec: ResourceModel) => (
                <Space size='small'>
                    <Button type='text' size='small'
                        disabled={!selectedResGroup}
                        onClick={() => {
                            if (selectedResGroup && selectedResGroup.id && rec.id) {
                                setResourceModalVisible(true)
                                dispatch(getResource(rec.id))
                            }
                        }}
                        className='actionButton'
                        icon={(selectedResGroup && selectedResGroup.id) ? <PlusCircleTwoTone /> : <PlusCircleTwoTone twoToneColor="#cccccc" />}
                    />
                </Space>
            ),
        },
    ]


    return (
        <>
            <Card className='ResourcePage'
                title={
                    <Row>
                        <Col span={8}>
                            <AppstoreAddOutlined />&nbsp;{t('rights.resources.title')} &nbsp;
                            {selectedResGroup && renderSelected(selectedResGroup.name)} &nbsp;
                            {
                                selectedResGroup && (
                                    <Button title={t('general.btnHistory')} size='small'
                                        onClick={() => {
                                            if (selectedResGroup.id) {
                                                setHistoryTitle(`Group / ${selectedResGroup?.name}`)
                                                setHistoryModelId(selectedResGroup.id)
                                                setHistoryModalVisible(true)
                                                setForceUpdateHistoryId(forceUpdateHistoryId)
                                            }
                                        }}
                                        icon={<InfoCircleTwoTone />}
                                        className='actionButton'
                                    />
                                )
                            }

                            {
                                selectedResGroup && (
                                    <Button title={t('general.rename')} size='small'
                                        onClick={() => {
                                            if (selectedResGroup.id) {
                                                setEditGroup(selectedResGroup)
                                                setResGroupModalVisible(true)
                                            }
                                        }}
                                        icon={<EditTwoTone twoToneColor='green' />}
                                        className='actionButton'
                                    />
                                )
                            }

                            {
                                selectedResGroup && (
                                    <PopConfirmZis title={t('rights.resources.delete_res_group_confirm')} onConfirm={() => {
                                        if (selectedResGroup) {
                                            if (resources && resources.length > 0) {
                                                message.error('Group must be empty.')
                                                return
                                            }
                                            onDeleteResGroup()
                                        }
                                    }}>
                                        <Button type='text' danger size='small'
                                            disabled={!selectedResGroup}
                                            title={t('rights.resources.delete_res_group')}
                                            className='actionButton'
                                            icon={<DeleteTwoTone twoToneColor='red' />}
                                        />
                                    </PopConfirmZis>)
                            }
                        </Col>
                        <Col span={16} className='center'>&nbsp;{t('rights.resources.filter')} &nbsp;
                            <Select
                                showSearch
                                placeholder={t('general.all')}
                                allowClear={true}
                                value={selectedCustomerId}
                                style={{ width: '320px', textAlign: 'left', fontSize: '1.2em', fontWeight: 'bold' }}
                                onChange={onChangeCustomer}
                                optionFilterProp='label'
                                options={custOptions}
                            />
                        </Col>
                    </Row>
                }
                extra={
                    <Button
                        type='primary'
                        onClick={() => {
                            setEditGroup(undefined)
                            setResGroupModalVisible(true)
                        }}>
                        <PlusCircleOutlined /> {t('rights.resources.new_resource')}
                    </Button>
                }
            >

                <Row gutter={[8, 8]} style={{ borderTop: '1px solid #dcdcdc' }}>
                    <Col span={5} className='panel' style={{ marginRight: '0px' }}>
                        <Table<ResGroupModel>
                            rowClassName={(rec) => (selectedResGroup && selectedResGroup.id) === rec.id ? 'tr-selected' : 'tr'}
                            bordered={true}
                            columns={columnsResGroup}
                            onRow={(rec: ResGroupModel, rowIndex) => {
                                return {
                                    onClick: (event) => { setSelectedResGroup(rec) }, // click row
                                }
                            }}
                            loading={isLoadingResGroup}
                            dataSource={dsResGroups}
                            className='res-group-table'
                            rowKey='id'
                            scroll={{ y: 600 }}
                            pagination={false}
                            footer={() => (
                                <Row>
                                    <Col span={12}><span>{t('rights.resources.total')}: {dsResGroups.length}</span></Col>
                                    <Col span={12}><div style={{ float: 'right' }}>&nbsp;</div></Col>
                                </Row>
                            )}
                        />
                    </Col>
                    <Col span={13} className='panel' style={{ marginRight: '0px' }}>
                        <Table<ResourceRowModel>
                            rowClassName={() => 'highlight'}
                            bordered={true}
                            columns={columnsRes}
                            loading={isLoadingRes}
                            dataSource={dsResources}
                            className='resource-table'
                            rowKey='key'
                            scroll={{ y: 600 }}
                            style={{ height: '600px' }}
                            pagination={false}
                            footer={() => (
                                <Row>
                                    <Col span={12}><span>{t('rights.resources.total')}: {dsResources.length}</span></Col>
                                    <Col span={12}><div style={{ float: 'right' }}>&nbsp;</div></Col>
                                </Row>
                            )}
                        />
                    </Col>
                    <Col span={6} className='panel' style={{ marginRight: '0px' }}>
                        <Table<ResourceModel>
                            rowClassName={() => 'highlight'}
                            bordered={true}
                            columns={columnsALL}
                            loading={isLoadingResAll}
                            dataSource={dsAllResources}
                            className='all-resource-table'
                            rowKey='resource'
                            scroll={{ y: 600 }}
                            pagination={false}
                            footer={() => (
                                <Row>
                                    <Col span={12}><span>{t('rights.resources.total')}: {dsAllResources.length}</span></Col>
                                    <Col span={12}><div style={{ float: 'right' }}>&nbsp;</div></Col>
                                </Row>
                            )}
                        />
                    </Col>
                </Row>

                <Row gutter={[8, 8]} style={{ borderTop: '1px solid #dcdcdc' }}>
                    <Col span={6} className='panel' style={{ marginRight: '0px' }}>

                    </Col>
                    <Col span={12} className='panel' style={{ marginRight: '0px' }}>

                    </Col>
                    <Col span={6} className='panel' style={{ marginRight: '0px' }}>

                    </Col>
                </Row>

            </Card>

            <Modal
                destroyOnClose={true}
                style={{ top: 20 }}
                title={
                    <>
                        <AppstoreAddOutlined /> {t('rights.resources.resGroup')}
                    </>
                }
                visible={isResGroupModalVisible}
                onCancel={() => setResGroupModalVisible(false)}
                maskClosable={false}
                footer={null}>
                <NewResGroupForm group={editGroup} setModalVisible={setResGroupModalVisible} />
            </Modal>

            <Modal
                destroyOnClose={true}
                style={{ top: 20 }}
                title={
                    <>
                        <AppstoreAddOutlined /> {t('rights.resources.edit_resource')}
                    </>
                }
                visible={isResourceModalVisible}
                onCancel={() => setResourceModalVisible(false)}
                maskClosable={false}
                footer={null}>
                <EditResourceForm setModalVisible={setResourceModalVisible} />
            </Modal>

            <Modal
                destroyOnClose
                style={{ top: 50 }}
                bodyStyle={{ height: '60%', minHeight: 450, padding: 2 }}
                width='60%'
                className='historyLogModal'
                title={(<><InfoCircleOutlined />&nbsp;{t('general.history')}: {historyTitle}</>)}
                visible={isHistoryModalVisible}
                onCancel={() => setHistoryModalVisible(false)}
                maskClosable={false}
                modalRender={(modal) => (
                    <Draggable bounds={bounds} onStart={(ev, data) => onStart(ev, data)}>
                        <div ref={draggleRef}>{modal}</div>
                    </Draggable>
                )}
                footer={null}>

                <HistoryLog isModal={true}
                    service='resource'
                    model='Group'
                    modelId={historyModelId}
                    forceUpdateId={forceUpdateHistoryId}
                    showTitle={false} />

            </Modal>
        </>
    )
}

export default ResourcePage
