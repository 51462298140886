import {UpdateVhostRequestAction, UpdateVhostResponseAction, UpdateVhostErrorAction} from './index'
import {protectedApiClient} from 'helpers/api'
import {handleApiErrorWithNotification} from 'helpers/errorHandling'
import {VhostModel, VhostParams} from '../models'

export default (params: VhostParams, cb?: (isSuccess: boolean) => void) => {
	return (dispatch: (arg: UpdateVhostRequestAction | UpdateVhostResponseAction | UpdateVhostErrorAction) => void) => {
		const request = new UpdateVhostRequestAction(params)
		dispatch(request)

		protectedApiClient
			.put<VhostModel>(`/web/vhost/${params.id}?expand=service,domain,is_config_applied`, params)
			.then((response) => {
				dispatch(new UpdateVhostResponseAction(request, response.data))
				cb && cb(true)
			})
			.catch((error) => {
				dispatch(new UpdateVhostErrorAction(request, error.toString()))
				handleApiErrorWithNotification(error)
				cb && cb(false)
			})
	}
}
