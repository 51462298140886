import React, {useEffect, useRef, useState} from 'react'
import {Card, Modal, message, Space, Table, AutoComplete, Col, Row, Spin} from 'antd'
import {
    GlobalOutlined,
    PlusCircleOutlined
} from '@ant-design/icons/lib/icons'
import {useTranslation} from 'react-i18next'
import {useDispatch, useSelector} from 'react-redux'
import {AppState} from 'common/models'
import {DnsServiceName} from './models'
import Button from 'antd-button-color'
import DnsServiceForm from 'pages/dns/service/DnsServiceForm'
import getDnsServices from './actions/getDnsServices'
import deleteDnsService from './actions/deleteDnsService'
import './DnsServicePage.scss'
import {ColumnsType} from "antd/lib/table";
import {useLoggedUser} from "../../../helpers/loginUserHelper";
import {LoadingIndicator} from "../../../components";
import ErrorPage403 from "../../../components/Errors/ErrorPage403";
import useLogger from "../../../common/useLogger";
import Draggable, {DraggableData, DraggableEvent} from "react-draggable";
import {
    DeleteTwoTone,
    EditTwoTone, ExclamationCircleOutlined,
    InfoCircleTwoTone,
    WarningTwoTone
} from "@ant-design/icons";
import {removeDiac, stopPropagation} from "../../../common/fce";
import {useHistory} from "react-router";
import {Link} from "react-router-dom";
import getDnsService from "./actions/getDnsService";
import clearDnsService from "./actions/clearDnsService";
import HistoryModal from "../../../components/History/HistoryModal";



const { confirm } = Modal


const DnsServicePage = () => {
    const CONTROL_NAME = 'page_dns_servers'
    const {t} = useTranslation()
    const history = useHistory()
    const dispatch = useDispatch()

    const {isLoading, isLoadingService, dnsService, dnsServiceNames} = useSelector((state: AppState) => state.dnsservice)
    const {dns_domains} = useSelector((state: AppState) => state.sidenav)

    const [dataSource, setDataSource] = useState<DnsServiceName[]>([])

    const [host, setHost] = useState('')
    const [selectedServiceId, setSelectedServiceId] = useState<number | undefined>(undefined)

    const [isDnsModalVisible, setDnsModalVisible] = useState<boolean>(false)
    const [isHistoryModalVisible, setHistoryModalVisible] = useState(false)
    const [historyTitle, setHistoryTitle] = useState('')
    const [historyModelId, setHistoryModelId] = useState<number | undefined>()
    const [openDelete, setOpenDelete] = useState(false)
    const [confirmDelete, setConfirmDelete] = useState(false)

    const [isDnsViewer, setDnsViewer] = useState(false)
    const [isDnsCreator, setDnsCreator] = useState(false)
    const [isDnsEditor, setDnsEditor] = useState(false)
    const [isDnsDeleter, setDnsDeleter] = useState(false)


// get settings and current user
    const loggedUser = useLoggedUser()
    if (!loggedUser || !loggedUser.isLoaded()) {
        // waiting..
        return (
            <div className="fullwidth-loading" >
                <LoadingIndicator/>
            </div>
        )
    }

    // required authorization
    if (!loggedUser.hasAccess(CONTROL_NAME)) {
        return <ErrorPage403 />
    }

    // settings
    const appSetting = loggedUser.getAppSettings()
    const SEARCH_MIN = appSetting.min_search_length

    // usage: logger(msg, obj=null)
    const logger = useLogger(appSetting, 'DnsServicePage')

    // history drag modal
    const [disabled, setDisabled] = useState(true)
    const draggleRef = useRef<HTMLDivElement>(null)
    const [bounds, setBounds] = useState({ left: 0, top: 0, bottom: 0, right: 0 })
    const onStart = (_event: DraggableEvent, uiData: DraggableData) => {
        const { clientWidth, clientHeight } = window.document.documentElement;
        const targetRect = draggleRef.current?.getBoundingClientRect();
        if (!targetRect) {
            return;
        }
        setBounds({
            left: -targetRect.left + uiData.x,
            right: clientWidth - (targetRect.right - uiData.x),
            top: -targetRect.top + uiData.y,
            bottom: clientHeight - (targetRect.bottom - uiData.y),
        });
    }

    useEffect(() => {
        // trigger ONCE
        const access = loggedUser.hasAccess('page_dns_servers')
        setDnsViewer(access)
        setDnsCreator(loggedUser.hasAccess('page_dns_servers_create_button'))
        setDnsEditor(loggedUser.hasAccess('page_dns_servers_edit_button'))
        setDnsDeleter(loggedUser.hasAccess('page_dns_servers_delete_button'))
        if (!access) {
            // failover 403
            message.error('No permissions!')
            history.replace('/')
            return
        }

        if (!isLoading && dnsServiceNames.length === 0) {
            dispatch(getDnsServices())
        }
    }, [])

    useEffect(() => {
        // trigger from Side menu clicked
        if (!isLoading) {
            // reload
            dispatch(getDnsServices())
        }
    }, [dns_domains])

    useEffect(() => {
        refreshGrid()
    }, [dnsServiceNames, host])

    const refreshGrid = () => {
        logger('refreshGrid')
        setDataSource(filtered())
    }

    const filtered = () => {
        // search for pattern
        let qs: string[] = []
        let data = dnsServiceNames
        if (host && host.length > SEARCH_MIN) {
            data = data.filter((s) => s.name && removeDiac(s.name).includes(removeDiac(host)))
            qs.push('host=' + removeDiac(host))
        }

        history.replace('/dns/services?' + qs.join("&"))
        logger(`filtered sourceFull: ${dataSource.length}, dataSource: ${data.length}`)
        return data
    }

    const handleCreate = () => {
        setSelectedServiceId(undefined)
        dispatch(clearDnsService())
        setDnsModalVisible(true)
    }

    const isNewService = () => {
        return !(dnsService && dnsService.id > 0)
    }


    const showConfirmDelete = (s: DnsServiceName, title: string) => {
        if (!s) {
            return
        }

        confirm({
                icon: <ExclamationCircleOutlined style={{color: 'red'}} />,
                title: title,
                content: <p>{s.name}</p>,
                okText: t('general.yes'),
                cancelText: t('general.cancel'),
                okButtonProps: { loading: confirmDelete },
                className: 'confirm-alert',
                onOk() {
                    setConfirmDelete(true)
                    dispatch(deleteDnsService(s.id,suc => {
                        setConfirmDelete(false)
                        setOpenDelete(false)
                        if (suc) {
                            message.success(t('general.success'))
                        }
                    }))
                },
                onCancel() {
                    setOpenDelete(false)
                },
            }
        )
    }

    const FilterByHost = (
        <AutoComplete
            placeholder={t('dnsPage.host')}
            style={{ width: '200px' }}
            value={host}
            allowClear={true}
            onClick={stopPropagation}
            onKeyDown={(e) => {if (e.key === 'Enter') {e.stopPropagation()}}}
            onChange={(v) => { setHost(v) }}
        />
    )

    const columns: ColumnsType<DnsServiceName> = [
        {
            title: FilterByHost,
            dataIndex: 'name',
            key: 'name',
            align: 'left',
            fixed: 'left',
            render: (name: string, rec: DnsServiceName) => (
                <Link to={`/dns/services/${rec.id}`}>{name}</Link>
            )
        },
        {
            title: (<div className='center'>Action</div>),
            key: 'action',
            dataIndex: 'action',
            width: '140px',
            align: 'center',
            render: (text: string, record: DnsServiceName) => (
                <Space size={1}  onClick={(e) => e.stopPropagation()}>

                    <Button title={t('general.update')}
                            disabled={!isDnsEditor}
                            type='text' size='small'
                            onClick={() => {
                                setSelectedServiceId(record.id)
                                dispatch(getDnsService(record.id))
                                setDnsModalVisible(true)
                            }}
                            className='actionButton'
                            icon={<EditTwoTone twoToneColor={isDnsEditor ? "green" : "#ccc"} />}
                    />

                    <Button title={t('general.delete')}
                            disabled={!isDnsDeleter}
                            type='text' danger size='small'
                            className='actionButton'
                            onClick={() => showConfirmDelete(record, t('dnsPage.confirm_delete'))}
                            icon={<DeleteTwoTone twoToneColor={isDnsDeleter ? "red" : "#ccc"}/>}
                    />

                    <Button title={t('general.btnHistory')} size='small'
                            onClick={() => {
                                setHistoryModelId(record.id)
                                setHistoryTitle(record.name)
                                setHistoryModalVisible(true)
                            }}
                            icon={<InfoCircleTwoTone/>}
                            className='actionButton'
                    />
                </Space>
            ),
        }
    ]

    if (!appSetting || Object.keys(appSetting).length === 0) {
        return (<Spin/>)
    }

    return (
        <>
            <Card
                className='DnsServicePage'
                title={<>
                    <Row>
                        <Col span={12}><GlobalOutlined/> &nbsp;{t('dnsPage.title')}</Col>
                        <Col span={12}>

                        </Col>
                    </Row>
                </>}
                extra={
                    <Button type='primary'
                            disabled={!isDnsCreator}
                            onClick={handleCreate}>
                        <PlusCircleOutlined/> {t('dnsPage.create_button')}
                    </Button>
                }
            >
                <Table<DnsServiceName>
                    dataSource={dataSource}
                    rowKey='id'
                    columns={columns}
                    loading={isLoadingService}
                    className='dnsServicesTable'
                    showHeader={true}
                    size='small'
                    style={{whiteSpace: 'pre'}}
                    scroll={{ x: 680 }}
                    bordered={true}
                    pagination={false}
                />

            </Card>

            <Modal title={
                       <div style={{width: '100%', cursor: 'move'}}
                            onMouseOver={() => {if (disabled) { setDisabled(false)}}}
                            onMouseOut={() => {setDisabled(true)}}
                            onFocus={() => {}}
                            onBlur={() => {}}
                       >
                           <GlobalOutlined/> &nbsp; {!isNewService() ? <>{t('dnsPage.update_title')}</> : <>{t('dnsPage.create_title')}</>}
                       </div>
                   }
                destroyOnClose
                style={{top: 20}}
                width={600}
                visible={isDnsModalVisible}
                onCancel={() => setDnsModalVisible(false)}
                   modalRender={(modal) => (
                       <Draggable disabled={disabled} bounds={bounds} onStart={(ev, data) => onStart(ev, data)}>
                           <div ref={draggleRef}>{modal}</div>
                       </Draggable>
                   )}
                footer={null}
                confirmLoading={true}
            >
                <DnsServiceForm serviceId={selectedServiceId} onClose={() => setDnsModalVisible(false)}/>
            </Modal>

            <HistoryModal service='dns' model='DnsService'
                          modelId={historyModelId}
                          title={historyTitle}
                          isModalVisible={isHistoryModalVisible}
                          setModalVisible={() => setHistoryModalVisible(false)}
                          modalRender={(modal) => (
                              <Draggable bounds={bounds} onStart={(ev, data) => onStart(ev, data)}>
                                  <div ref={draggleRef}>{modal}</div>
                              </Draggable>
                          )}
            />
        </>
    )
}

export default DnsServicePage
