import {Form, Input, message, Select, Spin, Switch} from "antd";
import {useForm} from "antd/lib/form/Form";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {useLoggedUser} from "../../helpers/loginUserHelper";
import React, {useEffect, useState} from "react";
import {formItemLayout, tailLayout} from "../../helpers/layoutHelpers";
import {AppState} from "../../common/models";
import {Store} from "antd/lib/form/interface";
import {UpdateDbPasswordParams} from "./models";
import Button from "antd-button-color";
import setDbPassword from "./actions/setDbPassword";
import getDbPassword from "./actions/getDbPassword";


interface Props {
    onClose: (pwd: string) => void
}


const DbPasswordForm = (props: Props) => {
    const [form] = useForm()
    const {t} = useTranslation()
    const dispatch = useDispatch()

    const {isSavingDatabase, database} = useSelector((state: AppState) => state.rdbservice)
    const [updateStatus, setUpdateStatus] = useState<boolean>(false)


    // get settings and logged user from store
    const loggedUser = useLoggedUser()
    if (!loggedUser || !loggedUser.isLoaded()) {
        return (<Spin/>)
    }
    const appSetting = loggedUser.getAppSettings()

    useEffect(() => {
        // once

    }, [])

    const onFormSubmit = (values: Store) => {
        if (database && database.id) {
            const params: UpdateDbPasswordParams = {
                id: database.id,
                password: values.password
            }
            dispatch(setDbPassword(params, (suc) => {
                if (suc) {
                    dispatch(getDbPassword(database.id))
                    message.success(t('general.success'))
                    props.onClose(params.password)
                }
            }))
        }
    }

    if (!appSetting || Object.keys(appSetting).length === 0) {
        return (<Spin />)
    }

    if (!database || !database.id) {
        return (<Spin />)
    }

    return (
        <Form
            onFinish={onFormSubmit}
            form={form}
            onChange={() => setUpdateStatus(true)}
            initialValues={{password: '', database_name: database.name}}
            {...formItemLayout}
        >

            <Form.Item label={t('databasesPage.database')} name='database_name'>
                <Input disabled />
            </Form.Item>

            <Form.Item label={t('databasesPage.password')} rules={[{required: true}]} name='password'>
                <Input />
            </Form.Item>

            <Form.Item {...tailLayout}>
                <Button type='primary'
                        loading={isSavingDatabase}
                        disabled={!updateStatus}
                        htmlType='submit'>
                    {t('general.set')}
                </Button>
            </Form.Item>
        </Form>
    )
}

export default DbPasswordForm
