import React, {useState, useEffect} from 'react'
import {formItemLayout, tailLayout} from 'helpers/layoutHelpers'
import {useForm} from 'antd/lib/form/Form'
import {Form, Input, Switch, Button, Select, message, Spin} from 'antd'
import {CheckOutlined, CloseOutlined, MailOutlined} from '@ant-design/icons'
import {AiOutlineNumber} from 'react-icons/ai'
import {FiPhoneCall} from 'react-icons/fi'
import {RiStickyNoteLine} from 'react-icons/ri'
import {useTranslation} from 'react-i18next'
import {useDispatch, useSelector} from 'react-redux'
import {AppState} from 'common/models'
import {FaRegAddressBook} from 'react-icons/fa'
import {GiInjustice} from 'react-icons/gi'
import {protectedApiClient} from 'helpers/api'
import {CompanyDetails, CompanyParams} from 'pages/company/models'
import {VALIDATE_STATUS_ENUM} from 'common/enums'
import getCompany from "./actions/getCompany";
import updateCompany from "./actions/updateCompany";
import createCompany from "./actions/createCompany";
import {useLoggedUser} from "../../helpers/loginUserHelper";
import {_parsePhone, _renderPhone} from "../../common/fce";
import {euPhoneNumberRegex} from "../../helpers/stringHelpers";
import tableCompanies from "../login/actions/tableCompanies";

interface Props {
    setModalVisible: (isShow: boolean) => void
    existCustomer: (companyId: number) => boolean
}

const REGEX_COMPANY_NUMBER = /^[0-9]{1,8}$/g

const isCzech = (country: string): boolean => {
    return country === 'Czechia' || country === 'CZ' || country === 'CZE'
}

const emptyAresData = {
    name: '',
    vat_number: '',
    street: '',
    city: '',
    zip: '',
    vat_payer: '',
    file_number: ''
}

const {Item} = Form

const CompanyForm = ({setModalVisible, existCustomer}: Props) => {
    const [form] = useForm()
    const dispatch = useDispatch()
    const [validationStatus, setValidationStatus] = useState<VALIDATE_STATUS_ENUM>(VALIDATE_STATUS_ENUM.SUCCESS)
    const [validationMessage, setValidationMessage] = useState<string>('')
    const {fontSize} = useSelector((state: AppState) => state.font)
    const {countries} = useSelector((state: AppState) => state.countries)
    const {companies, company, isLoading, isSaving} = useSelector((state: AppState) => state.company)
    const {t} = useTranslation()
    const [vatNumRequire, setVatNumRequire] = useState<boolean>(false)
    const [dataToUpdate, setDataToUpdate] = useState<CompanyDetails | undefined>(undefined)
    const [updateStatus, setUpdateStatus] = useState<boolean>(false)

    // get settings and logged user from store
    const loggedUser = useLoggedUser()
    if (!loggedUser || !loggedUser.isLoaded()) {
        return (<Spin />)
    }
    const appSetting = loggedUser.getAppSettings()

    useEffect(() => {
        if (company) {
            // product was fetched from DB
            setDataToUpdate({...company,
                vat_payer:Boolean(company.vat_payer),
                phone: _renderPhone(company.phone)
            })
            setVatNumRequire(Boolean(company.vat_payer))
        }
    }, [company]);

    useEffect( () => {
        // update values to form
        form.setFieldsValue(dataToUpdate)
    }, [dataToUpdate])

    const callAres = (cid: string) => {
        protectedApiClient
            .get(`/company/company/from-ares?id_number=${cid}`)
            .then((response: any) => {
                if (response.status === 200) {
                    const data = response.data
                    const aresData = {
                        name: data.name,
                        vat_number: data.vat_number,
                        street: data.street,
                        city: data.city,
                        zip: data.zip,
                        vat_payer: data.vat_payer,
                        file_number: data.file_number
                    }
                    form.setFieldsValue(aresData)
                    setVatNumRequire(Boolean(data.vat_payer))
                    setValidationStatus(VALIDATE_STATUS_ENUM.SUCCESS)
                    setUpdateStatus(true)
                }
                else {
                    setValidationStatus(VALIDATE_STATUS_ENUM.ERROR)
                    setValidationMessage('not_found_co_number')
                    form.setFieldsValue(emptyAresData)
                    setVatNumRequire(false)
                }
            })
            .catch((error) => {
                setValidationStatus(VALIDATE_STATUS_ENUM.ERROR)
                setValidationMessage('not_found_co_number2')
                form.setFieldsValue(emptyAresData)
                setVatNumRequire(false)
            })
    }

    const fetchCompanyId = (e) => {
        const comp_number = form.getFieldValue('company_number')
        const name = form.getFieldValue('name')
        // handle onClick ARES
        if (!companies || companies.items.length === 0) {
            // skip
            return
        }
        if (!isCzech(form.getFieldValue('country'))) {
            // NOT CZECH - dont verify
            return
        }

        if (!comp_number) {
            setValidationStatus(VALIDATE_STATUS_ENUM.ERROR)
            setValidationMessage('pattern_co_number')
            return
        }

        if (comp_number.length < 4) {
            // too short
            if (!dataToUpdate?.company_number) {
                setValidationStatus(VALIDATE_STATUS_ENUM.VALIDATION)
            }
            else {
                setValidationStatus(VALIDATE_STATUS_ENUM.ERROR)
                setValidationMessage('short_co_number')
            }
            return
        }

        // search in our DB
        if (comp_number && name && name.length > 3) {
            // try call local DB
            const existingCompany = companies.items.find(item => item.company_number === comp_number)
            if (existingCompany) {
                dispatch(getCompany(existingCompany.id))
                setValidationStatus(VALIDATE_STATUS_ENUM.SUCCESS)
                setValidationMessage('')
            }
        }

        // ask ARES
        callAres(comp_number)
    }

    const validateCompanyNumber = (comp_number: string) => {
        if (!isCzech(form.getFieldValue('country'))) {
            // NOT CZECH - dont verify
            return
        }

        if (!comp_number) {
            setValidationStatus(VALIDATE_STATUS_ENUM.SUCCESS)
            return;
        }
        if (comp_number.length < 4) {
            // too short
            setValidationStatus(VALIDATE_STATUS_ENUM.ERROR)
            setValidationMessage('short_co_number')
            return
        }

        const regex = new RegExp(REGEX_COMPANY_NUMBER)
        if (!regex.test(comp_number)) {
            setValidationStatus(VALIDATE_STATUS_ENUM.ERROR)
            setValidationMessage('pattern_co_number')
            return
        }
        setValidationStatus(VALIDATE_STATUS_ENUM.WARNING)
    }

    const onFinish = (values: CompanyParams): void => {
        dataToUpdate
            ? dispatch(
                updateCompany(
                    {
                        ...values,
                        phone: _parsePhone(values.phone),
                        vat_payer: Number(values.vat_payer),
                    },
                    (isSuccess: boolean) => {
                        if (isSuccess) {
                            dispatch(tableCompanies())
                            message.success(
                                <span dangerouslySetInnerHTML={{__html: t('companiesPage.updated', {name: values.name})}}/>,
                            )
                            setModalVisible(false)
                        }
                    },
                ),
            )
            : dispatch(
                createCompany(
                    {
                        ...values,
                        phone: _parsePhone(values.phone),
                        vat_payer: values.vat_payer ? 1 : 0,
                    },
                    (isSuccess: boolean) => {
                        if (isSuccess) {
                            dispatch(tableCompanies())
                            message.success(
                                <span dangerouslySetInnerHTML={{__html: t('companiesPage.created', {name: values.name})}}/>,
                            )
                            setModalVisible(false)
                        }
                    },
                ),
            )
    }

    return (
        <Form
            form={form}
            {...formItemLayout}
            name='create-company'
            className='CompanyForm'
            onFinish={onFinish}
            onChange={() => setUpdateStatus(true)}
            initialValues={{...dataToUpdate, country: 'CZ'}}>

            {dataToUpdate && dataToUpdate.id && (
                <Item name='id' style={{display: 'none'}}>
                    <Input type='hidden'/>
                </Item>
            )}

            <Item
                name='company_number'
                rules={[
                    {
                        pattern: new RegExp('^[0-9]{4,8}$'),
                        message: 'Number length less than 8'
                    },
                ]}
                validateStatus={validationStatus}
                className={dataToUpdate && existCustomer(dataToUpdate.id)  ? 'isCustomer' : ''}
                label={t('companiesPage.ico')}
                help={(validationStatus === VALIDATE_STATUS_ENUM.ERROR) ? t('companiesPage.error.' + validationMessage) : null}
                hasFeedback={!Boolean(dataToUpdate && dataToUpdate.id)}>
                <Input
                    size={fontSize}
                    onChange={(e) => {
                        validateCompanyNumber(e.target.value)
                    }}
                    suffix={
                        <Button type='primary' className='company-id-form'
                                size={fontSize}
                                onClick={(event => {
                                    fetchCompanyId(event)
                                })}>{t('companiesPage.fetch_data')}
                        </Button>
                    }
                    autoFocus
                />
            </Item>

            <Item name='name'
                rules={[{required: true, message: t('companiesPage.error.name')}]}
                label={t('companiesPage.name')}
                hasFeedback>
                <Input
                    size={fontSize}
                    autoComplete='new-password'
                    disabled={validationStatus === VALIDATE_STATUS_ENUM.VALIDATION}
                />
            </Item>
            <Item name='vat_number'
                rules={[{required: vatNumRequire, min: 6, max: 14, message: t('companiesPage.error.dic')}]}
                label={t('companiesPage.dic')}
                hasFeedback>
                <Input
                    size={fontSize}
                    suffix={<AiOutlineNumber className='site-form-item-icon'/>}
                    disabled={validationStatus === VALIDATE_STATUS_ENUM.VALIDATION}
                />
            </Item>
            <Item name='street'
                label={t('companiesPage.address_1')}
                rules={[{required: true, message: t('companiesPage.error.address_1')}]}
                hasFeedback>
                <Input
                    size={fontSize}
                    suffix={<FaRegAddressBook className='site-form-item-icon'/>}
                    disabled={validationStatus === VALIDATE_STATUS_ENUM.VALIDATION}
                />
            </Item>
            <Item name='city'
                label={t('companiesPage.city')}
                rules={[{required: true, message: t('companiesPage.error.city')}]}
                hasFeedback>
                <Input
                    size={fontSize}
                    suffix={<FaRegAddressBook className='site-form-item-icon'/>}
                    disabled={validationStatus === VALIDATE_STATUS_ENUM.VALIDATION}
                />
            </Item>
            <Item name='zip'
                label={t('companiesPage.zip')}
                rules={[{required: true, message: t('companiesPage.error.zip')}]}
                hasFeedback>
                <Input
                    size={fontSize}
                    suffix={<FaRegAddressBook className='site-form-item-icon'/>}
                    disabled={validationStatus === VALIDATE_STATUS_ENUM.VALIDATION}
                />
            </Item>
            <Item name='country'
                label={t('companiesPage.country')}
                rules={[{required: true, message: t('companiesPage.error.country')}]}
                hasFeedback>
                <Select
                    showSearch
                    filterOption={true}
                    optionFilterProp='label'
                    onChange={() => setUpdateStatus(true)}
                    options={countries && countries.map((c) => ({
                        label: c.name_cz,
                        key: c.iso,
                        value: c.iso
                    }))}
                    size={fontSize}
                    loading={isLoading}
                    disabled={validationStatus === VALIDATE_STATUS_ENUM.VALIDATION}
                />
            </Item>
            <Item name='vat_payer'
                label={t('companiesPage.vat_payer')}
                valuePropName='checked'>
                <Switch
                    checkedChildren={<CheckOutlined/>}
                    unCheckedChildren={<CloseOutlined/>}
                    checked={vatNumRequire}
                    onChange={() => {setVatNumRequire(!vatNumRequire);setUpdateStatus(true)}}
                    disabled={validationStatus === VALIDATE_STATUS_ENUM.VALIDATION}
                />
            </Item>
            <Item name='file_number' label={t('companiesPage.file_number')}>
                <Input
                    size={fontSize}
                    suffix={<GiInjustice className='site-form-item-icon'/>}
                    disabled={validationStatus === VALIDATE_STATUS_ENUM.VALIDATION}
                />
            </Item>
            <Item name='email'
                  label={t('companiesPage.email')}
                  rules={[{
                      required: false, type: "email",
                      message: t('errors.error_email')
                  }]}
                  hasFeedback>
                <Input size={fontSize} suffix={<MailOutlined className='site-form-item-icon'/>}/>
            </Item>
            <Item name='phone'
                  label={t('companiesPage.phone')}
                  rules={[{
                      pattern: new RegExp(euPhoneNumberRegex),
                      message: t('errors.error_phone')
                  }]}
                  hasFeedback>
                <Input size={fontSize} suffix={<FiPhoneCall className='site-form-item-icon'/>}/>
            </Item>
            <Item name='note' label={t('companiesPage.note')}>
                <Input size={fontSize} suffix={<RiStickyNoteLine className='site-form-item-icon'/>}/>
            </Item>

            <Item {...tailLayout}>
                <Button type='primary' htmlType='submit' className='login-form-button' size={fontSize} loading={isSaving}
                        disabled={validationStatus === VALIDATE_STATUS_ENUM.VALIDATION || !updateStatus}>
                    {(dataToUpdate && dataToUpdate.id) ? t('companiesPage.update') : t('companiesPage.create')}
                </Button>
            </Item>
        </Form>
    )
}


export default CompanyForm
