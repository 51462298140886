import {ModelName, NameItem, Pagination} from "../../common/models";


export interface CertificateGrid {
	items: CertificateModel[]
	pagination: Pagination
}

export interface CertificateState {
	isSaving?: boolean
	isLoading?: boolean
	certificate?: CertificateModel
	certificates: CertificateGrid
	pager: Pagination
	lookup_name: string[]
	isLoadingLookup: boolean
	vhosts?: [{[key: string]: CertificateModel[]}]
	error?: string
}

export interface CertificateModel {
	id: number
	customer_id: number
	customer_name?: string
	authority?: string
	is_le?: number
	name: string
	subjects?: string
	challenge: string
	key?: string
	crt?: string
	ca_crt?: string
	checksum?: string
	auto_prolong?: number
	prolong_before_days?: number
	days_to_expire?: number
	valid_from?: number
	valid_to?: number
	updated_at?: number
	comment?: string
	usages?: ModelName[]
}

export interface GenCertificateParams {
	customer_id: number
	domain: string
	wildcard?: number
	www?: number
}

export interface CreateCertificateParams {
	customer_id: number
	name: string
	challenge: CHALLENGE_ENUM
	key: string
	crt: string
	ca_crt: string
	auto_prolong?: number
	prolong_before_days?: number
	comment?: string
}

export interface UploadCertificateParams {
	customer_id: number
	name: string
	challenge: CHALLENGE_ENUM
	content: string
	auto_prolong?: number
	prolong_before_days?: number
	comment?: string
}

export interface UpdateCertificateParams {
	id: number
	name?: string
	challenge?: CHALLENGE_ENUM
	auto_prolong?: number
	prolong_before_days?: number
	comment?: string
}

export enum CHALLENGE_ENUM {
	CHALLENGE_MANUAL = 'manual',
	CHALLENGE_DNS = 'dns-01',
	CHALLENGE_HTTP = 'http-01',
}

export interface DeleteCertificateParams {
	id: number
}

export interface AddRemoveToServerCertificateParams {
	certificate_id: number
	server_id: number
}

export interface CertificateError {
	message: string
}

export interface ToggleCertificatesParams {
	certificate_id: number
	vhost_id: number
}
