import {ActionType, AppSettingsActions} from './actions'
import { AsyncActionMode } from 'common/models'
import {SettingsDetails, SettingsState} from './models'


const APP_SETTINGS_INITIAL_STATE: SettingsState = {
    isLoading: false,
    isSaving: false,
    error: undefined,
    settings: []
}


export default (state = APP_SETTINGS_INITIAL_STATE, action: AppSettingsActions): SettingsState => {
    switch (action.type) {
        case ActionType.GET_APP_SETTING:
            if (action.mode === AsyncActionMode.REQUEST) {
                return { ...state, isLoading: false, setting: undefined }
            }
            if (action.mode === AsyncActionMode.RESPONSE) {
                return {
                    ...state,
                    error: undefined,
                    setting: action.data,
                }
            }
            if (action.mode === AsyncActionMode.ERROR) {
                return {
                    ...state,
                    error: action.error,
                }
            }
            break

        case ActionType.GET_APP_SETTINGS2:
            if (action.mode === AsyncActionMode.REQUEST) {
                return { ...state, isLoading: false }
            }
            if (action.mode === AsyncActionMode.RESPONSE) {
                return {
                    ...state,
                    error: undefined,
                    settings: action.data,
                }
            }
            if (action.mode === AsyncActionMode.ERROR) {
                return {
                    ...state,
                    error: action.error,
                }
            }
            break

        case ActionType.UPDATE_APP_SETTING2:
            if (action.mode === AsyncActionMode.REQUEST) {
                return { ...state, isSaving: true }
            }
            if (action.mode === AsyncActionMode.RESPONSE) {
                return {
                    ...state,
                    isSaving: false,
                    settings: state.settings.map((s) =>
                        s.id === action.data.id ? action.data : s,
                    )
                }
            }
            if (action.mode === AsyncActionMode.ERROR) {
                return {
                    ...state,
                    isSaving: false,
                    error: action.error,
                }
            }
            break
    }
    return state
}
