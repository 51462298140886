import { AsyncActionMode } from 'common/models'
import {
	CreateCertificateParams,
	CertificateModel,
	CertificateGrid,
	UpdateCertificateParams,
	ToggleCertificatesParams,
	GenCertificateParams,
	UploadCertificateParams,
} from '../models'

export enum ActionType {
	CREATE_CERTIFICATE = 'CREATE_CERTIFICATE',
	UPLOAD_CERTIFICATE = 'UPLOAD_CERTIFICATE',
	GEN_CERTIFICATE = 'GEN_CERTIFICATE',
	PROLONG_CERTIFICATE = 'PROLONG_CERTIFICATE',
	GET_CERTIFICATES = 'GET_CERTIFICATES',
	GET_CERTIFICATES_BY_VHOST = 'GET_CERTIFICATES_BY_VHOST',
	GET_CERTIFICATE = 'GET_CERTIFICATE',
	GET_CERTIFICATE_SECURE = 'GET_CERTIFICATE_SECURE',
	UPDATE_CERTIFICATE = 'UPDATE_CERTIFICATE',
	DELETE_CERTIFICATE = 'DELETE_CERTIFICATE',
	ADD_CERTIFICATE_TO_VHOST = 'ADD_CERTIFICATE_TO_VHOST',
	REMOVE_CERTIFICATE_FROM_VHOST = 'REMOVE_CERTIFICATE_FROM_VHOST',
	LOOKUP_CERTIFICATE = 'LOOKUP_CERTIFICATE',
}

export type CertificateActions =
	| CreateCertificateRequestAction
	| CreateCertificateResponseAction
	| CreateCertificateErrorAction
	| UploadCertificateRequestAction
	| UploadCertificateResponseAction
	| UploadCertificateErrorAction
	| GenCertificateRequestAction
	| GenCertificateResponseAction
	| GenCertificateErrorAction
	| ProlongCertificateRequestAction
	| ProlongCertificateResponseAction
	| ProlongCertificateErrorAction
	| GetCertificatesRequestAction
	| GetCertificatesResponseAction
	| GetCertificatesErrorAction
	| GetCertificateRequestAction
	| GetCertificateResponseAction
	| GetCertificateErrorAction
	| GetCertificateSecureRequestAction
	| GetCertificateSecureResponseAction
	| GetCertificateSecureErrorAction
	| UpdateCertificateRequestAction
	| UpdateCertificateResponseAction
	| UpdateCertificateErrorAction
	| DeleteCertificateRequestAction
	| DeleteCertificateResponseAction
	| DeleteCertificateErrorAction
	| GetCertificatesByVhostRequestAction
	| GetCertificatesByVhostResponseAction
	| GetCertificatesByVhostErrorAction
	| AddCertificatesToVhostRequestAction
	| AddCertificatesToVhostResponseAction
	| AddCertificatesToVhostErrorAction
	| RemoveCertificatesFromVhostRequestAction
	| RemoveCertificatesFromVhostResponseAction
	| RemoveCertificatesFromVhostErrorAction
	| LookupCertificateRequestAction
	| LookupCertificateResponseAction
	| LookupCertificateErrorAction

export class RemoveCertificatesFromVhostRequestAction {
	readonly type = ActionType.REMOVE_CERTIFICATE_FROM_VHOST
	readonly mode = AsyncActionMode.REQUEST
	constructor(public payload: ToggleCertificatesParams) {
		''
	}
}
export class RemoveCertificatesFromVhostResponseAction {
	readonly type = ActionType.REMOVE_CERTIFICATE_FROM_VHOST
	readonly mode = AsyncActionMode.RESPONSE
	constructor(public request: RemoveCertificatesFromVhostRequestAction, public data: CertificateModel) {}
}
export class RemoveCertificatesFromVhostErrorAction {
	readonly type = ActionType.REMOVE_CERTIFICATE_FROM_VHOST
	readonly mode = AsyncActionMode.ERROR
	constructor(public request: RemoveCertificatesFromVhostRequestAction, public error: string) {}
}

export class AddCertificatesToVhostRequestAction {
	readonly type = ActionType.ADD_CERTIFICATE_TO_VHOST
	readonly mode = AsyncActionMode.REQUEST
	constructor(public payload: ToggleCertificatesParams) {
		''
	}
}
export class AddCertificatesToVhostResponseAction {
	readonly type = ActionType.ADD_CERTIFICATE_TO_VHOST
	readonly mode = AsyncActionMode.RESPONSE
	constructor(public request: AddCertificatesToVhostRequestAction, public data: CertificateModel) {}
}
export class AddCertificatesToVhostErrorAction {
	readonly type = ActionType.ADD_CERTIFICATE_TO_VHOST
	readonly mode = AsyncActionMode.ERROR
	constructor(public request: AddCertificatesToVhostRequestAction, public error: string) {}
}

export class GetCertificatesByVhostRequestAction {
	readonly type = ActionType.GET_CERTIFICATES_BY_VHOST
	readonly mode = AsyncActionMode.REQUEST
	constructor(public payload: number) {
		''
	}
}
export class GetCertificatesByVhostResponseAction {
	readonly type = ActionType.GET_CERTIFICATES_BY_VHOST
	readonly mode = AsyncActionMode.RESPONSE
	constructor(public request: GetCertificatesByVhostRequestAction, public data: CertificateModel[]) {}
}
export class GetCertificatesByVhostErrorAction {
	readonly type = ActionType.GET_CERTIFICATES_BY_VHOST
	readonly mode = AsyncActionMode.ERROR
	constructor(public request: GetCertificatesByVhostRequestAction, public error: string) {}
}

export class CreateCertificateRequestAction {
	readonly type = ActionType.CREATE_CERTIFICATE
	readonly mode = AsyncActionMode.REQUEST
	constructor(public payload: CreateCertificateParams) {}
}
export class CreateCertificateResponseAction {
	readonly type = ActionType.CREATE_CERTIFICATE
	readonly mode = AsyncActionMode.RESPONSE
	constructor(public request: CreateCertificateRequestAction, public data: CertificateModel) {}
}
export class CreateCertificateErrorAction {
	readonly type = ActionType.CREATE_CERTIFICATE
	readonly mode = AsyncActionMode.ERROR
	constructor(public request: CreateCertificateRequestAction, public error: string) {}
}

export class UploadCertificateRequestAction {
	readonly type = ActionType.UPLOAD_CERTIFICATE
	readonly mode = AsyncActionMode.REQUEST
	constructor(public payload: UploadCertificateParams) {}
}
export class UploadCertificateResponseAction {
	readonly type = ActionType.UPLOAD_CERTIFICATE
	readonly mode = AsyncActionMode.RESPONSE
	constructor(public request: UploadCertificateRequestAction, public data: CertificateModel) {}
}
export class UploadCertificateErrorAction {
	readonly type = ActionType.UPLOAD_CERTIFICATE
	readonly mode = AsyncActionMode.ERROR
	constructor(public request: UploadCertificateRequestAction, public error: string) {}
}

export class GenCertificateRequestAction {
	readonly type = ActionType.GEN_CERTIFICATE
	readonly mode = AsyncActionMode.REQUEST
	constructor(public payload: GenCertificateParams) {}
}
export class GenCertificateResponseAction {
	readonly type = ActionType.GEN_CERTIFICATE
	readonly mode = AsyncActionMode.RESPONSE
	constructor(public request: GenCertificateRequestAction, public data: string) {}
}
export class GenCertificateErrorAction {
	readonly type = ActionType.GEN_CERTIFICATE
	readonly mode = AsyncActionMode.ERROR
	constructor(public request: GenCertificateRequestAction, public error: string) {}
}

export class ProlongCertificateRequestAction {
	readonly type = ActionType.PROLONG_CERTIFICATE
	readonly mode = AsyncActionMode.REQUEST
	constructor(public id: number) {
		''
	}
}
export class ProlongCertificateResponseAction {
	readonly type = ActionType.PROLONG_CERTIFICATE
	readonly mode = AsyncActionMode.RESPONSE
	constructor(public request: ProlongCertificateRequestAction, public data: CertificateModel) {}
}
export class ProlongCertificateErrorAction {
	readonly type = ActionType.PROLONG_CERTIFICATE
	readonly mode = AsyncActionMode.ERROR
	constructor(public request: ProlongCertificateRequestAction, public error: string) {}
}
export class GetCertificateRequestAction {
	readonly type = ActionType.GET_CERTIFICATE
	readonly mode = AsyncActionMode.REQUEST
	constructor(public payload: number) {
		''
	}
}
export class GetCertificateResponseAction {
	readonly type = ActionType.GET_CERTIFICATE
	readonly mode = AsyncActionMode.RESPONSE
	constructor(public request: GetCertificateRequestAction, public data: CertificateModel) {}
}
export class GetCertificateErrorAction {
	readonly type = ActionType.GET_CERTIFICATE
	readonly mode = AsyncActionMode.ERROR
	constructor(public request: GetCertificateRequestAction, public error: string) {}
}

export class GetCertificateSecureRequestAction {
	readonly type = ActionType.GET_CERTIFICATE_SECURE
	readonly mode = AsyncActionMode.REQUEST
	constructor(public payload: number) {
		''
	}
}
export class GetCertificateSecureResponseAction {
	readonly type = ActionType.GET_CERTIFICATE_SECURE
	readonly mode = AsyncActionMode.RESPONSE
	constructor(public request: GetCertificateSecureRequestAction, public data: CertificateModel) {}
}
export class GetCertificateSecureErrorAction {
	readonly type = ActionType.GET_CERTIFICATE_SECURE
	readonly mode = AsyncActionMode.ERROR
	constructor(public request: GetCertificateSecureRequestAction, public error: string) {}
}

export class GetCertificatesRequestAction {
	readonly type = ActionType.GET_CERTIFICATES
	readonly mode = AsyncActionMode.REQUEST
	constructor() {
		''
	}
}
export class GetCertificatesResponseAction {
	readonly type = ActionType.GET_CERTIFICATES
	readonly mode = AsyncActionMode.RESPONSE
	constructor(public request: GetCertificatesRequestAction, public data: CertificateGrid) {}
}
export class GetCertificatesErrorAction {
	readonly type = ActionType.GET_CERTIFICATES
	readonly mode = AsyncActionMode.ERROR
	constructor(public request: GetCertificatesRequestAction, public error: string) {}
}

export class UpdateCertificateRequestAction {
	readonly type = ActionType.UPDATE_CERTIFICATE
	readonly mode = AsyncActionMode.REQUEST
	constructor(public params: UpdateCertificateParams) {}
}
export class UpdateCertificateResponseAction {
	readonly type = ActionType.UPDATE_CERTIFICATE
	readonly mode = AsyncActionMode.RESPONSE
	constructor(public request: UpdateCertificateRequestAction, public data: CertificateModel) {}
}
export class UpdateCertificateErrorAction {
	readonly type = ActionType.UPDATE_CERTIFICATE
	readonly mode = AsyncActionMode.ERROR
	constructor(public request: UpdateCertificateRequestAction, public error: string) {}
}

export class DeleteCertificateRequestAction {
	readonly type = ActionType.DELETE_CERTIFICATE
	readonly mode = AsyncActionMode.REQUEST
	constructor(public id: number) {
		''
	}
}
export class DeleteCertificateResponseAction {
	readonly type = ActionType.DELETE_CERTIFICATE
	readonly mode = AsyncActionMode.RESPONSE
	constructor(public request: DeleteCertificateRequestAction, public data: CertificateModel) {}
}
export class DeleteCertificateErrorAction {
	readonly type = ActionType.DELETE_CERTIFICATE
	readonly mode = AsyncActionMode.ERROR
	constructor(public request: DeleteCertificateRequestAction, public error: string) {}
}

export class LookupCertificateRequestAction {
	readonly type = ActionType.LOOKUP_CERTIFICATE
	readonly mode = AsyncActionMode.REQUEST
	constructor(public field: string) {
		''
	}
}
export class LookupCertificateResponseAction {
	readonly type = ActionType.LOOKUP_CERTIFICATE
	readonly mode = AsyncActionMode.RESPONSE
	constructor(public request: LookupCertificateRequestAction, public field: string, public data: string[]) {}
}
export class LookupCertificateErrorAction {
	readonly type = ActionType.LOOKUP_CERTIFICATE
	readonly mode = AsyncActionMode.ERROR
	constructor(public request: LookupCertificateRequestAction, public error: string) {}
}
