import { GetWebServiceByNameRequestAction, GetWebServiceByNameResponseAction, GetWebServiceByNameErrorAction } from '.'
import { protectedApiClient } from 'helpers/api'
import { handleApiErrorWithNotification } from 'helpers/errorHandling'
import { WebServiceModel } from '../models'

export default (name: string, cb?: (isSuccess: boolean) => void) => {
    return (
        dispatch: (
            arg: GetWebServiceByNameRequestAction | GetWebServiceByNameResponseAction | GetWebServiceByNameErrorAction,
        ) => void,
    ) => {
        const request = new GetWebServiceByNameRequestAction(name)
        dispatch(request)

        protectedApiClient
            .get<WebServiceModel[]>(`/web/service?host=${name}`)
            .then((response) => {
                dispatch(new GetWebServiceByNameResponseAction(request, response.data))
                cb && cb(true)
            })
            .catch((error) => {
                dispatch(new GetWebServiceByNameErrorAction(request, error.toString()))
                handleApiErrorWithNotification(error)
                cb && cb(false)
            })
    }
}
