import { ActionType, InventoryActions } from './actions'
import {AsyncActionMode, PAGE1} from 'common/models'
import {InventoryModel, InventoryPageState} from './models'
import {InventoryTypeModel} from "../type/models";


const INVENTORY_INITIAL_STATE: InventoryPageState = {
  isLoading: false,
  isSaving: false,
  isInventoryLoading: false,
  error: undefined,
  inventory: undefined,
  bill: undefined,
  bills: [],
  isLoadingBills: false,
  pager: PAGE1,
  inventories: {items: [], pagination: PAGE1},
  types: [],
  statuses: [],
  locations: [],
  usables: [],
  usables_inventories: [],
  inventoryNumber: '',
  ip_addresses: [],
  isLoadingIps: false,
  lookup_serial: [],
  lookup_name: [],
  lookup_inv_no: [],
  isLoadingLookup: false
}

export default (
  state = INVENTORY_INITIAL_STATE,
  action: InventoryActions,
): InventoryPageState => {

  function getInventories(types: InventoryTypeModel[]) {
    let data: InventoryModel[] = []
    for (const t of types) {
      if (t.inventory) {
        for (const i of t.inventory) {
          data.push(i)
        }
      }
    }
    return data
  }

  switch (action.type) {
    case ActionType.GET_INVENTORY:
      if (action.mode === AsyncActionMode.REQUEST) {
        return { ...state,
          isInventoryLoading: true }
      }
      if (action.mode === AsyncActionMode.RESPONSE) {
        return {
          ...state,
          inventory: action.data,
          isInventoryLoading: false
        }
      }
      if (action.mode === AsyncActionMode.ERROR) {
        return {
          ...state,
          isInventoryLoading: false,
          error: action.error
        }
      }
      break
    case ActionType.CLEAR_INVENTORY:
      if (action.mode === AsyncActionMode.RESPONSE) {
        return {
          ...state,
          inventory: undefined,
          inventoryNumber: '',
          isLoadingLookup: false
        }
      }
      break
    case ActionType.GET_INVENTORY_NUMBER:
      if (action.mode === AsyncActionMode.REQUEST) {
        return { ...state }
      }
      if (action.mode === AsyncActionMode.RESPONSE) {
        return {
          ...state,
          inventoryNumber: action.data.inv_no.toString(),
        }
      }
      if (action.mode === AsyncActionMode.ERROR) {
        return {
          ...state,
          error: action.error,
        }
      }
      break
    case ActionType.CREATE_INVENTORY:
      if (action.mode === AsyncActionMode.REQUEST) {
        return { ...state, isSaving: true }
      }
      if (action.mode === AsyncActionMode.RESPONSE) {
        return {
          ...state,
          inventories: {
            ...state.inventories,
            items: state.inventories.items.concat(action.data)
          },
          isSaving: false,
        }
      }
      if (action.mode === AsyncActionMode.ERROR) {
        return {
          ...state,
          isSaving: false,
          error: action.error,
        }
      }
      break
    case ActionType.GET_INVENTORIES:
      if (action.mode === AsyncActionMode.REQUEST) {
        return { ...state, isLoading: true }
      }
      if (action.mode === AsyncActionMode.RESPONSE) {
        return {
          ...state,
          isLoading: false,
          inventory: undefined,
          inventories: action.data,
          pager: {...action.data.pagination, page: action.data.pagination.page+1},
          isLoadingLookup: false
        }
      }
      if (action.mode === AsyncActionMode.ERROR) {
        return {
          ...state,
          isLoading: false,
          error: action.error,
        }
      }
      break
    case ActionType.GET_INVENTORY_PAGE_TYPES:
      if (action.mode === AsyncActionMode.REQUEST) {
        return { ...state }
      }
      if (action.mode === AsyncActionMode.RESPONSE) {
        return {
          ...state,
          types: action.data,
        }
      }
      if (action.mode === AsyncActionMode.ERROR) {
        return {
          ...state,
          error: action.error,
        }
      }
      break
    case ActionType.GET_INVENTORY_PAGE_LOCATIONS:
      if (action.mode === AsyncActionMode.REQUEST) {
        return { ...state }
      }
      if (action.mode === AsyncActionMode.RESPONSE) {
        return {
          ...state,
          locations: action.data,
        }
      }
      if (action.mode === AsyncActionMode.ERROR) {
        return {
          ...state,
          error: action.error,
        }
      }
      break
    case ActionType.GET_INVENTORY_BILLS:
      if (action.mode === AsyncActionMode.REQUEST) {
        return { ...state,
          isLoadingBills: true,
          bills:[]
        }
      }
      if (action.mode === AsyncActionMode.RESPONSE) {
        return {
          ...state,
          bills: action.data,
          isLoadingBills: false
        }
      }
      if (action.mode === AsyncActionMode.ERROR) {
        return {
          ...state,
          error: action.error,
          isLoadingLookup: false
        }
      }
      break
    case ActionType.LOOKUP_INVENTORY:
      if (action.mode === AsyncActionMode.REQUEST) {
        return { ...state,
          isLoadingLookup: true,
          lookup_serial:[]
        }
      }
      if (action.mode === AsyncActionMode.RESPONSE) {
        if (action.field === 'serial') {
          return {
            ...state,
            lookup_serial: action.data,
            isLoadingLookup: false
          }
        }
        if (action.field === 'name') {
          return {
            ...state,
            lookup_name: action.data,
            isLoadingLookup: false
          }
        }
        if (action.field === 'inv_no') {
          return {
            ...state,
            lookup_inv_no: action.data,
            isLoadingLookup: false
          }
        }
        console.error('Wrong action field.')
      }
      if (action.mode === AsyncActionMode.ERROR) {
        return {
          ...state,
          error: action.error,
          isLoadingLookup: false
        }
      }
      break
    case ActionType.GET_INVENTORY_STATUSES:
      if (action.mode === AsyncActionMode.REQUEST) {
        return { ...state }
      }
      if (action.mode === AsyncActionMode.RESPONSE) {
        return {
          ...state,
          statuses: action.data,
        }
      }
      if (action.mode === AsyncActionMode.ERROR) {
        return {
          ...state,
          error: action.error,
        }
      }
      break
    case ActionType.GET_INVENTORY_IPMI:
      if (action.mode === AsyncActionMode.REQUEST) {
        return { ...state, ip_addresses: [], isLoadingIps: true }
      }
      if (action.mode === AsyncActionMode.RESPONSE) {
        return {
          ...state,
          ip_addresses: action.data,
          isLoadingIps: false,
        }
      }
      if (action.mode === AsyncActionMode.ERROR) {
        return {
          ...state,
          error: action.error,
          isLoadingIps: false,
        }
      }
      break
    case ActionType.GET_INVENTORY_BILL:
      if (action.mode === AsyncActionMode.REQUEST) {
        return { ...state}
      }
      if (action.mode === AsyncActionMode.RESPONSE) {
        return {
          ...state,
          bill: action.data,
        }
      }
      if (action.mode === AsyncActionMode.ERROR) {
        return {
          ...state,
          error: action.error
        }
      }
      break

    case ActionType.GET_INVENTORY_TYPE_USABLES:
      if (action.mode === AsyncActionMode.REQUEST) {
        return { ...state,
          usables: [],
          usables_inventories: [],
          inventoryNumber: '',
        }
      }
      if (action.mode === AsyncActionMode.RESPONSE) {
        return {
          ...state,
          usables: action.data,
          usables_inventories: getInventories(action.data),
        }
      }
      if (action.mode === AsyncActionMode.ERROR) {
        return {
          ...state,
          error: action.error,
        }
      }
      break

    case ActionType.DELETE_INVENTORY:
      if (action.mode === AsyncActionMode.REQUEST) {
        return { ...state, isSaving: true }
      }
      if (action.mode === AsyncActionMode.RESPONSE) {
        return {
          ...state,
          inventory: undefined,
          inventories: {
            ...state.inventories,
            items: state.inventories.items.filter(
                (inventory) => inventory.id !== action.request.id,
            )},
          isSaving: false,
        }
      }
      if (action.mode === AsyncActionMode.ERROR) {
        return {
          ...state,
          isSaving: false,
          error: action.error,
        }
      }
      break

    case ActionType.UPDATE_INVENTORY:
      if (action.mode === AsyncActionMode.REQUEST) {
        return { ...state, isSaving: true }
      }
      if (action.mode === AsyncActionMode.RESPONSE) {
        return {
          ...state,
          inventory: action.data,
          inventories: {
            ...state.inventories,
            items: state.inventories.items.map((inventory) =>
                inventory.id === action.data.id ? { ...action.data } : inventory,
            )},
          isSaving: false,
        }
      }
      if (action.mode === AsyncActionMode.ERROR) {
        return {
          ...state,
          isSaving: false,
          error: action.error,
        }
      }
      break
  }
  return state
}
