import {
    GetCompanyBillRequestAction,
    GetCompanyBillResponseAction,
    GetCompanyBillErrorAction,
} from '.'
import { protectedApiClient } from 'helpers/api'
import { handleApiErrorWithNotification } from 'helpers/errorHandling'
import { BillModel } from '../models'
import {CompanyDetails} from "../../../company/models";


export default (id: number, cb?: (isSuccess: boolean) => void) => {
    return (
        dispatch: (
            arg: GetCompanyBillRequestAction | GetCompanyBillResponseAction | GetCompanyBillErrorAction,
        ) => void,
    ) => {
        const request = new GetCompanyBillRequestAction(id)
        dispatch(request)

        protectedApiClient
            .get<CompanyDetails>(`/company/company/${id}`)
            .then((response) => {
                dispatch(new GetCompanyBillResponseAction(request, response.data))
                cb && cb(true)
            })
            .catch((error) => {
                dispatch(new GetCompanyBillErrorAction(request, error))
                handleApiErrorWithNotification(error)
                cb && cb(false)
            })
    }
}
