import { GetRdbServicesRequestAction, GetRdbServicesResponseAction, GetRdbServicesErrorAction } from './index'
import { protectedApiClient } from 'helpers/api'
import { handleApiErrorWithNotification } from 'helpers/errorHandling'
import { NameItem } from 'common/models'

export default (cb?: (isSuccess: boolean) => void) => {
	return (
		dispatch: (arg: GetRdbServicesRequestAction | GetRdbServicesResponseAction | GetRdbServicesErrorAction) => void
	) => {
		const request = new GetRdbServicesRequestAction()
		dispatch(request)

		let url = `/rdb/service/list`
		protectedApiClient
			.get<NameItem[]>(url)
			.then((response) => {
				dispatch(new GetRdbServicesResponseAction(request, response.data))
				cb && cb(true)
			})
			.catch((error) => {
				dispatch(new GetRdbServicesErrorAction(request, error.toString()))
				handleApiErrorWithNotification(error)
				cb && cb(false)
			})
	}
}
