import {AsyncActionMode, NameItem} from 'common/models'
import {BaseInventoryModel, InventoryGrid, InventoryModel, InventoryNumber, InventoryParams} from '../models'
import {InventoryTypeModel} from "../../type/models";
import {InventoryLocationModel} from "../../location/models";
import {IpAddressModel, IpModel, IpSubnetModel} from "../../../ip/subnet/models";
import {BillModel} from "../../../billing/bill/models";

export enum ActionType {
    CREATE_INVENTORY = 'CREATE_INVENTORY',
    GET_INVENTORIES = 'GET_INVENTORIES',
    GET_INVENTORY = 'GET_INVENTORY',
    CLEAR_INVENTORY = 'CLEAR_INVENTORY',
    GET_INVENTORY_NUMBER = 'GET_INVENTORY_NUMBER',
    GET_INVENTORY_BILL = 'GET_INVENTORY_BILL',
    GET_INVENTORY_BILLS = 'GET_INVENTORY_BILLS',
    GET_INVENTORY_TYPE_USABLES = 'GET_INVENTORY_TYPE_USABLES',
    GET_INVENTORY_PAGE_LOCATIONS = 'GET_INVENTORY_PAGE_LOCATIONS',
    GET_INVENTORY_PAGE_TYPES = 'GET_INVENTORY_PAGE_TYPES',
    GET_INVENTORY_STATUSES = 'GET_INVENTORY_STATUSES',
    GET_INVENTORY_IPMI = 'GET_INVENTORY_IPMI',
    UPDATE_INVENTORY = 'UPDATE_INVENTORY',
    DELETE_INVENTORY = 'DELETE_INVENTORY',
    LOOKUP_INVENTORY = 'LOOKUP_INVENTORY',
}

export type InventoryActions =
    | CreateInventoryRequestAction
    | CreateInventoryResponseAction
    | CreateInventoryErrorAction

    | GetInventoriesRequestAction
    | GetInventoriesResponseAction
    | GetInventoriesErrorAction

    | GetInventoryTypeUsablesRequestAction
    | GetInventoryTypeUsablesResponseAction
    | GetInventoryTypeUsablesErrorAction
    | GetInventoryPageTypesRequestAction
    | GetInventoryPageTypesResponseAction
    | GetInventoryPageTypesErrorAction
    | GetInventoryPageLocationsRequestAction
    | GetInventoryPageLocationsResponseAction
    | GetInventoryPageLocationsErrorAction

    | GetInventoryStatusesRequestAction
    | GetInventoryStatusesResponseAction
    | GetInventoryStatusesErrorAction
    | GetInventoryIPMIRequestAction
    | GetInventoryIPMIResponseAction
    | GetInventoryIPMIErrorAction
    | GetInventoryBillRequestAction
    | GetInventoryBillResponseAction
    | GetInventoryBillErrorAction
    | GetInventoryBillsRequestAction
    | GetInventoryBillsResponseAction
    | GetInventoryBillsErrorAction
    | LookupInventoryRequestAction
    | LookupInventoryResponseAction
    | LookupInventoryErrorAction




    | ClearInventoryResponseAction

    | GetInventoryRequestAction
    | GetInventoryResponseAction
    | GetInventoryErrorAction
    | GetInventoryNumberRequestAction
    | GetInventoryNumberResponseAction
    | GetInventoryNumberErrorAction

    | UpdateInventoryRequestAction
    | UpdateInventoryResponseAction
    | UpdateInventoryErrorAction
    | DeleteInventoryRequestAction
    | DeleteInventoryResponseAction
    | DeleteInventoryErrorAction

export class CreateInventoryRequestAction {
    readonly type = ActionType.CREATE_INVENTORY
    readonly mode = AsyncActionMode.REQUEST

    constructor(public payload: BaseInventoryModel) {
    }
}

export class CreateInventoryResponseAction {
    readonly type = ActionType.CREATE_INVENTORY
    readonly mode = AsyncActionMode.RESPONSE

    constructor(
        public request: CreateInventoryRequestAction,
        public data: InventoryModel,
    ) {
    }
}

export class CreateInventoryErrorAction {
    readonly type = ActionType.CREATE_INVENTORY
    readonly mode = AsyncActionMode.ERROR

    constructor(
        public request: CreateInventoryRequestAction,
        public error: string,
    ) {
    }
}

export class GetInventoryRequestAction {
    readonly type = ActionType.GET_INVENTORY
    readonly mode = AsyncActionMode.REQUEST

    constructor(public payload: number) {
        ''
    }
}

export class GetInventoryResponseAction {
    readonly type = ActionType.GET_INVENTORY
    readonly mode = AsyncActionMode.RESPONSE

    constructor(
        public request: GetInventoryRequestAction,
        public data: InventoryModel,
    ) {
    }
}

export class GetInventoryErrorAction {
    readonly type = ActionType.GET_INVENTORY
    readonly mode = AsyncActionMode.ERROR

    constructor(
        public request: GetInventoryRequestAction,
        public error: string,
    ) {
    }
}

export class ClearInventoryResponseAction {
    readonly type = ActionType.CLEAR_INVENTORY
    readonly mode = AsyncActionMode.RESPONSE
    constructor() {}
}

export class GetInventoryNumberRequestAction {
    readonly type = ActionType.GET_INVENTORY_NUMBER
    readonly mode = AsyncActionMode.REQUEST

    constructor() {
        ''
    }
}

export class GetInventoryNumberResponseAction {
    readonly type = ActionType.GET_INVENTORY_NUMBER
    readonly mode = AsyncActionMode.RESPONSE

    constructor(
        public request: GetInventoryNumberRequestAction,
        public data: InventoryNumber,
    ) {
    }
}

export class GetInventoryNumberErrorAction {
    readonly type = ActionType.GET_INVENTORY_NUMBER
    readonly mode = AsyncActionMode.ERROR

    constructor(
        public request: GetInventoryNumberRequestAction,
        public error: string,
    ) {
    }
}


export class GetInventoryTypeUsablesRequestAction {
    readonly type = ActionType.GET_INVENTORY_TYPE_USABLES
    readonly mode = AsyncActionMode.REQUEST
    constructor(public payload: number) {
        ''
    }
}

export class GetInventoryTypeUsablesResponseAction {
    readonly type = ActionType.GET_INVENTORY_TYPE_USABLES
    readonly mode = AsyncActionMode.RESPONSE
    constructor(
        public request: GetInventoryTypeUsablesRequestAction,
        public data: InventoryTypeModel[],
    ) {
    }
}

export class GetInventoryTypeUsablesErrorAction {
    readonly type = ActionType.GET_INVENTORY_TYPE_USABLES
    readonly mode = AsyncActionMode.ERROR
    constructor(
        public request: GetInventoryTypeUsablesRequestAction,
        public error: string,
    ) {
    }
}


export class GetInventoriesRequestAction {
    readonly type = ActionType.GET_INVENTORIES
    readonly mode = AsyncActionMode.REQUEST
    constructor() {
        ''
    }
}

export class GetInventoriesResponseAction {
    readonly type = ActionType.GET_INVENTORIES
    readonly mode = AsyncActionMode.RESPONSE
    constructor(
        public request: GetInventoriesRequestAction,
        public data: InventoryGrid,
    ) {
    }
}

export class GetInventoriesErrorAction {
    readonly type = ActionType.GET_INVENTORIES
    readonly mode = AsyncActionMode.ERROR
    constructor(
        public request: GetInventoriesRequestAction,
        public error: string,
    ) {
    }
}

export class GetInventoryPageTypesRequestAction {
    readonly type = ActionType.GET_INVENTORY_PAGE_TYPES
    readonly mode = AsyncActionMode.REQUEST

    constructor() {
        ''
    }
}

export class GetInventoryPageTypesResponseAction {
    readonly type = ActionType.GET_INVENTORY_PAGE_TYPES
    readonly mode = AsyncActionMode.RESPONSE

    constructor(
        public request: GetInventoryPageTypesRequestAction,
        public data: InventoryTypeModel[],
    ) {
    }
}

export class GetInventoryPageTypesErrorAction {
    readonly type = ActionType.GET_INVENTORY_PAGE_TYPES
    readonly mode = AsyncActionMode.ERROR

    constructor(
        public request: GetInventoryPageTypesRequestAction,
        public error: string,
    ) {
    }
}


export class GetInventoryPageLocationsRequestAction {
    readonly type = ActionType.GET_INVENTORY_PAGE_LOCATIONS
    readonly mode = AsyncActionMode.REQUEST

    constructor() {
        ''
    }
}

export class GetInventoryPageLocationsResponseAction {
    readonly type = ActionType.GET_INVENTORY_PAGE_LOCATIONS
    readonly mode = AsyncActionMode.RESPONSE

    constructor(
        public request: GetInventoryPageLocationsRequestAction,
        public data: InventoryLocationModel[],
    ) {
    }
}

export class GetInventoryPageLocationsErrorAction {
    readonly type = ActionType.GET_INVENTORY_PAGE_LOCATIONS
    readonly mode = AsyncActionMode.ERROR

    constructor(
        public request: GetInventoryPageLocationsRequestAction,
        public error: string,
    ) {
    }
}


export class UpdateInventoryRequestAction {
    readonly type = ActionType.UPDATE_INVENTORY
    readonly mode = AsyncActionMode.REQUEST

    constructor(public params: InventoryParams) {}
}

export class UpdateInventoryResponseAction {
    readonly type = ActionType.UPDATE_INVENTORY
    readonly mode = AsyncActionMode.RESPONSE

    constructor(
        public request: UpdateInventoryRequestAction,
        public data: InventoryModel,
    ) {}
}

export class UpdateInventoryErrorAction {
    readonly type = ActionType.UPDATE_INVENTORY
    readonly mode = AsyncActionMode.ERROR

    constructor(
        public request: UpdateInventoryRequestAction,
        public error: string,
    ) {
    }
}

export class DeleteInventoryRequestAction {
    readonly type = ActionType.DELETE_INVENTORY
    readonly mode = AsyncActionMode.REQUEST
    constructor(public id: number) {
        ''
    }
}

export class DeleteInventoryResponseAction {
    readonly type = ActionType.DELETE_INVENTORY
    readonly mode = AsyncActionMode.RESPONSE
    constructor(
        public request: DeleteInventoryRequestAction,
        public data: InventoryModel,
    ) {
    }
}

export class DeleteInventoryErrorAction {
    readonly type = ActionType.DELETE_INVENTORY
    readonly mode = AsyncActionMode.ERROR
    constructor(
        public request: DeleteInventoryRequestAction,
        public error: string,
    ) {
    }
}


export class GetInventoryStatusesRequestAction {
    readonly type = ActionType.GET_INVENTORY_STATUSES
    readonly mode = AsyncActionMode.REQUEST
    constructor() {
        ''
    }
}
export class GetInventoryStatusesResponseAction {
    readonly type = ActionType.GET_INVENTORY_STATUSES
    readonly mode = AsyncActionMode.RESPONSE

    constructor(
        public request: GetInventoryStatusesRequestAction,
        public data: NameItem[],
    ) {
    }
}
export class GetInventoryStatusesErrorAction {
    readonly type = ActionType.GET_INVENTORY_STATUSES
    readonly mode = AsyncActionMode.ERROR

    constructor(
        public request: GetInventoryStatusesRequestAction,
        public error: string,
    ) {
    }
}

export class GetInventoryIPMIRequestAction {
    readonly type = ActionType.GET_INVENTORY_IPMI
    readonly mode = AsyncActionMode.REQUEST
    constructor() {
        ''
    }
}
export class GetInventoryIPMIResponseAction {
    readonly type = ActionType.GET_INVENTORY_IPMI
    readonly mode = AsyncActionMode.RESPONSE
    constructor(
        public request: GetInventoryIPMIRequestAction,
        public data: IpModel[],
    ) {
    }
}
export class GetInventoryIPMIErrorAction {
    readonly type = ActionType.GET_INVENTORY_IPMI
    readonly mode = AsyncActionMode.ERROR

    constructor(
        public request: GetInventoryIPMIRequestAction,
        public error: string,
    ) {
    }
}

export class GetInventoryBillRequestAction {
    readonly type = ActionType.GET_INVENTORY_BILL
    readonly mode = AsyncActionMode.REQUEST
    constructor(public payload: number) {
        ''
    }
}
export class GetInventoryBillResponseAction {
    readonly type = ActionType.GET_INVENTORY_BILL
    readonly mode = AsyncActionMode.RESPONSE
    constructor(public request: GetInventoryBillRequestAction, public data: BillModel) {}
}
export class GetInventoryBillErrorAction {
    readonly type = ActionType.GET_INVENTORY_BILL
    readonly mode = AsyncActionMode.ERROR
    constructor(public request: GetInventoryBillRequestAction, public error: string) {}
}


export class GetInventoryBillsRequestAction {
    readonly type = ActionType.GET_INVENTORY_BILLS
    readonly mode = AsyncActionMode.REQUEST
    constructor() {
        ''
    }
}
export class GetInventoryBillsResponseAction {
    readonly type = ActionType.GET_INVENTORY_BILLS
    readonly mode = AsyncActionMode.RESPONSE
    constructor(
        public request: GetInventoryBillsRequestAction,
        public data: BillModel[],
    ) {}
}
export class GetInventoryBillsErrorAction {
    readonly type = ActionType.GET_INVENTORY_BILLS
    readonly mode = AsyncActionMode.ERROR
    constructor(public request: GetInventoryBillsRequestAction, public error: string) {}
}

export class LookupInventoryRequestAction {
    readonly type = ActionType.LOOKUP_INVENTORY
    readonly mode = AsyncActionMode.REQUEST
    constructor(public field: string) {
        ''
    }
}
export class LookupInventoryResponseAction {
    readonly type = ActionType.LOOKUP_INVENTORY
    readonly mode = AsyncActionMode.RESPONSE
    constructor(
        public request: LookupInventoryRequestAction,
        public field: string,
        public data: string[],
    ) {}
}
export class LookupInventoryErrorAction {
    readonly type = ActionType.LOOKUP_INVENTORY
    readonly mode = AsyncActionMode.ERROR
    constructor(public request: LookupInventoryRequestAction, public error: string) {}
}
