import { CreateMailboxRequestAction, CreateMailboxResponseAction, CreateMailboxErrorAction } from '.'
import { protectedApiClient } from 'helpers/api'
import { handleApiErrorWithNotification } from 'helpers/errorHandling'
import { CreateMailboxParams, MailboxModel } from '../models'

export default (params: CreateMailboxParams, cb?: (isSuccess: boolean) => void) => {
	return (
		dispatch: (arg: CreateMailboxRequestAction | CreateMailboxResponseAction | CreateMailboxErrorAction) => void
	) => {
		const request = new CreateMailboxRequestAction(params)
		dispatch(request)
		protectedApiClient
			.post<MailboxModel>(`/mail/domain/${params.mail_domain_id}/mailbox`, params)
			.then((response) => {
				dispatch(new CreateMailboxResponseAction(request, response.data))
				cb && cb(true)
			})
			.catch((error) => {
				let msg = ''
				if (error) {
					if (error.message) {
						msg = error.message
					} else {
						msg = error.toString()
					}
				}
				dispatch(new CreateMailboxErrorAction(request, msg))
				handleApiErrorWithNotification(error)
				cb && cb(false)
			})
	}
}
