import { AsyncActionMode } from 'common/models'
import {
	CreateMailboxParams,
	CreateMailDomainParams,
	CreateMailServiceParams,
	CreateMailAliasParams,
	DaemonVersionResp,
	MailAliasModel,
	MailboxModel,
	MailDomainModel,
	MailServiceModel,
	MailVersionResp,
	UpdateMailboxParams,
	UpdateMailDomainParams,
	UpdateMailServiceParams,
	UpdateMailboxPasswordParams,
	CheckMXResp,
} from '../models'
import { ServerModel } from 'pages/server/models'
import { DomainModel } from 'pages/dns/service/models'

export enum ActionType {
	CREATE_MAIL_SERVICE = 'CREATE_MAIL_SERVICE',
	GET_MAIL_SERVICES = 'GET_MAIL_SERVICES',
	GET_MAIL_SERVICE = 'GET_MAIL_SERVICE',
	GET_MAIL_SERVICE_DEFAULT = 'GET_MAIL_SERVICE_DEFAULT',
	UPDATE_MAIL_SERVICE = 'UPDATE_MAIL_SERVICE',
	DELETE_MAIL_SERVICE = 'DELETE_MAIL_SERVICE',

	CREATE_MAIL_DOMAIN = 'CREATE_MAIL_DOMAIN',
	GET_MAIL_DOMAINS = 'GET_MAIL_DOMAINS',
	GET_MAIL_DOMAINS_BY_SERVICE = 'GET_MAIL_DOMAINS_BY_SERVICE',
	GET_MAIL_DOMAIN = 'GET_MAIL_DOMAIN',
	UPDATE_MAIL_DOMAIN = 'UPDATE_MAIL_DOMAIN',
	DELETE_MAIL_DOMAIN = 'DELETE_MAIL_DOMAIN',
	CHECK_MX = 'CHECK_MX',

	CREATE_MAILBOX = 'CREATE_MAILBOX',
	GET_MAILBOXES = 'GET_MAILBOXES',
	GET_MAILBOX = 'GET_MAILBOX',
	UPDATE_MAILBOX = 'UPDATE_MAILBOX',
	UPDATE_MAILBOX_PASSWORD = 'UPDATE_MAILBOX_PASSWORD',
	DELETE_MAILBOX = 'DELETE_MAILBOX',
	CREATE_TRASH = 'CREATE_TRASH',
	DELETE_TRASH = 'DELETE_TRASH',

	CREATE_MAIL_ALIAS = 'CREATE_MAIL_ALIAS',
	GET_MAIL_ALIASES = 'GET_MAIL_ALIASES',
	GET_MAIL_ALIAS = 'GET_MAIL_ALIAS',
	UPDATE_MAIL_ALIAS = 'UPDATE_MAIL_ALIAS',
	DELETE_MAIL_ALIAS = 'DELETE_MAIL_ALIAS',

	LOOKUP_MAIL_DOMAIN = 'LOOKUP_MAIL_DOMAIN',
	LOOKUP_MAIL_SERVICE = 'LOOKUP_MAIL_SERVICE',
	GET_DAEMON_VERSION = 'GET_DAEMON_VERSION',
	GET_MAIL_VERSION = 'GET_MAIL_VERSION',

	GET_SERVERS_MAIL = 'GET_SERVERS_MAIL',
	GET_DOMAINS_MAIL = 'GET_DOMAINS_MAIL',
}

export type MailServiceActions =
	| CreateMailServiceRequestAction
	| CreateMailServiceResponseAction
	| CreateMailServiceErrorAction
	| GetMailServiceRequestAction
	| GetMailServiceResponseAction
	| GetMailServiceErrorAction
	| GetMailServicesRequestAction
	| GetMailServicesResponseAction
	| GetMailServicesErrorAction
	| UpdateMailServiceRequestAction
	| UpdateMailServiceResponseAction
	| UpdateMailServiceErrorAction
	| DeleteMailServiceRequestAction
	| DeleteMailServiceResponseAction
	| DeleteMailServiceErrorAction
	| GetMailDomainsRequestAction
	| GetMailDomainsResponseAction
	| GetMailDomainsErrorAction
	| GetMailDomainsByServiceRequestAction
	| GetMailDomainsByServiceResponseAction
	| GetMailDomainsByServiceErrorAction
	| GetMailDomainRequestAction
	| GetMailDomainResponseAction
	| GetMailDomainErrorAction
	| CreateMailDomainRequestAction
	| CreateMailDomainResponseAction
	| CreateMailDomainErrorAction
	| UpdateMailDomainRequestAction
	| UpdateMailDomainResponseAction
	| UpdateMailDomainErrorAction
	| DeleteMailDomainRequestAction
	| DeleteMailDomainResponseAction
	| DeleteMailDomainErrorAction
	| GetMailboxesRequestAction
	| GetMailboxesResponseAction
	| GetMailboxesErrorAction
	| GetMailboxRequestAction
	| GetMailboxResponseAction
	| GetMailboxErrorAction
	| CreateMailboxRequestAction
	| CreateMailboxResponseAction
	| CreateMailboxErrorAction
	| UpdateMailboxRequestAction
	| UpdateMailboxResponseAction
	| UpdateMailboxErrorAction
	| UpdateMailboxPasswordRequestAction
	| UpdateMailboxPasswordResponseAction
	| UpdateMailboxPasswordErrorAction
	| DeleteMailboxRequestAction
	| DeleteMailboxResponseAction
	| DeleteMailboxErrorAction
	| GetMailServiceByNameRequestAction
	| GetMailServiceByNameResponseAction
	| GetMailServiceByNameErrorAction
	| LookupMailDomainRequestAction
	| LookupMailDomainResponseAction
	| LookupMailDomainErrorAction
	| LookupMailServiceRequestAction
	| LookupMailServiceResponseAction
	| LookupMailServiceErrorAction
	| GetServersMailRequestAction
	| GetServersMailResponseAction
	| GetServersMailErrorAction
	| GetDaemonVersionRequestAction
	| GetDaemonVersionResponseAction
	| GetDaemonVersionErrorAction
	| GetMailVersionRequestAction
	| GetMailVersionResponseAction
	| GetMailVersionErrorAction
	| CreateMailAliasRequestAction
	| CreateMailAliasResponseAction
	| CreateMailAliasErrorAction
	| GetMailAliasRequestAction
	| GetMailAliasResponseAction
	| GetMailAliasErrorAction
	| DeleteMailAliasRequestAction
	| DeleteMailAliasResponseAction
	| DeleteMailAliasErrorAction
	| GetMailAliasesRequestAction
	| GetMailAliasesResponseAction
	| GetMailAliasesErrorAction
	| GetDomainsMailRequestAction
	| GetDomainsMailResponseAction
	| GetDomainsMailErrorAction
	| CreateTrashRequestAction
	| CreateTrashResponseAction
	| CreateTrashErrorAction
	| DeleteTrashRequestAction
	| DeleteTrashResponseAction
	| DeleteTrashErrorAction
	| CheckMXRequestAction
	| CheckMXResponseAction
	| CheckMXErrorAction

export class CreateMailServiceRequestAction {
	readonly type = ActionType.CREATE_MAIL_SERVICE
	readonly mode = AsyncActionMode.REQUEST
	constructor(public payload: CreateMailServiceParams) {}
}
export class CreateMailServiceResponseAction {
	readonly type = ActionType.CREATE_MAIL_SERVICE
	readonly mode = AsyncActionMode.RESPONSE
	constructor(public request: CreateMailServiceRequestAction, public data: MailServiceModel) {}
}
export class CreateMailServiceErrorAction {
	readonly type = ActionType.CREATE_MAIL_SERVICE
	readonly mode = AsyncActionMode.ERROR
	constructor(public request: CreateMailServiceRequestAction, public error: string) {}
}

export class GetMailServiceRequestAction {
	readonly type = ActionType.GET_MAIL_SERVICE
	readonly mode = AsyncActionMode.REQUEST
	constructor(public id: number) {}
}

export class GetMailServiceResponseAction {
	readonly type = ActionType.GET_MAIL_SERVICE
	readonly mode = AsyncActionMode.RESPONSE
	constructor(public request: GetMailServiceRequestAction, public data: MailServiceModel) {}
}

export class GetMailServiceErrorAction {
	readonly type = ActionType.GET_MAIL_SERVICE
	readonly mode = AsyncActionMode.ERROR
	constructor(public request: GetMailServiceRequestAction, public error: string) {}
}

export class GetMailServicesRequestAction {
	readonly type = ActionType.GET_MAIL_SERVICES
	readonly mode = AsyncActionMode.REQUEST
	constructor() {}
}

export class GetMailServicesResponseAction {
	readonly type = ActionType.GET_MAIL_SERVICES
	readonly mode = AsyncActionMode.RESPONSE
	constructor(public request: GetMailServicesRequestAction, public data: MailServiceModel[]) {}
}

export class GetMailServicesErrorAction {
	readonly type = ActionType.GET_MAIL_SERVICES
	readonly mode = AsyncActionMode.ERROR
	constructor(public request: GetMailServicesRequestAction, public error: string) {}
}

export class GetMailServiceByNameRequestAction {
	readonly type = ActionType.GET_MAIL_SERVICE_DEFAULT
	readonly mode = AsyncActionMode.REQUEST
	constructor(name: string) {}
}

export class GetMailServiceByNameResponseAction {
	readonly type = ActionType.GET_MAIL_SERVICE_DEFAULT
	readonly mode = AsyncActionMode.RESPONSE
	constructor(public request: GetMailServiceByNameRequestAction, public data: MailServiceModel[]) {}
}

export class GetMailServiceByNameErrorAction {
	readonly type = ActionType.GET_MAIL_SERVICE_DEFAULT
	readonly mode = AsyncActionMode.ERROR
	constructor(public request: GetMailServiceByNameRequestAction, public error: string) {}
}

export class UpdateMailServiceRequestAction {
	readonly type = ActionType.UPDATE_MAIL_SERVICE
	readonly mode = AsyncActionMode.REQUEST
	constructor(public payload: UpdateMailServiceParams) {}
}

export class UpdateMailServiceResponseAction {
	readonly type = ActionType.UPDATE_MAIL_SERVICE
	readonly mode = AsyncActionMode.RESPONSE
	constructor(public request: UpdateMailServiceRequestAction, public data: MailServiceModel) {}
}

export class UpdateMailServiceErrorAction {
	readonly type = ActionType.UPDATE_MAIL_SERVICE
	readonly mode = AsyncActionMode.ERROR
	constructor(public request: UpdateMailServiceRequestAction, public error: string) {}
}

export class DeleteMailServiceRequestAction {
	readonly type = ActionType.DELETE_MAIL_SERVICE
	readonly mode = AsyncActionMode.REQUEST
	constructor(public id: number) {}
}

export class DeleteMailServiceResponseAction {
	readonly type = ActionType.DELETE_MAIL_SERVICE
	readonly mode = AsyncActionMode.RESPONSE
	constructor(public request: DeleteMailServiceRequestAction) {}
}

export class DeleteMailServiceErrorAction {
	readonly type = ActionType.DELETE_MAIL_SERVICE
	readonly mode = AsyncActionMode.ERROR
	constructor(public request: DeleteMailServiceRequestAction, public error: string) {}
}

export class GetMailDomainsRequestAction {
	readonly type = ActionType.GET_MAIL_DOMAINS
	readonly mode = AsyncActionMode.REQUEST
	constructor() {}
}

export class GetMailDomainsResponseAction {
	readonly type = ActionType.GET_MAIL_DOMAINS
	readonly mode = AsyncActionMode.RESPONSE
	constructor(
		public request: GetMailDomainsRequestAction,
		public data: { items: MailDomainModel[]; pagination: any }
	) {}
}

export class GetMailDomainsErrorAction {
	readonly type = ActionType.GET_MAIL_DOMAINS
	readonly mode = AsyncActionMode.ERROR
	constructor(public request: GetMailDomainsRequestAction, public error: string) {}
}

export class GetMailDomainsByServiceRequestAction {
	readonly type = ActionType.GET_MAIL_DOMAINS_BY_SERVICE
	readonly mode = AsyncActionMode.REQUEST
	constructor(public serviceId: number) {}
}

export class GetMailDomainsByServiceResponseAction {
	readonly type = ActionType.GET_MAIL_DOMAINS_BY_SERVICE
	readonly mode = AsyncActionMode.RESPONSE
	constructor(public request: GetMailDomainsByServiceRequestAction, public data: MailDomainModel[]) {}
}

export class GetMailDomainsByServiceErrorAction {
	readonly type = ActionType.GET_MAIL_DOMAINS_BY_SERVICE
	readonly mode = AsyncActionMode.ERROR
	constructor(public request: GetMailDomainsByServiceRequestAction, public error: string) {}
}

export class GetMailDomainRequestAction {
	readonly type = ActionType.GET_MAIL_DOMAIN
	readonly mode = AsyncActionMode.REQUEST
	constructor(public domainId: number) {}
}

export class GetMailDomainResponseAction {
	readonly type = ActionType.GET_MAIL_DOMAIN
	readonly mode = AsyncActionMode.RESPONSE
	constructor(public request: GetMailDomainRequestAction, public data: MailDomainModel) {}
}

export class GetMailDomainErrorAction {
	readonly type = ActionType.GET_MAIL_DOMAIN
	readonly mode = AsyncActionMode.ERROR
	constructor(public request: GetMailDomainRequestAction, public error: string) {}
}

export class CreateMailDomainRequestAction {
	readonly type = ActionType.CREATE_MAIL_DOMAIN
	readonly mode = AsyncActionMode.REQUEST
	constructor(public payload: CreateMailDomainParams) {}
}

export class CreateMailDomainResponseAction {
	readonly type = ActionType.CREATE_MAIL_DOMAIN
	readonly mode = AsyncActionMode.RESPONSE
	constructor(public request: CreateMailDomainRequestAction, public data: MailDomainModel) {}
}

export class CreateMailDomainErrorAction {
	readonly type = ActionType.CREATE_MAIL_DOMAIN
	readonly mode = AsyncActionMode.ERROR
	constructor(public request: CreateMailDomainRequestAction, public error: string) {}
}

export class UpdateMailDomainRequestAction {
	readonly type = ActionType.UPDATE_MAIL_DOMAIN
	readonly mode = AsyncActionMode.REQUEST
	constructor(public payload: UpdateMailDomainParams) {}
}

export class UpdateMailDomainResponseAction {
	readonly type = ActionType.UPDATE_MAIL_DOMAIN
	readonly mode = AsyncActionMode.RESPONSE
	constructor(public request: UpdateMailDomainRequestAction, public data: MailDomainModel) {}
}

export class UpdateMailDomainErrorAction {
	readonly type = ActionType.UPDATE_MAIL_DOMAIN
	readonly mode = AsyncActionMode.ERROR
	constructor(public request: UpdateMailDomainRequestAction, public error: string) {}
}

export class DeleteMailDomainRequestAction {
	readonly type = ActionType.DELETE_MAIL_DOMAIN
	readonly mode = AsyncActionMode.REQUEST
	constructor(public id: number) {}
}

export class DeleteMailDomainResponseAction {
	readonly type = ActionType.DELETE_MAIL_DOMAIN
	readonly mode = AsyncActionMode.RESPONSE
	constructor(public request: DeleteMailDomainRequestAction) {}
}

export class DeleteMailDomainErrorAction {
	readonly type = ActionType.DELETE_MAIL_DOMAIN
	readonly mode = AsyncActionMode.ERROR
	constructor(public request: DeleteMailDomainRequestAction, public error: string) {}
}

export class GetMailboxesRequestAction {
	readonly type = ActionType.GET_MAILBOXES
	readonly mode = AsyncActionMode.REQUEST
	constructor(public domainId: number) {}
}

export class GetMailboxesResponseAction {
	readonly type = ActionType.GET_MAILBOXES
	readonly mode = AsyncActionMode.RESPONSE
	constructor(public request: GetMailboxesRequestAction, public data: MailboxModel[]) {}
}

export class GetMailboxesErrorAction {
	readonly type = ActionType.GET_MAILBOXES
	readonly mode = AsyncActionMode.ERROR
	constructor(public request: GetMailboxesRequestAction, public error: string) {}
}

export class GetMailboxRequestAction {
	readonly type = ActionType.GET_MAILBOX
	readonly mode = AsyncActionMode.REQUEST
	constructor() {}
}

export class GetMailboxResponseAction {
	readonly type = ActionType.GET_MAILBOX
	readonly mode = AsyncActionMode.RESPONSE
	constructor(public request: GetMailboxRequestAction, public data: MailboxModel) {}
}

export class GetMailboxErrorAction {
	readonly type = ActionType.GET_MAILBOX
	readonly mode = AsyncActionMode.ERROR
	constructor(public request: GetMailboxRequestAction, public error: string) {}
}

export class CreateMailboxRequestAction {
	readonly type = ActionType.CREATE_MAILBOX
	readonly mode = AsyncActionMode.REQUEST
	constructor(public payload: CreateMailboxParams) {}
}

export class CreateMailboxResponseAction {
	readonly type = ActionType.CREATE_MAILBOX
	readonly mode = AsyncActionMode.RESPONSE
	constructor(public request: CreateMailboxRequestAction, public data: MailboxModel) {}
}

export class CreateMailboxErrorAction {
	readonly type = ActionType.CREATE_MAILBOX
	readonly mode = AsyncActionMode.ERROR
	constructor(public request: CreateMailboxRequestAction, public error: string) {}
}

export class UpdateMailboxRequestAction {
	readonly type = ActionType.UPDATE_MAILBOX
	readonly mode = AsyncActionMode.REQUEST
	constructor(public payload: UpdateMailboxParams) {}
}

export class UpdateMailboxResponseAction {
	readonly type = ActionType.UPDATE_MAILBOX
	readonly mode = AsyncActionMode.RESPONSE
	constructor(public request: UpdateMailboxRequestAction, public data: MailboxModel) {}
}

export class UpdateMailboxErrorAction {
	readonly type = ActionType.UPDATE_MAILBOX
	readonly mode = AsyncActionMode.ERROR
	constructor(public request: UpdateMailboxRequestAction, public error: string) {}
}

export class UpdateMailboxPasswordRequestAction {
	readonly type = ActionType.UPDATE_MAILBOX_PASSWORD
	readonly mode = AsyncActionMode.REQUEST
	constructor(public payload: UpdateMailboxPasswordParams) {}
}

export class UpdateMailboxPasswordResponseAction {
	readonly type = ActionType.UPDATE_MAILBOX_PASSWORD
	readonly mode = AsyncActionMode.RESPONSE
	constructor(public request: UpdateMailboxPasswordRequestAction, public data: MailboxModel) {}
}

export class UpdateMailboxPasswordErrorAction {
	readonly type = ActionType.UPDATE_MAILBOX_PASSWORD
	readonly mode = AsyncActionMode.ERROR
	constructor(public request: UpdateMailboxPasswordRequestAction, public error: string) {}
}

export class DeleteMailboxRequestAction {
	readonly type = ActionType.DELETE_MAILBOX
	readonly mode = AsyncActionMode.REQUEST
	constructor() {}
}

export class DeleteMailboxResponseAction {
	readonly type = ActionType.DELETE_MAILBOX
	readonly mode = AsyncActionMode.RESPONSE
	constructor(public request: DeleteMailboxRequestAction) {}
}

export class DeleteMailboxErrorAction {
	readonly type = ActionType.DELETE_MAILBOX
	readonly mode = AsyncActionMode.ERROR
	constructor(public request: DeleteMailboxRequestAction, public error: string) {}
}

export class GetServersMailRequestAction {
	readonly type = ActionType.GET_SERVERS_MAIL
	readonly mode = AsyncActionMode.REQUEST
	constructor() {}
}

export class GetServersMailResponseAction {
	readonly type = ActionType.GET_SERVERS_MAIL
	readonly mode = AsyncActionMode.RESPONSE
	constructor(public request: GetServersMailRequestAction, public data: ServerModel[]) {}
}

export class GetServersMailErrorAction {
	readonly type = ActionType.GET_SERVERS_MAIL
	readonly mode = AsyncActionMode.ERROR
	constructor(public request: GetServersMailRequestAction, public error: string) {}
}

export class LookupMailDomainRequestAction {
	readonly type = ActionType.LOOKUP_MAIL_DOMAIN
	readonly mode = AsyncActionMode.REQUEST
	constructor(public field: string) {}
}

export class LookupMailDomainResponseAction {
	readonly type = ActionType.LOOKUP_MAIL_DOMAIN
	readonly mode = AsyncActionMode.RESPONSE
	constructor(public request: LookupMailDomainRequestAction, public field: string, public data: string[]) {}
}

export class LookupMailDomainErrorAction {
	readonly type = ActionType.LOOKUP_MAIL_DOMAIN
	readonly mode = AsyncActionMode.ERROR
	constructor(public request: LookupMailDomainRequestAction, public error: string) {}
}

export class LookupMailServiceRequestAction {
	readonly type = ActionType.LOOKUP_MAIL_SERVICE
	readonly mode = AsyncActionMode.REQUEST
	constructor(public field: string) {}
}

export class LookupMailServiceResponseAction {
	readonly type = ActionType.LOOKUP_MAIL_SERVICE
	readonly mode = AsyncActionMode.RESPONSE
	constructor(public request: LookupMailServiceRequestAction, public field: string, public data: string[]) {}
}

export class LookupMailServiceErrorAction {
	readonly type = ActionType.LOOKUP_MAIL_SERVICE
	readonly mode = AsyncActionMode.ERROR
	constructor(public request: LookupMailServiceRequestAction, public error: string) {}
}

export class GetDaemonVersionRequestAction {
	readonly type = ActionType.GET_DAEMON_VERSION
	readonly mode = AsyncActionMode.REQUEST
}

export class GetDaemonVersionResponseAction {
	readonly type = ActionType.GET_DAEMON_VERSION
	readonly mode = AsyncActionMode.RESPONSE
	constructor(public request: GetDaemonVersionRequestAction, public data: DaemonVersionResp) {}
}

export class GetDaemonVersionErrorAction {
	readonly type = ActionType.GET_DAEMON_VERSION
	readonly mode = AsyncActionMode.ERROR
	constructor(public request: GetDaemonVersionRequestAction, public error: string) {}
}

export class GetMailVersionRequestAction {
	readonly type = ActionType.GET_MAIL_VERSION
	readonly mode = AsyncActionMode.REQUEST
}

export class GetMailVersionResponseAction {
	readonly type = ActionType.GET_MAIL_VERSION
	readonly mode = AsyncActionMode.RESPONSE
	constructor(public request: GetMailVersionRequestAction, public data: MailVersionResp) {}
}

export class GetMailVersionErrorAction {
	readonly type = ActionType.GET_MAIL_VERSION
	readonly mode = AsyncActionMode.ERROR
	constructor(public request: GetMailVersionRequestAction, public error: string) {}
}

export class CreateMailAliasRequestAction {
	readonly type = ActionType.CREATE_MAIL_ALIAS
	readonly mode = AsyncActionMode.REQUEST
	constructor(public payload: CreateMailAliasParams) {}
}

export class CreateMailAliasResponseAction {
	readonly type = ActionType.CREATE_MAIL_ALIAS
	readonly mode = AsyncActionMode.RESPONSE
	constructor(public request: CreateMailAliasRequestAction, public data: MailAliasModel[]) {}
}

export class CreateMailAliasErrorAction {
	readonly type = ActionType.CREATE_MAIL_ALIAS
	readonly mode = AsyncActionMode.ERROR
	constructor(public request: CreateMailAliasRequestAction, public error: string) {}
}

export class GetMailAliasesRequestAction {
	readonly type = ActionType.GET_MAIL_ALIASES
	readonly mode = AsyncActionMode.REQUEST
	constructor(public domainId: number) {}
}

export class GetMailAliasesResponseAction {
	readonly type = ActionType.GET_MAIL_ALIASES
	readonly mode = AsyncActionMode.RESPONSE
	constructor(public request: GetMailAliasesRequestAction, public data: MailAliasModel[]) {}
}

export class GetMailAliasesErrorAction {
	readonly type = ActionType.GET_MAIL_ALIASES
	readonly mode = AsyncActionMode.ERROR
	constructor(public request: GetMailAliasesRequestAction, public error: string) {}
}

export class GetMailAliasRequestAction {
	readonly type = ActionType.GET_MAIL_ALIAS
	readonly mode = AsyncActionMode.REQUEST
	constructor(public email: string) {}
}

export class GetMailAliasResponseAction {
	readonly type = ActionType.GET_MAIL_ALIAS
	readonly mode = AsyncActionMode.RESPONSE
	constructor(public request: GetMailAliasRequestAction, public data: MailAliasModel) {}
}

export class GetMailAliasErrorAction {
	readonly type = ActionType.GET_MAIL_ALIAS
	readonly mode = AsyncActionMode.ERROR
	constructor(public request: GetMailAliasRequestAction, public error: string) {}
}

export class DeleteMailAliasRequestAction {
	readonly type = ActionType.DELETE_MAIL_ALIAS
	readonly mode = AsyncActionMode.REQUEST
	constructor(public domainId: number, public name: string) {}
}

export class DeleteMailAliasResponseAction {
	readonly type = ActionType.DELETE_MAIL_ALIAS
	readonly mode = AsyncActionMode.RESPONSE
	constructor(public request: DeleteMailAliasRequestAction) {}
}

export class DeleteMailAliasErrorAction {
	readonly type = ActionType.DELETE_MAIL_ALIAS
	readonly mode = AsyncActionMode.ERROR
	constructor(public request: DeleteMailAliasRequestAction, public error: string) {}
}

export class GetDomainsMailRequestAction {
	readonly type = ActionType.GET_DOMAINS_MAIL
	readonly mode = AsyncActionMode.REQUEST
	constructor() {}
}

export class GetDomainsMailResponseAction {
	readonly type = ActionType.GET_DOMAINS_MAIL
	readonly mode = AsyncActionMode.RESPONSE
	constructor(public request: GetDomainsMailRequestAction, public data: DomainModel[]) {}
}

export class GetDomainsMailErrorAction {
	readonly type = ActionType.GET_DOMAINS_MAIL
	readonly mode = AsyncActionMode.ERROR
	constructor(public request: GetDomainsMailRequestAction, public error: string) {}
}

export class DeleteTrashRequestAction {
	readonly type = ActionType.DELETE_TRASH
	readonly mode = AsyncActionMode.REQUEST
	constructor(public domainId: number) {}
}

export class DeleteTrashResponseAction {
	readonly type = ActionType.DELETE_TRASH
	readonly mode = AsyncActionMode.RESPONSE
	constructor(public request: DeleteTrashRequestAction) {}
}

export class DeleteTrashErrorAction {
	readonly type = ActionType.DELETE_TRASH
	readonly mode = AsyncActionMode.ERROR
	constructor(public request: DeleteTrashRequestAction, public error: string) {}
}

export class CreateTrashRequestAction {
	readonly type = ActionType.CREATE_TRASH
	readonly mode = AsyncActionMode.REQUEST
	constructor(public payload: CreateMailboxParams) {}
}

export class CreateTrashResponseAction {
	readonly type = ActionType.CREATE_TRASH
	readonly mode = AsyncActionMode.RESPONSE
	constructor(public request: CreateTrashRequestAction, public data: MailboxModel) {}
}

export class CreateTrashErrorAction {
	readonly type = ActionType.CREATE_TRASH
	readonly mode = AsyncActionMode.ERROR
	constructor(public request: CreateTrashRequestAction, public error: string) {}
}

export class CheckMXRequestAction {
	readonly type = ActionType.CHECK_MX
	readonly mode = AsyncActionMode.REQUEST
	constructor(public domainId: number) {}
}

export class CheckMXResponseAction {
	readonly type = ActionType.CHECK_MX
	readonly mode = AsyncActionMode.RESPONSE
	constructor(public request: CheckMXRequestAction, public data: CheckMXResp) {}
}

export class CheckMXErrorAction {
	readonly type = ActionType.CHECK_MX
	readonly mode = AsyncActionMode.ERROR
	constructor(public request: CheckMXRequestAction, public error: string) {}
}
